import React, { useState, useEffect } from 'react';
import { useHistory , Link} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form , Alert, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';

// Custom Component
import Footer from 'Components/Footer';
import TabsIcon from 'Components/layout/TabsIcon';
import MyPagination from 'Components/MyPagination';

// Icon
import Back from 'assets/icon/back.png';
import Edit from 'assets/icon/key-fix.png';
import Add from 'assets/icon/add_menu.png';
import Setting from 'assets/icon/setting.png';
import Delete from 'assets/icon/delete.png';
// API
import {
  CarTypeList,
  CreateCarType,
  UpdateCarType,
  DestroyCarType,
} from 'actions/settings/car_type';
import { loadAuth } from 'actions/auth';

export default function TypeCars() {
  const history = useHistory();
  const dispatch = useDispatch(); 
  const [showAlert , setShowAlert] = useState(false)
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [Data, setData] = useState({
    Name: null,
  });

  const [Rows, setRows] = useState(0);
  const [CarType, setCarType] = useState([]);

  const [validated, setValidated] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const data = {
      name: Data.Name,
    };

    try {
      const response = await dispatch(CreateCarType(data));
      console.log(response);
      if (response) {
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          setData({ Name: null });
          setValidated(false);
        }else{
          setData({ Name: null });
          setValidated(false);
          GetCarType();
          window.location.reload(false);
        }
        window.location.reload(false);
      } else {
        console.log('failed');
         setShowAlert(false)
        if (Data.Name) {
          setShowAlert(true)
          console.log('OK')
          return;
        }

      }
     
    } catch (error) {
      console.error(error);
      console.log(error.response)
      
    } finally {
      console.log('finally');
      
    }
    window.location.reload(false);
  };

  const [isLoading, setisLoading] = useState(false);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1,
  });

  const getAuth = async (data) => {
    try {
      const params = {
        name: data.name,
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const GetCarType = async (data) => {
    setisLoading(true);
    const params = {
      limit: Search.PerPage,
      page: Search.CurrentPage,
    };
    try {
      const response = await dispatch(CarTypeList(params));
      if (response.data.data && response.data.data.data) {
        let carType = [];
        response.data.data.data.forEach((item) => {
          let obj = {};
          obj = item;
          obj.edit = false;
          carType.push(obj);
        });
        setCarType(carType);
        setRows(response.data.data.last_page);
        setisLoading(false);
      } else {
        let carType = [];
        response.data.data.forEach((item) => {
          let obj = {};
          obj = item;
          obj.edit = false;
          carType.push(obj);
        });
        setCarType(carType);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const deleteCarType = async (e, id) => {
    e.preventDefault();

    try {
      const response = await dispatch(DestroyCarType(id));

      if (response) {
        GetCarType();
      } else {
        console.log('failed');
      }
      setDeleting([]);
      setConfirmation(false);
    } catch (error) {
      console.error(error);
      setDeleting([]);
      setConfirmation(false);
    } finally {
      console.log('finally');
      setDeleting([]);
      setConfirmation(false);
    }
  };

  useEffect(() => {
    GetCarType();
    getAuth({name: ['ตั้งค่า'],permission : 'view'});
    console.log('ShowAlert', showAlert)
    
  }, [Search.PerPage, Search.CurrentPage]);

  function close() {
    let path = `/setting`;
    history.push(path);
  }

  const ToggleEdit = async (index, data) => {
    let items = [...CarType];
    items[index] = { ...items[index], edit: !data.edit };
    setCarType(items);

    if (data.edit) {
      const form = {
        id: data.id,
        data: data,
      };
      try {
        const response = await dispatch(UpdateCarType(form));
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally');
      }
    }
  };

  function SetEditValue(index, value) {
    let items = [...CarType];
    items[index] = { ...items[index], name: value };
    setCarType(items);
  }

  function deleteConfirmation(e, id) {
    const value = {
      e: e,
      id: id,
    }
    setDeleting(value)
    setConfirmation(true)
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ประเภทรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ประเภทรถ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Col xs={12} md={9} lg={4}>
                          <Form.Control
                            placeholder="ชื่อประเภท"
                            required
                            onChange={(e) =>
                              setData({ ...Data, Name: e.target.value })
                            }
                          ></Form.Control>
                        </Col>
                        <Col md={3} lg={3} xl={2}>
                          <Button type="submit" className="button">
                            <img src={Add} /> &nbsp; เพิ่มรายการ
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row className='mt-4 g-0'>
                  <Col>
                  { showAlert === true ? 
                    <Alert variant="danger" onClose={() => setShowAlert(false)} >
                      <p className='text-left'>
                        ชื่อนี้ถูกใช้ไปแล้ว
                        </p>
                        <Button className='button-alert button-image text-dark ' onClick={() => setShowAlert(false)}>X</Button>
                    </Alert>
                      : null}
                  </Col>
                </Row>       
                <div className="set-height">
                  <Row className="g-0    justify-content-center">
                    <Row className=" ">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">ประเภท</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {CarType?.map((cartype, index) => {
                            return (
                              <tr className="t-tr-setting" key={index}>
                                <td className="text-left">
                                  {cartype.edit ? (
                                    <Form.Control
                                      placeholder="ชื่อประเภท"
                                      required
                                      value={cartype.name}
                                      onChange={(e) =>
                                        SetEditValue(index, e.target.value)
                                      }
                                    ></Form.Control>
                                  ) : (
                                    cartype.name
                                  )}
                                </td>
                                <td className="text-left">
                                  <Button
                                    className="button-package "
                                    onClick={() => ToggleEdit(index, cartype)}
                                  >
                                    <img src={Edit} />
                                  </Button>
                                  &nbsp;
                                  <Button
                                    className="button-package"
                                    onClick={(e) =>
                                      deleteConfirmation(e, cartype.id)
                                    }
                                  >
                                    <img src={Delete} />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          isSearchable={false}
                          options={Pages}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) =>
                            setSearch({ ...Search, PerPage: data.value })
                          }
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {!isLoading ? (
                        <MyPagination
                          totPages={Rows}
                          currentPage={Search.CurrentPage}
                          pageClicked={(e) => {
                            setSearch({ ...Search, CurrentPage: e });
                          }}
                        ></MyPagination>
                      ) : (
                        ''
                      )} */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบรายการหรือไม่</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => deleteCarType(deleting.e, deleting.id) }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
