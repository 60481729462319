import axios, { post } from 'axios';

export const LoadServices = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/services`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadService = (id) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/services/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateService = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/services`,
    formData,
    config
  ).then(response => {
    // console.log('response', response)
    return Promise.resolve(response);
  }).catch( e => {
    // console.log('e', e)
    return Promise.reject(e)
  });
};

export const UpdateService = ({id, formData}) => async(dispatch) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/services/${id}`,
    formData,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyService = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/services/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

