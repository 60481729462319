import axios, { post,put } from 'axios';

export const OrderList = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/orders`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const OrderFind = (id) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/orders/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};
export const CreateOrder = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/orders`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateOrderAdmin = ({id, data}) => async (dispatch) => {
  try {
    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/orders/${id}`,
      data,
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateOrder = ({id, data}) => async (dispatch) => {
  try {
    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/orders/update-status/${id}`,
      data,
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CancelOrder = ({id, data}) => async (dispatch) => {
  try {
    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/orders/${id}`,
      data,
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
} 