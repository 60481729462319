import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { ProductList } from 'actions/product'

export default function Index() {
  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

  const [Data,setData] = useState({
    StorageListProduct: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  function updateLocalStorage(data,item,form){
    
    if(form === 'Quantity'){
      setData(prevState => ({...Data,
        StorageListProduct: prevState.StorageListProduct.map(
          el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
        )}))
  
      item.Quantity = Number(data.target.value)
    }

    if(form ==='note'){
      setData({...Data, note: data.target.value })
      Data.note = data.target.value
    }

    if(form ==='insite_note'){
      setData({...Data, insite_note: data.target.value })
      Data.insite_note = data.target.value
    }

    if(form ==='things_in_car'){
      setData({...Data, things_in_car: data})
      Data.things_in_car = data
    }

    if(form ==='defect_car'){
      setData({...Data, defect_car: data})
      Data.defect_car = data
    }

    if(form ==='area'){
      setData({...Data, area: data.target.value })
      Data.area = data.target.value
    }

    const result = Data.StorageListProduct

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = result;
      parseDataJob.note = Data.note
      parseDataJob.insite_note = Data.insite_note
      parseDataJob.area = Data.area
      parseDataJob.things_in_car = Data.things_in_car
      parseDataJob.defect_car = Data.defect_car
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  const removeProduct = (item) => {
    let find = null;
    if(item.specialName === 'normal'){
      const PackageId = item.PackageId
      find = Data.StorageListProduct.filter(i => i.PackageId !== PackageId);
      
    }else{
      find = Data.StorageListProduct.filter(i => i.key !== item.key);
    }

    setData({ ...Data,
      StorageListProduct : find,
    })

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = find;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  } 

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('getDisplayTextPositionCoating',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  return (
    <>
      <Form>
        <Container fluid>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package">
                {
                 Data.StorageListProduct?.map((item, key) => {
                  return (
                    <tr>
                      <td>{ key + 1}</td>
                      <td>{item.PackageCode}</td>
                      <td>{item.PackageName} 
                        <p>
                          { getTextByPackage(item)}
                          { getDisplayTextPositionCoating(item) }
                          { getDisplayTextPositionUse(item)}
                          { item.TextDetails }
                        </p>
                        {item.service_now === true ? 'บริการทันที' : ''}</td>
                      <td>
                        <Form.Control className="input-table"
                          value={item.Quantity ? item.Quantity : ''}
                          type="number" 
                          required
                          disabled={item.Status === 'appointment'}
                          onChange={
                            (data) => {
                              updateLocalStorage(data,item,'Quantity');
                            }
                          }
                        ></Form.Control>
                      </td>
                      <td>
                        <Button type="button" disabled={item.Status === 'appointment'} className="button-table" onClick={() => removeProduct(item)}>
                          <img src={Delete} />
                        </Button>
                      </td>
                    </tr>
                  );
                 })
                }
                
              </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={ (data) => {
                      setData({...Data, note: data.target.value});
                      updateLocalStorage(data,null,'note');
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={ (data) => {
                      setData({...Data, insite_note: data.target.value})
                      updateLocalStorage(data,null,'insite_note');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}
