import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  // Dropdown,
} from 'react-bootstrap';
import { DatePicker, TimePicker } from 'antd';

// import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';
import Select, { components } from 'react-select';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import Calendar from 'assets/icon/calendar-bg.png';
import FilmImage from 'assets/icon/glass-staff.png'
import Back from 'assets/icon/back.png';
import 'assets/scss/style-component.scss';
import Increase from 'assets/icon/increase.png';
import Setting from 'assets/icon/setting.png';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from 'assets/icon/increase.png';
import DeleteIcon from 'assets/icon/delete.png';

//API
import { JobList } from 'actions/jobs'
import { Branch } from 'actions/branch'
import { Customer } from 'actions/customer';
import { TechnicianQueueList, CreateTechnicianQueue, UpdateTechnicianQueue, UpdateOnlyDateTechnicianQueue, DestroyTechnicianQueue } from 'actions/technician_queue';
import { List } from 'actions/employee';

import moment from 'moment';
var dayjs = require('dayjs')

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation()
  let { jobId } = useParams();

  const { employee } = useSelector( (state) => state.user)

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, 5)
  });

  const days = [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์'
  ]

  const months = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ]

  let yearLoop = []
  let i = 2022
  while (i < (2022 + 15) ){
    yearLoop.push(i)
    i++;
  }

  const [years, setYears] = useState(yearLoop);

  const [selectedYear, setSelectedYear] = useState(dayjs().get('year'));
  const [selectedMonth, setSelectedMonth] = useState(dayjs().get('month'));

  function closePage() {
    history.push(`/`);
  }

  function isToday(day){
    return day && day.isToday() ? 'isToday' : ''
  }

  function allowDrop(e, id) {
    e.preventDefault();
    let field = document.getElementById(id)
    field.childNodes[0].style['pointer-events'] = 'none'
    field.childNodes[1].style['pointer-events'] = 'none'
    if(field.style.border !== '2px dashed rgba(104, 44, 128, 0.8)') field.style.border = '2px solid rgba(104, 44, 128, 0.8)';
    if(field.style['border-style'] !== 'dashed') field.style['border-style'] = 'dashed';
  }
  
  function exitDrop(e, id) {
    e.preventDefault();
    let field = document.getElementById(id)
    field.childNodes[0].style['pointer-events'] = 'auto'
    field.childNodes[1].style['pointer-events'] = 'auto'
    field.style.border = 'none';
  }

  function DragEnd(e, id) {
    const field = document.getElementById(id)
    if (field.childNodes[1].childNodes) {
      field.childNodes[1].childNodes.forEach(item => {
        item.style.opacity = 1;
      });
    }
  }

  function drag(e) {
    e.target.style.opacity = 0.5;
    e.dataTransfer.setData("text", e.target.id);
  }

  function drop(e, id) {
    e.preventDefault();
    let field = document.getElementById(id)
    field.childNodes[1].style['pointer-events'] = 'auto'
    field.style.border = 'none';
    const data = e.dataTransfer.getData("text");
    let ele = document.getElementById(data)
    
    const form = {
      id: ele.dataset.itemId,
      data:{
        date: e.target.dataset.itemDate
      }
    }

    dispatch(UpdateOnlyDateTechnicianQueue(form)).then(response => {
      let index = queues.findIndex((value) => { return Number(value.id) === Number(ele.dataset.itemId) })
      const newQueues = [...queues]
      newQueues[index].date = e.target.dataset.itemDate
      setQueues(newQueues)

      if(ele) ele.style.opacity = 1;
    }).catch( e => {

    })
  }

  function ChangeMonth(action){
    switch (action) {
      case 'previous':
        if((selectedMonth - 1) >= 0) setSelectedMonth(selectedMonth - 1)
        if((selectedMonth - 1) < 0){
          setSelectedMonth(11)
          setSelectedYear(selectedYear - 1)
        }
        break;
      case 'next':
        if((selectedMonth + 1) <= 11) setSelectedMonth(selectedMonth + 1)
        if((selectedMonth + 1) > 11){
          setSelectedMonth(0)
          setSelectedYear(selectedYear + 1)
        }
        break;
    }
  }

  function previousMonth(data){
    switch (days[data]) {
      case 'จันทร์':
        return 0
        break;
      case 'อังคาร':
        return 1
        break;
      case 'พุธ':
        return 2
        break;
      case 'พฤหัสบดี':
        return 3
        break;
      case 'ศุกร์':
        return 4
        break;
      case 'เสาร์':
        return 5
        break;
      case 'อาทิตย์':
        return 6
        break;
    }
  }

  function getDataByDay(date){
    return queues.filter(item => dayjs(item.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
  }

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [form, setForm] = useState({
    job_id: null,
    date: null,
    start_time: null,
    end_time: null,
    time: null,
    type: 'สาขา',
    b2b_id: null,
    b2b_contact_id: null,
    branch_id: null,
    employee_id: null
  });

  const [formEdit, setFormEdit] = useState({
    id: null,
    job_id: null,
    date: null,
    start_time: null,
    end_time: null,
    type: 'สาขา',
    b2b_id: null,
    b2b_contact_id: null,
    branch_id: null,
    employee_id: null
  });

  function openAddModal(item = null){
    let date
    if(item) date = item.dayjs.format('YYYY-MM-DD')
    if(!item) date = dayjs().format('YYYY-MM-DD')

    setForm({...form, date: moment(date)})
    setAddModal(true)
  }

  function openEditModal(item){
    setSelectedCustomer(customers.find(c => c.id === item.b2b_id))
    setFormEdit({
      id: item.id,
      job_id: item.job_id,
      date: moment(item.date),
      time: [moment(item.start_time, 'HH:mm:ss'), moment(item.end_time, 'HH:mm:ss')],
      type: item.type,
      b2b_id: item.b2b_id ? item.b2b_id : null,
      b2b_contact_id: item.b2b_contact_id ? item.b2b_contact_id : null,
      branch_id: item.branch_id ? item.branch_id : null,
      employee_id: item.employee_id ? item.employee_id : null,
    })
    
    setEditModal(true)
  }

  const [queues, setQueues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  async function getQueue(){
    setIsLoading(true)
    const params = {
      year: selectedYear,
      month: (selectedMonth + 1)
    }
    dispatch(TechnicianQueueList(params)).then(response => {
      setQueues(response.data.data)
      setIsLoading(false)
    }).catch( e => {

    })
  }

  const [jobs, setJobs] = useState([]);

  async function getJob(){
    const params = {
      status: 'all'
    }
    dispatch(JobList(params)).then(response => {
      setJobs(response.data.data);
    }).catch( e => {

    })
  }

  const [branches, setBranches] = useState([]);

  async function getBranch(){
    dispatch(Branch()).then(response => {
      setBranches(response.data.data);
    }).catch( e => {

    })
  }

  const [employees, setEmployees] = useState([]);

  async function getEmployee(){
    const params = {
      departments: ['ช่างกระจก', 'ช่างติดฟิล์ม']
    }
    dispatch(List(params)).then(response => {
      setEmployees(response.data.data);
    }).catch( e => {

    })
  }

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  
  async function getCustomer(){
    const params = {
      type: ['โชว์รูม']
    }

    dispatch(Customer(params)).then(response => {
      setCustomers(response.data.data);
    }).catch( e => {

    })
  }
  
  const [validated, setValidated] = useState(false);

  const AddQueue = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true)

    let formData = new FormData();
    if(form.job_id) formData.append('job_id', form.job_id)
    if(form.date) formData.append('date', form.date.format('YYYY-MM-DD'))
    if(form.time && form.time[0]) formData.append('start_time', moment(form.time[0], 'HH:mm').format('HH:mm'))
    if(form.time && form.time[1]) formData.append('end_time', moment(form.time[1], 'HH:mm').format('HH:mm'))
    if(form.type) formData.append('type', form.type)
    if(form.b2b_id) formData.append('b2b_id', form.b2b_id)
    if(form.b2b_contact_id) formData.append('b2b_contact_id', form.b2b_contact_id)
    if(form.branch_id) formData.append('branch_id', form.branch_id)
    if(form.employee_id) formData.append('employee_id', form.employee_id)
    
    dispatch(CreateTechnicianQueue(formData)).then(response => {
      getQueue()
      setForm({
        job_id: null,
        date: null,
        time: null,
        type: 'สาขา',
        b2b_id: null,
        b2b_contact_id: null
      })
      setSelectedCustomer(null)
      setSelectedContact(null)
      history.replace('/technician-queue')
      setAddModal(false)
    }).catch( e => {

    })
  }

  const EditQueue = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true)

    let formData = new FormData();
    if(formEdit.job_id) formData.append('job_id', formEdit.job_id)
    if(formEdit.date) formData.append('date', formEdit.date.format('YYYY-MM-DD'))
    if(formEdit.time && formEdit.time[0]) formData.append('start_time', moment(formEdit.time[0], 'HH:mm').format('HH:mm'))
    if(formEdit.time && formEdit.time[1]) formData.append('end_time', moment(formEdit.time[1], 'HH:mm').format('HH:mm'))
    if(formEdit.type) formData.append('type', formEdit.type)
    if(formEdit.b2b_id) formData.append('b2b_id', formEdit.b2b_id)
    if(formEdit.b2b_contact_id) formData.append('b2b_contact_id', formEdit.b2b_contact_id)
    if(formEdit.branch_id) formData.append('branch_id', formEdit.branch_id)
    if(formEdit.employee_id) formData.append('employee_id', formEdit.employee_id)
    formData.append("_method", "put");

    const form = {
      id: formEdit.id,
      formData
    }
    
    dispatch(UpdateTechnicianQueue(form)).then(response => {
      getQueue()
      setForm({
        job_id: null,
        date: null,
        time: null,
        type: 'สาขา',
        b2b_id: null,
        b2b_contact_id: null
      })
      setSelectedCustomer(null)
      setSelectedContact(null)
      setEditModal(false)
    }).catch( e => {

    })
  }

  const deleteQueue = () => {
    dispatch(DestroyTechnicianQueue(formEdit.id)).then(response => {
      setQueues(queues.filter(item => item.id !== formEdit.id))
      setEditModal(false)
    }).catch( e => {

    })
  }

  const [dayData, setDayData] = useState(yearLoop);
  const [before, setBefore] = useState(0);
  useEffect(() => {

    getQueue()

    const firstDayInMonth = dayjs().year(selectedYear).month(selectedMonth).date(1).get('day')
    setBefore(previousMonth(firstDayInMonth))

    let tempData = []
    const daysInMonth = dayjs().year(selectedYear).month(selectedMonth).daysInMonth()

    for (let index = 1; index <= daysInMonth; index++) {
      let temp = dayjs().year(selectedYear).month(selectedMonth).date(index)
      tempData.push(
        {
          day: temp.date(),
          name: days[temp.day()],
          date: temp.format('YYYY-MM-DD'),
          dayjs: temp
        }
      )
    }

    setDayData(tempData)
  }, [selectedYear, selectedMonth]);

  useEffect(async () => {
    getEmployee()
    getCustomer()
    getBranch()
    getJob()
    getQueue()
    if (jobId) {
      setForm({...form, job_id: jobId, date: moment()})
      setAddModal(true)
    }
  }, []);

  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={FilmImage} />
                  <b>คิวช่างกระจก</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={FilmImage} />
                  <b>คิวช่างกระจก</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>

                <Row className="g-0 justify-content-end">
                  <Col sm={12} md={4} lg={4}>
                    <Button type="button" className="button my-2" onClick={() => openAddModal() }>
                      <img src={AddIcon} className="icon-button" /> &nbsp;
                      เพิ่มรายการ
                    </Button>
                  </Col>
                </Row>

                <Row className="g-0 justify-content-start">
                  <Col>
                    <div className='custom-calendar-year'>
                      <p className='left-arrow' onClick={() => setSelectedYear(selectedYear - 1)}>{'<'}</p>
                      <p>{ selectedYear }</p>
                      <p className='right-arrow' onClick={() => setSelectedYear(selectedYear + 1)}>{'>'}</p>
                    </div>
                    <div className='custom-calendar-month'>
                      <p className='left-arrow' onClick={() => ChangeMonth('previous')}>{'<'}</p>
                      <p>{ months[selectedMonth] }</p>
                      <p className='right-arrow' onClick={() => ChangeMonth('next')}>{'>'}</p>
                    </div>
                    <div className='custom-calendar'>
                      <div>จันทร์</div>
                      <div>อังคาร</div>
                      <div>พุธ</div>
                      <div>พฤหัสบดี</div>
                      <div>ศุกร์</div>
                      <div>เสาร์</div>
                      <div>อาทิตย์</div>
                    </div>
                    {
                      !isLoading 
                      ?
                        <div className='custom-calendar-day'>
                          {
                            [...Array(before)]?.map((day, key) => {
                              return (
                                <>
                                  <div className='group-day'>
                                    <div className='day'></div>
                                    <div className='event'></div>
                                  </div>
                                </>
                              )
                            })
                          }
                          {
                            dayData?.map(item => {
                              return (
                                <>
                                  <div id={`group-day-${item.day}`} data-item-date={item.date} className={`group-day ${isToday(item.dayjs)}`} onDrop={ (e) => drop(e, `group-day-${item.day}`) } onDragOver={ (e) => allowDrop(e, `group-day-${item.day}`) } onDragLeave={ (e) => exitDrop(e, `group-day-${item.day}`) } onDragEnd={ (e) => DragEnd(e, `group-day-${item.day}`) }>
                                    <div className='day'>
                                      <div className='left-button'>
                                        <div className='number'>{item.day}</div>
                                        <img src={Increase} className='add-icon' onClick={() => openAddModal(item) }/>
                                      </div>
                                      <img src={Setting} className='add-icon' onClick={() => history.push(`/technician-queue/${item.dayjs.format('YYYY-MM-DD')}/detail`) }/>
                                    </div>
                                    <div className='event'>
                                      {
                                        getDataByDay(item.dayjs)?.slice(0, 3).map((queue, key)=> {
                                          return (
                                            <div id={`day-${item.day}-item-${key}`} data-item-id={queue.id} className='content' draggable onDragStart={ (e) => drag(e) } onClick={ () => openEditModal(queue) }>{queue.employee?.fullname}</div>
                                          )
                                        })
                                      }
                                      {
                                        getDataByDay(item.dayjs)?.length > 3 &&
                                        <div className='content more' onClick={() => history.push(`/technician-queue/${item.dayjs.format('YYYY-MM-DD')}/detail`)}>เพิ่มเติม { getDataByDay(item.dayjs)?.length - 3 } รายการ</div>
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      :
                        <div style={{ backgroundColor: 'white' }}>
                          <div className="spinner-border m-4" style={{ width: '3rem', height: '3rem', 'color': '#682c80' }} role="status"></div>
                        </div>
                    }

                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        className="modal-style"
        centered
        show={addModal}
        onHide={ () => setAddModal(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>เพิ่มคิวช่างกระจก</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => {setAddModal(false);history.replace('/technician-queue');} }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={validated} onSubmit={AddQueue}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '400px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <Select
                  placeholder="เลขที่ Job"
                  className='my-1'
                  options={jobs}
                  getOptionLabel={(option) => option.job_code}
                  getOptionValue={(option) => option.id}
                  value={form.job_id ? jobs.find( item => item.id === Number(form.job_id)) : null}
                  onChange={(j) => setForm({ ...form, job_id: j.id }) }
                />

                <DatePicker value={form.date} className='my-1' onChange={(date) => setForm({...form, date: date } ) } />

                <TimePicker.RangePicker className='my-1' disabledTime={disabledDateTime} format={'HH:mm'} onChange={(time) => setForm({...form, time: time } ) } />

                <Select
                  placeholder="ช่าง"
                  className='my-1'
                  options={employees}
                  getOptionLabel={(option) => option.fullname}
                  getOptionValue={(option) => option.id}
                  value={selectedEmployee && selectedEmployee}
                  onChange={(employee) => { setForm({ ...form, employee_id: employee.id });setSelectedEmployee(employee); } }
                />

                <div className='d-flex justify-content-around'>
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-1'
                    id={`radio-type`}
                    label={`สาขา`}
                    checked={form.type === "สาขา"}
                    value='สาขา'
                    onChange={(e) => {setForm({...form, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                  
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-2'
                    id={`radio-type`}
                    label={`โชว์รูม`}
                    value='โชว์รูม'
                    checked={form.type === "โชว์รูม"}
                    onChange={(e) => {setForm({...form, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                </div>

                {
                  form.type === "สาขา" &&
                  <Select
                    placeholder="สาขา"
                    className='my-1'
                    options={branches}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={form.branch_id ? branches.find((item) => {return item.id === form.branch_id}) : null}
                    onChange={(b) => setForm({ ...form, branch_id: b.id }) }
                  />
                }
              
                {
                  form.type === "โชว์รูม" &&
                  <>
                    <Select
                      placeholder="B2B"
                      className='my-1'
                      options={customers}
                      getOptionLabel={(option) => option.organization}
                      getOptionValue={(option) => option.id}
                      value={selectedCustomer && selectedCustomer}
                      onChange={(b2b) => { setForm({ ...form, b2b_id: b2b.id, b2b_contact_id: null });setSelectedCustomer(b2b);setSelectedContact(null); } }
                    />
                    <Select
                      placeholder="พนักงานโชว์รูม"
                      className='my-1'
                      options={selectedCustomer?.showroom_contacts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isDisabled={!form.b2b_id}
                      value={selectedContact && selectedContact}
                      onChange={(contact) => {setForm({ ...form, b2b_contact_id: contact.id });setSelectedContact(contact);} }
                    />
                  </>
                }
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => {setAddModal(false);history.replace('/technician-queue');} }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={editModal}
        onHide={ () => setEditModal(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>แก้ไขคิวช่างกระจก</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setEditModal(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Row className="g-0 justify-content-start">
            <Col sm={12} md={4} lg={4}>
              <Button type="button" className="button my-2" onClick={() => deleteQueue() }>
                <img src={DeleteIcon} className="icon-button" /> &nbsp;
                ลบ
              </Button>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={EditQueue}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '400px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <Select
                  placeholder="เลขที่ Job"
                  className='my-1'
                  options={jobs}
                  getOptionLabel={(option) => option.job_code}
                  getOptionValue={(option) => option.id}
                  value={formEdit.job_id ? jobs.find((item) => {return item.id === formEdit.job_id}) : null}
                  onChange={(j) => setFormEdit({ ...formEdit, job_id: j.id }) }
                />

                <DatePicker value={formEdit.date} className='my-1' onChange={(date) => setFormEdit({...formEdit, date: date } ) } />

                <TimePicker.RangePicker className='my-1' disabledTime={disabledDateTime} defaultValue={formEdit.time} format={'HH:mm'} onChange={(time) => setFormEdit({...formEdit, time: [moment(time[0], 'HH:mm'), moment(time[1], 'HH:mm')] } ) } />
                
                <Select
                  placeholder="ช่าง"
                  className='my-1'
                  options={employees}
                  getOptionLabel={(option) => option.fullname}
                  getOptionValue={(option) => option.id}
                  value={formEdit.employee_id ? employees.find(item => item.id === formEdit.employee_id) : null}
                  onChange={(employee) => { setFormEdit({ ...formEdit, employee_id: employee.id });setSelectedEmployee(employee); } }
                />

                <div className='d-flex justify-content-around'>
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-1'
                    id={`radio-type`}
                    label={`สาขา`}
                    checked={formEdit.type === "สาขา"}
                    value='สาขา'
                    onChange={(e) => {setFormEdit({...formEdit, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                  
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-2'
                    id={`radio-type`}
                    label={`โชว์รูม`}
                    value='โชว์รูม'
                    checked={formEdit.type === "โชว์รูม"}
                    onChange={(e) => {setFormEdit({...formEdit, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                </div>

                {
                  formEdit.type === "สาขา" &&
                  <Select
                    placeholder="สาขา"
                    className='my-1'
                    options={branches}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={formEdit.branch_id ? branches.find((item) => {return item.id === formEdit.branch_id}) : null}
                    onChange={(b) => setFormEdit({ ...formEdit, branch_id: b.id }) }
                  />
                }
              
                {
                  formEdit.type === "โชว์รูม" &&
                  <>
                    <Select
                      placeholder="B2B"
                      className='my-1'
                      options={customers}
                      getOptionLabel={(option) => option.organization}
                      getOptionValue={(option) => option.id}
                      value={formEdit.b2b_id ? customers.find((item) => {return item.id === formEdit.b2b_id}) : null}
                      onChange={(b2b) => { setFormEdit({ ...formEdit, b2b_id: b2b.id, b2b_contact_id: null });setSelectedCustomer(b2b);setSelectedContact(null); } }
                    />
                    <Select
                      placeholder="พนักงานโชว์รูม"
                      className='my-1'
                      options={selectedCustomer?.showroom_contacts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isDisabled={!formEdit.b2b_id}
                      value={formEdit.b2b_contact_id ? selectedCustomer?.showroom_contacts.find((item) => {return item.id === formEdit.b2b_contact_id}) : null}
                      onChange={(contact) => {setFormEdit({ ...formEdit, b2b_contact_id: contact.id });setSelectedContact(contact);} }
                    />
                  </>
                }
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setEditModal(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
