import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "./image/logo.png";

import 'Setting/PreviewBill/Component/css/bill-8.css';
import dayjs from 'dayjs'
import moment from 'moment'

export class Bill8 extends React.PureComponent {
  render() {
    const { Job,Data,PayerForm } = this.props

    // console.log('Job', Job)
    // console.log('Data',Data)
    // console.log('PayerForm',PayerForm);
    const displayPhone = (phone,form) => {
      // return 'tes'
      if(phone && Array.isArray(phone)){
        if(phone && Array.isArray(phone) === true){
          return phone.join(', ')
        }else{
          return phone
        }
      }else if(phone && Array.isArray(JSON.parse(phone))){
        if(phone && Array.isArray(JSON.parse(phone))) return JSON.parse(phone).join(', ')
      }else{
        return phone
      }
    }

    function displayTagPackage(data,form) {
        switch (data.package_type.name) {
          case 'บริการฟิล์มกรองแสง':
              return (
                <>
                  <p className='m-0'>{data.package.name}</p>
                  {
                    getTextDetails(data.text_details) &&
                    <p className='m-0'>{getTextDetails(data.text_details)}</p>
                  }
                  <p className='m-0'>
                    { `รุ่น` + data.package_sizes_model } { ` รหัสรุ่น ${data.package_sizes_model_code}`}
                  </p>
                </>
              )
            break;
          case 'บริการฟิล์มกันรอย' :
            let position = null
            if(JSON.parse(data.position_details)){
              position = JSON.parse(data.position_details).map((item) => {
                return item.label
              })
            }
            if(form){
              switch (form) {
                case 'ฟิล์มกันรอย':
                  const index = position.indexOf("บานหน้า");
                    if (index > -1) { // only splice array when item is found
                      position.splice(index, 1); // 2nd parameter means remove one item only
                    }
                  if(position.length > 0){
                    return (
                        <>
                          <p className='m-0'>{data.package.name}</p>
                          { getTextDetails(data.text_details) }
                          <p className='m-0'>
                            { `รุ่น` + data.package_sizes_model } { ` รหัสรุ่น ${data.package_sizes_model_code}`}
                          </p>
                          { position ? position.join(",") : '' }
                        </>
                    )
                  }else{
                    return '' 
                  }
                  break;
                case 'ฟิล์มนิรภัยกระจก':
                    if(position.includes('บานหน้า') === true){
                      return (
                          <>
                            <p className='m-0'>{data.package.name}</p>
                            { getTextDetails(data.text_details) }
                            <p className='m-0'>
                              { `รุ่น` + data.package_sizes_model } { ` รหัสรุ่น ${data.package_sizes_model_code}`}
                            </p>
                            { 'บานหน้า' }
                          </>
                      )
                    }else{
                      return ''
                    }
                  break;
              
                default:
                  break;
              }
            }
            
            break;
          case 'บริการเคลือบเฉพาะจุด' : 
              console.log('data',data);
              return (
                <>
                  <p className='m-0'>{data.package.name}</p>
                    { getTextDetails(data.text_details) }
                    { getDisplayTextPositionCoating(data) }
                    { getDisplayTextPositionUse(data) }
                </>
              )
            break;
          default:
            break;
          
        }
    }

    function getTextByPackage(item){
      // console.log('item',item);
      switch (item.PackageTypeName) {
        case 'บริการล้างและเคลือบแว๊กซ์':
            return ''
        case 'บริการฟิล์มกรองแสง':
            return ''
        case 'บริการเคลือบเหมา':
          return ''
        case 'บริการเคลือบเฉพาะจุด':
          return ''
        case 'บริการฟิล์มกันรอย':
          let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
          return set_text
        case 'แพ็คเกจพิเศษ':
          return ''
      
      }
    }
  
    function  getDisplayTextPositionCoating(item) {
      if(item?.job_coating_point !== null){
        const result = item?.job_coating_point.map((item) => {
          return item.package_product.size 
        }).join(", ")
        return result ? 'บริเวณ :' + result : ''
      }else{
        return ''
      }
    }
  
    function getDisplayTextPositionUse(item){

      if(JSON.parse(item.position_details) !== null){
        const result = JSON.parse(item.position_details).map((item) => {
          return item.label 
        }).join(", ")
        
        return result ? 'บริเวณ : ' + result : ''
      }else{
        return ''
      }
      
    }

    function getTextDetails(text){
      return text ? <p className='m-0'>{JSON.parse(text)}</p> : ''
    }

    return (
      <div id="bill-8" className='bill-8'>
        <div className='bill-8-field'>
          <div className='half-top'>
            <p className='half-type'>ต้นฉบับ</p>
            <div className='header-group'>
              <div className='location'>
                <label>สถานที่ติดตั้ง</label>
                <p>{`${Job?.branch?.name} ${Job?.branch?.address}`}</p>
                <p>วันที่ติดตั้ง: {`${dayjs(Job?.created_at).format('DD/MM/YYYY')}`}</p>
                <p>เวลาติดตั้ง: {`${dayjs(Job?.created_at).format('HH:mm')}`}</p>
              </div>
              
              <div className='car-info'>
                <label>ข้อมูลรถลูกค้า</label>
                <p>ยี่ห้อรถ : { Job?.car_owner?.car_serie?.car_model?.car_brand?.name }</p>
                <p>ทะเบียนรถ : { Job?.car_owner?.license_plate }</p>
                <p>เลขตัวถัง : { Job?.car_owner?.chassis_number }</p>
                <p>ประเภทรถ : { Job?.car_owner?.car_type?.name}</p>
                <p>ฟิล์มรถ :</p>
              </div>

              <div className='car-info-per'>
                <label>&nbsp;</label>
                <p>รุ่น : { Job?.car_owner?.car_serie?.car_model?.name }</p>
                <p>สี : { Job?.car_owner?.color }</p>
              </div>

              <div className='flag'>
                <img src={LogoImage} />
                <div className='label'>MRK Order film</div>
                <p style={{ marginTop: '5px' }}>วันที่ออกเอกสาร : {`${dayjs(Job?.created_at).format('DD/MM/YYYY')}`}</p>
                <p>เวลาออกเอกสาร : {`${dayjs(Job?.created_at).format('HH:mm')}`}</p>
              </div>
            </div>

            <div className='body-group'>
              <label>รายการติดตั้ง</label>
              <div className='table-header'>
                <div className='title'>ฟิล์มกรองแสง</div>
                <div className='title'>ฟิล์มกันรอย</div>
                <div className='title'>ฟิล์มนิรภัยกระจกหน้า</div>
                <div className='title'>เคลือบเหมา/เคลือบเฉพาะจุด</div>
              </div>
              <div className='table-body'>
                {
                  Job?.job_details.map((e, i) => {
                    return(
                      <div className='table-field'>
                        <div className='highlight'>
                          {
                            e?.package_type?.name === 'บริการฟิล์มกรองแสง' ? displayTagPackage(e) : ''
                          }
                        </div>
                        <div className='highlight'>{e?.package_type?.name === 'บริการฟิล์มกันรอย' ? displayTagPackage(e,'ฟิล์มกันรอย') : ''}</div>
                        <div>{e?.package_type?.name === 'บริการฟิล์มกันรอย' ? displayTagPackage(e,'ฟิล์มนิรภัยกระจก') : ''}</div>
                        <div>{e?.package_type?.name === 'บริการเคลือบเฉพาะจุด' ? displayTagPackage(e) : ''}</div>
                      </div>
                    ) 
                  })
                }
              </div>
            </div>
            <div className='footer-group'>

              <div className='customer-info'>
                <div className='box'>
                  <p>อื่น : { Job?.insite_note }</p>
                </div>
              </div>

              <div className='customer-info'>
                <p>ชื่อลูกค้า : 
                  {
                    Job?.customer?.type === 'นิติบุคคล' &&
                    `${Job?.customer?.organization}`
                  }
                  {
                    Job?.customer?.type === 'บุคคลธรรมดา' &&
                    ` คุณ ${Job?.customer?.first_name} ${Job?.customer?.last_name}`
                  }
                </p>
                <p>ที่อยู่ : { Job?.customer?.address }</p>
                <p>เบอร์โทร : { displayPhone(Job?.customer?.phone) }</p>
                <p>วันที่นัดรับ : {`${ Job && Job.job_assgin ? dayjs(Job?.job_assgin?.pickup_date).format('DD/MM/YYYY') : ''}`}</p>
                <p>เวลานัดรับ : {`${ Job && Job.job_assgin ? moment(`${Job?.job_assgin?.pickup_hours}:${Job?.job_assgin?.pickup_minutes}`, 'HH:mm').format('HH:mm') : ''}`}</p>
              </div>

              <div className='price'>
                <div className='inline'>
                  <p>ราคา</p>
                  <p>{Data?.SumTotalAfterVat }</p>
                  <p>บาท</p>
                </div>
                <div className='inline'>
                  <p>ใบสั่ง</p>
                  <p>{Data?.OrderText}</p>
                  <p>บาท</p>
                </div>
                <div className='inline'>
                  <p>จ่ายเอง</p>
                  <p>{Data?.OrderPay}</p>
                  <p>บาท</p>
                </div>

                <div className='signature'>
                  <div className='employee'>ลงลายมือชื่อพนักงาน</div>
                  {/* <div className='sale'>ลงชื่อ Sale</div> */}
                  <div className='car-owner'>ลงลายมือชื่อเจ้าของรถ</div>
                </div>
              </div>
              </div>
              <div className='footer-group'>
              <div className='customer-info'>
                {/* null */}
              </div>
                
              <div className='note'>
                <div className='box'>
                  <p>หมายเหตุ : { PayerForm?.Note }</p>
                </div>
              </div>
              <div className='price'>
                  {/* null */}
              </div>
              </div>
          </div>

          <div className='half-bottom'>
            <p className='half-type'>สำเนา</p>
            <div className='header-group'>
              <div className='location'>
                <label>สถานที่ติดตั้ง</label>
                <p>{`${Job?.branch?.name} ${Job?.branch?.address}`}</p>
                <p>วันที่ติดตั้ง: {`${dayjs(Job?.created_at).format('DD/MM/YYYY')}`}</p>
                <p>เวลาติดตั้ง: {`${dayjs(Job?.created_at).format('HH:mm')}`}</p>
              </div>
              
              <div className='car-info'>
                <label>ข้อมูลรถลูกค้า</label>
                <p>ยี่ห้อรถ : { Job?.car_owner?.car_serie?.car_model?.car_brand?.name }</p>
                <p>ทะเบียนรถ : { Job?.car_owner?.license_plate }</p>
                <p>เลขตัวถัง : { Job?.car_owner?.chassis_number }</p>
                <p>ประเภทรถ : { Job?.car_owner?.car_type?.name}</p>
                <p>ฟิล์มรถ :</p>
              </div>

              <div className='car-info-per'>
                <label>&nbsp;</label>
                <p>รุ่น : { Job?.car_owner?.car_serie?.car_model?.name }</p>
                <p>สี : { Job?.car_owner?.color }</p>
              </div>

              <div className='flag'>
                <img src={LogoImage} />
                <div className='label'>MRK Order film</div>
                <p style={{ marginTop: '5px' }}>วันที่ออกเอกสาร : {`${dayjs(Job?.created_at).format('DD/MM/YYYY')}`}</p>
                <p>เวลาออกเอกสาร : {`${dayjs(Job?.created_at).format('HH:mm')}`}</p>
              </div>
            </div>
            
            <div className='body-group'>
              <label>รายการติดตั้ง</label>
              <div className='table-header'>
                <div className='title'>ฟิล์มกรองแสง</div>
                <div className='title'>ฟิล์มกันรอย</div>
                <div className='title'>ฟิล์มนิรภัยกระจกหน้า</div>
                <div className='title'>เคลือบเหมา/เคลือบเฉพาะจุด</div>
              </div>
              <div className='table-body'>
                {
                  Job?.job_details.map((e, i) => {
                    return(
                      <div className='table-field'>
                        <div className='highlight'>{e?.package_type?.name === 'บริการฟิล์มกรองแสง' ? displayTagPackage(e) : ''}</div>
                        <div className='highlight'>{e?.package_type?.name === 'บริการฟิล์มกันรอย' ? displayTagPackage(e,'ฟิล์มกันรอย') : ''}</div>
                        <div>{e?.package_type?.name === 'บริการฟิล์มกันรอย' ? displayTagPackage(e,'ฟิล์มนิรภัยกระจก') : ''}</div>
                        <div>{e?.package_type?.name === 'บริการเคลือบเฉพาะจุด' ? displayTagPackage(e) : ''}</div>
                      </div>
                    ) 
                  })
                }
              </div>
            </div>

            <div className='footer-group'>

              <div className='customer-info'>
                <div className='box'>
                  <p>อื่น : { Job?.insite_note }</p>
                </div>
              </div>

              <div className='customer-info'>
                <p>ชื่อลูกค้า : 
                  {
                    Job?.customer?.type === 'นิติบุคคล' &&
                    `${Job?.customer?.organization}`
                  }
                  {
                    Job?.customer?.type === 'บุคคลธรรมดา' &&
                    ` คุณ ${Job?.customer?.first_name} ${Job?.customer?.last_name}`
                  }
                </p>
                <p>ที่อยู่ : { Job?.customer?.address }</p>
                <p>เบอร์โทร : { displayPhone(Job?.customer?.phone) }</p>
                <p>วันที่นัดรับ : {`${ Job && Job.job_assgin ? dayjs(Job?.job_assgin?.pickup_date).format('DD/MM/YYYY') : ''}`}</p>
                <p>เวลานัดรับ : {`${ Job && Job.job_assgin ? moment(`${Job?.job_assgin?.pickup_hours}:${Job?.job_assgin?.pickup_minutes}`, 'HH:mm').format('HH:mm') : ''}`}</p>
              </div>

              <div className='price'>
                <div className='inline'>
                  <p>ราคา</p>
                  <p>{Data?.SumTotalAfterVat}</p>
                  <p>บาท</p>
                </div>
                <div className='inline'>
                  <p>ใบสั่ง</p>
                  <p>{Data?.OrderText}</p>
                  <p>บาท</p>
                </div>
                <div className='inline'>
                  <p>จ่ายเอง</p>
                  <p>{Data?.OrderPay}</p>
                  <p>บาท</p>
                </div>

                <div className='signature'>
                  <div className='employee'>ลงลายมือชื่อพนักงาน</div>
                  {/* <div className='sale'>ลงชื่อ Sale</div> */}
                  <div className='car-owner'>ลงลายมือชื่อเจ้าของรถ</div>
                </div>
              </div>
            </div>
            <div className='footer-group'>
              <div className='customer-info'>
                {/* null */}
              </div>
                
              <div className='note'>
                <div className='box'>
                  <p>หมายเหตุ : { PayerForm?.Note }</p>
                </div>
              </div>
              <div className='price'>
                  {/* null */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}