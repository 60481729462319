import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router ,
  Switch,
  Route,
  HashRouter
} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import axios from 'axios'
import { store } from 'store';
import { createBrowserHistory } from 'history';
import httpService from './interceptor';

const history = createBrowserHistory();
httpService.setupInterceptors(store, history);

// axios.defaults.crossDomain = true;
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
axios.defaults.headers.post['Content-Type'] = 'application/json';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
