import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert
} from 'react-bootstrap';
import { Switch } from 'antd';
import Select, { components } from 'react-select';

// Custom Components
import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';

// Icon
import Back from 'assets/icon/back.png';
import Management from 'assets/icon/management.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/getout.png';

// css
import 'assets/css/app.css';
import 'assets/css/validate.css';
import 'assets/css2/css/app.css';
import 'assets/css2/css/modal.css';
import 'assets/css2/css/employee.css';
import 'assets/css2/css/responsive.css';
import 'assets/css2/css/input.css';
import 'assets/css2/css/table.css';
import 'assets/css2/css/mr.kleane.css';


// API
import { CreateProduct } from 'actions/product';
import { CategoryList } from 'actions/settings/category';
import { UnitList } from 'actions/settings/unit';
import { loadAuth } from 'actions/auth';

import dayjs from 'dayjs';

export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();

  const ProductType = [
    {
      label: 'ขาย',
      value: 'ขาย'
    },
    {
      label: 'ใช้งาน',
      value: 'ใช้งาน'
    },
    {
      label: 'ขายและใช้งาน',
      value: 'ขายและใช้งาน'
    }
  ]

  const model = {
    type: null,
    price: null,
    product_category_id: null,
    code: null,
    name: null,
    unit_id: null,
    sales: [],
    notification: false,
    reorder_point: null,
    minimum_quantity: null,
    note: null
  }

  const [Data, setData] = useState(model);
  const [ProductCategories, setProductCategories] = useState([]);
  const [Units, setUnits] = useState([]);
  const [Sales, setSales] = useState([]);
  const [validated, setValidated] = useState(false);
  const [Errors, setErrors] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    }
    
    let formData = new FormData();
    if(Data.type) formData.append('type', Data.type);
    if(Data.price) formData.append('price', Data.price);
    if(Data.product_category_id) formData.append('product_category_id', Data.product_category_id);
    if(Data.code) formData.append('code', Data.code);
    if(Data.name) formData.append('name', Data.name);
    if(Data.unit_id) formData.append('unit_id', Data.unit_id);
    if(Data.notification) formData.append('notification', Data.notification);
    if(Data.reorder_point) formData.append('reorder_point', Data.reorder_point);
    if(Data.minimum_quantity) formData.append('minimum_quantity', Data.minimum_quantity);
    if(Data.note) formData.append('note', Data.note);

    Data.sales.forEach((item,key) => {
      if(item.price) formData.append(`sales[${item.unit_sale_id}][price]`, item.price);
    });

    dispatch(CreateProduct(formData)).then(response => {
      setErrors([])
      setValidated(false);
      history.push('/product');
    }).catch(e => {
      let errors = []
      if (e.response && e.response.data && e.response.data.messages) {
        e.response.data.messages.forEach(element => {
          errors.push(element)
        });
        setErrors(errors)
      }
    })
  };

  const GetCategory = async () => {
    try {
      const response = await dispatch(CategoryList());
      if (response) {
        setProductCategories(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetUnit = async (data) => {
    try {
      const response = await dispatch(UnitList());
      if (response) {
        setUnits(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const InsertSales = (data) => {
    const value = {
      key: Data.sales.length,
      unit_sale_id: data.id,
      name: data.name_sale,
      price: null
    }

    const exist = Data.sales.find(item => item.unit_sale_id === data.id)
    if (!exist) {
      setData({...Data, sales: [...Data.sales, value] })
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        // name: ['จัดการวัสดุ/อุปกรณ์'],
        name: ['จัดการสินค้า'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/product');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  function generateCode(){
    let gcode = dayjs().format('YYMMDDHHmmss');
    setData({...Data, code: gcode})
    return gcode;
  }

  useEffect(() => {
    GetCategory();
    GetUnit();
    getAuth();
  }, []);

  function close() {
    history.push(`/`);
  }

  function exit() {
    history.push(`/product`);
  }
  
  function addCommas(x) {
    return parseInt(x.replaceAll(',', '')).toLocaleString()
  }
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div>
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={Management} />
                    <b>
                      <Link to={`/product`} className='mr-2'>วัสดุอุปกรณ์(สินค้า)</Link>
                      &gt; เพิ่ม
                    </b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={Management} />
                    <b>
                      <Link to={`/user`} className='mr-2'>วัสดุ/อุปกรณ์</Link>
                      &gt; เพิ่ม
                    </b>
                  </h5>
                </div>
                <div className="modal-body set-menu-productmanage">
                  <Row className="g-0 tabsicon justify-content-center ">
                    <TabsIcon />
                  </Row>
                  {
                    Errors.length > 0 &&
                    <Alert variant="danger">
                      <Alert.Heading>เกิดข้อผิดพลาด !</Alert.Heading>
                      <div className='text-left'>
                      {
                        Errors.map((error, key) => {
                          return(
                            <p>{error}</p>
                          )
                        })
                      }
                      </div>
                    </Alert>
                  }
                  <Row className="g-0 justify-content-center mb-3 pb-3 border-bottom">
                    
                  </Row>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                      <Col className="mt-3 text-left">
                        <Form.Label>
                          <b>รายละเอียดวัสดุ/อุปกรณ์</b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0  mt-2 mb-2">
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>ประเภทวัสดุ/อุปกรณ์</Form.Label>
                          </Col>
                          <Col>
                            <Select
                              placeholder="ประเภทวัสดุ/อุปกรณ์"
                              options={ProductType}
                              value={Data.type ? ProductType.find((item) => item.label === Data.type) : null}
                              onChange={(data) => setData({...Data, type: data.label})}
                              className={`select-list ` + (!Data.type && validated ? 'validate-fail' : '') }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            {/* <Form.Label>ราคา</Form.Label> */}
                          </Col>
                          <Col>
                            {/* <Form.Control
                              type="number"
                              value={Data.price ? Data.price : ''}
                              required
                              onChange={(e) => setData({...Data, price: e.target.value})}
                            ></Form.Control> */}
                          </Col>
                          <Col xs={1} className="text-left">
                            {/* <Form.Label>บาท</Form.Label> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0 mt-2 mb-2">
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>หมวดหมู่</Form.Label>
                          </Col>
                          <Col>
                            <Select
                              options={ProductCategories}
                              placeholder="หมวดหมู่"
                              isClearable={false}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              isDisabled={ProductCategories.length === 0}
                              value={Data.product_category_id ? ProductCategories.find((item) => item.id === Data.product_category_id) : null }
                              onChange={(data) => setData({...Data, product_category_id: data.id})}
                              className={`select-list ` + (!Data.product_category_id && validated ? 'validate-fail' : '') }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>รหัสวัสดุ/อุปกรณ์</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              placeholder="รหัสวัสดุ/อุปกรณ์"
                              value={Data.code ? Data.code : generateCode()}
                              onChange={(e) => setData({...Data, code: e.target.value})}
                              required
                            ></Form.Control>
                          </Col>
                          <Col xs={1}>
                            <Form.Label></Form.Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0 mt-2 mb-2 pb-4 border-bottom">
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>ชื่อวัสดุ/อุปกรณ์</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              placeholder="ชื่อวัสดุ/อุปกรณ์"
                              value={Data.name ? Data.name : ''}
                              onChange={(e) => setData({...Data, name: e.target.value})}
                              required
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mt-2 mb-2"></Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 text-left">
                        <Form.Label>
                          <b>ข้อมูลการสั่ง</b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0 mt-2 mb-2">
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>หน่วยซื้อ</Form.Label>
                          </Col>
                          <Col>
                            <Select
                              options={Units}
                              placeholder="หน่วยซื้อ"
                              // getOptionLabel={option => `${option.value_buy} ${option.name_buy}`}
                              getOptionLabel={option => `${option.name_buy}`}
                              getOptionValue={option => option.id}
                              isDisabled={ProductCategories.length === 0}
                              onChange={(data) => {setData({...Data, unit_id: data.id }); setSales(data.sales)}}
                              className={`select-list ` + (!Data.unit_id && validated ? 'validate-fail' : '') }
                            />
                          </Col>
                        </Row>

                        {
                          Data.unit_id &&
                          <Row className="align-items-center mt-4">
                            <Col xs={4} className="text-left">
                              <Form.Label>หน่วยขาย</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Sales}
                                placeholder="หน่วยขาย"
                                // getOptionLabel={option => `${option.value_sale} ${option.name_sale}`}
                                getOptionLabel={option => `${option.name_sale}`}
                                getOptionValue={option => option.id}
                                isDisabled={Sales.length === 0}
                                onChange={(data) => InsertSales(data)}
                                className={`select-list ` + (!Data.unit_id && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        }

                        {
                          Data.sales?.map((sale, key) => {
                          return(
                            <Row className="align-items-center mt-4">
                              <Col xs={4} className="text-left">
                                <Form.Label>{ `- ${sale.name}` }</Form.Label>
                              </Col>

                              <Col className="text-left">
                                <Form.Control
                                  type="text"
                                  value={sale.price ? addCommas(sale.price) : ''}
                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                  onChange={
                                    (e) => setData(prevState => ({...Data,
                                      sales: prevState.sales.map(
                                        el => el.key === key ? { ...el, price: e.target.value.replaceAll(',', '') }: el
                                      )
                                    })) 
                                  }
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  required
                                ></Form.Control>
                              </Col>

                              <Col className="text-left">
                                <Form.Label>บาท</Form.Label>
                              </Col>
                            </Row>
                          )})
                        }
                      </Col>
                      <Col lg={6} className="m-0"></Col>
                    </Row>

                    <Row>
                      <Col className="mt-3 text-left">
                        <Row className="align-items-center justify-content-start">
                          <Col>
                            <Form.Label>
                              <b>แจ้งเตือนจำนวนวัสดุ/อุปกรณ์</b>
                            </Form.Label>
                          </Col>
                          <Col>
                            <Switch 
                              checked={Data.notification}
                              onChange={(checked) => setData({...Data, notification: checked })}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row 
                      className="g-0 justify-content-center m-0 mt-2 mb-2" 
                    >
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>จุดสั่งซื้อ</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="จำนวน"
                              value={Data.reorder_point ? addCommas(Data.reorder_point) : 0}
                              required={Data.notification}
                              onChange={(e) => setData({...Data, reorder_point: e.target.value.replaceAll(',', '') })}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={Data.notification ? false : true}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>จุดต่ำสุด</Form.Label>
                          </Col>
                          <Col>
                              {/* {addCommas(Data.minimum_quantity)} */}
                            <Form.Control
                              type="text"
                              placeholder="จำนวน"
                              value={Data.minimum_quantity ? addCommas(Data.minimum_quantity) : 0}
                              required={Data.notification}
                              onChange={(e) => setData({...Data, minimum_quantity: e.target.value.replaceAll(',', '') })}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={Data.notification ? false : true}
                            ></Form.Control>
                          </Col>
                          <Col xs={1}>
                            <Form.Label></Form.Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0 mt-2 mb-2">
                      <Col lg={6} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col xs={4} className="text-left">
                            <Form.Label>หมายเหตุ</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              as="textarea"
                              value={Data.note ? Data.note : ''}
                              onChange={(e) => setData({...Data, note: e.target.value })}
                              placeholder="หมายเหตุ"
                              style={{ height: '100px' }}
                            />
                          </Col>
                          <Col xs={1}>
                            <Form.Label></Form.Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="m-0"></Col>
                    </Row>
                    <Row className="g-0 justify-content-center m-0 mt-5 mb-5">
                      <Col lg={2} className="mt-2 mb-2">
                        <Button type="submit" className="button">
                          <img src={Save} /> &nbsp; บันทึก
                        </Button>
                      </Col>
                      <Col lg={2} className="mt-2 mb-2">
                        <Button type="button" className="button" onClick={exit}>
                          <img src={Return} /> &nbsp; ออก
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}