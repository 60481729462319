// import { createStore, combineReducers, applyMiddleware } from 'redux';
// import {
//   Provider,
//   createStoreHook,
//   createDispatchHook,
//   createSelectorHook,
// } from 'react-redux'

// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { userLoginReducer } from './Reducers/userReducer';
// const reducer = combineReducers({
//   userLogin: userLoginReducer,
// });

// const userInfoFromStorage = localStorage.getItem('userInfo')
//   ? JSON.parse(localStorage.getItem('userInfo'))
//   : null;
  
// const initialState = {
//   userLogin: { userInfo: userInfoFromStorage },
// };

// const middleware = [thunk];

// const store = createStoreHook(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
// export default store;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'slice/userSlice'

export const store = configureStore({
  reducer:{
    user: userReducer
  }
})
