import axios, { post, put } from 'axios';

export const CategoryList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/product-categories`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateCategory = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/product-categories`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateCategory = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/product-categories/${id}`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyCategory = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/product-categories/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};