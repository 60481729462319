import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
  Dropdown,
} from 'react-bootstrap';
// import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import Back from 'assets/icon/back.png';
import Save from 'assets/icon/new-save.png';
import getout from 'assets/icon/getout.png';
import Search from 'assets/icon/search.png';
import View_Data from 'assets/icon/view-data.png';
import Return from 'assets/icon/return-value.png';
import Select, { components } from 'react-select';
import Arrow from 'assets/stock/adjust-stock.png';
import Document from 'assets/stock/cut-stock.png';
import StockImage from 'assets/icon/appwizard_list.png'
import 'assets/scss/style-component.scss';
// import Increase from 'assets/icon/increase.png';
import PurchaseOrder from 'assets/stock/purchase-order.png';
import MenuSend from 'assets/stock/accept-orders.png';
import Transfer from 'assets/stock/storefront-transfer.png';
import Order_Stock from '../../BranchSystems/assets/stock/order-stock.png';
//assets/stock/order-stock
import Request from 'assets/stock/delivery-request.png';
import { useDispatch, useSelector } from 'react-redux';

import MyPagination from 'Components/MyPagination';

// import DayJS from 'react-dayjs';
import dayjs from 'dayjs'

//API
import {LoadStockList,saveStorage,LoadNewStockCode} from 'actions/stock/index' 
import { UserList } from '../../actions/user/index'
import { loadAuth } from 'actions/auth';
import { List } from 'actions/employee';
import { UnitSale } from 'actions/settings/unit'; 


export default function Index() {
  const history = useHistory();
  const { employee } = useSelector( (state) => state.user)
  const dispatch = useDispatch();
  const [stock, setStock] = useState([]);
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);
  const [showCutStock, setShowCutStock] = useState(false);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: '', label: '' }]);
  const [validated, setValidated] = useState(false);
  const [Report, setReport] = useState(false);
  const handleCutStockClose = () => setShowCutStock(false); 
  const handleCutStockShow = () => setShowCutStock(true);

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const dateFormat = 'YYYY/MM/DD';

  const StorageModel = {
    key: 0,
    ProductId: null,
    TotalQuantity: 0,
    UnitName: null,
    Admit: true,
    TakeOut: false,
    Quantity: null,
    UnitType: null,
    UnitId: null,
    UnitSaleId: null,
    CountBy: null,
    Note: null,
    StockDate: null,
    reasonType: null,
  }
  const [Data,SetData] = useState({
      StorageModel
  });

  // const [modalStock, setModalStock] = useState([]); 

  const setModalCutStock = (item) => {
    SetData({
            ProductId: item.id,
            Code: item.code,
            Name: item.name,
            TotalQuantity: item.total_quantity,
            UnitName: item.unit && item.unit.name_buy,
            Admit: false,
            TakeOut: true,
            Quantity: null,
            UnitType: null,
            UnitId: null,
            UnitSaleId: null,
            CountBy: profile.id,
            Note: null,
            reasonType: null,
            StockDate: null,
            Type: 'cut_stock',
          });
  }

  const [unitSales, setUnitSales] = useState([]);
  async function getUnitSale(unit_id){
    setLoading(true)
    try {
      const params = {
        unit_id: unit_id,
      }
        const response = await dispatch(UnitSale(params));
        setUnitSales(response.data.data)
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }

  }
  
  const [showAdjustStock, setShowAdjustStock] = useState(false);
  const handleAdjustStockClose = () => setShowAdjustStock(false);
  const handleAdjustStockShow = () => setShowAdjustStock(true);

  const setModalAdjustStock = (item) => {
    SetData({
      ProductId: item.id,
      Code: item.code,
      Name: item.name,
      TotalQuantity: item.total_quantity,
      UnitName: item.unit && item.unit.name_buy,
      Admit: true,
      TakeOut: false,
      Quantity: null,
      UnitType: null,
      UnitId: null,
      UnitSaleId: null,
      CountBy: null,
      Note: null,
      reasonType: null,
      StockDate: null,
      Type:'adjust',
    });
  }

  const setModalTransfer = (item) => {
    SetData({
      ProductId: item.id,
      Code: item.code,
      Name: item.name,
      TotalQuantity: item.total_quantity,
      UnitName: item.unit && item.unit.name_buy,
      Admit: false,
      TakeOut: true,
      Quantity: null,
      UnitType: null,
      UnitId: null,
      UnitSaleId: null,
      CountBy: null,
      Note: null,
      reasonType: null,
      StockDate: null,
      Type:'transfer',
    });
  }

  const [statepage, setStatepage] = useState('');
  const [Page, setPage] = useState([
    { value: 0, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);
  const [unit, setUnit] = useState([
    { value: 'buy', label: 'ซื้อ' },
    { value: 'sale', label: 'ขาย' },
  ]);
  const [cause, setCause] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);
  const [causeAdjust, setCauseAdjust] = useState([
    { value: 'lost_product', label: 'สินค้าหาย' },
    { value: 'excess_product', label: 'สินค้าเกิน' },
    { value: 'other', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const handleSelectPage = (e) => setStatepage(e);
  function closeCutStock() {
    setShowCutStock(false);
  }
  function closeAdjustStock() {
    setShowAdjustStock(false);
  }
  function closeTransfer(){
    setShowTransfer(false);
  }
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function closePage() {
    let path = `/`;
    history.push(path);
  }

  const [search, setSearch] = useState({
    Keyword: '',
    Display: '',
    PerPage: 10,
    CurrentPage: 1,
    Row:0,
  });
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [button_Search, setButton_Search] = useState('');
  const [isLoading, setLoading] = useState(true);
  const handleSelectType = (e) => setType(e);
  const handleSelectCategory = (e) => setCategory(e);
  const [Users, setUsers] = useState([]);

  const [showTransfer, setShowTransfer] = useState(false);
  const handleTransferClose = () => setShowTransfer(false);
  const handleTransferShow = () => setShowTransfer(true);

  async function GetStocks(display){
    setLoading(true)
    const params = {
      limit: search.PerPage,
      page: search.Keyword === '' ? search.CurrentPage : 1,
      keyword:search.Keyword,
      display: search.Display ? search.Display : display,
    }
    try {
     
      const response = await dispatch(LoadStockList(params));
      
      if(response.data.data && response.data.data.data){
        const stock = response.data.data.data
        setStock(stock);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setStock(response.data.data);
        setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
      }
      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const GetUser = async () => {
    setLoading(true);
    try {
      const params = {
        has_lavel: true,
        permission:{
          adjust_stock:{
            name:'ปรับ/ตัด(stock)',
            add: true,
          },
        }
      }
      const response = await dispatch(UserList(params));
      setUsers(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  async function saveStorageList(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    let formData = new FormData();
    formData.append(`stock_list[0]`, JSON.stringify(Data));

    try {
      const response = await dispatch(saveStorage(formData));
      if(response?.data?.message === 'success'){
        // history.push(`/stock`);
        SetData(StorageModel)
        setClearSearch(true)
        setShowAdjustStock(false)
        setShowCutStock(false)
        setShowTransfer(false)
        GetStocks()
        setReport(false)
      }else if(response?.data?.message === 'errors'){
        const report = response.data.data
        report.map((item) => {
          setReport(true)
        });
      }else if(response?.data?.message === 'errors_empty'){
        console.log('errors_empty')
        setReport(false)
      }else{
        console.log('failed')
        setReport(false)
      }
    } catch (error) {
      console.error(error);
      setReport(false)
    } finally {
      console.log('finally')
    }


  }

  const getparamsAuth = async (data) => {
    // console.log(data.name);
    try {
      const params = {
        name: data.name,
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        // history.push('/stock');
        setShowAdjustStock(false)
        setShowCutStock(false)
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: ['ลงชื่อ/รับเข้า(stock)','ปรับ/ตัด(stock)'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  const [StockCode,setStockCode] = useState([]);
  async function getNewStockCode(type){
    setLoading(true)
      try {
        const params = {
          type: type,
        }
        const response = await dispatch(LoadNewStockCode(params));
        setStockCode(response.data.data);
        setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...search, 
      Keyword: '',
      Display: '',
      PerPage: 10 
    })

    setClearSearch(true)
  }

  const [employees,setEmployees] = useState([]);

  const GetEmployee = async () => {
      setLoading(true)
      try {
        const params = {
          keyword: '',
          branch_id : profile.branch_id
        }
        const response = await dispatch(List(params));
        setEmployees(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

  useEffect(() => {
    if (!ClearSearch) GetStocks();
    setClearSearch(false)
    GetUser();
    getAuth()
    GetEmployee();
  }, [search.PerPage, search.CurrentPage, ClearSearch]);

  // useEffect(() => {
  //   // GetStocks();
  //   GetUser();
  //   getAuth()
  //   GetEmployee();
  // }, [search.PerPage, search.CurrentPage]);

  

  

  // useEffect(() => {
    // GetStocks();
    // setClearSearch(false);
  // }, [PerPage, ClearSearch]);

  const [display, setDisplay] = useState([
    {
      value: 'product_low',
      label: 'สินค้าใกล้หมด',
    },
    {
      value: 'product_zero',
      label: 'สินค้าหมดแล้ว',
    },
    {
      value: 'desc',
      label: 'ใหม่-เก่า',
    },
    {
      value: 'asc',
      label: 'เก่า-ใหม่',
    },
  ]);

  function toPath(path){
    history.push(path)
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b>Stock</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b>Stock</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="g-0 justify-content-start">
                  <Col
                    lg={5}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อสินค้า,รหัสสินค้า"
                      value={search.Keyword ? search.Keyword : ''}
                      onChange={(e) => setSearch({...search, Keyword:e.target.value})}
                      className="input"
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      className="select-list select-style"
                      aria-label="Default select example"
                      placeholder="การแสดงผล"
                      options={display}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={search.Display ? display.find((data) => {return data.value === search.Display}) : null}
                      onChange={  
                        (e) => {setSearch({...search, Display: e.value })}
                      }
                    />
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu"
                      type='button'
                      onClick={() => GetStocks()}
                    >
                      <img src={Search} /> &nbsp; ค้นหา
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={ResetSearch}>
                      <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    {/* <Link className="link-page" to="/stock/adjust-stock"> */}
                      <Button className="button-menu" type='button' onClick={() => {toPath('/stock/adjust-stock')}}>
                        <img src={Arrow} /> &nbsp; ปรับสต๊อก
                      </Button>
                    {/* </Link> */}
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    {/* <Link className="link-page" to="/stock/cut-stock"> */}
                      <Button className="button-menu" type='button' onClick={() => {toPath('/stock/cut-stock')}}>
                        <img src={Document} /> &nbsp; ตัดสต็อก
                      </Button>
                    {/* </Link> */}
                  </Col>

                  {
                    level.middle === false ? (
                      <Col
                      xs={6}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      {/* <Link
                        className="link-page"
                        to="/stock/transfer-store"
                      > */}
                        <Button className="button-menu" type='button' onClick={() => {toPath('/stock/transfer-store')}}>
                          <img src={Transfer} /> &nbsp; โอนไปหน้าร้าน
                        </Button>
                      {/* </Link> */}
                    </Col>
                    ) : ''
                  }
                  
                  {
                    level.middle === true ? (
                      <Col
                        xs={6}
                        sm={6}
                        md={4}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center mt-2 mb-2"
                      >
                        {/* <Link className="link-page" to="/stock/purchase-order"> */}
                          <Button className="button-menu" type='button' onClick={() => {toPath('/stock/purchase-order')}}>
                            <img src={PurchaseOrder} /> &nbsp; ใบสั่งซื้อ
                          </Button>
                        {/* </Link> */}
                      </Col>
                      
                    ) : (
                      <Col
                        xs={6}
                        sm={6}
                        md={4}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center mt-2 mb-2"
                        
                      >
                        {/* <Link
                          className="link-page"
                          to="/branch/stock/receive/order-to-stock"
                        > */}
                          <Button className="button-menu" type='button' onClick={() => {toPath('/stock/receive/order-to-stock')}}>
                            <img src={Order_Stock} /> &nbsp; สั่งเข้าสต๊อก
                          </Button>
                        {/* </Link> */}
                      </Col>
                    )
                  }
                  
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    {/* <Link className="link-page" to="/stock/receipt-order"> */}
                      <Button className="button-menu" type='button' onClick={() => {toPath('/stock/receipt-order')}} >
                        <img src={MenuSend} /> &nbsp; รายการรับเข้า/สั่งซื้อ
                      </Button>
                    {/* </Link> */}
                  </Col>
                  {
                    level.middle === true ? (
                      <Col
                        xs={6}
                        sm={6}
                        md={4}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center mt-2 mb-2"
                      >
                        {/* <Link className="link-page" to="/stock/request-order"> */}
                          <Button className="button-menu" type='button' onClick={() => {toPath('/stock/request-order')}} >
                            <img src={Request} /> &nbsp; <b>คำร้อง</b>
                          </Button>
                        {/* </Link> */}
                      </Col>
                    ) : ''
                  }
                </Row>
                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="g-0 justify-content-center m-0">
                  <Col
                    className=" mt-3 p-0"
                    style={{ overflow: 'scroll', height: '500px' }}
                  >
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>รหัสสินค้า</th>
                          <th>ชื่อสินค้า</th>
                          <th>จุดสั่งซื้อ</th>
                          <th>จุดต่ำสุด</th>
                          <th>คงเหลือ</th>
                          <th>หน่วยซื้อ</th>
                          <th >อัพเดทล่าสุด</th>
                          {/* <th></th> */}
                          {
                                level.middle === true ? (
                                  <th></th>
                                ) :''
                              }
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {stock?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.code}</td>
                              <td>{item.name}</td>
                              <td>{item.reorder_point}</td>
                              <td>{item.minimum_quantity}</td>
                              <td
                                className={
                                  item.reorder_point < item.total_quantity
                                    ? 'text-green'
                                    : item.minimum_quantity > item.total_quantity
                                    ? 'text-red'
                                    : item.reorder_point > item.total_quantity ||
                                      item.minimum_quantity < item.total_quantity
                                    ? 'text-orange'
                                    : ''
                                }
                              >
                                {item.total_quantity ? item.total_quantity.toFixed(2) : 0}
                              </td>
                              <td>{item.unit && item.unit.name_buy}</td>
                              <td>{item.stock_updated_at && item.stock_updated_at.updated_at ? dayjs(item.stock_updated_at.updated_at).format('DD-MM-YYYY H:mm') : '-'}</td>
                              {/* <td>{item.user}</td> */}
                              <td>
                                <Button
                                  type=""
                                  className="button-package"
                                  onClick={() => {handleAdjustStockShow();setModalAdjustStock(item);
                                    getUnitSale(item.unit_id)
                                    getparamsAuth({name: ['ปรับ/ตัด(stock)'],permission : 'add'});
                                    getNewStockCode('adjust');
                                  }}
                                >
                                  <img src={Arrow} />
                                </Button>
                              </td>
                              <td>
                                <Button
                                    type=""
                                    className="button-package"
                                    onClick={() => {handleCutStockShow();setModalCutStock(item)
                                      getUnitSale(item.unit_id)
                                      getparamsAuth({name: ['ปรับ/ตัด(stock)'],permission : 'add'});
                                      getNewStockCode('cut_stock');
                                    }}
                                  >
                                    <img src={Document} />
                                </Button>
                              </td>
                              {
                                level.middle === false ? (
                                  <td>
                                    <Button
                                      type="button"
                                      className="button-package"
                                      onClick={() => {handleTransferShow();setModalTransfer(item);
                                        getUnitSale(item.unit_id)
                                        getparamsAuth({name: ['ปรับ/ตัด(stock)'],permission : 'add'});
                                        getNewStockCode('transfer');
                                      }}
                                    >
                                      <img src={Transfer} />
                                    </Button>
                                  </td>
                                ) : null
                              }
                              <td>
                                <Link className="link-page" to={`/stock/history/${item.id}`} >
                                  <img src={View_Data} />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          placeholder=""
                          options={Page}
                          onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                  <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> : ''
                      } */}
                      {
                        !isLoading ? 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-cutstock"
          centered
          show={showCutStock}
          onHide={handleCutStockClose}
        >
          <Modal.Header className="modal-header-cutstock">
            <p>
              <b>ตัดสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeCutStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>{Data.Code}</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{Data.Name}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{ Data.TotalQuantity && Data.TotalQuantity.toFixed(2) } {Data.UnitName}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวนนำออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      className="form-input"
                      value={commaCase(Data.Quantity)}
                      onFocus={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={
                        (e) => SetData({...Data, Quantity: e.target.value.replaceAll(',', '') })
                      }
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                    ></Form.Control>
                  </Col>
                  {
                    Report === true ? <p style={{color: 'red'}}>จำนวนคงเหลือไม่พอ</p> : ''
                  }
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>หน่วย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={unitSales}
                      getOptionLabel={(option) => option.name_sale}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, UnitSaleId: e.id })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นำสินค้าออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      format={dateFormat}
                      value={Data.StockDate}
                      onChange={
                        (e) => SetData({...Data, StockDate: e })
                      }
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={causeAdjust}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={
                        (e) => SetData({...Data, reasonType: e.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                      value={Data.Note}
                      onChange={
                        (e) => SetData({...Data, Note: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {StockCode}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ตัด</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {dayjs().format('DD-MM-YYYY')}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {profile.fullname}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" className="button" onClick={saveStorageList}>
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button"  onClick={closeCutStock} className="button">
                      <img src={getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-adjuststock"
          centered
          show={showAdjustStock}
          onHide={handleAdjustStockClose}
        >
          <Modal.Header className="modal-header-adjuststock">
            <p>
              <b>ปรับสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeAdjustStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>{Data.Code}</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{Data.Name}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                   <p>{ Data.TotalQuantity && Data.TotalQuantity.toFixed(2) } {Data.UnitName}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 justify-content-start">
                  <Col xs={6} lg={8} className="">
                    {['radio'].map((type) => (
                      <Row
                        key={`inline-${type}`}
                        className="justify-content-start"
                      >
                        <Col xs={6} lg={4} className="d-flex">
                          <Form.Check
                            inline
                            label="รับเข้า"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                            // onChange={(e) => setAdmit(e.target.value)}
                            onChange={
                              (e) => SetData({...Data, Admit: e.target.value,TakeOut: false })
                            }
                            checked={Data.Admit}
                          />
                        </Col>
                        <Col xs={6} lg={3} className="d-flex">
                          <Form.Check
                            inline
                            label="นำออก"
                            name="group1"
                            type={type}
                            id={`inline-${type}-2`}
                            onChange={
                              (e) => SetData({...Data, Admit: false, TakeOut: e.target.checked })
                            }
                            checked={Data.takeOut}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>หน่วย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={unitSales}
                      getOptionLabel={(option) => option.name_sale}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, UnitSaleId: e.id })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      className="form-input"
                      value={commaCase(Data.Quantity)}
                      onFocus={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={
                        (e) => SetData({...Data, Quantity: e.target.value.replaceAll(',', '') })
                      }
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    lg={2}
                    className="d-flex align-items-center text-left  "
                  >
                    <Form.Label>นับโดย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list set-respon"
                      aria-label="Default select example"
                      placeholder=""
                      options={employees}
                      getOptionLabel={(option) => option.fullname}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, CountBy: e.id })
                      }
                    />
                  </Col>
                </Row>

                {
                  Report === true ? <p style={{color: 'red'}}>จำนวนคงเหลือไม่พอ</p> : ''
                }

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นับสต็อก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      format={dateFormat}
                      value={Data.StockDate}
                      onChange={
                        (e) => SetData({...Data, StockDate: e })
                      }
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                    
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    {/* { Data.Reason} */}
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={causeAdjust}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={
                        (e) => SetData({...Data, reasonType: e.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                      value={Data.Note}
                      onChange={
                        (e) => SetData({...Data, Note: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {StockCode}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ปรับ</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {dayjs().format('DD-MM-YYYY')}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {profile.fullname}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" onClick={saveStorageList} className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button"  onClick={closeAdjustStock} className="button">
                      <img src={getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          className="modal-cutstock"
          centered
          show={showTransfer}
          onHide={handleTransferClose}
        >
          <Modal.Header className="modal-header-cutstock">
            <p>
              <b>โอนไปหน้าร้าน</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleTransferClose}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>{Data.Code}</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{Data.Name}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                  <p>{ Data.TotalQuantity && Data.TotalQuantity.toFixed(2) } {Data.UnitName}</p>
                  </Col>
                </Row>
              <Row> 
                <Col xs={6}
                  lg={3} 
                  className="d-flex align-items-center text-left">
                    <Form.Label>หน่วย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                      <Select
                        options={unitSales}
                        getOptionLabel={(option) => option.name_sale}
                        getOptionValue={(option) => option.id}
                        placeholder=""
                        isClearable={false}
                        isSearchable={false}
                        onChange={
                          (e) => SetData({...Data, UnitSaleId: e.id })
                        }
                        className="select-style select-list"
                      />
                    </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      className="form-input"
                      value={commaCase(Data.Quantity)}
                      onFocus={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={
                        (e) => SetData({...Data, Quantity: e.target.value.replaceAll(',', '') })
                      }
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                    ></Form.Control>
                  </Col>
                </Row>

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่โอน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      value={Data.StockDate}
                      onChange={
                        (e) => SetData({...Data, StockDate: e })
                      }
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>โอนโดย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={employees}
                      getOptionLabel={(option) => option.fullname}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, CountBy: e.id })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                      value={Data.Note}
                      onChange={
                        (e) => SetData({...Data, Note: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {StockCode}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่โอน</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {dayjs().format('DD-MM-YYYY')}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {profile.fullname}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" onClick={saveStorageList} className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button" className="button" onClick={closeTransfer} >
                      <img src={getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </>
  );
}
