import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { FindPackageType, GetFilmModel,GetFilmSizeCodeByModel } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';
import { JobAppointmentList } from 'actions/jobs/appointment';
import { data } from 'jquery';

export default function ServiceFilter(props) {
  const dispatch = useDispatch()
  const history = useHistory();
  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  // console.log('dat',props.data);
  const [Data,setData] =useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    // PackageTypeId: props.data && props.data.PackageTypeId ? props.data.PackageTypeId : null,
    // package_type_name: props.data && props.data.package_type_name ? props.data.package_type_name : null,
    // PackageStorageList: props.data.PackageStorageList.length > 0  ? props.data.PackageStorageList : [],
    // insite_note: props.data && props.data.insite_note ? props.data.insite_note : null,
    // key: props.data && props.data.key ? props.data.key : null,
    // note: props.data && props.data.note ? props.data.note : null,
    // service_now: props.data && props.data.service_now ? props.data.service_now : null,
    // service_more: props.data && props.data.service_more ? props.data.service_more : null,
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);
  const [PositionUse,setPositionUse] = useState([])

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);

  const [serviceMore, setServiceMore] = useState(false);
  const [fastService, setFastService] = useState(props.data && props.data.service_now ? props.data.service_now : false);
  const [renewalClaim, setRenewalClaim] = useState(false);
  const [checkSetting, setCheckSetting] = useState(false);
  const [treatment, setTreatment] = useState(false);
  const [keepWork, setKeepWork] = useState(false);
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [filmBrand, setFilmBrand] = useState();
  const [FilmModel, setFilmModel] = useState();
  const [modelNumber, setModelNumber] = useState();
  const [TypeWork,setTypeWork] = useState([])
  const [PackageSizeModel,setPackageSizeModel] = useState([]);
  const [PackageSizeCode,setPackageSizeCode] = useState([]);

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }
  


  const handleFastService = () => {
    setFastService(!fastService);
    // updateLocalStorage(!fastService,null,'service_now');
  };
  const handleCheckSetting = () => {
    setCheckSetting(!checkSetting);
  };
  const handleRenewalClaim = () => {
    setRenewalClaim(!renewalClaim);
  };
  const handleTreatment = () => {
    setTreatment(!treatment);
  };
  const handleKeepWork = () => {
    setKeepWork(!keepWork);
  };
  const handleServiceMore = () => {
    setServiceMore(!serviceMore);
  };
  const optionService = [
    { value: 'ติดตั้งใหม่', label: 'ติดตั้งใหม่' },
    {
      value: 'ลอกติดตั้ง',
      label: 'ลอกติดตั้ง',
    },
  ];

  const optionPosition = [
    {value: 'around_car', label: 'รอบคัน' },
    {value: 'car_head',label: 'หัวรถ'},
    {value: 'handle_hole,',label: 'หลุมมือจับ'},
    {value: 'headlight_set',label: 'ชุดไฟหน้า'},
    {value: 'tail_light_set',label: 'ชุดไฟท้าย'},
    {value: 'set_of_4_stairs',label: 'ชุดบันได 4 จุด'},
    {value: 'front_bumper',label: 'กันชนหน้า'},
    {value: 'bonnet',label: 'ฝากระโปรง'},
    {value: 'front_fender_L+R,',label: 'บังโคลนหน้า L+R'},
    {value: 'side_mirror_L+R',label: 'กระจกมองข้าง L+R'},
    {value: 'front',label: 'บานหน้า'},
    {value: 'inside', label: 'ภายใน'}
  ];
  

  const optionFilmBrand = [{ value: '', label: '' }];
  const optionFilmVersion = [{ value: '', label: '' }];
  const optionModelNumber = [{ value: '', label: '' }];

  function SetService(data) {
    setService({ value: data.value, label: data.value });
    // updateLocalStorage(data.name,null,'type_work');
  }
  function SetFilmBrand(data) {
    setFilmBrand({ value: data.id, label: data.name, data });
    getSeriesFilm(data.id);
  }

  function SetFilmModel(data) {
    setFilmModel({ value: data.model, label: data.model, data});
    getFilmListCode(data)
  }
  function SetModelNumber(data) {
    setModelNumber({ value: data.code, label: data.code, data });

  }

  function SetPosition(data){
    // SetPosition({ value: data.value, label: data.label})
    setPositionUse(data);
  }

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('getDisplayTextPositionCoating',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
      // console.log('result',result);
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }


  const StorageModel = {
    key: 0,
    PackageId: '',
    PackageCode: '',
    PackageName: '',
    PackageTypeId: '',
    PackageTypeName: '',
    form: 'package',
    Status: 'new',
    Quantity: '',
    ServiceNow: false,
    TypeWork: '',
  }

  function InsertPackage(){

    if(filmBrand?.data?.id  && FilmModel?.data?.model && modelNumber?.data?.code && PositionUse.length > 0){
      let insert = StorageModel
      insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
      insert.PackageId = filmBrand.data.id
      insert.PackageCode = filmBrand.data.code
      insert.PackageName = filmBrand.data.name
      insert.PackageTypeId = props.data.id 
      insert.PackageTypeName = props.data.name
      insert.ServiceNow = fastService
      insert.TypeWork = service.value
      const CarSize = CarOwner.car_serie.size
      // console.log(filmBrand)
      const MiddleSize = filmBrand.data.middle_sizes
      const resultCarSize = MiddleSize.find((item) => {
        return item.size === CarSize
      })
      if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
        insert.Price = MiddleSize[0].price
      }else{
        insert.Price = resultCarSize.price ? resultCarSize.price : 0 
      }
      insert.FilmThickness = null
      insert.TypeWork = null
      insert.PackageType = filmBrand.data.category
      insert.PositionUse = PositionUse
      insert.FilmModel = FilmModel.value
      insert.modelNumber = modelNumber.value
      insert.TextDetails = null
      // for Appointment
      insert.CleanService = null
      insert.Treatment = null // TreatmentTime * Quantity
      insert.TreatmentTime = filmBrand.data.treatment_time
      insert.TreatmentRanges = filmBrand.data.treatment_ranges
      insert.Setup = filmBrand.data.setup
      insert.Schedule = filmBrand.data.schedule
      insert.PackageTypeId = filmBrand.data.package_type_id

      setData({ ...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

      //clear 
      setFilmBrand([{ value: '', label: '' }]);
      setPackageSizeModel([{ value: '', label: '' }]);
      setPackageSizeCode([{ value: '', label: '' }]);
      setFilmModel([]);
      setModelNumber([]);
      setPositionUse([]);
      setService([{ value: '', label: '' }]);

      const package_list = Data.PackageStorageList
      package_list.push(insert)
      
      parseDataJob.PackageList = package_list;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

    }else{
      return alert('เลือกรายการ')
    }
  }

  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'Quantity':
        setData(prevState => ({...Data,
          PackageStorageList: prevState.PackageStorageList.map(
            el => el.key === item.key ? { ...el, Quantity: Number(data.target.value), Treatment: (Number(data.target.value) * Number(el.TreatmentTime)) }: el
          )}))
    
        item.Quantity = Number(data.target.value)
        item.Treatment = Number(data.target.value) * Number(item.TreatmentTime)
        break;
      case 'note':
        setData({...Data, note: data.target.value })
        Data.note = data.target.value
        break;
      case 'insite_note':
        setData({...Data, insite_note: data.target.value })
        Data.insite_note = data.target.value
        break;
        case 'things_in_car':
          setData({...Data, things_in_car: data })
          Data.things_in_car = data
          break;
        case 'defect_car':
          setData({...Data, defect_car: data })
          Data.defect_car = data
          break;
        case 'area':
          setData({...Data, area: data.target.value })
          Data.area = data.target.value
          break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = result;
    parseDataJob.note = Data.note
    parseDataJob.insite_note = Data.insite_note
    parseDataJob.area = Data.area
    parseDataJob.things_in_car = Data.things_in_car
    parseDataJob.defect_car = Data.defect_car
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.PackageId
    })
    Packages.push(result);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

 

  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  async function getSeriesFilm(PackageId){
    setLoading(true)
    try {
      const response = await dispatch(GetFilmModel(PackageId));
      const packages_size = response.data.data
      setPackageSizeModel(packages_size);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  async function getFilmListCode(data){
    // console.log('getFilmListCode',data);
    setLoading(true)
    try {
      const params = {
        package_id: data.package_id,
        model: data.model,
      }
      const response = await dispatch(GetFilmSizeCodeByModel(params));
      const package_size_code = response.data.data
      setPackageSizeCode(package_size_code);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[])
  return (
    <>
      <Form>
        <Container fluid  className="pl-3 pr-3">
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <Select
                    options={optionService}
                    placeholder="ประเภทงาน"
                    isClearable={false}
                    isSearchable={false}
                    onChange={(data) => SetService(data)}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-end">
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={fastService}
                      className="checkbox-input mt-3"
                      value
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={6} >
              <Row>
              <Col lg={10}>
                <Select
                  isMulti
                  placeholder="เลือกตำแหน่ง"
                  options={optionPosition}
                  value={PositionUse}
                  onChange={(data) => SetPosition(data)}
                  className={`select-list ` }
                />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 justify-content-start">
            <Col
              xs={6}
              sm={5}
              md={4}
              lg={4}
              xl={3}
              className="mt-4  d-lg-none p-0"
            >
              {/* <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={fastService}
                className="checkbox-input"
              /> */}
            </Col>
          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Select
                options={Packages}
                getOptionValue={(option)=> option.id}
                getOptionLabel={(option) => option.name}
                // value={Data.PackageId ? Packages?.find((data) => {return data.PackageId === Data.PackageId}) : '' }
                placeholder="เลือกยี่ห้อฟิล์ม"
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetFilmBrand(data)}
                className="select-list"
              />
            </Col>
          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={3} className="mt-2 mb-2">
              <Select
                options={PackageSizeModel}
                placeholder="เลือกรุ่นฟิล์ม"
                getOptionValue={(option)=> option.model}
                getOptionLabel={(option) => option.model}
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetFilmModel(data)}
                className="select-list"
              />
            </Col>
            <Col lg={3} className="mt-2 mb-2">
              <Select
                options={PackageSizeCode}
                getOptionValue={(option)=> option.id}
                getOptionLabel={(option) => option.code}
                placeholder="เลือกเลขรุ่น"
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetModelNumber(data)}
                className="select-list"
              />
            </Col>
            <Col lg={3} xl={2}  className="mt-2 mb-2">
              <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                เพิ่ม
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr key={key}>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td>{item.PackageName} 
                          <p>
                            { getTextByPackage(item)}
                            { getDisplayTextPositionCoating(item) }
                            { getDisplayTextPositionUse(item)}
                            { item.TextDetails }
                          </p>
                          {/* (ระยะเวลา 1 ปี) 1/3 */}
                          </td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            type="number"
                            required
                            disabled={item.Status === 'appointment'}
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type="" className="button-package"
                             disabled={item.Status === 'appointment'}
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          
        </Container>
      </Form>
    </>
  );
}
