import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form
} from 'react-bootstrap';

import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import dayjs from 'dayjs';
import AppointmentImage from 'assets/icon/purchase.png';

// API
import { FindAppointment, CreateAppointmentLog,UpdateAppointmentStatus } from 'actions/appointment/index';

export default function AppointmentByID() {
  const dispatch = useDispatch();
  const { employee } = useSelector( (state) => state.user)
  const history = useHistory();
  const { id } = useParams();

  const [appointment, setAppointment] = useState(null);

  function close() {
    history.push(`/`);
  }

  const [isLoading, setLoading] = useState(true);
  const GetAppointment = async () => {
    setLoading(true)
    dispatch(FindAppointment(id)).then(response => {
      setAppointment(response.data.data)
      setLoading(false)
    }).catch(e => {
      setLoading(false)
    })
  }

  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    status: 'การติดตาม',
    note: null,
  });

  function createLog(e) {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const form = {
      id: id,
      data
    }

    dispatch(CreateAppointmentLog(form)).then( response =>{
      GetAppointment()
    }).catch(e => {
      console.log(e)
    })
  }

  async function returnStatus(data){

    const form = {
      id: id,
      data:{
        appointment_log_id : data.id,
        rejected: 1 // true
      }
    }

    dispatch(UpdateAppointmentStatus(form)).then( response =>{
      // history.push('/appointment')
    }).catch(e => {
      console.log(e)
    })

  }

  useEffect(() => {
    GetAppointment()
  }, []);

  return (
    <>
      
      <Container
        fluid
        className="package-container Service Stock Job Appointment bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={AppointmentImage} />
                  <b>การนัดหมาย</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={AppointmentImage} />
                  <b>การนัดหมาย</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <div className="set-appointment-id">
                  <Row className="g-0 justify-content-center mt-3 ">
                    <Col className="text-left  mb-3">
                      <Form.Label className="font-weight-bold">
                        Job
                      </Form.Label>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <div className="body-text-appointment">
                        <Row className="align-items-center">
                          <Col xs={12} lg={5} className="text-left pl-5">
                            <p className="m-0">
                              <b>Job {appointment?.job_detail?.job?.job_code}</b> สาขา{appointment?.job_detail?.job?.branch_name}
                            </p>
                            <p className="m-0">
                            {`${appointment?.job_detail?.job?.customer?.first_name} ${appointment?.job_detail?.job?.customer?.last_name} (${appointment?.job_detail?.job?.customer?.nickname}) ${ appointment?.job_detail?.job?.customer && JSON.parse(appointment.job_detail?.job.customer.phone)}`}
                            </p>
                            <p className="m-0">
                              {/* {item.work} ({item.date}) เวลา {item.time} น. */}
                            </p>
                          </Col>
                          <Col xs={12} lg={3} className="mt-2 mb-2">
                            <Row>
                              <Col xs={5} lg={7} className="pl-5">
                                <Form.Label className="font-weight-bold">
                                  {appointment?.status}
                                </Form.Label>
                              </Col>
                              <Col xs={3} lg={5}></Col>
                            </Row>
                          </Col>
                          <Col xs={12} lg={4}></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center mt-3 ">
                    <Form noValidate validated={validated} onSubmit={ createLog }>
                      <Col className="text-left  mb-3">
                        <Form.Label className="font-weight-bold">
                          อัพเดทการติดตาม
                        </Form.Label>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Form.Control
                          as="textarea"
                          value={data.note ? data.note : ''}
                          onChange={(e) => setData({ ...data, note: e.target.value })}
                          placeholder="การติดตาม"
                          required
                          style={{ height: '150px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', border: '1px solid #7a7a7a' }}
                        />
                      </Col>
                      <Col className="mt-5 mb-5">
                        <Row className="justify-content-center">
                          <Col xs={5} lg={3} xl={2}>
                            <Button type="submit" className="button">
                              เพิ่ม
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Form>
                  </Row>
                  <Row className="g-0 justify-content-center">
                    <Col className=" g-0 ">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left" width="20%">
                              วันที่
                            </th>
                            <th className="text-left" width="20%">
                              รายละเอียด
                            </th>
                            <th>หมายเหตุ</th>
                            <th>ผู้บันทึก</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            appointment?.appointment_log.map((item, index) => {
                              return(
                                <tr className="t-tr-setting mb-3" key={index}>
                                  <td className="text-left">{ dayjs(item.created_at).format('YYYY-MM-DD') }</td>
                                  <td className="text-left">
                                    <Col className="p-0">
                                      <div className="border-div">
                                        {item.status}
                                        {
                                          item.status === 'เลื่อนนัด' && item.appointment_time
                                          &&
                                          ` #${item.appointment_time}`
                                        }
                                      </div>
                                    </Col>
                                    { 
                                    item.status === 'เลื่อนนัด' && 
                                    <Col className="p-0">{item.appointment_date && `(${dayjs(item.appointment_date).format('DD/MM/YYYY')})`}</Col>
                                    }
                                  </td>
                                  <td>{item.note}</td>
                                  <td>{item.employee?.fullname}</td>
                                  <td> 
                                    {
                                      item.status === 'มาตามนัด' && item.rejected === false ? (
                                        <Button type="submit" className="button" onClick={() => {
                                          returnStatus(item)
                                        }}>
                                          ยกเลิก
                                        </Button>
                                      ) : ''
                                    }
                                    
                                    </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
                <Row className="justify-content-center mt-4 mb-4">
                  <Col className="d-flex justify-content-center set-paginate">
                    <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> : ''
                      } */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
