import React , {useState , useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import PDFBillFilm from './Components/PDFBillFilm'
// import { PDFDownloadLink } from '@react-pdf/renderer';
import Print from 'assets/icon/print.png';

export default function index() {
  return (
      <>
          <Container>
              {/* <PDFDownloadLink document={<PDFBillFilm />} fileName="FilmBill">
                  {({ loading }) => 
                      loading ? (
                          <Button type="button" >
                              <img src={Print} /> &nbsp; กำลังแก้ไขเอกสาร
                          </Button>
                          ) : (
                          <Button type="button" >
                              <img src={Print} /> &nbsp; พิมพ์ใบเสร็จ
                          </Button>
                              )}
              </PDFDownloadLink> */}
          </Container>
    </>
  )
}
