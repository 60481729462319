import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams  , Link} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Alert
} from 'react-bootstrap';

// Custom Component
import Footer from 'Components/Footer';
import TabsIcon from 'Components/layout/TabsIcon';

// Icon
import Setting from 'assets/icon/setting.png';
import Back from 'assets/icon/back.png';
import Add from 'assets/icon/add_menu.png';

// API
import { FindUnit, UpdateUnit, DestroyUnit } from 'actions/settings/unit';
import { loadAuth } from 'actions/auth';

export default function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert , setShowAlert] = useState(false)

  let { id } = useParams();

  const DefaultSales = [
    {
      key: 0,
      name_sale: null,
      value_sale: null
    }
  ]

  const model = {
    type: null,
    name_buy: null,
    value_buy: null,
    sales: DefaultSales
  }
  const [Data, setData] = useState(model)

  const [Type, setType] = useState([
    { value: 'ชิ้น', label: 'ชิ้น' },
    { value: 'ความยาว', label: 'ความยาว' },
    { value: 'น้ำหนัก', label: 'น้ำหนัก' },
  ]);

  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const GetUnit = async (data) => {
    setisLoading(true)
    try {
      const response = await dispatch(FindUnit(id));
      if (response) {
        console.log(response.data.data.sales)
        if (response.data.data.sales && response.data.data.sales.length > 0) {
          response.data.data.sales.forEach((item, key) => {
            delete item.created_at
            delete item.updated_at
            item.key = key
          });
        }else{
          response.data.data.sales = DefaultSales
        }

        setData(response.data.data)
        setisLoading(false)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting/unit');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    let formData = new FormData();
    if(Data.type) formData.append('type', Data.type);
    if(Data.name_buy) formData.append('name_buy', Data.name_buy);
    if(Data.value_buy) formData.append('value_buy', Data.value_buy);
    Data.sales.forEach((item, key) => {
      if(item.name_sale) formData.append(`sales[${key}][name_sale]`, item.name_sale);
      if(item.value_sale) formData.append(`sales[${key}][value_sale]`, item.value_sale);
    });

    formData.append('_method', 'PUT');

    const data = {
      id,
      formData
    }

    try {
      const response = await dispatch(UpdateUnit(data));
     
      if(response){
        setData(model);
        setValidated(false);
        setShowAlert(true);
        setTimeout(function() {
          history.push(`/setting/unit`);
        }, 2000);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const InsertSale = () => {
    const obj = {
      key: Data.sales.length,
      name_sale: null,
      value_sale: null
    }
    setData({...Data, sales:[...Data.sales, obj]})
  }

  const DeleteUnit = async (id) => {
    setisLoading(true)
    try {
      const response = await dispatch(DestroyUnit(id));
      if (response) {
        setisLoading(false)
        GetUnit();
        
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'edit'});
    GetUnit();
  }, []);

  function close() {
    history.push(`/`);
  }
  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b> <Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/unit" className='link-pagination'>หน่วย</Link> &gt; แก้ไข</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b> <Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/unit" className='link-pagination'>หน่วย</Link> &gt; แก้ไข</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className='g-0'>
                  <Col>
                   { showAlert === true ? 
                    <Alert variant="success" onClose={() => setShowAlert(false)} >
                      <p className='text-left'>
                        บันทึกสำเร็จ
                        </p>
                        <Button className='button-alert button-image text-dark ' onClick={() => setShowAlert(false)}>X</Button>
                    </Alert>
                      : null}
                  </Col>
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="g-0 justify-content-center">
                    <Col xs={12}>
                      <Row className="justify-content-center">
                        <Col xs={12} md={9} lg={12}>
                          <Row className="mt-2 mb-2">
                            <Col
                              xs={4}
                              lg={2}
                              className="text-left d-flex align-items-center"
                            >
                              <Form.Label>ประเภทหน่วย</Form.Label>
                            </Col>
                            <Col xs={8} lg={3}>    
                              <Form.Select 
                                required
                                value={Data.type ? Data.type : null}
                                onChange={(e) => setData({...Data, type: e.target.value })}
                              >
                                <option value="" hidden>เลือกประเภทหน่วย</option>
                                {
                                  Type?.map((item, key) => {
                                    return (
                                      <option value={item.value} key={key}>{item.value}</option>
                                    )
                                  })
                                }
                              </Form.Select>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={9} lg={12}>
                          <Row className="mt-2 mb-2">
                            <Col
                              xs={4}
                              lg={2}
                              className="text-left d-flex align-items-center"
                            >
                              <Form.Label>หน่วยซื้อ</Form.Label>
                            </Col>
                            <Col xs={8} lg={3} className="mt-2 mb-2">
                              <Form.Control 
                                placeholder="หน่วยซื้อ"
                                value={Data.name_buy ? Data.name_buy : ''}
                                onChange={(e) => setData({ ...Data, name_buy: e.target.value })}
                                required
                              ></Form.Control>
                            </Col>
                            <Col
                              xs={4}
                              lg={1}
                              className=" d-flex align-items-center"
                            >
                              <Form.Label>จำนวน</Form.Label>
                            </Col>
                            <Col xs={8} lg={3} className="mt-2 ">
                              <Form.Control
                                type='number'
                                placeholder=""
                                value={Data.value_buy ? Data.value_buy : ''}
                                disabled={!Data.name_buy}
                                required
                                onChange={(e) => setData({ ...Data, value_buy: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        {
                          Data.sales?.map((sale, key) => {
                            return(
                              <Col xs={12} md={9} lg={12} key={key}>
                                <Row className="mt-2 mb-2">
                                  <Col
                                    xs={4}
                                    lg={2}
                                    className="text-left d-flex align-items-center"
                                  >
                                    <Form.Label>หน่วยขาย { key + 1 }</Form.Label>
                                  </Col>
                                  <Col xs={8} lg={3} className="mt-2 mb-2">
                                    <Form.Control 
                                      type='text'
                                      placeholder="หน่วยขาย"
                                      value={sale.name_sale ? sale.name_sale : ''}
                                      required
                                      onChange={(e) => setData(prevState => ({...Data,
                                        sales: prevState.sales.map(
                                          el => el.key === key ? { ...el, name_sale: e.target.value }: el
                                        )
                                      }))}

                                    ></Form.Control>
                                  </Col>
                                  <Col
                                    xs={4}
                                    lg={1}
                                    className="text-left d-flex align-items-center"
                                  >
                                    <Form.Label>จำนวน</Form.Label>
                                  </Col>
                                  <Col xs={8} lg={3} className="mt-2 mb-2">
                                    <Form.Control
                                      type='number'
                                      placeholder=""
                                      value={sale.value_sale ? sale.value_sale : ''}
                                      disabled={!sale.name_sale}
                                      required
                                      onChange={(e) => setData(prevState => ({...Data,
                                        sales: prevState.sales.map(
                                          el => el.key === key ? { ...el, value_sale: e.target.value }: el
                                        )
                                      }))}
                                    ></Form.Control>
                                  </Col>
                                  <Col xs={12} lg={3} xl={2} className="mt-2 mb-2 ">
                                    {
                                      (Data.sales.length - 1) === key
                                      &&
                                      <Row className="justify-content-around">
                                        <Col xs={6} lg={12}>
                                          <Button type="button" className="button" onClick={() => InsertSale()}>
                                            <img src={Add} /> &nbsp; เพิ่มหน่วยขาย
                                          </Button>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            )
                          })
                        }
                      </Row>

                      <Row className="justify-content-around">
                        <Col xs={6} sm={12} md={12} lg={4} xl={3}>
                          <Button type="submit" className="button">
                            <img src={Add} /> &nbsp; เพิ่มรายการ
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
