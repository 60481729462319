import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';



export default function ViewAll(props) {
const dispatch = useDispatch();



  return (
    <>
      <Row className="justify-content-around mt-5">
          {
            props.property?.map( item => {
              return (
                <Col xs={12} sm={12} md={10} lg={3} className="set-colum property-set-colum">
                    <Row className="header">
                      <Col>{item.name}</Col>
                    </Row>
                    <Row className="body">
                    <Col>
                      {
                        item.prop_information && item.prop_information.property_storage?.map( i => {
                          return (
                            <div>
                              <Row>
                                <Col className="text-left">
                                  <p>{i.placement_point}</p>
                                </Col>
                                <Col className="text-right">
                                  <p>{i.amount}</p>
                                </Col>
                              </Row>
                            </div>
                          )
                        })
                      }
                      <Row>
                        <Col className="text-center">
                          <p>มูลค่ารวมทรัพย์สิน {item.prop_information && (item.prop_information.price * item.prop_information.amount) } บาท</p>
                        </Col>
                      </Row>
                       </Col>
                    </Row>
                  </Col>
              )
            })
          }
        {/* <Col xs={12} sm={12} md={10} lg={3} className="set-colum">
          <Row className="header">
            <Col>สาขาท่าแพ11</Col>
          </Row>
          <Row className="body">
            <Col>
              <Row>
                <Col className="text-left">
                  <p>ทีวี</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>ตู้เย็น</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>คอมพิวเตอร์</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>โทรศัพท์</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <p>มูลค่ารวมทรัพย์สิน 50,000 บาท</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={10} lg={3} className="set-colum">
          <Row className="header">
            <Col>สาขาศาลเด็ก</Col>
          </Row>
          <Row className="body">
            <Col>
              <Row>
                <Col className="text-left">
                  <p>ทีวี</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>ตู้เย็น</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>คอมพิวเตอร์</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <p>โทรศัพท์</p>
                </Col>
                <Col className="text-right">
                  <p>2</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <p>มูลค่ารวมทรัพย์สิน 50,000 บาท</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
}
