// import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import MrkImage from 'assets/icon/dashboard.png'
import MemberImage from 'assets/icon/member.png'
import UserImage from 'assets/icon/user.png'
import JobImage from 'assets/icon/job.png'
import NotificationImage from 'assets/icon/notification.png'
import B2BImage from 'assets/icon/address_book_home.png'
import StockImage from 'assets/icon/appwizard_list.png'
import BillImage from 'assets/icon/job.png'
import AppointmentImage from 'assets/icon/purchase.png'
import GlassImage from 'assets/icon/glass-staff.png'
import ReportImage from 'assets/icon/report-menu.png'
import Add from 'assets/icon/add_menu.png'
import '../../../assets/scss/style-component.scss';
import Text from 'text-setting';

export default function TabsIconUser() {
  return (
    <Row className="g-0 d-flex justify-content-start m-0">
      <Col className="d-flex tabs-icon mb-4">
          {/* <Link className='link-button' to="">
          <Button className='button-menu-page p-1'>
          <img src={MrkImage} width="100%"  alt="" title={Text.menuName.mrk} />
          </Button>
          </Link> */}
          <Link className='link-button' to="/member">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={MemberImage} width="100%"  alt="" title={Text.menuName.member} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/user">
          <Button className='button-menu-page p-1'>
            <img src={UserImage} width="100%"  alt="" title={Text.menuName.user} />
          </Button>
          </Link> */}
          <Link className='link-button' to="/job">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={JobImage} width="100%"  alt="" title={Text.menuName.job} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/b2b">
          <Button className='button-menu-page p-1'>
            <img src={B2BImage} width="100%"  alt="" title={Text.menuName.b2b} />
          </Button>
          </Link> */}
          {/* <Link className='link-button' to="/stock">
          <Button className='button-menu-page p-1'>
            <img src={StockImage} width="100%"  alt="" title={Text.menuName.stock} />
          </Button>
        </Link> */}
          <Link className='link-button' to="/bill">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={BillImage} width="100%"  alt="" title={Text.menuName.bill} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/notification">
          <Button className='button-menu-page p-1'>
            <img src={NotificationImage} width="100%"  alt="" title={Text.menuName.notification} />
          </Button>
          </Link> */}
          {/* <Link className='link-button' to="/appointment">
          <Button className='button-menu-page p-1'>
            <img src={AppointmentImage} width="100%"  alt="" title={Text.menuName.appointment} />
          </Button>
          </Link>
          <Link className='link-button' to="/branch/">
          <Button className='button-menu-page p-1'>
            <img src={GlassImage} width="100%"  alt="" title={Text.menuName.glass} />
          </Button>
          </Link>
          <Link className='link-button' to="/report">
          <Button className='button-menu-page p-1'>
            <img src={ReportImage} width="100%"  alt="" title={Text.menuName.report} />
          </Button>
          </Link> */}
         <Link to="/user/add">
          <Button className="button-icon d-flex  justify-content-center align-items-center">
            เพิ่มผู้ใช้งาน &nbsp; <img src={Add} className='image-add' />
          </Button>
        </Link>
      </Col>
    </Row>
  );
}
