import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Table,
} from 'react-bootstrap';
import Note from 'assets/icon/note-car.png';
import View from 'assets/icon/view-data.png';
import Select, { components } from 'react-select';
import Write from 'assets/icon/add-staff.png';
import Increase from 'assets/icon/add-staff.png';
import { useHistory, Link } from 'react-router-dom';
import MyPagination from 'Components/MyPagination';

//api
import { LoadPropertyStorageByPropertyId } from 'actions/property/index'
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

export default function Storage(props) {
  const property_id = props.id
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [statepage, setStatepage] = useState('');
  const [search, setSearch] = useState({
    property_id: property_id,
    PerPage: 10,
    CurrentPage: 1
  });

  const [Data,setData] = useState([])

  // const edit = (information_id) => {
  //   editStorage(information_id)
  // }

  async function GetPropertyStorageByPropertyId(params){
    setLoading(true);
    const newparams = {
      property_id: property_id,
      limit: search.PerPage,
      page: search.CurrentPage
    }
    try {
      const response = await dispatch(LoadPropertyStorageByPropertyId(newparams));
      let prop_information =[]
      if(search.PerPage > 0){
         prop_information = response.data.data.data
         setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
         prop_information = response.data.data
         setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
      }
      if(prop_information){
        setData(prop_information); 
      }
      setLoading(false);
      console.log('prop_information',prop_information);
      console.log("Data",Data);
     
    } catch (error) {
        console.log(error);
    }
  }

  function addStorage(){
    let path = `/property/add-storage/${property_id}`;
    history.push(path);
  }

  function editStorage(information_id){
    let path = `/property/edit-storage/${property_id}`;
    history.push(path);
  }

  useEffect(() => {
    GetPropertyStorageByPropertyId();
  }, [search.PerPage, search.CurrentPage]);




  return (
    <>
      <Row className="g-0 justify-content-center">
        <Row className="justify-content-end">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={2}
            className="d-flex justify-content-end"
          >
            <Button type="button" className="button-increase" onClick={addStorage}>
              <img src={Increase} /> &nbsp; เพิ่มรายการ
            </Button>
          </Col>
        </Row>
        <Row
          className="g-0 m-0 mt-3"
          style={{ overflow: 'scroll', height: '500px' }}
        >
          <Col className="">
            <Table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th>วันที่ซื้อ</th>
                  <th>ซื้อจาก</th>
                  <th>ที่เก็บ</th>
                  <th>จำนวน</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
                {
                  Data && Data.map( item => {
                    return(
                      <tr>
                        <td>{ dayjs(item.purchase_date).format('DD-MM-YYYY') }</td>
                        <td>{ item.buy_from }</td>
                        <td>{ item.property_storage && item.property_storage.map( storage => {
                          return (
                            <p>
                              {storage.placement_point} สาขา {storage.branch && storage.branch.name }
                            </p>
                          )
                        }) }</td>
                        <td>{ item.property_storage && item.property_storage.map( storage => {
                          return (
                            <p>
                              {storage.amount}
                            </p>
                          )
                        }) }</td>
                        <td className="">
                          {/* <Button
                            type="button"
                            className=" m-2  "
                            style={{ 'box-shadow': 'none' }}
                            onClick={edit}
                          >
                            <img src={Write} />
                          </Button> */}
                          
                        <Link to={`/property/edit-storage/${property_id}/${item.id}`}>
                            <Button className="button-package">
                              <img src={View} />
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                }

{/* <tr>
                  <td>11/04/2021 </td>
                  <td>เพาเวอร์บาย (สาขา ยูพีซีเชียงใหม่)</td>
                  <td>
                    สาขาสวนดอก (ชั้น2) <br />
                    สาขาสวนดอก (ห้องรับรอง)
                  </td>
                  <td>
                    1 <br /> 1
                  </td>
                  <td className="">
                    <Button
                      type=""
                      className=" m-2  "
                      style={{ 'box-shadow': 'none' }}
                    >
                      <img src={Write} />
                    </Button>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
      <Row className="g-0 mt-3 mb-3">
        <Col lg={5} className=" set-page">
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <p style={{ margin: 0 }} className="set-text">
                แสดง
              </p>
            </Col>
            <Col lg={7}>
              <Select
                className="text-left select-style"
                aria-label="Default select example"
                options={Pages}
                onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                defaultValue={{ value: 10, label: '10' }}
              />
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <p style={{ margin: 0 }}>รายการ</p>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-content-end set-paginate">
          <div className="box-paginate">
          {
            !isLoading ? 
              search.Rows > 1 && search.PerPage > 0 ?
                <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
              : ''
            : 
              search.Rows > 1 && search.PerPage > 0 ?
                <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
              : ''
          }
          </div>
        </Col>
      </Row>
    </>
  );
}
