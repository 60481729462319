import React, { useState, useEffect } from 'react';

import Footer from 'Components/Footer';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Table, Label, Input, FormGroup } from 'reactstrap';
import Select, { components } from 'react-select';

import 'assets/css/app.css';
import 'assets/css2/css/app.css';

import 'assets/css2/css/modal.css';
import 'assets/css2/css/employee.css';
import 'assets/css2/css/responsive.css';
import 'assets/css2/css/input.css';
import 'assets/css2/css/table.css';
import 'assets/css2/css/mr.kleane.css';
import 'assets/scss/style.scss';
import TabsIconB2B from '../Components/layout/TabsIconB2B';
import TabsIconB2BB from 'BranchSystems/Components/layout/TabsIconB2B';
// import B2B from 'assets/icon/users_key-4.png';
import Back from 'assets/icon/back.png';
import ViewData from 'assets/icon/view-data.png'
import Returnvalue from 'assets/icon/return-value.png'
import SearchImage from 'assets/icon/search.png'
import Add from 'assets/icon/add_menu.png'
import Delete from 'assets/icon/delete.png';
import MyPagination from 'Components/MyPagination'
import B2BImage from 'assets/icon/address_book_home.png'

// API
import { Customer, DestroyCustomer } from 'actions/customer/index';
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { loadAuth } from 'actions/auth';

var dayjs = require('dayjs')
var relativeTime = require('dayjs/plugin/relativeTime')
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: "ใน %s",
    past: "%s ที่แล้ว",
    s: 'ไม่กี่วินาที',
    m: "ไม่กี่่นาที",
    mm: "%d นาที",
    h: "ไม่กี่ชั่วโมง",
    hh: "%d ชั่วโมง",
    d: "1 วัน",
    dd: "%d วัน",
    M: "1 เดือน",
    MM: "%d เดือน",
    y: "1 ปี",
    yy: "%d ปี"
  }
})
export default function Index({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  const { employee } = useSelector( (state) => state.user)

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [Customers, setCustomers] = useState([]);
  const [CarBrands, setCarBrands] = useState([]);
  const [CarModels, setCarModels] = useState([]);

  const [Search, setSearch] = useState({
    Keyword: '',
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const GetCustomer = async () => {
    setLoading(true)
    const params = {
      type: ['โชว์รูม'],
      keyword: Search.Keyword,
      segment_id: Search.SegmentId,
      car_brand_id: Search.CarBrandId,
      car_model_id: Search.CarModelId,
      limit: Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1
    }
    try {
      const response = await dispatch(Customer(params));
      if(response.data.data && response.data.data.data){
        setCustomers(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setCustomers(response.data.data);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setCarBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    setSearch({...Search, CarBrandId: car_brand_id, CarModelId: null })

    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setCarModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['customerB2B'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/');
      // }
    } catch (error) {
	history.push('/');
      console.error(error);
    }
  };

  function ChangeModel(data) {
    GetCarModel(data.id)
  }

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...Search, 
      Keyword: '',
      CarBrandId: null,
      CarModelId: null,
      LicensePlate: null,
      PerPage: 10 
    })

    setClearSearch(true)
  }

  useEffect(() => {
    if (!ClearSearch) GetCustomer();
    setClearSearch(false)
  }, [Search.PerPage, Search.CurrentPage, ClearSearch]);

  useEffect(() => {
    GetBrand();
    getAuth()
  }, []);

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);

  function deleteConfirmation(customer) {
    setDeleting(customer)
    setConfirmation(true)
  }

  const DeleteCustomer = () => {
    dispatch(DestroyCustomer(deleting.id)).then( response => {
      GetCustomer()
      setDeleting(null)
      setConfirmation(false)
    }).catch(e => {
      console.log(e)
    })
  }

  const OnEnterSearch = (event) => {
    if (event.key === 'Enter') {
      GetCustomer();
    }
  }

  const OnEmptySearch = (event) => {
    if (event.target.value.length === 0) {
      GetCustomer();
    }
  }
  
  function close() {
    history.push(`/`);
  }

  return (
    <>
      
      <Container
        fluid
        className="package-container Service Stock User B2B bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  <b><Link to={`/b2b`} className='mr-2'>Company (B2B)</Link></b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  <b>Company (B2B)</b>
                </h5>
              </div>
              <div className="modal-body">
                <Col className="justify-content-start  " style={{ padding: 0 }}>
                  <Row>
                    { 
                    employee.is_middle ?
                    <TabsIconB2B />
                    :
                    <TabsIconB2BB />
                  }
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="justify-content-center"
                  style={{ padding: 0 }}
                >
                  <div className="box-typecar ">
                    {/* <Form> */}
                      <Row>
                        <Col lg={7} className="set-col-b2b mt-2 mb-2">
                          <Form.Control
                            type="text"
                            value={Search.Keyword ? Search.Keyword : ''}
                            onChange={(e) => setSearch({...Search, Keyword: e.target.value })}
                            onKeyDown={OnEnterSearch}
                            onKeyUp={OnEmptySearch}
                            placeholder="ค้นหารหัสสมาชิก, ชื่อโชว์รูม, ชื่อสมาชิก, เบอร์โทรศัพท์สมาชิก"
                            className="input"
                          ></Form.Control>
                        </Col>
                      
                        <Col className="set-col-b2b mt-2 mb-2">
                            <Button className="button" onClick={() => GetCustomer()}>
                            ค้นหา &nbsp;
                            <img src={SearchImage} alt="" />
                          </Button>
                        </Col>
                        <Col
                          xs={12}
                          className="set-col-b2b mt-2 mb-2 d-md-none"
                        >
                          <Button className="button" onClick={() => ResetSearch()}>
                            คืนค่า &nbsp; <img src={Returnvalue} alt="" />
                          </Button>
                        </Col>
                        <Col className="set-col-b2b mt-2 mb-2 set-hidden-b2b">
                          <Button className="button" onClick={() => ResetSearch()}>
                            คืนค่า &nbsp; <img src={Returnvalue} alt="" />
                            
                          </Button>
                        </Col>
                        <Col className="set-col-b2b mt-2 mb-2 d-sm-none">
                          <Link to="/b2b/add">
                            <Button className="button">เพิ่มรายชื่อ &nbsp; <img src={Add} alt="" /></Button>
                          </Link>
                        </Col>
                      </Row>
                    {/* </Form> */}
                  </div>
                </Col>
                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0">
                  <Table responsive className="table-custom text-nowrap mt-4">
                  <thead>
                    <tr>
                      <th className='text-left'>รหัสสมาชิก</th>
                      <th className='text-left'>ชื่อโชว์รูม</th>
                      <th className='text-left'>สาขา</th>
                      <th>ใช้บริการล่าสุด</th>
                      <th>ยอดใช้บริการ</th>
                      <th>อายุสมาชิก</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { 
                      Customers.map( (customer, key) => {
                        return (
                          <tr key={key}>
                            <td className='text-left'>{customer.customer_code}</td>
                            <td className='text-left'>{customer.organization}</td>
                            <td>{ customer.branch_name }</td>
                            <td >
                              { customer.job_company_date.length > 0 ? dayjs(customer.job_company_date[0].created_at).format('DD-MM-YYYY') : '-'}
                            </td>
                            <td>{ customer.job_company_sum_total_price !== null ? Intl.NumberFormat('en-US').format(customer.job_company_sum_total_price) : 0 }</td>
                            <td >{ dayjs(customer.created_at).fromNow(true) }</td>
                            <td>
                              <LinkContainer to={`/b2b/${customer.id}/edit`} className="link">
                                <Button className="view-button">
                                  <img src={ViewData} alt="" />
                                </Button>
                              </LinkContainer>
                            </td>
                            <td>
                              <Button
                                className="button-package"
                                onClick={(e) =>
                                  deleteConfirmation(customer)
                                }
                              >
                                <img src={Delete} />
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
                  </Col>
                </Row>

                <Row className="g-0 pt-5 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                    {
                      !isLoading ? 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                      : 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                    }
                    </div>
                  </Col>
                </Row>   
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบสมาชิก { `${deleting?.organization}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยินยันการลบสมาชิก { `${deleting?.organization}` }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteCustomer() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
