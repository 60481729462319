import { useDispatch } from "react-redux";
import { useHistory , Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

import {
  Container,
  Row,
  Col,
  Button,
  Form
} from 'react-bootstrap';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';

// API
import { ImportExcel } from 'actions/import_excel';

// Icon
import Setting from 'assets/icon/setting.png';
import Back from 'assets/icon/back.png';

export default function Index() {
  const history = useHistory()
  const dispatch = useDispatch()

  const DocumentFileRef = useRef(null);
  const [Data, setData] = useState({
    excel: null
  });
  const [validated, setValidated] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [Label, setLabel] = useState(null);
  const [Message, setMessage] = useState(null);
  const [isProcessed, setProcessed] = useState(false);
  const [isError, setError] = useState(false);
  const [Counter, setCounter] = useState(0);

  const SetExcelFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setData({ ...Data, excel : e.target.files[0]})
      setLabel(e.target.files[0].name)
    }
  }

  const ToggleExcelFileInput = () => {
    if(DocumentFileRef.current) DocumentFileRef.current.click();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true);
    
    if (form.checkValidity()) {
      let formData = new FormData();
      
      formData.append('excel', Data.excel)
      setProcessing(true)
      setProcessed(false)
      dispatch(ImportExcel(formData)).then((response) =>{
        setError(false)
        setData({excel: null})
        setLabel(null)
        setMessage(response.data.message)
        setCounter(response.data.data.length)
        setProcessing(false)
        setProcessed(true)
      }).catch(e => {
        setError(true)
        setProcessing(false)
        setProcessed(true)
        setData({excel: null})
        setLabel(null)
        if (e.response.data && e.response.data.message) {
          setMessage(e.response.data.message)
        }
      });
    }
  };

  function close() {
    history.push(`/login`);
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk" style={{ minHeight: '100vh' }}>
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>นำเข้า</Link> &gt; Excel</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ประเภทรถ</b>
                </h5>
              </div>
              <div className="modal-body" style={{ minHeight: '700px' }}>
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} className='mt-5 d-flex justify-content-center'>
                      <h1 style={{ cursor: 'pointer' }} onClick={ () => ToggleExcelFileInput() }>{ Label || 'กรุณาอัพโหลดไฟล์ Excel (.xlsx)' }</h1>
                    </Col>

                    <Col xs={12}>
                      {
                        Label ?
                          <Button type="submit" className="button">
                            เพิ่มข้อมูล
                          </Button>
                        :
                          <Button type="button" className="button" onClick={ () => ToggleExcelFileInput()}>
                            อัพโหลดไฟล์ Excel
                          </Button>
                      }
                      <Form.Control
                        type="file"
                        className="d-none"
                        id="document-files"
                        ref={DocumentFileRef}
                        label={Data.Document}
                        accept=".xlsx"
                        onChange={(e) => SetExcelFile(e) }
                        required
                      />
                    </Col>

                    {
                      isProcessing ?
                      <Col xs={12} className='mt-5'>
                        <h3>กำลังประมวลผล</h3>
                      </Col>
                      :
                      isProcessed &&
                      <Col xs={12} className='mt-5'>
                        <h3 className={ isError ? 'text-danger' : 'text-success' }>{ Message }</h3>
                        {
                          Message === 'นำเข้าข้อมูลสำเร็จ' &&
                          <h3>{ 'จำนวนข้อมูลทั้งหมด ' + Counter }</h3>
                        }
                      </Col>
                    }
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
