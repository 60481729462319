import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Dropdown,
  Form,
  Tab,
  Tabs,
} from 'react-bootstrap';
import Select, { components } from 'react-select';
import Footer from 'Components/Footer';
import { useHistory } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import MenuProperty from 'MiddleSystems/Components/layout/MenuProperty';
import Back from 'assets/icon/back.png';
import Staff from 'assets/icon/staff.png';
import PropertyImage from 'assets/icon/asset.png'
import Branch_manage from 'assets/icon/branch-manage.png';
import Write from 'assets/icon/flat-style-circle-write.png';
import Note from 'assets/icon/address_book.png';
import View from 'assets/icon/view-member.png';
import Property from '../Components/Property/Property';
import ViewAll from '../Components/Property/ViewAll';
import Data from '../Components/Property/Data';
import Storage from '../Components/Property/Storage';
import uploadImage from 'assets/icon/upload.png';

import Increase from 'assets/icon/add-staff.png';
import Save from 'assets/icon/save.png';
import Turn_Off from 'assets/icon/new-re-turn.png';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import { Formik, FieldArray, Field } from 'formik';
import Delete from 'assets/icon/delete.png';

//api
import { updateStorage, LoadPropertyStorageById, destroyStorage } from 'actions/property/index'
import { Branch } from 'actions/branch/index'


import { useDispatch } from 'react-redux';

var dayjs = require('dayjs')


export default function EditStorage(props) {
  const { id, information_id } = props.match.params
  const dispatch = useDispatch();
  const history = useHistory();

  const [statepage, setStatepage] = useState('');
  const handleSelectPage = (e) => setStatepage(e);
  const [key, setKey] = useState('Data');
  const [Branches, setBranches] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [PropertyInformation , setPropertyInformation] = useState([]);

  const [validated, setValidated] = useState(false);
  const StorageModel = {
    key: 0,
    Id: null,
    BrancheId: null,
    PlacementPoint: null,
    Amount: null,
    RemoveFileArr: null,
    status: 'new',
    PropertyInformationId:null
  }

  const GalleryModel = {
    key: 0,
    id: null,
    file: null,
    name: null,
    status: 'new',
    width: null,
    height: null,
  }
  const [FormStorage, setFormStorage] = useState({
    StorageList:[
      StorageModel
    ],
    Gallery:[
      GalleryModel,
    ],
    RemoveFileArr:[],
  })


  function CalculateRemaining (data){
    let inArrTotal = []
    inArrTotal = data.length > 0 ? data.reduce((accum,item) => Number(accum) + Number(item.Amount), 0): 0
   

    return inArrTotal ? inArrTotal : 0
  }

  const [Total,setTotal] = useState({
    Amount: FormStorage.Amount ? FormStorage.Amount : 0,
    Remaining: (Number(FormStorage.Amount ? FormStorage.Amount : 0) - CalculateRemaining(FormStorage.StorageList)),
    Use: CalculateRemaining(FormStorage.StorageList)
  }) 

  const InsertStorage = () => {
    if(FormStorage.Amount > 0){
      let inArrTotal = []
      if(FormStorage.StorageList) inArrTotal = FormStorage.StorageList.reduce((accum,item) => Number(accum) + Number(item.Amount), 0)
        
      if(FormStorage.Amount > inArrTotal){
        let insert = StorageModel
        insert.key = FormStorage.StorageList.length
        setFormStorage({ ...FormStorage, StorageList:[...FormStorage.StorageList, insert]})
      }else{
        alert(`จำนวนสินค้าคงเหลือไม่พอ ${FormStorage.Amount - inArrTotal}`)
      }
    }else{
      alert('โปรดใส่จำนวนสินค้าจัดซื้อ')
    }
  }

  const RemoveStorage = async (key,item) => {
    const list = FormStorage.StorageList
    // console.log('list',list);
    const index = list.filter((item) => item.key !== key)
    if(item && item.Id){
      // setFormStorage({ ...FormStorage, Remove: {"id": item.Id} })
      // setFormStorage(prevState => ({
      //   remove: [...prevState.remove, {"id": item.Id}]
      // }))
    }
    // list.splice(index, 1)
    setFormStorage({ ...FormStorage, StorageList: index })
      console.log('FormStorage',FormStorage);
  }

  async function GetPropertyInformation(){
    setLoading(true)
    try {
      const response = await dispatch(LoadPropertyStorageById(information_id));
        const info = response.data.data
        let arr = []
        if(info.property_storage.length > 0){
          arr =  info.property_storage.map( (item,key) => {
            return {
              key: key,
              Id: item.id,
              BrancheId: item.branche_id,
              PlacementPoint: item.placement_point,
              Amount: item.amount,
              PropertyInformationId: item.property_information_id,
              status: 'old',
            }
          });
        }
        let warranties = []
        if(info.warranties.length > 0){
          warranties =  info.warranties.map( (item,key) => {
            return {
              key: key,
              id: item.id,
              file: null,
              image: item.image,
              name: item.original_name,
              width: item.width,
              height: item.height,
              status: 'old',
            }
          });
        }
        // console.log('StorageModel',StorageModel);
        // console.log('warranties',warranties);
        setFormStorage({
          PurchaseDate : dayjs(info.purchase_date),
          BuyFrom: info.buy_from,
          BoughtBy: info.bought_by,
          Price : info.price,
          Amount: info.amount,
          // WarrantyImage: info.warranty_image,
          Gallery: warranties,
          StorageList: arr.length > 0 ? arr : StorageModel,
          RemoveFileArr: null,
        })
        setTotal({
          Amount: info.amount,
          Remaining: (Number(info.amount) - arr ? CalculateRemaining(arr) : 0),
          Use: arr ? CalculateRemaining(arr) : 0
        })
        setLoading(false);

        // console.log('FormStorage.StorageList',FormStorage.StorageList);
      }  catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
  }

  async function GetBranches(){
    setLoading(true)
    try {
    const response = await dispatch(Branch());
      const branches = response.data.data
      setBranches(branches)
      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  function uploadFile(e){
  
    let insert = GalleryModel
    insert.key = FormStorage.Gallery.length
    insert.file = e.target.files[0]
    insert.image = URL.createObjectURL(e.target.files[0])
    insert.name =  e.target.files[0].name
    insert.width = 500
    insert.height = 500
    insert.status = 'new';
    setFormStorage({...FormStorage, Gallery:[...FormStorage.Gallery, insert]})
    console.log(' FormStorage.Gallery', FormStorage.Gallery);
  }

  function closePage() {
    let path = `/property`;
    history.push(path);
  }
  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const RemoveFile = (data) =>{
    const list = FormStorage.Gallery
    const index = list.findIndex((item) => item.key === data.key)
    const remove = list.find((item) => item.key === data.key)
    list.splice(index, 1)
    setFormStorage({...FormStorage, Gallery: list})
  }


  async function updateStorageList(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    const Remaining = (Number(FormStorage.Amount) - CalculateRemaining(FormStorage.StorageList));
    if(Remaining >= 0){ 

      let formData = new FormData();
      formData.append('property_id', id)
      formData.append('purchase_date', dayjs(FormStorage.PurchaseDate).format('YYYY-MM-DD'))
      formData.append('buy_from', FormStorage.BuyFrom)
      formData.append('bought_by', FormStorage.BoughtBy)
      formData.append('price', FormStorage.Price)
      formData.append('amount', FormStorage.Amount)
      // if(FormStorage.WarrantyImage) formData.append('warranty_image', FormStorage.WarrantyImage)
      if(FormStorage.Gallery.length > 0){
        for (let i = 0; i < FormStorage.Gallery.length; i++) {
          // if(FormStorage.Gallery[i].status === 'new') formData.append(`gallery[${i}]`, FormStorage.Gallery[i].file);
          if(FormStorage.Gallery[i].file) formData.append(`galleries[${i}][file]`, FormStorage.Gallery[i].file);
          if(FormStorage.Gallery[i]) formData.append(`galleries[${i}][id]`, FormStorage.Gallery[i].id);
          if(FormStorage.Gallery[i].status) formData.append(`galleries[${i}][status]`, FormStorage.Gallery[i].status);
        }
      }
      // if(FormStorage.RemoveFileArr.length > 0){
      //   for (let i = 0; i < FormStorage.RemoveFileArr.length; i++) {
      //     formData.append(`remove_file[${i}]`, JSON.stringify(FormStorage.RemoveFileArr[i]));
      //   }
      // }
      if(FormStorage.StorageList.length > 0){
        for (let i = 0; i < FormStorage.StorageList.length; i++) {
          formData.append(`property_storages[${i}]`, JSON.stringify(FormStorage.StorageList[i]));
        }
      }
    
      // if(FormStorage.RemoveFileArr.length > 0){
      //   for (let i = 0; i < FormStorage.RemoveFileArr.length; i++) {
      //     formData.append(`remove_file[${i}]`, JSON.stringify(FormStorage.RemoveFileArr[i]));
      //   }
      // }
      console.log('updateStorageList',FormStorage);
      formData.append('_method', 'PUT');
      try {
        const response = await dispatch(updateStorage({information_id,formData}));
      
        if(response){
          history.push(`/property/data-property-edit/${id}`);
        }else{
          console.log('failed')
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }

    }else{
      alert(`จำนวนสินค้าคงเหลือไม่พอ ${Remaining}`)
    }


  }

  function downloadImage(file){
    const { image ,name} = file

    const pageImage = new Image();
    pageImage.src = image;
    pageImage.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = pageImage.naturalWidth;
      canvas.height= pageImage.naturalHeight;
      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(pageImage, 0, 0);    
      saveScreenshot(canvas,name);
    }
  }


  function saveScreenshot(canvas,name) {
    let fileName = name
    const link = document.createElement('a');
    link.download = fileName + '.png';   
    canvas.toBlob(function(blob) {
        // console.log(blob)
        link.href = URL.createObjectURL(blob);
        link.click();
    });
};

  useEffect(() => {
    GetBranches();
    GetPropertyInformation();
  }, []);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }
  return (
    <>
      
      <Container
        fluid
        className="package-container Staff   Property Property-data bg-mrk"
      >
        <Form noValidate validated={validated} onSubmit={updateStorageList} >
          <div className="package">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={PropertyImage} />
                    <b>แก้ไขรายการจัดเก็บ</b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closePage}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closePage}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={PropertyImage} />
                    <b>แก้ไขรายการจัดเก็บ</b>
                  </h5>
                </div>
                <div className="modal-body">
                  <Row className="g-0 tabsicon justify-content-center ">
                    <TabsIcon />
                  </Row>
                  <Row>
                    <div className="box-typecar "></div>
                  </Row>
                  <Row className="justify-content-center ">
                    <Col lg={10} className="d-flex">
                      <p className="title">ข้อมูลจัดซื้อ</p>
                    </Col>
                    <Col lg={10}>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>วันที่ซื้อ</Form.Label>
                            </Col>
                            <Col>
                              {/* <DatePicker
                                onChange={(e) => setFormStorage({ ...FormStorage, PurchaseDate: e.target.value })}
                                placeholder=""
                                suffixIcon={<img src={Calendar} />}
                                style={{ borderRadius: '0px' }}
                              /> */}
                              <DatePicker
                                    placeholder="กรุณาระบุวันที่"
                                    suffixIcon={<img src={Calendar} />}
                                    value={FormStorage.PurchaseDate}
                                    onChange={ (data) => setFormStorage({ ...FormStorage , PurchaseDate: data })}
                                  />
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>ซื้อจาก</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control className="input"
                                required
                                value={FormStorage.BuyFrom}
                                onChange={(e) => setFormStorage({ ...FormStorage, BuyFrom: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>ซื้อโดย</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control className="input"
                                required
                                value={FormStorage.BoughtBy}
                                onChange={(e) => setFormStorage({ ...FormStorage, BoughtBy: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>ราคาต่อหน่วย</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                required
                                placeholder=""
                                className="input"
                                value={commaCase(FormStorage.Price)}
                                onFocus={
                                  (e) => setFormStorage({ ...FormStorage, Price: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                                }
                                onBlur={
                                  (e) => setFormStorage({ ...FormStorage, Price: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                                }
                                onChange={
                                  (e) => setFormStorage({ ...FormStorage, Price: e.target.value.replaceAll(',', '') })
                                }
                                onKeyPress={(event) => {
                                  if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                    event.preventDefault();
                                  }
                                }}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>จำนวน</Form.Label>
                            </Col>
                            <Col>
                            <Form.Control
                                type="text"
                                required
                                placeholder=""
                                className="input"
                                value={commaCase(FormStorage.Amount)}
                                onFocus={
                                  (e) => setFormStorage({ ...FormStorage, Amount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                                }
                                onBlur={
                                  (e) => setFormStorage({ ...FormStorage, Amount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                                }
                                onChange={
                                  (e) => setFormStorage({ ...FormStorage, Amount: e.target.value.replaceAll(',', '') })
                                }
                                onKeyPress={(event) => {
                                  if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                    event.preventDefault();
                                  }
                                }}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Row>
                            <Col lg={4} className="d-flex ">
                              <Form.Label>ใบรับประกันสินค้า</Form.Label>
                            </Col>
                            <Col className='mt-3'>
                            { FormStorage.Gallery?.map( (file, key) => {
                                return (
                                  <Col lg={12} className="">
                                  <Row>
                                    <Col lg={12}>
                                      <Row>
                                        {
                                          file.image ? (
                                            <img width={file.width} height={file.height} src={file.image} onClick={() => {
                                              downloadImage(file)
                                            }}></img>
                                          ) : (
                                            <img alt='ไฟล์รูปเสียหาย'></img>
                                          )
                                        }
                                        {/* <p className='text-left p-0 m-0'>{ file.name }</p> */}
                                        {
                                           file.name ? 
                                           <Button
                                                 className="button-image"
                                                 onClick={() => RemoveFile(file)}
                                               >
                                                   <img src={Delete} />
                                             </Button> : null
                                        }
                                        
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                )
                              }) }
                            </Col>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center set-button-upload">
                          <Form.Label className="button-image">
                          <input type="file" onChange={uploadFile} />
                            <img src={uploadImage} /> &nbsp; อัพโหลด
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center  mt-5">
                    <Col lg={10} className="d-flex">
                      <p className="title">ข้อมูลจัดเก็บ</p>
                    </Col>
                    <Col lg={10}>
                    {
                      FormStorage?.StorageList?.map(  (item, key) => {
                        return (
                          <Col lg={10} className="mt-4 mb-3">
                              <Row>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={4} className="d-flex ">
                                      <Form.Label>สถานที่</Form.Label>
                                    </Col>
                                    <Col>
                                      {/* <Form.Control className="input" 
                                        type="text"
                                        name="BrancheId"
                                        value={item.BrancheId}
                                        onChange={
                                          (e) => setFormStorage(prevState => ({...FormStorage,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === key ? { ...el, BrancheId: e.target.value }: el
                                            )
                                          }))
                                        }
                                        ></Form.Control> */}
                                    <Select
                                          placeholder="สาขา"
                                          options={Branches}
                                          getOptionLabel={option => option.name}
                                          getOptionValue={option => option.id}
                                          isClearable={false}
                                          isSearchable={false}
                                          value={item.BrancheId ? Branches.find((data) => {return data.id === item.BrancheId}) : null}
                                          onChange={
                                            (e) => setFormStorage(prevState => ({...FormStorage,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === key ? { ...el, BrancheId: e.id }: el
                                              )
                                            }))
                                          }
                                          className="select-list w-100"
                                        />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col></Col>
                              </Row>

                              <Row>
                                <Col lg={6}>
                                  <Row className='mt-2'>
                                    <Col lg={4} className="d-flex ">
                                      <Form.Label>จุดที่วาง</Form.Label>
                                    </Col>
                                    <Col>
                                    <Form.Control className="input" 
                                      type="text"
                                      name="PlacementPoint"
                                        value={item.PlacementPoint}
                                        onChange={
                                          (e) => setFormStorage(prevState => ({...FormStorage,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === key ? { ...el, PlacementPoint: e.target.value }: el
                                            )
                                          }))
                                        }
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col></Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={4} className="d-flex ">
                                      <Form.Label>จำนวน</Form.Label>
                                    </Col>
                                    <Col>
                                      <Form.Control
                                        type="text"
                                        required
                                        placeholder=""
                                        className="input"
                                        value={commaCase(item.Amount)}
                                        onFocus={
                                          (e) => setFormStorage(prevState => ({...FormStorage,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === key ? { ...el, Amount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                            )
                                          }))
                                        }
                                        onBlur={
                                          (e) => setFormStorage(prevState => ({...FormStorage,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === key ? { ...el, Amount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                            )
                                          }))
                                        }
                                        onChange={
                                          (e) => setFormStorage(prevState => ({...FormStorage,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === key ? { ...el, Amount: e.target.value.replaceAll(',', '') }: el
                                            )
                                          }))
                                        }
                                        onKeyPress={(event) => {
                                          if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="d-flex"></Col>
                              </Row>
                              <Col
                                lg={2}
                                xl={2}
                                className="d-flex align-items-center justify-content-center mt-3 mb-3"
                              >
                                {
                                  (
                                    <Button
                                      className="button-image"
                                      onClick={() => RemoveStorage(key,item)}
                                    >
                                      <img src={Delete} />
                                    </Button>
                                  )
                                }
                              </Col>
                          </Col>
                        );
                      })
                    }

                  <Row>
                    <Col md={12} lg={6}>
                            <Row>
                              <Col lg={4}  className="d-flex "></Col>
                              <Col className="mt-4 mb-3">
                                <Button type="button" onClick={InsertStorage} className="button-increase" >
                                  <img src={Increase} /> &nbsp; เพิ่มรายการ
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="d-flex"></Col>
                        </Row>
                    </Col>
                  </Row>
                  <Row className="mt-5 m-5  justify-content-between">
                    <Col xs={6} sm={6} md={6} lg={4}  className="">
                      <Button type="submit" className="button-increase">
                        <img src={Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={4} className="">
                      <Button type="button" className="button-increase" onClick={closePage}>
                        <img src={Turn_Off} /> &nbsp; ออก
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Container>
      <Footer />
    </>
  );
}
