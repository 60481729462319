import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Dropdown,
  Form,
} from 'react-bootstrap';

import BillImage from 'assets/icon/job.png'
import Footer from '../Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/flat-style-circle-write.png';
import Arrow from '../assets/icon/arrow.png';
import AddLink from '../assets/icon/add-link.png';

import Document from '../assets/icon/document.png';
import Search from '../assets/icon/eyes-search.png';
import Save from '../assets/icon/flat-style-circle-save.png';
import Return from '../assets/icon/flat-style-circle-turn-on.png';
import Product_Component from '../Components/Job/Product';
import Service_Component from '../Components/Job/Service';
import Package_Component from '../Components/Job/Package';
import { useDispatch } from 'react-redux';

//API
import { Customer,FindCustomer } from 'actions/customer'
import { CarOwnerList } from 'actions/customer/car'
import { PackageList, PackageTypeList } from 'actions/package';
import { JobList } from 'actions/jobs'

import { List } from 'actions/employee'
import { Branch } from 'actions/branch'

export default function CompanyB2BMember() {
  
  const history = useHistory();
  const dispatch = useDispatch();

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

  function close() {
    let path = `/job`;
    history.push(path);
  }

  const [selectSearch, setSelectSearch] = useState({ kindOfStand: "customer"});
  const [isLoading, setLoading] = useState(true);
  const [filterCustomers, setfilterCustomers] = useState([]);
  const [filterCustomersDefault, setfilterCustomersDefault] = useState([]);
  const [filterCustomersBySelect, setfilterCarOwnersBySelect] = useState([]);
  const [filterCarOwners, setfilterCarOwners] = useState([]);
  const [filterCarOwnersDefault, setfilterCarOwnersDefault] = useState([]);
  const [Company,setCompany] = useState([]);
  const [ShowRoomEmployee,setShowRoomEmployee] = useState([]);
  const [SearchCustomerName, setSearchCustomerName] = useState('');
  const [SearchCarLicensePlateName, setSearchCarLicensePlateName] = useState('');
  const [CustomerState, setCustomerState] =  useState(
    {
      customer_id: null,
      customer_code: '',
      customer_name: '',
      nick_name: '',
      aka:'',
      phone: '',

    }
  );

  const [Data,setData] = useState({
    customer_id: null,    
    not_have_car: true,
    PackageTypeId: '',
    PackageTypeName: '',
    PackageStorageList:[],
    PackageAppointment:[],
    employee_id: null,
    branch_id: null,
    PackageId: '',
    PackageName:'',
    CleanCar: true,
    ContinuousService: false,
    SprayCoating: false,
    RenewalClaim:false,
    CheckSetting:false,
    Treatment:false,
    KeepWorking:false,
  });

  const [displayDetail,setDisplayDetail] = useState(
    {
      car_id: null,
      license_plate: '',
      car_brand_name: '',
      car_model_name: '',
      color: '',
      plate_type: '',
      province_name: '',
      chassis_number: '',
      special_need: '',
      note:'',
    }
  );

  const handleChange = e => {
    e.persist();
    // console.log(e.target.value);

    setSelectSearch(prevState => ({
      ...prevState,
      kindOfStand: e.target.value
    }));
    clearSearch()
    
  };

  function SearchfilterCustomers(value){

    setSearchCustomerName(value)
    setCustomerKeyword(value)
    let searchList = [];
    if (value){
      const list = selectSearch.kindOfStand === 'customer' ? filterCustomersDefault : filterCustomersBySelect
      list.map(item => {
        if(item.full_name){
          if(item.full_name.includes(value)){
            searchList.push(item);
          }
        } else if(item.organization){
          if(item.organization.includes(value)){
            searchList.push(item);
          }
        }
      })
      setfilterCustomers(searchList);
    }else{
      if(selectSearch.kindOfStand === 'customer'){
        setfilterCustomers(filterCustomersDefault);
      }else{
        setfilterCustomers(filterCustomersBySelect);
      } 
      
    }
  }

  function SearchfilterLicensePlate(value){
    
    setSearchCarLicensePlateName(value)
    setCarOwnerPlateName(value)
    let searchList = [];
    if (value){
      filterCarOwnersDefault.map(item => {
        if(item.license_plate){
          if(item.license_plate.includes(value)){
            searchList.push(item);
          }
        }
      })
      setfilterCarOwners(searchList);
    }else{
      setfilterCarOwners(filterCarOwnersDefault);
    }
  }


  function clearSearch(){
    
    setCustomerState({...CustomerState, 
      customer_id: null,
      customer_name: null,
      not_have_car: null,
      CarOwnerLicensePlate: '',
    })
    setDisplayDetail({...displayDetail,
      phone: '',
      car_id:null,
      customer_code: '',
      license_plate: '',
      car_brand_name: '',
      car_model_name: '',
      color: '',
      plate_type: '',
      province_name: '',
      chassis_number: '',
      special_need: '',
      note:'',
    });


  }

  function clearSearchE() {
    setCustomerState({...CustomerState, 
      employee_id: null,
      branch_id: null,
    })
  }

  function setCustomersList(item, key) {

    console.log(item)
  
    if(selectSearch.kindOfStand === 'customer'){
      setCustomerState({...CustomerState, customer_id: item.id ,
        customer_name: item.type === 'บุคคลธรรมดา' ? item.first_name + ' ' + item.last_name : item.type === 'นิติบุคคล' ? item.organization : item.first_name,
        not_have_car: item.cars.length < 1 ? true : false,
        customer_code: item.customer_code, 
        nick_name: item.nickname,
        aka: item.aka,
      })
      setSearchCustomerName(item.type === 'บุคคลธรรมดา' ? item.first_name + ' ' + item.last_name : item.type === 'นิติบุคคล' ? item.organization : item.first_name)
      setfilterCarOwners(item.cars)   
      setfilterCarOwnersDefault(item.cars)
    }else{
      setCustomerState({...CustomerState, customer_id: item.id ,
        customer_name: item.type === 'บุคคลธรรมดา' ? item.first_name + ' ' + item.last_name : item.type === 'นิติบุคคล' ?
        item.organization : item.first_name,
        customer_code: item.customer_code, 
        nick_name: item.nickname,
        aka: item.aka,
      })
      setSearchCustomerName(item.type === 'บุคคลธรรมดา' ? item.full_name : item.type === 'นิติบุคคล' ? item.organization : item.first_name)
    }
    setDisplayDetail({...displayDetail , 
      phone: JSON.parse(item.phone), 
      note: item.note, 
      special_need: item.special_need,
    })
  }

  function setEmployeeList(item, key){
    // console.log(item)
    setData({...Data, employee_id: item.id})
    setSearchEmployeesName(item ? item.fullname + ' - ' + item.branch.name : '')
    parseDataJob.employee_id = item.id ? item.id : null
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function setBranchList(item, key){
    // console.log(item)
    setData({...Data, branch_id: item.id})
    setSearchBranchName(item ? item.name : '')
    parseDataJob.branch_id = item.id ? item.id : null
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function setCarOwnerList(item,key){
    setData({...Data, CarOwnerLicensePlate: item.license_plate })
    if(selectSearch.kindOfStand === 'car'){
      setfilterCustomers(item.customers)
      setfilterCarOwnersBySelect(item.customers)
      // setJob(item.customers.job)
    }

    setSearchCarLicensePlateName(item.license_plate ? item.license_plate : '')
    
    setDisplayDetail({...displayDetail , 
      car_id: item.id,
      license_plate: item.license_plate ? item.license_plate : '',
      car_brand_name: item.car_serie && item.car_serie.car_model && item.car_serie.car_model.car_brand && item.car_serie.car_model.car_brand.name && item.car_serie.car_model.car_brand.name ? item.car_serie.car_model.car_brand.name : '',
      car_model_name: item.car_serie && item.car_serie.car_model && item.car_serie.car_model.model ? item.car_serie.car_model.model : '',
      color: item.color,
      plate_type: item.plate_type,
      province_name: item.province && item.province.name_th ? item.province.name_th : '',
      chassis_number: item.chassis_number,
    })
  }

  function LinkTo(url){
    history.push(url)
  }


  function createCustomer(){
    history.push('/member/add')
  }

  function createCarOwner(){
    history.push(`/member/edit/${Customer.customer_id}/add-car`);
  }

  const [CustomerKeyword,setCustomerKeyword] = useState('');

  const GetCustomer = async () => {
    setLoading(true)
    const params = {
      type: ['บุคคลธรรมดา','นิติบุคคล'],
      customer_name: CustomerKeyword ,
      take: 10,
    }
    try {
      const response = await dispatch(Customer(params));
      setfilterCustomers(response.data.data);
      setfilterCustomersDefault(response.data.data)
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [CarOwnerPlateName,setCarOwnerPlateName] = useState('');

  const GetCarOwner = async () => {
    setLoading(true)
    try {
      const params = {
        have_owner: true,
        keyword: CarOwnerPlateName,
        take: 10,
      }
      const response = await dispatch(CarOwnerList(params));
      setfilterCarOwners(response.data.data);
      setfilterCarOwnersDefault(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  async function FindCompany(){
    setLoading(true)
    try {
      const response = await dispatch(FindCustomer(parseDataJob.company_id));
      setCompany(response.data.data);
      // show_room_employee_id
      const show_room = response.data.data && response.data.data.showroom_contacts
      const result = show_room?.find((item) => {
        return item.id === parseDataJob.show_room_employee_id
      });
      setShowRoomEmployee(result)
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [filterEmployees, setfilterEmployees] = useState([]);
  const [filterEmployeesD, setfilterEmployeesD] = useState([]);
  const GetEmployee = async () => {
    try {
      const pr = {
        all: true,
        keyword: EmployeesKeyword,
      }
      const res = await dispatch(List(pr));
      let data = res.data.data;
      setfilterEmployees(data)
      setfilterEmployeesD(data)
      // console.log(filterEmployeesD)
    } catch (e) {
      console.error(e)
    } finally {
      console.log('finally')
    }
  }

  const [filterBranch, setfilterBranch] = useState([]);
  const [filterBranchD, setfilterBranchD] = useState([]);
  const GetBranch = async () => {
    try {
      const res = await dispatch(Branch());
      let data = res.data.data;
      setfilterBranch(data)
      setfilterBranchD(data)
      // console.log(data)
    } catch (e) {
      console.error(e)
    } finally {
      console.log('finally')
    }
  }

  const [SearchEmployeesName, setSearchEmployeesName] = useState('');
  const [EmployeesKeyword,setEmployeesKeyword] = useState('');
  function SearchfilterEmployees(value){

    setSearchEmployeesName(value)
      setEmployeesKeyword(value)
  }
  
  const [SearchBranchName, setSearchBranchName] = useState('');
  const [BranchKeyword,setBranchKeyword] = useState('');
  function SearchfilterBranch(value){

    setSearchBranchName(value)
      setBranchKeyword(value)
  }

  function createForm(){
      console.log(displayDetail)
      console.log(CustomerState)
      if(CustomerState.customer_id === null){
        return alert('เลือกลูกค้า')
      }
      if(displayDetail.car_id === null){
        return alert('เลือกป้ายทะเบียน')
      }
      if(CustomerState.customer_id && displayDetail.car_id){
       
        const data = {
          customer_id : CustomerState.customer_id,
          car_id: displayDetail.car_id,
          status_job: 'new',
          employee_id: Data.employee_id,
          branch_id: Data.branch_id,
          company_id: parseDataJob.company_id ? parseDataJob.company_id : null, //  == employee_id 
          show_room_employee_id: parseDataJob.show_room_employee_id ? parseDataJob.show_room_employee_id : null,  // == show_room_id
          type: parseDataJob.type ,
          PackageList:[],
          PackageListAppointment:[],
          // show_room_employee_id: '',
          // company_id: '',
          // type: 'b2b',
        }
        localStorage.setItem('job-inside-data',JSON.stringify(data));
        history.push('/job/inside-create');
      
    }
  }

  useEffect(() => {
    GetEmployee()
    GetBranch()
    console.log('parseDataJob',parseDataJob);
    selectSearch.kindOfStand === 'customer' ?  GetCustomer() : GetCarOwner();
    FindCompany();
    
  }, [selectSearch.kindOfStand]);

  useEffect(() => {
    if(EmployeesKeyword){
      GetEmployee()
    }
  },[EmployeesKeyword]);

  useEffect(() => {
    if(CustomerKeyword){
      GetCustomer()
    }
  },[CustomerKeyword]);

  useEffect(() => {
    if(CarOwnerPlateName){
      GetCarOwner()
    }
  },[CarOwnerPlateName]);

  useEffect( () => {

    if(parseDataJob.employee_id){
      const result = filterEmployeesD?.find((data) => {return data.id === parseDataJob.employee_id })
      setSearchEmployeesName(result ? result.fullname + ' - ' + result.branch.name : '')
      
    }
  },[])
  

  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  JOB &gt; Company (B2B)
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  JOB &gt; Company (B2B)
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="justify-content-center set-height-order">
                  <Col lg={11}>
                    <Row className="mt-4 mb-4 justify-content-center">
                      <Col xs={12} lg={6}>
                        <Row className="justify-content-center">
                          <Col className="d-flex justify-content-start">
                            <p className="font-weight-bold">ค้นหาลูกค้า</p>
                          </Col>
                        </Row>
                        <Row className=" mt-3 mb-3">
                          <Col className="d-flex justify-content-start set-position-radio">
                            <Form.Group controlId="kindOfStand">
                              <Form.Check
                                value="customer"
                                type="radio"
                                aria-label="radio 1"
                                label="ค้นหาด้วยชื่อ"
                                onChange={handleChange}
                                checked={selectSearch.kindOfStand === "customer"}
                                defaultValue={true}
                              />
                              <Form.Check
                                value="car"
                                type="radio"
                                aria-label="radio 2"
                                label="ค้นหาด้วยป้ายทะเบียน"
                                onChange={handleChange}
                                checked={selectSearch.kindOfStand === "car"}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row id="search">
                          {
                            selectSearch.kindOfStand === 'customer' ? (
                              <Row className=" mt-3 mb-3"  >
                                <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='ค้นหาด้วยชื่อ,รหัสสมาชิก,เบอร์โทรศัพท์'
                                            onInput={(e) => {SearchfilterCustomers(e.target.value)}}
                                            onChange={(e) => clearSearch()}
                                            value={SearchCustomerName}
                                            required
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterCustomers?.map((item, key) => {
                                              return (
                                                <Dropdown.Item className="font-black-only" onClick={() => {setCustomersList(item, key)}}>{item.type === 'บุคคลธรรมดา' ? `${item.first_name} ${item.last_name}` : 
                                                `${item.type === 'นิติบุคคล' ? item.organization : item.first_name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="font-black-only" onClick={() => { createCustomer() }}>
                                          <img src={AddLink} /> เพิ่มรายชื่อ
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className=" mt-3 mb-3"  >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown' >
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100' disabled={CustomerState.not_have_car}>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='ค้นหาเลขทะเบียน'
                                            onInput={(e) => SearchfilterLicensePlate(e.target.value)}
                                            value={SearchCarLicensePlateName}
                                            required
                                            disabled={CustomerState.not_have_car}
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100' >
                                        <div className='limit'>
                                          {
                                            filterCarOwners?.map((item, key) => {
                                              return (
                                                <Dropdown.Item className="font-black-only" onClick={() => setCarOwnerList(item, key)}>{`${item.license_plate  + ' (' + item.chassis_number + ')'}`}</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="font-black-only" onClick={() => { createCarOwner() }}>
                                          <img src={AddLink} /> เพิ่มรถ
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                              </Row>

                              
                              
                            ) : (
                            <Row className=" mt-3 mb-3">
                              <Col lg={12}>
                                 <Row >
                                    <Col lg={12}>
                                      <Dropdown className='w-100 custom-dropdown' >
                                        <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                          <div className='d-flex'>
                                            <Form.Control
                                              className="w-100"
                                              placeholder='ค้นหาเลขทะเบียน'
                                              onInput={(e) => SearchfilterLicensePlate(e.target.value)}
                                              value={SearchCarLicensePlateName}
                                              required
                                            ></Form.Control>
                                            <Button className='arrow'></Button>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100' >
                                          <div className='limit'>
                                            {
                                              filterCarOwners?.map((item, key) => {
                                                return (
                                                  <Dropdown.Item className="font-black-only" onClick={() => setCarOwnerList(item, key)}>{`${item.license_plate  + ' (' + item.chassis_number + ')'}`}</Dropdown.Item>
                                                )
                                              })
                                            }
                                          </div>
                                          {/* <Dropdown.Divider /> */}
                                          {/* <Dropdown.Item onClick={() => { createCarOwner() }}>
                                            <img src={AddLink} /> เพิ่มรถ
                                          </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </Row>
                                  <Row className=" mt-3 mb-3">
                                    <Col lg={12}>
                                      <Dropdown className='w-100 custom-dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                          <div className='d-flex'>
                                            <Form.Control
                                              className="w-100"
                                              placeholder='ค้นหาด้วยชื่อ,รหัสสมาชิก,เบอร์โทรศัพท์'
                                              onInput={(e) => SearchfilterCustomers(e.target.value)}
                                              value={SearchCustomerName}
                                              required
                                            ></Form.Control>
                                            <Button className='arrow'></Button>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100'>
                                          <div className='limit'>
                                            {
                                              filterCustomers?.map((item, key) => {
                                                return (
                                                  <Dropdown.Item className="font-black-only" onClick={() => setCustomersList(item, key)}>{item.type === 'บุคคลธรรมดา' ? `${item.first_name} ${item.last_name}` : 
                                                  `${item.type === 'นิติบุคคล' ? item.organization : item.first_name}`
                                                  }</Dropdown.Item>
                                                )
                                              })
                                            }
                                          </div>
                                          <Dropdown.Divider />
                                          <Dropdown.Item className="font-black-only" onClick={() => { createCustomer() }}>
                                            <img src={AddLink} /> เพิ่มรายชื่อ
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </Row>
                                </Col>
                            </Row>
                            )
                          }
                          <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown mt-4'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='เลือกพนักงาน'
                                            onInput={(e) => {SearchfilterEmployees(e.target.value)}}
                                            onChange={(e) => clearSearchE()}
                                            value={SearchEmployeesName}
                                            option
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterEmployees?.map((item, key) => {
                                              return (
                                                <Dropdown.Item 
                                                  className="font-black-only"
                                                  onClick={() => {setEmployeeList(item, key)}}
                                                >
                                                {
                                                  `${item.fullname + ' - ' + item.branch.name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>

                                <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown mt-3'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='เลิอกสาขา'
                                            onInput={(e) => {SearchfilterBranch(e.target.value)}}
                                            onChange={(e) => clearSearchE()}
                                            value={SearchBranchName}
                                            option
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterBranch?.map((item, key) => {
                                              return (
                                                <Dropdown.Item 
                                                  className={item.name == 'ระบบกลาง' ? 'd-none' : 'font-black-only'}
                                                  
                                                  onClick={() => {setBranchList(item, key)}}
                                                >
                                                {
                                                  `${item.name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                        </Row>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Row className="justify-content-end">
                          <Col xs={12} lg={4}>
                          <Button type="button" className="button" onClick={() => {
                              createCustomer()
                            }}>
                              สร้างรายชื่อใหม่
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={4}>
                        <Row>
                          <Col xs={12} lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col xs={12} md={12} className="text-left">
                                <Form.Label className="font-weight-bold">
                                  ข้อมูลโชว์รูม
                                </Form.Label>
                              </Col>
                              <Col md={12} className="ml-3  mt-3 text-left">
                                <p>{Company.organization}</p>
                                <p>
                                  {
                                    `
                                    ${Company.address ? Company.address : ''} 
                                    ${Company.amphure && Company.amphure.name_th ? Company.amphure.name_th : ''}
                                    ${Company.province && Company.province.name_th ? Company.province.name_th : ''} 
                                    ${Company.postcode}
                                    `
                                  }
                                </p>

                                <p>{Company.phone ? JSON.parse(Company.phone) : ''}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col xs={12} md={12} className="text-left">
                                <Form.Label className="font-weight-bold">
                                  พนักงานขายที่ดูแล
                                </Form.Label>
                              </Col>
                              <Col md={12} className="ml-3  mt-3 text-left">
                                <p>{ShowRoomEmployee?.name}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} lg={8}>
                        <Row>
                          <Col xs={12} lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col xs={12} md={12} className="text-left">
                                <Form.Label className="font-weight-bold">
                                  ข้อมูลลูกค้า
                                </Form.Label>
                              </Col>
                              <Col md={12} className="ml-3  mt-3 text-left">
                                <Row>
                                  <Col xs={4} lg={3}>
                                    <Form.Label>รหัสสมาชิก</Form.Label>
                                  </Col>
                                  <Col>
                                    <p>{displayDetail.customer_code}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4} lg={3}>
                                    <Form.Label>ชื่อ</Form.Label>
                                  </Col>
                                  <Col>
                                    <p>
                                      {CustomerState.customer_name}
                                      {CustomerState.nick_name ? 
                                      '('+ CustomerState.nick_name + ')' : ''}
                                      { Customer.aka ? `ชื่อAKA ${CustomerState.aka}` : ''}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4} lg={3}>
                                    <Form.Label>เบอร์โทรศัพท์</Form.Label>
                                  </Col>
                                  <Col>
                                    <p>{CustomerState.phone}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4} lg={3}>
                                    <Form.Label>รถที่เข้ารับบริการ</Form.Label>
                                  </Col>
                                  <Col>
                                    <span>
                                    {
                                      `เลขทะเบียนรถ : ${displayDetail.license_plate} รายละเอียด : ยี่ห้อ : ${displayDetail.car_brand_name}
                                      รุ่น ${displayDetail.car_model_name}, สี : ${displayDetail.color}, ป้าย : ${displayDetail.plate_type}
                                      จังหวัด : ${displayDetail.province_name} เลขตัวถัง : ${displayDetail.chassis_number}
                                      `
                                    }
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4} lg={3}>
                                    <Form.Label>หมายเหตุ</Form.Label>
                                  </Col>
                                  <Col>
                                    <p>{displayDetail.note}</p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={12} className="mt-3 mb-3">
                        <Row className="justify-content-center">
                        <Col xs={4} lg={3}>
                            <Button type="button" onClick={() => {
                              LinkTo('/job/company-b2b-show-room')
                            }} 
                            className="button">
                              กลับ
                            </Button>
                          </Col>
                          <Col xs={4} lg={3}>
                            <Button type="button" onClick={() => {
                              createForm()
                            }} 
                            className="button">
                              ถัดไป
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
