import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import Select from 'react-select';

import MyPagination from 'Components/MyPagination'
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import ReportImage from 'assets/icon/report-menu.png'
import Back from 'assets/icon/back.png';
// image
import CALENDAR from 'assets/icon/Calendar.png';

import { useDispatch, useSelector } from 'react-redux';
//API
import { loadAuth } from 'actions/auth';
import { LoadBranchSalesReport } from 'actions/reports/index'

import { DatePicker } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import SearchImage from 'assets/icon/search.png';


export default function Stock() {
  const history = useHistory();
  const dispatch = useDispatch();

  const permission = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
  const { log_permissions } = useSelector( (state) => state.user.permissions)
  const report_permission = log_permissions.find(item => item.permission.name === 'รายงาน' && item.middle === permission.middle)

  const { RangePicker } = DatePicker;

  const [Search, setSearch] = useState({
    Keyword: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
    Type: '',

  })

  const getAuth = async () => {
    try {
      const params = {
        name: ['ยอดขายพนักงาน'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/report');
      }
    } catch (error) {
      history.push('/report');
      console.error(error);
    }
  };

  const [errors, setErrors] = useState([]);
  const ExportExcel = () => {
    const params = {
      Keyword: Search.Keyword,
      page: 1,
      status: Search.Type,
    }

    dispatch(LoadBranchSalesReport(params)).then(response => {
      if (response.data.data && !response.data.data.length) {
        setErrors(['ไม่พบการนัดหมายที่เลือก'])
        return false
      }
      let data = [];
      let export_name = [];
      response.data.data.map( (item, key) => {
        let set = {
          'ลำดับ': key + 1 ,
          // 'วันที่': dayjs(item.created_at).format('DD/MM/YYYY'),
          // 'เลขที่ Job': item.job_detail?.job?.job_code,
          'รหัสสมาชิก': `${item.employee_code}`,
          'ชื่อพนักงาน': `${item.fullname}`,
          'สาขา': `${item?.my_branch[0]?.name}`,
          'แผนก': `${item?.department?.name}`,
          'ระยะเวลาทำงาน': `${DayDiff(item.start_date)}`,
          'JOB EXPERIENCE': `${JobCount(item?.job)}`,
          'หมายเลข Job Exp.': `${JobText(item?.job)}`,
					'มูลค่า Job Exp.': `${JobTotal(item?.job)}`,
					'JOB ASSIST': `${JobCount(item?.job_assign)}`,
					'หมายเลข Job Ass.': `${JobText(item?.job_assign)}`,
					'มูลค่า Job Ass.': `${JobTotal(item?.job_assign)}`,

        }
        export_name = `BranchSalesReport - ${dayjs(item.created_at).format('DD-MM-YYYY')} `

        data.push(set);
      })
      const dataWS = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, export_name + '.xlsx')
    }).catch(e => {
      setErrors(['เกิดข้อผิดพลาด'])
    })
  }

  const [Loading,setLoading] = useState(false);

  const [BranchSalesReport,setBranchSalesReport] = useState([])

  const [Pages, setPage] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);


  const getBranchSalesReport = async () => {
    try {
      const params = {
				Keyword: Search.Keyword,
        limit: Search.PerPage,
        page: Search.CurrentPage,
        status: Search.Type,
      };
      const response = await dispatch(LoadBranchSalesReport(params));
      if(Search.PerPage > 0){
        setBranchSalesReport(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        setBranchSalesReport(response.data.data);
        setSearch({ ...Search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

	function JobText(item){
		let text = []
		item.map( (item) => {
			text.push(item.job_code);
		})

		return text.join()
	}

	function JobCount(item){
		return item.length;
	}

	function JobTotal(item){
		let total = 0;
		item.map( (item) => {
			total = total + Number(item.total_price)
		})	

		return numeral(total).format('0,0.00');
	}

	function DayDiff(val){
		return dayjs().diff(val, 'day') + ' วัน';
	}


  useEffect( () =>{
    getAuth()
    getBranchSalesReport();
  },[Search.Date,Search.PerPage,Search.CurrentPage,Search.Type]);

  function close() {
    history.push(`/report`);
  }
  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Bill Report bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; ยอดขายพนักงาน
                    </b>
                </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; ยอดขายพนักงาน
                    </b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
										<Form.Control
											className="input"
											type="text"
											placeholder="ค้นหาด้วยชื่อพนักงาน"
											value={Search.Keyword ? Search.Keyword : ''}
											onChange={(e) => setSearch({ ...Search, Keyword: e.target.value })}
										></Form.Control>
                  </Col>
									<Col xs={6} lg={2} className="mt-2 mb-2">
										<Button
											className="button-menu"
											style={{ padding: 0, margin: 0 }}
											onClick={() => getBranchSalesReport()}
										>
											ค้นหา &nbsp;
											<img src={SearchImage} alt="" />
										</Button>
									</Col>
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button type="button" className="button" onClick={() => ExportExcel()}>
                      Export
                    </Button>
                  </Col>
                  
                </Row>

                <Row className="g-0 justify-content-center mt-3 m-0">
                  <Col className="set-table p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>รหัสสมาชิก</th>
                          <th>ชื่อพนักงาน</th>
                          <th>สาขา</th>
                          <th>แผนก</th>
                          <th>ระยะเวลาทำงาน</th>
                          {/* <th>ยอดคงเหลือ</th> รอมาแก้ */}
                          <th>JOB EXPERIENCE</th>
                          <th>หมายเลข Job Exp.</th>
													<th>มูลค่า Job Exp.</th>
													<th>JOB ASSIST</th>
													<th>หมายเลข Job Ass.</th>
													<th>มูลค่า Job Ass.</th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {
                          BranchSalesReport?.map((item,index) => {
                            return(
                              <tr key={item.id}>
                                <td>{ item.employee_code}</td>
                                <td>{ item.fullname}</td>
                                <td>{ item?.my_branch[0]?.name}</td>
                                <td>{ item?.department?.name}</td>
                                <td>{ DayDiff(item.start_date) }</td>
                                <td>{ JobCount(item?.job) }</td>
                                <td>{ JobText(item?.job) }</td>
                                <td>{ JobTotal(item?.job) }</td>
																<td>{ JobCount(item?.job_assign) }</td>
                                <td>{ JobText(item?.job_assign) }</td>
                                <td>{ JobTotal(item?.job_assign) }</td>
                              </tr> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="g-0 mt-5 ">
            <Col lg={4} className=" set-page">
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>แสดง</p>
                </Col>
                <Col lg={7}>
                  <Select
                    className="text-left select-style"
                    aria-label="Default select example"
                    options={Pages}
                    onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                    defaultValue={{ value: 10, label: '10' }}
                  />
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>รายการ</p>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex justify-content-end set-paginate">
              <div className="box-paginate">
                {
                  !Loading ? 
                    Search.Rows > 1 && Search.PerPage > 0 ?
                      <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                  : 
                    Search.Rows > 1 && Search.PerPage > 0 ?
                      <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                }
              </div>
            </Col>
          </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
