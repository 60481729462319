import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Switch } from 'antd';
import Select, { components } from 'react-select';

// Custom Component
import TabsIconSetBank from 'Components/layout/TabsIconSetBank';
import Footer from 'Components/Footer';
import MyPagination from 'Components/MyPagination';

// Custom Icon
import SettingIcon from 'assets/icon/setting.png';
import BackIcon from 'assets/icon/back.png';
import WriteIcon from 'assets/icon/view-data.png';
import AddIcon from 'assets/icon/add_menu.png';
import SearchIcon from 'assets/icon/search.png';
import Delete from 'assets/icon/getout.png';
import Return from 'assets/icon/return-value.png'
// API
import { BankList, DestroyBank } from 'actions/settings/bank';
import { UpdateBankAccount } from 'actions/settings/bank_account';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ClearSearch, setClearSearch] = useState(false);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [Banks, setBanks] = useState([]);
  const [Selection, setSelection] = useState({
    Banks: [],
  });
  const [isLoading, setisLoading] = useState(false);
  const [Rows, setRows] = useState(0);
  const [Search, setSearch] = useState({
    Keyword: '',
    BankId: null,
    PerPage: 10,
    CurrentPage: 1,
  });

  const GetBank = async () => {
    setisLoading(true);
    const params = {
      keyword: Search.Keyword,
      bank_id: Search.BankId,
      limit: Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1,
    };
    try {
      const response = await dispatch(BankList(params));
      if (response) {
        GetSelectBank();
        if (Search.PerPage) {
          setBanks(response.data.data.data);
          setRows(response.data.data.last_page);
          setisLoading(false);
        } else {
          setBanks(response.data.data);
          setRows(0);
          setisLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const GetSelectBank = async () => {
    try {
      const response = await dispatch(BankList());
      if (response) {
        setSelection({ ...Selection, Banks: response.data.data });
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const UpdateAccountStatus = async (id, checked, account_id) => {
    try {
      const data = {
        id: id,
        data: {
          active: checked,
        },
      };
      const response = await dispatch(UpdateBankAccount(data));
      if (response) {

        let data = [...Banks]
        data.forEach(item => {
          item.branches.forEach(branch => {
            let account = branch.accounts.find(account => account.id === account_id)
            if (account) account.active = checked
          });
        });

        setBanks(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };
  const deleteBank = async (e, id) => {
    e.preventDefault();

    try {
      const response = await dispatch(DestroyBank(id));

      if (response) {
        GetBank();
      } else {
        console.log('failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const SearchBank = {
    Keyword: '',
    BankId: null,
    PerPage: 10,
    CurrentPage: 1,
  }

  async function ResetSearch() { 
    await setSearch(SearchBank)
    setClearSearch(true)
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'view'});
   if (!ClearSearch) GetBank();
    setClearSearch(false)
  }, [Search.PerPage, Search.CurrentPage,ClearSearch]);

  function close() {
    history.push(`/setting`);
  }
  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={SettingIcon} />
                  <b> <Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ธนาคาร</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={BackIcon} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={SettingIcon} />
                  <b> ตั้งค่า &gt; ธนาคาร2</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIconSetBank />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} md={4} lg={4} className="mt-3">
                        <Form.Control
                          placeholder="ค้นหาชื่อบัญชี,เลขบัญชี"
                          className="input-search"
                          onChange={(e) =>
                            setSearch({ ...Search, Keyword: e.target.value })
                          }
                          value={Search.Keyword ? Search.Keyword : ""}
                        ></Form.Control>
                      </Col>
                      <Col xs={12} md={4} lg={3} className="mt-3">
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="ธนาคาร"
                          options={Selection.Banks}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(data) =>
                            setSearch({ ...Search, BankId: data.id })
                          }
                          value={
                            Search.BankId
                              ? Selection.Banks.find((data) => {
                                  return data.id === Search.BankId;
                                })
                              : null
                          }
                        />
                      </Col>
                      <Col md={3} lg={3} xl={2} className="mt-3">
                        <Button
                          type="button"
                          className="button"
                          onClick={() => GetBank()}
                        >
                          <img src={SearchIcon} /> &nbsp; ค้นหา
                        </Button>
                      </Col>
                      <Col md={3} lg={3} xl={2} className="mt-3">
                        <Button
                          type="button"
                          className="button"
                          onClick={() => ResetSearch()}
                        >
                          <img src={Return} /> &nbsp; คืนค่า
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        md={9}
                        lg={3}
                        className="mt-3 set-hidden-add"
                      >
                        <Button type="" className="button">
                          <img src={AddIcon} />
                          &nbsp; เพิ่มรายการ
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-4'></Row>
                <div className="set-height">
                  <Row className="g-0 justify-content-center">
                    <Row className="">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">ธนาคาร</th>
                            <th className="text-left">ชื่อบัญชี</th>
                            <th className="text-left">สาขาธนาคาร</th>
                            <th>เลขบัญชี</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Banks?.map((bank, key) => {
                            return (
                              <tr className="t-tr-setting" key={`bank-${key}`}>
                                <td className="text-left">{bank.name}</td>
                                <td className="text-left">
                                  {bank.branches?.map((branch, branch_key) => {
                                    return branch.accounts?.map(
                                      (account, account_key) => {
                                        return (
                                          <p key={`account-${account_key}`} className="align-items-center d-flex my-2" style={{ height: '25px' }}>
                                            {account.name}
                                          </p>
                                        );
                                      }
                                    );
                                  })}
                                </td>
                                <td className="text-left">
                                  {bank.branches?.map((branch, branch_key) => {
                                    return branch.accounts?.map(
                                      (account, account_key) => {
                                        return (
                                          <p key={`branch-${account_key}`} className="align-items-center d-flex my-2" style={{ height: '25px' }}>
                                            {branch.name}
                                          </p>
                                        );
                                      }
                                    );
                                  })}
                                </td>
                                <td>
                                  {bank.branches?.map((branch, branch_key) => {
                                    return branch.accounts?.map(
                                      (account, account_key) => {
                                        return (
                                          <>
                                            <p key={`number-${account_key}`} className="align-items-center d-flex my-2" style={{ height: '25px' }}>
                                              {account.account_number}
                                            </p>
                                          </>
                                        );
                                      }
                                    );
                                  })}
                                </td>
                                <td>
                                  {bank.branches?.map((branch, branch_key) => {
                                    return branch.accounts?.map(
                                      (account, account_key) => {
                                        return (
                                          <div key={`switch-${account_key}`} className="my-2">
                                            <Switch
                                              checked={account.active}
                                              onChange={(checked) =>
                                                UpdateAccountStatus(
                                                  account.id,
                                                  checked,
                                                  account.id
                                                )
                                              }
                                            />
                                          </div>
                                        );
                                      }
                                    );
                                  })}
                                </td>
                                <td className="text-left">
                                  {bank.branches?.map((branch, branch_key) => {
                                    return branch.accounts?.map(
                                      (account, account_key) => {
                                        return (
                                          <div key={`edit-${account_key}`} className="align-items-center d-flex my-2" style={{ height: '25px' }}>
                                            <Link
                                              to={`/setting/bank/${account.id}/edit/account`}
                                            >
                                             <img src={WriteIcon} />
                                            </Link>
                                          </div>
                                        );
                                      }
                                    );
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          isSearchable={false}
                          value={Pages.find((data) => {
                            return data.value === Search.PerPage;
                          })}
                          onChange={(data) =>
                            setSearch({ ...Search, PerPage: data.value })
                          }
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {!isLoading ? (
                        <MyPagination
                          totPages={Rows}
                          currentPage={Search.CurrentPage}
                          pageClicked={(e) => {
                            setSearch({ ...Search, CurrentPage: e });
                          }}
                        ></MyPagination>
                      ) : (
                        ''
                      )} */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
