import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Modal,
} from 'react-bootstrap';
import Service from 'assets/icon/appwizard_list.png';
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';

import Save from 'assets/icon/new-save.png';
import Getout from 'assets/icon/new-re-turn.png';
import Print from 'assets/icon/print.png';
import CanCel from 'assets/icon/new-cancel.png';
import Delete from 'assets/icon/delete.png';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import { useDispatch } from 'react-redux';

//API
import {PartnerList} from 'actions/partner/index';
import { List } from 'actions/employee/index';
import { ProductList } from 'actions/product/index'
import {CreateOrder} from 'actions/orders/index'
import { loadAuth } from 'actions/auth';
import { CompaniesList } from 'actions/company';

import moment from 'moment';
import dayjs from 'dayjs';
import { data } from 'jquery';

export default function PurchaseOrder() {
  const history = useHistory();
  const dispatch = useDispatch();

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [discountType, setDiscount] = useState({value: 'percent', label: 'ส่วนลด(เปอร์เซ็น)' });
  const [searchProduct, setSearchProduct] = useState([
    { value: '', label: '' },
  ]);
  const [tax, setTax] = useState([{ value: 'non', label: 'Non Vat' }]);
  const [use, setUse] = useState([
    { value: 'เข้าสต็อก', label: 'เข้าสต็อก' },
    { value: 'ใช้ทันที', label: 'ใช้ทันที' },
  ]);

  const [validated, setValidated] = useState(false);
  const [view_placeholder, Setview_placeholder] = useState('%');

  function closePackage() {
    let path = `/stock`;
    history.push(path);
  }

  function SetTax(data) {
    setTax({ value: data.value, label: data.value });
  }

  function SetDiscount(data) {
    setDiscount({ value: data.value, label: data.label });
  }
  const optionTax = [
    { value: 'exclude', label: 'Exclude Vat' },
    { value: 'include', label: 'Include Vat' },
    { value: 'non', label: 'Non Vat' },
  ];

  const optionDiscount = [
    { value: 'percent', label: 'ส่วนลด(เปอร์เซ็น)' },
    { value: 'baht', label: 'ส่วนลด(บาท)' },
  ];

  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });

  const [companies,setCompanies] = useState([]);

  const CurrentDate = dayjs().format('DD-MM-YYYY')

  const [isLoading,setIsLoading] = useState(false);
  const [partners,setPartners] = useState([]);
  const [employees,setEmployees] = useState([]);
  const [products, setProducts] = useState([]);

  const StorageModel = {
    key: 0,
    ProductId: null,
    ProductCode: null,
    ProductName: null,
    Quantity: 1,
    Price:0,
    Discount: 0,
    DiscountType: '',
    DiscountCal: 0,
    Total: 0,
    ProductTotal: 0,
    UnitId: null, 
  }

  const [Data,SetData] = useState({
    PartnerId: null,
    CompanyId: null,
    CompanyCode: null,
    EmployeeId: null,
    OrderDate: moment(),
    UserId: null,
    SumTotal: 0,
    VatType: 'non',
    SumDiscount: 0,
    SumTotalAndDiscount: 0,
    VatCalculate:0,
    SumTotalAfterVat:0,
    StorageList:[],
    Note: '',
  });
 
  const GetPartner = async () => {
    setIsLoading(true)
    try {
      const params = {
        keyword: '',
      }
      const response = await dispatch(PartnerList(params));
      setPartners(response.data.data);
      setIsLoading(false)
    } catch (error) {
      console.error(error);
      
    } finally {
      console.log('finally')
      setIsLoading(false)
    }
  }

  const GetCompanies = async () => {
    setIsLoading(true)
    try {
      const params = {
        keyword: '',
      }
      const response = await dispatch(CompaniesList(params));
      setCompanies(response.data.data);
      setIsLoading(false)
    } catch (error) {
      console.error(error);
      
    } finally {
      console.log('finally')
      setIsLoading(false)
    }
  }

  const GetEmployee = async () => {
    setIsLoading(true)
    try {
      const params = {
        keyword: '',
        branch_id: profile.branch_id
      }
      const response = await dispatch(List(params));
      setEmployees(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const GetProducts = async () => {
    setIsLoading(true)
    try {
      const params = {
        keyword: '',
      }
      const response = await dispatch(ProductList(params));
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const SetProductList = async (item, key) => {
        let insert = StorageModel
        insert.key = Data.StorageList.length
        insert.ProductId = item.id
        insert.ProductCode = item.code
        insert.ProductName= item.name
        insert.UnitName = item.unit && item.unit.name_buy
        insert.UnitId = item.unit && item.unit.id
        insert.Price = Number(0)
        insert.Total = Number(0)
        insert.Discount = 0
        insert.TotalDiscount = 0
        insert.ProductTotal = Number(0)
        insert.DiscountType = discountType.value
        // insert.SpecialType = 
        SetData({ ...Data, StorageList:[...Data.StorageList, insert]
        })
  }

  const CalculateGrandTotal = async (item, value, type) => {
    if (value === null) value = 0
    let newQuantity = type === 'quantity' ? Number(value) : Number(item.Quantity)
    let newPrice = type === 'price' ? Number(value) : Number(item.Price)
    let total = newQuantity * newPrice
    let grandTotal = 0
    let totalDiscount = 0
    let newDiscount = type === 'discount' ? Number(value) : Number(item.Discount)
    if(discountType.value){
      switch (discountType.value) {
        case 'percent':
          totalDiscount = Number((total / 100) * newDiscount)
          grandTotal = total - totalDiscount
          break;
        case 'baht':
          totalDiscount = Number(newDiscount)
          grandTotal = total - newDiscount
          break;
      }
    }
    SetData(prevState => ({...Data,
      StorageList: prevState.StorageList.map(
        el => el.key === item.key ? { ...el, ProductTotal:Number(grandTotal),TotalDiscount: Number(totalDiscount),Total: Number(total) }: el
      ),
    }))

  }

  const funcSumTotal = () =>{
    const Total = Number(Data.StorageList.map(i => i.Total).reduce((prev, curr) => prev + curr, 0))
    return Total
  }
  const funcSumDsicount = () => {
    const Discount = Number(Data.StorageList.map(i => i.TotalDiscount).reduce((prev, curr) => prev + curr, 0))
    return Discount
  }
  const funcSumTotalAndDiscount = () => {
    const SumTotalAndDiscount = Number(Data.StorageList.map(i => i.ProductTotal).reduce((prev, curr) => prev + curr, 0))
    return SumTotalAndDiscount
  }
  const funcSumVatCalculate = () => {
    let exclude = 0
    let include = 0
    const Total = (Data.StorageList.map(i => i.ProductTotal).reduce((prev, curr) => prev + curr, 0))
    // console.log('Total',Total);
    if(Data.VatType === 'exclude'){
      exclude = (Total * 0.07).toFixed(2)
    }else if(Data.VatType === 'include'){
      include = (Total - (Total * 100 / 107)).toFixed(2)
    }
    return Data.VatType === 'exclude' ? exclude : Data.VatType === 'include' ? include : 0
  }

  const funcSumTotalAfterVat = () => {
    let exclude = 0
    let include = 0
    const Total = (Data.StorageList.map(i => i.ProductTotal).reduce((prev, curr) => prev + curr, 0))
    
    if(Data.VatType === 'exclude'){
      exclude = (Total * 0.07).toFixed(2)
    }else if(Data.VatType === 'include'){
      include = (Total - (Total * 100 / 107)).toFixed(2)
    }
    // console.log('Number(Total)',Number(Total),exclude);
    return Data.VatType === 'exclude' ? (Number(Total) + Number(exclude)).toFixed(2) : Data.VatType === 'include' ? (Number(Total) - include).toFixed(2) : Number(Total).toFixed(2)
  }
  
  const funcSumResult = () => {
    let exclude = 0
    const Total = (Data.StorageList.map(i => i.ProductTotal).reduce((prev, curr) => prev + curr, 0))
    
    if(Data.VatType === 'exclude'){
      exclude = (Total * 0.07).toFixed(2)
    }
    return Data.VatType === 'exclude' ? (Number(Total) + Number(exclude)).toFixed(2) : Data.VatType === 'include' ? Number(Total).toFixed(2) : Number(Total).toFixed(2)
  }
  const removeProduct = (ProductId) => {
    var find = Data.StorageList.filter(i => i.ProductId !== ProductId);
    // console.log('find',find);
    SetData({ ...Data,
      SumTotal: Data.StorageList.map(i => i.Total).reduce((prev, curr) => prev + curr, 0) - Number(find.Total),
      SumDiscount: Data.StorageList.map(i => i.TotalDiscount).reduce((prev, curr) => prev + curr, 0) > 0 ? Data.StorageList.map(i => i.TotalDiscount).reduce((prev, curr) => prev + curr, 0) - find.TotalDiscount: 0,
      SumTotalAndDiscount : Data.StorageList.map(i => i.ProductTotal).reduce((prev, curr) => prev + curr, 0) - Number(find.ProductTotal),
      StorageList : find,
    })
    // Data.StorageList.splice(find.key,1);
  } 

  const bankToStock = () => {
    history.push(`/stock`);
  }

  const handleSubmit = async (e) => {
   
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);
    setIsLoading(true);
    setTimeout(async () => {
      const validator = document.querySelectorAll('.validate-fail');
      if (form.checkValidity() && validator.length === 0) {
        // console.log('Data',Data);
        let formData = new FormData();
        if (Data.PartnerId) formData.append('partner_name', Data.PartnerId);
        // if (Data.PartnerId) formData.append('partner_id', Data.PartnerId);
        if (Data.CompanyId) formData.append('company_id', Data.CompanyId);
        if (Data.EmployeeId) formData.append('ordered_id', Data.EmployeeId);
        if (Data.OrderDate) formData.append('order_date', Data.OrderDate);
        formData.append('vat_type', Data.VatType);
        if(Data.StorageList.length > 0){
          for (let i = 0; i < Data.StorageList.length; i++) {
            formData.append(`storage_list[${i}]`, JSON.stringify(Data.StorageList[i]));
          }
        }
        formData.append('sumTotal', funcSumTotal());
        formData.append('sumDiscount', funcSumDsicount());
        formData.append('sumTotalAndDiscount', funcSumTotalAndDiscount());
        formData.append('vat', funcSumVatCalculate());
        formData.append('sum_total_after_vat', funcSumTotalAfterVat());
        formData.append('sumResult',funcSumResult());
        formData.append('note',Data.Note);
        formData.append('type_use', 'stock_order');
        
        try {
          const response = await dispatch(CreateOrder(formData));
         
          if(response){
            history.push('/stock/receipt-order');
            setIsLoading(false)
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false)
        } finally {
          console.log('finally')
        }
      }
    })
    
    
  };

  const getAuth = async () => {
    try {
      const params = {
        name: ['ลงชื่อ/รับเข้า(stock)'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/stock');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log('status-discount', discount);
    if (discountType.value === 'ส่วนลด(เปอร์เซ็น)') {
      Setview_placeholder('%');
    } else if (discountType.value === 'ส่วนลด(บาท)') {
      Setview_placeholder('บาท');
    }
  });

  useEffect(() => {
    GetPartner();
    GetEmployee();
    GetProducts();
    GetCompanies();
    getAuth()
  }, [Search.PerPage, Search.CurrentPage]);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  return (
    <>
      <Container fluid className="package-container Staff Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ใบสั่งซื้อ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ใบสั่งซื้อ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}  className="Stock-Request-Order-Branch mt-2 mb-2">
                  <Row className="g-0 justify-content-center">
                    <Col md={12} lg={6} className="">
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center  set-label text-left"
                        >
                          <Form.Label>ผู้จัดจำหน่าย</Form.Label>
                        </Col>
                        <Col
                          xs={8}
                          sm={6}
                          md={6}
                          lg={5}
                          className="text-left mt-2 mb-2"
                        >
                          {/* <Select
                            className="text-left select-style"
                            aria-label="Default select example"
                            placeholder=""
                            options={partners}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={
                              (e) => SetData({...Data, PartnerId: e.id })
                            }
                          /> */}

                          <Form.Control
                            value={Data.PartnerId}
                            placeholder="ผู้จัดจำหน่าย"
                            onChange={(data) => SetData({...Data, PartnerId: data.target.value })}
                            required
                            className="input-form"
                          ></Form.Control>

                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center  set-label"
                        >
                          <Form.Label>ผู้สั่งซื้อ</Form.Label>
                        </Col>
                        <Col
                          xs={8}
                          sm={6}
                          md={6}
                          lg={5}
                          className="text-left mt-2 mb-2"
                        >
                          <Select
                            className={`text-left select-style select-list ` + (!Data.EmployeeId && validated ? 'validate-fail' : '') }
                            required
                            aria-label="Default select example"
                            placeholder="ผู้สั่งซื้อ"
                            options={employees}
                            getOptionLabel={(option) => option.fullname}
                            getOptionValue={(option) => option.id}
                            onChange={
                              (e) => SetData({...Data, EmployeeId: e.id })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center  set-label"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col
                          xs={8}
                          sm={6}
                          md={6}
                          lg={5}
                          className="text-left mt-2 mb-2"
                        >
                          <Select
                            className={`text-left select-style select-list ` + (!Data.CompanyId && validated ? 'validate-fail' : '') }
                            required
                            aria-label="Default select example"
                            placeholder="สั่งโดย"
                            options={companies}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            request
                            onChange={
                              (e) => SetData({...Data, CompanyId: e.id, CompanyCode: e.company_code })
                            }
                          />
                        </Col>
                        {/* <Col>
                            { Data.CompanyCode ? Data.CompanyCode : '' }
                        </Col> */}
                      </Row>
                    </Col>
                    <Col
                      md={12}
                      lg={6}
                      className="set-div-position "
                      style={{ position: 'relative', zIndex: '200' }}
                    >
                      <Row className="justify-content-end set-row-status">
                        <Col xs={12} md={12} lg={8} xl={8} className="bg-admit">
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center text-left"
                            >
                              <Form.Label>วันที่สั่ง</Form.Label>
                            </Col>
                            <Col>
                              <DatePicker
                                placeholder="วันที่สั่ง"
                                suffixIcon={<img src={Calendar} />}
                                format={"YYYY-MM-DD"}
                                defaultValue={moment()}
                                onChange={
                                  (e) => SetData({...Data, OrderDate: e })
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center text-left"
                            >
                              <Form.Label>วันที่บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p>{CurrentDate}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center text-left"
                            >
                              <Form.Label>ผู้บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p className="text-p">: {profile.fullname ? profile.fullname : ''}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center text-left"
                            >
                              <Form.Label>ชนิดภาษี</Form.Label>
                            </Col>
                            <Col
                              className=""
                              style={{
                                position: 'relative',
                                zIndex: '500 !important',
                              }}
                            >
                              <Select
                                options={optionTax}
                                placeholder="ชนิดภาษี"
                                isClearable={false}
                                isSearchable={false}
                                value={Data.VatType ? optionTax.find((data) => {return data.value === Data.VatType}) : null}
                                onChange={(data) => {SetData({...Data, VatType: data.value})}}
                                className="select-list"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 pt-3">
                      <Select
                        options={products}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable={false}
                        isSearchable={true}
                        placeholder="ค้นหาและเลือกสินค้า"
                        onChange={(data,index) => SetProductList(data,index)}
                        className={`text-left select-style select-list ` + (Data.StorageList.length < 1 && validated ? 'validate-fail' : '') }
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center m-0">
                    <Col
                      className=" mt-3 p-0"
                      style={{ overflow: 'scroll', height: 'auto' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th className="text-left">รหัสสินค้า</th>
                            <th className="text-left">ชื่อสินค้า</th>
                            <th>หน่วย</th>
                            <th>จำนวน</th>
                            <th>ราคาต่อหน่วย</th>
                            <th>
                              <div className="div-select-table">
                                <Select
                                  options={optionDiscount}
                                  placeholder="ส่วนลด(เปอร์เซ็น)"
                                  isClearable={false}
                                  isSearchable={false}
                                  onChange={(data) => SetDiscount(data)}
                                  className="select-in-table"
                                />
                              </div>
                            </th>
                            <th>ราคารวม</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                          {Data.StorageList?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td className="text-left">{item.ProductCode}</td>
                                <td className="text-left">{item.ProductName}</td>
                                <td>{item.UnitName}</td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder=""
                                    className="input-in-table"
                                    value={commaCase(item.Quantity)}
                                    onFocus={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'quantity');
                                      }
                                    }
                                    onBlur={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'quantity');
                                      }
                                    }
                                    onChange={
                                      (e) => {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Quantity: e.target.value.replaceAll(',', '') }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'quantity');
                                      }
                                    }
                                    onKeyPress={(event) => {
                                      if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  ></Form.Control>
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder=""
                                    className="input-in-table"
                                    value={commaCase(item.Price)}
                                    onFocus={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Price: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'price');
                                      }
                                    }
                                    onBlur={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Price: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'price');
                                      }
                                    }
                                    onChange={
                                      (e) => {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Price: e.target.value.replaceAll(',', '') }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'price');
                                      }
                                    }
                                    onKeyPress={(event) => {
                                      if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  ></Form.Control>
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    required
                                    // placeholder={view_placeholder}
                                    placeholder=''
                                    className="input-in-table"
                                    value={commaCase(item.Discount)}
                                    onFocus={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Discount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'discount');
                                      }
                                    }
                                    onBlur={
                                      (e) =>
                                      {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Discount: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'discount');
                                      }
                                    }
                                    onChange={
                                      (e) => {
                                        SetData(prevState => ({...Data,
                                          StorageList: prevState.StorageList.map(
                                            el => el.key === item.key ? { ...el, Discount: e.target.value.replaceAll(',', '') }: el
                                          )
                                        }))
                                        CalculateGrandTotal(item, e.target.value === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')), 'discount');
                                      }
                                    }
                                    onKeyPress={(event) => {
                                      if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  ></Form.Control>
                                </td>
                                <td>{addCommas(item.ProductTotal)}</td>
                                <th>
                                  <Button type="button" className="button-package" onClick={() => {
                                    removeProduct(item.ProductId)
                                  }}>
                                    <img src={Delete} />
                                  </Button>
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row className="g-0 mt-2 mb-2 justify-content-between align-items-center set-position">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            as="textarea"
                            placeholder="หมายเหตุ"
                            rows={3}
                            className=""
                            onChange={
                              (e) => SetData({...Data, Note: e.target.value })
                            }
                            style={{ minHeight: '100px' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={10} lg={5} className="set-data-purchase">
                      <Row className="justify-content-between ">
                        <Col className="d-flex justify-content-start text-left">
                          <p>ราคาสินค้า</p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {/* <p>{Data.SumTotal} บาท</p>
                           */}
                           <p>{ addCommas(funcSumTotal()) } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="d-flex justify-content-start text-left">
                          <p>ส่วนลดรวม</p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {/* <p>{Data.SumDiscount} บาท</p> */}
                          <p>{ addCommas(funcSumDsicount()) } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="d-flex justify-content-start text-left">
                          <p>ราคาหลังหักส่วนลด</p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <p>
                            {/* {Data.SumTotalAndDiscount} */}
                            { addCommas(funcSumTotalAndDiscount()) } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="d-flex justify-content-start text-left">
                          <p>จำนวนภาษีมูลค่าเพิ่ม</p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {/* <p>{Data.VatCalculate} บาท</p> */}
                          <p>{ addCommas(funcSumVatCalculate()) } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="d-flex justify-content-start text-left">
                          <p>รวมเป็นเงิน</p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {/* <p>{Data.SumTotalAfterVat} บาท</p> */}
                          <p>{ addCommas(funcSumTotalAfterVat()) } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between border-top mt-2">
                        <Col className="d-flex justify-content-start text-left">
                          <p>จำนวนรวมทั้งสิ้น </p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {/* <p>{Data.SumTotalAfterVat} บาท</p> */}
                          <p>{ addCommas(funcSumResult()) } บาท</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className=" justify-content-center set-row-botton-request">
                    <Col lg={9}>
                      <Row className="d-flex justify-content-start">
                        <Col xs={12} lg={4} xl={3} className="mt-2">
                            <Button className="button" type='submit' disabled={isLoading}>
                              <img src={Save} /> &nbsp; สั่งซื้อ
                            </Button>
                        </Col>
                        {/* <Col xs={12} lg={4} xl={3} className="mt-2">
                          <Button className="button">
                            <img src={Print} /> &nbsp; พิมพ์เอกสาร
                          </Button>
                        </Col> */}
                        <Col xs={6} lg={4} xl={3} className="mt-2">
                          {/* <Button className="button">
                            <img src={CanCel} /> &nbsp; ยกเลิก
                          </Button> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <Row className="d-flex justify-content-end">
                        <Col className=" mt-2">
                          <Button className="button" onClick={bankToStock}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
