import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authentication: localStorage.getItem('authentication') ? JSON.parse(localStorage.getItem('authentication')) : null,
  employee: localStorage.getItem('employee') ? JSON.parse(localStorage.getItem('employee')) : null,
  permissions: localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null,
  notification: localStorage.getItem('notification') ? JSON.parse(localStorage.getItem('notification')) : null,
}

export const userSlice = createSlice({
  name: 'app',
  initialState,
  reducers:{
    updateUser: (state, action) => {
      if (!action.payload) {
        state.authentication = {
          tokenType: null,
          accessToken: null,
          expiresAt: null,
          isLogin: false
        }
        state.employee = null
        state.permissions = null
        localStorage.removeItem('authentication');
        localStorage.removeItem('employee');
        localStorage.removeItem('permissions');
        // clear old
        localStorage.removeItem('profile');
        localStorage.removeItem('access_token');
        localStorage.removeItem('level');
        localStorage.removeItem('userInfo');
      }else{
        state.authentication = {
          tokenType: action.payload.token_type,
          accessToken: action.payload.access_token,
          expiresAt: action.payload.expires_at,
          isLogin: !!action.payload.token_type
        }
        state.employee = action.payload.profile
        state.permissions = action.payload.level
        localStorage.setItem('authentication', JSON.stringify(state.authentication));
        localStorage.setItem('employee', JSON.stringify(state.employee));
        localStorage.setItem('permissions', JSON.stringify(state.permissions));
      }
    },
    setNotification: (state, action) => {
      if (!action.payload) {
        state.notification = null
        localStorage.removeItem('notification');
      }else{
        state.notification = action.payload
        localStorage.setItem('notification', JSON.stringify(state.notification));
      }
    }
  }
})

export const { updateUser, setNotification } = userSlice.actions
export default userSlice.reducer