import React, { useState, useEffect } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import MrkImage from 'assets/icon/dashboard.png'
import MemberImage from 'assets/icon/member.png'
import UserImage from 'assets/icon/user.png'
import JobImage from 'assets/icon/job.png'
import NotificationImage from 'assets/icon/notification.png'
import B2BImage from 'assets/icon/address_book_home.png'
import StockImage from 'assets/icon/appwizard_list.png'
import BillImage from 'assets/icon/job.png'
import AppointmentImage from 'assets/icon/purchase.png'
import GlassImage from 'assets/icon/glass-staff.png'
import ReportImage from 'assets/icon/report-menu.png'
import Text from 'text-setting';

import { useDispatch } from 'react-redux';

import { loadAuth } from 'actions/auth';


export default function TabsIconJob() {
  const [show, setShow] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const close = () => {
    setShow(false);
  };


  const [canShowroomCreate, setCanShowroomCreate] = useState(false);
  const getAuthShowRoomCanCreate = async () => {
    try {
      const params = {
        name: ['showroom'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      console.log('s1',canShowroomCreate);
      if(response.data.data !== 'Unauthorized'){
        // alert(response.data.message)
        // history.push('/report');
        setCanShowroomCreate(true);
        console.log('s2',canShowroomCreate);
      }
    } catch (error) {
      // history.push('/report');
      console.error(error);
    }
  };

  function setUpStorage(){
    let url = '/job/inside-data';

    const data = {
      customer_id : '',
      car_id: '',
      status_job: 'new',
      type: '' ,
      employee_id: null,
      branch_id: null,
      PackageList:[],
      PackageListAppointment:[],
      show_room_employee_id: '',
      company_id: '',
    }
    localStorage.setItem('job-inside-data',JSON.stringify(data));
    history.push(url);

  }

  useEffect(() => {
    getAuthShowRoomCanCreate();
  }, []);
  return (
    <Row className="g-0">
      <Col lg={12} className="d-flex justify-content-start">
        <div className="tabs-icon mb-4">
          {/* <Link className='link-button' to="">
          <Button className='button-menu-page p-1'>
          <img src={MrkImage} width="100%"  alt="" title={Text.menuName.mrk} />
          </Button>
          </Link> */}
          <Link className='link-button' to="/member">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={MemberImage} width="100%"  alt="" title={Text.menuName.member} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/user">
          <Button className='button-menu-page p-1'>
            <img src={UserImage} width="100%"  alt="" title={Text.menuName.user} />
          </Button>
          </Link> */}
          <Link className='link-button' to="/job">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={JobImage} width="100%"  alt="" title={Text.menuName.job} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/b2b">
          <Button className='button-menu-page p-1'>
            <img src={B2BImage} width="100%"  alt="" title={Text.menuName.b2b} />
          </Button>
          </Link> */}
          {/* <Link className='link-button' to="/stock">
          <Button className='button-menu-page p-1'>
            <img src={StockImage} width="100%"  alt="" title={Text.menuName.stock} />
          </Button>
        </Link> */}
          <Link className='link-button' to="/bill">
          <Button className='button-menu-page p-1 mr-2'>
            <img src={BillImage} width="100%"  alt="" title={Text.menuName.bill} />
          </Button>
          </Link>
          {/* <Link className='link-button' to="/notification">
          <Button className='button-menu-page p-1'>
            <img src={NotificationImage} width="100%"  alt="" title={Text.menuName.notification} />
          </Button>
          </Link> */}
          {/* <Link className='link-button' to="/appointment">
          <Button className='button-menu-page p-1'>
            <img src={AppointmentImage} width="100%"  alt="" title={Text.menuName.appointment} />
          </Button>
          </Link>
          <Link className='link-button' to="/branch/">
          <Button className='button-menu-page p-1'>
            <img src={GlassImage} width="100%"  alt="" title={Text.menuName.glass} />
          </Button>
          </Link>
          <Link className='link-button' to="/report">
          <Button className='button-menu-page p-1'>
            <img src={ReportImage} width="100%"  alt="" title={Text.menuName.report} />
          </Button>
          </Link> */}
          <Button type="button" className="increase" onClick={handleShow}>
            <p>สร้าง JOB</p>
          </Button>
        </div>
      </Col>
      <Modal className="modal-style" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-style">
          <p>
            <b>JOB</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center set-modal-body-job">
            <Col lg={3}>
              {/* <Link to="/job/inside-data"> */}
                <Button type="button" onClick={() => {
                  setUpStorage()
                }} className="button">
                  หน้าร้าน
                </Button>
              {/* </Link> */}
            </Col>

            {
              canShowroomCreate ? (
                <Col lg={3}>
                  <Link to="/job/company-b2b-show-room">
                    <Button type="button" onClick={() => {
                      setUpStorage()
                    }} className="button">
                      Showroom (B2B)
                    </Button>
                  </Link>
                </Col>
              ):(
                ''
                )
            }

            <Col lg={3}>
              <Link to="/job/inside-data-old">
                <Button type="button" onClick={() => {
                  setUpStorage()
                }} className="button">
                  ลูกค้าย้อนหลัง
                </Button>
              </Link>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Row>
  );
}
