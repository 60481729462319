import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Button
} from 'react-bootstrap';
import Footer from 'Components/Footer';

import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import TabIconAssets from 'MiddleSystems/Components/layout/TabIconProperty';
import MenuProperty from 'MiddleSystems/Components/layout/MenuProperty';
import Back from 'assets/icon/back.png';
import Return from 'assets/icon/return-value.png';
import Increase from 'assets/icon/add-staff.png';
import SearchImage from 'assets/icon/search.png';
import PropertyImage from 'assets/icon/asset.png'
import MyPagination from 'Components/MyPagination'
// import Service from 'assets/icon/management.png';

import Property from './Components/Property';
import ViewAll from 'MiddleSystems/Components/Property/ViewAll';
import { useDispatch } from 'react-redux';

//api
import { LoadProperties } from 'actions/property' 
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [Rows, setRows] = useState(0);
  const [PropertyState, setProperties] = useState([]);
  const [statepage, setStatepage] = useState('');
  const handleSelectPage = (e) => setStatepage(e);
  const [key, setKey] = useState('Property');
  const [Reload,setReload] = useState(false);

  const [isLoading, setLoading] = useState(true);

    const [search, setSearch] = useState({
      Keyword: null,
      PerPage: 10,
      CurrentPage: 1
    });
    const [Keyword, setKeyword] = useState(null);
    const [Pages, setPages] = useState([
      { value: null, label: 'ทั้งหมด' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
    ]);

  async function GetProperties(){
    setLoading(true)
    const params = {
      keyword: search.Keyword,
      limit: search.PerPage,
      page: search.CurrentPage
    }
    try {
     
      const response = await dispatch(LoadProperties(params));
      if(search.PerPage > 0){
        const properties = response.data.data.data
        setProperties(properties);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        const properties = response.data.data
        setProperties(properties)
        setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
      } ;
      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  const [ClearSearch, setClearSearch] = useState(false);
  const ResetProperties = () => {
      // setKeyword(null)
      setSearch({...search, 
        Keyword: null,
        PerPage: 10,
        CurrentPage:1,
        Rows:0
      })
    setClearSearch(true)
  }

  const [property ,setProperty] = useState([]);

  async function getProperty(){
    try {
      setLoading(true);
      const response = await dispatch(LoadProperties());
      setProperty(response.data.data);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (!ClearSearch) GetProperties();
    setClearSearch(false)
  }, [search.PerPage, search.CurrentPage, ClearSearch]);

  const getAuth = async () => {
    try {
      const params = {
        name: ['ทรัพย์สิน'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function closePackage() {
    let path = `/`;
    history.push(path);
  }

  useEffect(() => {
    if(Reload === true){
      if(key === 'Property'){
        GetProperties(); 
      }else{
        getProperty();
      }
    }
    
    getAuth();
    setReload(false)
  }, [Reload]);

 
  useEffect(() => {
    GetProperties();
    getProperty();
    getAuth();
  }, [search.PerPage, search.CurrentPage]);
  
  return (
    <>
      
      <Container fluid className="package-container Staff   Property bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={PropertyImage} />
                  <b>รายการทรัพย์สิน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={PropertyImage} />
                  <b>รายการทรัพย์สิน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabIconAssets 
                  path="/property/data-property" 
                  />
                </Row>
                <Row className="g-0 justify-content-center">
                  {/* <MenuProperty search={search} GetProperties={GetProperties} ResetProperties={ResetProperties}/> */}

                  {
                    key === 'Property' ? (
                      <Row className="g-0">
                    <Col lg={5} className="d-flex justify-content-center mt-2 mb-2">
                      <Form.Control
                        type="text"
                        placeholder="ค้นหาด้วยชื่อ"
                        // defaultValue={statepage[0]}
                        value={search.Keyword}
                        onChange={(e) => setSearch({...search, Keyword: e.target.value})}
                        className="input"
                      ></Form.Control>
                    </Col>

                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <Button className="button-menu" onClick={GetProperties}>
                        <img src={SearchImage} /> &nbsp; ค้นหา
                      </Button>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <Button className="button-menu" onClick={ResetProperties}>
                        <img src={Return} /> &nbsp; คืนค่า
                      </Button>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2 hide-increase"
                    >
                      <Button className=" button-menu ">
                        <Row className="justify-content-center align-items-center">
                          <Col xs={5} className="p-0 d-flex justify-content-end">
                            <img src={Increase} />
                          </Col>
                          <Col className="p-0 d-flex">
                            <p>เพิ่มสาขา</p>
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                    ) : null
                  }
                  
                </Row>
                <Row className="g-0 justify-content-center Staff-tabs">
                  <Col className="justify-content-start  ">
                    <div className="box-typecar ">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => {setKey(k);setReload(true);} } 
                        className="mb-4"
                      >
                        <Tab eventKey="Property" title="รายการทรัพย์สิน" >
                          <Row className="justify-content-center">
                            <Property PropertyState={PropertyState} />
                          </Row>
                          <Row className="g-0 mt-3 mb-3">
                            <Col lg={4} className=" set-page">
                              <Row>
                                <Col className="d-flex justify-content-center align-items-center">
                                  <p style={{ margin: 0 }}>แสดง</p>
                                </Col>
                                <Col lg={7}>
                                  <Select
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={Pages}
                                    onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                                    defaultValue={{ value: 10, label: '10' }}
                                  />
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                  <p style={{ margin: 0 }}>รายการ</p>
                                </Col>
                              </Row>
                            </Col>

                            <Col className="d-flex justify-content-end set-paginate">
                              <div className="box-paginate">
                                {
                                  !isLoading ? 
                                    search.Rows > 1 && search.PerPage > 0 ?
                                      <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                                    : ''
                                  : 
                                    search.Rows > 1 && search.PerPage > 0 ?
                                      <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                                    : ''
                                }
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="Dashboard" title="ภาพรวม" >
                          <Row className="justify-content-center">
                            <ViewAll property={property}/>
                          </Row>
                        </Tab>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
