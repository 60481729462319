import axios from 'axios';

export const Province = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/provinces`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const Amphure = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/amphures`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const District = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/districts`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};
