import axios, { post } from 'axios';

export const DamageList = (params) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/damages`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindDamage = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/damages/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateDamage = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/damages`,
    data,
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateDamage = ({id, data}) => async () => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/damages/${id}`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyDamage = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/damages/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};
