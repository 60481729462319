import axios, { post, put } from 'axios';

export const BankAccountList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/bank-accounts`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindBankAccount = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/bank-accounts/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateBankAccount = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/bank-accounts`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateBankAccount = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/bank-accounts/${id}`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyBankAccount = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/bank-accounts/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};