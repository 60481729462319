import axios, { post, put } from 'axios';

export const CarColorList = (params) => async (dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/car-color`, { params })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};

export const CreateCarColor = (data) => async (dispatch) => {
  return await post(`${process.env.REACT_APP_BASE_URL}/api/car-color`, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdateCarColor = ({ id, data }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      return await put(
        `${process.env.REACT_APP_BASE_URL}/api/car-color/${id}`,
        data,
        config
      )
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    } catch (error) {}
  };

export const DestroyCarColor = (id) => async () => {
  return await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/api/car-color/${id}`)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};