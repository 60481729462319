import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Select, { components } from 'react-select';
import { Switch, DatePicker, Space } from 'antd';
import { Container, Row, Col, Form, Tab, Tabs, Button, Table, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';

// Icon
import Back from 'assets/icon/back.png';
import Package_image from 'assets/icon/package.png';
import Save from 'assets/icon/new-save.png';
import Turnoff from 'assets/icon/new-cancel.png';
import getout from 'assets/icon/getout.png';
import Delete from 'assets/icon/delete.png';
import Increase from 'assets/icon/add-staff.png';
import uploadImage from 'assets/icon/upload.png';
// CSS
import 'assets/scss/style.scss';

import dayjs from 'dayjs';
// API
import { ProductList } from 'actions/product';
import { CreatePackage } from 'actions/package';
import { Customer } from 'actions/customer/index';
import { LoadServices } from 'actions/service'
import { PackageTypeList } from 'actions/package';
import { PackageList } from 'actions/package';
import { loadAuth } from 'actions/auth';

import axios, { post, put } from 'axios';

export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { name } = useParams();
  const LogoFileRef = useRef(null);
  const productRef = useRef(null)
  const productRefScroll = () => productRef.current.scrollIntoView()

  const SizeModel = [
    {
      size: 'S',
      price: null
    },
    {
      size: 'M',
      price: null
    },
    {
      size: 'L',
      price: null
    },
    {
      size: 'TRUCK CAB',
      price: null
    },
    {
      size: 'SUV',
      price: null
    },
    {
      size: 'TRUCK 4 DOOR',
      price: null
    },
    {
      size: 'XL',
      price: null
    },
    {
      size: 'VAN',
      price: null
    }
  ]

  const ProcessingTime = [
    {
      key: 0,
      size: 'S',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      size: 'M',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      size: 'L',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      size: 'SUV',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      size: 'XL',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 7,
      size: 'VAN',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const ProcessingTimeModel = [
    {
      key: 0,
      model: null,
      code: null,
      size: 'S',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      model: null,
      code: null,
      size: 'M',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      model: null,
      code: null,
      size: 'L',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      model: null,
      code: null,
      size: 'TRUCK CAB',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      model: null,
      code: null,
      size: 'SUV',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      model: null,
      code: null,
      size: 'TRUCK 4 DOOR',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      model: null,
      code: null,
      size: 'XL',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 7,
      model: null,
      code: null,
      size: 'VAN',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const PackageOnlyPart = [
    {
      key: 0,
      size: 'กระโปรงหน้า',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      size: 'กระโปรงหลัง',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      size: 'ประตูหน้า',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      size: 'ประตูหลัง',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      size: 'กันชน',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      size: 'หลังคา',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      size: 'แก้ม',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const ProductSizesModel = [
    {
      key: 0,
      size: 'S',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 1,
      size: 'M',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 2,
      size: 'L',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 4,
      size: 'SUV',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 6,
      size: 'XL',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 7,
      size: 'VAN',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    }
  ]

  const ProductPartsModel = [
    {
      key: 0,
      size: 'กระโปรงหน้า',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 1,
      size: 'กระโปรงหลัง',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 2,
      size: 'ประตูหน้า',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 3,
      size: 'ประตูหลัง',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 4,
      size: 'กันชน',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 5,
      size: 'หลังคา',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 6,
      size: 'แก้ม',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    }
  ]

  const PeriodType = [
    {
      label: 'เดือน',
      value: 'เดือน'
    }
  ]

  const [Data, setData] = useState({
    name: null,
    code: null,
    period: null,
    period_type: null,
    service_time: null,
    treatment_time: null,
    warranty_period: null,
    schedule: null,
    setup: null,
    sizes: SizeModel,
    times: [],
    b2b_prices: [],
    products: [],
    product_times: [],
    active: false,
    range1: null,
    range2: null,
    renew_price: null,
    wash_upholstery: null,
    wash_door: null,
    wash_belt: null,
    enamel: null,
    wash: null,
    // for display
    product_list: [],
    model_list: [],
  });

  const [validated, setValidated] = useState(false);
  const [ValidatedProduct, setValidatedProduct] = useState(false);

  const [UnitSales, setUnitSales] = useState([])
  const [ProductId, setProductId] = useState(null)
  const [UnitSaleId, setUnitSaleId] = useState(null)
  const [Model, setModel] = useState(null)
  const [Code, setCode] = useState(null)
  const [Errors, setErrors] = useState([])
  const [tags, setTags] = React.useState([])
  const [DisableProduct,setDisableProduct] = useState(false);

  // Special
  const [SelectOption, setSelectOption] = useState('product')
 
  let modelChoice = []
  let ProductSizeChoice = []
  switch (name) {
    case 'บริการฟิล์มกรองแสง':
      modelChoice = ProcessingTimeModel
      ProductSizeChoice = ProductSizesModel
      break;
    case 'บริการเคลือบเฉพาะจุด':
      modelChoice = PackageOnlyPart
      ProductSizeChoice = ProductPartsModel
      break;
  
    default:
      modelChoice = ProcessingTime
      ProductSizeChoice = ProductSizesModel
      break;
  }

  const [ProductSizes, setProductSizes] = useState(ProductSizeChoice);
  const [ProductTimes, setProductTimes] = useState(modelChoice);

  let usingType = [
    { value: 'ครั้งแรก',
      label: 'ครั้งแรก',
      used:[
        'บริการล้างและเคลือบแว๊กซ์',
        'บริการฟิล์มกรองแสง',
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด',
        'บริการฟิล์มกันรอย'
      ] 
    },
    { value: 'ตามนัด',
      label: 'ตามนัด',
      used:[
        'บริการล้างและเคลือบแว๊กซ์'
      ]
    },
    { 
      value: 'เช็คการเซ็ตตัว',
      label: 'เช็คการเซ็ตตัว',
      used:[
        'บริการฟิล์มกรองแสง',
        'บริการฟิล์มกันรอย'
      ]
    },
    { 
      value: 'พ่นเคลือบ',
      label: 'พ่นเคลือบ',
      used:[
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด'
      ] 
    },
    { value: 'เก็บงาน',
      label: 'เก็บงาน',
      used:[
        'บริการฟิล์มกันรอย'
      ] 
    },
    { value: 'ทรีตเมนต์',
      label: 'ทรีตเมนต์',
      used:[
        'บริการฟิล์มกันรอย'
      ] 
    },
    { 
      value: 'ล้างรถ',
      label: 'ล้างรถ',
      used:[
        'บริการล้างและเคลือบแว๊กซ์',
        'บริการฟิล์มกรองแสง',
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด',
        'บริการฟิล์มกันรอย'
      ]
    },
    { value: 'เคลือบ',
      label: 'เคลือบ',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ล้าง',
      label: 'ล้าง',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ขัดสี',
      label: 'ขัดสี',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ซักเบาะ',
      label: 'ซักเบาะ',
      used:[
        'Interior'
      ] 
    },
    { value: 'ซักแผงประตู',
      label: 'ซักแผงประตู',
      used:[
        'Interior'
      ] 
    },
    { value: 'ซักสายเข็มขัด',
      label: 'ซักสายเข็มขัด',
      used:[
        'Interior'
      ] 
    },
    { value: 'เคลือบภายในรถ',
      label: 'เคลือบภายในรถ',
      used:[
        'Interior'
      ] 
    },
    { value: 'ล้างรถ',
      label: 'ล้างรถ',
      used:[
        'Interior'
      ] 
    },
    { value: 'อบโอโซน',
      label: 'อบโอโซน',
      used:[
        'Interior'
      ] 
    },
  ]
  
  const [useType, setUseType] = useState(usingType.filter(item => item.used.includes(name)));
  const [usedType, setUsedType] = useState([])
  const InsertProductSize = async (e) => {
    let valid = false

    if (!usedType.length) valid = true
    if (!ProductId) valid = true
    if (!UnitSaleId) valid = true

    if(valid) productRefScroll()
    setValidatedProduct(valid);
    
    if (!valid) {
      let obj = {}
      const data = []
      ProductSizes.forEach((product, key) => {
        obj.model = Model
        obj.code = Code
        obj.usedType = usedType
        obj[product.size] = Number(product.quantity) || null
        obj.product_id = ProductId
        obj.unit_sale_id = UnitSaleId
        product.usedType = usedType
        product.product_id = ProductId
        product.unit_sale_id = UnitSaleId
        obj.key = Data.product_list.length
        data.push(product)
      });

      obj.product = Products.find((item) => item.id === ProductId)
      obj.product_id = ProductId

      // delete all old data by product_id
      let filter = Data.products.filter((item) => item.product_id !== obj.product_id);
      let result = filter.concat(data)

      setData({...Data, product_list: [...Data.product_list, obj ], products: result})
      
      setProductSizes(ProductSizeChoice)
      setProducts([...Products.filter(item => item.id !== obj.product_id)])
      setUsedType([])
      setValidatedProduct(false);
      setProductId(null);
      setUnitSaleId(null)
    }
  }

  const InsertProductTime = async (e) => {
    let valid = false
    setValidatedProduct(valid);
    
    if (!valid) {

      let array = Data.model_list
      let data = []

      ProductTimes.forEach((product, key) => {
        data.push({
          model: Model,
          code: Code,
          [product.size]: Number(product.price) || null
        })
        product.model = Model
        product.code = Code
      });

      const checkExist = array.find(item => item.model === Model)

      if (!checkExist) {
        array.push(
          {
            model: Model,
            data: [data]
          }
        )

        setData({...Data, model_list: array, product_times: [...Data.product_times, ...ProductTimes]})
        setProductTimes(modelChoice)
        setValidatedProduct(false);
      }else{
        // exist
        let result = true
        checkExist.data.forEach(item => {
          const value = item.find(item => item.code === Code)
          if(value) result = false
        });

        if (result) {
          const index = array.findIndex(item => item.model === Model)
          array[index].data = [
            ...array[index].data,
            data
          ]

          setData({...Data, model_list: array, product_times: [...Data.product_times, ...ProductTimes]})
          setProductTimes(modelChoice)
          setValidatedProduct(false);
        }
      }
    }
  }

  const [ProductEditMode, setProductEditMode] = useState(false);

  const EditProduct = (product, table_key) => {
    const data = []

    let temp = {}
    ProductSizes.forEach(item => {
      let obj = {}
      if (product.ref) {
        let data = product.ref.find(value => value.size === item.size)
        obj.id = data.id
        obj.package_id = data.package_id
      }
      obj.usedType = product.usedType
      obj.size = item.size
      obj.quantity = product[item.size]
      obj.product_id = product.product_id
      obj.unit_sale_id = product.unit_sale_id
      temp = {
        product_id: product.product_id,
        unit_sale_id: product.unit_sale_id
      }
      obj.product = product.product
      obj.key = data.length
      obj.table_key = table_key
      data.push(obj)
    });

    const UsingType = product.usedType
    setUsedType(UsingType)

    const sales = product.product.unit.sales
    setUnitSales(sales)
    setUnitSaleId(temp.unit_sale_id)
    const pd = Products.filter(item => item.id === product.product_id)
    if (pd.length === 0) {
      setProducts([...Products, product.product])
    }
    
    setProductId(product.product_id)
    setProductSizes(data)
    setProductEditMode(true)
  }

  const DeleteProduct = (product, key) => {
    setData({...Data, products: Data.products.filter(item => item.product_id !== product.product_id),  product_list: Data.product_list.filter(item => item.product_id !== product.product_id) })
    setProducts([...Products, product.product]) 
  }

  const [Special, setSpecial] = useState({
    ProductId: null,
    Products: [],
    Data: {
      all_size: false,
      product_times: [
        {
          key: 0,
          size: 'ราคาเหมา',
          price: null,
          process_times: null,
          treatment_price: null,
          active: 0
        },
      ]
    },
    ServiceId: null,
    PackageId: null,
    Total: 0,
  });

  const InsertSpecial = (data, type) => {
    console.log('data, type',data, type);
    let exist = null
    switch (type) {
      case 'สินค้า':
        exist = Special.Products.find(item => item.id === data.product_id)
        break;
      case 'บริการ':
        exist = Special.Products.find(item => item.id === data.service_id)
        break;
      case 'แพ็กเกจ':
        exist = Special.Products.find(item => item.id === data.package_id)
        break;
    }
    if (!exist) {
      data.key = ( Special.Products.slice(-1).length > 0 ? Special.Products.slice(-1)[0].key : 0) + 1
      data.quantity = 1
      data.product_unit_sale_id = null
      data.sale_price = 0
      data.total = 0
      data.type = type

      if (type === 'สินค้า') {
        data.product_id = data.id
        data.service_id = null
        data.package_id = null
        data.product = data
        setProducts([...Products.filter(item => item.id !== data.id)])
      }
      if (type === 'บริการ') {
        data.service_id = data.id
        data.package_id = null
        data.product_id = null
        data.service = data
        setServices([...Services.filter(item => item.id !== data.id)])
      }
      if (type === 'แพ็กเกจ') {
        data.package_id = data.id
        data.service_id = null
        data.product_id = null
        data.package = data
        setPackages([...Packages.filter(item => item.id !== data.id)])
      }

      setSpecial({...Special, Products: [...Special.Products, data]})
      setPackage(null)
    }
  }

  const RemoveSpecial = (data) => {
    let result = null
    // console.log('data',data);
    switch (data.type) {
      case 'สินค้า':
        result = Special.Products.filter((item) => item.key !== data.key)
        setProducts([...Products, data.product])
        break;
      case 'บริการ':
        result = Special.Products.filter((item) => item.key !== data.key)
        setServices([...Services, data.service])
        break;
      case 'แพ็กเกจ':
        result = Special.Products.filter((item) => item.key !== data.key)
        setPackages([...Packages, data.package])
        break;
        default:
          break;
    }
    setSpecial({...Special, Products: result })
  }


  const [Products, setProducts] = useState([]);
  const [Customers, setCustomers] = useState([]);

  const GetProduct = async (data) => {
    try {
      const response = await dispatch(ProductList({ has_sales: true }));
      if (response) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async () => {
    const params = {
      type: ['โชว์รูม']
    }
    try {
      const response = await dispatch(Customer(params));
      if (response) {
        setCustomers(response.data.data);
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    
    let formData = new FormData();
    
    if(name === 'แพ็กเกจ'){
      formData.append('type', 'แพ็คเกจพิเศษ');
    }else{
      formData.append('type', name);
    }

    if (name === 'แพ็กเกจ') {
      formData.append('category', 'พิเศษ');
      let i = 0 ;
      while (i < Special.Data.product_times.length){
        if(Special.Data.product_times[i].size) formData.append(`product_times[${i+ProductTimes.length}][size]`, Special.Data.product_times[i].size);
        Special.Data.product_times[i].price ? formData.append(`product_times[${i+ProductTimes.length}][price]`, Special.Data.product_times[i].price) : formData.append(`product_times[${i+ProductTimes.length}][price]`, 0);
        if(Special.Data.product_times[i].process_times) formData.append(`product_times[${i+ProductTimes.length}][process_times]`, Special.Data.product_times[i].process_times);
        Special.Data.product_times[i].treatment_price ? formData.append(`product_times[${i+ProductTimes.length}][treatment_price]`, Special.Data.product_times[i].treatment_price) : formData.append(`product_times[${i+ProductTimes.length}][treatment_price]`, 0);
        formData.append(`product_times[${i+ProductTimes.length}][active]`, Special.Data.product_times[i].active);
        i++;
      }
      
      i = 0 ;
      while (i < Special.Products.length){
        const product = Special.Products[i]
        formData.append(`special_products[${i}][type]`, product.type);
        if(product.product_id) formData.append(`special_products[${i}][product_id]`, product.product_id);
        if(product.service_id)formData.append(`special_products[${i}][service_id]`, product.service_id);
        if(product.package_id) formData.append(`special_products[${i}][package_id]`, product.package_id);
        formData.append(`special_products[${i}][quantity]`, product.quantity);
        if(product.product_unit_sale_id) formData.append(`special_products[${i}][product_unit_sale_id]`, product.product_unit_sale_id);
        i++;
      }
    }else{
      formData.append('category', 'ธรรมดา');
    }
    
    if(Data.name) formData.append('name', Data.name);
    if(Data.code) formData.append('code', Data.code);
    if(Data.period) formData.append('period', Data.period);
    if(Data.period_type) formData.append('period_type', Data.period_type);
    if(Data.service_time) formData.append('service_time', Data.service_time);
    if(Data.schedule) formData.append('schedule', Data.schedule);
    if(Data.setup) formData.append('setup', Data.setup);
    if(Data.warranty_period) formData.append('warranty_period', Data.warranty_period);
    if(Data.treatment_time) formData.append('treatment_time', Data.treatment_time);
    if(Data.enamel) formData.append('enamel', Data.enamel);
    if(Data.wash) formData.append('wash', Data.wash);
    if(Data.wash_upholstery) formData.append('wash_upholstery', Data.wash_upholstery);
    if(Data.wash_door) formData.append('wash_door', Data.wash_door);
    if(Data.wash_belt) formData.append('wash_belt', Data.wash_belt);
    if(Data.range1 && Data.range2) formData.append('treatment_ranges', JSON.stringify([Data.range1 , Data.range2]));
    formData.append('active', Data.active);
    
    if(Data.product_times && Data.product_times.length > 0){
      let i = 0 ;
      while (i < Data.product_times.length){
        if(Data.product_times[i].model) formData.append(`product_times[${i}][model]`, Data.product_times[i].model);
        if(Data.product_times[i].code) formData.append(`product_times[${i}][code]`, Data.product_times[i].code);
        formData.append(`product_times[${i}][size]`, Data.product_times[i].size);
        formData.append(`product_times[${i}][price]`, Data.product_times[i].price ? Data.product_times[i].price : 0);
        formData.append(`product_times[${i}][process_times]`, Data.product_times[i].process_times);
        if(Data.product_times[i].treatment_price) formData.append(`product_times[${i}][treatment_price]`, Data.product_times[i].treatment_price);
        formData.append(`product_times[${i}][active]`, Data.product_times[i].active);
        i++;
      }
    }else{
      let i = 0 ;
      while (i < ProductTimes.length){
        formData.append(`product_times[${i}][size]`, ProductTimes[i].size);
        ProductTimes[i].price ? formData.append(`product_times[${i}][price]`, ProductTimes[i].price) : formData.append(`product_times[${i}][price]`, 0);
        if(ProductTimes[i].process_times) formData.append(`product_times[${i}][process_times]`, ProductTimes[i].process_times);
        if(ProductTimes[i].treatment_price) formData.append(`product_times[${i}][treatment_price]`, ProductTimes[i].treatment_price);
        formData.append(`product_times[${i}][active]`, ProductTimes[i].active);
        i++;
      }
    }

    if(Data.products && Data.products.length > 0){
      // console.log('Data.products',Data.products);
      let i = 0;
      let key = 0;
      while (i < Data.products.length){
          let product = Data.products[i]
          if(product.id) formData.append(`products[${key}][id]`, product.id);
          if(product.package_id) formData.append(`products[${key}][package_id]`, product.package_id);
          formData.append(`products[${key}][size]`, product.size);
          formData.append(`products[${key}][quantity]`, product.quantity);
          formData.append(`products[${key}][product_id]`, product.product_id);
          formData.append(`products[${key}][unit_sale_id]`, product.unit_sale_id);
          let type = []
          product.usedType.forEach(element => {
            type.push(element.value)
          });
          formData.append(`products[${key}][type]`, JSON.stringify(type));
          key++;
        i++;
      }
    }

    if(Data.logo) formData.append('logo', Data.logo[0]);

    dispatch(CreatePackage(formData)).then(response => {
      setErrors([])
      history.push(`/package/${name}`);
      
    }).catch(e => {
      let errors = []
      if (e.response && e.response.data && e.response.data.messages) {
        e.response.data.messages.forEach(element => {
          errors.push(element)
        });
        setErrors(errors)
      }
    })
  };
  
  const SelectUnit = (data, key) => {
    setSpecial(prevState => ({...Special,
      Products: prevState.Products.map(
        el => el.key === key ? { ...el, unit_sale: data, product_unit_sale_id: data.pivot.id, sale_price: data.pivot.price, total: data.pivot.price * el.quantity  }: el
      )
    })) 
  }

  const Changequantity = (value, key) => {
    setSpecial(prevState => ({...Special,
      Products: prevState.Products.map(
        el => el.key === key ? { ...el, quantity: value, total: el.sale_price * value }: el
      )
    }))
  }

  const SwitchPrice = (checked) => {
    let ChangeMiddleStatus = [...ProductTimes]
    
    if (checked) {
      ChangeMiddleStatus.forEach(item => {
        item.active = 0
      });

      setSpecial({...Special,
        Data:{
          ...Special.Data,
          all_size: checked,
          product_times: Special.Data.product_times.map(
            el => el.key === 0 ? { ...el, active: 1 }: el
          )
        }
      })
    }else{
      ChangeMiddleStatus.forEach(item => {
        item.active = 1
      });

      setSpecial({...Special,
        Data:{
          ...Special.Data,
          all_size: checked,
          product_times: Special.Data.product_times.map(
            el => el.key === 0 ? { ...el, active: 0 }: el
          )
        }
      })
    }

    setProductTimes(ChangeMiddleStatus)
  }

  const UpdateProductState = () => {
    let obj = {}
    let edited = []

    ProductSizes.forEach(product => {
      obj.usedType = usedType
      obj[product.size] = Number(product.quantity) || null
      obj.product_id = product.product_id
      obj.sale_unit_id = product.sale_unit_id
      obj.unit_sale_id = product.unit_sale_id
      obj.key = product.table_key
      obj.product = product.product
      product.usedType = usedType
      edited.push(product)
    });

    // delete all old data by product_id
    let filter = Data.products.filter((item) => item.product_id !== obj.product_id);
    let result = filter.concat(edited)

    setData(prevState => ({
      ...Data,
      product_list: prevState.product_list.map(
        el => el.key === obj.key ? obj : el
      ),
      products: result
    })) 

    setProductSizes(ProductSizeChoice)
    setValidatedProduct(false)
    setProductId(null)
    setUnitSaleId(null)
    setUsedType([])
    setProductEditMode(false)
  }

  const [ProductTimeEditMode, setProductTimeEditMode] = useState(false);
  const EditProductTime = (product) => {
    console.log('product', product)
    const data = []

    let model = null
    let code = null

    ProductTimes.forEach((item, key) => {
      let value = product.find(i => i.size === item.size)
      let time = {}
      time.key = key
      if(value && value.id) time.id = value.id
      if(value && value.package_id) time.package_id = value.package_id
      model = value.model
      code = value.code
      time.model = value.model
      time.code = value.code
      time.size = value.size
      time.price = value.price
      time.process_times = value.process_times
      time.treatment_price = value.treatment_price
      data.push(time)
    });

    setModel(model)
    setCode(code)

    setProductTimes(data)
    setProductTimeEditMode(true)
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการแพ็คเกจ'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push(`/package/${name}`);
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  useEffect(() => {
    getAuth();
    GetProduct();
    GetCustomer();
    // Special
    if ((['แพ็กเกจ']).includes(name)) {
      GetService();
      GetPackageType();
    }
  }, []);

  useEffect(() => {
    if(UnitSaleId !== null){
      setDisableProduct(true)
    }else{
      setDisableProduct(false)
    }
    
  },[UnitSaleId])

  const [FileName, setFileName] = useState({
    Logo: '',
    Image: []
  });

  const ToggleDocumentFile = () => {
    if(LogoFileRef.current) LogoFileRef.current.click();
  }

  const SetLogoFile = (e) => {
    setData({ ...Data, logo : e.target.files})
    const name = []
    const logo = []
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      logo.push(URL.createObjectURL(item))
      name.push(item.name)
    }
    setFileName({ ...FileName, Logo : name, Image: logo})
  }

  const DisplayPrice = (name, sizes) => {
    const result = sizes.find(item => item[name])
    return result && result[name] ? result[name] : ''
  }

  const [Services, setServices] = useState([]);
  async function GetService(){
    try {
      const response = await dispatch(LoadServices());
      const service = response.data.data
      setServices(service);
    }  catch (error) {
      console.error(error);
    } finally {

    }
  }

  const [PackageType, setPackageType] = useState([]);

  const GetPackageType = async (data) => {
    try {
      const response = await dispatch(PackageTypeList());
      if (response) {
        let data = response.data.data
        let result = []
        data.forEach(item => {
          if (item.name !== 'บริการเคลือบเฉพาะจุด' && item.name !== 'แพ็กเกจ' && item.name !== 'แพ็คเกจพิเศษ') {
            result.push(item)
          }
        });
        setPackageType(result)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function generateCode(){
    let gcode = dayjs().format('YYMMDDHHmmss');
    setData({...Data, code: gcode})
    console.log(Data)
    return gcode;
  }

  const [Packages, setPackages] = useState([]);
  const [Package, setPackage] = useState([]);
  const GetPackage = async (type) => {
    try {
      const params = {
        type
      }
      const response = await dispatch(PackageList(params))
      if (response) {
        setPackages(response.data.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function close() {
    history.push(`/`);
  }

  function exit() {
    history.push(`/package/${name}`);
  }
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Package_image} />
                  <LinkContainer to={`/package/${name}`} className="cursor-pointer">
                    <b>แพ็คเกจ &gt; { name }</b> 
                  </LinkContainer>
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Package_image} />
                  <b>แพ็คเกจ &gt; { name }</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="tabsicon">
                  <TabsIcon />
                </Row>
                <Row className="tabs-button">
                  <Col>
                    <Tabs
                      defaultActiveKey="middle"
                      transition={false}
                      id="noanim-tab-example"
                      className="tabs-package mb-3"
                    >

                      <Tab eventKey="middle" title="ราคากลาง">
                        {
                          Errors.length > 0 &&
                          <Alert variant="danger">
                            <Alert.Heading>เกิดข้อผิดพลาด !</Alert.Heading>
                            <div className='text-left'>
                            {
                              Errors.map((error, key) => {
                                return(
                                  <p>{error}</p>
                                )
                              })
                            }
                            </div>
                          </Alert>
                        }

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Container
                            fluid
                            className="package-container Service Stock User set-heght"
                          >
                              <p className="form-title">ข้อมูลสินค้า</p>

                              <Row className="g-0">
                                <Col xs={2} sm={3} md={3} lg={2} xl={2} className="text-title">
                                  <p>ชื่อสินค้า</p>
                                </Col>
                                <Col xs={10} md={6} lg={5} xl={4}>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={Data.name}
                                    required
                                    onChange={(e) => setData({...Data, name: e.target.value})}
                                    className="input-middle-price my-1"
                                  ></Form.Control>
                                </Col>
                                <Col className="set-col-button-delete">
                                  {/* <Button type="button" className="delete-package">
                                    <img src={Delete} className="" /> &nbsp; ลบสินค้า
                                  </Button> */}
                                </Col>
                              </Row>

                              <Row className="g-0">
                                <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                  <p>รหัสสินค้า</p>
                                </Col>
                                <Col xs={8} md={8} lg={3} xl={2}>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={Data.code ? Data.code : generateCode()}
                                    required
                                    onChange={(e) => setData({...Data, code: e.target.value})}
                                    className="input-middle-price my-1"
                                  ></Form.Control>
                                </Col>
                                  
                                {
                                  (['บริการฟิล์มกรองแสง', 'บริการเคลือบเหมา', 'บริการเคลือบเฉพาะจุด', 'บริการฟิล์มกันรอย']).includes(name)
                                  &&
                                  <>
                                    <Col xs={4} sm={4} md={4} lg={3} xl={2} xxl={1} className="text-title text-setting-position">
                                      <p>{(['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ? 'กำหนดการเซ็ตตัว': 'กำหนดพ่นเคลือบหลังติดตั้ง'}</p>
                                    </Col>

                                    <Col xs={8} md={8} lg={4} xl={3}>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={Data.setup ? Data.setup : ''}
                                        required
                                        onChange={(e) => setData({...Data, setup: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>

                                    <Col className="text-title">
                                      <p>วัน (แจ้งเตือน)</p>
                                    </Col>
                                  </>
                                }

                              </Row>

                              {
                                name === 'บริการล้างและเคลือบแว๊กซ์'
                                &&
                                <>        
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ระยะเวลาให้บริการ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={4}>
                                      <Row>
                                        <Col>
                                          <Form.Control
                                            type="number"
                                            placeholder="ใส่ตัวเลข"
                                            value={Data.period}
                                            // required
                                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                            onChange={(e) => setData({...Data, period: e.target.value})}
                                            min={0}
                                            onWheel={(e) => e.target.blur() }
                                            className="input-middle-price my-1"
                                          ></Form.Control>
                                        </Col>
                                        <Col>
                                            <Select
                                              placeholder="เดือน"
                                              isClearable={false}
                                              options={PeriodType}
                                              // required
                                              onChange={(data) => setData({...Data, period_type: data.value})}
                                              className="select-list my-1"
                                            />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>จำนวนครั้งที่รับบริการ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={4}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.service_time}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, service_time: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </>
                              }

                              {
                                name === 'Box Set'
                                &&
                                <>
                                  <Row className="g-0 mt-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>จำนวนครั้งรับบริการ</p>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>เคลือบ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.enamel}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, enamel: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 mb-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ล้าง</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </>
                              }


                              {
                                name === 'Interior'
                                &&
                                <>
                                  <Row className="g-0 mt-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>จำนวนครั้งรับบริการ</p>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักเบาะ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_upholstery}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_upholstery: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักสายเข็มขัด</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_belt}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_belt: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักแผงประตู</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_door}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_door: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>เคลือบภายใน</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.enamel}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, enamel: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 mb-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ล้างรถ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </>
                              }

                              {
                                name === 'บริการฟิล์มกันรอย'
                                &&
                                <>
                                  <Row className="g-0">
                                    <Col xs={2} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>กำหนดการเรียกเก็บงาน</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={4}>
                                      <Row>
                                        <Col>
                                          <Form.Control
                                            type="number"
                                            placeholder=""
                                            value={Data.schedule ? Data.schedule : ''}
                                            required
                                            min={0}
                                            onWheel={(e) => e.target.blur() }
                                            onChange={(e) => setData({...Data, schedule: e.target.value})}
                                            className="input-middle-price my-1"
                                          ></Form.Control>
                                        </Col>
                                        <Col className="text-title">
                                          <p>วัน</p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              }

                              {
                                (['บริการฟิล์มกรองแสง', 'บริการเคลือบเหมา','บริการเคลือบเฉพาะจุด', 'บริการฟิล์มกันรอย']).includes(name)
                                &&
                                <>        
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ระยะเวลารับประกัน</p>
                                    </Col>

                                    <Col xs={8} md={8} lg={6} xl={4}>
                                      <Row>
                                        <Col>
                                          <Form.Control
                                            type="number"
                                            placeholder="ใส่ตัวเลข"
                                            value={Data.warranty_period ? Data.warranty_period : ''}
                                            required
                                            min={0}
                                            onWheel={(e) => e.target.blur() }
                                            onChange={(e) => setData({...Data, warranty_period: e.target.value})}
                                            className="input-middle-price my-1"
                                          ></Form.Control>
                                        </Col>
                                        <Col className="text-title">
                                          <p>ปี</p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {
                                    (['บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name)
                                    &&
                                    <>
                                      <Row className="g-0">
                                        <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                          <p>ราคาเคลมต่ออายุประกัน</p>
                                        </Col>

                                        <Col xs={8} md={8} lg={6} xl={4}>
                                          <Row>
                                            <Col>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={Data.period}
                                                required
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onChange={(e) => setData({...Data, period: e.target.value})}
                                                className="input-middle-price my-1"
                                              ></Form.Control>
                                            </Col>
                                            <Col className="text-title">
                                              <p>บาท</p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </>
                                  }
                                </>
                              }

                              <Row className="g-0">
                                <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                  <p>อัพโหลดโลโก้</p>
                                </Col>
                                <Col xl={4} lg={6} md={6} xs={10} className="display-text-upload">
                                  <div className='d-flex w-100'>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={FileName.Logo}
                                      className="input-search-add-staff mr-3"
                                      readOnly
                                    ></Form.Control>
                                    <Button type="button" className="delete-package input-search-add-staff" onClick={ToggleDocumentFile}>
                                      <img src={uploadImage} /> &nbsp; อัพโหลดโลโก้
                                    </Button>
                                  </div>
                                  {/* hidden file input */}
                                  <Form.Control
                                    type="file"
                                    className="d-none"
                                    id="document-files"
                                    ref={LogoFileRef}
                                    label={Data.Logo}
                                    onChange={(e) => SetLogoFile(e) }
                                  />
                                  {
                                    FileName.Image.map((logo, key) => {
                                      return(
                                        <img src={logo} width="100%"  alt=""  />
                                      )
                                    })
                                  }
                                </Col>
                              </Row>

                            {
                              (['บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name) && 
                              <>
                                <p className="form-title">ทรีตเมนต์</p>
                                <Row className="g-0">
                                  <Col lg={2} className="text-title">
                                    <p>จำนวน</p>
                                  </Col>

                                  <Col lg={2}>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={Data.treatment_time ? Data.treatment_time : ''}
                                      required
                                      onChange={(e) => setData({...Data, treatment_time: e.target.value})}
                                      className="input-middle-price"
                                    ></Form.Control>
                                  </Col>

                                  <Col className="text-title">
                                    <p>ครั้ง</p>
                                  </Col>
                                </Row>

                                <Row className="g-0 mt-2">
                                  <Col lg={2} className="text-title">
                                    <p>ช่วงเข้ารับบริการ</p>
                                  </Col>

                                  <Col lg={2} className='d-flex'>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={Data.range1 ? Data.range1 : ''}
                                      required
                                      onChange={(e) => setData({...Data, range1: e.target.value})}
                                      className="input-middle-price"
                                    ></Form.Control>

                                    <div className="text-title mx-2">
                                      <p>-</p>
                                    </div>
                                    
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={Data.range2 ? Data.range2 : ''}
                                      required
                                      onChange={(e) => setData({...Data, range2: e.target.value})}
                                      className="input-middle-price"
                                    ></Form.Control>
                                  </Col>

                                  <Col className="text-title">
                                    <p>เดือน</p>
                                  </Col>
                                </Row>
                              </>
                            }

                            {
                              (['แพ็กเกจ']).includes(name) &&
                              <Row className="g-0">
                                <Col className="d-flex justify-content-start set-position-radio">
                                <Form.Group className="package-data" controlId="formbasicEmail">
                                  {
                                    ['radio'].map((type) => (
                                      <div key={`inline-${type}`} className="mb-3">
                                      
                                        <Form.Check
                                          inline
                                          label="วัสดุ/อุปกรณ์"
                                          name="group1"
                                          type={type}
                                          id={`inline-${type}-1`}
                                          checked={SelectOption === 'product'}
                                          onChange={(e) => setSelectOption('product')}
                                        />
                                        <Form.Check
                                          inline
                                          label="บริการ"
                                          name="group1"
                                          type={type}
                                          id={`inline-${type}-2`}
                                          checked={SelectOption === 'service'}
                                          onChange={(e) => setSelectOption('service')}
                                        />
                                        <Form.Check
                                          inline
                                          label="สินค้า"
                                          name="group1"
                                          type={type}
                                          id={`inline-${type}-3`}
                                          checked={SelectOption === 'package'}
                                          onChange={(e) => setSelectOption('package')}
                                        />
                                      </div>
                                    ))
                                  }
                                  </Form.Group>
                                </Col>
                                {
                                  SelectOption === 'product' &&
                                  <Col lg={12} className="mt-2 mb-2">
                                    <Select
                                      options={Products}
                                      placeholder="ค้นหาสินค้าด้วยรหัสสินค้า, ชื่อสินค้า"
                                      isClearable={false}
                                      className="select-list"
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.id}
                                      onChange={(data) => { InsertSpecial(data, 'สินค้า')}}
                                    />
                                  </Col>
                                }

                                {
                                  SelectOption === 'service' &&
                                  <Col lg={12} className="mt-2 mb-2">
                                    <Select
                                      options={Services}
                                      placeholder="ค้นหาสินค้าด้วยรหัสบริการ, ชื่อบริการ"
                                      isClearable={false}
                                      className="select-list"
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.id}
                                      onChange={(data) => { InsertSpecial(data, 'บริการ')}}
                                    />
                                  </Col>
                                }

                                {
                                  SelectOption === 'package' &&
                                  <Col lg={12} className="mt-2 mb-2">
                                    <Select
                                      options={PackageType}
                                      placeholder="เลือกประเภท"
                                      isClearable={false}
                                      className="select-list"
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.id}
                                      onChange={(data) => GetPackage(data.name)}
                                    />
                                    <Select
                                      options={Packages}
                                      placeholder="เลือกแพ็คเกจ"
                                      isClearable={false}
                                      className="select-list"
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.id}
                                      value={Package}
                                      onChange={(data) => { InsertSpecial(data, 'แพ็กเกจ')}}
                                    />
                                  </Col>
                                }

                                <Col lg={12}>
                                  <table className="table table-responsive table-wash-part ">
                                    <thead>
                                      <tr className="t-header-package">
                                        <th>ชื่อรายการ</th>
                                        <th style={{ 'width': "20%"}}>จำนวน</th>
                                        <th style={{ 'width': "25%"}}>เลือกหน่วย</th>
                                        {/* <th>ราคาต่อหน่วย</th>
                                        <th>ยอดรวม</th> */}
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody className="t-body-package ">
                                      { 
                                        Special.Products.map((product, key) => {
                                          return (
                                            <tr key={ product.key }>
                                              <td>{ `${product.name}` }</td>
                                              <td>
                                                { product.type !== 'สินค้า' }
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  required
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onChange={
                                                    (e) => Changequantity(e.target.value, key)
                                                  }
                                                  disabled={product.type !== 'สินค้า'}
                                                  className="input-middle-price"
                                                ></Form.Control>
                                              </td>
                                              <td>
                                                {
                                                  product.type === 'สินค้า' &&
                                                  <Select
                                                    options={product.unit_sales}
                                                    placeholder="หน่วยขาย"
                                                    required
                                                    isClearable={false}
                                                    className={`select-list ` + (!product.product_unit_sale_id && validated ? 'validate-fail' : '') }
                                                    value={product.unit_sale}
                                                    getOptionLabel={option => option.name_sale}
                                                    getOptionValue={option => option.id}
                                                    onChange={
                                                      (data) => SelectUnit(data, key)
                                                    }
                                                  />
                                                }
                                              </td>
                                              {/* <td>{ `${product.sale_price}` }</td>
                                              <td>{ `${product.total}` }</td> */}
                                              <td>
                                                <img src={Delete} onClick={() => RemoveSpecial(product)} />
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                  {/* รวม { Special.Total } */}
                                </Col>
                              </Row>
                            }

                            {
                              (['บริการเคลือบเฉพาะจุด']).includes(name) && <p className="form-title">ขนาดรถและราคาเฉพาะจุด</p>
                            }

                            {
                              (['บริการฟิล์มกรองแสง']).includes(name) && <p className="form-title">ตั้งราคา</p>
                            }

                            {
                              (['บริการล้างและเคลือบแว๊กซ์', 'บริการเคลือบเหมา']).includes(name) && <p className="form-title">ขนาดรถและราคา</p>
                            }

                            {
                              (['บริการฟิล์มกันรอย']).includes(name) && <p className="form-title">ราคา</p>
                            }

                            {
                              <Form.Group className="package-data" controlId="formBasicEmail">
                                {
                                  (['แพ็กเกจ']).includes(name) &&
                                  Special.Data.product_times?.map((time, key) => {
                                    return(
                                      <Row>
                                        <Col lg={6}>
                                          <Row>
                                            <Col
                                              xs={4}
                                              sm={3}
                                              md={3}
                                              lg={4}
                                              xl={4}
                                              className="text-title"
                                            >
                                              <p>{Special.Data.all_size ? 'ราคาเหมา' : '' }</p> <Switch checked={Special.Data.all_size} className={'ml-3'} onChange={(checked) => SwitchPrice(checked) } /> {' '}
                                            </Col>
                                            <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time.price ? time.price : ''}
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={!Special.Data.all_size}
                                                onChange={
                                                  (e) => setSpecial({...Special,
                                                    Data:{
                                                      ...Special.Data,
                                                      product_times: Special.Data.product_times.map(
                                                        el => el.key === key ? { ...el, price: e.target.value }: el
                                                      )
                                                    }
                                                  })
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                            </Col>
                                            <Col xs={1} sm={2} className="text-bath">
                                              <p>บาท</p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col lg={6}>
                                          <Row>
                                            <Col
                                              xs={4}
                                              sm={3}
                                              md={3}
                                              lg={3}
                                              xl={3}
                                              className="text-title"
                                            >
                                              <p>เวลาดำเนินการ</p>
                                            </Col>
                                            <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time.process_times ? time.process_times : ''}
                                                // required
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={!Special.Data.all_size}
                                                onChange={
                                                  (e) => setSpecial({...Special,
                                                    Data:{
                                                      ...Special.Data,
                                                      product_times: Special.Data.product_times.map(
                                                        el => el.key === key ? { ...el, process_times: e.target.value }: el
                                                      )
                                                    }
                                                  })
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                            </Col>
                                            <Col xs={1} sm={2} className="text-bath">
                                              <p>นาที</p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                              </Form.Group>
                            }

                            {
                              (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                              <Row className="g-0">
                                <Col lg={12}>
                                  <div className='package-size-group'>
                                    <div className='package-size-col'>
                                      <p>รุ่น</p>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={Model}
                                        required
                                        onChange={(e) => setModel(e.target.value)}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </div>

                                    <div className='package-size-col'>
                                      <p>เลขรุ่น</p>
                                      <Form.Control
                                        id="code"
                                        type="text"
                                        placeholder=""
                                        value={Code}
                                        required
                                        onChange={(e) => setCode(e.target.value)}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            }

                            {
                              <Form.Group className="package-data" controlId="formBasicEmail">
                                {
                                  ProductTimes?.map((time, key) => {
                                    return(
                                      <Row>
                                        <Col lg={12}>
                                          <div className='package-size-group'>
                                            <div className='package-size-col'>
                                              <p>{time.size}</p>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time.price ? time.price : ''}
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={Special.Data.all_size}
                                                onChange={
                                                  (e) => setProductTimes(ProductTimes.map(
                                                    el => el.key === key ? { ...el, price: e.target.value }: el
                                                  )) 
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                              <p className='text-end'>บาท</p>
                                            </div>
                                            
                                            <div className='package-size-col'>
                                              <p>เวลาดำเนินการ</p>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time.process_times ? time.process_times : ''}
                                                // required
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={Special.Data.all_size}
                                                onChange={
                                                  (e) => setProductTimes(ProductTimes.map(
                                                    el => el.key === key ? { ...el, process_times: e.target.value }: el
                                                  )) 
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                              <p className='text-end'>นาที</p>
                                            </div>

                                            {
                                              (['บริการเคลือบเหมา', 'บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                                              <div className='package-size-col'>
                                                <p>ราคาทรีทเม้นท์</p>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={time.treatment_price ? time.treatment_price : ''}
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                  // disabled={Special.Data.all_size}
                                                  onChange={
                                                    (e) => setProductTimes(ProductTimes.map(
                                                      el => el.key === key ? { ...el, treatment_price: e.target.value }: el
                                                    )) 
                                                  }
                                                  className="input-search-add-wash-part"
                                                ></Form.Control>
                                                <p className='text-end'>บาท</p>
                                              </div>
                                            }
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                              </Form.Group>
                            }

                            {
                              (['บริการฟิล์มกรองแสง']).includes(name) &&
                              <Row className="g-0">
                                <Col
                                  xl={12}
            
                                >
                                  <Button
                                    type="button"
                                    className="button-package-save mt-4 mb-4"
                                    style={{ margin: 0 }}
                                    onClick={ () => InsertProductTime()}
                                  >
                                    <img src={Increase} /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                  </Button>
                                </Col>
                              </Row>
                            }

                            {
                              (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                              Data.model_list?.map((model, index) => {
                                return (
                                  <Row className="g-0">
                                    <Col xs={12} className='mb-4'>
                                    <p className='p-0 m-0 text-left form-title'>ราคา</p>
                                    </Col>
                                    <Col>
                                      <Table className="table table-responsive table-wash-part ">
                                        <thead>
                                          <tr className="t-header-package">
                                            <th>รุ่น</th>
                                            <th>เลขรุ่น</th>
                                            <th>S</th>
                                            <th>M</th>
                                            <th>L</th>
                                            <th>TRUCK CAB</th>
                                            <th>SUV</th>
                                            <th>TRUCK 4 DOOR </th>
                                            <th>XL</th>
                                            <th>VAN</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody className="t-body-package">
                                          {
                                            model.data?.map((product, key) => {
                                              return(
                                                <tr key={`column-${key}`}>
                                                  <td>
                                                    { !key ? model.model : ''}
                                                  </td>
                                                  <td>{product[key].code}</td>
                                                  <td>{DisplayPrice('S', product)}</td>
                                                  <td>{DisplayPrice('M', product)}</td>
                                                  <td>{DisplayPrice('L', product)}</td>
                                                  <td>{DisplayPrice('TRUCK CAB', product)}</td>
                                                  <td>{DisplayPrice('SUV', product)}</td>
                                                  <td>{DisplayPrice('TRUCK 4 DOOR', product)}</td>
                                                  <td>{DisplayPrice('XL', product)}</td>
                                                  <td>{DisplayPrice('VAN', product)}</td>
                                                  <td>
                                                    <img src={Increase} onClick={() => EditProductTime(product)}/>
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                )
                              })
                            }

                            { 
                              !(['แพ็กเกจ']).includes(name) &&
                              <>
                                <p ref={productRef} className="form-title">วัสดุ/อุปกรณ์อ้างอิง</p>
                                <Row>
                                  <Col lg={6}>
                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          isMulti
                                          placeholder="เลือก"
                                          required={!validated || ValidatedProduct}
                                          options={useType}
                                          value={usedType}
                                          onChange={(data) => setUsedType(data)}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                        />
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          options={Products}
                                          placeholder="ค้นหาสต๊อก"
                                          required={!validated || ValidatedProduct}
                                          isClearable={true}
                                          isSearchable={true}
                                          isDisabled={DisableProduct}
                                          // className="select-list"
                                          getOptionLabel={option => option.name}
                                          getOptionValue={option => option.id}
                                          value={ProductId ? Products.find((item) => item.id === ProductId) : null }
                                          onChange={(data) => {setProductId(data?.id ? data.id : null); setUnitSales(data?.unit_sales ? data.unit_sales : null)}}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                        />
                                      </Col>
                                    </Row>
  
                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          options={UnitSales}
                                          required={!validated || ValidatedProduct}
                                          placeholder="เลือกหน่วยขาย"
                                          isClearable={true}
                                          isSearchable={true}
                                          // className="select-list"
                                          getOptionLabel={option => option.name_sale}
                                          getOptionValue={option => option.id}
                                          value={UnitSaleId ? UnitSales.find((item) => item.id === UnitSaleId) : null }
                                          isDisabled={!ProductId || UnitSales && UnitSales.length === 0 }
                                          onChange={(data) => setUnitSaleId(data?.id ? data.id : null)}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                {
                                  ProductSizes?.map((product, key) => {
                                    return(
                                      <Row className="g-0">
                                        <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                                          <p>{product.size}</p>
                                        </Col>
                                        <Col xs={9} sm={4} md={4} lg={3} xl={2} className="input-stock">
                                          <Form.Control
                                            type="number"
                                            name={product.size}
                                            placeholder=""
                                            className="input-search-add-wash-part"
                                            value={product.quantity ? product.quantity : ''}
                                            required={!validated || ValidatedProduct}
                                            min={0}
                                            onWheel={(e) => e.target.blur() }
                                            isInvalid={!product.quantity && ValidatedProduct}
                                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                            onChange={
                                              (e) => setProductSizes(ProductSizes.map(
                                                el => el.key === key ? { ...el, quantity: e.target.value }: el
                                              )) 
                                            }
                                          ></Form.Control>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }

                                <Row className="g-0">
                                  <Col xs={0} sm={3} md={3} lg={2} xl={2} className="text-title">
                                  </Col>
                                  <Col
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    className="set-row-group-input"
                                  >
                                  {
                                    ProductEditMode 
                                    ?
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      style={{ margin: 0 }}
                                      onClick={ () => UpdateProductState()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                                    </Button>
                                    :
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      style={{ margin: 0 }}
                                      onClick={ () => InsertProductSize()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                    </Button>
                                  }
                                  </Col>
                                </Row>

                                <Row className="g-0" style={{ overflow: 'scroll', height: '200px' }}>
                                  <Col>
                                    <Table className="table table-responsive table-wash-part ">
                                      <thead>
                                        {
                                          !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                            <tr className="t-header-package">
                                              <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                                              <th>ประเภทงาน</th>
                                              <th>S</th>
                                              <th>M</th>
                                              <th>L</th>
                                              <th>TRUCK CAB</th>
                                              <th>SUV</th>
                                              <th>TRUCK 4 DOOR </th>
                                              <th>XL</th>
                                              <th>VAN</th>
                                              <th></th>
                                              <th></th>
                                            </tr>
                                          :
                                          <tr className="t-header-package">
                                            <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                                            <th>ประเภทงาน</th>
                                            <th>กระโปรงหน้า</th>
                                            <th>กระโปรงหลัง</th>
                                            <th>ประตูหน้า</th>
                                            <th>ประตูหลัง</th>
                                            <th>กันชน</th>
                                            <th>หลังคา</th>
                                            <th>แก้ม</th>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                        }
                                      </thead>
                                      <tbody className="t-body-package">
                                        {
                                          !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                          Data.product_list?.map((product, key) => {
                                            return(
                                              <tr key={`column-${key}`}>
                                                <td>{product.product.name}</td>
                                                <td>
                                                    {
                                                      product.usedType?.map((type, key) => {
                                                        return(
                                                          <>
                                                            { type.value }
                                                            { key + 1 < product.usedType.length && ',' }
                                                          </>
                                                        )
                                                      })
                                                    }
                                                </td>
                                                <td>{product.S}</td>
                                                <td>{product.M}</td>
                                                <td>{product.L}</td>
                                                <td>{product['TRUCK CAB']}</td>
                                                <td>{product['SUV']}</td>
                                                <td>{product['TRUCK 4 DOOR']}</td>
                                                <td>{product['XL']}</td>
                                                <td>{product['VAN']}</td>
                                                <td><img src={Increase} onClick={() => EditProduct(product, key)} /></td>
                                                <td>                                    
                                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>
                                                </td>
                                              </tr>
                                            )
                                          })
                                          :
                                          Data.product_list?.map((product, key) => {
                                            return(
                                              <tr key={`column-${key}`}>
                                                <td>{product.product.name}</td>
                                                <td>
                                                    {
                                                      product.usedType?.map((type, key) => {
                                                        return(
                                                          <>
                                                            { type.value }
                                                            { key + 1 < product.usedType.length && ',' }
                                                          </>
                                                        )
                                                      })
                                                    }
                                                </td>
                                                <td>{product['กระโปรงหน้า']}</td>
                                                <td>{product['กระโปรงหลัง']}</td>
                                                <td>{product['ประตูหน้า']}</td>
                                                <td>{product['ประตูหลัง']}</td>
                                                <td>{product['กันชน']}</td>
                                                <td>{product['หลังคา']}</td>
                                                <td>{product['แก้ม']}</td>
                                                <td><img src={Increase} onClick={() => EditProduct(product, key)} /></td>
                                                <td>                                    
                                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>
                                                </td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </>
                            }

                            <Row>
                              <Col className="colum-open-service">
                                <p>{Data.active ? 'เปิด' : 'ปิด' } ให้บริการ</p> <Switch checked={Data.active} onChange={(checked) => setData({...Data, active: checked})} /> {' '}
                              </Col>
                            </Row>

                            <Row className="g-0 d-flex justify-content-center row-delete-mobile">
                              <Button type="button" className="button-package-save">
                                <img src={Delete} className="" /> &nbsp; ลบสินค้า
                              </Button>
                            </Row>

                            <Row className="g-0 mt-5 mb-5">
                              <Col className="d-flex justify-content-center">
                                <Button type="submit" className="button-package-save">
                                  <img src={Save} /> &nbsp; &nbsp; บันทึก
                                </Button>
                                <Button type="button" className="button-package-save" onClick={exit}>
                                  <img src={getout} className="" /> &nbsp; &nbsp; ออก
                                </Button>
                              </Col>
                            </Row>

                          </Container>
                        </Form>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}