import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form,Button } from 'react-bootstrap';
import ShowId from './ShowId';
import Edit from './Edit';
export default function Data(props) {
  const [key, setkey] = useState('');
  const [formedit, setFormedit] = useState(false);

  const changeform = (changeformprops) => {
    setFormedit(changeformprops);
  };

  function DisplayPhone(data) {
    // console.log('display phone', [data?.Employee?.phone,JSON.parse(data?.Employee?.phone)]);
    return data?.Employee?.phone
  }
  

  // console.log('props',props.data.Avatar);

  useEffect(() => {
    setFormedit(false)
  }, []);
  
  // console.log('data',props.data);

  return (
    <>
      <Container fluid className="">
        <Row className="justify-content-center">
          <Col lg={11} classsName="">
            {formedit === true ? (
              <Edit changeFormToEdit={changeform.bind(this)} data={props.data} GetProfile={props.GetProfile}/>
            ) : (
              <ShowId changeFormToEdit={changeform.bind(this)} data={props.data} GetProfile={props.GetProfile} />
            )}
          </Col>
        </Row>
        <Row className="justify-content-center set-row-data">
          <Col xs={11} sm={11} md={11} lg={11} className=" bg-profile">
            <Row className="set-title">
              <Col className="d-flex">
                <Form.Label className="title-data">ข้อมูลส่วนตัว</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={4} lg={4} xl={4} className="d-flex">
                    <Form.Label>ชื่อ-สกุล</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.prefix } { props.data.Employee && props.data.Employee.fullname } &nbsp; ชื่อเล่น  &nbsp; { props.data.Employee && props.data.Employee.nickname} &nbsp; A.K.A  &nbsp; { props.data.Employee && props.data.Employee.aka}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={2}>
                <Row>
                  <Col xs={4} lg={3} className="d-flex">
                    <Form.Label>เพศ</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.gender }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <Col xs={4} lg={4} className="text-left d-flex">
                    <Form.Label>วัน/เดือน/ปีเกิด</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.birthdate }</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={3}>
                <Row>
                  <Col xs={4} lg={8} className="d-flex">
                    <Form.Label>สาขา</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.branch.name }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={3}>
                <Row>
                  <Col xs={4} lg={2} className="d-flex">
                    <Form.Label>แผนก</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.department.name }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={4} lg={3} className="d-flex">
                    <Form.Label>วันเริ่มต้นทำงาน</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.start_date }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <Col xs={4} lg={6} className="d-flex">
                    <Form.Label>สถานะการทำงาน</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.type }</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={4} lg={2} className="d-flex">
                <Form.Label>ที่อยู่</Form.Label>
              </Col>
              <Col className="text-left">
                <p>
                  { props.data.Employee && props.data.Employee.address }
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={4} lg={5} className="d-flex">
                    <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  </Col>
                  <Col className="text-left pl-lg-1">
                    <p>{ DisplayPhone(props.data) }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={7}>
                <Row>
                  <Col xs={8} lg={4} className="d-flex">
                    <Form.Label>เลขบัตรประชาชน/พาสปอร์ต</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.citizen_id }</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={12}>
                <Row>
                  <Col xs={4} lg={2} className="d-flex">
                    <Form.Label>E-mail</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>{ props.data.Employee && props.data.Employee.email }</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={4} lg={2} className="d-flex">
                    <Form.Label>คติประจำใจ</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <p>
                    { props.data.Employee && props.data.Employee.motto }
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={4} lg={2} className="d-flex">
                    <Form.Label>ไฟล์ที่อัพโหลด</Form.Label>
                  </Col>
                  <Col className="text-left">
                    {/* <p>สำเนาทะเบียนบ้าน.pdf </p>
                    <p>สำเนาบัตรประชาชน.pdf </p> */}
                    { 
                      props.data.Employee && props.data.Employee.personal_documents.map(item => {
                        return (
                          <p>item.employee_id</p>
                        );
                      })
                        }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
