import React, { useState , useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, ToastContainer, Toast } from 'react-bootstrap';
import Select from 'react-select';
import { DatePicker } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import Save from 'assets/icon/save.png';
import { useDispatch } from 'react-redux';
import Upload from 'assets/icon/upload.png';
import Delete from 'assets/icon/delete.png';
import ReactTagInput from '@pathofdev/react-tag-input';

import 'assets/css/validate.css';

// API
import { Province, Amphure, District } from 'actions/data/index';
import { Branch } from 'actions/branch/index';
import { Department } from 'actions/department/index';
import { FindEmployee, UpdateEmployee, DestroyEmployee, DestroyDocument } from 'actions/employee/index';
import { VehicleList } from 'actions/vehicle/index';
import { loadAuth } from 'actions/auth';

import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
var dayjs = require('dayjs')

export default function AddData() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  const DocumentFileRef = useRef(null);
  const AvatarFileRef = useRef(null);

  const Prefixes = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const BloodTypes = [
    {
      value: 'A+',
      label: 'A+',
    },
    {
      value: 'A-',
      label: 'A-',
    },
    {
      value: 'B+',
      label: 'B+',
    },
    {
      value: 'B-',
      label: 'B-',
    },
    {
      value: 'O+',
      label: 'O+',
    },
    {
      value: 'O-',
      label: 'O-',
    },
    {
      value: 'AB+',
      label: 'AB+',
    },
    {
      value: 'AB-',
      label: 'AB-',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const Personalities = [
    {
      value: 'นักออกแบบ INTJ-A / INTJ-T',
      label: 'นักออกแบบ INTJ-A / INTJ-T',
    },
    {
      value: 'นักตรรกะ INTP-A / INTP-T',
      label: 'นักตรรกะ INTP-A / INTP-T',
    },
    {
      value: 'ผู้บัญชาการ ENTJ-A / ENTJ-T',
      label: 'ผู้บัญชาการ ENTJ-A / ENTJ-T',
    },
    {
      value: 'นักโต้วาที ENTP-A / ENTP-T',
      label: 'นักโต้วาที ENTP-A / ENTP-T',
    },
    {
      value: 'ผู้แนะนำ INFJ-A / INFJ-T',
      label: 'ผู้แนะนำ INFJ-A / INFJ-T',
    },
    {
      value: 'ผู้ไกล่เกลี่ย INFP-A / INFP-T',
      label: 'ผู้ไกล่เกลี่ย INFP-A / INFP-T',
    },
    {
      value: 'ตัวเอก ENFJ-A / ENFJ-T',
      label: 'ตัวเอก ENFJ-A / ENFJ-T',
    },
    {
      value: 'นักรณรงค์ ENFP-A / ENFP-T',
      label: 'นักรณรงค์ ENFP-A / ENFP-T',
    },
    {
      value: 'นักคำนวณ ISTJ-A / ISTJ-T',
      label: 'นักคำนวณ ISTJ-A / ISTJ-T',
    },
    {
      value: 'ผู้ตั้งรับ ISFJ-A / ISFJ-T',
      label: 'ผู้ตั้งรับ ISFJ-A / ISFJ-T',
    },
    {
      value: 'ผู้บริหาร ESTJ-A / ESTJ-T',
      label: 'ผู้บริหาร ESTJ-A / ESTJ-T',
    },
    {
      value: 'ผู้ให้คำปรึกษา ESFJ-A / ESFJ-T',
      label: 'ผู้ให้คำปรึกษา ESFJ-A / ESFJ-T',
    },
    {
      value: 'ผู้เชี่ยวชาญด้านศิลปะ ISTP-A / ISTP-T',
      label: 'ผู้เชี่ยวชาญด้านศิลปะ ISTP-A / ISTP-T',
    },
    {
      value: 'นักผจญภัย ISFP-A / ISFP-T',
      label: 'นักผจญภัย ISFP-A / ISFP-T',
    },
    {
      value: 'ผู้ประกอบการ ESTP-A / ESTP-T',
      label: 'ผู้ประกอบการ ESTP-A / ESTP-T',
    },
    {
      value: 'ผู้มอบความบันเทิง ESFP-A / ESFP-T',
      label: 'ผู้มอบความบันเทิง ESFP-A / ESFP-T ',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const [validated, setValidated] = useState(false);
  const [Label, setLabel] = useState({
    Avatar: null,
    Documents: []
  })
  const [Data, setData] = useState({
    EmployeeCode: null,
    Prefix: null,
    Fullname: null,
    NickName: null,
    AKA: null,
    Height: null,
    Weight: null,
    SkinColor: null,
    BirthDate: null,
    BloodType: null,
    ProvinceId: null,
    AmphureId: null,
    DistrictId: null,
    // Phone: null,
    Phones: [],
    PostCode: null,
    Address: null,
    Motto: null,
    BranchId: null,
    DepartmentId: null,
    MusicalTalent: null,
    AthleticAbility: null,
    CitizenId: null,
    CongenitalDisease: null,
    FoodAllergy: null,
    DrugAllergy: null,
    Email: null,
    StartDate: null,
    EndDate: null,
    WorkExperience: null,
    Note: null,
    Personality: null,
    NeedInOrganization: null,
    SalesTarget: null,
    Period: null,
    Avatar: null,
    Document: null,
    Vehicles: [],
  });

  const disabledStartDate = (current) => {
    return current && current.valueOf() > Date.now();
  };

  const disabledDate = (current) => {
    return current && current.valueOf() < Data.StartDate;
  };
  
  const [FileName, setFileName] = useState({
    Document: '',
    Avatar: ''
  });

  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Districts, setDistricts] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [Vehicles, setVehicles] = useState([]);
  const [Resign, SetResign] = useState(false);
  const [errors, setErrors] = useState([]);

  const GetProvince = async () => {
    setData({...Data, AmphureId: null})
    setData({...Data, DistrictId: null})
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['พนักงาน'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/staff');
      }
    } catch (error) {
      history.push('/');
      console.error(error);
    }
  };

  const GetAmphure = async (data) => {
    setData({ ...Data, ProvinceId: data.id, AmphureId: null, DistrictId: null })
    const params = {
      ProvinceId: data.id
    }
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetDistrict = async (data) => {
    setData({ ...Data, AmphureId: data.id, DistrictId: null })

    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBranch = async (data) => {
    try {
      const response = await dispatch(Branch());
      setBranches(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetDepartment = (data) => {
    dispatch(Department()).then(response => {
      setDepartments(response.data.data);
    }).catch(e => {
      console.error(e);
    })
  }

  const GetVehicle = async (data) => {
    try {
      const response = await dispatch(VehicleList());
      setVehicles(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const displayPhone = (phone) => {
      if(phone && Array.isArray(phone)){
        if(phone && Array.isArray(phone) === true && phone.length > 1){
          return phone.join(', ')
        }else{
          return [phone]
        }
      }else{
        return [phone]
      }
  }

  const GetEmployee = async () => {
    try {
      const response = await dispatch(FindEmployee({id}));
      const data = response.data.data
      let vehicles = []
      data.vehicles.forEach(vehicle => {
        vehicles.push(vehicle.pivot.vehicle_id)
      });

      let documents = []
      data.personal_documents.forEach(document => {
        documents.push(document)
      });

      GetProvince();
      GetAmphure(data.district && data.district.amphure && data.district.amphure.province ? data.district.amphure.province : '');
      GetDistrict(data.district && data.district.amphure ? data.district.amphure : '');
      
      setLabel({
        Avatar: data.avatar,
        Documents: documents
      })

      setData({ 
        EmployeeCode: data.employee_code,
        Prefix: data.prefix,
        Fullname: data.fullname,
        NickName: data.nickname,
        AKA: data.aka,
        Height: data.height,
        Weight: data.weight,
        SkinColor: data.skin_color,
        BirthDate: moment(data.birthdate, dateFormat),
        BloodType: data.blood_type,
        ProvinceId: data.district && data.district.amphure && data.district.amphure.province && data.district.amphure.province.id ? data.district.amphure.province.id : '',
        AmphureId: data.district && data.district.amphure && data.district.amphure.id,
        DistrictId: data.district_id,
        Phones: data.phone ? displayPhone(data.phone) : [],
        PostCode: data.postcode,
        Address: data.address,
        Motto: data.motto,
        BranchId: data.branch_id,
        DepartmentId: data.department_id,
        MusicalTalent: data.musical_talent,
        AthleticAbility: data.athletic_ability,
        CitizenId: data.citizen_id,
        CongenitalDisease: data.congenital_disease,
        FoodAllergy: data.food_allergy,
        DrugAllergy: data.drug_allergy,
        Email: data.email,
        StartDate: data.start_date ? moment(data.start_date, dateFormat) : null,
        EndDate: data.end_date ? moment(data.end_date, dateFormat) : null,
        WorkExperience: data.work_experience,
        Note: data.note,
        Personality: data.personality,
        NeedInOrganization: data.need_in_organization,
        SalesTarget: data.sales_target,
        Period: (data.period_start && data.period_end) ? [
          moment(data.period_start, dateFormat),
          moment(data.period_end, dateFormat)
        ] : null,
        Avatar: null,
        Document: null,
        Vehicles: vehicles,
      })
      if(data.end_date) SetResign(true)

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetEmployee();
    // GetProvince();
    GetBranch();
    GetDepartment();
    GetVehicle();
    getAuth()
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    
    if(form.checkValidity()){
      let formData = new FormData();
      formData.append('type', 'พนักงาน')
      if(Data.Prefix && Data.Prefix === 'นาย') formData.append('gender', 'ชาย')
      if(Data.Prefix && Data.Prefix === 'นาง') formData.append('gender', 'หญิง')
      if(Data.Prefix && Data.Prefix === 'นางสาว') formData.append('gender', 'หญิง')
      if(Data.EmployeeCode) formData.append('employee_code', Data.EmployeeCode);
      if(Data.Prefix) formData.append('prefix', Data.Prefix);
      if(Data.Fullname) formData.append('fullname', Data.Fullname);
      if(Data.NickName) formData.append('nickname', Data.NickName);
      if(Data.AKA) formData.append('aka', Data.AKA);
      if(Data.Height) formData.append('height', Data.Height);
      if(Data.Weight) formData.append('weight', Data.Weight);
      if(Data.SkinColor) formData.append('skin_color', Data.SkinColor);
      if(Data.BirthDate) formData.append('birthdate', dayjs(Data.BirthDate).format('YYYY-MM-DD'));
      if(Data.BloodType) formData.append('blood_type', Data.BloodType);
      if(Data.ProvinceId) formData.append('province_id', Data.ProvinceId);
      if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId);
      if(Data.DistrictId) formData.append('district_id', Data.DistrictId);
      if(Data.PostCode) formData.append('postcode', Data.PostCode);
      if(Data.Address) formData.append('address', Data.Address);
      if(Data.Motto) formData.append('motto', Data.Motto);
      if(Data.BranchId) formData.append('branch_id', Data.BranchId);
      if(Data.DepartmentId) formData.append('department_id', Data.DepartmentId);
      if(Data.MusicalTalent) formData.append('musical_talent', Data.MusicalTalent);
      if(Data.AthleticAbility) formData.append('athletic_ability', Data.AthleticAbility);
      if(Data.CitizenId) formData.append('citizen_id', Data.CitizenId);
      if(Data.CongenitalDisease) formData.append('congenital_disease', Data.CongenitalDisease);
      if(Data.FoodAllergy) formData.append('food_allergy', Data.FoodAllergy);
      if(Data.DrugAllergy) formData.append('drug_allergy', Data.DrugAllergy);
      if(Data.Email) formData.append('email', Data.Email);
      // if(Data.Phone) formData.append('phone', Data.Phone);
      if(Data.Phones) formData.append('phone', JSON.stringify(Data.Phones));
      if(Data.StartDate) formData.append('start_date', dayjs(Data.StartDate).format('YYYY-MM-DD'));
      if(Data.EndDate && Resign) formData.append('end_date', dayjs(Data.EndDate).format('YYYY-MM-DD'));
      if(Data.WorkExperience) formData.append('work_experience', Data.WorkExperience);
      if(Data.Note) formData.append('note', Data.Note);
      if(Data.Personality) formData.append('personality', Data.Personality);
      if(Data.NeedInOrganization) formData.append('need_in_organization', Data.NeedInOrganization);
      if(Data.SalesTarget) formData.append('sales_target', Data.SalesTarget);
      if(Data.Period && Data.Period[0]) formData.append('period_start', dayjs(Data.Period[0]).format('YYYY-MM-DD'));
      if(Data.Period && Data.Period[1]) formData.append('period_end', dayjs(Data.Period[1]).format('YYYY-MM-DD'));
      if(Data.Vehicles && Data.Vehicles.length > 0){
        let i = 0 ;
        while (i < Data.Vehicles.length){
          formData.append('vehicles[]', Data.Vehicles[i]);
          i++;
        }
      }
  
      if(Data.Avatar) formData.append('avatar', Data.Avatar[0]);
      if(Data.Document) {
        let i = 0 ;
        while (i < Data.Document.length){
          formData.append(`document[${i}]`, Data.Document[i]);
          i++;
        }
      }
      formData.append('_method', 'put');
      
      dispatch(UpdateEmployee({id, formData})).then(() =>{
          history.push('/staff');
        }).catch(e => {
          if (e.response.data && e.response.data.messages) {
            setErrors([...e.response.data.messages])
          }
        });
    }
  };

  const ToggleDocumentFile = () => {
    if(DocumentFileRef.current) DocumentFileRef.current.click();
  }

  const SetDocumentFile = (e) => {
    setData({ ...Data, Document : e.target.files})
    const name = []
    const documents = Label.Documents
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      let obj = {}
      obj.uid = (new Date()).getTime();
      obj.image = URL.createObjectURL(item)
      obj.name = item.name
      documents.push(obj)
      name.push(item.name)
    }

    setFileName({ ...FileName, Document : name})
    setLabel({...Label,
      Documents: documents
    })
  }

  const ToggleAvatarFile = () => {
    if(AvatarFileRef.current) AvatarFileRef.current.click();
  }

  const SetAvatarFile = (e) => {
    setData({ ...Data, Avatar : e.target.files})
    const name = []
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      name.push(item.name)
    }
    setFileName({ ...FileName, Avatar: name})
    setLabel({...Label,
      Avatar: URL.createObjectURL(e.target.files[0])
    })
  }

  const SetVehicle = (checked, id) => {
    if (checked) {
      setData({ ...Data, Vehicles: [...Data.Vehicles,  id] })
    }else{
      setData({ ...Data, Vehicles: Data.Vehicles.filter((vehicle) => { 
        return vehicle !== id 
      })});
    }
  }

  const downloadFile = (blob, fileName, type) => {
    const linkSource = `data:application/${type ? type : 'png'};base64,${blob}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName.includes('.') ? fileName : fileName+'.png';
    downloadLink.click();
  };  

  function addCommas(x) {
    return parseInt(x.replaceAll(',', '')).toLocaleString()
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState({
    key: null
  });
  const [deleteValidated, setDeleteValidated] = useState(false);

  function deleteConfirmation() {
    // setDeleting(customer)
    setDeleting('');
    setConfirmation(true);
  }

  const DeleteEmployee = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setDeleteValidated(true);
    
    const params = {
      key: deleting.key
    }
    dispatch(DestroyEmployee({id, params})).then( response => {
      setConfirmation(false)
      history.push('/staff')
    }).catch((e) => {
      console.log(e.response);
      alert(e.response.data.message);
      setDeleting({ ...deleting, key: null});
    })
  }

  const DeleteDocument = (document) => {
    if (document.id) {
      dispatch(DestroyDocument(document.id)).then( () => {
        GetEmployee()
      }).catch(e => {
        console.log(e)
      })
    }else{
      setLabel({...Label,
        Documents: Label.Documents.filter(item => item.uid !== document.uid )
      })
      setFileName({ ...FileName, Document : FileName.Document.filter(item => item !== document.name )})
    }
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  return (
    <>
      <ToastContainer position='bottom-end'>
        {
          errors?.map((error, key) => {
            return (
              <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                <Toast.Header>
                  <strong className="me-auto">แจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body>{ error }</Toast.Body>
              </Toast>
            )
          })
        }
      </ToastContainer>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {/* <Row>
          <Col
            color={12}
            className="text-right"
          >
            <Button 
              type="button" 
              className="button-save button-respon"
              onClick={() => deleteConfirmation()}
            >
              <img src={Delete} /> ลบ
            </Button>
          </Col>
        </Row> */}
        <Row className="set-row-add-staff Stocks">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center set-input"
              >
                <Form.Label className="label-input">รหัสพนักงาน</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.EmployeeCode}
                  required
                  onChange={(e) => setData({ ...Data, EmployeeCode: e.target.value})}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">คำนำหน้าชื่อ</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Prefixes}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  required
                  value={Data.Prefix ? Prefixes.find((data) => {return data.value === Data.Prefix}) : null}
                  onChange={(data) => setData({ ...Data, Prefix: data.value})}
                  className={`select-list ` + (!Data.Prefix && validated ? 'validate-fail' : '') }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ชื่อ-นามสกุล</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.Fullname}
                  required
                  onChange={(e) => setData({ ...Data, Fullname: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col
            color={12}
            className="text-right"
          >
            <Button 
              type="button" 
              className="button-save button-respon"
              onClick={() => deleteConfirmation()}
            >
              <img src={Delete} /> ลบ
            </Button>
          </Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">ชื่อเล่น</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.NickName}
                  // required
                  onChange={(e) => setData({ ...Data, NickName: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ชื่อAKA</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.AKA}
                  // required
                  onChange={(e) => setData({ ...Data, AKA: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">วัน/เดือน/ปีเกิด</Form.Label>
              </Col>
              <Col>
                <DatePicker
                  value={Data.BirthDate}
                  onChange={(date) => setData({ ...Data, BirthDate: date})}
                  suffixIcon={<img src={Calendar} />}
                  style={{ borderRadius: '0' }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff align-items-center">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">กรุ๊ปเลือด</Form.Label>
              </Col>
              <Col className="mt-2 mb-2">
                <Select
                  options={BloodTypes}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  value={Data.BloodType ? BloodTypes.find((data) => {return data.value === Data.BloodType}) : null}
                  onChange={(data) => setData({ ...Data, BloodType: data.value})}
                  className={`select-list `}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">น้ำหนัก</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={Data.Weight}
                  // required
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, Weight: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ส่วนสูง</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={Data.Height}
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, Height: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">สีผิว</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.SkinColor}
                  // required
                  onChange={(e) => setData({ ...Data, SkinColor: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">โรคประจำตัว</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.CongenitalDisease}
                  // required
                  onChange={(e) => setData({ ...Data, CongenitalDisease: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">การแพ้อาหาร</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.FoodAllergy}
                  // required
                  onChange={(e) => setData({ ...Data, FoodAllergy: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">การแพ้ยา</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.DrugAllergy}
                  // required
                  onChange={(e) => setData({ ...Data, DrugAllergy: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff align-items-center">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">
                  16 Personalities
                </Form.Label>
              </Col>
              <Col lg={9} xl={9} className="mt-2 mb-2">
                <Select
                  options={Personalities}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  value={Data.Personality ? Personalities.find((data) => {return data.value === Data.Personality}) : null}
                  onChange={(data) => setData({ ...Data, Personality: data.value })}
                  className={`select-list ` }
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านดนตรี
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  value={Data.MusicalTalent}
                  onChange={(e) => setData({ ...Data, MusicalTalent: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านกีฬา
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  value={Data.AthleticAbility}
                  onChange={(e) => setData({ ...Data, AthleticAbility: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านการขับขี่
                </Form.Label>
              </Col>
              <Col>
                <Row>
                  <Col lg={12}>
                    <Row>
                     
                    {
                      Vehicles.filter(data => data.type.includes('other')).map(vehicle => {
                        return (
                          <Col lg={6} xl={4}>
                            <Form.Check
                              type="checkbox"
                              aria-label="radio 1"
                              label={vehicle.title}
                              checked={Data.Vehicles && Data.Vehicles.length > 0 ? Data.Vehicles.includes(vehicle.id) : null}
                              onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                            />
                          </Col>
                        )
                      })
                    }
                    </Row>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={12} xl={12} className="d-flex align-items-center">
                        <Form.Label className="label-input">
                          รถยนต์ส่วนบุคคล{' '}
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      {
                        Vehicles.filter(data => data.type.includes('car')).map(vehicle => {
                          return (
                            <Col lg={6} xl={4}>
                              <Form.Check
                                type="checkbox"
                                aria-label="radio 1"
                                label={vehicle.title}
                                checked={Data.Vehicles && Data.Vehicles.length > 0 ? Data.Vehicles.includes(vehicle.id) : null}
                                onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                              />
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={12} xl={12} className="d-flex align-items-center">
                        <Form.Label className="label-input">
                          จักรยานยนต์
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      {
                        Vehicles.filter(data => data.type.includes('motorcycle')).map(vehicle => {
                          return (
                            <Col lg={6} xl={4}>
                              <Form.Check
                                type="checkbox"
                                aria-label="radio 1"
                                label={vehicle.title}
                                checked={Data.Vehicles && Data.Vehicles.length > 0 ? Data.Vehicles.includes(vehicle.id) : null}
                                onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                              />
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-5">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">สาขา</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Select
                  options={Branches}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.BranchId}
                  text="name"
                  value={Data.BranchId ? Branches.find((data) => {return data.id === Data.BranchId}) : null}
                  onChange={(data) => setData({ ...Data, BranchId: data.id })}
                  className={`select-list w-100 ` + (!Data.BranchId && validated ? 'validate-fail' : '') }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">แผนก</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Departments}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.DepartmentId}
                  text="name"
                  value={Data.DepartmentId ? Departments.find((data) => {return data.id === Data.DepartmentId}) : null}
                  onChange={(data) => setData({ ...Data, DepartmentId: data.id })}
                  className={`select-list w-100 ` + (!Data.DepartmentId && validated ? 'validate-fail' : '') }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">เริ่มงาน</Form.Label>
              </Col>
              <Col>
                <DatePicker
                  value={Data.StartDate}
                  onChange={(date) =>  setData({ ...Data, StartDate: date })}
                  placeholder=""
                  suffixIcon={<img src={Calendar} />}
                  disabledDate={disabledStartDate}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center" style={{ height: '60px' }}>
                <Form.Check
                  type="checkbox"
                  aria-label="radio 1"
                  label="สิ้นสุดงาน"
                  checked={Resign}
                  onChange={(e) => SetResign(e.target.checked)}
                />
              </Col>
              <Col>
              {
                  Resign && Data.StartDate &&
                  <DatePicker
                    value={Data.EndDate}
                    onChange={(date) =>  setData({ ...Data, EndDate: date })}
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    disabledDate={disabledDate}
                    className={(!Data.EndDate && validated ? 'validate-fail' : '') }
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={12}>
            <Row className="mb-3 mt-3">
              <Col
                xs={12}
                sm={12}
                lg={1}
                className="d-flex align-items-center set-input mr-4"
              >
                <Form.Label className="label-input">ที่อยู่</Form.Label>
              </Col>
              <Col lg={10} className="d-flex align-items-center ml-1">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.Address}
                  // required
                  onChange={(e) => setData({ ...Data, Address: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col xs="auto"></Col>
          <Col xs="auto"></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">จังหวัด</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Select
                  options={Provinces}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  text="name"
                  value={Data.ProvinceId ? Provinces.find((data) => {return data.id === Data.ProvinceId}) : null}
                  onChange={(data) => { GetAmphure(data) }}
                  className={`select-list w-100 `}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">อำเภอ</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Amphures}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  text="name"
                  value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                  onChange={(data) => { setData({ ...Data, AmphureId: data.id });GetDistrict(data) }}
                  isDisabled={!Data.ProvinceId}
                  className={`select-list w-100 `}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ตำบล</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Districts}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder=""
                  isClearable={false}
                  isSearchable={false}
                  text="name"
                  value={Data.DistrictId ? Districts.find((data) => {return data.id === Data.DistrictId}) : null}
                  onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : ''  }) }}
                  isDisabled={!Data.AmphureId}
                  className={`select-list w-100 `}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">รหัสไปรษณีย์</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.PostCode}
                  // required
                  onChange={(e) => setData({ ...Data, PostCode: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff mt-3">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">เบอร์โทรศัพท์</Form.Label>
              </Col>
              <Col>
                {/* <Form.Control
                  type="text"
                  // required
                  placeholder=""
                  value={Data.Phone}
                  onChange={(e) => setData({ ...Data, Phone: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control> */}
                <div className={(!Data.Phones | Data.Phones.length === 0 && validated ? 'validate-fail-tags' : '') }>
                  <ReactTagInput
                    placeholder="เบอร์โทรศัพท์"
                    editable={true}
                    readOnly={false}
                    removeOnBackspace={true}
                    maxTags={2}
                    tags={Data.Phones}
                    required
                    onChange={(newTags) => setData({ ...Data, Phones: newTags })}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">เลขบัตรประชาชน</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={Data.CitizenId}
                  // required
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, CitizenId: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col md={12} lg={6} xl={5}>
            <Row className="mb-3">
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">E-mail</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="email"
                  placeholder=""
                  value={Data.Email}
                  // required
                  onChange={(e) => setData({ ...Data, Email: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-top set-input">
                <Form.Label className="label-input">ประสบการณ์ทำงาน</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.WorkExperience}
                  onChange={(e) => setData({ ...Data, WorkExperience: e.target.value })}
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={6}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                xl={4}
                className="d-flex align-items-top set-input"
              >
                <Form.Label className="label-input">
                  ความต้องการในองค์กร
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.NeedInOrganization}
                  onChange={(e) => setData({ ...Data, NeedInOrganization: e.target.value })}
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-top  set-input"
              >
                <Form.Label className="label-input">คติประจำใจ</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.Motto}
                  onChange={(e) => setData({ ...Data, Motto: e.target.value })}
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={6}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                xl={4}
                className="d-flex align-items-top set-input"
              >
                <Form.Label className="label-input">หมายเหตุ</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.Note}
                  onChange={(e) => setData({ ...Data, Note: e.target.value })}
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="set-row-add-staff mt-2 mb-2">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={1}
            xl={1}
            className="d-flex align-items-center set-input mr-4"
          >
            <Form.Label className="label-input">อัพโหลดไฟล์</Form.Label>
          </Col>

          <Col lg={5}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={FileName.Document ? FileName.Document : ''}
                  // required
                  className="input-search-add-staff"
                  readOnly
                ></Form.Control>

                <Form.Control
                  type="file"
                  className="d-none"
                  id="document-files"
                  ref={DocumentFileRef}
                  label={Data.Document}
                  onChange={(e) => SetDocumentFile(e) }
                  multiple
                />     
              </Col>

              <Col
                md={12}
                lg="auto"
                className="d-flex justify-content-center align-items-center"
              >
                <Button 
                  type="button" 
                  className="button-save button-respon"
                  onClick={ToggleDocumentFile}
                >
                  เลือกไฟล์
                </Button>
              </Col>
            </Row>
          </Col>

          <Col lg={5}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={FileName.Avatar}
                  required
                  className="input-search-add-staff"
                  readOnly
                ></Form.Control>
                <Form.Control
                  type="file"
                  className="d-none"
                  id="avatar-file"
                  ref={AvatarFileRef}
                  label={Data.Avatar}
                  onChange={(e) => SetAvatarFile(e) }
                />
              </Col>
              <Col
                md={12}
                lg="auto"
                className="d-flex justify-content-center align-items-center"
              >
                <Button 
                  type="button" 
                  className="button-save button-respon"
                  onClick={ToggleAvatarFile}
                >
                  <img src={Upload} /> &nbsp; เลือกรูปถ่าย
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={1}
            xl={1}
            className="d-flex align-items-center set-input mr-4"
          >
           
          </Col>

          <Col lg={5} className='mt-4 mb-4'>
            {
              Label.Documents.map((document, key) => {
                return (
                  <>
                    <Row className='align-items-center my-2'>
                      <Col  className='text-left'><p className='p-0 m-0'>{`${document.original_name ? document.original_name : 'ไฟล์ที่' + key + 1  }`}</p></Col>
                      <Col xs={6} sm={6}  md={6} lg="auto" >
                        <Button type="button" className="button-save button-respon" onClick={() => downloadFile(document.image, `${document.original_name ? document.original_name : 'ไฟล์ที่' + key + 1  }`, document.type)}>ดาวน์โหลด</Button>
                        <Button type="button" className="x-button" onClick={ () => DeleteDocument(document) }><span>X</span></Button>
                      </Col>
                    </Row>
                  </>
                );
              })
            }
          </Col>
          <Col lg={5}>
            <Row>
              <Col className='d-flex justify-content-center'>
               <img src={Label.Avatar} className='m-auto' height='200px' />
              </Col>
              
            </Row>
          </Col>
        </Row>              
        <Row className="g-0 set-row-add-staff mt-5 mb-5 d-flex justify-content-center ">
          <Col xs={10} sm={10} md={10} lg={8} className="border-colum-time">
            <Row>
              <Col ms={12} sm={12} lg={6}>
                <Row>
                  <Col xs={4} sm={3} md={4} lg={3} className="text-input">
                    <p>เป้าขาย</p>
                  </Col>
                  <Col xs={8} sm={9} md={8} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className="input-search-add-staff"
                      value={commaCase(Data.SalesTarget)}
                      onFocus={
                        (e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={(e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })}
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                      // required
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col ms={12} sm={12} lg={6}>
                <Row>
                  <Col xs={4} sm={3} md={3} lg={3} className="text-input">
                    <p>ช่วงเวลา</p>
                  </Col>
                  <Col xs={8} sm={9} md={9} lg={9}>
                    <DatePicker.RangePicker
                      value={Data.Period}
                      onChange={(date) => setData({ ...Data, Period: date })}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Button type="submit" className="button-save button-respon">
            <img src={Save} /> &nbsp; บันทึก
          </Button>
        </Row>
      </Form>

      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบครอบครัว { `${Data?.Fullname}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={deleteValidated} onSubmit={DeleteEmployee}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: 'auto' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบครอบครัว {  `${Data?.Fullname}` }</h3>
              </Col>
              <Col xs={10} lg={10} className="mt-2 ">
                <Form.Control
                  type="password"
                  placeholder="รหัสลับ"
                  value={deleting.key ? deleting.key : ''}
                  required
                  className='m-0 mx-auto'
                  onChange={(e) => setDeleting({ ...deleting, key: e.target.value})}
                ></Form.Control>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center mt-4">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
