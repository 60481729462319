import axios, { post } from 'axios';

export const Customer = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/customers`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const FindCustomer = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateCustomer = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/customers`,
      data,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.resolve(e.response)
    });

  } catch (error) {

  }
};

  export const CheckCustomer = (data) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
  
      return await post(
        `${process.env.REACT_APP_BASE_URL}/api/check-customers`,
        data,
        config
      ).then(response => {
        return Promise.resolve(response);
      }).catch( e => {
        return Promise.resolve(e.response)
      });
  
    } catch (error) {
  
    }
};

export const UpdateCustomer = ({id, formData}) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.resolve(e.response)
    });

  } catch (error) {

  }
};

export const DestroyCustomer = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const OverView = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/customers/over-view`, { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const PackageHistoryCustomer = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/customers/package-history`,{ params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const HistoryCustomer = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/customers/history`,{ params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};