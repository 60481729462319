import axios, { put } from 'axios';

export const NotificationList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/notifications`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindNotification = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateNotification = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`, data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const approvedCoupon = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}/coupon`, data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const notificationJob = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}/job`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};
