import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form
} from 'react-bootstrap';
import Footer from 'Components/Footer';

import { Link, useHistory } from 'react-router-dom';
import TabIconManagement from '../Components/layout/TabIconManagement';
// import MenuManagement from '../Components/layout/MenuManagement';
import Back from 'assets/icon/back.png';
import Branch_manage from 'assets/icon/branch-manage.png';
import Edit from 'assets/icon/key-fix.png';
import View from 'assets/icon/view-member.png';
import SearchIcon from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import Increase from 'assets/icon/add-staff.png';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import MyPagination from 'Components/MyPagination'
//API
import { Branch } from 'actions/branch/index';
import { loadAuth } from 'actions/auth';
export default function Index() {
  const history = useHistory();

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' }
  ]);

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [ClearSearch, setClearSearch] = useState(false);
  const [Branches, setBranches] = useState([]);
  const [Search, setSearch] = useState({
    Keyword: '',
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const GetBranch = async () => {
    setLoading(true)
    const params = {
      keyword: Search.Keyword,
      limit: Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1
    }
    try {
      const response = await dispatch(Branch(params));
      if(response.data.data && response.data.data.data){
        setBranches(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setBranches(response.data.data);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการสาขา'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/');
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!ClearSearch) GetBranch();
    setClearSearch(false)
    getAuth()
  }, [Search.PerPage, Search.CurrentPage, ClearSearch]);
  
  function ResetSearch() {
    setSearch({ ...Search, 
      Keyword: '',
      PerPage: 10
    })

    setClearSearch(true)
  }

  function close() {
    history.push(`/`);
  }

  return (
    <>
      
      <Container fluid className="package-container Service Stock Management bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Branch_manage} />
                  <b>การจัดการสาขา</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Branch_manage} />
                  <b>การจัดการสาขา</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabIconManagement />
                </Row>
                <Row className="g-0 justify-content-center">
                  <Row className="g-0">
                    <Col lg={5} className="d-flex justify-content-center mt-2 mb-2">
                      <Form.Control
                        type="text"
                        placeholder="ค้นหาด้วยชื่อสาขา"
                        value={Search.Keyword ? Search.Keyword : ''}
                        onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                        className="input"
                      ></Form.Control>
                    </Col>

                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <Button className="button-menu" onClick={() => GetBranch()}>
                        <img src={SearchIcon} /> &nbsp; ค้นหา
                      </Button>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <Button 
                        className="button-menu" 
                        onClick={() => ResetSearch()}
                      >
                        <img src={Return} /> &nbsp; คืนค่า
                      </Button>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={4}
                      lg={2}
                      xl={2}
                      className="d-flex justify-content-center mt-2 mb-2 d-md-none"
                    >
                      <Button className="button-menu">
                        <img src={Increase} /> &nbsp; เพิ่มสาขา
                      </Button>
                    </Col>
                  </Row>
                </Row>
                <Row className="g-0 justify-content-center m-0">
                  <Col
                    className=" mt-3 p-0"
                    style={{ overflow: 'scroll', height: '500px' }}
                  >
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>ชื่อสาขา</th>
                          <th>เวลาเปิด-ปิด</th>
                          <th>ติดต่อ</th>
                          <th>การใช้งาน</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        { 
                          Branches?.map((branch, key) => {
                            return (
                              <tr>
                                <td>{branch.name}</td>
                                <td>{`${branch.open} - ${branch.closed} น.`}</td>
                                
                                <td style={{ minWidth: '20%' }}>
                                  { 
                                    branch.contacts?.map(contact => {
                                      return(
                                        <>
                                          <p>{contact.name}</p>
                                          <p className='m-0'>{JSON.parse(contact?.phone).join(', ')}</p>
                                        </>
                                      )
                                    })
                                  }
                                </td>
                                <td >
                                  <span className={(branch.active ? 'text-green2':'text-red')}>{branch.active ? <b>เปิด</b> : <b>ปิด</b>}</span>
                                </td>
                                <td></td>
                                <td className="set-button-table-management">
                                  <Link to={`/management/${branch.id}/edit`}>
                                    <Button type="button" className="button-package m-2">
                                      <img src={Edit} />
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                    <Col lg={5} className=" set-page">
                      <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                          <p style={{ margin: 0 }}>แสดง</p>
                        </Col>
                        <Col lg={7}>
                          <Select
                            className="text-left select-style"
                            aria-label="Default select example"
                            options={Pages}
                            onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                            defaultValue={{ value: 10, label: '10' }}
                          />
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <p style={{ margin: 0 }}>รายการ</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="d-flex justify-content-end set-paginate">
                      <div className="box-paginate">
                      {
                        !isLoading ? 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                      </div>
                    </Col>
                  </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
