import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
  Tab,
  Tabs
} from 'react-bootstrap';
import BillImage from 'assets/icon/job.png'
import Footer from '../Components/Footer';

import { useHistory,useParams ,useLocation} from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/flat-style-circle-write.png';
import Arrow from '../assets/icon/arrow.png';
import Document from '../assets/icon/document.png';
import Search from '../assets/icon/eyes-search.png';
import Save from '../assets/icon/flat-style-circle-save.png';
import Return from '../assets/icon/flat-style-circle-turn-on.png';

import OrderComponent from '../Components/Job/Order';
import StatusLog from '../Components/Job/StatusLog';
import JobAssign from '../Components/Job/JobAssign';
import Appointment from '../Components/Job/Appointment';

import { useDispatch } from 'react-redux';

//api
import { FindJob,JobLogs } from 'actions/jobs'
// import { useLocation } from 'react-router';
import { loadAuth } from 'actions/auth';


export default function Order() {
 
  const history = useHistory();
  const dispatch = useDispatch();

  const [key, setKey] = useState('Order');
  function setStorageKey(item){
    setKey(setKey(item))

    // localStorage.setItem('key',item)
  }
  let { id  } = useParams();

  const loadKey = localStorage.getItem('key') ? localStorage.getItem('key') : 'Order';
  const [Job, setJob] = useState(null);
  const [JobLog, setJobLog] = useState([]);
  // const [Customers,setCustomers] = useState([]);
  // console.log('id',id);

  function close() {
    let path = `/job`;
    history.push(path);
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['job'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };


  
  const [sPrice, setsPrice] = useState(null);
  const [sDiscount, setsDiscount] = useState(null);
  const [sCost, setsCost] = useState(null);
  const [sCommission, setsCommission] = useState(null);
  const [sTotal, setsTotal] = useState(null)

  function modifileShowroom(name, val){
    if(name == "price"){setsPrice(val)}
    if(name == "discount"){setsDiscount(val)}
    if(name == "cost"){setsCost(val)}

    upComiss()
    // console.log(sPrice)
  }

  function upComiss() {
    let result = Number(sPrice) - (Number(prePrice) + Number(sDiscount) + Number(sCost));
    setsCommission(result)

    let total = Number(sPrice) - Number(sDiscount);
    setsTotal(total)

  }

  const [prePrice, setPrePrice] = useState(0);
  const getPrePrice = () => {
    setPrePrice(0);
    if(Job.job_details.length > 0){
      let total = 0;
      Job.job_details.map(item => {
        let result = item.quantity * item.price;
        total = total + result;
      });
      setPrePrice(total);
    }else{
      setPrePrice(0);
    }
  };

  async function getJob(){
    
    try {
      const response = await dispatch(FindJob({id}));
      // console.log('response.data.data',response.data.data);
      if(response){
        const data = response.data.data
        setJob(data);

        // getPrePrice();
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  async function getJobLog(){
    
    try {
      const params = {
        job_id: id,
      };
      const response = await dispatch(JobLogs(params));
      
      if(response){
        const data = response.data.data
        setJobLog(data);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }


  // async function getCustomers(){
  //   const params = {
  //     type: ['บุคคลธรรมดา','นิติบุคคล'],
  //   }
  //   try {
  //     const response = await dispatch(Customer(params));
      
  //     if(response){
  //       const data = response.data.data
  //       setCustomers(data);
  //     }else{
  //       console.log('failed')
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     console.log('finally')
  //   }
  // }

  useEffect(() => {
    getJob()
    getJobLog()
    setKey(loadKey);
    getAuth();

    // getPrePrice()
    // upComiss()

    // getCustomers()
  },[]);

  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; ภายในร้าน &gt; Order</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; ภายในร้าน &gt; Order</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0 justify-content-center ">
                  <Col>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setStorageKey(k)}
                      className="mb-3"
                      
                    >
                      <Tab eventKey="Order" title="Order">
                        { 
                          Job && <OrderComponent JobState={Job} PayerState={Job.customer} CarOwnerState={Job.car_owner} 
                          // CustomersState={Customers} 
                          JobId={id}/>
                        }
                      </Tab>
                      <Tab eventKey="StatusLog" title="StatusLog">
                        <StatusLog JobLogState={JobLog}/>
                      </Tab>
                      <Tab eventKey="JobAssign" title="JobAssign">
                        { Job && <JobAssign JobState={Job}/> }
                      </Tab>

                      {/* <Tab eventKey="appointment" title="นัดหมาย">
                        { Job && <Appointment JobState={Job}/> }
                      </Tab> */}
                    </Tabs>
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center mt-3"></Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
