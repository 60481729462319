import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Search from 'assets/icon/search.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/out.png';

import { Province, Amphure } from 'actions/data/index';
import { SegmentList } from 'actions/settings/segment';
import { Customer, FindCustomer, UpdateCustomer } from 'actions/customer/index';

import Delete from 'assets/icon/restrict.png';
import add_contract from 'assets/icon/add-contract.png';

export default function CustomerInfo({ props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const DisplayNumber = async () => {
    if(GNumber.length === 0) GetNumber();
    setShow(true)
  }

  const close = () => {
    setShow(false);
  };

  const exit = () => {
    history.push('/member');
  };

  const Pad = async (num = 1, size = 6) => {
    num = num.toString();
        num = "1" + num;
    while (num.length < size) num = "0" + num;
    return num
  }
  
  const [GNumber, setGNumber] = useState([]);

  const GetNumber = async () => {
    let numbers = []
    for (let index = 0; index < 1200000; index++) {
      numbers.push(await Pad(index+1, 6))
    }
    setGNumber(numbers)

    let limit = 9999999
    let count = 0
    const result =  numbers.filter(item => {
      if(count <= limit){
        count++
        return true
      }else{
        return false
      }
    });
    
    setSegmentNumber(result)
  }

  const InsertContact = () => {
    const contacts = Data.Contacts
    const model = {
      key: Data.Contacts.length,
      fullname: null,
      phone: []
    }
    contacts.push(model)
    const result = contacts

    setData({ ...Data, Contacts: result })
  }

  const RemoveContact = (key) => {
    const contacts = Data.Contacts
    const index = contacts.findIndex((item) => item.key === key)
    contacts.splice(index, 1)
    const result = contacts
    setData({ ...Data, Contacts: result })
  }

  const [SegmentNumber, setSegmentNumber] = useState([]);
  const [SearchSegment, setSearchSegment] = useState({
    Keyword: ''
  });

  const NumberList = async (e, action) => {
    e.preventDefault();
    if(action === 'reset') setSearchSegment({Keyword: '' })
    
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    const keyword = action !== 'reset' ? SearchSegment.Keyword : ''
    let limit = 9999999
    let count = 0
    const result =  GNumber.filter(item => {
      if(item.includes(keyword.toString()) && count <= limit){
        count++
        return true
      }else{
        return false
      }
    });
    
    setSegmentNumber(result)
  }

  const Prefixes = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const Channels = [
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Offline', label: 'Offline' },
    { value: 'Connection', label: 'Connection' },
    { value: 'ไม่ระบุ', label: 'ไม่ระบุ' }
  ];

  const [validated, setValidated] = useState(false);
  const [Segments, setSegments] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Customers, setCustomers] = useState([]);

  const [Data, setData] = useState({
    SegmentId: null,
    SegmentNumber: null,
    Prefix: null,
    FirstName: null,
    LastName: null,
    NickName: null,
    AKA: null,
    CitizenId: null,
    Phones: [],
    ProvinceId: null,
    AmphureId: null,
    PostCode: null,
    Channel: null,
    CustomerId: null,
    Note: null,
    SpecialNeed: null,

    Organization: null,
    InvoiceAddress: null,
    Contacts:[
      {
        key: 1,
        fullname: null,
        phone: []
      }
    ]
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      let formData = new FormData();
      formData.append('type', Data.Type)
      if(Data.SegmentId) formData.append('segment_id', Data.SegmentId)
      if(Data.SegmentNumber) formData.append('segment_number', Data.SegmentNumber)
      if(Data.Prefix) formData.append('prefix', Data.Prefix)
      if(Data.FirstName) formData.append('first_name', Data.FirstName)
      if(Data.LastName) formData.append('last_name', Data.LastName)
      if(Data.NickName) formData.append('nickname', Data.NickName)
      if(Data.AKA) formData.append('aka', Data.AKA)
      if(Data.CitizenId) formData.append('citizen_id', Data.CitizenId)
      if(Data.CustomerId) formData.append('recommender', Data.CustomerId)
      if(Data.Phone) formData.append('phone', Data.Phone)
      if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId)
      if(Data.PostCode) formData.append('postcode', Data.PostCode)
      if(Data.Phones) formData.append('phone', JSON.stringify(Data.Phones))
      if(Data.Channel) formData.append('channel', Data.Channel)
      if(Data.Note) formData.append('note', Data.Note)
      if(Data.SpecialNeed) formData.append('special_need', Data.SpecialNeed)

      if(Data.Organization) formData.append('organization', Data.Organization)
      if(Data.InvoiceAddress) formData.append('tax_invoice_address', Data.InvoiceAddress)
      if(Data.Email) formData.append('email', Data.Email)

      Data.Contacts.forEach((contact, key) => {
        if(contact.id) formData.append(`contacts[${key}][id]`, contact.id)
        formData.append(`contacts[${key}][fullname]`, contact.fullname)
        formData.append(`contacts[${key}][phone]`, JSON.stringify(contact.phone))
      });

      formData.append('_method', 'put');
      const data = {
        id: id,
        formData
      }
      
      try {
        const response = await dispatch(UpdateCustomer(data));
       
        if(response){
          // console.log(response);
          if(response.data.message == 'บันทึกสำเร็จ'){
            alert('บันทึกสำเร็จ');
            GetCustomerInfo();
            GetProvince();
            GetSegment();
          }else{
            let text = '';
            Object.values(response.data.message).map((item, index) => {
              if(item.length > 0){
                item.map(i => {
                  text = text + i + '\n'
                })
              }else{
                text = text + item + '\n'
              }
            });
            alert(text);
          }
        }else{
          console.log('failed')
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
    }
  };

  useEffect(() => {
    GetCustomerInfo();
    GetProvince();
    GetSegment();
    GetCustomer();
  }, []);

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetAmphure = async (data, select = true) => {
    if(select) setData({ ...Data, ProvinceId: data.id })
    const params = {
      ProvinceId: data.id
    }
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetSegment = async (data) => {
    try {
      const response = await dispatch(SegmentList());
      setSegments(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async () => {
    try {
      const response = await dispatch(Customer());
      setCustomers(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const GetCustomerInfo = async () => {
    try {
      const response = await dispatch(FindCustomer(id));
      let contacts = []
      if (response.data.data.contacts && response.data.data.contacts.length > 0) {
        response.data.data.contacts.forEach((contact, key) => {
          let obj = {}
          obj.key = key
          obj.id = contact.id || null
          obj.fullname = contact.fullname
          obj.phone = JSON.parse(contact.phone)
          contacts.push(obj)
        });
      }else{
        const model = {
          key: 0,
          fullname: null,
          phone: []
        }
        contacts.push(model)
      }

      setData({
        Type: response.data.data.type,
        SegmentId: response.data.data.segment_id,
        SegmentNumber: response.data.data.segment_number,
        Organization: response.data.data.organization,
        Prefix: response.data.data.prefix,
        FirstName: response.data.data.first_name,
        LastName: response.data.data.last_name,
        NickName: response.data.data.nickname,
        AKA: response.data.data.aka,
        CitizenId: response.data.data.citizen_id,
        Phones: JSON.parse(response.data.data.phone),
        Email: response.data.data.email,
        ProvinceId: response.data.data.amphure && response.data.data.amphure.province && response.data.data.amphure.province.id,
        AmphureId: response.data.data.amphure_id,
        InvoiceAddress: response.data.data.tax_invoice_address,
        PostCode: response.data.data.postcode,
        Channel: response.data.data.channel,
        CustomerId: response.data.data.recommender,
        Note: response.data.data.note,
        SpecialNeed: response.data.data.special_need,
        Contacts: contacts
      });

      GetAmphure(response.data.data.amphure.province, false)
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const SetSegmentNumber = (gnumber) => {
    setData({ ...Data, SegmentNumber: gnumber });
    setShow(false);
  }

  return (
    <>
      {
        Data.Type === 'นิติบุคคล' &&
        <Container fluid className="component-create-member">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="row-form-create-memeber ">
              <Col xs={12} lg={4} className=" ">
                <Row className="align-items-center">
                  <Col xs={4} lg={4} className="text-left mt-3 mb-3">
                    <Form.Label>Segment</Form.Label>
                  </Col>
                  <Col className="mt-3 mb-3">
                    <Select
                      options={Segments}
                      placeholder="Segment"
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={false}
                      value={Data.SegmentId ? Segments.find((data) => {return data.id === Data.SegmentId}) : null}
                      onChange={(data) => setData({ ...Data, SegmentId: data.id })}
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className=" pass-segment">
                <Row className="align-items-center">
                  <Col xs={4} xl={3} className="mt-3 mb-3 text-left">
                    <Button
                      type="button"
                      className="pick-segment-button"
                      onClick={DisplayNumber}
                    >
                      <p className='m-0'>เลือกรหัส4</p>
                    </Button>
                  </Col>
                  <Col xs={12} sm={8} className="mt-3 mb-3 text-left p-0">
                    <p className="ml-4">Ex.  Segment2: {Data.SegmentId && Segments.length > 0 ? Segments.find((data) => {return data.id === Data.SegmentId}).name : null} {Data.SegmentNumber}</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="row-form-create-memeber">
              <Col lg={6} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={2} className="text-left">
                    <Form.Label>ชื่อองค์กร</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={Data.Organization}
                      onChange={(e) => setData({ ...Data, Organization: e.target.value })}
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} xl={3} className="text-left">
                    <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                  </Col>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      value={Data.CitizenId}
                      onChange={(e) => setData({ ...Data, CitizenId: e.target.value })}
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="row-form-create-memeber">
              <Col lg={12} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={2} className="text-left">
                    <Form.Label>ที่อยู่ออกใบกำกับภาษี</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={Data.InvoiceAddress}
                      onChange={(e) => setData({ ...Data, InvoiceAddress: e.target.value })}
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} className="text-left">
                    <Form.Label>จังหวัด</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      options={Provinces}
                      placeholder="จังหวัด"
                      getOptionLabel={option => option.name_th}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={true}
                      value={Data.ProvinceId ? Provinces.find((data) => {return data.id === Data.ProvinceId}) : null}
                      onChange={(data) => { GetAmphure(data) }}
                      className="select-list w-100"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col className="text-left" lg={3}>
                    <Form.Label>อำเภอ</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      options={Amphures}
                      placeholder="อำเภอ"
                      getOptionLabel={option => option.name_th}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={true}
                      value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                      onChange={(data) => { setData({ ...Data, AmphureId: data.id }) }}
                      isDisabled={!Data.ProvinceId}
                      className="select-list w-100"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} className="text-left">
                    <Form.Label>รหัสไปรษณีย์ </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={Data.PostCode}
                      onChange={(e) => setData({ ...Data, PostCode: e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={5} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={2} className="text-left">
                    <Form.Label>E-mail</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="email"
                      value={Data.Email}
                      onChange={(e) => setData({ ...Data, Email: e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>

            {
              Data.Contacts?.map((contact, key) => {
                return (
                  <div key={key}>
                    <Row className="row-form-create-memeber mt-4 mb-4">
                      <Col lg={8}>
                        <Row>
                          <Col lg={5} className="mt-3 mb-3">
                            <Row className="align-items-center">
                              <Col lg={4} className=" text-left">
                                <Form.Label>ชื่อผู้ติดต่อ</Form.Label>
                              </Col>
                              <Col className="">
                                <Form.Control
                                  type="text"
                                  value={contact.fullname}
                                  required
                                  onChange={
                                    (e) => setData(prevState => ({...Data,
                                      Contacts: prevState.Contacts.map(
                                        el => el.key === key ? { ...el, fullname: e.target.value }: el
                                      )
                                    })) 
                                  }
                                ></Form.Control>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="mt-3 mb-3">
                            <Row className="align-items-center">
                              <Col lg={4} className="text-left">
                                <Form.Label>เบอร์โทรศัพท์</Form.Label>
                              </Col>
                              <Col>
                                <ReactTagInput
                                  placeholder="เบอร์โทรศัพท์"
                                  editable={true}
                                  readOnly={false}
                                  removeOnBackspace={true}
                                  maxTags={2}
                                  tags={contact.phone}
                                  required
                                  onChange={
                                    (newTags) => setData(prevState => ({
                                      ...Data,
                                      Contacts: prevState.Contacts.map(
                                        el => el.key === key ? { ...el, phone: newTags }: el
                                      )
                                    })) 
                                  }
                                  className="input-tage"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={1} className="set-button-delete">
                            <Button
                              type="button"
                              className="delete-contract"
                              onClick={() => RemoveContact(key)}
                            >
                              <img src={Delete} />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3} className="mt-3 mb-3">
                        {
                          (key+1) === Data.Contacts.length &&
                          <Row className="align-items-center">
                          <Col>
                            <Button
                              type="button"
                              className="add-contract-button"
                              onClick={(e) => InsertContact()}
                            >
                              <img src={add_contract} /> &nbsp;
                              เพิ่มผู้ติดต่อ
                            </Button>
                          </Col>
                        </Row>
                        }
                      </Col>
                    </Row>
                  </div>
                )
              })
            }
            
            <Row className="row-form-create-memeber comment-row mt-5">
              <Col lg={4} className="">
                <Row>
                  <Col lg={3} className="text-left">
                    <Form.Label>หมายเหตุ </Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      as="textarea"
                      placeholder=""
                      style={{ height: '121px' }}
                      value={Data.Note}
                      onChange={(e) => setData({ ...Data, Note: e.target.value }) }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-between mt-5 mb-5">
              <Col xs={6} md={4} lg={3}>
                <Button type="submit" className="button">
                  <img src={Save} /> &nbsp; บันทึก
                </Button>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Button type="button" className="button" onClick={exit}>
                  <img src={Return} /> &nbsp; ออก
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      }

      {
        Data.Type === 'บุคคลธรรมดา' &&
        <Container fluid className="component-create-member">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="row-form-create-memeber ">
              <Col xs={12} lg={4} className=" ">
                <Row className="align-items-center">
                  <Col xs={4} lg={4} className="text-left mt-3 mb-3">
                    <Form.Label>Segment</Form.Label>
                  </Col>
                  <Col className="mt-3 mb-3">
                    <Select
                      options={Segments}
                      placeholder="Segment"
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={false}
                      value={Data.SegmentId ? Segments.find((data) => {return data.id === Data.SegmentId}) : null}
                      onChange={(data) => setData({ ...Data, SegmentId: data.id })}
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className=" pass-segment">
                <Row className="align-items-center">
                  <Col xs={4} xl={3} className="mt-3 mb-3 text-left">
                    <Button
                      type="button"
                      className="pick-segment-button"
                      onClick={DisplayNumber}
                    >
                      <p className='m-0'>เลือกรหัส5</p>
                    </Button>
                  </Col>
                  <Col xs={12} sm={8} className="mt-3 mb-3 text-left p-0">
                    <p className="ml-4">Ex.  Segment2.2: {Data.SegmentId && Segments.length > 0 ? Segments.find((data) => {return data.id === Data.SegmentId}).name : null} {Data.SegmentNumber}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col xs={12} lg={5} className="text-left ">
                    <Form.Label>คำนำหน้าชื่อ</Form.Label>
                  </Col>
                  <Col className="">
                    <Select
                      options={Prefixes}
                      placeholder=""
                      isClearable={false}
                      isSearchable={false}
                      value={Data.Prefix ? Prefixes.find((data) => {return data.value === Data.Prefix}) : null}
                      onChange={(data) => setData({ ...Data, Prefix : data.value })}
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={2} className="text-left">
                    <Form.Label>ชื่อ</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="text"
                      required
                      value={Data.FirstName}
                      onChange={(e) => setData({ ...Data, FirstName : e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} xl={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} xl={3} className="text-left">
                    <Form.Label>นามสกุล</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="text"
                      required
                      value={Data.LastName}
                      onChange={(e) => setData({ ...Data, LastName : e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} xl={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} xl={3} className="text-left">
                    <Form.Label>ชื่อเล่น</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={Data.NickName}
                      onChange={(e) => setData({ ...Data, NickName : e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={5} className="text-left">
                    <Form.Label>ชื่อAKA</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={Data.AKA}
                      onChange={(e) => setData({ ...Data, AKA : e.target.value }) }
                      className="aka-form"
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col lg={5} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={3} className="text-left">
                    <Form.Label>เบอร์โทรศัพท์</Form.Label>
                  </Col>
                  <Col className="">
                    <ReactTagInput
                      placeholder="เบอร์โทรศัพท์"
                      editable={true}
                      removeOnBackspace={true}
                      maxTags={2}
                      tags={Data.Phones}
                      onChange={(newTags) => setData({ ...Data, Phones : newTags }) }
                      className="input-tage"
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={6} className="text-left">
                    <Form.Label>เลขบัตรประชาชน/พาสปอร์ต</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="number"
                      placeholder=""
                      // required
                      value={Data.CitizenId}
                      onChange={(e) => setData({ ...Data, CitizenId : e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={5} className="text-left">
                    <Form.Label>จังหวัด</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      options={Provinces}
                      placeholder="จังหวัด"
                      getOptionLabel={option => option.name_th}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={true}
                      value={Data.ProvinceId ? Provinces.find((data) => {return data.id === Data.ProvinceId}) : null}
                      onChange={(data) => { GetAmphure(data) }}
                      className="select-list w-100"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={3} className="text-left">
                    <Form.Label>อำเภอ</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      options={Amphures}
                      placeholder="อำเภอ"
                      getOptionLabel={option => option.name_th}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={true}
                      value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                      onChange={(data) => { setData({ ...Data, AmphureId: data.id }) }}
                      isDisabled={!Data.ProvinceId}
                      className="select-list w-100"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} className="text-left">
                    <Form.Label>รหัสไปรษณีย์ </Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      type="number"
                      value={Data.PostCode}
                      onChange={(e) => setData({ ...Data, PostCode: e.target.value }) }
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={4} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} className="text-left">
                    <Form.Label>ช่องทางการติดต่อ</Form.Label>
                  </Col>
                  <Col className="">
                    <Select
                      placeholder="ช่องทางการติดต่อ"
                      options={Channels}
                      isClearable={false}
                      isSearchable={false}
                      value={Data.Channel ? Channels.find((data) => {return data.value === Data.Channel}) : null}
                      onChange={(data) => setData({ ...Data, Channel: data.value }) }
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={4} className="mt-3 mb-3">
                <Row className="align-items-center">
                  <Col lg={4} className="text-left">
                    <Form.Label>ชื่อผู้แนะนำ </Form.Label>
                  </Col>
                  <Col>
                    <Select
                    placeholder="ชื่อผู้แนะนำ"
                      options={Customers}
                      getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                      getOptionValue={option => option.id}
                      isClearable={false}
                      isSearchable={true}
                      value={Data.CustomerId ? Customers.find((data) => {return data.id === Data.CustomerId}) : null}
                      onChange={(data) => setData({ ...Data, CustomerId: data.id }) }
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-form-create-memeber">
              <Col lg={5} className="mt-3 mb-3">
                <Row>
                  <Col lg={3} className="text-left">
                    <Form.Label>หมายเหตุ </Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      as="textarea"
                      placeholder=""
                      style={{ height: '121px' }}
                      value={Data.Note}
                      onChange={(e) => setData({ ...Data, Note: e.target.value }) }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="mt-3 mb-3">
                <Row>
                  <Col lg={4} className="text-left">
                    <Form.Label>ความต้องการพิเศษ</Form.Label>
                  </Col>
                  <Col className="">
                    <Form.Control
                      as="textarea"
                      placeholder=""
                      style={{ height: '121px' }}
                      value={Data.SpecialNeed}
                      onChange={(e) => setData({ ...Data, SpecialNeed: e.target.value }) }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-between mt-5 mb-5">
              <Col xs={6} md={4} lg={3}>
                <Button type="submit" className="button">
                  <img src={Save} /> &nbsp; บันทึก
                </Button>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Button type="button" className="button" onClick={exit}>
                  <img src={Return} /> &nbsp; ออก
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      }
      
      <Modal
        className="modal-segment"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-segment">
          <p>
            <b>เลือกรหัส</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={NumberList}>
            <Row className="set-row-search-box">
              <Col lg={3} className="d-flex justify-content-center">
                <Form.Control
                  type="number"
                  placeholder="ค้นหารหัสว่าง4"
                  required
                  value={SearchSegment.Keyword ? SearchSegment.Keyword : ''}
                  onChange={(e) => setSearchSegment({Keyword: e.target.value})}
                  className="search-code"
                ></Form.Control>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='submit' className="button-modal-segment">
                  <img src={Search} /> &nbsp; ค้นหา
                </Button>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='button' className="button-modal-segment" onClick={(e) => NumberList(e, 'reset')}>คืนค่า</Button>
              </Col>
            </Row>
          </Form>
          <Row className="set-box-value">
            <Col className="colum-value">
            { 
              SegmentNumber?.map(gnumber => {
                return (
                  <Button type='button' className="put-value-segment" onClick={(e) => SetSegmentNumber(gnumber)} key={gnumber}>{gnumber}</Button>
                )
              })
            }
            </Col>
            ;
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
