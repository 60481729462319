import React, { useState, useEffect, useRef} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Select from 'react-select';

import Footer from 'Components/Footer';
import TabsIcon from 'Components/layout/TabsIcon';
import save from 'assets/icon/new-save.png';
import cancel from 'assets/icon/getout.png';
import delete_button from 'assets/icon/delete.png';
import Delete from 'assets/icon/delete.png';
import Service from 'assets/icon/management.png';
import Back from 'assets/icon/back.png';
import { ModifierFlags } from 'typescript';
import uploadImage from 'assets/icon/upload.png';
import { Province } from 'actions/data/index';
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { CarTypeList } from 'actions/settings/car_type';
import { CarColorList } from 'actions/settings/car_color';
import { CreateCarOwner, CheckCarOwner } from 'actions/customer/car';
export default function AddCar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  const ImageFileRef = useRef(null);

  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [Brands, setBrands] = useState([]);
  const [Color, setColor] = useState([]);
  const [Models, setModels] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [Type, setType] = useState([
    
  ]);
  
  const [PlateTypes, setPlateType] = useState([
    { label: 'ป้ายแดง', value: 'ป้ายแดง' },
    { label: 'ป้ายธรรมดา', value: 'ป้ายธรรมดา' },
    { label: 'ไม่มีป้าย', value: 'ไม่มีป้าย' },
    { label: 'ป้ายขาว', value: 'ป้ายขาว' },
    { label: 'ป้ายประมูล', value: 'ป้ายประมูล' },
  ]);

  const [ValidateLicensePlate,setValidateLicensePlate] = useState([]);

  const model = {
    CustomerId: id,
    CarTypeId: null,
    CarBrandId: null,
    CarSerieId: null,
    Year: null,
    // Size: null,
    Engine: null,
    Color: null,
    ProvinceId: null,
    PlateType: null,
    LicensePlate: null,
    ChassisNumber: null,
    PaintCoating: null,
    Mileage: null,
    Note: null,
    Images: []
  }
  const [Data, setData] = useState(
    model
  );

  // setValidateLicensePlate({...ValidateLicensePlate, RequestLicensePlate: Data.PlateType !== 'ไม่มีป้าย' ? true  :false, RequestProvinces: Data.PlateType !== 'ไม่มีป้าย' ? true : false});

  // const [FileName, setFileName] = useState({
  //   Image: ''
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(Data.PlateType === null){
      alert('กรุณาเลือกประเภทแผ่นป้าย');
    }
    if(Data.PlateType != "ไม่มีป้าย" && Data.LicensePlate === null){
      alert('กรุณากรอกป้ายทะเบียน');
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    }

    setValidated(true);

    // console.log(Data)
    const validator = document.querySelectorAll('.validate-fail');
    if (form.checkValidity() && validator.length === 0) {
      let formData = new FormData();
      if(Data.CustomerId) formData.append('customer_id', Data.CustomerId);
      if(Data.CarSerieId) formData.append('car_serie_id', Data.CarSerieId);
      if(Data.CarTypeId) formData.append('car_type_id', Data.CarTypeId);
      if(Data.ProvinceId) formData.append('province_id', Data.ProvinceId);
      if(Data.Year) formData.append('year', Data.Year);
      // if(Data.Size) formData.append('size', Data.Size);
      if(Data.Engine) formData.append('engine', Data.Engine);
      if(Data.Color) formData.append('color', Data.Color);
      if(Data.PlateType) formData.append('plate_type', Data.PlateType);
      if(Data.LicensePlate) formData.append('license_plate', Data.LicensePlate);
      if(Data.ChassisNumber) formData.append('chassis_number', Data.ChassisNumber);
      if(Data.PaintCoating) formData.append('paint_coating', Data.PaintCoating);
      if(Data.Mileage) formData.append('mileage', Data.Mileage);
      if(Data.Note) formData.append('note', Data.Note);
      if(Data.Images.length > 0) {
        let i = 0 ;
        while (i < Data.Images.length){
          formData.append('images[]', Data.Images[i]);
          i++;
        }
      }
 
      let check = false;
      const res = await dispatch(CheckCarOwner(formData));

      console.log(res)
      if(res.data.msg == '200'){
        check = true;
      }else{

        let text = "เลขที่ทะเบียนนี้ เคยถูกเพิ่มแล้วคุณต้องการเพิ่มซ้ำอยู่ไหม";
        if (window.confirm(text) == true) {
          check = true;
        }
      }

      if(check === true){
        try {
          const response = await dispatch(CreateCarOwner(formData));
        
          if(response){
            setData(model);
            setValidated(false);
            // history.push(`/member/edit/${id}`);
            history.push({
              pathname: `/member/edit/${id}`,
              search: `?activeTab=2`
            })
          }else{
            console.log('failed')
          }
        } catch (error) {
          console.error(error);
        } finally {
          console.log('finally')
        }
      }
    }
  };

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetColor = async (data) => {
    try {
      const response = await dispatch(CarColorList());
      setColor(response.data.raw);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetType = async () => {
    try {
      const response = await dispatch(CarTypeList());
      setType(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetBrand();
    GetProvince();
    GetType();
    GetColor();
  }, []);

  function ChangeModel(data) {
    setData({...Data, CarBrandId: data.id, CarSerieId: '', CarTypeId: ''})
    GetCarModel(data.id)

  }

  function ChangeColor(data) {
    setData({...Data, Color: data.id})
  }

  const ToggleImageFile = () => {
    if(ImageFileRef.current) ImageFileRef.current.click();
  }

  const SetImageFiles = (e) => {
    console.log('inputed', e.target.files)
    
    // const name = []
    const files = [...Data.Images];
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      // name.push(item.name)
      files.push(item)
    }
    // setFileName({ ...FileName, Image: name})
    setData({ ...Data, Images: files })
    console.log('Data Images', Data.Images)
    console.log('Data Image', files)
  } 
  
  
  const deleteFile = (index) => {
    setData({ ...Data, Images: Data.Images.filter(function(_, key) { 
      return key !== index
    })})
  }

  function close() {
    history.push(`/member/edit/${id}`);
  }

  function exit() {
    history.push(`/member/edit/${id}`);
  }

  function addCommas(x) {
    return parseInt(x.replaceAll(',', '')).toLocaleString()
  }

  return (
    <>
      <Container fluid className=" package-container Service Stock User bg-mrk">
        <div className="package ">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>ลูกค้า &gt; สร้างข้อมูลรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>ลูกค้า &gt; สร้างข้อมูลรถ</b>
                </h5>
              </div>
              <div className="modal-body add-car">
                <Row className="m-0">
                  <TabsIcon />
                </Row>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <p className="title-addcar">เพิ่มข้อมูลรถ</p>
                  </Col>
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ยี่ห้อ</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Brands}
                                placeholder=""
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                onChange={(data) => ChangeModel(data)}
                                required
                                className={`select-add-member select-list ` + (!Data.CarBrandId && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>รุ่นและรหัสรุ่น</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Models}
                                placeholder=""
                                getOptionLabel={option => `${option.model}-${option?.car_serie?.code}`}
                                getOptionValue={option => option.id}
                                isDisabled={!Data.CarBrandId}
                                onChange={(data) => setData({...Data, CarSerieId: data?.car_serie && data?.car_serie?.id, CarTypeId: data?.car_serie?.car_type_id })}
                                value={Data.CarSerieId ? Models.find((data) => {return data?.car_serie?.id === Data.CarSerieId}) : null}
                                required
                                className={`select-add-member select-list ` + (!Data.CarSerieId && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ประเภท </Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Type}
                                placeholder=""
                                getOptionLabel={(option) => `${option.name}`}
                                getOptionValue={(option) => option.id}
                                isDisabled={!Data.CarBrandId}
                                defaultValue={Data.CarTypeId}
                                value={Data.CarTypeId ? Type.find((data) => {return data.id === Data.CarTypeId}) : null}
                                onChange={(data) => setData({...Data, CarTypeId: data.id })}
                                required
                                className={`select-add-member select-list ` + (!Data.CarTypeId && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ปีที่ออกรถ</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Year}
                                onChange={(e) => setData({...Data, Year: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>สีรถ</Form.Label>
                            </Col>
                            <Col>
                              {/* <Form.Control
                                type="text"
                                required
                                placeholder=""
                                value={Data.Color}
                                onChange={(e) => setData({...Data, Color: e.target.value })}
                              ></Form.Control> */}
                              <Select
                                options={Color}
                                placeholder=""
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                onChange={(data) => ChangeColor(data)}
                                required
                                className={`select-add-member select-list ` + (!Data.Color && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>ขนาดเครื่องยนต์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Engine}
                                onChange={(e) => setData({...Data, Engine: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ประเภทป้าย</Form.Label>
                            </Col>
                             <Col>
                              <Select
                                options={PlateTypes}
                                placeholder=""
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.value}
                                onChange={(data) => {setData({...Data, PlateType: data.value });setValidateLicensePlate({...ValidateLicensePlate, RequestLicensePlate: data.value !== 'ไม่มีป้าย' ? true  :false, RequestProvinces: data.value !== 'ไม่มีป้าย' ? true : false})}}
                                value={Data.PlateType ? PlateTypes.find((data) => {return data.value === Data.PlateType}) : null}
                                required
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>เลขทะเบียน</Form.Label>
                            </Col>
                            <Col>
                              {/* <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.LicensePlate}
                                // required={ValidateLicensePlate.RequestLicensePlate ? ValidateLicensePlate.RequestLicensePlate : false}
                                required
                                onChange={(e) => setData({...Data, LicensePlate: e.target.value })}
                              ></Form.Control> */}
                              <Col>
                              <Form.Control
                                type="text"
                                required={ValidateLicensePlate.RequestLicensePlate ? ValidateLicensePlate.RequestLicensePlate : false}
                                placeholder=""
                                value={Data.LicensePlate}
                                onChange={(e) => setData({...Data, LicensePlate: e.target.value })}
                              ></Form.Control>
                            </Col>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>จังหวัด</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Provinces}
                                placeholder="จังหวัด"
                                getOptionLabel={option => option.name_th}
                                getOptionValue={option => option.id}
                                isClearable={false}
                                onChange={(data) => setData({...Data, ProvinceId: data.id })}
                                required={ValidateLicensePlate.RequestProvinces ? ValidateLicensePlate.RequestProvinces : false}
                                className={`w-100 select-list ` + (!Data.ProvinceId && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center ">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>หมายเลขตัวถัง</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                required={Data.PlateType == 'ไม่มีป้าย' || Data.PlateType == 'ป้ายแดง' ? true : false}
                                placeholder=""
                                value={Data.ChassisNumber}
                                onChange={(e) => setData({...Data, ChassisNumber: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>เลขไมล์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Mileage ? addCommas(Data.Mileage) : ''}
                                onChange={(e) => setData({...Data, Mileage: e.target.value.replaceAll(',', '') })}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ความหนาสีรถยนต์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                // required
                                placeholder=""
                                value={Data.PaintCoating}
                                onChange={(e) => setData({...Data, PaintCoating: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={9} className="d-flex justify-content-start">
                          <div className="scale-image multi-preview">
                            <div className="result">
                              <Row>
                                {
                                  Data.Images.map((item, index) => {
                                    return (
                                      <Col xs="auto" key={`preview-${index}`} className="mt-3 ">
                                        <img src={URL.createObjectURL(item)} width="150px" height="100%"/>
                                        <button type='button' onClick={() => deleteFile(index)} className="position-absolute-button-image">
                                          <img src={Delete} />
                                        </button>
                                      </Col>
                                    );
                                  })
                                }
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="d-flex justify-content-center mt-3 mb-3">
                          <div className="label-holder">
                            <label htmlFor="file" className="label" onClick={ToggleImageFile}>
                              <img src={uploadImage} /> &nbsp;อัพโหลดภาพ
                            </label>
                          </div>
                          <Form.Control
                            type="file"
                            className="d-none"
                            id="avatar-file"
                            ref={ImageFileRef}
                            multiple
                            onChange={(e) => SetImageFiles(e) }
                          />
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row>
                            <Col lg={3} className="text-left">
                              <Form.Label>หมายเหตุ</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                value={Data.Note}
                                onChange={(e) => setData({...Data, Note: e.target.value })}
                                rows={3}
                                className="text-area"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col xs={12} lg={6}>
                          <Row>
                            <Col xs={12} lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="submit"
                                className="btn-upload-image"
                              >
                                <img src={save} />
                                &nbsp; บันทึก
                              </Button>
                            </Col>
                            {/* <Col xs={12} lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="button"
                                className="btn-upload-image"
                              >
                                <img src={delete_button} />
                                &nbsp; ลบ
                              </Button>
                            </Col> */}
                          </Row>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Row className="justify-content-end">
                            <Col lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="button"
                                className="btn-upload-image"
                                onClick={exit}
                              >
                                <img src={cancel} />
                                &nbsp; ออก
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
