import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { FindPackageType, GetFilmModel,GetFilmSizeCodeByModel } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';

export default function Lightfilter(props) {
  const dispatch = useDispatch()
  const history = useHistory();

  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  console.log('parseDataJob', parseDataJob)
  const [Data,setData] =useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    // PackageTypeId: props.data && props.data.PackageTypeId ? props.data.PackageTypeId : null,
    // package_type_name: props.data && props.data.package_type_name ? props.data.package_type_name : null,
    // PackageStorageList: props.data.PackageStorageList.length > 0  ? props.data.PackageStorageList : [],
    // insite_note: props.data && props.data.insite_note ? props.data.insite_note : null,
    // key: props.data && props.data.key ? props.data.key : null,
    // note: props.data && props.data.note ? props.data.note : null,
    // type_work: props.data && props.data.tyep_work ? props.data.type_work : null,
    // service_now: props.data && props.data.service_now ? props.data.service_now : null,
    // service_more: props.data && props.data.service_more ? props.data.service_more : null,
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);
  const [TypeWork,setTypeWork] = useState([])
  const [TypeWorkId,setTypeWorkId] = useState('');
 
  const [serviceMore, setServiceMore] = useState(false);
  const [serviceNow, setServiceNow] = useState(false);
  const [checkSetting, setCheckSetting] = useState(false);
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [position, setPosition] = useState([{ value: '', label: '' }]);
  const [filmBrand, setFilmBrand] = useState([{ value: '', label: '' }]);
  const [filmVersion, setFilmVersion] = useState([{ value: '', label: '' }]);
  const [modelNumber, setModelNumber] = useState([{ value: '', label: '' }]);
  const [filmThickness, setFilmThickness] = useState('');
  const [TextDetails,setTextDetails] = useState('');
  const [FilmModel, setFilmModel] = useState([{ value: '', label: '' }]);
  const [PackageSizeModel,setPackageSizeModel] = useState([]);
  const [PackageSizeCode,setPackageSizeCode] = useState([]);
  // const packages = props.packagas ? props.packagas : [];
  const [pointCoatings, setPointCoatings] = useState(null);
  const [PositionUse, setPositionUse] = useState(null);
  const handleServiceMore = () => {
    setServiceMore(!serviceMore);
  };
  const handleFastService = () => {
    setServiceNow(!serviceNow);
    updateLocalStorage(!serviceNow,null,'service_now');
  };
  const handleCheckSetting = () => {
    setCheckSetting(!checkSetting);
    updateLocalStorage(!checkSetting,null,'check_setting');
  };

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function SetFilmThickness(data) {
    setFilmThickness({...filmThickness, value: data.target.value});
  }

  function SetTextDetails(data) {
    setTextDetails(data.target.value);
  }

  const TypeWorkList= [{id: 'ติดตั้งใหม่', name: 'ติดตั้งใหม่' },{id: 'ลอกอันเก่าและติดใหม่', name: 'ลอกอันเก่าและติดใหม่'}];

  // function SetFilmModel(data) {
  //   console.log('SetFilmModel',data);
  //   setFilmModel({ value: data.model, label: data.model, data});
  //   getFilmListCode(data)
  // }
  function SetModelNumber(data) {
    setModelNumber({ value: data.code, label: data.code, data }); 

  }

  // const optionService = packages;
  const optionPosition = [{ value: '', label: '' }];
  const optionFilmBrand = [{ value: '', label: '' }];
  const optionFilmVersion = [{ value: '', label: '' }];
  const optionModelNumber = [{ value: '', label: '' }];
  const optionFilmThickness = [{ value: '', label: '' }];

  function SetService(data) {
    setService({ value: data.id, label: data.name, data });
    getSeriesFilm(data.id);
    // console.log('SetService',{ value: data.id, label: data.name, data });
  }

  function SetFilmModel(data) {
    console.log('SetFilmModel',data);
    setFilmModel({ value: data.model, label: data.model, data});
    getFilmListCode(data)
  }
  function SetModelNumber(data) {
    setModelNumber({ value: data.code, label: data.code, data });

  }
  function SetTypeWork(data){
    setTypeWork(data.name)
    setTypeWorkId(data.id)
    // updateLocalStorage(data.name,null,'type_work');
  }


  const StorageModel = {
    key: 0,
    PackageId: '',
    PackageCode: '',
    PackageName: '',
    PackageTypeId: '',
    PackageTypeName: '',
    Status: 'new',
    form: 'package',
    Quantity: null,
    TypeWork: '',
    FilmThickness: null,
  }

  function InsertPackage(){
    if(service.data && service.data.id){
      // console.log('service.data',service.data);
      let insert = StorageModel
      insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
      insert.PackageId = service.data.id
      insert.PackageCode = service.data.code
      insert.PackageName = service.data.name
      insert.FilmThickness = filmThickness.value
      insert.PackageTypeId = props.data.id 
      insert.PackageTypeName = props.data.name
      insert.ServiceNow = serviceNow
      insert.TypeWork = TypeWork
      const CarSize = CarOwner.car_serie.size
      const MiddleSize = service.data.middle_sizes
      const resultCarSize = MiddleSize.find((item) => {
        return item.size === CarSize
      })
      if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
        insert.Price = MiddleSize[0].price
      }else{
        insert.Price = resultCarSize.price ? resultCarSize.price : 0 
      }
      insert.PackageType = service.data.category
      insert.TextDetails = TextDetails
      insert.PositionUse = PositionUse
      insert.FilmModel = FilmModel.value
      insert.modelNumber = modelNumber.value
      insert.PointCoatings = pointCoatings

      // for Appointment
      insert.CleanService = null
      insert.Treatment = null // TreatmentTime * Quantity
      insert.TreatmentTime = service.data.treatment_time
      insert.TreatmentRanges = service.data.treatment_ranges
      insert.Setup = service.data.setup
      insert.Schedule = service.data.schedule
      insert.PackageTypeId = service.data.package_type_id
      console.log('insert', insert)
      setData({...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

      //clear select
      setTypeWorkId('');
      setServiceNow(false);
      setService({ value: '', label: '' });
      setFilmModel({ value: '', label: '' });
      setFilmThickness({ value: ''});
      setFilmModel({ value: '', label: '' });
      setTextDetails('');
      setModelNumber({ value: '', label: ''});

      // //for remove select form select list
      // const result = Packages.filter((item)=> {
      //   return item.id !== service.data.id
      // });
      // setPackages(result);
      // // console.log('Data',Data);
      const package_list = Data.PackageStorageList
      package_list.push(insert)

      parseDataJob.PackageList = package_list;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

    }else{
      alert('เลือกรายการ')
    }
  }

  async function getSeriesFilm(PackageId){
    setLoading(true)
    try {
      const response = await dispatch(GetFilmModel(PackageId));
      const packages_size = response.data.data
      setPackageSizeModel(packages_size);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  async function getFilmListCode(data){
    // console.log('getFilmListCode',data);
    setLoading(true)
    try {
      const params = {
        package_id: data.package_id,
        model: data.model,
      }
      const response = await dispatch(GetFilmSizeCodeByModel(params));
      const package_size_code = response.data.data
      setPackageSizeCode(package_size_code);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.package_id
    })
    Packages.push(result);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'Quantity':
        setData(prevState => ({...Data,
          PackageStorageList: prevState.PackageStorageList.map(
            el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
          )}))
    
        item.Quantity = Number(data.target.value)
        break;
      case 'note':
        setData({...Data, note: data.target.value })
        Data.note = data.target.value
        break;
      case 'insite_note':
        setData({...Data, insite_note: data.target.value })
        Data.insite_note = data.target.value
        break;
      case 'service_now':
        setData({...Data, service_now: data })
        Data.service_now = data
        break;
      case 'service_more':
        setData({...Data, service_more: data })
        Data.service_more = data
        break;
      case 'filmThickness':
        setData({...Data, service_now: data })
        Data.film_thickness = data
        break;
      case 'things_in_car':
        setData({...Data, things_in_car: data })
        Data.things_in_car = data
        break;
      case 'defect_car':
        setData({...Data, defect_car: data })
        Data.defect_car = data
        break;
      case 'area':
        setData({...Data, area: data.target.value })
        Data.area = data.target.value
        break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = result;
    parseDataJob.note = Data.note
    parseDataJob.insite_note = Data.insite_note
    parseDataJob.area = Data.area
    parseDataJob.things_in_car = Data.things_in_car
    parseDataJob.defect_car = Data.defect_car
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      // console.log('packages',packages);
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function getTextByPackage(item){
    let set_text = '';
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
        set_text =  `${item.TypeWork ? item.TypeWork : ''} ${ item.FilmModel !== null ? item.FilmModel : ''}-${item.modelNumber !== null ? item.modelNumber : ''}  `
        return set_text
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('getDisplayTextPositionCoating',item);
    if(item?.PositionUse === null && item?.PointCoatings === null){
      return ''
    }else{
      if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
        // console.log(' item?.PointCoatings', item?.PointCoatings); 
        const result = item?.PointCoatings.map((item) => {
          return item.size 
        }).join(", ")
    
        return result ? 'บริเวณ :' + result : ''
      }else{
        return ''
      }
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[])
  return (
    <>
      <Form>
        <Container fluid className="pl-3 pr-3">
          <Row className="g-0 justify-content-start align-items-center d-lg-none">
            {/* <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-3 mb-3 ">
              <Form.Check
                type="checkbox"
                label="เช็คการเซ็ตตัว"
                onChange={handleCheckSetting}
                checked={checkSetting}
                className="checkbox-input"
              />
            </Col> */}
            <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-3 mb-3 ">
              <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={serviceNow}
                className="checkbox-input mt-3"
              />
            </Col>
          </Row>
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <Select
                    options={TypeWorkList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={TypeWorkId ? TypeWorkList.find((data) => { return data.id === TypeWorkId}) : '' }
                    placeholder="ประเภทงาน"
                    isClearable={false}
                    isSearchable={false}
                    onChange={(data) => SetTypeWork(data)}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-between">
                {/* <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="เช็คการเซ็ตตัว"
                      onChange={handleCheckSetting}
                      checked={checkSetting}
                      className="checkbox-input"
                    />
                  </div>
                </Col> */}
                <Col lg={6} xl={5}>
                  {/* <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={serviceNow}
                      className="checkbox-input"
                    />
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Select
                options={Packages}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={service.value ? Packages.find((data) => {return data.id === service.value}) : ''}
                placeholder="บริการของสินค้า"
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetService(data)}
                className="select-list"
              />
            </Col>
            <Col lg={5} className="mt-2 mb-2">
              <Form.Control className="input-table2"
                placeholder="ความหนาของฟิล์ม"
                value={filmThickness.value}
                onChange={(e) => SetFilmThickness(e)}
                type='number'
              ></Form.Control>
            </Col>
          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={3} className="mt-2 mb-2">
              <Select
                options={PackageSizeModel}
                placeholder="เลือกรุ่นฟิล์ม"
                getOptionValue={(option)=> option.model}
                getOptionLabel={(option) => option.model}
                value={FilmModel.value ? PackageSizeModel.find((data) => { return data.model === FilmModel.value}) : ''}
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetFilmModel(data)}
                className="select-list"
              />
            </Col>
            <Col lg={3} className="mt-2 mb-2">
              <Select
                options={PackageSizeCode}
                getOptionValue={(option)=> option.id}
                getOptionLabel={(option) => option.code}
                placeholder="เลือกเลขรุ่น"
                value={modelNumber.value ? PackageSizeCode.find((data) => { return data.code === modelNumber.value}) : ''}
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetModelNumber(data)}
                className="select-list"
              />
            </Col>

          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
                <Form.Label>รายละเอียด</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={TextDetails}
                    onChange={(e) => SetTextDetails(e)}
                  />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xl={2} className="mt-3 mb-3">
              <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                เพิ่ม
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td>{item.PackageName} 
                          <p>
                            { getTextByPackage(item)}
                            { getDisplayTextPositionCoating(item) }
                            { getDisplayTextPositionUse(item)}
                            { item.TextDetails ? `(${item.TextDetails})` : '' }
                          </p>
                          {/* (ระยะเวลา 1 ปี) 1/3 */}
                          </td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            required
                            type="number"
                            disabled={item.Status === 'appointment'}
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type="" className="button-package"
                             disabled={item.Status === 'appointment'}
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}
