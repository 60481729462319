import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";
import 'Setting/PreviewBill/Component/css/bill-2.css';

import dayjs from 'dayjs'
import { map } from 'jquery';
const THBText = require('assets/libraries/thai-baht-text') 

export class Bill2 extends React.PureComponent {
  
  render() {

    const { Bill, Set } = this.props

    console.log(Bill)

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    // const displayPhone = (phone) => {
    //   if(phone && Array.isArray(phone)) return phone.join(', ')
    //   if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
    // }

    const displayPhone = (phone,form) => {
      // return 'test'
        // console.log(form)
        if(phone && Array.isArray(phone)){
          if(phone && Array.isArray(phone) === true && phone.length > 1){
            return phone.join(', ')
          }else{
            return [phone]
          }
        }else if(phone && Array.isArray(Array.isArray(phone) ? JSON.parse(phone) : false)){
          if(phone && Array.isArray(JSON.parse(phone)  && phone.length > 1)){
            return JSON.parse(phone).join(', ')
          }else{
            return JSON.parse(phone)
          }
        }else{
          return [phone]
        }
    }

    return (
      <div id="bill-2" className='bill-2'>
        <div className='bill-2-field'>
          <div className='top' style={{ paddingTop: '80px' }}>
          </div>
          <div className='inline' style={{ top: '240px', left: '60%', width: '40%', paddingTop: '70px', position: 'absolute'}}>
            <p style={{ width: '100%', paddingLeft: '33px' }}>{ Bill?.job?.create_by?.employee ? Bill?.job?.create_by?.employee.fullname : '-'  }</p>
          </div>

          <div className='detail'>
            <div className='line' style={{ height: '125px' }}>
              <label style={{ width: '40%'}}>{ Bill?.job?.branch?.bill_title !== null ? Bill?.job?.branch?.bill_title.split('\n').map((str) => { return (<p>{str}</p>) }) : '-' }</label>
            </div>
            <div className='line' style={{ paddingTop: '20px' }}>
              <label style={{ width: '60%'}}>
                {
                  Bill?.job?.customer?.type === 'นิติบุคคล' &&
                  <p style={{ marginLeft: '35px' }}>{ `${Bill?.job?.customer?.organization} (${Bill?.job?.customer?.customer_code})` }</p>
                }
                {
                  Bill?.job?.customer?.type === 'บุคคลธรรมดา' &&
                  <p style={{ marginLeft: '35px' }}>{ `${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name} (${Bill?.job?.customer?.customer_code})` }</p>
                }
                { /*Bill?.job?.job_code*/ }</label>
              <div className='inline between' style={{ width: '40%'}}>
                <p style={{ width: '100%' }}>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
              </div>
            </div>
            {/* <div className='line'>
              <div className='inline' style={{ width: '40%'}} >
                <label className="bill-6-label">ชื่อลูกค้า</label>
                {
                  Bill?.job?.customer?.type === 'นิติบุคคล' &&
                  <p style={{ marginLeft: '5px' }}>{ `${Bill?.job?.customer?.organization}` }</p>
                }
                {
                  Bill?.job?.customer?.type === 'บุคคลธรรมดา' &&
                  <p style={{ marginLeft: '5px' }}>{ `${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` }</p>
                }
              </div>
              <div className='inline between' style={{ width: '60%'}}>
                <p style={{ width: '50px'}}></p>
                <p style={{ width: '100%'}}>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
              </div>
            </div> */}
            <div className='line'>
              <div className='inline'>
                <label className="bill-6-label"></label>
                <p style={{ marginLeft: '35px' }}>{ Bill?.job?.customer?.address ? Bill?.job?.customer?.address : ''  }</p>
              </div>
            </div>
            <div className='line'>
              <div className='inline'>
                <label className="bill-6-label"></label>
                {
                  Bill?.job?.customer?.type === 'นิติบุคคล' &&
                  <p style={{ marginLeft: '35px' }}>เลขประจำตัวผู้เสียภาษี  { Bill?.job?.customer?.citizen_id }</p>
                }
              </div>
            </div>
            <div className='line'>
              <div className='inline'>
                <label className="bill-6-label" style={{ marginLeft: '35px' }}>โทร.</label>
                <p style={{ marginLeft: '5px' }}>
                {
                  Bill && Bill.job && Bill.job.customer && Bill.job.customer.phone ? 
                  <span> { `${JSON.parse(Bill.job.customer.phone)}` }</span>
                  : '-' 
                }</p>
              </div>
            </div>
          </div>

          <div className='table-bill-2'>

            <div className='body-field' style={{ marginTop: '33px' }}>
              {
                Bill?.job?.job_details.map((data, index) => {
                  return(
                    <div className='table-body'>
                      <div style={{ textAlign: 'left' }}>{index + 1}</div>
                      <div>{renderProduct(data)}</div>
                      <div>{data.quantity}</div>
                      <div style={{ textAlign: 'right' }}>{data.price}</div>
                      <div>{data.grand_total}</div>
                    </div>
                  )
                })
              }

            </div>

            <div className='table-body pricing first' style={{ paddingBottom: '6px' }}>
              <div></div>
              {/* <div>สีขาว</div> */}
              <div className='merge bordered'>{ Bill?.job && THBText(Bill?.job?.total_price) }</div>
              <div className='bordered'>{ Bill?.job?.total }</div>
            </div>

            <div className='table-body pricing second' style={{ paddingBottom: '6px', paddingTop: '10px' }}>
              <div className='bordered'></div>
              <div></div>
              <div className='bordered'>VAT(7%)</div>
              <div className='bordered'>{ Bill?.job?.vat_calculate }</div>
            </div>

            <div className='table-body pricing last' style={{ paddingTop: '4px' }}>
              <div className='bordered'></div>
              {/* <div className='bordered'></div> */}
              <div className='merge bordered'></div>
              <div className='bordered'>{ Bill?.job?.total_price }</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}