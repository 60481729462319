import axios, { post } from 'axios';

export const JobList = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/jobs`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const JobLogs = (params) => async (dispatch) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/job_logs`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const FindJob = ({id}) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};


// export const FindJobLog = ({id}) => async (dispatch) => {
//   try {
//     return await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/api/job_logs/${id}`
//     ).then(response => {
//       return Promise.resolve(response);
//     }).catch( e => {
//       return Promise.reject(e)
//     });

//   } catch (error) {

//   }
// };


export const CreateJob = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/jobs`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CreateOldJob = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/job-old-store`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateJob = ({id, formData}) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/${id}`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};


export const MergeJobPackage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/merge-packages`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};


