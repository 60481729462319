import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Row, Col, Button, Form, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationList, FindNotification, UpdateNotification } from 'actions/notification';
import NotificationSound from 'assets/notification_sound.mp3'
import MyPagination from 'Components/MyPagination'
import { Link, useHistory } from 'react-router-dom';
var dayjs = require('dayjs')
var isToday = require('dayjs/plugin/isToday')
var isYesterday = require('dayjs/plugin/isYesterday')
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export default function List(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { name, current, type, status } = props

  const DateFormat = "DD/MM/YYYY";
  const TimeFormat = 'HH:mm';

  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [rows, setRows] = useState(0);
  const [search, setSearch] = useState({
    type,
    status,
    limit: 10,
    page: 1
  });
  
  const [notifications, setNotifications] = useState([])
  const { employee } = useSelector( (state) => state.user)
  const { notification } = useSelector( (state) => state.user)
  const [isFirstLoad, setFirstLoad] = useState(true);

  const GetNotification = (data) => {
    setisLoading(true)
    dispatch(NotificationList(search)).then(response => {
      if (search.limit) {
        setNotifications(response.data.data.data)
        setRows(response.data.data.last_page)
        setisLoading(false)
      }else{
        setNotifications(response.data.data)
        setRows(0)
        setisLoading(false)
      }
      setisLoading(false)
    }).catch(e => {

    })
  }

  useEffect(() => {
    if (name === current) {
      GetNotification()
    }
  }, [name, current, search.page]);
  
  let exists = []
  const CheckGroupDate = (date, save = false) => {
    if (dayjs(date).isToday()) {
      if (!exists.includes('วันนี้') && save) exists.push('วันนี้')
      return 'วันนี้'
    }else if(dayjs(date).isYesterday()){
      if (!exists.includes('เมื่อวาน') && save) exists.push('เมื่อวาน')
      return 'เมื่อวาน'
    }else{
      if (!exists.includes('date') && save) exists.push(dayjs(date).format(DateFormat))
      return dayjs(date).format(DateFormat)
    }
  };

  const DisplayTime = (date) =>  {
    return dayjs(date).format(TimeFormat)
  }

  const EditNotification = (item) =>  {
    const form = {
      id: item.id,
      data:{
        status: item.status,
        active: false
      }
    }
    dispatch(UpdateNotification(form)).then(response => {
      GetNotification()
    }).catch(e => {

    })
  }

  const goToTarget = (item) => {
    if(item.type === 'ส่วนลด' && employee.branch_id === 1) history.push(`/notification/${item.id}`); 
  }

  useEffect( () =>{
    // On NOTIFICATIONUPDATE
    if (!isFirstLoad) {
      if (notification && employee && Number(notification.branch_id) === Number(employee.branch_id)) {
        if (name === current) {
          GetNotification()
          const audio = new Audio(NotificationSound);
          audio.play();
        }
      }
    }
    setFirstLoad(false)

  },[notification]);

  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Job Notification "
      >
        <div className="set-height-notification">
          <Row className="justify-content-center">
          {
            notifications?.map((item, index) => {
                return (
                  <>
                    {
                      !exists.includes(CheckGroupDate(item.date)) &&

                      <Col xs={12} className="text-left mt-3 mb-3">
                        <div className="border-title">
                          <Form.Label>{ CheckGroupDate(item.date, true) }</Form.Label>
                        </div>
                      </Col>
                    }

                    <Col key={item.id} xs={11} className="">
                      {/* <LinkContainer
                        to={
                          item.status === 'ส่วนลด' ? `/notification/${item.job_id}` : false
                        }
                      > */}
                        <Row
                          className={
                            `col-body-notification align-items-center ${(item.type === 'ส่วนลด') && 'col-body-notification-active'}`
                          }
                          onClick={() => goToTarget(item) }
                        >
                          <Col xs={7} md={8}>
                            <Row className="">
                              <Col xs={12} md={12} lg={7} xl={6} className="text-left">
                                {
                                  item.type === 'ส่วนลด' &&
                                  <Form.Label>
                                    {item.type} &nbsp;
                                    {item.job?.job_code} &nbsp;
                                  </Form.Label>
                                }

                                {
                                  item.type === 'ส่วนลด' && (item.status === 'อนุมัติเบิก' || item.status === 'ยกเลิกเบิก') &&
                                  <Form.Label>
                                    {item.type} &nbsp;
                                    {item.job?.job_code} &nbsp;
                                  </Form.Label>
                                }
                                {
                                  item.type === 'ใบสั่งซื้อ' &&
                                  <Form.Label>
                                    {item.type} &nbsp;
                                    {item?.order?.order_number !== '' ? item?.order?.order_number : item?.order?.stock_code} &nbsp;
                                  </Form.Label>
                                }
                                {
                                  item.type === 'สินค้า' &&
                                  <Form.Label>
                                    {/* {item.type} &nbsp;0 */}
                                    {item.product?.name} &nbsp;
                                  </Form.Label>
                                }
                              </Col>
                              <Col xs={2} md={4} lg={3} className="text-left">
                                <Form.Label className="font-weight-bold">
                                  <div className="box-status">{item.status}</div>
                                </Form.Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="justify-content-end">
                              <Col xs={6} className="text-right">
                                <Form.Label>{DisplayTime(item.created_at)}</Form.Label>
                              </Col>
                              <Col xs={6} lg={3} xl={2} className="text-right">
                                <Button
                                  onClick={ (e) => { e.stopPropagation();EditNotification(item) }}
                                  className="button-delete"
                                ></Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={8}>
                            <Row>
                              <Col xs={12} md={8} lg={7} xl={5} className="text-left">
                                {
                                  item.type === 'ใบสั่งซื้อ' && item.order && item.order.branche &&
                                  <Form.Label className="font-weight-bold">
                                    {/* {item.job?.} สาขา {item.division} */}
                                    สาขา {item.order?.branche?.name}
                                  </Form.Label>
                                }
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      {/* </LinkContainer> */}
                    </Col>
                  </>
                );
              })
            }
          </Row>
        </div>
        <Row className="justify-content-center mt-5">
          <Col className="d-flex justify-content-center set-paginate">
            <div className="box-paginate">
              {
                !isLoading && <MyPagination totPages={rows} currentPage={search.page} pageClicked={(e) => { setSearch({...search, page: e}) }}></MyPagination>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
