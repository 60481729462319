import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap';
import Service from '../assets/icon/management.png';
import Footer from '../Components/Footer';

import { useHistory , Link} from 'react-router-dom';
import TabsIconJob from '../Components/layout/TabsIconJob';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/add-staff.png';
import Edit from 'assets/icon/key-fix.png';
import SearchImage from 'assets/icon/search.png';
import Return from '../../assets/icon/return-value.png';
import Increase from '../../assets/icon/increase.png';
import Arrow from '../../assets/icon/arrow.png';
import Document from '../../assets/icon/document.png';
import PurchaseOrder from '../../assets/icon/order.png';
import MenuSend from '../../assets/icon/send-file.png';
import { useDispatch, useSelector } from 'react-redux';
import MyPagination from 'Components/MyPagination';
import PenddingApproved from'../../assets/icon-job/pending_approved.png';
import Pending from'../../assets/icon-job/pending.png';
import Approved from'../../assets/icon-job/approved.png';
import Rejected from'../../assets/icon-job/rejected.png';
import Done from'../../assets/icon-job/done.png';
import BillImage from 'assets/icon/job.png'

import { Branch } from 'actions/branch'


//API
import { JobList } from 'actions/jobs'
import dayjs from 'dayjs';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCutStock, setShowCutStock] = useState(false);

  const [statepage, setStatepage] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closed = () => {
    setShow(false);
  };

  const [Search, setSearch] = useState({
    Keyword: '',
    Status: 'all',
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });


  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);
  const [unit, setUnit] = useState([
    { value: 'ซื้อ', label: 'ซื้อ' },
    { value: 'ขาย', label: 'ขาย' },
  ]);
  const [cause, setCause] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);
  const [causeAdjust, setCauseAdjust] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const [type, setType] = useState('');
  const [category, setCategory] = useState('');


  const reset_value = () => {
    setSearch('');
    setType('');
    setCategory('');
  };

  const [isLoading, setLoading] = useState(false);
  const [Jobs,setJobs] = useState([]);

  const handleSelectPage = (e) => setStatepage(e);
  function closeCutStock() {
    setShowCutStock(false);
  }

  function setStorageKey(item){
    localStorage.setItem('key',item)
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['job'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [canShowroomView, setCanShowroomView] = useState(false);
  const getAuthShowRoomCanView = async () => {
    try {
      const params = {
        name: ['showroom'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      // console.log('s1',canShowroomCreate);
      if(response.data.data !== 'Unauthorized'){
        // alert(response.data.message)
        // history.push('/report');
        setCanShowroomView(true);
        // console.log('s2',canShowroomCreate);
      }
    } catch (error) {
      // history.push('/report');
      console.error(error);
    }
  };

  async function getJobs(){
    setLoading(true)
      try {
        const params = {
          keyword: Search.Keyword,
          limit: Search.PerPage,
          page: Search.Keyword === '' ? Search.CurrentPage : 1,
          status: Search.Status,
          branch_id : Search.branch_id,
        };
        const response = await dispatch(JobList(params));
        if(response.data.data && response.data.data.data){
          const bills = response.data.data.data
          setJobs(bills);
          setSearch({ ...Search, 
            Rows: response.data.data.last_page,
            CurrentPage: response.data.data.current_page 
          })
          setLoading(false);
        }else{
          const bills = response.data.data
          setJobs(bills);
          setSearch({ ...Search, 
            Rows: 0,
            CurrentPage: 1 
          })
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    let path = `/`;
    history.push(path);
  }
  const optionStatusJob = [
    { value: 'all', label: 'ทั้งหมด' },
    { value: 'pending_approved', label: 'รออนุมัติ',icon: <img src={PenddingApproved} className="icon-job" /> },
    { value: 'pending', label: 'รอดำเนินการ',icon: <img src={Pending} className="icon-job"/> },
    { value: 'approved', label: 'ดำเนินการ',icon: <img src={Approved} className="icon-job"/> },
    // { value: 'discount_approved', label: 'รออนุมัติส่วนลด',icon: <img src={PenddingApproved} className="icon-job"/> },
    { value: 'rejected', label: 'ยกเลิก',icon: <img src={Rejected} className="icon-job"/> },
    { value: 'done', label: 'เสร็จสิ้น',icon: <img src={Done} className="icon-job"/> },
  ];

  const [optionBranch, setoptionBranch] = useState([]);
  
  const GetBranch = async () => {
    try {
      const res = await dispatch(Branch());
      let data = res.data.data;
      let list = [];
      data.map((i) => {
        if(i.name != 'ระบบกลาง'){
          list.push({value: i.id, label: i.name});
        }
      })
      setoptionBranch(list);
      console.log(optionBranch)
    } catch (e) {
      console.error(e)
    } finally {
      console.log('finally')
    }
  }

  function getThStatus(data){
    switch (data) {
      case 'pending':
        return  <><img src={Pending} className="icon-job" /><label>รอดำเนินการ</label></>
        case 'pending_approved':
        return <><img src={PenddingApproved} className="icon-job" /><label>รออนุมัติ</label></>
        case 'approved':
        return <><img src={Approved} className="icon-job" /><label>อนุมัติ</label></>
        // case 'discount_approved':
        // return <><img src={PenddingApproved} className="icon-job" /><label>รออนุมัติส่วนลด</label></>
        case 'rejected':
        return <><img src={Rejected} className="icon-job" /><label>ยกเลิก</label></>
        case 'done':
        return  <><img src={Done} className="icon-job" /><label>เสร็จสิ้น</label></>
    
      default:
        break;
    }
  }

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({
      Keyword: '',
      Status: 'all',
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
    });

    setClearSearch(true);
  }

  function openOrder(id, type){
    if(type == 'show_room'){
      if(canShowroomView){
        setStorageKey('Order')
        history.push(`/job/order/${id}`);
      }
    }else{
      setStorageKey('Order')
      history.push(`/job/order/${id}`);
    }
  }

  useEffect(() => {
    getAuth();
    getJobs();
    GetBranch();
    getAuthShowRoomCanView();
  }, [Search.PerPage, Search.CurrentPage, ClearSearch ]);
  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIconJob />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={4}
                    xl={3}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อลูกค้า,เลขทะเบียน,ตัวถัง"
                      // defaultValue={statepage[0]}
                      value={Search.Keyword ? Search.Keyword : ''}
                      onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                      className="input"
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      options={optionStatusJob}
                      placeholder="สถานะ JOB"
                      isClearable={false}
                      isSearchable={false}
                      getOptionLabel={e => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {e.icon}
                          <span style={{ marginLeft: 5 }}>{e.label}</span>
                          
                        </div>
                      )}
                      getOptionValue={(option) => option.value}
                      value={Search.Status ? optionStatusJob.find((item) =>{
                        return item.value === Search.Status
                      }): null}
                      onChange={
                        (e) => {
                          setSearch({...Search, Status: e.value });
                        }
                      }
                      className="select-list"
                    />
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      options={optionBranch}
                      placeholder="สาขา"
                      isClearable={false}
                      isSearchable={false}
                      getOptionLabel={e => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginLeft: 5 }}>{e.label}</span>
                        </div>
                      )}
                      getOptionValue={(option) => option.value}
                      value={Search.branch_id ? optionBranch.find((item) =>{
                        return item.value === Search.branch_id
                      }): null}
                      onChange={
                        (e) => {
                          setSearch({...Search, branch_id: e.value });
                        }
                      }
                      className="select-list"
                    />
                  </Col>

                  

                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={getJobs}>
                      <img src={SearchImage} />
                      <p>ค้นหา</p>
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={2}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={ResetSearch}>
                      <img src={Return} />
                      <p>คืนค่า</p>
                    </Button>
                  </Col>
                  
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    className="d-flex justify-content-center mt-2 mb-2 d-sm-none"
                  >
                    <Button className="button-menu" onClick={handleShow}>
                      <img src={Increase} />
                      <p>สร้าง JOB</p>
                    </Button>
                  </Col>
                  <Modal className="modal-style" centered show={show} onHide={handleClose}>
                  <Modal.Header className="modal-header-style">
                    <p>
                      <b>JOB</b>
                    </p>
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closed}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="justify-content-center set-modal-body-job">
                      <Col lg={3}>
                        <Link to="/job/inside-data">
                          <Button type="button" className="button">
                            หน้าร้าน
                          </Button>
                        </Link>
                      </Col>


                      {/* {
                        canShowroomCreate ? (
                          <Col lg={3}>
                            <Link to="/job/company-b2b-show-room">
                              <Button type="button" className="button">
                                Showroom (B2B)
                              </Button>
                            </Link>
                          </Col>
                        ):(
                        ''
                        )
                      } */}

                      <Col lg={12}>
                        <Link to="/job/inside-data">
                          <Button type="button" className="button">
                            ลูกค้าเก่า
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
                </Row>

                <Row className="g-0 justify-content-center mt-3 m-0">
                  <Col className="set-table p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>เลขที่ JOB</th>
                          <th>วันที่</th>
                          {/* <th>ประเภท</th> */}
                          <th>รหัสลูกค้า</th>
                          <th>ชื่อลูกค้า</th>
                          <th>ทะเบียนรถ </th>
                          <th>ข้อมูลรถ</th>
                          <th>ยอดรวม</th>
                          <th>สถานะ</th>
                          <th>พนักงานออก Job</th>
                          <th>มอบหมายงาน</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {
                          Jobs?.map((item,index) => {
                            return(
                              <tr>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item.job_code}</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item?.customer?.customer_code} {item?.type == 'show_room' ? `(${item?.showroom_contact?.customer?.organization})` : ''} </td>
                                {
                                  item.car_owner.cus !== null ?
                                    item.car_owner.cus.first_name === null && item.car_owner.cus.last_name === null ?
                                      item.car_owner.cus.organization !== null ?
                                      <td onClick={() => { openOrder(item.id,item.type) }}>{item.car_owner.cus.organization}</td>
                                      :
                                      <td onClick={() => { openOrder(item.id,item.type) }}>-</td>
                                    :
                                    <td onClick={() => { openOrder(item.id,item.type) }}>{item?.car_owner.cus?.first_name} {item?.car_owner.cus?.last_name} ({item?.car_owner.cus?.nickname})</td>
                                  : <td onClick={() => { openOrder(item.id,item.type) }}>-</td>
                                }
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item?.car_owner?.license_plate} {item?.car_owner?.province?.name_th} ({item?.car_owner?.chassis_number})</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item?.car_owner?.car_serie?.car_model?.model} {item?.car_owner?.car_serie?.year}</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item?.type == 'show_room' ? item.pre_price : item.total_price}</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{getThStatus(item.status)}</td>
                                <td onClick={() => { openOrder(item.id,item.type) }}>{item?.create_by?.employee ? item?.create_by?.employee.fullname : '-'}</td>
                                <td>
                                  <Link to={{pathname: `/job/order/${item.id}` }} 
                                  onClick={() => { setStorageKey('JobAssign')}}
                                  className="text-dark border-bottom">
                                    มอบหมายงาน
                                  </Link>
                                </td>
                                <td>
                                  <Link to={{pathname: `/job/order/${item.id}`}}  
                                     onClick={() => { setStorageKey('Order')}}
                                  >
                                  <Button type="" className="button-package">
                                    <img src={Edit} />
                                    </Button>
                                  </Link>
                                </td>
                            </tr> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="g-0 mt-3 mb-3">
                  <Col sm={6} md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col sm={5} md={6} lg={6}>
                        <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          placeholder="การแสดงผล"
                          options={Pages}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          defaultValue={{ value: 10, label: '10' }}
                          onChange={
                            (e) => {setSearch({...Search, PerPage: e.value });}
                          }
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                  <div className="box-paginate">
                    {
                      !isLoading ? 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                      : 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                    }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
