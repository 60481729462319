import React, { useState} from 'react';
import {
  Container,
  Row,

} from 'react-bootstrap';
import NotificationImage from 'assets/icon/notification.png'
import Footer from 'Components/Footer';
import { useHistory } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import JobDetail from 'MiddleSystems/Notification/Components/Job';

export default function Order() {
  const history = useHistory();

  function close() {
    history.push(`/`);
  }

  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={NotificationImage} />
                  JOB &gt; ภายในร้าน &gt; Order
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={NotificationImage} />
                  JOB &gt; ภายในร้าน &gt; Order
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0 justify-content-center  m-0">
                  <JobDetail />
                </Row>
                <Row className="g-0 justify-content-center mt-3"></Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
