import React, { Component, useState, useEffect } from 'react';
import { Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { Container, Row, Col, Form, Modal, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Search from 'assets/icon/search.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/new-re-turn.png';
import Returnvalue from 'assets/icon/return-value.png'
import { Province, Amphure, District } from 'actions/data/index';
import { CreateCustomer, CheckCustomer } from 'actions/customer/index';
import { SegmentList } from 'actions/settings/segment';
import { Customer } from 'actions/customer/index';
import { loadAuth } from 'actions/auth';

export default function AddOrdinaryPart({ props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const DisplayNumber = async () => {
    if (!Data.SegmentId) return false
    GetNumber();
    setShow(true)
  }

  const GenerateNumber = async () => {
    if (!Data.SegmentId) return false
    GetNumber();
    // setShow(true)
  }

  const close = () => {
    setShow(false);
  };

  const Pad = async (num = 1, size = 6) => {
    num = num.toString();
        num = "1" + num;
    while (num.length < size) num = "0" + num;
    return num
  }
  
  const [GNumber, setGNumber] = useState([]);
  const [FNumber, setFNumber] = useState(null);
  const [PreNumber, setPreNumber] = useState([]);

  const PrepareNumber = async () => {
    let numbers = []
    for (let index = 0; index < 1200000; index++) {
      // numbers.push(await Pad(index+1, 6))
      PreNumber.push(await Pad(index+1, 6))
    }
    // setPreNumber(numbers)
    console.log('Prepare Number', 'success')
    console.log(PreNumber)
  }

  const GetNumber = async () => {

    if(PreNumber.length > 0){
      setGNumber(PreNumber)
      let limit = 4000
      let count = 0
      let result =  PreNumber.filter(item => {
        if(count <= limit){
          count++
          return true
        }else{
          return false
        }
      });
      result = result.filter(val => !SegmentsExist[Data.SegmentId].includes(val));
      console.log(result)
      // setData({...Data, SegmentNumber: result[0]})
      setSegmentNumber(result)
      // setData({...Data, SegmentNumber: result[0]})
      setFNumber(result[0])
      // console.log(FNumber)
      // setData({ ...Data, SegmentNumber: result[0] });
    }else{
      await PrepareNumber()
      GetNumber()
    }
  }

  const [SegmentNumber, setSegmentNumber] = useState([]);
  const [SearchSegment, setSearchSegment] = useState({
    Keyword: ''
  });

  const NumberList = async (e, action) => {
    e.preventDefault();
    if(action === 'reset') setSearchSegment({Keyword: '' })
    
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    const keyword = action !== 'reset' ? SearchSegment.Keyword : ''
    let limit = 9999999
    let count = 0
    let result =  GNumber.filter(item => {
      if(item.includes(keyword.toString()) && count <= limit){
        count++
        return true
      }else{
        return false
      }
    });
    
    result = result.filter(val => !SegmentsExist[Data.SegmentId].includes(val));
    // setData({...Data, SegmentNumber: result[0]})
    // console.log(result)
    setSegmentNumber(result)
  }

  const Prefixes = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const Channels = [
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Offline', label: 'Offline' },
    { value: 'Connection', label: 'Connection' },
    { value: 'ไม่ระบุ', label: 'ไม่ระบุ' }
  ];

  const [validated, setValidated] = useState(false);
  const [Segments, setSegments] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [SegmentsExist, setSegmentsExist] = useState([]);
  const [DataNext, setDataNext] = useState([]);

  const [Data, setData] = useState({
    SegmentId: null,
    SegmentNumber: null,
    Prefix: null,
    FirstName: null,
    LastName: null,
    NickName: null,
    AKA: null,
    CitizenId: null,
    PostCode: null,
    Phones: [],
    ProvinceId: null,
    AmphureId: null,
    Address: null,
    PostCode: null,
    Channel: 'ไม่ระบุ',
    CustomerId: null,
    Note: null,
    SpecialNeed: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    setValidated(true);


    setTimeout(async () => {
      const validator = document.querySelectorAll('.validate-fail');
      if (form.checkValidity() && validator.length === 0) {
        let formData = new FormData();
        formData.append('type', 'บุคคลธรรมดา')
        if(Data.SegmentId) formData.append('segment_id', Data.SegmentId)
        if(Data.SegmentNumber) formData.append('segment_number', Data.SegmentNumber)
        if(Data.Prefix) formData.append('prefix', Data.Prefix)
        if(Data.FirstName) formData.append('first_name', Data.FirstName)
        if(Data.LastName) formData.append('last_name', Data.LastName)
        if(Data.NickName) formData.append('nickname', Data.NickName)
        if(Data.CustomerId) formData.append('recommender', Data.CustomerId)
        if(Data.Address) formData.append('address', Data.Address)
        if(Data.Phone) formData.append('recommender', Data.Phone)
        if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId)
        if(Data.DistrictId) formData.append('district_id', Data.DistrictId)
        if(Data.PostCode) formData.append('postcode', Data.PostCode)
        if(Data.AKA) formData.append('aka', Data.AKA)
        if(Data.Phones) formData.append('phone', JSON.stringify(Data.Phones))
        if(Data.Channel) formData.append('channel', Data.Channel)
        if(Data.Note) formData.append('note', Data.Note)
        if(Data.SpecialNeed) formData.append('special_need', Data.SpecialNeed)
        if(Data.CitizenId) formData.append('citizen_id', Data.CitizenId)
        
        let check = false;
        const res = await dispatch(CheckCustomer(formData));

        if(res.data.msg == '200'){
          check = true;
        }else{

          let text = "ลูกค้าคนนี้ เคยถูกเพิ่มแล้วคุณต้องการเพิ่มซ้ำอยู่ไหม";
          if (window.confirm(text) == true) {
            check = true;
          }
        }

        if(check === true){
          try {
            const response = await dispatch(CreateCustomer(formData));
          
            if(response){
              if(response.data.message == 'บันทึกสำเร็จ'){
                GetProvince();
                GetSegment();
                getAuth();
                // GetNumber();
                alert('บันทึกเสร็จสิ้น')
                setDataNext(response.data.data)
                setData({
                  SegmentId: null,
                  SegmentNumber: null,
                  Prefix: null,
                  FirstName: null,
                  Address: null,
                  LastName: null,
                  NickName: null,
                  AKA: null,
                  CitizenId: null,
                  PostCode: null,
                  Phones: [],
                  ProvinceId: null,
                  AmphureId: null,
                  PostCode: null,
                  Channel: 'ไม่ระบุ',
                  CustomerId: null,
                  Note: null,
                  SpecialNeed: null,
                })
              }else{
                console.log(response)
                if(response.data.message == 'มีรายชื่อนี้แล้ว'){
                  alert(response.data.message);
                }else{
                  let text = '';
                  Object.values(response.data.message).map((item, index) => {
                    if(item.length > 0){
                      item.map(i => {
                        text = text + i + '\n'
                      })
                    }else{
                      text = text + item + '\n'
                    }
                  });
                  alert(text);
                }
              }
            }else{
              console.log('failed')
            }
          } catch (error) {
            console.error(error);
          } finally {
            console.log('finally')
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    GetProvince();
    GetSegment();
    getAuth();
    PrepareNumber();
    // GetNumber();
    // setData({ ...Data, SegmentNumber: '000001' });
  }, []);

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetAmphure = async (data, select = true) => {
    if (select) setData({ ...Data, ProvinceId: data.id, AmphureId: null, DistrictId: null })
    
    const params = {
      ProvinceId: data.id,
    };
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally");
    }
  };

  const GetSegment = (data) => {
    try {
      dispatch(SegmentList()).then(response => {
        setSegments(response.data.data);
        GetCustomer(response.data.data);
      }).catch(e => {
        console.log(e)
      })
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async (segmentData) => {
    let pr = {
      seg: 'seg',
    }
    try {
      const response = await dispatch(Customer(pr));
      
      if(response.data.data){
        const data = response.data.data
        setCustomers(data);
        // let arr = data.map(i => {
        //   if(i.segment_number){
        //     return { segment_id: i.segment_id, segment_number: i.segment_number }
        //   }
        // });
        let arr = []
        segmentData.forEach(segment => {
          arr[segment.id] = data.filter(item => item.segment_id === segment.id).map(i => i.segment_number)
        });

        setSegmentsExist(arr)
      }
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['customerB2C'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/member');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const SetSegmentNumber = (gnumber) => {
    setData({ ...Data, SegmentNumber: gnumber });
    setShow(false);
  }

  function closePage() {
    history.push(`/member`);
  }

  const [Districts, setDistricts] = useState([]);

  const GetDistrict = async (data) => {
    setData({ ...Data, AmphureId: data.id, DistrictId: '', PostCode: '' })
    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }


  return (
    <>
      <Container fluid className="component-create-member">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="row-form-create-memeber ">
            <Col xs={12} lg={3} className=" ">
              <Row className="align-items-center">
                <Col xs={4} lg={5} className="text-left text-lg-right mt-3 mb-3 pr-lg-0">
                  <Form.Label>Segment</Form.Label>
                </Col>
                <Col className="mt-3 mb-3">
                  <Select
                    options={Segments}
                    placeholder="Segment"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={false}
                    value={Data.SegmentId ? Segments.find((data) => {return data.id === Data.SegmentId}) : null}
                    onChange={(data) => { 
                      GenerateNumber();
                      setData({ ...Data, SegmentId: data.id })
                      // GetNumber() 
                      // setData({...Data, SegmentNumber: FNumber})
                      // SetMySegmentNumber(FNumber, data.id)
                    }}
                    className={`select-list ` + (!Data.SegmentId && validated ? 'validate-fail' : '') }
                    required
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className=" pass-segment">
              <Row className="align-items-center">
                <Col xs={4} xl={3} className="mt-3 mb-3 text-left">
                  <Button
                    type="button"
                    onClick={DisplayNumber}
                    className={`pick-segment-button select-list ` + (!Data.SegmentNumber && validated ? 'validate-fail' : '') }
                  >
                    <p className='p-0 m-0'>เลือกรหัส</p>
                  </Button>
                </Col>
                <Col xs={12} sm={8} className="mt-3 mb-3 text-left p-0">
                  <p className="m-0 ml-4">Ex. Segment: {Data.SegmentId && Segments.length > 0 ? Segments.find((data) => {return data.id === Data.SegmentId}).name : null} {Data.SegmentNumber}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={5} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>คำนำหน้าชื่อ</Form.Label>
                </Col>
                <Col className="">
                  <Select
                    options={Prefixes}
                    placeholder="คำนำหน้าชื่อ"
                    isClearable={false}
                    isSearchable={false}
                    required
                    value={Data.Prefix ? Prefixes.find((data) => {return data.value === Data.Prefix}) : null}
                    onChange={(data) => setData({ ...Data, Prefix : data.value })}
                    className={`select-list ` + (!Data.Prefix && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    placeholder="ชื่อ"
                    required
                    value={Data.FirstName}
                    onChange={(e) => setData({ ...Data, FirstName : e.target.value }) }
                    className={!Data.FirstName && validated ? 'validate-fail' : '' }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={4} xl={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} xl={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>นามสกุล</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="นามสกุล"
                    required
                    value={Data.LastName}
                    onChange={(e) => setData({ ...Data, LastName : e.target.value }) }
                    className={!Data.LastName && validated ? 'validate-fail' : '' }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            {/* <Col lg={4} xl={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} xl={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อเล่น</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    required
                    placeholder="ชื่อเล่น"
                    value={Data.NickName}
                    onChange={(e) => setData({ ...Data, NickName : e.target.value }) }
                    className={!Data.NickName && validated ? 'validate-fail' : '' }
                  ></Form.Control>
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={5} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อเล่น</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    // required
                    placeholder="ชื่อเล่น"
                    value={Data.NickName}
                    onChange={(e) => setData({ ...Data, NickName : e.target.value }) }
                    // className={!Data.NickName && validated ? 'validate-fail' : '' }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อAKA</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    // required
                    placeholder="ชื่อAKA"
                    value={Data.AKA}
                    onChange={(e) => setData({ ...Data, AKA : e.target.value }) }
                    // className={`aka-form ` + (!Data.AKA && validated ? 'validate-fail' : '') }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                </Col>
                <Col>
                  <div 
                  // className={(!Data.Phones || Data.Phones.length === 0 && validated ? 'validate-fail' : '') } 
                  >
                    <ReactTagInput
                      placeholder="เบอร์โทรศัพท์"
                      removeOnBackspace={true}
                      maxTags={2}
                      tags={Data.Phones}
                      onChange={(newTags) => setData({ ...Data, Phones : newTags }) }
                      className={`input-tage ` 
                      // + (!Data.Phones || Data.Phones.length === 0 && validated ? 'validate-fail' : '')
                     }
                      // required
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={5} className="text-left pr-lg-0">
                  <Form.Label>เลขบัตรประชาชน/พาสปอร์ต</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="number"
                    placeholder="เลขบัตรประชาชน/พาสปอร์ต"
                    // required
                    value={Data.CitizenId}
                    onChange={(e) => setData({ ...Data, CitizenId : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={4}  className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>จังหวัด</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Select
                    options={Provinces}
                    placeholder="จังหวัด"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={true}
                    defaultValue={Data.ProvinceId}
                    onChange={(data) => { GetAmphure(data) }}
                    // required
                    className={`w-100 select-list ` 
                    // + (!Data.ProvinceId && validated ? 'validate-fail' : '')
                   }
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>อำเภอ/เขต</Form.Label>
                </Col>
                <Col>
                  <Select
                    options={Amphures}
                    placeholder="อำเภอ/เขต"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={true}
                    value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                    onChange={(data) => { GetDistrict(data) }}
                    isDisabled={!Data.ProvinceId}
                    className="select-list w-100"
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ตำบล/แขวง</Form.Label>
                </Col>
                <Col lg={8}>
                  <Select
                    options={Districts}
                    placeholder="ตำบล/แขวง"
                    getOptionLabel={(option) => option.name_th}
                    getOptionValue={(option) => option.id}
                    isClearable={false}
                    defaultValue={Data.DistrictId}
                    value={
                      Data.DistrictId
                        ? Districts.find((data) => {
                          return data.id === Data.DistrictId;
                        })
                        : null
                    }
                    onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : '' }) }}
                    isDisabled={!Data.AmphureId && Districts.length === 0}
                    className={`w-100 select-list `}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>รหัสไปรษณีย์</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Form.Control
                    type="number"
                    placeholder="รหัสไปรษณีย์"
                    value={Data.PostCode}
                    onChange={(e) => setData({ ...Data, PostCode: e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ที่อยู่</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    // required
                    placeholder="ที่อยู่"
                    value={Data.Address}
                    onChange={(e) => setData({ ...Data, Address : e.target.value }) }
                    // className={`aka-form ` + (!Data.Address && validated ? 'validate-fail' : '') }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>ช่องทางการติดต่อ</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Select
                    placeholder="ช่องทางการติดต่อ"
                    options={Channels}
                    isClearable={false}
                    isSearchable={false}
                    value={Data.Channel ? Channels.find((data) => {return data.value === Data.Channel}) : null}
                    onChange={(data) => setData({ ...Data, Channel: data.value }) }
                    className={`w-100 select-list ` + (!Data.Channel && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>ชื่อผู้แนะนำ </Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Select
                    placeholder="ชื่อผู้แนะนำ"
                    options={Customers}
                    getOptionLabel={option => `${option.type === 'โชว์รูม' || option.type === 'นิติบุคคล' ? option.organization : `${option.first_name} ${option.last_name}` }`}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={true}
                    value={Data.CustomerId ? Customers.find((data) => {return data.id === Data.CustomerId}) : null}
                    onChange={(data) => setData({ ...Data, CustomerId: data.id }) }
                    className={`w-100 select-list`}
                    // className={`w-100 select-list ` + (!Data.CustomerId && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={5} className="mt-3 mb-3">
              <Row>
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    as="textarea"
                    placeholder="หมายเหตุ"
                    // required
                    style={{ height: '121px' }}
                    value={Data.Note}
                    onChange={(e) => setData({ ...Data, Note: e.target.value }) }
                    // className={(!Data.Note && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="mt-3 mb-3">
              <Row>
                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ความต้องการพิเศษ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    as="textarea"
                    placeholder="ความต้องการพิเศษ"
                    // required
                    style={{ height: '121px' }}
                    value={Data.SpecialNeed}
                    onChange={(e) => setData({ ...Data, SpecialNeed: e.target.value }) }
                    // className={(!Data.SpecialNeed && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-between mt-5 mb-5">
            <Col xs={6} md={4} lg={3}>
              <Button type="submit" className="button">
                <img src={Save} /> &nbsp; บันทึก
              </Button>
              { DataNext.id ? (<Link to={"edit/"+DataNext.id+"?activeTab=2"}>
                <Button type="button" className="button mt-3">
                  เพิ่มข้อมูลรถ
                </Button>
              </Link>) : null}
            </Col>
            <Col xs={6} md={4} lg={3}>
              <Button type="button" className="button" onClick={closePage}>
                <img src={Return} /> &nbsp; ออก
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      
      <Modal
        className="modal-segment"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-segment">
          <p>
            <b>เลือกรหัส</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={NumberList}>
            <Row className="set-row-search-box">
              <Col lg={3} className="d-flex justify-content-center">
                <Form.Control
                  type="number"
                  placeholder="ค้นหารหัสว่าง"
                  required
                  value={SearchSegment.Keyword ? SearchSegment.Keyword : ''}
                  onChange={(e) => setSearchSegment({Keyword: e.target.value})}
                  className="search-code"
                ></Form.Control>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='submit' className="button-modal-segment">
                  <img src={Search} /> &nbsp; ค้นหา
                </Button>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='button' className="button-modal-segment" onClick={(e) => NumberList(e, 'reset')}><img src={Returnvalue} /> &nbsp; คืนค่า</Button>
              </Col>
            </Row>
          </Form>
          <Row className="set-box-value">
            <Col className="colum-value">
            { 
              SegmentNumber?.map(gnumber => {
                return (
                  <Button type='button' className="put-value-segment" onClick={(e) => SetSegmentNumber(gnumber)} key={gnumber}>{gnumber}</Button>
                )
              })
            }
            </Col>
            ;
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
