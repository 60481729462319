import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Delete from '../../../assets/icon/delete.png';
import { Switch } from 'antd';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { FindPackageType } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';

export default function Protective(props) {

  const dispatch = useDispatch()
  const history = useHistory();
  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  // console.log('parseDataJob', parseDataJob)
  const [Data,setData] = useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    // PackageTypeId: props.data && props.data.PackageTypeId ? props.data.PackageTypeId : null,
    // package_type_name: props.data && props.data.package_type_name ? props.data.package_type_name : null,
    // PackageStorageList: props.data.PackageStorageList.length > 0  ? props.data.PackageStorageList : [],
    // insite_note: props.data && props.data.insite_note ? props.data.insite_note : null,
    // key: props.data && props.data.key ? props.data.key : null,
    // note: props.data && props.data.note ? props.data.note : null,
    // point_coating: props.data && props.data.point_coating ? props.data.point_coating : [],
    // service_now: props.data && props.data.service_now ? props.data.service_now : null,
    // service_more: props.data && props.data.service_more ? props.data.service_more : null,
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);
  

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const optionPosition = [
    {value: 'around_car', label: 'รอบคัน' },
    {value: 'car_head',label: 'หัวรถ'},
    {value: 'handle_hole,',label: 'หลุมมือจับ'},
    {value: 'headlight_set',label: 'ชุดไฟหน้า'},
    {value: 'tail_light_set',label: 'ชุดไฟท้าย'},
    {value: 'set_of_4_stairs',label: 'ชุดบันได 4 จุด'},
    {value: 'front_bumper',label: 'กันชนหน้า'},
    {value: 'bonnet',label: 'ฝากระโปรง'},
    {value: 'front_fender_L+R,',label: 'บังโคลนหน้า L+R'},
    {value: 'side_mirror_L+R',label: 'กระจกมองข้าง L+R'},
    {value: 'door', label: 'ประตู'},
    {value: 'left_front_cheek', label: 'แก้มหน้าซ้าย'},
    {value: 'right_front_cheek', label: 'แก้มหน้าขวา'},
    {value: 'rear_bumper', label: 'กันชนหลัง'},
    {value: 'roof', label: 'หลังคา'},
    {value: 'left_rear_cheek', label: 'แก้มหลังช้าย'},
    {value: 'right_rear_cheek', label: 'แก้มหลังขวา'},
  ];


  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);
  const [PointCoatingPackage,setPointCoatingPackage] = useState([]);

  const [serviceMore, setServiceMore] = useState(true);
  const [fastService, setFastService] = useState(props.data.service_now ? props.data.service_now : false );
  const [sprayCoating, setSprayCoating] = useState(props.data.spray_coating ? props.data.spray_coating : false);
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [typeCoating, setTypeCoating] = useState([{ value: '', label: '' }]);
  const [pointCoatings, setPointCoatings] = useState([]);
  const [PositionUse,setPositionUse] = useState([]);
  const [Other, setOther] = useState([]);
  const [StatusToggle,setStatusToggle] = useState(false)
  const [TextDetails,setTextDetails] = useState('');

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }
  


  const handleSprayCoating = () => {
    setSprayCoating(!sprayCoating);
    // updateLocalStorage(!fastService,null,'spray_coating');
  };

  const handleServiceMore = () => {
    setServiceMore(!serviceMore);
  };
  const handleFastService = () => {
    setFastService(!fastService);
    // updateLocalStorage(!fastService,null,'service_now');
  };

  function SetPosition(data){
    // SetPosition({ value: data.value, label: data.label})
    setPositionUse(data);
  }

  function SetTextDetails(data) {
    setTextDetails(data.target.value);
  }

  function SetSwitch(data) {
    setStatusToggle(data);
    // clear ค่า เลือกตำแหน่ง 
    if(data === false){
      setPositionUse([])
    }
  }

  const StorageModel = {
    key: 0,
    PackageId: '',
    PackageCode: '',
    PackageName: '',
    PackageTypeId: '',
    PackageTypeName: '',
    PointCoatings:'',
    form: 'package',
    Status: 'new',
    Quantity: '',
    ServiceNow: false,
    Other: [],
  }

  function InsertPackage(){
    if(typeCoating.data && typeCoating.data.id){
      // console.log('service.data.category',typeCoating.data);
      let insert = StorageModel
      insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
      insert.PackageId = typeCoating.data.id
      insert.PackageCode = typeCoating.data.code
      insert.PackageName = typeCoating.data.name
      insert.PackageTypeId = props.data.id 
      insert.PackageTypeName = props.data.name
      insert.ServiceNow = fastService
      insert.PointCoatings = pointCoatings
      const CarSize = CarOwner.car_serie.size
      const MiddleSize = typeCoating.data.middle_sizes
      console.log(pointCoatings);
      let resultCarSize = 0;
      pointCoatings.map((p) => {
        let m = MiddleSize.find((item) => {
          return item.size === p.size
        })

        if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
          resultCarSize = resultCarSize + MiddleSize[0].price
        }else{
          let mp = m.price ? m.price : 0;

          resultCarSize = resultCarSize + mp;
        }
        
      });
      insert.Price = resultCarSize
      insert.FilmThickness = null
      insert.TypeWork = null
      insert.PackageType = typeCoating.data.category
      insert.TextDetails = TextDetails
      insert.PositionUse = PositionUse
      insert.Other = Other

      // for Appointment
      insert.CleanService = null
      insert.Treatment = null // TreatmentTime * Quantity
      insert.TreatmentTime = typeCoating.data.treatment_time
      insert.TreatmentRanges = typeCoating.data.treatment_ranges
      insert.Setup = typeCoating.data.setup
      insert.Schedule = typeCoating.data.schedule
      insert.PackageTypeId = typeCoating.data.package_type_id
      setData({ ...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

      // // //for remove select form select list
      // const result = Packages.filter((item)=> {
      //   return item.id !== typeCoating.data.id
      // });
      // setPackages(result);

      // clear select
      setService({ value: '', label: ''})
      setFastService(false)
      setTypeCoating({ value:'', label: ''});
      setPointCoatings('');
      setPositionUse('');
      setTextDetails('')
      setOther([])
      
      const package_list = Data.PackageStorageList
      package_list.push(insert)
      
      parseDataJob.PackageList = package_list;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

      // clear select 
      // ClearSelect()

    }else{
      alert('เลือกรายการ')
    }
  }
  const optionTypeCoating = [{ value: '', label: '' }];
  const optionPointCoating = [{ value: '', label: '' }];

  function SetService(data) {
    setService({ value: data.value, label: data.value });
  }
  function SetTypeCoating(data) {
    setTypeCoating({ value: data.id, label: data.name, data });
    setPointCoatingPackage(data.product_quantities? data.product_quantities : []);
  }
  function SetPointCoating(data) {
    console.log('data',data);
    setPointCoatings(data);
  }
  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'Quantity':
        setData(prevState => ({...Data,
          PackageStorageList: prevState.PackageStorageList.map(
            el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
          )}))
    
        item.Quantity = Number(data.target.value)
        break;
      case 'note':
        setData({...Data, note: data.target.value })
        Data.note = data.target.value
        break;
      case 'insite_note':
        setData({...Data, insite_note: data.target.value })
        Data.insite_note = data.target.value
        break;
        case 'things_in_car':
          setData({...Data, things_in_car: data })
          Data.things_in_car = data
          break;
        case 'defect_car':
          setData({...Data, defect_car: data })
          Data.defect_car = data
          break;
        case 'area':
          setData({...Data, area: data.target.value })
          Data.area = data.target.value
          break;
      // case 'service_now':
      //   setData({...Data, service_now: data })
      //   Data.service_now = data
      //   break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = result;
    parseDataJob.note = Data.note
    parseDataJob.insite_note = Data.insite_note
    parseDataJob.area = Data.area
    parseDataJob.things_in_car = Data.things_in_car
    parseDataJob.defect_car = Data.defect_car
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.package_id
    })
    Packages.push(result);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('item',item);
    if(item.Status ==='new' && item?.PositionUse !== null && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){

    if(item.Status ==='new' && item?.PositionUse !== null && item.PositionUse.length > 0 && item.PointCoatings.length === 0){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  function getDisplayOther(item){

    if(item.Status ==='new' && item.Other && item.Other.length > 0){
      const result = item?.Other.map((item) => {
        return item.value
      }).join(", ")
  
      return result ? 'บริเวณอื่นๆ : ' + result : ''
    }else{
      return ''
    }
    
  }

  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[])
  return (
    <>
      <Form>
        <Container fluid className="pl-3 pr-3">
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                {/* <Col lg={10}>
                  <Select
                    options={optionService}
                    placeholder=""
                    isClearable={false}
                    isSearchable={false}
                    onChange={(data) => SetService(data)}
                    className="select-list"
                  />
                </Col> */}
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-end">
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={fastService}
                      className="checkbox-input mt-3"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 justify-content-start align-items-start">
            {/* <Col xs={6} sm={5} md={4} lg={4} xl={3} className="">
              <Form.Check
                type="checkbox"
                label=" พ่นเคลือบหลังติดตั้ง"
                onChange={handleSprayCoating}
                checked={sprayCoating}
                className="checkbox-input"
              />
            </Col> */}
            <Col xs={6} sm={5} md={4} lg={4} xl={3} className=" d-lg-none">
              {/* <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={fastService}
                className="checkbox-input"
              /> */}
            </Col>
          </Row>
          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Select
                options={Packages}
                getOptionValue={(option)=> option.id}
                getOptionLabel={(option) => option.name}
                value={typeCoating.value ? Packages?.find((data) => {return data.PackageId === typeCoating.value}) : '' }
                placeholder="เลือกประเภทการเคลือบ"
                isClearable={false}
                isSearchable={false}
                onChange={(data) => SetTypeCoating(data)}
                className="select-list"
              />
            </Col>
            <Col lg={5} className="mt-2 mb-2">
              <Select
                options={PointCoatingPackage}
                getOptionValue={(option)=> option.id}
                getOptionLabel={(option) => option.size}
                value={pointCoatings ? PointCoatingPackage.find((data) => { return data.id === pointCoatings.id}) : ''}
                defaultValue={pointCoatings}
                isMulti
                placeholder="เลือกจุดที่ต้องการเคลือบ"
                isClearable={false}
                isSearchable={false}
                isDisabled={PointCoatingPackage.length < 1 ? true :false || StatusToggle === true}
                onChange={(data) => SetPointCoating(data)}
                className="select-list"
              />
            </Col>
          </Row>

          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
            <Row>
                <Col xs={12} className="text-left mt-3 mb-3 ml-4">
                  <Switch checked={StatusToggle} onClick={(data) =>SetSwitch(data)} />
                  &nbsp;
                  <Form.Label>
                  <b>{StatusToggle === true ? 'บริเวณที่ติดตั้ง' : 'เลือกจุดที่ต้องการเคลือบ'}</b>
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="mt-2 mb-2">
              
            </Col>
          </Row>

          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Select
                  isMulti
                  placeholder="บริเวณที่ติดตั้ง"
                  options={optionPosition}
                  value={PositionUse.length > 0 ? PositionUse : ''}
                  onChange={(data) => SetPosition(data)}
                  isDisabled={StatusToggle === false}
                  className={`select-list ` }
                />
            </Col>
            <Col lg={5} className="mt-2 mb-2">
                
            </Col>
          </Row>

          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Creatable
                placeholder="อื่นๆ"
                isClearable
                isMulti
                options={Other}
                value={Other}
                onChange={(data) => setOther(data)}
              />
            </Col>
            <Col lg={5} className="mt-2 mb-2">
                
            </Col>
          </Row>

          <Row className="g-0 justify-content-start mt-2">
            <Col lg={5} className="mt-2 mb-2">
              <Form.Label>รายละเอียด</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder=""
                      className="input-area"
                      rows={5}
                      value={TextDetails}
                      onChange={(e) => SetTextDetails(e)}
                    />
            </Col>
            <Col lg={5} className="mt-2 mb-2">
              
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col lg={3} xl={2} className="mt-3 mb-3">
                <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                    เพิ่ม
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td className="text-start">{item.PackageName} 
                            <p className="m-0">
                              { getTextByPackage(item) }
                            </p>
                            <p className="m-0">
                              { getDisplayTextPositionCoating(item) }
                            </p>
                            <p className="m-0">
                              { getDisplayTextPositionUse(item)}
                            </p>
                            <p className="m-0">
                              { getDisplayOther(item) }
                            </p>
                            <p className="m-0">
                              { item.TextDetails }
                            </p>
                          </td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            type="number"
                            required
                            disabled={item.Status === 'appointment'}
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type="" className="button-package"
                             disabled={item.Status === 'appointment'}
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="set-row-button-create justify-content-center">
            <Col sm={4} lg={2}>
              <Button type="button" className="button">
                สร้าง
              </Button>
            </Col>
          </Row> */}
        </Container>
      </Form>
    </>
  );
}
