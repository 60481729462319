import React, { useState } from 'react';

import { useDispatch } from 'react-redux';


import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Table,
  Modal
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import Note from 'assets/icon/note-car.png';
import ViewData from 'assets/icon/view-data.png';
import Delete from 'assets/icon/delete.png';
import Select, { components } from 'react-select';
import dayjs from 'dayjs';

import { destroyProperty } from 'actions/property' 

export default function Property(props) {

  const history = useHistory();
  const dispatch = useDispatch();
  
  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState([]);

  const [page, setPage] = useState([
    {
      value: 0,
      label: 'ทั้งหมด',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
  ]);

  const deleteCarType = async (id) => {

    try {
      const response = await dispatch(destroyProperty(id));

      if (response) {
        props.PropertyState.splice(deleting.index, 1);
      } else {
        console.log('failed');
      }
      setDeleting([]);
      setConfirmation(false);
    } catch (error) {
      console.error(error);
      setDeleting([]);
      setConfirmation(false);
    } finally {
      console.log('finally');
      setDeleting([]);
      setConfirmation(false);
    }
  };

  function deleteConfirmation(id, index) {
    const value = {
      id: id,
      index: index,
    }
    setDeleting(value)
    setConfirmation(true)
  }

  return (
    <>
      <Row className="g-0 justify-content-center ">
        <Col className=" mt-3 " style={{ overflow: 'scroll', height: '500px' }}>
          <Table className="table table-responsive table-wash-part ">
            <thead>
              <tr className="t-header-package">
                <th>ชื่อทรัพย์สิน</th>
                <th>จำนวน</th>
                <th>สถานที่เก็บ</th>
                <th>บันทึกโดย</th>

                <th>วันที่อัพเดท</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="t-body-package ">
              {
                props.PropertyState.map( (item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{ item.name}</td>
                      <td>{ item.amount ? item.amount : 0}</td>
                      <td>{ item.prop_information && item.prop_information.property_storage?.map((value) => {
                          return (
                            <p>{`${value.placement_point}`} สาขา {value.branch && value.branch.name}</p>
                          )
                        })}</td>
                      <td>{ item.prop_information && item.prop_information.bought_by}</td>
                      <td>{ dayjs(item.updated_at).format('DD-MM-YYYY HH:mm')}</td>
                      <td className="set-button-table-management">
                        <Link to={{
                          pathname:`property/data-property-edit/${item.id}`
                        }}>
                          <Button type="" className=" button-package m-2">
                            <img src={ViewData} />
                          </Button>
                        </Link>
                        <Button type="" className=" button-package m-2" onClick={() => deleteConfirmation(item.id, index)}>
                          <img src={Delete} />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยัน</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบรายการหรือไม่</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => deleteCarType(deleting.id) }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <Row className="g-0 mt-3 mb-3">
        <Col lg={5} className=" set-page">
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <p style={{ margin: 0 }}>แสดง</p>
            </Col>
            <Col lg={7}>
              <Select
                className="text-left select-style"
                aria-label="Default select example"
                placeholder=""
                options={page}
              />
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <p style={{ margin: 0 }}>รายการ</p>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-content-end set-paginate">
          <div className="box-paginate">
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item>{1}</Pagination.Item>

              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item active>{3}</Pagination.Item>

              <Pagination.Ellipsis />
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          </div>
        </Col>
      </Row> */}
    </>
  );
}
