import React, { useState, useRef, useEffect } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Dropdow,
  Pagination,
} from 'react-bootstrap';

import Select from 'react-select';
import note_car from 'assets/icon/note-car.png';
import add_menu from 'assets/icon/add_menu.png';
import View from 'assets/icon/view-data.png';
import SearchImage from 'assets/icon/search.png'
import MyPagination from 'Components/MyPagination'
import Returnvalue from 'assets/icon/return-value.png'

import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { PackageHistoryCustomer } from 'actions/customer/index';
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import dayjs from 'dayjs';

export default function MemberPackage() {

  const dispatch = useDispatch();
  const { id } = useParams();
  const [CarBrands, setCarBrands] = useState([]);
  const [CarModels, setCarModels] = useState([]);

  const [search, setSearch] = useState({
    Keyword: null,
    customer_id: id,
    CarBrandId: null,
    CarModelId: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const [packageHistory, setPackageHistory] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);

  const [stateplateSearch, setStateplateSearch] = useState({
    plateSearch: 'not plate',
  });

  const [Pages, setPage] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setCarBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setCarModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetPackageHistoryCustomer = (params) => {
    setLoading(true);
    const newParams = {
      keyword: search.Keyword,
      car_brand_id: search.CarBrandId,
      car_model_id: search.CarModelId,
      customer_id: id,
      limit: search.PerPage,
      page: search.CurrentPage
      
    }

    dispatch(PackageHistoryCustomer(newParams)).then(response => {
        
      if(search.PerPage > 0){
        // console.log(response.data.data.data);
        setPackageHistory(response.data.data.data);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        // console.log(response.data.data);
        setPackageHistory(response.data.data);
        setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
      
    }).catch(e => {
      console.log(e);
    })
  }

  function ChangeModel(data) {
    setSearch({...search, CarBrandId: data.id })
    GetCarModel(data.id)
  }
  
  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...search, 
      Keyword: null,
      customer_id: id,
      CarBrandId: null,
      CarModelId: null,
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
    })

    setClearSearch(true)
  }
  // const search = () => {};

  useEffect(() => {
    GetBrand();
  }, []);

  useEffect(() => {
    GetPackageHistoryCustomer();
  }, [search.PerPage, search.CurrentPage]);

  useEffect(() => {
    if (!ClearSearch) GetPackageHistoryCustomer();
    setClearSearch(false)
  }, [search.PerPage, ClearSearch]);

  

  return (
    <>
      <div className="member-package">
        <br />

        <div className="align-items-center">
          <Row className="justify-content-start align-items-center mb-3 ">
            <Col xs={12} xl={2} className="set-padding-search mt-2 mb-2">
              <Form.Control
                id="_input_search"
                className="input"
                type="text"
                placeholder="ทะเบียนรถ"
                value={search.Keyword ? search.Keyword : ''}
                onChange={(e) => setSearch({ ...search, Keyword: e.target.value })}
              ></Form.Control>
            </Col>

            <Col xs={12} xl={2} className="mt-2 mb-2 text-left">
              <Label className="search-label d-block d-lg-none">ยี่ห้อรถ</Label>
              <Select
                options={CarBrands}
                placeholder="ยี่ห้อรถ"
                className="select-add-member"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={search.CarBrandId ? CarBrands.find((data) => {return data.id === search.CarBrandId}) : null}
                onChange={(data) => ChangeModel(data)}
              />
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2 text-left">
              <Label className="search-label d-block d-lg-none">รุ่นรถ</Label>
              <Select
                options={CarModels}
                placeholder="รุ่นรถ"
                className="select-add-member"
                getOptionLabel={option => `${option.model}-${option.car_serie.code}`}
                getOptionValue={option => option.id}
                isDisabled={!search.CarBrandId}
                value={search.CarModelId ? CarModels.find((data) => {return data.id === search.CarModelId}) : null}
                onChange={(data) => setSearch({...search, CarModelId: data.id })}
              />
            </Col>

            <Col xs={12} xl={2} className="mt-2 mb-2">
              <Button
                className="button"
                style={{ padding: 0, margin: 0 }}
                onClick={() => GetPackageHistoryCustomer()}
              >
                ค้นหา &nbsp; <img src={SearchImage} alt="" />
              </Button>
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2">
              <Button 
                className="button"
                onClick={ResetSearch}
              >
                คืนค่า &nbsp; <img src={Returnvalue} alt="" />
              </Button>
            </Col>
          </Row>
        </div>

        <Row>
          <Col className="" style={{ Overflow: 'scroll', height: '440px' }}>
            <Table responsive className="custom-table-pacekage text-nowrap ">
              <thead>
                <tr>
                  <th>ยี่ห้อ</th>
                  <th>รุ่น</th>
                  <th>ทะเบียน</th>
                  <th>สี</th>
                  <th>แพคเก็จ</th>
                  <th>รับประกัน</th>
                  <th>สถานะ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  packageHistory.map(data => {
                    var numdays = data.job_detail.package.period;
                    if(data.job_detail.package.period_type == 'ปี'){
                      numdays = numdays * 365;
                    }else if(data.job_detail.package.period_type == 'เดือน') {
                      numdays = numdays * 30;
                    }
                    var enddays = Math.round((dayjs(data.job_detail.job.bill === null ? data.job_detail.job.updated_at : data.job_detail.job.bill.updated_at).add(numdays, 'days') - dayjs())/(1000*60*60*24));
                    return (
                      <tr key={ data.id }>
                        <td>{ data.car_owner.car_serie.car_model.car_brand.name }</td>
                        <td>{ data.car_owner.car_serie.car_model.model }</td>
                        <td>{ data.car_owner.license_plate + ' ' + data.car_owner.province.name_th }</td>
                        <td>{ data.car_owner?.color?.name ? data.car_owner.color.name : '-' }</td>
                        <td>{ data.job_detail.package.name }</td>
                        <td>{ dayjs(data.job_detail.job.bill === null ? data.job_detail.job.updated_at : data.job_detail.job.bill.updated_at).format('DD/MM/YYYY') } ( { enddays }วัน )</td>
                        <td>{ enddays <= 0 ? 'หมดอายุประกัน' : 'อยู่ในประกัน' }</td>
                        <td>
                          <Link to={`/job/order/${data.job_detail.job.id}`}>
                            <Button type="" className="button-image">
                              <img src={View} />
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="g-0 mt-3 mb-3">
          <Col lg={4} className=" set-page">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>แสดง</p>
              </Col>
              <Col lg={7}>
                <Select
                  className="text-left select-style"
                  aria-label="Default select example"
                  options={Pages}
                  onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                  defaultValue={{ value: 10, label: '10' }}
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>รายการ</p>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex justify-content-end set-paginate">
            <div className="box-paginate">
              {
                !isLoading ? 
                  search.Rows > 1 && search.PerPage > 0 ?
                    <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                  : ''
                : 
                  search.Rows > 1 && search.PerPage > 0 ?
                    <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                  : ''
              }
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
