import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal
} from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';

// Custom Conponent
import TabsIconProduct from 'MiddleSystems/Components/layout/TabsIconProduct';
import Footer from 'Components/Footer';
import MyPagination from 'Components/MyPagination'

// Icon
import SearchIcon from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import Add from 'assets/icon/add-staff.png';
import Edit from 'assets/icon/key-fix.png';
import Back from 'assets/icon/back.png';
import Management from 'assets/icon/management.png';
import Delete from 'assets/icon/delete.png';
import ProductImage from 'assets/icon/product-manage.png'

// css
import 'assets/css/app.css';
import 'assets/css2/css/app.css';
import 'assets/css2/css/modal.css';
import 'assets/css2/css/employee.css';
import 'assets/css2/css/responsive.css';
import 'assets/css2/css/input.css';
import 'assets/css2/css/table.css';
import 'assets/css2/css/mr.kleane.css';
import 'assets/scss/style-component.scss';

// API
import { ProductList, DestroyProduct } from 'actions/product';
import { CategoryList } from 'actions/settings/category';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setisLoading] = useState(false);
  const [Rows, setRows] = useState(0);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const SearchModel = {
    Keyword: '',
    type: null,
    ProductCategoryId: null,
    PerPage: 10,
    CurrentPage: 1,
    Row: 0,
  }
  const [Search, setSearch] = useState(SearchModel);

  const ProductType = [
    {
      label: 'ขาย',
      value: 'ขาย'
    },
    {
      label: 'ใช้งาน',
      value: 'ใช้งาน'
    },
    {
      label: 'ขายและใช้งาน',
      value: 'ขายและใช้งาน'
    }
  ]

  const [Products, setProducts] = useState([]);
  const [ProductCategories, setProductCategories] = useState([]);
  const [ClearSearch, setClearSearch] = useState(false);

  const GetProduct = (data) => {
    setisLoading(true)
    const params = {
      keyword: Search.Keyword,
      type: Search.type,
      product_category_id: Search.ProductCategoryId,
      limit:  Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1
    }

    dispatch(ProductList(params)).then(response => {
      if (Search.PerPage) {
        setProducts(response.data.data.data);
        setRows(response.data.data.last_page);
        setisLoading(false)
      }else{
        setProducts(response.data.data);
        setRows(0);
        setisLoading(false)
      }
    }).catch(e => {
      console.log(e)
    })
  }
  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการสินค้า'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };
  
  const GetCategory = async () => {
    dispatch(CategoryList()).then(response => {
      setProductCategories(response.data.data);
    }).catch(e => {
      history.push('/');
    })
  }


  useEffect(() => {
    GetProduct();
    GetCategory()
    getAuth();
  }, []);
  
  useEffect(() => {
    if (!ClearSearch) GetProduct();
    setClearSearch(false)
  }, [Search.PerPage, Search.CurrentPage, ClearSearch]);

  const ResetSearch = () => {
    setSearch(SearchModel)
    setClearSearch(true)
  }

  function close() {
    history.push(`/`);
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);
  function deleteConfirmation(product) {
    setDeleting(product)
    setConfirmation(true)
  }

  const DeleteProduct = () => {
    dispatch(DestroyProduct(deleting.id)).then( response => {
      GetProduct()
      setDeleting(null)
      setConfirmation(false)
    }).catch(e => {
      alert('สินค้านี้กำลังถูกใช้งานอยู่ไม่สามารถลบได้');
      console.log(e)
    })
  }

  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div>
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={ProductImage} />
                    <b>วัสดุอุปกรณ์(สินค้า)</b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={ProductImage} />
                    <b>วัสดุอุปกรณ์(สินค้า)</b>
                  </h5>
                </div>
                <div className="modal-body set-menu-productmanage">
                  <Row className="g-0 tabsicon justify-content-center ">
                    <TabsIconProduct />
                  </Row>
                  <Row className="g-0 justify-content-center mb-3">
                    <Row className="g-0">
                      <Col lg={4} className="d-flex justify-content-center mt-2 mb-2">
                        <Form.Control
                          type="text"
                          placeholder="ค้นหาด้วยรหัสสินค้า, ชื่อสินค้า"
                          className="input"
                          value={Search.Keyword ? Search.Keyword : ''}
                          onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                        ></Form.Control>
                      </Col>
                      <Col xs={12} lg={2} className=" my-auto mt-2 mb-2 set-dropdown-division">
                        <Select
                          placeholder="ประเภทสินค้า"
                          options={ProductType}
                          value={Search.type ? ProductType.find((item) => item.label === Search.type) : null}
                          onChange={(data) => setSearch({...Search, type: data.label})}
                          className="select-list"
                        />
                      </Col>
                      <Col xs={12} lg={2} className=" my-auto mt-2 mb-2 set-dropdown-division">
                        <Select
                          options={ProductCategories}
                          placeholder="หมวดหมู่"
                          isClearable={false}
                          isSearchable={false}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          value={Search.ProductCategoryId ? ProductCategories.find((item) => item.id === Search.ProductCategoryId) : null}
                          onChange={(data) => setSearch({...Search, ProductCategoryId: data.id})}
                          className="select-list"
                          isDisabled={ProductCategories.length === 0}
                          
                        />
                      </Col>
                      <Col xs={12} lg={2} className="d-flex justify-content-center mt-2 mb-2">
                        <Button className="button-menu" onClick={() => GetProduct()}>
                          <img src={SearchIcon} /> &nbsp; ค้นหา
                        </Button>
                      </Col>
                      <Col xs={6} lg={2} className="d-flex justify-content-center mt-2 mb-2">
                        <Button className="button-menu" onClick={ResetSearch}>
                          <img src={Return} /> &nbsp; คืนค่า 
                        </Button>
                      </Col>
                      <Col xs={6} className=" mt-2 mb-2 d-md-none">
                        <Link to="/product/add">
                          <Button className="button-menu">
                            <Row className="justify-content-center align-items-center">
                              <Col className="p-0 d-flex justify-content-end">
                                <img src={Add} />
                              </Col>
                              <Col className="p-0 d-flex justify-content-start">
                                <p>เพิ่มวัสดุ/อุปกรณ์</p>
                              </Col>
                            </Row>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="g-0 justify-content-center m-0">
                    <Col className="set-table-stock p-0">
                      <Table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            {/* <th>รหัสวัสดุ/อุปกรณ์</th> */}
                            <th>ชื่อวัสดุ/อุปกรณ์</th>
                            <th>หมวดหมู่</th>
                            <th>ประเภท</th>
                            <th>จุดสั่งซื้อ</th>
                            <th>จุดต่ำ</th>
                            <th>หมายเหตุ</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                          {
                            Products?.map((product, key) => {
                              return(
                                <tr key={key}>
                                  {/* <td>{product.code}</td> */}
                                  <td>{product.name}</td>
                                  <td>{product.category?.name}</td>
                                  <td>{product.type}</td>
                                  <td>{product.reorder_point}</td>
                                  <td>{product.minimum_quantity}</td>
                                  <td>{product.note}</td>
                                  <td>
                                    <Link to={`/product/${product.id}/edit`}>
                                      <Button className="view-button">
                                        <img src={Edit} alt="" />
                                      </Button>
                                    </Link>
                                  </td>
                                  <td>
                                    <Button
                                      className="button-package"
                                      onClick={(e) =>
                                        deleteConfirmation(product)
                                      }
                                    >
                                      <img src={Delete} />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row className="g-0 mt-3 mb-3">
                    <Col lg={5} className=" set-page">
                      <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                          <p style={{ margin: 0 }}>แสดง</p>
                        </Col>
                        <Col lg={7}>
                          <Select
                            className="text-left select-style"
                            aria-label="Default select example"
                            placeholder="ทั้งหมด"
                            options={Pages}
                            isSearchable={false}
                            value={Search.PerPage ? Pages.find((data) => {return data.value === Search.PerPage}) : null}
                            onChange={(data) => setSearch({...Search, PerPage: data.value })}
                            defaultValue={{ value: 10, label: '10' }}
                          />
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <p style={{ margin: 0 }}>รายการ</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="d-flex justify-content-end set-paginate">
                      <div className="box-paginate">
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ลบวัสดุ/อุปกรณ์ { `${deleting?.name}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยินยันการลบวัสดุ/อุปกรณ์ { `${deleting?.name}` }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteProduct() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
