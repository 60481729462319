import React, { useState, useEffect } from 'react';
import { useHistory, useParams , Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';

// Icon
import Setting from 'assets/icon/setting.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/getout.png';
import Delete from 'assets/icon/delete.png';
import increate from 'assets/icon/add-staff.png';
import Back from 'assets/icon/back.png';

// API
import { CarBrandList, FindCarBrand, CreateCarBrand, UpdateCarBrand } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { CarSerieList } from 'actions/settings/car_serie';
import { CarTypeList } from 'actions/settings/car_type';
import { loadAuth } from 'actions/auth';

// Plugin
var dayjs = require('dayjs')

export default function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const InputModel = {
    CarModel: null,
    CarSerie: null,
    CarType: null,
    CarSize: null,
    CarYear: null
  };

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  useEffect(() => {
    ShowCarBrand();
    // GetCarBrand();
    GetCarType();
    getAuth({name:'ตั้งค่า',permission:'edit'});
  }, []);

  
  const [Data, setData] = useState({
    CarBrand: null,
    Value: [
      InputModel
    ],
    Destroys: []
  });

  const [CarBrands, setCarBrands] = useState([]);
  const [CarModels, setCarModels] = useState([]);
  const [CarSeries, setCarSeries] = useState([]);
  const [CarType, setCarType] = useState([]);

  const ShowCarBrand = async (data) => {
    try {
      const response = await dispatch(FindCarBrand(id));

      const value = []
      response.data.data.car_models?.forEach(model => {
        value.push(
          {
            CarModel: model,
            CarSerie: model?.car_serie,
            CarType: model?.car_serie?.car_type,
            CarSize: model?.car_serie?.size,
            CarYear: model?.car_serie?.year
          }
        )
      });
      setData({
        CarBrand: response.data.data,
        Value: value.length > 0 ? value : [InputModel],
        Destroys: []
      })
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  // const GetCarBrand = async (data) => {
  //   try {
  //     const response = await dispatch(CarBrandList());
  //     setCarBrands(response.data.data)
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     console.log('finally')
  //   }
  // }

  // const GetCarModel = async (car_brand_id) => {
  //   const params = {
  //     car_brand_id
  //   }

  //   try {
  //     const response = await dispatch(CarModelList(params));
  //     setCarModels(response.data.data)
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     console.log('finally')
  //   }
  // }

  const GetCarSerie = async (car_model_id) => {
    const params = {
      car_model_id
    }

    try {
      const response = await dispatch(CarSerieList(params));
      setCarSeries(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarType = async (data) => {
    try {
      const response = await dispatch(CarTypeList());
      setCarType(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const Years = []
  let YearRange = (dayjs().year() - 100);
  const CurrentYear = dayjs().year()
  for (let index = YearRange; index <= CurrentYear; index++) {
    Years.push({ text: index, value: index })
  }
  Years.reverse();

  const CarSizes = [{
    id: 'S',
    name: 'S'
  }, {
    id: 'M',
    name: 'M'
  }, {
    id: 'L',
    name: 'L'
  }, {
    id: 'TRUCK CAB',
    name: 'TRUCK CAB'
  }, {
    id: 'SUV',
    name: 'SUV'
  }, {
    id: 'TRUCK 4 DOOR',
    name: 'TRUCK 4 DOOR'
  }, {
    id: 'XL',
    name: 'XL'
  }, {
    id: 'VAN',
    name: 'VAN'
  }];

  // function SetCarBrandValue(data) {
  //   if(!data){
  //     setData({...Data, CarBrand: null})
  //   }else if (typeof data.id === 'string' || data.id instanceof String){
  //     setData({...Data, CarBrand: data})
  //   }else{
  //     setData({...Data, CarBrand: data});
  //   }
  // }

  function SetCarModelValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarModel: null };
      setData({...Data, Value: items});
    }else if (typeof data.id === 'string' || data.id instanceof String){
      items[index] = {...items[index], CarModel: data};
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarModel: data};
      setData({...Data, Value: items});
    }
  }

  function SetCarSerieValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarSerie: null };
      setData({...Data, Value: items});
    }else if (typeof data.id === 'string' || data.id instanceof String){
      items[index] = {...items[index], CarSerie: data};
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarSerie: data};
      setData({...Data, Value: items});
      GetCarSerie(data.id)
    }
  }

  function SetCarTypeValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarType: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarType: data};
      setData({...Data, Value: items});
    }
  }

  function SetCarSizeValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarSize: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarSize: data.name};
      setData({...Data, Value: items});
    }
  }

  function SetCarYearValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarYear: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarYear: data.value};
      setData({...Data, Value: items});
    }
  }

  const [validated, setValidated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const data = {
      CarBrand: Data.CarBrand,
      value: Data.Value,
      destroys: Data.Destroys
    }

    const result = {
      id: id,
      data
    }

    try {
      const response = await dispatch(UpdateCarBrand(result));
      if (response) {
        const data = response.data.data
          ShowCarBrand();
          setValidated(false);
          history.push('/setting/car-brand');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function removeColumn (index, value) {

    if(value.CarModel && value.CarModel.id){
      const destroys = Data.Destroys
      destroys.push(value.CarModel.id)
      setData({ ...Data, Destroys: destroys });
    }

    setData({ ...Data, Value: Data.Value.filter(function(item, key) { 
      return key !== index
    })});
  }

  function close() {
    history.push(`/`);
  }

  function exit() {
    history.push('/setting/car-brand');
  }

  return (
    <>
      <Container fluid className="package-container Service Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/car-brand" className='link-pagination'>ยี่ห้อรถ/รุ่นรถ</Link> &gt; แก้ไขรายการรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/car-brand" className='link-pagination'>ยี่ห้อรถ/รุ่นรถ</Link> &gt; แก้ไขรายการรถ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="g-0  m-0 mt-3 mb-3 justify-content-center">
                    <Col className="text-left  set-bg d-flex align-items-center">
                      <Form.Label className="text-size">
                        แก้ไขรายการรถ
                      </Form.Label>
                    </Col>
                  </Row>
                  <div className="add-car-height">
                    <Row>
                      <Col lg={12} className="text-left mt-3 mb-3">
                        <Form.Label className="text-size  ml-3">
                          ยี่ห้อ
                        </Form.Label>
                      </Col>
                      <Col lg={5} className="text-left  mb-3 style-input-tag">
                        <Form.Control
                          placeholder="ยี่ห้อ"
                          required
                          value={Data.CarBrand && Data.CarBrand.name}
                          onChange={(e) =>
                            setData({ ...Data, CarBrand: { name: e.target.value } })
                          }
                        ></Form.Control>
                        {/* <Creatable
                          placeholder="ยี่ห้อ"
                          isClearable
                          options={CarBrands}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: optionLabel
                          })}
                          value={Data.CarBrand}
                          onChange={(data) => SetCarBrandValue(data)}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      { 
                        Data.Value?.map( (value, key) => {
                          return (
                            <Col
                              sm={12}
                              lg={12}
                              key={`mainfield-operation-value-${key}`}
                            >
                              <Row className="align-items-center">
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    รุ่นรถ
                                  </Form.Label>

                                  <Row>
                                    <Creatable
                                      placeholder="รุ่นรถ"
                                      isClearable
                                      options={CarModels}
                                      getOptionLabel={option => option.model}
                                      getOptionValue={option => option.id}
                                      getNewOptionData={(inputValue, optionLabel) => ({
                                        id: value.CarModel && value.CarModel.id ? value.CarModel.id : null,
                                        model: optionLabel,
                                      })}
                                      value={value.CarModel}
                                      onChange={(data) => SetCarModelValue(key, data)}
                                    />
                                  </Row>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    รหัสรุ่นรถ
                                  </Form.Label>
                                  <Creatable
                                    placeholder="รหัสรุ่นรถ"
                                    isClearable
                                    options={CarSeries}
                                    getOptionLabel={option => option.code}
                                    getOptionValue={option => option.id}
                                    getNewOptionData={(inputValue, optionLabel) => ({
                                      id: value.CarSerie && value.CarSerie.id ? value.CarSerie.id : null,
                                      code: optionLabel,
                                    })}
                                    value={value.CarSerie}
                                    onChange={(data) => SetCarSerieValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ประเภทรถ
                                  </Form.Label>
                                  <Creatable
                                    placeholder="ประเภทรถ"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={CarType}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    getNewOptionData={(inputValue, optionLabel) => ({
                                      id: value.CarType && value.CarType.id ? value.CarType.id : null,
                                      name: optionLabel,
                                    })}
                                    value={value.CarType}
                                    onChange={(data) => SetCarTypeValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ขนาดรถ
                                  </Form.Label>
                                  <Select
                                    placeholder="ขนาดรถ"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={CarSizes}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    required
                                    value={value.CarSize ? { id: value.CarSize, name: value.CarSize } : null}
                                    onChange={(data) => SetCarSizeValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ปี
                                  </Form.Label>
                                  <Select
                                    placeholder="ปี"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={Years}
                                    getOptionLabel={option => option.text}
                                    getOptionValue={option => option.value}
                                    required
                                    value={value.CarYear ? { text: value.CarYear, value: value.CarYear } : null}
                                    onChange={(data) => SetCarYearValue(key, data)}
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={1}>
                                  {
                                    key > 0 && 
                                    (
                                      <Row className="justify-content-end g-0">
                                        <Col>
                                          <Button
                                            className="button-image set-hidden-delete"
                                            onClick={() => removeColumn(key, value)}
                                          >
                                            <img src={Delete} />
                                          </Button>
                                        </Col>
                                        <Col xs={12}>
                                          <Button
                                            className="button d-md-none"
                                            onClick={() => removeColumn(key, value)}
                                          >
                                            <img src={Delete} />
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                </Col>
                                <Col
                                  xs={6}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className=""
                                >
                                  {
                                    Data.Value.length - 1 === key && 
                                    (
                                      <Button
                                        className="button mt-3 mb-3"
                                        onClick={() =>
                                          setData({ ...Data, Value: [...Data.Value, InputModel]})
                                        }
                                      >
                                        <img src={increate} /> &nbsp;
                                        เพิ่มรายการ
                                      </Button>
                                    )
                                  }
                                </Col>
                              </Row>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </div>
                  <Row className="g-0 mt-3 mb-3 justify-content-between">
                    <Col md={6} lg={6} className="mt-3 mb-3">
                      <Row>
                        <Col md={6} lg={4} className="mt-3 mb-3">
                          <Button className="button" type='submit'>
                            <img src={Save} /> &nbsp; บันทึก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} lg={6}>
                      <Row className="justify-content-end">
                        <Col md={6} lg={4} className="mt-3 mb-3">
                          <Button className="button" onClick={exit}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
