import axios, { post } from 'axios';

export const UnitList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/units`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UnitSale = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/unit-sales`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindUnit = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/units/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateUnit = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/units`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateUnit = ({id, formData}) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/units/${id}`, formData
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyUnit = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/units/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};