import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Service from '../assets/icon/management.png';
import UserImage from 'assets/icon/user.png'
import Footer from '../../Components/Footer';

import { useHistory, Link } from 'react-router-dom';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';

import Search from '../assets/icon/search.png';
import Return from '../assets/icon/return-value.png';
import Edit from 'assets/icon/key-fix.png';
import TabsIconUser from '../Components/layout/TabsIconUser';
import { Branch } from '../../actions/branch/index';
import { Department } from '../../actions/department/index';

import { useDispatch } from 'react-redux';

import MyPagination from 'Components/MyPagination';

import { UserList } from '../../actions/user/index';

// img
import Add from '../../assets/icon/add-staff.png';


export default function Index() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [Users, setUsers] = useState([ 
  ]);
  
  const [isLoading, setLoading] = useState(true);

  const [status, setStatus] = useState('เปิด');
  const [showCutStock, setShowCutStock] = useState(false);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: '', label: '' }]);
  const handleCutStockClose = () => setShowCutStock(false);
  const handleCutStockShow = () => setShowCutStock(true);

  const [showAdjustStock, setShowAdjustStock] = useState(false);

  const handleAdjustStockClose = () => setShowAdjustStock(false);
  const handleAdjustStockShow = () => setShowAdjustStock(true);

  const [statepage, setStatepage] = useState('');
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [Units, setUnits] = useState([
    { value: 'ซื้อ', label: 'ซื้อ' },
    { value: 'ขาย', label: 'ขาย' },
  ]);

  const [Causes, setCauses] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const [Causeadjusts, setCauseAdjusts] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  // search
  const [Keyword, setKeyword] = useState(null);
  const [DepartmentId, setDepartmentId] = useState('');
  const [BranchId, setBranchId] = useState('');
  // pagination
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);

  const GetUser = async () => {
    setLoading(true);
    try {
      const params = {
        keyword: Keyword,
        branch_id: BranchId,
        department_id: DepartmentId,
        limit: PerPage,
        page: CurrentPage,
      };
      const response = await dispatch(UserList(params));
      setUsers(response.data.data.data);
      setRows(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [Departments, setDepartments] = useState([]);
  const GetDepartment = async (data) => {
    try {
      const response = await dispatch(Department());
      setDepartments(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const [Branches, setBranches] = useState([]);
  const GetBranch = async (data) => {
    try {
      const response = await dispatch(Branch());
      setBranches(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const [ClearSearch, setClearSearch] = useState(false);
  

  function ResetSearch() {
    setKeyword(null);
    setDepartmentId(null);
    setBranchId(null);
    setClearSearch(true);
  }

  useEffect(() => {
    GetUser();
    GetDepartment();
    GetBranch();
  }, []);

  useEffect(() => {
    GetUser();
    setClearSearch(false);
  }, [PerPage, ClearSearch]);

  function closePage() {
    let path = `/`;
    history.push(path);
  }
  const SetStatusUser = (data, user) => {
  setUsers(Users.map(el => el.id === user.id ? { ...el, active: user.active } : el
  ))
   console.log('user.active', user.active);
}
const optionStatusUser = [
    { value: 0, label: 'ปิด' },
    { value: 1, label: 'เปิด' },
];
  
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk ">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User &gt; รายชื่อผู้ใช้ระบบ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User &gt; รายชื่อผู้ใช้ระบบ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIconUser />
                </Row>
                <Row className="g-0 justify-content-start mb-3">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อพนักงาน,เบอร์โทรศัพท์"
                      value={Keyword ? Keyword : ''}
                      onChange={(e) => setKeyword(e.target.value)}
                      className="input"
                    ></Form.Control>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      options={Departments}
                      placeholder="แผนก"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={false}
                      isSearchable={false}
                      value={
                        DepartmentId
                          ? Departments.find((data) => {
                              return data.id === DepartmentId;
                            })
                          : null
                      }
                      onChange={(data) => setDepartmentId(data.id)}
                      className="select-list"
                    />
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      options={Branches}
                      placeholder="สาขา"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={false}
                      isSearchable={false}
                      value={
                        Branches
                          ? Branches.find((data) => {
                              return data.id === BranchId;
                            })
                          : null
                      }
                      onChange={(data) => setBranchId(data.id)}
                      className="select-list"
                    />
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={GetUser}>
                      <img src={Search} /> &nbsp; ค้นหา
                    </Button>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={ResetSearch}>
                      <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>

                  <Col xs={6} className=" mt-2 mb-2 d-md-none">
                    <Link to="/user/add">
                      <Button className="button-menu">
                        <img src={Add} /> &nbsp; เพิ่มรายชื่อ
                      </Button>
                    </Link>
                  </Col>
                </Row>

                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>ชื่อพนักงาน</th>
                          <th>สาขา</th>
                          <th>แผนก</th>
                          <th>ชื่อผู้ใช้</th>
                          <th>เข้าใช้งานล่าสุด</th>
                          <th>Active</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {Users?.map((user) => {
                          return (
                            <tr key={user.id}>
                              <td>{`${user.employee?.fullname}`}</td>
                              <td>{`${user.employee?.branch.name}`}</td>
                              <td>{`${user.employee?.department.name}`}</td>
                              <td>{`${user.username}`}</td>
                              <td>{`${
                                user.last_login_at ? user.last_login_at : '-'
                              }`}</td>
                              <td className='p-0' width="15%">
                                <div className="div-select-table" style={{'width': '60px'}}>
                                <Select
                                  options={optionStatusUser}
                                  defaultValue={optionStatusUser[0]}
                                  isClearable={false}
                                  isSearchable={false}
                                  value={ optionStatusUser.find(item => item.value === user.active)}
                                  onChange={(data) => SetStatusUser(data , user)}
                                  className={"select-in-table" +
                                    (user.active === 0 ? 'text-green' : 'text-red' )
                                  }
                                />
                                  </div>
                                {/* <span
                                  className={
                                    user.active === 0 ? 'text-green' : 'text-orange'
                                  }
                                >
                                  {user.active ? 'เปิด' : 'ปิด'}
                                </span>{' '}
                                &nbsp; <img src={TriangleArrow} /> */}
                              </td>
                              <td>
                                <Link to={`/user/edit/${user.id}`}>
                                  <Button className="button-package">
                                    <img src={Edit} />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={5} xl={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          onChange={(data) => setPerPage(data.value)}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {!isLoading ? (
                        <MyPagination
                          totPages={Rows}
                          currentPage={CurrentPage}
                          pageClicked={(e) => {
                            setCurrentPage(e);
                            GetUser();
                          }}
                        ></MyPagination>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
