import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Modal, Button } from 'react-bootstrap';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

import List from 'MiddleSystems/Package/List';
import PackageImage from 'assets/icon/package-manage.png'

import 'assets/scss/style.scss';

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import Staff from 'assets/icon/staff.png';

// API
import { PackageTypeList } from 'actions/package';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { name } = useParams();
  
  const [CurrentTab, setCurrentTab] = useState(null);
  const [PacketType, setPacketType] = useState([]);

  const GetPackageType = async (data) => {
    try {
      const response = await dispatch(PackageTypeList());
      if (response) {
        setCurrentTab(name)
        setPacketType(response.data.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const setTabAndURL = (tab) => {
    window.history.pushState("", "", `/package/${tab}`);
    setCurrentTab(tab)
  }

  function closePage() {
    history.push(`/`);
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการแพ็คเกจ'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  useEffect(() => {
    getAuth();
    GetPackageType();
  }, []);

  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={PackageImage} />
                  <b>แพ็คเกจ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={PackageImage} />
                  <b>แพ็คเกจ</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="tabsicon m-0">
                  <TabsIcon />
                </Row>
                <Row className="tabs-button g-0">
                  <Col>
                    <Tabs
                      onSelect={(tab) => setTabAndURL(tab)}
                      defaultActiveKey={name}
                      className="tabs-package"
                    >
                      {
                        PacketType?.map( (type, key) => {
                          return(
                            type.name === 'แพ็คเกจพิเศษ' ?
                            <Tab
                              eventKey="แพ็กเกจ"
                              title="แพ็กเกจ"
                              key={key}
                            >
                              <List name="แพ็กเกจ" current={CurrentTab} />
                            </Tab>
                            :
                            <Tab
                              eventKey={type.name}
                              title={type.name}
                              key={key}
                            >
                              <List name={type.name} current={CurrentTab} />
                            </Tab>
                          )
                        })
                      }
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}