import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";
import 'Setting/PreviewBill/Component/css/bill-5.css';

import dayjs from 'dayjs'
const THBText = require('assets/libraries/thai-baht-text') 

export class Bill5 extends React.PureComponent {
  
  render() {

    const { Bill } = this.props

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    const displayPhone = (phone) => {
      if(phone && Array.isArray(phone)) return phone.join(', ')
      if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
    }
    console.log(Bill); 
    return (
      <div id='bill-5' className='bill-5'>
        <div className='top'>
          <h5>ใบวางบิล / ใบแจ้งหนี้</h5>
        </div>

        <div className='detail'>
          <div className='line'>
            <label style={{ width: '72.5%'}}>
              { Bill?.job?.branch?.branche_code ? Bill?.job?.branch?.branche_code : '' }
            </label>
            <div className='inline between' style={{ width: '27.5%'}}>
              <p style={{ width: '70px', textAlign: 'start' }}>เลขที่/NO:</p>
              <p>{ Bill?.bill_code }</p>
            </div>
          </div>
          <div className='line'>
            <div className='inline' style={{ width: '72.5%'}} >
              <label className="bill-6-label">{ Bill?.job?.branch?.address ? Bill?.job?.branch?.address : '' }</label>
            </div>
            <div className='inline between' style={{ width: '27.5%' }}>
              <p style={{ width: '70px', textAlign: 'start' }}>วันที่</p>
              <p>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
            </div>
          </div>
          <div className='line'>
            <div className='inline'>
              <label className="bill-6-label">โทร.</label>
              <p>{ `${displayPhone(Bill?.job?.branch?.phone)}` }</p>
            </div>
          </div>
        </div>

        <div className='location-group'>
          <div className='address'>
            <p>ชื่อ : 
              {
                Bill?.job?.customer?.type === 'นิติบุคคล' &&
                ` ${Bill?.job?.customer?.organization}`
              }
              {
                Bill?.job?.customer?.type === 'บุคคลธรรมดา' &&
                ` ${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}`
              }
            </p>
            <p>ที่อยู่ : 
              { `${Bill?.job?.customer?.address ? Bill?.job?.customer?.address : ''}` }
            </p>
            <p>โทร : { ` ${displayPhone(Bill?.job?.customer?.phone)}` } </p>
          </div>

          <div className='location'>
            <p>สถานที่ส่งของ : </p>
            <p>DELIVER TO : </p>
          </div>
        </div>

        <div className='table-bill-5'>
          <div className='table-head'>
            <div>
              <p>รหัสสินค้า</p>
              <p>COTE</p>
            </div>
            <div>
              <p>เลขที่บิล</p>
              <p>BILL NUMBER</p>
            </div>
            <div>
              <p>วันที่</p>
              <p>DATE</p>
            </div>
            <div>
              <p>จำนวน</p>
              <p>QTY</p>
            </div>
            <div>
              <p>ราคา/หน่วย</p>
              <p>PRICE</p>
            </div>
            <div>
              <p>ส่วนลด</p>
              <p>DISCOUNT</p>
            </div>
            <div>
              <p>จำนวนเงิน</p>
              <p>AMOUNT</p>
            </div>
          </div>

          <div className='body-field'>
            <div className='table-body'>
              <div>1</div>
              <div>{ Bill?.bill_code }</div>
              <div>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</div>
              <div>1</div>
              <div>{ Bill?.job?.total }</div>
              <div>{ Bill?.job?.discount }</div>
              <div>{ Bill?.job?.discount }</div>
            </div>

            <div className='table-body pricing first'>
              <div></div>
              <div></div>
              <div>ราคาสินค้า :</div>
              <div>{ Bill?.job?.total }</div>
            </div>

            <div className='table-body pricing'>
              <div></div>
              <div></div>
              <div>จำนวนเงิน/ภาษีมูลค่าเพิ่ม</div>
              <div>{ Bill?.job?.tax_calculate }</div>
            </div>

            <div className='table-body pricing last'>
              <div>ตัวอักษร :</div>
              <div>{ Bill?.job && THBText(Bill?.job?.total_price) }</div>
              <div>ยอดเงินสุทธิ</div>
              <div>{ Bill?.job?.total_price }</div>
            </div>

            <div className='table-body payment first'>
              <div style={{ justifyContent: 'center', display: 'flex' }}>ชำระโดย</div>
              <div></div>
              <div></div>
            </div>

            <div className='table-body payment'>
              <div style={{ display: 'flex' }}>
                <div className='model-checkbox group-check'>
                  {/* ✓ */}
                </div>
                
                <div className='group-text'>
                  <p>เงินสด</p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '60px' }}>
                  <p>ผู้วางบิล</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 65px)' }}>
                  <p></p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '50px' }}>
                  <p>ผู้อนุมัต</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 65px)' }}>
                  <p></p>
                </div>
              </div>
            </div>

            <div className='table-body payment'>
              <div style={{ display: 'flex' }}>
                <div className='model-checkbox group-check'>
                  {/* ✓ */}
                </div>
                
                <div className='group-text' style={{ width: '100%' }}>
                  <p>เช็ค ธนาคาร</p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '80px' }}>
                  <p>ผู้รับวางบิล</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 65px)' }}>
                  <p></p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '65px' }}>
                  <p>คลังสินค้า</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 65px)' }}>
                  <p></p>
                </div>
              </div>
            </div>

            <div className='table-body payment last'>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ minWidth: '60px' }}>
                  <p>เลขที่เช็ค</p>
                </div>
                <div className='group-text-dot' style={{ width: '100%' }}>
                  <p></p>
                </div>
                <div className='group-text' style={{ minWidth: '50px' }}>
                  <p>ลงวันที่</p>
                </div>
                <div className='group-text-dot' style={{ width: '100%' }}>
                  <p></p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '35px' }}>
                  <p>วันที่</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 35px)' }}>
                  <p></p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className='group-text' style={{ width: '35px' }}>
                  <p>บัญชี</p>
                </div>
                <div className='group-text-dot' style={{ width: 'calc(100% - 65px)' }}>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}