import React, { useState , useEffect  } from 'react';
import { Container, Row, Col, Form, Button,ToastContainer, Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Save from 'assets/icon/save.png';
import Upload from 'assets/icon/upload.png';
// import Test_Image from '.assets/images/test-image.jpg';
import ImageSize from 'assets/icon/Mr.Kleane.png'

//api 
import { LoadProperty,CreateProperty, UpdateProperty } from 'actions/property/index'
export default function Data(props) {
  const [selectedImage, setSelectedImage] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();


  // console.log('isEdit', props.isEdit);

  const imageChange = (e) => {
    if(props.isEdit === false){
      if (e.target.files && e.target.files.length > 0) {
        // setSelectedImage(e.target.files[0]);
        setData({...Data, selectedImage: URL.createObjectURL(e.target.files[0]) })
        e.target.value = null
      }
    }else{
      // setData({...Data, selectedImage: null })
      if (e.target.files && e.target.files.length > 0) {
        // setSelectedImage(e.target.files[0]);
        setData({...Data, selectedImage: URL.createObjectURL(e.target.files[0]) })
        e.target.value = null
      }
    }
  };
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const [Data,setData] = useState({
    Name: null,
    Unit: null,
    selectedImage: null,
    displayImage: null,
    Note: null,
  })

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState([]);

  async function handleSubmit(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);

    let formData = new FormData();
    if(props.isEdit === true) formData.append('id', id);
    if(Data.Name) formData.append('name', Data.Name);
    if(Data.Unit) formData.append('unit', Data.Unit);
    if(Data.selectedImage) formData.append('image', Data.selectedImage);
    if(Data.Note) formData.append('note', Data.Note);
    if(props.isEdit === true) formData.append('_method', 'PUT');

    try {
      let response = [];
      if(props.isEdit === true ){
        response = await dispatch(UpdateProperty({id,formData}));
      }else{
        response = await dispatch(CreateProperty(formData));
      }
      if(response.data){
        history.push('/property');
      }else{
        console.log('failed')
      }
    } catch (error) {
      if(error.response && error.response.data){
        if(error.response.data.messages){
          setErrors([...errors, error.response.data.messages])
        }
      }
    } finally {
      console.log('finally')
    }
  };

  function clearImage(){
    if(props.isEdit === false){
      setData({...Data, selectedImage: null});
    }else{
      setData({...Data, selectedImage: Data.image_api});
    } 
  }

    async function GetProperty(id){
      try {
        const response = await dispatch(LoadProperty(id));
        const property = response.data.data
        setData({
          Name: property.name,
          Unit: property.unit,
          selectedImage: property.image ? property.image : null,
          image_api:  property.image ? property.image : null,
          Note: property.note,
        });
      } catch (error) {
        
      }
    }

  useEffect(() => {
    if(props.isEdit === true){
      GetProperty(id);
    }
  }, []);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  return (
    <>
      <ToastContainer position='top-end'>
        {
          errors?.map((error, key) => {
            return (
              <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                <Toast.Header>
                  <strong className="me-auto">แจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body>{ error }</Toast.Body>
              </Toast>
            )
          })
        }
      </ToastContainer>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="Property-data ">
        <Row className='pt-3'>
          <Col lg={6}>
            <Row>
              <Col lg={4} className="d-flex">
                <Form.Label>ชื่อ</Form.Label>
              </Col>
              <Col>
                <Form.Control 
                  className="input"
                  placeholder="ชื่อทรัพย์สิน"
                  type="text"
                  value={Data.Name}
                  required
                  onChange={(e) => setData({ ...Data, Name: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={4} className="d-flex">
                <Form.Label>หน่วย</Form.Label>
              </Col>
              <Col>
                <Form.Control className="input"
                   type="text"
                   placeholder="หน่วย (ตัว, เครื่อง ฯลฯ)"
                   value={Data.Unit}
                   required
                   onChange={(e) => setData({ ...Data, Unit: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={4} className="d-flex">
                <Form.Label>ภาพตัวอย่าง สินทรัพย์</Form.Label>
              </Col>
              <Col className={(!Data.selectedImage ? null : 'd-none')} >
                {
                  Data.selectedImage ? null : 
                  (
                    <img src={ImageSize} width={200} height={200} alt="Thumb" />
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col className="d-flex align-items-center set-button-upload">
            <Form.Label className="button-image">
              <input type="file" onChange={imageChange} />
              <img src={Upload} /> &nbsp; อัพโหลด
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={4}></Col>
              <Col>
                <div className="set-image-upload" style={{position: 'relative'}} >
                  
                  {Data.selectedImage && props.isEdit === true && (
                    <img src={Data.selectedImage} width={200} height={200} alt="Thumb" />
                  )}

                  {Data.selectedImage && props.isEdit === false && (
                    <img src={Data.selectedImage} width={200} height={200} alt="Thumb" />
                  )}

                  {Data.selectedImage && (
                    <Button onClick={clearImage} style={{position: 'absolute',marginLeft: '-10px'}}>x</Button>
                  )}
                   
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex align-items-center set-button-upload"></Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={4}>
                <Form.Label>หมายเหตุ</Form.Label>
              </Col>
              <Col>
                <Form.Control as="textarea" rows={4} className="input-area" 
                type="text"
                placeholder="หมายเหตุ"
                value={Data.Note}
                onChange={(e) => setData({...Data, Note: e.target.value })}
                />
              </Col>
            </Row>
          </Col>
          <Col className="d-flex align-items-center"></Col>
        </Row>

        <Row>
          <Col lg={6} className="mt-4">
            <Button type="submit " className="button-increase button-save">
              <img src={Save} /> &nbsp; บันทึก
            </Button>
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
}
