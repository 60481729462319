import React, { useState } from 'react';

import Footer from '../Components/Footer';
import { useHistory } from 'react-router-dom';
import {
  Button
} from 'reactstrap';

import { Container, Row, Col } from 'react-bootstrap';
import CustomerInfo from './Components/CustomerInfo';
import CarInfo from './Components/CarInfo/Index';
import CustomerHistory from '../Components/Member/CustomerHistory';
import MemberPackage from '../Components/Member/MemberPackage';
import MemberOverall from '../Components/Member/MemberOverall';
import TabsIcon from '../Components/layout/TabsIcon';
import Service from '../assets/icon/management.png';
import MemberImage from 'assets/icon/member.png'
import Back from '../assets/icon/back.png';
export default function MemberInformation() {
  const [stateActiveTab, setStateActiveTab] = useState({ activeTab: 'tab1' });

  const history = useHistory();

  function close() {
    history.push(`/member`);
  }

  return (
    <>
      
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="memberinformation">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b> บุคคลธรรมดา &#62; ข้อมูลส่วนตัว</b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b> บุคคลธรรมดา &#62; ข้อมูลส่วนตัว</b>
                  </h5>
                </div>
                <div className="modal-body ">
                  <Col className="icon-menu">
                    <TabsIcon />
                  </Col>
                  <Col className="tabs-form mb-3">
                    <Row className="m-0">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab1' });
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab1' && 'active'
                        }
                      >
                        ข้อมูลส่วนตัว
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab2' });
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab2' && 'active'
                        }
                      >
                        ข้อมูลรถ
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab3' });
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab3' && 'active'
                        }
                      >
                        ประวัติรับบริการ
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab4' });
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab4' && 'active'
                        }
                      >
                        ภาพรวม
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab5' });
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab5' && 'active'
                        }
                      >
                        แพ็กเกจ
                      </Button>
                    </Row>
                  </Col>
                  <Col style={{ padding: 0 }}>
                    {stateActiveTab.activeTab === 'tab1' && <CustomerInfo />}
                    {stateActiveTab.activeTab === 'tab2' && <CarInfo />}
                    {stateActiveTab.activeTab === 'tab3' && <CustomerHistory />}
                    {stateActiveTab.activeTab === 'tab4' && <MemberOverall />}
                    {stateActiveTab.activeTab === 'tab5' && <MemberPackage />}
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
