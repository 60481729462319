import axios, {post, put} from 'axios';

export const Branch = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/branches`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindBranch = (id) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/branches/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateBranch = (data) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/branches`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateBranch = ({id, data}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/branches/${id}`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyBranch = ({id, params}) => async (dispatch) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/branches/${id}/${params.key}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};