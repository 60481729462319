import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { Input } from 'reactstrap';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import Service from 'assets/icon/management.png';
import MemberImage from 'assets/icon/member.png';

import AddJuristicPart from './AddJuristicPart';
import AddOrdinaryPart from './AddOrdinaryPart';
import Back from 'assets/icon/back.png';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';


import Footer from 'Components/Footer';

import '@pathofdev/react-tag-input/build/index.css';



export default function MemberCreate() {
  const [state1, setState1] = useState(true);
  const [state2, setState2] = useState(false);
  const history = useHistory();
  const { Option } = Select;

  function close() {
    let path = `/member`;
    history.push(path);
  }

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  function ordinary() {
    setState1(true);
    setState2(false);
  }
  function juristic() {
    setState2(true);
    setState1(false);
  }

  return (
    <>
      
      <Container
        fluid
        className="package-container Service Stock User Member bg-mrk"
      >
        <div className="package">
          <div>
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b>
                      <Link to={`/member`} className='mr-2'>Customer (B2C)</Link>
                      &gt; เพิ่มรายชื่อ
                    </b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b>
                      <Link to={`/member`} className='mr-2'>Customer (B2C)</Link>
                      &gt; เพิ่มรายชื่อ
                    </b>
                  </h5>
                </div>
                <div className="modal-body ">
                  <Row>
                    <Col lg={12} className="d-flex justify-content-start">
                      <div className="tabs-icon mb-4">
                        <TabsIcon />
                      </div>
                    </Col>
                    <Col xs={12} className="   mb-3 m-0">
                      <Row className="set-line m-0 align-items-center">
                        {['radio'].map((type) => (
                          <Col
                            key={`inline-${type}`}
                            className=" mt-3 text-left"
                          >
                            <Form.Label className="mr-3">
                              ประเภทสมาชิก
                            </Form.Label>
                            <Form.Check
                              inline
                              label="บุคคลธรรมดา"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              onChange={(e) => ordinary()}
                              checked={state1}
                            />

                            <Form.Check
                              inline
                              label="นิติบุคคล"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              onChange={() => juristic()}
                              checked={state2}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>

                    <Col>
                        { 
                          state1 && <AddOrdinaryPart />
                        }
                        { 
                          state2 && <AddJuristicPart />
                        }
                        {/* {state1 === true ? <AddOrdinaryPart /> : null} */}
                        {/* {state2 === true ? <AddJuristicPart /> : null} */}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
