import React, { useState , useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, ToastContainer, Toast } from 'react-bootstrap';
import Select from 'react-select';
import { Avatar, DatePicker } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import Save from 'assets/icon/save.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTagInput from '@pathofdev/react-tag-input';
import Upload from 'assets/icon/upload.png';
import moment from 'moment';

// API
import { Province, Amphure, District } from 'actions/data/index';
import { Branch } from 'actions/branch/index';
import { Department } from 'actions/department/index';
import { CreateEmployee } from 'actions/employee/index';
import { VehicleList } from 'actions/vehicle/index';
import { loadAuth } from 'actions/auth';

import 'assets/css/validate.css';
var dayjs = require('dayjs')

export default function AddData() {
  const dispatch = useDispatch();
  const history = useHistory();
  const DocumentFileRef = useRef(null);
  const AvatarFileRef = useRef(null);
  const [tags, setTags] = React.useState([])
  const Prefix = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    },
  ];

  const BloodTypes = [
    {
      value: 'A+',
      label: 'A+',
    },
    {
      value: 'A-',
      label: 'A-',
    },
    {
      value: 'B+',
      label: 'B+',
    },
    {
      value: 'B-',
      label: 'B-',
    },
    {
      value: 'O+',
      label: 'O+',
    },
    {
      value: 'O-',
      label: 'O-',
    },
    {
      value: 'AB+',
      label: 'AB+',
    },
    {
      value: 'AB-',
      label: 'AB-',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const Personalities = [
    {
      value: 'นักออกแบบ INTJ-A / INTJ-T',
      label: 'นักออกแบบ INTJ-A / INTJ-T',
    },
    {
      value: 'นักตรรกะ INTP-A / INTP-T',
      label: 'นักตรรกะ INTP-A / INTP-T',
    },
    {
      value: 'ผู้บัญชาการ ENTJ-A / ENTJ-T',
      label: 'ผู้บัญชาการ ENTJ-A / ENTJ-T',
    },
    {
      value: 'นักโต้วาที ENTP-A / ENTP-T',
      label: 'นักโต้วาที ENTP-A / ENTP-T',
    },
    {
      value: 'ผู้แนะนำ INFJ-A / INFJ-T',
      label: 'ผู้แนะนำ INFJ-A / INFJ-T',
    },
    {
      value: 'ผู้ไกล่เกลี่ย INFP-A / INFP-T',
      label: 'ผู้ไกล่เกลี่ย INFP-A / INFP-T',
    },
    {
      value: 'ตัวเอก ENFJ-A / ENFJ-T',
      label: 'ตัวเอก ENFJ-A / ENFJ-T',
    },
    {
      value: 'นักรณรงค์ ENFP-A / ENFP-T',
      label: 'นักรณรงค์ ENFP-A / ENFP-T',
    },
    {
      value: 'นักคำนวณ ISTJ-A / ISTJ-T',
      label: 'นักคำนวณ ISTJ-A / ISTJ-T',
    },
    {
      value: 'ผู้ตั้งรับ ISFJ-A / ISFJ-T',
      label: 'ผู้ตั้งรับ ISFJ-A / ISFJ-T',
    },
    {
      value: 'ผู้บริหาร ESTJ-A / ESTJ-T',
      label: 'ผู้บริหาร ESTJ-A / ESTJ-T',
    },
    {
      value: 'ผู้ให้คำปรึกษา ESFJ-A / ESFJ-T',
      label: 'ผู้ให้คำปรึกษา ESFJ-A / ESFJ-T',
    },
    {
      value: 'ผู้เชี่ยวชาญด้านศิลปะ ISTP-A / ISTP-T',
      label: 'ผู้เชี่ยวชาญด้านศิลปะ ISTP-A / ISTP-T',
    },
    {
      value: 'นักผจญภัย ISFP-A / ISFP-T',
      label: 'นักผจญภัย ISFP-A / ISFP-T',
    },
    {
      value: 'ผู้ประกอบการ ESTP-A / ESTP-T',
      label: 'ผู้ประกอบการ ESTP-A / ESTP-T',
    },
    {
      value: 'ผู้มอบความบันเทิง ESFP-A / ESFP-T',
      label: 'ผู้มอบความบันเทิง ESFP-A / ESFP-T ',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const [validated, setValidated] = useState(false);

  const [errors, setErrors] = useState([]);

  const [Data, setData] = useState({
    EmployeeCode: null,
    Prefix: null,
    Fullname: null,
    NickName: null,
    AKA: null,
    Height: null,
    Weight: null,
    SkinColor: null,
    BirthDate: null,
    BloodType: null,
    ProvinceId: null,
    AmphureId: null,
    DistrictId: null,
    // Phone: null,
    Phones: [],
    PostCode: null,
    Address: null,
    Motto: null,
    BranchId: null,
    DepartmentId: null,
    MusicalTalent: null,
    AthleticAbility: null,
    CitizenId: null,
    CongenitalDisease: null,
    FoodAllergy: null,
    DrugAllergy: null,
    Email: null,
    StartDate: null,
    EndDate: null,
    WorkExperience: null,
    Note: null,
    Personality: null,
    NeedInOrganization: null,
    SalesTarget: null,
    Period: null,
    Avatar: [],
    Document: [],
    Vehicles: [],
  });

  const disabledStartDate = (current) => {
    return current && current.valueOf() > Date.now();
  };

  const disabledDate = (current) => {
    return current && current.valueOf() < Data.StartDate;
  };
  
  const [FileName, setFileName] = useState({
    Document: [],
    Avatar: []
  });

  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Districts, setDistricts] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [Vehicles, setVehicles] = useState([]);
  const [Resign, SetResign] = useState(false);
  

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['พนักงาน'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/staff');
      }
    } catch (error) {
      history.push('/');
      console.error(error);
    }
  };

  const GetAmphure = async (data) => {
    setData({ ...Data, ProvinceId: data.id , AmphureId: '', DistrictId: '', PostCode: ''  })
    const params = {
      ProvinceId: data.id
    }
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetDistrict = async (data) => {
    setData({ ...Data, AmphureId: data.id, DistrictId: '', PostCode: '' })
    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBranch = async (data) => {
    try {
      const response = await dispatch(Branch());
      setBranches(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetDepartment = async (data) => {
    try {
      const response = await dispatch(Department());
      setDepartments(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

const GetVehicle = async (data) => {
  try {
    const response = await dispatch(VehicleList());
    setVehicles(response.data.data);
  } catch (error) {
    console.error(error);
  } finally {
    console.log('finally')
  }
}

  useEffect(() => {
    GetProvince();
    GetBranch();
    GetDepartment();
    GetVehicle();
    getAuth();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    
    if (form.checkValidity()) {
      let formData = new FormData();
      formData.append('type', 'พนักงาน')
      if(Data.Prefix && Data.Prefix === 'นาย') formData.append('gender', 'ชาย')
      if(Data.Prefix && Data.Prefix === 'นาง') formData.append('gender', 'หญิง')
      if(Data.Prefix && Data.Prefix === 'นางสาว') formData.append('gender', 'หญิง')
      if(Data.EmployeeCode) formData.append('employee_code', Data.EmployeeCode);
      if(Data.Prefix) formData.append('prefix', Data.Prefix);
      if(Data.Fullname) formData.append('fullname', Data.Fullname);
      if(Data.NickName) formData.append('nickname', Data.NickName);
      if(Data.AKA) formData.append('aka', Data.AKA);
      if(Data.Height) formData.append('height', Data.Height);
      if(Data.Weight) formData.append('weight', Data.Weight);
      if(Data.SkinColor) formData.append('skin_color', Data.SkinColor);
      if(Data.BirthDate) formData.append('birthdate', dayjs(Data.BirthDate).format('YYYY-MM-DD'));
      if(Data.BloodType) formData.append('blood_type', Data.BloodType);
      if(Data.ProvinceId) formData.append('province_id', Data.ProvinceId);
      if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId);
      if(Data.DistrictId) formData.append('district_id', Data.DistrictId);
      if(Data.PostCode) formData.append('postcode', Data.PostCode);
      if(Data.Address) formData.append('address', Data.Address);
      if(Data.Motto) formData.append('motto', Data.Motto);
      if(Data.BranchId) formData.append('branch_id', Data.BranchId);
      if(Data.DepartmentId) formData.append('department_id', Data.DepartmentId);
      if(Data.MusicalTalent) formData.append('musical_talent', Data.MusicalTalent);
      if(Data.AthleticAbility) formData.append('athletic_ability', Data.AthleticAbility);
      if(Data.CitizenId) formData.append('citizen_id', Data.CitizenId);
      if(Data.CongenitalDisease) formData.append('congenital_disease', Data.CongenitalDisease);
      if(Data.FoodAllergy) formData.append('food_allergy', Data.FoodAllergy);
      if(Data.DrugAllergy) formData.append('drug_allergy', Data.DrugAllergy);
      if(Data.Email) formData.append('email', Data.Email);
      if(Data.Phones) formData.append('phone', JSON.stringify(Data.Phones));
      if(Data.StartDate) formData.append('start_date',  dayjs(Data.StartDate).format('YYYY-MM-DD'));
      if(Data.EndDate) formData.append('end_date', dayjs(Data.EndDate).format('YYYY-MM-DD'));
      if(Data.WorkExperience) formData.append('work_experience', Data.WorkExperience);
      if(Data.Note) formData.append('note', Data.Note);
      if(Data.Personality) formData.append('personality', Data.Personality);
      if(Data.NeedInOrganization) formData.append('need_in_organization', Data.NeedInOrganization);
      if(Data.SalesTarget) formData.append('sales_target', Data.SalesTarget);
      if(Data.Period && Data.Period[0]) formData.append('period_start', dayjs(Data.Period[0]).format('YYYY-MM-DD'));
      if(Data.Period && Data.Period[1]) formData.append('period_end', dayjs(Data.Period[1]).format('YYYY-MM-DD'));
      if(Data.Vehicles && Data.Vehicles.length > 0){
        let i = 0 ;
        while (i < Data.Vehicles.length){
          formData.append('vehicles[]', Data.Vehicles[i]);
          i++;
        }
      }

      if(Data.Avatar) formData.append('avatar', Data.Avatar[0]);
      if(Data.Document) {
        let i = 0 ;
        while (i < Data.Document.length){
          formData.append(`document[${i}]`, Data.Document[i]);
          i++;
        }
      }
  
      dispatch(CreateEmployee(formData)).then(() =>{
          history.push('/staff');
        }).catch(e => {
          if (e.response.data && e.response.data.messages) {
            setErrors([...e.response.data.messages])
          }
        });
    }
  };

  const ToggleDocumentFile = () => {
    if(DocumentFileRef.current) DocumentFileRef.current.click();
  }

  const SetDocumentFile = (e) => {
    setData({ ...Data, Document : e.target.files})
    const name = []
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      name.push(item.name)
    }
    setFileName({ ...FileName, Document : name})
  }

  const ToggleAvatarFile = () => {
    if(AvatarFileRef.current) AvatarFileRef.current.click();
  }

  const SetAvatarFile = (e) => {
    setData({ ...Data, Avatar : e.target.files})
    const name = []
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      name.push(item.name)
    }
    setFileName({ ...FileName, Avatar: name})
  }

  const SetVehicle = (checked, id) => {
    if (checked) {
      setData({ ...Data, Vehicles: [...Data.Vehicles,  id] })
    }else{
      setData({ ...Data, Vehicles: Data.Vehicles.filter((vehicle) => { 
        return vehicle !== id 
      })});
    }
  }
  const deleteFile = (index) => {
    setData({ ...Data, Document: Data.Document.filter(function(_, key) { 
      return key !== index
    })})
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }
  
  return (
    <>
      <ToastContainer position='bottom-end'>
        {
          errors?.map((error, key) => {
            return (
              <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                <Toast.Header>
                  <strong className="me-auto">แจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body>{ error }</Toast.Body>
              </Toast>
            )
          })
        }
      </ToastContainer>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="set-row-add-staff Stocks">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center set-input"
              >
                <Form.Label className="label-input">รหัสพนักงาน</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder="รหัสพนักงาน"
                  // defaultValue={statepage[0]}
                  value={Data.EmployeeCode}
                  onChange={(e) => setData({ ...Data, EmployeeCode: e.target.value})}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">คำนำหน้าชื่อ</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Prefix}
                  placeholder="คำนำหน้าชื่อ"
                  isClearable={false}
                  isSearchable={false}
                  required
                  className={`select-list ` + (!Data.Prefix && validated ? 'validate-fail' : '') }
                  onChange={(data) => setData({ ...Data, Prefix: data.value})}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ชื่อ-นามสกุล</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="ชื่อ-นามสกุล"
                  value={Data.Fullname}
                  required
                  onChange={(e) => setData({ ...Data, Fullname: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">ชื่อเล่น</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder="ชื่อเล่น"
                  value={Data.NickName}
                  onChange={(e) => setData({ ...Data, NickName: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ชื่อAKA</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="ชื่อAKA"
                  value={Data.AKA}
                  onChange={(e) => setData({ ...Data, AKA: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">วัน/เดือน/ปีเกิด</Form.Label>
              </Col>
              <Col>
                <DatePicker
                  selected={Data.BirthDate}
                  onChange={(date) => setData({ ...Data, BirthDate: date})}
                  placeholder="วัน/เดือน/ปีเกิด"
                  suffixIcon={<img src={Calendar} />}
                  style={{ borderRadius: '0' }}
                  className={(!Data.BirthDate && validated ? 'validate-fail' : '') }
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff align-items-center">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">กรุ๊ปเลือด</Form.Label>
              </Col>
              <Col className="mt-2 mb-2">
                <Select
                  options={BloodTypes}
                  placeholder="กรุ๊ปเลือด"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(data) => setData({ ...Data, BloodType: data.value})}
                  required
                  className={`select-list `
                   + (!Data.BloodType && validated ? 'validate-fail' : '') 
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">น้ำหนัก</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="กิโลกรัม"
                  value={Data.Weight}
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, Weight: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ส่วนสูง</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="เซนติเมตร"
                  value={Data.Height}
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, Height: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">สีผิว</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="สีผิว"
                  value={Data.SkinColor}
                  onChange={(e) => setData({ ...Data, SkinColor: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">โรคประจำตัว</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="โรคประจำตัว"
                  value={Data.CongenitalDisease}
                  onChange={(e) => setData({ ...Data, CongenitalDisease: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">การแพ้อาหาร</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="การแพ้อาหาร"
                  value={Data.FoodAllergy}
                  onChange={(e) => setData({ ...Data, FoodAllergy: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">การแพ้ยา</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="การแพ้ยา"
                  value={Data.DrugAllergy}
                  onChange={(e) => setData({ ...Data, DrugAllergy: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff align-items-center">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">
                  16 Personalities
                </Form.Label>
              </Col>
              <Col lg={9} xl={9} className="mt-2 mb-2">
                <Select
                  options={Personalities}
                  placeholder="16 Personalities"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(data) => setData({ ...Data, Personality: data.value })}
                  required
                  className={`select-list ` 
                  + (!Data.Personality && validated ? 'validate-fail' : '')
                 }
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านดนตรี
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder="ความสามารถด้านดนตรี"
                  value={Data.MusicalTalent}
                  onChange={(e) => setData({ ...Data, MusicalTalent: e.target.value })}
                  required
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านกีฬา
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder="ความสามารถด้านกีฬา"
                  value={Data.AthleticAbility}
                  onChange={(e) => setData({ ...Data, AthleticAbility: e.target.value })}
                  required
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex  set-input">
                <Form.Label className="label-input">
                  ความสามารถด้านการขับขี่
                </Form.Label>
              </Col>
              <Col>
                <Row>
                  <Col lg={12}>
                    <Row>
                    {
                      Vehicles.filter(data => data.type.includes('other')).map(vehicle => {
                        return (
                          <Col lg={5} xl={4}>
                            <Form.Check
                              type="checkbox"
                              aria-label="radio 1"
                              label={vehicle.title}
                              onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                            />
                          </Col>
                        )
                      })
                    }
                    </Row>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={5} xl={4} className="d-flex align-items-center">
                        <Form.Label className="label-input">
                          รถยนต์ส่วนบุคคล{' '}
                        </Form.Label>
                      </Col>
                      {
                        Vehicles.filter(data => data.type.includes('car')).map(vehicle => {
                          return (
                            <Col lg={7} xl={4}>
                              <Form.Check
                                type="checkbox"
                                aria-label="radio 1"
                                label={vehicle.title}
                                isValid={false}
                                onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                              />
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>

                  <Col lg={12}>
                    <Row>
                      <Col lg={5} xl={4} className="d-flex align-items-center">
                        <Form.Label className="label-input">
                          จักรยานยนต์
                        </Form.Label>
                      </Col>
                      {
                        Vehicles.filter(data => data.type.includes('motorcycle')).map(vehicle => {
                          return (
                            <Col lg={7} xl={4}>
                              <Form.Check
                                type="checkbox"
                                aria-label="radio 1"
                                label={vehicle.title}
                                onChange={(e) => SetVehicle(e.target.checked, vehicle.id)}
                              />
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mt-5">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">สาขา</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Select
                  options={Branches}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder="สาขา"
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.BranchId}
                  text="name"
                  onChange={(data) => setData({ ...Data, BranchId: data.id })}
                  className={`select-list w-100 ` + (!Data.BranchId && validated ? 'validate-fail' : '') }
                />
                {/* <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.BranchId}
                  onChange={(e) => setData({ ...Data, BranchId: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control> */}
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">แผนก</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Departments}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder="แผนก"
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.DepartmentId}
                  text="name"
                  onChange={(data) => setData({ ...Data, DepartmentId: data.id })}
                  className={`select-list w-100 ` + (!Data.DepartmentId && validated ? 'validate-fail' : '') }
                />

                {/* <Form.Control
                  type="text"
                  placeholder=""
                  value={Data.DepartmentId}
                  onChange={(e) => setData({ ...Data, DepartmentId: e.target.value })}
                  className="input-search-add-staff"
                ></Form.Control> */}
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">เริ่มงาน</Form.Label>
              </Col>
              <Col>
                <DatePicker
                  value={Data.StartDate}
                  onChange={(date) =>  setData({ ...Data, StartDate: date })}
                  placeholder="วันที่เริ่มงาน"
                  suffixIcon={<img src={Calendar} />}
                  disabledDate={disabledStartDate}
                  className={(!Data.StartDate && validated ? 'validate-fail' : '') }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center" style={{ height: '60px' }}>
                <Form.Check
                  type="checkbox"
                  aria-label="radio 1"
                  label="สิ้นสุดงาน"
                  checked={Resign}
                  onChange={(e) => SetResign(e.target.checked)}
                />
              </Col>
              <Col>
                {
                  Resign && Data.StartDate &&
                  <DatePicker
                    value={Data.EndDate}
                    onChange={(date) =>  setData({ ...Data, EndDate: date })}
                    placeholder="วันที่สิ้นสุดงาน"
                    suffixIcon={<img src={Calendar} />}
                    disabledDate={disabledDate}
                    className={(!Data.EndDate && validated ? 'validate-fail' : '') }
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={12}>
            <Row className="mb-3 mt-3">
              <Col
                xs={12}
                sm={12}
                lg={1}
                className="d-flex align-items-center set-input mr-4"
              >
                <Form.Label className="label-input">ที่อยู่</Form.Label>
              </Col>
              <Col lg={10} className="d-flex align-items-center ml-1">
                <Form.Control
                  type="text"
                  placeholder="ที่อยู่"
                  value={Data.Address}
                  onChange={(e) => setData({ ...Data, Address: e.target.value })}
                  required
                  className={`input-search-add-staff` + (!Data.Address && validated ? 'validate-fail' : '') }
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col xs="auto"></Col>
          <Col xs="auto"></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={4} xl={3}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">จังหวัด</Form.Label>
              </Col>
              <Col className="d-flex align-items-center">
                <Select
                  options={Provinces}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder="จังหวัด"
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.ProvinceId}
                  text="name"
                  onChange={(data) => { GetAmphure(data) }}
                  className={`select-list w-100 ` 
                  // + (!Data.ProvinceId && validated ? 'validate-fail' : '') 
                }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">อำเภอ</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Amphures}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder="อำเภอ"
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.AmphureId}
                  text="name"
                  onChange={(data) => { GetDistrict(data);  }}
                  value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                  isDisabled={!Data.ProvinceId}
                  className={`select-list w-100 `
                  //  + (!Data.AmphureId && validated ? 'validate-fail' : '') 
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">ตำบล</Form.Label>
              </Col>
              <Col>
                <Select
                  options={Districts}
                  getOptionLabel={option => option.name_th}
                  getOptionValue={option => option.id}
                  placeholder="ตำบล"
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={Data.DistrictId}
                  value={Data.DistrictId ? Districts.find((data) => {return data.id === Data.DistrictId}) : null}
                  text="name"
                  onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : '' }) }}
                  isDisabled={!Data.AmphureId}
                  className={`select-list w-100 ` 
                  // + (!Data.DistrictId && validated ? 'validate-fail' : '')
                 }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row>
              <Col xs={12} sm={12} lg={5} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">รหัสไปรษณีย์</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  value={Data.PostCode}
                  onChange={(e) => setData({ ...Data, PostCode: e.target.value })}
                  // required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff ">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">เบอร์โทรศัพท์</Form.Label>
              </Col>
              <Col>
                <div className={(!Data.Phones | Data.Phones.length === 0 && validated ? 'validate-fail-tags' : '') }>
                  <ReactTagInput
                    placeholder="เบอร์โทรศัพท์"
                    editable={true}
                    readOnly={false}
                    removeOnBackspace={true}
                    maxTags={2}
                    tags={Data.Phones}
                    required
                    onChange={(newTags) => setData({ ...Data, Phones: newTags })}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-center set-input">
                <Form.Label className="label-input">เลขบัตรประชาชน</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="เลขบัตรประชาชน"
                  value={Data.CitizenId}
                  onWheel={(e) => e.target.blur() }
                  onChange={(e) => setData({ ...Data, CitizenId: e.target.value })}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  // required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col xs="auto"></Col>
        </Row>
        <Row className="set-row-add-staff mb-3">
          <Col md={12} lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-center  set-input"
              >
                <Form.Label className="label-input">E-mail</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  value={Data.Email}
                  onChange={(e) => setData({ ...Data, Email: e.target.value })}
                  required
                  className="input-search-add-staff"
                ></Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff">
          <Col lg={6} xl={5}>
            <Row>
              <Col xs={12} sm={12} lg={3} className="d-flex align-items-top set-input">
                <Form.Label className="label-input">ประสบการณ์ทำงาน</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.WorkExperience}
                  onChange={(e) => setData({ ...Data, WorkExperience: e.target.value })}
                  required
                  placeholder="ประสบการณ์ทำงาน"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={6}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                xl={4}
                className="d-flex align-items-top set-input"
              >
                <Form.Label className="label-input">
                  ความต้องการในองค์กร
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.NeedInOrganization}
                  onChange={(e) => setData({ ...Data, NeedInOrganization: e.target.value })}
                  required
                  placeholder="ความต้องการในองค์กร"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2">
          <Col lg={6} xl={5}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={3}
                className="d-flex align-items-top  set-input"
              >
                <Form.Label className="label-input">คติประจำใจ</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.Motto}
                  onChange={(e) => setData({ ...Data, Motto: e.target.value })}
                  required
                  placeholder="คติประจำใจ"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={6}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={5}
                xl={4}
                className="d-flex align-items-top set-input"
              >
                <Form.Label className="label-input">หมายเหตุ</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  value={Data.Note}
                  onChange={(e) => setData({ ...Data, Note: e.target.value })}
                  required
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="set-row-add-staff mt-2 mb-2 align-items-center">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={1}
            xl={1}
            className="d-flex align-items-center set-input mr-4"
          >
            <Form.Label className="label-input ">อัพโหลดไฟล์</Form.Label>
          </Col>
          <Col lg={5}>
            <Row>
              <Col>
                {/* <Form.Control
                  type="text"
                  placeholder=""
                  value={FileName.Document}
                  className="input-search-add-staff"
                  readOnly
                ></Form.Control> */}
                <ReactTagInput
                    placeholder=" "
                    editable={true}
                    removeOnBackspace={true}
                    tags={FileName.Document}
                    onChange={(e) => setFileName({ ...FileName, Document : e }) }
                    className="input-search-add-staff"
                    disabled
                    required
                />
                <Form.Control
                  type="file"
                  className="d-none"
                  id="document-files"
                  ref={DocumentFileRef}
                  label={Data.Document}
                  onChange={(e) => SetDocumentFile(e) }
                  multiple
                />
                
              </Col>
              <Col
                md={12}
                lg="auto"
                className="d-flex justify-content-center align-items-center"
              >
                <Button 
                  type="button" 
                  className="button-save button-respon"
                  onClick={ToggleDocumentFile}
                >
                  เลือกไฟล์
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={5}>
            <Row>
              <Col>
                {/* <Form.Control
                  type="text"
                  placeholder=""
                  value={FileName.Avatar}
                  className="input-search-add-staff"
                  readOnly
                ></Form.Control> */}
                <Form.Control
                  type="file"
                  className="d-none"
                  id="avatar-file"
                  ref={AvatarFileRef}
                  label={Data.Avatar}
                  onChange={(e) => SetAvatarFile(e) }
                />
                <ReactTagInput
                    placeholder=" "
                    editable={true}
                    removeOnBackspace={true}
                    tags={FileName.Avatar}
                    onChange={(e) => setFileName({ ...FileName, Avatar : e }) }
                    className="input-search-add-staff"
                    disabled
                    required
                />
              </Col>
              <Col
                md={12}
                lg="auto"
                className="d-flex justify-content-center align-items-center"
              >
                <Button 
                  type="button" 
                  className="button-save button-respon"
                  onClick={ToggleAvatarFile}
                >
                  <img src={Upload} /> &nbsp; เลือกรูปถ่าย
                </Button>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-0 set-row-add-staff mt-5 mb-5 d-flex justify-content-center ">
          <Col xs={10} sm={10} md={10} lg={8} className="border-colum-time">
            <Row>
              <Col ms={12} sm={12} lg={6}>
                <Row>
                  <Col xs={4} sm={3} md={4} lg={3} className="text-input">
                    <p>เป้าขาย</p>
                  </Col>
                  <Col xs={8} sm={9} md={8} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="เป้าขาย"
                      className="input-search-add-staff"
                      value={commaCase(Data.SalesTarget)}
                      onFocus={
                        (e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={(e) => setData({ ...Data, SalesTarget: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })}
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                      // required
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col ms={12} sm={12} lg={6}>
                <Row>
                  <Col xs={4} sm={3} md={3} lg={3} className="text-input">
                    <p>ช่วงเวลา</p>
                  </Col>
                  <Col xs={8} sm={9} md={9} lg={9}>
                    <DatePicker.RangePicker
                      selected={Data.Period}
                      onChange={(date) => setData({ ...Data, Period: date })}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                      // className={(!Data.Period && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Button type="submit" className="button-save button-respon mb-5">
            <img src={Save} /> &nbsp; บันทึก
          </Button>
        </Row>
      </Form>
    </>
  );
}
