import axios, { post } from 'axios';

export const ProductList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/products`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindProduct = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/products/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const ProductHistory = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/products-history/${params.product_id}`,
    {params}
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const CreateProduct = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/products`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateProduct = ({id, data}) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/products/${id}`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyProduct = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/products/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};