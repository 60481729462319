import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from 'Components/Footer';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  MenuItem,
  Pagination,
  Tabs,
  Tab,
} from 'react-bootstrap';
import ReactTagInput from '@pathofdev/react-tag-input';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Delete from 'assets/icon/delete.png';

import { Table, Label, Input, FormGroup } from 'reactstrap';
import Select, { components } from 'react-select';

import 'assets/css/app.css';
import 'assets/css2/css/app.css';

import 'assets/css2/css/modal.css';
import 'assets/css2/css/employee.css';
import 'assets/css2/css/responsive.css';
import 'assets/css2/css/input.css';
import 'assets/css2/css/table.css';
import 'assets/css2/css/mr.kleane.css';
import 'assets/scss/style.scss';

import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';

import TabsIcon from '../Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import B2B from 'assets/icon/users_key-4.png';
import Back from 'assets/icon/back.png';
import Save from 'assets/icon/new-save.png';
import Out from 'assets/icon/getout.png';
import Increase from 'assets/icon/add_menu.png';
import { CreateCustomer } from 'actions/customer/index';
import { Province, Amphure, District } from 'actions/data/index';
import { loadAuth } from 'actions/auth';
import B2BImage from 'assets/icon/address_book_home.png'

var dayjs = require('dayjs')

export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const { employee } = useSelector( (state) => state.user)
  const ContactModel = {
    key: 0,
    name: null,
    phone: null,
    bank_name: null,
    account_name: null,
    account_number: null,
  }

  const [Data, setData] = useState({
    Organization: null,
    BranchName: null,
    TaxId: null,
    InvoiceAddress: null,
    Address: null,
    AmphureId: null,
    DistrictId: null,
    PostCode: null,
    Email: null,
    ContactDate: null,
    Contacts: [
      ContactModel
    ],
  });

  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Districts, setDistricts] = useState([]);

  const [ErrorEmail, SetErrorEmail] = useState('');

  const checkTaxId = (e) => {
    let val = e.target.value
    let maxLength = 13
    let newValue = val < maxLength ? val : parseInt(val.toString().substring(0, maxLength));
    setData({ ...Data, TaxId: newValue })
  }
  const checkPostCode = (e) => {
    let val = e.target.value
    let maxLength = 6
    let newValue = val < maxLength ? val : parseInt(val.toString().substring(0, maxLength));
    setData({ ...Data, PostCode: newValue })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    setTimeout(async () => {
      const validator = document.querySelectorAll('.validate-fail');
      // console.log('validator', validator.length)
      if (form.checkValidity() && validator.length === 0) {
        let formData = new FormData();
        formData.append('type', 'โชว์รูม')
        if (Data.Organization) formData.append('organization', Data.Organization)
        if (Data.BranchName) formData.append('branch_name', Data.BranchName)
        if (Data.Email) formData.append('email', Data.Email);
        if (Data.TaxId) formData.append('citizen_id', Data.TaxId)
        if (Data.AmphureId) formData.append('amphure_id', Data.AmphureId)
        if (Data.DistrictId) formData.append('district_id', Data.DistrictId)
        if (Data.PostCode) formData.append('postcode', Data.PostCode)
        if (Data.CustomerId) formData.append('recommender', Data.CustomerId)
        if (Data.Address) formData.append('address', Data.Address)
        if (Data.InvoiceAddress) formData.append('tax_invoice_address', Data.InvoiceAddress)
        if (Data.ContactDate) formData.append('contact_date', dayjs(Data.ContactDate).format('YYYY-MM-DD'))
        if (Data.Contacts && Data.Contacts.length > 0) {
          Data.Contacts.forEach((contact, key) => {
            formData.append(`contacts[${key}][name]`, contact.name)
            formData.append(`contacts[${key}][phone]`, contact.phone)
            formData.append(`contacts[${key}][bank_name]`, contact.bank_name)
            formData.append(`contacts[${key}][account_name]`, contact.account_name)
            formData.append(`contacts[${key}][account_number]`, contact.account_number)
          });
        }
  
        if (Data.Note) formData.append('note', Data.Note)
  
        try {
          const response = await dispatch(CreateCustomer(formData));
  
          if (response) {
            if(response.data.message == 'บันทึกสำเร็จ'){
              GetProvince();
              alert('บันทึกเสร็จสิ้น')
              setData({
                Organization: null,
                BranchName: null,
                TaxId: null,
                InvoiceAddress: null,
                AmphureId: null,
                PostCode: null,
                Email: null,
                ContactDate: null,
                Contacts: [
                  ContactModel
                ],
              })
            }else{
              console.log(response)
              let text = '';
              Object.values(response.data.message).map((item, index) => {
                if(item.length > 0){
                  item.map(i => {
                    text = text + i + '\n'
                  })
                }else{
                  text = text + item + '\n'
                }
              });
              alert(text);
            }
          } else {
            console.log('failed')
            if (Data.Email) {
              SetErrorEmail('อีเมลให้ถูกต้อง')
            } else {
              SetErrorEmail('')
            }
          }
        } catch (error) {
          console.error(error);
  
        } finally {
          console.log('finally')
        }
      }
    }, 200)
  };

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['customerB2B'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/b2b');
      // }
    } catch (error) {
	  history.push('/');
      console.error(error);
    }
  };
  
  const GetAmphure = async (data) => {
    setData({ ...Data, ProvinceId: data.id, AmphureId: '', PostCode: '' })
    const params = {
      ProvinceId: data.id
    }
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetDistrict = async (data) => {
    setData({ ...Data, AmphureId: data.id, DistrictId: '', PostCode: '' })
    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect((e) => {
    GetProvince();
    getAuth();
  }, []);

  const RemoveContact = (key) => {
    const contacts = Data.Contacts
    const index = contacts.findIndex((item) => item.key === key)
    contacts.splice(index, 1)
    const result = contacts
    setData({ ...Data, Contacts: result })
  }

  const InsertContact = () => {
    let insert = ContactModel
    insert.key = Data.Contacts.length
    setData({ ...Data, Contacts: [...Data.Contacts, insert] })
  }

  function close() {
    history.push(`/b2b`);
  }

  function exit() {
    history.push(`/`);
  }
  return (
    <>

      <Container
        fluid
        className="package-container Service Stock User B2B bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  <b><Link to={`/b2b`} className='mr-2'>Company (B2B)</Link></b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  Company (B2B)
                </h5>
              </div>
              <div className="modal-body">
                <Col className="justify-content-start" style={{ padding: 0 }}>
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Col>
                <Col className="justify-content-start" style={{ padding: 0 }}>
                  <div className="box-typecar "></div>
                  <Tabs
                    id="controlled-tab-example"
                    className="mb-4"
                  >
                    <Tab eventKey="data" title="ข้อมูล">
                      <Container
                        fluid
                        style={{ paddingTop: 0, margin: 0 }}
                        className="data-b2b"
                      >
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Row>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col xs={12} lg={3} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>ชื่อโชว์รูม</Form.Label>
                                </Col>
                                <Col className="">
                                  <Form.Control
                                    type="text"
                                    name="company"
                                    value={Data.Organization}
                                    onChange={(e) => setData({ ...Data, Organization: e.target.value })}
                                    placeholder="ชื่อโชว์รูม"
                                    required
                                    className={`text-left search-btn-top ` + (!Data.Organization && validated ? 'validate-fail' : '') }
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={3} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col xs={12} lg={3} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>สาขา</Form.Label>
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="text"
                                    name="branch"
                                    value={Data.BranchName}
                                    onChange={(e) => setData({ ...Data, BranchName: e.target.value })}
                                    placeholder="สาขา"
                                    className={`text-left search-btn-top ` + (!Data.BranchName && validated ? 'validate-fail' : '') }
                                    required
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={5} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col xs={12} lg={4} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="number"
                                    maxLength="11"
                                    name="TaxpayerIdentificationNo"
                                    value={Data.TaxId}
                                    onChange={(e) => checkTaxId(e)}
                                    placeholder="เลขประจำตัวผู้เสียภาษี"
                                    // required
                                    className={`text-left search-btn-top ` + (!Data.TaxId && validated ? 'validate-fail' : '') }
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col xs={12} lg={2} className="text-left ">
                                  <Form.Label>ที่อยู่ออกใบกำกับภาษี</Form.Label>
                                </Col>
                                <Col lg={5}>
                                  <Form.Control
                                    type="text"
                                    name="taxinvoice"
                                    value={Data.InvoiceAddress}
                                    onChange={(e) => setData({ ...Data, InvoiceAddress: e.target.value })}
                                    placeholder="ที่อยู่ออกใบกำกับภาษี"
                                    className={`text-left search-btn-top ` + (!Data.InvoiceAddress && validated ? 'validate-fail' : '') }
                                    required
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>จังหวัด</Form.Label>
                                </Col>
                                <Col lg={9}>
                                  <Select
                                    options={Provinces}
                                    placeholder="จังหวัด"
                                    getOptionLabel={(option) => option.name_th}
                                    getOptionValue={(option) => option.id}
                                    isClearable={false}
                                    defaultValue={Data.ProvinceId}
                                    onChange={(data) => { GetAmphure(data) }}
                                    required
                                    className={`w-100 select-list ` + (!Data.ProvinceId && validated ? 'validate-fail' : '') }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>อำเภอ</Form.Label>
                                </Col>
                                <Col lg={8}>
                                  <Select
                                    options={Amphures}
                                    placeholder="อำเภอ"
                                    getOptionLabel={(option) => option.name_th}
                                    getOptionValue={(option) => option.id}
                                    isClearable={false}
                                    defaultValue={Data.AmphureId}
                                    value={
                                      Data.AmphureId
                                        ? Amphures.find((data) => {
                                          return data.id === Data.AmphureId;
                                        })
                                        : null
                                    }
                                    onChange={(data) => { GetDistrict(data) }}
                                    isDisabled={!Data.ProvinceId}
                                    required
                                    className={`w-100 select-list ` + (!Data.AmphureId && validated ? 'validate-fail' : '') }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>ตำบล</Form.Label>
                                </Col>
                                <Col lg={8}>
                                  <Select
                                    options={Districts}
                                    placeholder="ตำบล"
                                    getOptionLabel={(option) => option.name_th}
                                    getOptionValue={(option) => option.id}
                                    isClearable={false}
                                    defaultValue={Data.DistrictId}
                                    value={
                                      Data.DistrictId
                                        ? Districts.find((data) => {
                                          return data.id === Data.DistrictId;
                                        })
                                        : null
                                    }
                                    onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : '' }) }}
                                    isDisabled={!Data.AmphureId && Districts.length > 0}
                                    required
                                    className={`w-100 select-list ` + (!Data.DistrictId && validated ? 'validate-fail' : '') }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>รหัสไปรษณีย์</Form.Label>
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="number"
                                    name="postcode"
                                    defaultValue={Data.PostCode}
                                    onChange={(e) => checkPostCode(e)}
                                    placeholder="รหัสไปรษณีย์"
                                    required
                                    className={`text-left search-btn-top ` + (!Data.PostCode && validated ? 'validate-fail' : '') }
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>ที่อยู่</Form.Label>
                                </Col>
                                <Col lg={8}>
                                  <Form.Control
                                    type="text"
                                    name="address"
                                    value={Data.Address}
                                    onChange={ (e) => setData({ ...Data, Address: e.target.value }) }
                                    placeholder=""
                                    className="text-left search-btn-top"
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col lg={6} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label>E-mail</Form.Label>
                                </Col>
                                <Col className='text-left'>
                                  <Form.Control
                                    type="text"
                                    name="email"
                                    value={Data.Email}
                                    onChange={(e) => setData({ ...Data, Email: e.target.value })}
                                    placeholder="E-mail"
                                    required
                                    className={`text-left search-btn-top ` + (!Data.Email && validated ? 'validate-fail' : '') }
                                  ></Form.Control>
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col lg={2} className="text-left">

                                </Col>
                                <Col className='text-left'>
                                  <label className='text-danger mt-2'>{ErrorEmail}</label>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} className="mt-3 mb-3">
                              <Row className="align-items-center">
                                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label> วันเริ่มติดต่อ</Form.Label>
                                </Col>
                                <Col>
                                  <DatePicker
                                    placeholder="วันเริ่มติดต่อ"
                                    suffixIcon={<img src={Calendar} />}
                                    value={Data.ContactDate}
                                    onChange={(data) => setData({ ...Data, ContactDate: data })}
                                    required
                                    className={(!Data.ContactDate && validated ? 'validate-fail' : '') }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className=" increase-contract">
                            {
                              Data.Contacts?.map((contact, key) => {
                                return (
                                  <Col xs={12}>
                                    <Row className="align-items-end">
                                      <Col lg={10} className="set-increase-contract">
                                        <Row className="align-items-center">
                                          <Col lg={4} className="mt-2 mb-2">
                                            <Row className="align-items-center">
                                              <Col lg={3} className="text-left text-lg-right pr-lg-0">
                                                <Form.Label>ชื่อผู้ติดต่อ</Form.Label>
                                              </Col>
                                              <Col className='set-margin'>
                                                <Form.Control
                                                  type="text"
                                                  placeholder="ชื่อผู้ติดต่อ"
                                                  name="nameContract"
                                                  value={contact.name}
                                                  onChange={
                                                    (e) => setData(prevState => ({
                                                      ...Data,
                                                      Contacts: prevState.Contacts.map(
                                                        el => el.key === key ? { ...el, name: e.target.value } : el
                                                      )
                                                    }))
                                                  }
                                                  required
                                                  className={'text-left search-btn-top ' + (!contact.name && validated ? 'validate-fail' : '') }
                                                ></Form.Control>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col lg={4} className="mt-2 mb-2">
                                            <Row className="align-items-center">
                                              <Col lg={4} className="text-left text-lg-right pr-lg-0">
                                                <Form.Label>เบอร์โทรศัพท์</Form.Label>
                                              </Col>
                                              {/* <Col>
                                                <Form.Control
                                                  type="text"
                                                  name="phone"
                                                  value={contact.phone}
                                                  onChange={
                                                    (e) => setData(prevState => ({
                                                      ...Data,
                                                      Contacts: prevState.Contacts.map(
                                                        el => el.key === key ? { ...el, phone: e.target.value } : el
                                                      )
                                                    }))
                                                  }
                                                  placeholder=""
                                                  required
                                                  className={'text-left search-btn-top ' + (!contact.phone && validated ? 'validate-fail' : '') }
                                                ></Form.Control>
                                              </Col> */}
                                              <Col >
                                                {/* <div className={(contact.phone.length === 0 && validated ? 'validate-fail' : '') }> */}
                                                  <ReactTagInput
                                                    placeholder="เบอร์โทรศัพท์"
                                                    editable={true}
                                                    readOnly={false}
                                                    removeOnBackspace={true}
                                                    maxTags={2}
                                                    tags={contact.phone && contact.phone.length > 0 ? contact.phone : []}
                                                    required
                                                    onChange={
                                                      (newTags) => setData(prevState => ({
                                                        ...Data,
                                                        Contacts: prevState.Contacts.map(
                                                          el => el.key === key ? { ...el, phone: newTags }: el
                                                        )
                                                      })) 
                                                    }
                                                    // onKeyPress={(event) => {
                                                    //   if (!/[0-9]/.test(event.key)) {
                                                    //     event.preventDefault();
                                                    //   }
                                                    // }}
                                                  />
                                                {/* </div> */}
                                              </Col>
                                            </Row>
                                          </Col>

                                          <Col
                                            lg={2}
                                            xl={2}
                                            className="d-flex align-items-center justify-content-center mt-3 mb-3"
                                          >
                                            {
                                              key > 0 &&
                                              (
                                                <Button
                                                  className="button"
                                                  onClick={() => RemoveContact(key)}
                                                >
                                                  <img src={Delete} />
                                                </Button>
                                              )
                                            }
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={2} xl={2} className='setting-add mb-3'>
                                        <Row className="justify-content-center align-items-end">
                                          {
                                            Data.Contacts.length - 1 === key && (
                                              <Col sm={12} >
                                                <Button
                                                  className="button"
                                                  onClick={() => InsertContact()}
                                                >
                                                  <img src={Increase} /> &nbsp; เพิ่ม
                                                </Button>
                                              </Col>
                                            )
                                          }
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                          <Row>
                            <Col className="mt-3 mb-3">
                              <Row>
                                <Col lg={1} className="text-left text-lg-right pr-lg-0">
                                  <Form.Label style={{ 'FontSize': '16px' }}>
                                    หมายเหตุ
                                  </Form.Label>
                                </Col>
                                <Col lg={8}>
                                  <Form.Control
                                    as="textarea"
                                    placeholder="หมายเหตุ"
                                    rows={5}
                                    value={Data.Note}
                                    required
                                    onChange={(e) => setData({ ...Data, Note: e.target.value })}
                                    className={'text-area ' + (!Data.Note && validated ? 'validate-fail' : '') }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row className="mt-3 mb-2">
                            <Col className="d-flex justify-content-start">
                              <Button type="submit" className="increase">
                                <img src={Save} />
                                <span>บันทึก</span>
                              </Button>
                            </Col>
                            <Col className="d-flex justify-content-end">
                              <Button type="button" className="increase" onClick={close}>
                                <img src={Out} />
                                <span>ออก</span>
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </Tab>
                  </Tabs>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
