import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import Select from 'react-select';

import MyPagination from 'Components/MyPagination'
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import ReportImage from 'assets/icon/report-menu.png'
import Back from 'assets/icon/back.png';
// image
import CALENDAR from 'assets/icon/Calendar.png';

import { useDispatch, useSelector } from 'react-redux';
//API
import { loadAuth } from 'actions/auth';
import { LoadIncomeReport } from 'actions/reports/index'

import { DatePicker } from 'antd';
import moment from 'moment';

import * as htmlToImage from 'html-to-image';


export default function Stock() {
  const history = useHistory();
  const dispatch = useDispatch();

  const permission = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
  const { log_permissions } = useSelector( (state) => state.user.permissions)
  const report_permission = log_permissions.find(item => item.permission.name === 'รายงาน' && item.middle === permission.middle)

  const { RangePicker } = DatePicker;

  const [Search, setSearch] = useState({
    Date: moment(new Date()),
  })

  const getAuth = async () => {
    try {
      const params = {
        name: ['รายงานคิวช่างกระจก'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        // alert(response.data.message)
        // history.push('/report');
      }
    } catch (error) {
      // history.push('/report');
      console.error(error);
    }
  };

  const [errors, setErrors] = useState([]);
  const [Loading,setLoading] = useState(false);

  const [IncomeReport,setIncomeReport] = useState([])


  const getIncomeReport = async () => {
    try {
      const params = {
        date: moment(Search?.Date).format('YYYY-MM-DD'),
				is_branche: permission.middle ? 'middle' : 'branche',
      };
      const response = await dispatch(LoadIncomeReport(params));
        setIncomeReport(response.data);
				// console.log(IncomeReport)
        setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

	const DownloadImage = () => {
    htmlToImage.toPng(document.getElementById('print-image'), { quality: 1 })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = `${'IncomeReport-' + moment(new Date()).format('Y-MM-DD') }.png`;
      link.href = dataUrl;
      link.click();
    });
  }

	const [copyText,setcopyText] = useState('Copy Text');
	const handleCopy = () => {
		const textToCopy = document.getElementById('text-plan').innerText;
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				setcopyText('Copied Text!')
				setTimeout( () => {
					setcopyText('Copy Text')
				},700)
				console.log('Text copied to clipboard:', textToCopy);
			})
			.catch(error => {
				console.error('Error copying text:', error);
			});
  };

  useEffect( () =>{
    // getAuth()
    getIncomeReport();
  },[Search.Date]);

  function close() {
    history.push(`/report`);
  }
  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Bill Report bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; รายงานสรุปยอดขาย
                    </b>
                </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; รายงานสรุปยอดขาย
                    </b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <DatePicker
                        value={Search.Date}
                        onChange={(date) => setSearch({...Search, Date: date})}
                        suffixIcon={<img src={CALENDAR} />}
                        separator="-"
                        className="calendar-antd-dashboard"
                        style={{
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                      />
                  </Col>
									<Col
                    lg={3}
                    xl={4}
                    className="d-flex justify-content-center mt-3 mb-2"
                  >
										<Button type="button" className="button" onClick={() => handleCopy()}>
                      {copyText}
                    </Button>
									</Col>
                  <Col
                    lg={4}
                    xl={4}
                    className="d-flex justify-content-center mt-3 mb-2"
                  >
                    <Button type="button" className="button" onClick={() => DownloadImage()}>
                      Download Image
                    </Button>
                  </Col>
                  
                </Row>
								<div id="text-plan" className="text-left" style={{height: '0px', opacity: '0'}}>
									{permission.middle === true ? (
											//Middle Text
											<>
												ระบบกลาง<br/>
												ยอดขาย<br/>
												---------------------------------------------------------------<br/>
												ยอดJob : { IncomeReport?.middle?.total_job_all } บาท ( {IncomeReport?.middle?.total_job_all_for_unpaid} )<br/>
												ยอดค้างจ่าย : { IncomeReport?.middle?.total_job_unpaid } บาท<br/>
												ยอดขายบัตรเครดิต : { IncomeReport?.middle?.total_slip } บาท<br/>
												ยอดขายเงินสด : { IncomeReport?.middle?.total_cash } บาท<br/>
												ยอดขายเช็ค : { IncomeReport?.middle?.total_check } บาท<br/>
												ยอดขาย(เงินโอน)<br/>
												{
													IncomeReport?.middle?.tranfer_pay?.map((item,index) => {
														return(
															<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
														)
													})
												}
												ยอดขาย(Package)<br/>
												{
													IncomeReport?.middle?.package_pays?.map((item,index) => {
														return(
															<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
														)
													})
												}

												<br/>
												ยอดชำระค้างจ่าย<br/>
												---------------------------------------------------------------<br/>
												ยอดชำระค้างจ่าย : { IncomeReport?.middle?.unpaid_job } บาท<br/>
												ยอดชำระค้างจ่ายบัตรเครดิต : { IncomeReport?.middle?.unpaid_slip } บาท<br/>
												ยอดชำระค้างจ่ายเงินสด : { IncomeReport?.middle?.unpaid_cash } บาท<br/>
												ยอดชำระค้างจ่ายเช็ค : { IncomeReport?.middle?.unpaid_check } บาท<br/>
												ยอดชำระค้างจ่าย(เงินโอน)<br/>
												{
													IncomeReport?.middle?.unpaid_tranfer_pay?.map((item,index) => {
														return(
															<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
														)
													})
												}
												<br/>
											</>
									) : (<></>)}

									{
										IncomeReport?.branche?.map((b,index) => {
											return(
												<>
													{b.name}<br/>
													ยอดขาย<br/>
													---------------------------------------------------------------<br/>
													ยอดJob : { b?.total_job_all } บาท ( {b?.total_job_all_for_unpaid} )<br/>
													ยอดค้างจ่าย : { b?.total_job_unpaid } บาท<br/>
													ยอดขายบัตรเครดิต : { b?.total_slip } บาท<br/>
													ยอดขายเงินสด : { b?.total_cash } บาท<br/>
													ยอดขายเช็ค : { b?.total_check } บาท<br/>
													ยอดขาย(เงินโอน)<br/>
													{
														b?.tranfer_pay?.map((item,index) => {
															return(
																<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
															)
														})
													}
													ยอดขาย(Package)<br/>
													{
														b?.package_pays?.map((item,index) => {
															return(
																<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
															)
														})
													}

													<br/>
													ยอดชำระค้างจ่าย<br/>
													---------------------------------------------------------------<br/>
													ยอดชำระค้างจ่าย : { b?.unpaid_job } บาท<br/>
													ยอดชำระค้างจ่ายบัตรเครดิต : { b?.unpaid_slip } บาท<br/>
													ยอดชำระค้างจ่ายเงินสด : { b?.unpaid_cash } บาท<br/>
													ยอดชำระค้างจ่ายเช็ค : { b?.unpaid_check } บาท<br/>
													ยอดชำระค้างจ่าย(เงินโอน)<br/>
													{
														b?.unpaid_tranfer_pay?.map((item,index) => {
															return(
																<>&nbsp;&nbsp;- {item.name} : { item.total } บาท<br/></>
															)
														})
													}
													<br/>
												</>
											)
										})
									}

								</div>
								<div id="print-image" className="print-image">
									{permission.middle === true ? (
										<Row className="g-0 justify-content-center mt-3 m-0 mb-3">

										<Col sm={12} className="p-2 pt-0"><div>
												<h3 className="text-left m-0">ระบบกลาง</h3>
										</div></Col>
										<Row className="m-0">
											<Col sm={12} lg={6} className="p-0 h-100">
												<div className="p-2 bg-white rounded">

													<h4 className="text-left m-0 mt-1">ยอดขาย</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดJob</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_job_all } บาท ( {IncomeReport?.middle?.total_job_all_for_unpaid} )</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดค้างจ่าย</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_job_unpaid } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดขายบัตรเครดิต</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_slip } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดขายเงินสด</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_cash } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดขายเช็ค</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_check } บาท</Col>
															</Row>
														</Col>
													</Row>

													<h4 className="text-left m-0 mt-2">ยอดขาย(เงินโอน)</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														{
															IncomeReport?.middle?.tranfer_pay?.map((item,index) => {
																return(
																	<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																		<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																				<Col sm={6} className="border-0 text-left">{item.name}</Col>
																				<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																				<Col sm={5} className="border-0">{ item.total } บาท</Col>
																		</Row>
																	</Col>
																)
															})
														}
													</Row>

													<h4 className="text-left m-0 mt-2">ยอดขาย(Package)</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														{
															IncomeReport?.middle?.package_pays?.map((item,index) => {
																return(
																	<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																		<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																				<Col sm={6} className="border-0 text-left">{item.name}</Col>
																				<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																				<Col sm={5} className="border-0">{ item.total } บาท</Col>
																		</Row>
																	</Col>
																)
															})
														}
													</Row>

												</div>
											</Col>

											<Col sm={12} lg={6} className="p-0 h-100">
												<div className="p-2 bg-white rounded">

													<h4 className="text-left m-0 mt-1">ยอดชำระค้างจ่าย</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่าย</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.unpaid_job } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายบัตรเครดิต</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.unpaid_slip } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายเงินสด</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.unpaid_cash } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายเช็ค</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.unpaid_check } บาท</Col>
															</Row>
														</Col>
													</Row>

													<h4 className="text-left m-0 mt-2">ยอดชำระค้างจ่าย(เงินโอน)</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														{
															IncomeReport?.middle?.unpaid_tranfer_pay?.map((item,index) => {
																return(
																	<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																		<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																				<Col sm={6} className="border-0 text-left">{item.name}</Col>
																				<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																				<Col sm={5} className="border-0">{ item.total } บาท</Col>
																		</Row>
																	</Col>
																)
															})
														}
													</Row>


													<h4 className="text-left m-0 mt-1">ยอดรถที่เข้ารับบริการวันนี้</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">รถที่เข้ารับบริการวันนี้</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.car_counts } คัน</Col>
															</Row>
														</Col>
													</Row>

													<h4 className="text-left m-0 mt-1">รายงาน Showroom วันนี้</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอด Comission</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_commissions } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดขาย</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_showroom_job } บาท</Col>
															</Row>
														</Col>
														<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
															<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																	<Col sm={6} className="border-0 text-left">ยอดรับจริง</Col>
																	<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																	<Col sm={5} className="border-0">{ IncomeReport?.middle?.total_real_showroom } บาท</Col>
															</Row>
														</Col>
													</Row>

													{/* <h4 className="text-left m-0 mt-2">ยอดชำระค้างจ่าย(Package)</h4>
													<Row className="g-0 justify-content-center m-0 p-2">
														{
															IncomeReport?.middle?.package_pays?.map((item,index) => {
																return(
																	<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																		<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																				<Col sm={6} className="border-0 text-left">{item.name}</Col>
																				<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																				<Col sm={5} className="border-0">{ item.total } บาท</Col>
																		</Row>
																	</Col>
																)
															})
														}
													</Row> */}

												</div>
											</Col>


										</Row>
										</Row>
									) : (<></>)}

									{
										IncomeReport?.branche?.map((b,index) => {
											return(
												<Row className="g-0 justify-content-center mt-3 m-0 mb-3">

												<Col sm={12} className="p-2 pt-0"><div>
														<h3 className="text-left m-0">{b.name}</h3>
												</div></Col>
												<Row className="m-0">
													<Col sm={12} lg={6} className="p-0 h-100">
														<div className="p-2 bg-white rounded">
			
															<h4 className="text-left m-0 mt-1">ยอดขาย</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดJob</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.total_job_all } บาท  ( {b?.total_job_all_for_unpaid} )</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดค้างจ่าย</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.total_job_unpaid } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดขายบัตรเครดิต</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.total_slip } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดขายเงินสด</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.total_cash } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดขายเช็ค</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.total_check } บาท</Col>
																	</Row>
																</Col>
															</Row>
			
															<h4 className="text-left m-0 mt-2">ยอดขาย(เงินโอน)</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																{
																	b?.tranfer_pay?.map((item,index) => {
																		return(
																			<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																				<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																						<Col sm={6} className="border-0 text-left">{item.name}</Col>
																						<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																						<Col sm={5} className="border-0">{ item.total } บาท</Col>
																				</Row>
																			</Col>
																		)
																	})
																}
															</Row>
			
															<h4 className="text-left m-0 mt-2">ยอดขาย(Package)</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																{
																	b?.package_pays?.map((item,index) => {
																		return(
																			<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																				<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																						<Col sm={6} className="border-0 text-left">{item.name}</Col>
																						<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																						<Col sm={5} className="border-0">{ item.total } บาท</Col>
																				</Row>
																			</Col>
																		)
																	})
																}
															</Row>
			
														</div>
													</Col>
			
													<Col sm={12} lg={6} className="p-0 h-100">
														<div className="p-2 bg-white rounded">
			
															<h4 className="text-left m-0 mt-1">ยอดชำระค้างจ่าย</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่าย</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.unpaid_job } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายบัตรเครดิต</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.unpaid_slip } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายเงินสด</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.unpaid_cash } บาท</Col>
																	</Row>
																</Col>
																<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">ยอดชำระค้างจ่ายเช็ค</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.unpaid_check } บาท</Col>
																	</Row>
																</Col>
															</Row>
			
															<h4 className="text-left m-0 mt-2">ยอดชำระค้างจ่าย(เงินโอน)</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																{
																	b?.unpaid_tranfer_pay?.map((item,index) => {
																		return(
																			<Col sm={12} className="rounded mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																				<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																						<Col sm={6} className="border-0 text-left">{item.name}</Col>
																						<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																						<Col sm={5} className="border-0">{ item.total } บาท</Col>
																				</Row>
																			</Col>
																		)
																	})
																}
															</Row>

															<h4 className="text-left m-0 mt-1">ยอดรถที่เข้ารับบริการวันนี้</h4>
															<Row className="g-0 justify-content-center m-0 p-2">
																<Col sm={12} className="rounded  mt-2" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
																	<Row className="g-0 justify-content-center mt-2 m-0 p-2">
																			<Col sm={6} className="border-0 text-left">รถที่เข้ารับบริการวันนี้</Col>
																			<Col sm={1} className="border-0 p-0" style={{color: 'rgba(0,0,0,0.4)'}}>|</Col>
																			<Col sm={5} className="border-0">{ b?.car_counts } คัน</Col>
																	</Row>
																</Col>
															</Row>

														</div>
													</Col>
			
			
												</Row>
												</Row>
											)
										})
									}
								</div>



									

              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
