import React, { useState,useEffect } from 'react';

import Footer from 'Components/Footer';
import { useHistory, useParams,useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button
} from 'reactstrap';

import { Container, Row, Col } from 'react-bootstrap';
import CustomerInfo from './Components/CustomerInfo';
import CarInfo from './Components/CarInfo/Index';
import CustomerHistory from 'MiddleSystems/Components/Member/CustomerHistory';
import MemberPackage from 'MiddleSystems/Components/Member/MemberPackage';
import OverView from 'MiddleSystems/Member/Components/OverView';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Service from 'assets/icon/management.png';
import Back from 'assets/icon/back.png';
import MemberImage from 'assets/icon/member.png';


import {FindCustomer } from 'actions/customer/index';

export default function MemberInformation() {
  const search = useLocation().search;
  const activeTab = new URLSearchParams(search).get('activeTab');
  const [stateActiveTab, setStateActiveTab] = useState({ activeTab: activeTab ? `tab${activeTab}` : 'tab1' });

  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  function close() {
    history.push(`/member`);
  }

  function ActiveTap(tap) {
    history.push({
      pathname: `/member/edit/${id}`,
      search: `?activeTab=${tap}`
    })
  }

  const [Data, setData] = useState({
    Customer: null,
    Type: null,
  });

  useEffect(() => {
    GetCustomerInfo();
  }, []);

  const GetCustomerInfo = () => {
    dispatch(FindCustomer(id)).then( response => {
      setData({
        Customer: response.data.data,
        Type: response.data.data.type,
      });
    }).catch(e => {
      console.log(e);
    })
  }
  return (
    <>
      
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="memberinformation">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b>
                      <Link to={`/member`} className='mr-2'>Customer (B2C)</Link>
                      &gt; {Data.Type} &#62; ข้อมูลส่วนตัว
                    </b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div className="modal-header-mobile">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <img src={Back} />
                  </button>
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={MemberImage} />
                    <b>
                      <Link to={`/member`} className='mr-2'>Customer (B2C)</Link>
                      &gt; {Data.Type} &#62; ข้อมูลส่วนตัว
                    </b>
                  </h5>
                </div>
                <div className="modal-body ">
                  <Col className="icon-menu">
                    <TabsIcon />
                  </Col>
                  <Col className="tabs-form mb-3">
                    <Row className="m-0">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab1' });
                          ActiveTap('1');
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab1' && 'active'
                        }
                      >
                        ข้อมูลส่วนตัว
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab2' });
                          ActiveTap('2');
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab2' && 'active'
                        }
                      >
                        ข้อมูลรถ
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab3' });
                          ActiveTap('3');
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab3' && 'active'
                        }
                      >
                        ประวัติรับบริการ
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab4' });
                          ActiveTap('4');
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab4' && 'active'
                        }
                      >
                        ภาพรวม
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setStateActiveTab({ activeTab: 'tab5' });
                          ActiveTap('5');
                        }}
                        className={
                          stateActiveTab.activeTab === 'tab5' && 'active'
                        }
                      >
                        แพ็กเกจ
                      </Button>
                    </Row>
                  </Col>
                  <Col style={{ padding: 0 }}>
                    {stateActiveTab.activeTab === 'tab1' && <CustomerInfo />}
                    {stateActiveTab.activeTab === 'tab2' && <CarInfo />}
                    {stateActiveTab.activeTab === 'tab3' && <CustomerHistory />}
                    {stateActiveTab.activeTab === 'tab4' && Data.Customer && <OverView Customer={Data.Customer} />}
                    {stateActiveTab.activeTab === 'tab5' && <MemberPackage />}
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
