import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Pagination,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  Button,
  Label,
  Table,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';


import MyPagination from 'Components/MyPagination'

//Api
import { CarOwnerList } from 'actions/customer/car';
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';

// Icon
import note_car from 'assets/icon/note-car.png';
import add_menu from 'assets/icon/add_menu.png';
import SearchImage from 'assets/icon/search.png'
import Returnvalue from 'assets/icon/return-value.png'

export default function Index(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const [isLoading, setLoading] = useState(true);
  const [Brands, setBrands] = useState([]);
  const [Models, setModels] = useState([]);

  const [PlateType, setPlateType] = useState([
    { label: 'ป้ายแดง', value: 'ป้ายแดง' },
    { label: 'ป้ายธรรมดา', value: 'ป้ายธรรมดา' },
    { label: 'ไม่มีป้าย', value: 'ไม่มีป้าย' },
    { label: 'ป้ายขาว', value: 'ป้ายขาว' },
    { label: 'ป้ายประมูล', value: 'ป้ายประมูล' },
  ]);

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [Search, setSearch] = useState({
    Keyword: null,
    PlateType: null,
    CarBrandId: null,
    CarSerieId: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closed = () => {
    setShow(false);
  };

  const [CustomerCars, setCustomerCars] = useState([]);
  const GetCustomerCar = async () => {
    setLoading(true)
    const params = {
      keyword: Search.Keyword,
      customer_id: id,
      plate_type: Search.PlateType,
      car_brand_id: Search.CarBrandId,
      car_serie_id: Search.CarSerieId,
      limit: Search.PerPage,
      page: Search.CurrentPage
    }
    try {
      const response = await dispatch(CarOwnerList(params));
      if(response.data.data && response.data.data.data){
        setCustomerCars(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setCustomerCars(response.data.data);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function ChangeModel(data) {
    setSearch({...Search, CarBrandId: data.id })
    GetCarModel(data.id)
  }

  function goToJob(l,y){
    const data = {
      customer_id : '',
      car_id: '',
      status_job: 'new',
      type: y,
      PackageList:[],
      show_room_employee_id: '',
      company_id: '',
    }
    localStorage.setItem('job-inside-data',JSON.stringify(data));
    history.push(l);
  }

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...Search, 
      Keyword: null,
      PlateType: null,
      CarBrandId: null,
      CarSerieId: null,
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
    })

    setClearSearch(true)
  }
  
  useEffect(() => {
    GetBrand();
  }, []);

  useEffect(() => {
    if (!ClearSearch) GetCustomerCar();
    setClearSearch(false)
  }, [Search.PerPage, Search.CurrentPage, ClearSearch]);

  return (
    <>
      <Container fluid className="carinfo">
        <Form>
          <Row className="justify-content-center align-items-center mb-3 ">
            <Col xs={12} xl={2} className="set-padding-search mt-2 mb-2">
              <Form.Control
                className="input"
                type="text"
                placeholder="ทะเบียนรถ"
                value={Search.Keyword ? Search.Keyword : ''}
                onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
              ></Form.Control>
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2 text-left">
              <Label className="search-label d-block d-lg-none">
                ประเภทป้าย
              </Label>

              <Select
                options={PlateType}
                placeholder="ประเภทป้าย"
                isClearable={false}
                isSearchable={false}
                value={Search.PlateType ? PlateType.find((data) => {return data.value === PlateType.value}) : null}
                onChange={(data) => setSearch({...Search, PlateType: data.value})}
                className="select-list"
              />
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2 text-left">
              <Label className="search-label d-block d-lg-none">ยี่ห้อรถ</Label>
              <Select
                options={Brands}
                placeholder="ยี่ห้อรถ"
                className="select-add-member"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={Search.CarBrandId ? Brands.find((data) => {return data.id === Brands.id}) : null}
                onChange={(data) => ChangeModel(data)}
              />
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2 text-left">
              <Label className="search-label d-block d-lg-none">รุ่นรถ</Label>
              <Select
                options={Models}
                placeholder="รุ่นรถ"
                className="select-add-member"
                getOptionLabel={option => `${option.model}-${option.car_serie.code}`}
                getOptionValue={option => option.id}
                isDisabled={!Search.CarBrandId}
                value={Search.CarSerieId ? Models.find((data) => {return data.car_serie.id === Models.id}) : null}
                onChange={(data) => setSearch({...Search, CarSerieId: data.car_serie.id })}
              />
            </Col>

            <Col xs={12} xl={2} className="mt-2 mb-2">
              <Button
                className="button"
                style={{ padding: 0, margin: 0 }}
                onClick={() => GetCustomerCar()}
              >
                ค้นหา &nbsp; <img src={SearchImage} />
              </Button>
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2">
              <Button
                className="button"
                style={{ padding: 0, margin: 0 }}
                onClick={ResetSearch}
              >
                คืนค่า &nbsp; <img src={Returnvalue} />
              </Button>
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2">
              <Link to={`/member/edit/${id}/add-car`} className="link">
                <Button type='button' className="button">
                  <img src={add_menu} alt="" /> &nbsp; เพิ่มรายการ
                </Button>
              </Link>
            </Col>
            <Col xs={12} xl={2} className="mt-2 mb-2">
            { level.middle !== true ? (<>
                <Button type="button" className="button mt-3" onClick={handleShow}>
                  JOB หน้าร้าน
                </Button>
                <Modal className="modal-style" centered show={show} onHide={handleClose}>
                <Modal.Header className="modal-header-style">
                  <p>
                    <b>JOB</b>
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closed}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <Row className="justify-content-center set-modal-body-job">
                  <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/inside-data', 'b2c') }} className="button">
                        หน้าร้าน
                      </Button>
                    </Col>

                    <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/company-b2b-show-room', '') }} className="button">
                        Showroom (B2B)
                      </Button>
                    </Col>

                    <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/inside-data-old', 'b2c') }} className="button">
                        ลูกค้าเก่า
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
              </>) : null}
            </Col>
          </Row>
        </Form>
        <Row>
          <Table responsive striped className="custom-table-info text-nowrap">
            <thead className="thead-custom">
              <tr className="text-center">
                <th>ทะเบียนรถ</th>
                <th>ประเภทป้าย</th>
                <th>ยี่ห้อ</th>
                <th>รุ่น</th>
                <th>รหัสรุ่นรถ</th>
                <th>สี</th>
                <th>ขนาดรถ</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="table-custom">
            { 
              CustomerCars?.map(car => {
                return (
                  <tr>
                    <td>{`${car.license_plate}-${car?.province?.name_th}`}</td>
                    <td>{`${car.plate_type}`}</td>
                    <td>{`${car?.car_serie?.car_model?.car_brand?.name}`}</td>
                    <td>{`${car?.car_serie?.car_model?.model}`}</td>
                    <td>{`${car.car_serie && car.car_serie.code}`}</td>
                    <td>{`${car?.color?.name}`}</td>
                    <td>{`${car.car_serie.size}`}</td>
                    <td>
                      <Link to={`/member/edit/${car.customer_id}/car-owner/${car.id}`}>
                        <Button type="button" className="note-car-button">
                          <img src={note_car} />
                        </Button>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </Table>
        </Row>
        <Row className="g-0 mt-3 mb-3">
          <Col lg={4} className="set-page">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>แสดง</p>
              </Col>
              <Col lg={7}>
                <Select
                  className="text-left select-style"
                  aria-label="Default select example"
                  options={Pages}
                  onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                  defaultValue={{ value: 10, label: '10' }}
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>รายการ</p>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex justify-content-end set-paginate">
            <div className="box-paginate">
            {
              !isLoading ? 
                Search.Rows > 1 && Search.PerPage > 0 ?
                  <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                : ''
              : 
                Search.Rows > 1 && Search.PerPage > 0 ?
                  <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                : ''
            }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
