import React, { useState, useEffect } from 'react';
import { useHistory, useParams , Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import { Switch } from 'antd';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';

// Icon
import SaveIcon from 'assets/icon/save.png';
import DeleteIcon from 'assets/icon/delete.png';
import OutIcon from 'assets/icon/new-re-turn.png';
import SettingIcon from 'assets/icon/setting.png';
import BackIcon from 'assets/icon/back.png';

// API
import { BankList, FindBank } from 'actions/settings/bank';
import { FindBankAccount, UpdateBankAccount, DestroyBankAccount } from 'actions/settings/bank_account';
import { loadAuth } from 'actions/auth';

export default function EditBank() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {id, type} = useParams();

  const [Selection, setSelection] = useState({
    Banks: [],
    Branches: [],
    Accounts: []
  })

  const [Data, setData] = useState({
    Active: false,
    Bank: null,
    Branch: null,
    Account: null,
    AccountNumber: null
  });
  const [validated, setValidated] = useState(false);

  const GetSelectBank = async () => {
    try {
      const response = await dispatch(BankList());
      // console.log('response',response);
      if (response) {
        setSelection({ ...Selection,
          Banks: response.data.data
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting/bank');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const GetAccount = async () => {
    try {
     
      if (type ==='bank') {
        const response = await dispatch(FindBank(id));
        const data = response.data.data
        console.log('data',data);
        setData({...Data,
          Active: false,
          Bank: data ? data : '',
          Branch: data && data.branches ? data.branches : '',
          Account: '',
          AccountNumber: ''
        })
      }else{
        const response = await dispatch(FindBankAccount(id));
        if (response) {
          const data = response.data.data
          setData({...Data,
            Active: data.active,
            Bank: data.branch.bank,
            Branch: data.branch,
            Account: data,
            AccountNumber: data.account_number
          })
        }
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    
    const data = {
      id: id,
      data:{
        active: Data.Active,
        bank_name: Data.Bank.name,
        branch_name: Data.Branch.name,
        account_name: Data.Account.name,
        account_number: Data.AccountNumber
      }
    }

    try {
      const response = await dispatch(UpdateBankAccount(data));
     
      if(response){
        setValidated(false);
        history.push('/setting/bank');
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const DeleteBankAccount = async () => {
    try {
      const response = await dispatch(DestroyBankAccount(id));
      if (response) {
        history.push('/setting/bank');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const SelectedBank = (data) => {
    setSelection({ ...Selection,
      Branches: data.branches,
      Accounts: []
    });
    setData({...Data, Bank: data, Branch: null});
  }

  const SelectedBranch = (data) => {
    setSelection({ ...Selection,
      Accounts: data.accounts
    });
    setData({...Data, Branch: data});
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'edit'});
    GetAccount();
    GetSelectBank();
  }, []);
  
  function exit() {
    history.push(`/setting/bank`);
  }

  function close() {
    history.push(`/setting/bank`);
  }
  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={SettingIcon} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/bank" className='link-pagination'>ธนาคาร</Link> &gt; แก้ไขธนาคาร</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={BackIcon} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={SettingIcon} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/bank" className='link-pagination'>ธนาคาร</Link> &gt; แก้ไขธนาคาร</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="set-height">
                    <Row className="g-0 justify-content-start">
                      <Col lg={6}>
                        <Row className="mt-4 mb-4">
                          <Col lg={12} className="d-flex">
                            <Form.Label className="pl-3">ชื่อธนาคาร</Form.Label>
                          </Col>
                          <Col lg={12}>
                            <Select
                              placeholder=""
                              options={Selection.Banks}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              value={Data.Bank}
                              isDisabled
                              onChange={(data) => SelectedBank(data)}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                          <Col lg={12} className="d-flex">
                            <Form.Label className="pl-3">สาขาธนาคาร</Form.Label>
                          </Col>
                          <Col lg={12}>   
                            <Select
                              placeholder=""
                              options={Selection.Branches}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              value={Data.Branch}
                              isDisabled
                              onChange={(data) => SelectedBranch(data)}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                          <Col lg={12} className="d-flex">
                            <Form.Label className="pl-3">ชื่อบัญชี</Form.Label>
                          </Col>
                          <Col lg={12}>
                            <Creatable
                              placeholder=""
                              isClearable
                              options={Selection.Accounts}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              getNewOptionData={(inputValue, optionLabel) => ({
                                id: inputValue,
                                name: optionLabel,
                              })}
                              value={Data.Account}
                              onChange={(data) => setData({...Data, Account: data})}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                          <Col lg={12} className="d-flex">
                            <Form.Label className="pl-3">เลขบัญชี</Form.Label>
                          </Col>
                          <Col lg={12}>
                            <Form.Control
                              type='number'
                              placeholder='เลขบัญชี'
                              required
                              value={Data.AccountNumber}
                              // onKeyDown={(e) => { Data.AccountNumber.length === 14 && e.preventDefault(); }}
                              onInput={(e) => {
                                const format = e.target.value.toString().slice(0,14)
                                setData({ ...Data, AccountNumber: format })}
                              }
                              disabled={Data.AccountNumber === 14}
                            >
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                          <Col lg={12} className="d-flex">
                            <Row>
                              <Col
                                lg={6}
                                className="pl-4 d-flex align-items-center"
                              >
                                {Data.Active ? (
                                  <span>เปิดใช้งาน</span>
                                ) : (
                                  <span>ปิดใช้งาน</span>
                                )}
                              </Col>
                              <Col lg={4}>
                                <Switch checked={Data.Active} onClick={(checked) => setData({...Data, Active: checked})} />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <Row className="g-0 justify-content-between">
                    <Col lg={6} className="mt-2 mb-2">
                      <Row className="justify-content-start">
                        <Col xs={6} lg={5} xl={4}>
                          <Button type="submit" className="button">
                            <img src={SaveIcon} /> &nbsp; บันทึก
                          </Button>
                        </Col>

                        <Col xs={6} lg={5} xl={4}>
                          <Button type="button" className="button" onClick={() => DeleteBankAccount()}>
                            <img src={DeleteIcon} /> &nbsp; ลบ
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="mt-2 mb-2">
                      <Row className="justify-content-end">
                        <Col lg={5} xl={4}>
                          <Button type="button" className="button" onClick={exit}>
                            <img src={OutIcon} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}