import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";

import 'Setting/PreviewBill/Component/css/bill-4.css';
import dayjs from 'dayjs'

const THBText = require('assets/libraries/thai-baht-text') 

export class Bill4 extends React.PureComponent {
  render() {

    const { Bill } = this.props
    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    // let decode = str => {
    //   return str.replace(/&#(\d+);/g, function(match, dec) {
    //     return String.fromCharCode(dec);
    //   });
    // }

    // const markPayment = (type) => {
    //   if (type === Bill?.payment_histories?.payment_method) {
    //     return decode('&#10003;')
    //   }
    // }

    const payment = (payment) => {
      switch (payment) {
        case 'cash':
          return 'เงินสด'
        case 'tranfer':
          return 'เงินโอน'
        case 'slip':
          return 'สลิป'
        case 'check':
          return 'เช็ค'
      }
    }

    return (
      <div id="bill-4" className='bill-4'>
        <div className='half-left'>
          <div className='type'>
            <p>ต้นฉบับ</p>
          </div>

          <div className='header'>
            <div className="group-address">
              <p>Mr.Kleane สาขา { Bill?.job?.branch?.name }</p>
              <p>{ Bill?.job?.branch?.address }</p>
              <p>{ Bill?.job?.branch?.phone }</p>
            </div>
            <div className="group-invoice">
              <p>ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</p>
              <div className='inline' style={{ margin: '0 10px 0 10px'}}>
                <p className='label'>ลำดับที่</p>
                <p className='input-data'></p>
              </div>

              <div className='inline' style={{ margin: '0 10px 0 10px'}}>
                <p className='label'>เลขที่</p>
                <p className='input-data'>{ Bill?.bill_code }</p>
              </div>
            </div>
          </div>

          <div className='tax'>
            <p>เลขประจำตัวผู้เสียภาษี { Bill?.job?.customer?.citizen_id }</p>
          </div>

          <div className='group-customer'>
            <div className='inline'>
              <p className="label">ชื่อผู้ซื้อ (Customer's name)</p>
              <p className='input-data'>{ Bill?.job?.customer?.type === 'บุคคลธรรมดา' ? `${Bill?.job?.customer?.prefix} ${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` : Bill?.job?.customer?.organization }</p>
              <p className="label">วันที่ (Date)</p>
              <p className='input-data'>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
            </div>

            <div className='inline'>
              <p className="label">ที่อยู่ (Address)</p>
              <p className='input-data'>{ Bill?.job?.customer?.address }</p>
            </div>

            <div className='inline'>
              <p className='input-data' style={{ minHeight: '22px' }}></p>
            </div>
          </div>

          <div className='group-table'>
            <div className='header'>
              <div className='group-header'>
                <div className="sub-header">
                  <p>ลำดับ</p>
                  <p>(No)</p>
                </div>
                <div className="sub-header">
                  <p>รายการ</p>
                  <p>(Description)</p>
                </div>
                <div className="sub-header">
                  <p>จำนวนหน่วย</p>
                  <p>(Quantity)</p>
                </div>
                <div className="sub-header">
                  <p>ราคาต่อหน่วย</p>
                  <p>(Unit Price)</p>
                </div>
                <div className="sub-header">
                  <p>ส่วนลด</p>
                  <p>(Discount)</p>
                </div>
                <div className="sub-header">
                  <p>จำนวนเงิน</p>
                  <p>(Amount)</p>
                </div>
              </div>
            </div>
            <div className='body'>
              {
                // [...Array(7)].map((e, i) => {
                Bill?.job?.job_details.map((data, index) => {
                  return(
                    <div className='group-body'>
                      <div className="sub-body">
                        <p>{ index + 1}</p>
                      </div>
                      <div className="sub-body">
                        <p>{ renderProduct(data) }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.quantity }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.price }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.discount }</p>
                      </div>
                      <div className="sub-body conflict">
                        <div className='main'>
                          <p>{ data.total }</p>
                        </div>
                        {/* <div className='intervene'>
      
                        </div> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className='total'>
            <p className='title'>(Discount) ส่วนลด</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.discount }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>หักภาษี ณ ที่จ่าย { `${Bill?.job?.tax ? `(${Bill?.job?.tax}%)` : '' }` }</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.tax_calculate }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>ภาษีมูลค่าเพิ่ม { `${Bill?.job?.vat ? `(${Bill?.job?.vat}%)` : '' }` }</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.vat_calculate }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>(Total Amount) จำนวนเงินรวมทั้งสิ้น</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.total_price }</p>
            </div>
          </div>

          <div className='total-character'>
            <p className='title'>จำนวนเงินรวมทั้งสิ้น (ตัวอักษร)</p>
            <div className='group'>
              <p className='title'>Total Amount (in letters)</p>
              <div className='data-box'>
                <p>{ Bill?.job && THBText(Bill?.job?.total_price) }</p>
              </div>
            </div>
          </div>
          
          <div>
            <div className='inline'>
              <div className='pay-by'>
                <p className='label' style={{ marginRight: '10px' }}>ชำระโดย</p>
              </div>
              <div style={{ width: '100%' }}>
                {
                  Bill?.payment_histories.map((data, index) => {
                    return(
                      <>
                        {
                          payment(data.payment_method) === 'เงินสด' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'เงินโอน' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'สลิป' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'เช็ค' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em',  width: '100%' }}>
                              <div className='inline' style={{ width: '100%' }}>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>เช็คธนาคาร</p>
                                  <p className='input-data'></p>
                                </div>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>สาขา</p>
                                  <p className='input-data'></p>
                                </div>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>เลขที่</p>
                                  <p className='input-data'></p>
                                </div>
                              </div>
                            </label>
                          </div>
                        }
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div className='group-note'>
            <div className='note'>
              <p>หมายเหตุ : ราคาสินค้าหรือบริการดังกล่าว</p>
              <p>ได้รวมภาษีมูลค่าเพิ่มไว้แล้ว</p>
            </div>
            <div className='signature'>
              <p className='input-data' style={{ width: '80%', margin: 'auto'}}></p>
              <p style={{ marginTop: '5px'}}>ผู้รับเงิน (Collector)</p>
            </div>
          </div>
        </div>
        
        <div className='half-right'>
          <div className='type'>
            <p>สำเนา</p>
          </div>

          <div className='header'>
            <div className="group-address">
              <p>Mr.Kleane สาขา { Bill?.job?.branch?.name }</p>
              <p>{ Bill?.job?.branch?.address }</p>
              <p>{ Bill?.job?.branch?.phone }</p>
            </div>
            <div className="group-invoice">
              <p>ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</p>
              <div className='inline' style={{ margin: '0 10px 0 10px'}}>
                <p className='label'>ลำดับที่</p>
                <p className='input-data'></p>
              </div>

              <div className='inline' style={{ margin: '0 10px 0 10px'}}>
                <p className='label'>เลขที่</p>
                <p className='input-data'>{ Bill?.bill_code }</p>
              </div>
            </div>
          </div>

          <div className='tax'>
            <p>เลขประจำตัวผู้เสียภาษี { Bill?.job?.customer?.citizen_id }</p>
          </div>

          <div className='group-customer'>
            <div className='inline'>
              <p className="label">ชื่อผู้ซื้อ (Customer's name)</p>
              <p className='input-data'>{ Bill?.job?.customer?.type === 'บุคคลธรรมดา' ? `${Bill?.job?.customer?.prefix} ${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` : Bill?.job?.customer?.organization }</p>
              <p className="label">วันที่ (Date)</p>
              <p className='input-data'>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
            </div>

            <div className='inline'>
              <p className="label">ที่อยู่ (Address)</p>
              <p className='input-data'>{ Bill?.job?.customer?.address }</p>
            </div>

            <div className='inline'>
              <p className='input-data' style={{ minHeight: '22px' }}></p>
            </div>
          </div>

          <div className='group-table'>
            <div className='header'>
              <div className='group-header'>
                <div className="sub-header">
                  <p>ลำดับ</p>
                  <p>(No)</p>
                </div>
                <div className="sub-header">
                  <p>รายการ</p>
                  <p>(Description)</p>
                </div>
                <div className="sub-header">
                  <p>จำนวนหน่วย</p>
                  <p>(Quantity)</p>
                </div>
                <div className="sub-header">
                  <p>ราคาต่อหน่วย</p>
                  <p>(Unit Price)</p>
                </div>
                <div className="sub-header">
                  <p>ส่วนลด</p>
                  <p>(Discount)</p>
                </div>
                <div className="sub-header">
                  <p>จำนวนเงิน</p>
                  <p>(Amount)</p>
                </div>
              </div>
            </div>
            <div className='body'>
              {
                // [...Array(7)].map((e, i) => {
                Bill?.job?.job_details.map((data, index) => {
                  return(
                    <div className='group-body'>
                      <div className="sub-body">
                        <p>{ index + 1}</p>
                      </div>
                      <div className="sub-body">
                        <p>{ renderProduct(data) }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.quantity }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.price }</p>
                      </div>
                      <div className="sub-body">
                        <p>{ data.discount }</p>
                      </div>
                      <div className="sub-body conflict">
                        <div className='main'>
                          <p>{ data.total }</p>
                        </div>
                        {/* <div className='intervene'>
      
                        </div> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className='total'>
            <p className='title'>(Discount) ส่วนลด</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.discount }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>หักภาษี ณ ที่จ่าย { `${Bill?.job?.tax ? `(${Bill?.job?.tax}%)` : '' }` }</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.tax_calculate }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>ภาษีมูลค่าเพิ่ม { `${Bill?.job?.vat ? `(${Bill?.job?.vat}%)` : '' }` }</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.vat_calculate }</p>
            </div>
          </div>

          <div className='total'>
            <p className='title'>(Total Amount) จำนวนเงินรวมทั้งสิ้น</p>
            <div className='data-box' style={{ width: '20%' }}>
              <p>{ Bill?.job?.total_price }</p>
            </div>
          </div>

          <div className='total-character'>
            <p className='title'>จำนวนเงินรวมทั้งสิ้น (ตัวอักษร)</p>
            <div className='group'>
              <p className='title'>Total Amount (in letters)</p>
              <div className='data-box'>
                <p>{ Bill?.job && THBText(Bill?.job?.total_price) }</p>
              </div>
            </div>
          </div>
          
          <div>
            <div className='inline'>
              <div className='pay-by'>
                <p className='label' style={{ marginRight: '10px' }}>ชำระโดย</p>
              </div>
              <div style={{ width: '100%' }}>
                {
                  Bill?.payment_histories.map((data, index) => {
                    return(
                      <>
                        {
                          payment(data.payment_method) === 'เงินสด' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'เงินโอน' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'สลิป' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em' }}>{ payment(data.payment_method) }</label>
                          </div>
                        }
                        {
                          payment(data.payment_method) === 'เช็ค' &&
                          <div className='inline'>
                            <div className='model-checkbox'>✓</div>
                            <label style={{ position: 'relative', bottom: '-0.4em',  width: '100%' }}>
                              <div className='inline' style={{ width: '100%' }}>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>เช็คธนาคาร</p>
                                  <p className='input-data'></p>
                                </div>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>สาขา</p>
                                  <p className='input-data'></p>
                                </div>
                                <div className='inline' style={{ width: '100%' }}>
                                  <p className='label'>เลขที่</p>
                                  <p className='input-data'></p>
                                </div>
                              </div>
                            </label>
                          </div>
                        }
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div className='group-note'>
            <div className='note'>
              <p>หมายเหตุ : ราคาสินค้าหรือบริการดังกล่าว</p>
              <p>ได้รวมภาษีมูลค่าเพิ่มไว้แล้ว</p>
            </div>
            <div className='signature'>
              <p className='input-data' style={{ width: '80%', margin: 'auto'}}></p>
              <p style={{ marginTop: '5px'}}>ผู้รับเงิน (Collector)</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}