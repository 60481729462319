import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";
import 'Setting/PreviewBill/Component/css/bill-6.css';

import dayjs from 'dayjs'
const THBText = require('assets/libraries/thai-baht-text') 

export class Bill6 extends React.PureComponent {
  
  render() {

    const { Bill } = this.props

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    const displayPhone = (phone) => {
      if(phone && Array.isArray(phone)) return phone.join(', ')
      if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
    }

    return (
      <div id='bill-6' className='bill-6'>
        <div className='top'>
          <h5>ใบส่งของ</h5>
        </div>

        <div className='detail'>
          <div className='line'>
            <label style={{ width: '50%'}}>JOB ORDER : { Bill?.job?.job_code }</label>
            <div className='inline between' style={{ width: '23%'}}>
              <p>วันที่</p>
              <p>{ dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
            </div>
          </div>
          <div className='line'>
            <div className='inline' style={{ width: '50%'}} >
              <label className="bill-6-label">นามลูกค้า : </label>
              {
                Bill?.job?.customer?.type === 'นิติบุคคล' &&
                <p>{ Bill?.job?.customer?.organization }</p>
              }
              {
                Bill?.job?.customer?.type === 'บุคคลธรรมดา' &&
                <p>{ `${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` }</p>
              }
            </div>
            <div className='inline between' style={{ width: '23%'}}>
              <p>เลขที่บิล</p>
              <p>{ Bill?.bill_code }</p>
            </div>
          </div>
          <div className='line'>
            <div className='inline'>
              <label className="bill-6-label">ที่อยู่ : </label>
              <p>{ `${Bill?.job?.customer?.address}` }</p>
            </div>
          </div>
          <div className='line'>
            <div className='inline'>
              <label className="bill-6-label"></label>
              {
                Bill?.job?.customer?.type === 'นิติบุคคล' &&
                <p>เลขประจำตัวผู้เสียภาษี  { Bill?.job?.customer?.citizen_id }</p>
              }
            </div>
          </div>
          <div className='line'>
            <div className='inline'>
              <label className="bill-6-label">โทร</label>
              <p>{ displayPhone(Bill?.job?.customer?.phone) }</p>
            </div>
          </div>
        </div>

        <div className='table-bill-6'>
          <div className='table-head'>
            <div>ลำดับ</div>
            <div>รายการ</div>
            <div>จำนวน</div>
            <div>หน่วยละ</div>
            <div>จำนวนเงิน</div>
          </div>

          <div className='body-field'>
            {
              Bill?.job?.job_details.map((data, index) => {
                return(
                  <div className='table-body'>
                    <div>{index + 1}</div>
                    <div>{renderProduct(data)}</div>
                    <div>{data.quantity}</div>
                    <div>{data.price}</div>
                    <div>{data.grand_total}</div>
                  </div>
                )
              })
            }
          </div>

          <div className='price-field'>
            <div className='price-area'>
              <div></div>
              <div></div>
              <div>รวมเงิน : SUB TOTAL</div>
              <div>{ Bill?.job?.total }</div>
            </div>
            <div className='price-area'>
              <div></div>
              <div></div>
              <div>ภาษีมูลค่าเพิ่ม { `${Bill?.job?.vat ? `(${Bill?.job?.vat}%)` : '' }` } : VAT { `${Bill?.job?.vat ? `(${Bill?.job?.vat}%)` : '' }` }</div>
              <div>{ Bill?.job?.vat_calculate }</div>
            </div>
            <div className='price-area'>
              <div>ตัวอักษร</div>
              <div>{ Bill?.job && THBText(Bill?.job?.total_price) }</div>
              <div>ยอดสุทธิ : NET AMOUNT</div>
              <div>{ Bill?.job?.total_price }</div>
            </div>
          </div>
        </div>

        <div className='signature'>
          <div className='signature-field'>
            <div className='signature-area'>
              <div className='signature-name'></div>
              <div className='signature-date'>
                <p><span>/</span></p>
                <p><span>/</span></p>
              </div>
            </div>
            <div className='signature-position'>
              <h5>ผู้รับสินค้า</h5>
            </div>
          </div>

          <div className='signature-field'>
            <div className='signature-area'>
              <div className='signature-name'></div>
              <div className='signature-date'>
                <p><span>/</span></p>
                <p><span>/</span></p>
              </div>
            </div>
            <div className='signature-position'>
              <h5>ผู้ส่งสินค้า</h5>
            </div>
          </div>

          <div className='signature-field'>
            <div className='signature-area'>
              <div className='signature-name'></div>
              <div className='signature-date'>
                <p><span>/</span></p>
                <p><span>/</span></p>
              </div>
            </div>
            <div className='signature-position'>
              <h5>ผู้จัดการ</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }

}