import axios from 'axios';

export const Department = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/departments`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};