import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

// import MainDashboard from 'MiddleSystems/Dashboard/Components/MainDashboard';
import MainDashboard from 'MiddleSystems/Dashboard/Components/NewMainDashboard';
import BranchDashboard from 'MiddleSystems/Dashboard/Components/NewBranchDashboard';

export default function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { employee } = useSelector( (state) => state.user)

  return (
    <Container fluid className="bg-mrk">
        {
          employee?.branch_id === 1 ? <MainDashboard /> : <BranchDashboard />
        }
    </Container>
  );
}
