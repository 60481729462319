import axios, { post, put } from 'axios';

export const BankList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/banks`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindBank = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/banks/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateBank = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/banks`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateBank = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/banks/${id}`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyBank = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/banks/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};
