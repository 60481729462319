import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import Select from 'react-select';

import MyPagination from 'Components/MyPagination'
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import ReportImage from 'assets/icon/report-menu.png'
import Back from 'assets/icon/back.png';
// image
import CALENDAR from 'assets/icon/Calendar.png';

import { useDispatch, useSelector } from 'react-redux';
//API
import { loadAuth } from 'actions/auth';
import { LoadAllBranchSummaryReport } from 'actions/reports/index'

import { DatePicker } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { Branch } from 'actions/branch'


export default function Stock() {
  const history = useHistory();
  const dispatch = useDispatch();

  // const permission = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
  // const { log_permissions } = useSelector( (state) => state.user.permissions)
  // const report_permission = log_permissions.find(item => item.permission.name === 'รายงาน' && item.middle === permission.middle)

  const { RangePicker } = DatePicker;

  const [Search, setSearch] = useState({
    Date: [
      moment(),
      moment()
    ],
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
    Type: '',
    BranchId: null,

  })

  const [branches, setBranches] = useState([]);

  const getBranch = () => {
    dispatch(Branch()).then(response => {
      setBranches(response.data.data);
      // branches.push([{id: 0, name: 'ทั้งหมด'}])
    
    }).catch( e => {

    })
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['รายงานสรุปยอดรวมสาขา (ทุกสาขา)'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/report');
      }
    } catch (error) {
      history.push('/report');
      console.error(error);
    }
  };

  const [errors, setErrors] = useState([]);
  const ExportExcel = () => {
    const params = {
      start_date: moment(Search?.Date[0]).format('YYYY-MM-DD'),
      end_date: moment(Search?.Date[1]).format('YYYY-MM-DD'),
      page: 1,
      status: Search.Type,
      branch_id: JSON.parse(localStorage.getItem('permissions')).middle === false ? JSON.parse(localStorage.getItem('permissions')).id : Search.BranchId
    }

    dispatch(LoadAllBranchSummaryReport(params)).then(response => {
      if (response.data.data && !response.data.data.length) {
        setErrors(['ไม่พบการนัดหมายที่เลือก'])
        return false
      }
      let data = [];
      let export_name = [];
      response.data.data.map( (item, key) => {
        let set = {
          'ลำดับ': key + 1 ,
          'สาขา': `${item.jobr.branch.name}`,
          'วันที่ออก Job': `${moment(item.jobr.created_at).format('YYYY-MM-DD')}`,
          'เลขที่ Job': `${item.jobr.job_code}`,
          'สถานะการชำระเงิน': `${item.status_pay === 'paid' ? 'ชำระเงินแล้ว' : 'รอการชำระ'}`,
          'วันที่ชำระ': `${item.status_pay === 'paid' ? moment(item.updated_at).format('YYYY-MM-DD') : ''}`,
          'เลขที่ Bill': `${item.bill_code}`,
          'รหัสลูกค้า': `${item.jobr.customer.customer_code}`,
          'ชื่อลูกค้า': `${item.jobr.customer.full_name}`,
          'เลขทะเบียนรถ': `${item.jobr.car_owner.license_plate}`,
          'ข้อมูลรถ': `${item.jobr.car_owner.car_serie.car_model.car_brand.name + ' ' + item.jobr.car_owner.car_serie.car_model.model + ' ' + item.jobr.car_owner.car_serie.code + ' ' + item.jobr.car_owner.year}`,
          'รหัสสินค้า/บริการ': `${generateProCodeList(item)}`,
          'ชื่อสินค้า/บริการ': `${generateProNameList(item)}`,
          'จำนวน': `${generateProQtyList(item)}`,
          'ราคาสินค้า/บริการ': `${generateProPriceList(item)}`,
          'ยอดรวม (บาท)': `${numeral(item.jobr.total_price).format('0,0.00')}`,

        }

        export_name = `AllBranchSummaryReport - ${dayjs(item.created_at).format('DD-MM-YYYY')} `

        data.push(set);
      })
      const dataWS = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, export_name + '.xlsx')
    }).catch(e => {
      setErrors(['เกิดข้อผิดพลาด'])
    })
  }

  const [Loading,setLoading] = useState(false);

  const [AllBranchSummaryReport,setAllBranchSummaryReport] = useState([])

  const [Pages, setPage] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);


  const getAllBranchSummaryReport = async () => {
    try {
      const params = {
        start_date: moment(Search?.Date[0]).format('YYYY-MM-DD'),
        end_date: moment(Search?.Date[1]).format('YYYY-MM-DD'),
        limit: Search.PerPage,
        page: Search.CurrentPage,
        status: Search.Type,
        branch_id: JSON.parse(localStorage.getItem('permissions')).middle === false ? JSON.parse(localStorage.getItem('permissions')).id : Search.BranchId
      };
      const response = await dispatch(LoadAllBranchSummaryReport(params));
      if(Search.PerPage > 0){
        setAllBranchSummaryReport(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        setAllBranchSummaryReport(response.data.data);
        setSearch({ ...Search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect( () =>{
    // getAuth()
    getBranch();
    getAllBranchSummaryReport();
  },[Search.Date,Search.PerPage,Search.CurrentPage,Search.Type]);

	const displayPhone = (phone) => {
    if(phone && Array.isArray(phone)) return phone.join(', ')
    if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
  }

  const generateProCodeList = (item) => {
    let text = '';
    let l = item.jobr.job_details.length;
    item.jobr.job_details.map( (item, index) => {
      if(item.package !== null){
        text = text + item.package.code;
      }
      if(item.product !== null){
        text = text + item.product.code;
      }
      if(item.service !== null){
        text = text + 'SV' + item.service.code;
      }
      if(index + 1 < l){
        text = text + '/';
      }
    })
    return text;
  }
  const generateProNameList = (item) => {
    let text = '';
    let l = item.jobr.job_details.length;
    item.jobr.job_details.map( (item, index) => {
      if(item.package !== null){
        text = text + item.package.name;
      }
      if(item.product !== null){
        text = text + item.product.name;
      }
      if(item.service !== null){
        text = text + 'SV' + item.service.name;
      }
      if(index + 1 < l){
        text = text + '/';
      }
    })
    return text;
  }
  const generateProPriceList = (item) => {
    let text = '';
    let l = item.jobr.job_details.length;
    item.jobr.job_details.map( (item, index) => {
      text = text + numeral(item.total).format('0,0.00');
      // if(item.package !== null){
      //   text = text + item.package.name;
      // }
      // if(item.product !== null){
      //   text = text + item.product.name;
      // }
      // if(item.service !== null){
      //   text = text + 'SV' + item.service.name;
      // }
      if(index + 1 < l){
        text = text + '/';
      }
    })
    return text;
  }
  const generateProQtyList = (item) => {
    let text = '';
    let l = item.jobr.job_details.length;
    item.jobr.job_details.map( (item, index) => {
      text = text + item.quantity;
      // if(item.package !== null){
      //   text = text + item.package.name;
      // }
      // if(item.product !== null){
      //   text = text + item.product.name;
      // }
      // if(item.service !== null){
      //   text = text + 'SV' + item.service.name;
      // }
      if(index + 1 < l){
        text = text + '/';
      }
    })
    return text;
  }

  function close() {
    history.push(`/report`);
  }
  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Bill Report bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; รายงานสรุปยอดรวมสาขา (ทุกสาขา) 
                    </b>
                </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; รายงานสรุปยอดรวมสาขา (ทุกสาขา) 
                    </b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <RangePicker
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                        }}
                        value={Search.Date}
                        onChange={(date) => setSearch({...Search, Date: date})}
                        suffixIcon={<img src={CALENDAR} />}
                        separator="-"
                        className="calendar-antd-dashboard"
                        style={{
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                      />
                  </Col>
                  {
                    JSON.parse(localStorage.getItem('permissions')).middle === true ?
                    <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <Select
                        placeholder="สาขา"
                        className='w-100'
                        options={branches}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={branches.find((item) => {return item.id === Search.BranchId})}
                        onChange={(b) => setSearch({ ...Search, BranchId: b.id })}
                      />
                    </Col>
                    : ''
                  }
                  <Col
                    lg={3}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button type="button" className="button" onClick={() => getAllBranchSummaryReport()}>
                      ค้นหา
                    </Button>
                  </Col>
                  <Col
                    lg={3}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button type="button" className="button" onClick={() => ExportExcel()}>
                      Export
                    </Button>
                  </Col>
                  
                </Row>

                <Row className="g-0 justify-content-center mt-3 m-0">
                  <Col className="set-table p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>ลำดับที่</th>
                          <th>สาขา</th>
                          <th>วันที่ออก Job</th>
                          <th>เลขที่ Job</th>
                          <th>สถานะการชำระเงิน</th>
                          <th>วันที่ชำระ</th>
                          <th>เลขที่ Bill</th>
                          <th>รหัสลูกค้า</th>
                          <th>ชื่อลูกค้า</th>
                          {/* <th>เบอร์โทรศัพท์</th> */}
													<th>เลขทะเบียนรถ</th>
													<th>ข้อมูลรถ</th>
													<th>รหัสสินค้า/บริการ</th>
													<th>ชื่อสินค้า/บริการ</th>
													<th>จำนวน</th>
													<th>ราคาสินค้า/บริการ</th>
													<th>ยอดรวม (บาท)</th>
													{/* <th>ผู้บันทึก</th> */}
													{/* <th>หมายเหตุ</th> */}
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {
                          AllBranchSummaryReport?.map((item,index) => {
                            return(
                              <tr key={item.id}>
																<td>{ index + 1 }</td>
																<td>{ item.jobr.branch.name }</td>
                                <td>{ moment(item.jobr.created_at).format('YYYY-MM-DD') }</td>
																<td>{ item.jobr.job_code }</td>
																<td>{ item.status_pay === 'paid' ? 'ชำระเงินแล้ว' : 'รอการชำระ' }</td>
																<td>{ item.status_pay === 'paid' ? moment(item.updated_at).format('YYYY-MM-DD') : '' }</td>
																<td>{ item.bill_code }</td>
																<td>{ item.jobr.customer.customer_code }</td>
																<td>{ item.jobr.customer.full_name }</td>
																{/* <td>{ displayPhone(item.jobr.customer.phone) }</td> */}
																<td>{ item.jobr.car_owner.license_plate }</td>
																<td>{ item.jobr.car_owner.car_serie.car_model.car_brand.name + ' ' + item.jobr.car_owner.car_serie.car_model.model + ' ' + item.jobr.car_owner.car_serie.code + ' ' + item.jobr.car_owner.year }</td>
																<td>{ generateProCodeList(item) }</td>
																<td>{ generateProNameList(item) }</td>
																<td>{ generateProQtyList(item) }</td>
																<td>{ generateProPriceList(item) }</td>
																<td>{ numeral(item.jobr.total_price).format('0,0.00') }</td>
																{/* <td>{ item.jobr.create_by.employee ? item.jobr.create_by.employee.fullname : '-'  }</td> */}
																{/* <td>{ item.jobr.note !== null ? item.jobr.note : '-' }</td> */}
                              </tr> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="g-0 mt-5 ">
            <Col lg={4} className=" set-page">
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>แสดง</p>
                </Col>
                <Col lg={7}>
                  <Select
                    className="text-left select-style"
                    aria-label="Default select example"
                    options={Pages}
                    onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                    defaultValue={{ value: 10, label: '10' }}
                  />
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>รายการ</p>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex justify-content-end set-paginate">
              <div className="box-paginate">
                {
                  !Loading ? 
                    Search.Rows > 1 && Search.PerPage > 0 ?
                      <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                  : 
                    Search.Rows > 1 && Search.PerPage > 0 ?
                      <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                }
              </div>
            </Col>
          </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
