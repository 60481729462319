import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Footer from 'Components/Footer';

import { useHistory } from 'react-router-dom';
import TabIconStaff from '../Components/layout/TabIconStaff';
import Back from 'assets/icon/back.png';
import Service from 'assets/icon/management.png';
import Staff from 'assets/icon/staff.png';
import Key_staff from 'assets/icon/key-fix.png';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// img
import FindIcon from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import Add from 'assets/icon/add-staff.png';

// api
import { List } from 'actions/employee/index';
import { Province } from 'actions/data/index';
import { loadAuth } from 'actions/auth';

import MyPagination from 'Components/MyPagination'

var dayjs = require('dayjs')
var relativeTime = require('dayjs/plugin/relativeTime')
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: "ใน %s",
    past: "%s ที่แล้ว",
    s: 'ไม่กี่วินาที',
    m: "ไม่กี่่นาที",
    mm: "%d นาที",
    h: "ไม่กี่ชั่วโมง",
    hh: "%d ชั่วโมง",
    d: "1 วัน",
    dd: "%d วัน",
    M: "1 เดือน",
    MM: "%d เดือน",
    y: "1 ปี",
    yy: "%d ปี"
  }
})

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [Employees, setEmployees] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // pagination
  const [Search, setSearch] = useState({
    Keyword: '',
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const GetEmployee = async () => {
    setLoading(true)

    const params = {
      keyword: Search.Keyword,
      limit: Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1,
      status: 'employee',
    }

    dispatch(List(params)).then(response => {
      if(response.data.data && response.data.data.data){
        const employees = response.data.data.data
        setEmployees(employees);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setEmployees(response.data.data);
        setSearch({ ...Search, 
          Rows: 0,
          CurrentPage: 1 
        })
      }
      setLoading(false);
    }).catch(e => {

    })
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['พนักงาน'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' }
  ]);


  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...Search, 
      Keyword: '',
      PerPage: 10 
    })
    setClearSearch(true)
  }

  useEffect(() => {
    GetEmployee();
    getAuth();
  }, []);

  useEffect(() => {
    if (!ClearSearch) GetEmployee();
    setClearSearch(false)
  }, [Search.PerPage, Search.CurrentPage, ClearSearch]);

  function closePage() {
    let path = `/`;
    history.push(path);
  }

  const getCountDate = (date) => {
    const date1 = dayjs(date ? date : null);
    const date2 = dayjs();

    let hours = date2.diff(date1, 'hours');
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    return days + 1
  }
  return (
    <>
      
      <Container fluid className="package-container Service bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Staff} />
                  <b>ครอบครัว(MRKfam)</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>พนักงาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-start ">
                  <TabIconStaff />
                </Row>
                <Row className="g-0 justify-content-start">
                  {/* <MenuStaff /> */}
                  <Col lg={5} className="d-flex justify-content-center mt-2 mb-2">
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อพนักงาน"
                      value={Search.Keyword ? Search.Keyword : ''}
                      onChange={(e) => setSearch({...Search, Keyword : e.target.value})}
                      className="input"
                    ></Form.Control>
                  </Col>
                  {/* <Col xs={12} lg={3} className=" my-auto mt-2 mb-2 set-dropdown-division">
                    <Select
                      options={optionServiceType}
                      placeholder=""
                      isClearable={false}
                      isSearchable={false}
                      onChange={(data) => SetType(data)}
                      className="select-list"
                    />
                  </Col> */}
                  <Col xs={12} lg={2} className="d-flex justify-content-center mt-2 mb-2">
                    <Button className="button-menu" onClick={GetEmployee}>
                      <img src={FindIcon} /> &nbsp; ค้นหา
                    </Button>
                  </Col>
                  <Col xs={6} lg={2} className="d-flex justify-content-center mt-2 mb-2">
                    <Button className="button-menu" onClick={ResetSearch}>
                      <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>
                  <Col xs={6} className=" mt-2 mb-2 d-md-none">
                    <Link to="/staff/add-staff">
                      <Button className="button-menu">
                            <img src={Add} /> &nbsp; เพิ่มรายชื่อ
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center m-0">
                  <Col
                    className=" mt-3 p-0"
                    style={{ overflow: 'scroll', height: '500px' }}
                  >
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>ชื่อพนักงาน</th>
                          <th>สาขา</th>
                          <th>แผนก</th>
                          <th>ระยะเวลาทำงาน</th>
                          <th>สถานะ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        { 
                          Employees.map(employee => {
                            return (
                              <tr key={ employee.id }>
                                <td>{ `${employee.fullname}` }</td>
                                <td>{ `${employee.branch.name}` }</td>
                                <td>{ `${employee.department && employee.department.name}` }</td>
                                {/* <td>{ getCountDate(employee.start_date) }</td> */}
                                <td>{ dayjs(employee.start_date).fromNow(true) }</td>
                                <td>{ `${employee.end_date ? 'ลาออก': 'พนักงาน'}` }</td>
                                <td>
                                  <Link to={`staff/edit-staff/${employee.id}`}>
                                    <Button className="button-package">
                                      <img src={Key_staff} />
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="การแสดงผล"
                          options={Pages}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          defaultValue={{ value: 10, label: '10' }}
                          onChange={
                            (e) => {setSearch({...Search, PerPage: e.value });}
                          }
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {
                        !isLoading ? 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
