import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { Table} from 'reactstrap';
import Select, { components } from 'react-select';
import { DatePicker} from 'antd';

import MyPagination from 'Components/MyPagination'

import SearchIcon from 'assets/icon/search.png';
import Calendar from 'assets/icon/calendar-bg.png';
import Add from 'assets/icon/add_menu.png'

// API
import { DamageList, CreateDamage, DestroyDamage } from "actions/customer/damage";
import { JobList } from "actions/jobs";

import moment from 'moment';
var dayjs = require('dayjs')
const DateFormat = 'YYYY-MM-DD';

export default function DamageReport() {
  const dispatch = useDispatch()
  let { id } = useParams();

  
  const [display, setDisplay] = useState({
    value: null,
    duration: null
  });

  const [damages, setDamages] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [data, setData] = useState({
    employee_id: id,
    job_id: null,
    detail: null,
    value: null,
    hour: null,
    minute: null,
  });

  const [search, setSearch] = useState({
    employee_id: id,
    date: [],
    limit: 10,
    page: 1,
    rows: 0,
  });

  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    
    setValidated(true);

    dispatch(CreateDamage(data)).then(response => {
      setData({
        employee_id: id,
        job_id: null,
        detail: null,
        value: null,
        hour: null,
        minute: null
      })
      setValidated(false);
      getDamage()
    }).catch(e => {

    })
  }

  const getJob = () => {
    const params = {
      status: 'all'
    }
    dispatch(JobList(params)).then(response => {
      setJobs(response.data.data);
    }).catch(e => {

    })
  }
  
  const [isLoading, setLoading] = useState(true);

  const getDamage = () => {
    setLoading(true)

    dispatch(DamageList(search)).then(response => {
      if(response.data.data && response.data.data.data){
        setDamages(response.data.data.data);
        setSearch({ ...search, 
          rows: response.data.data.last_page
        })

        setDisplay({
          value: response.data.display.value,
          duration: response.data.display.duration,
        })
      }else{
        setDamages(response.data.data);
        setDisplay({
          value: response.display.value,
          duration: response.display.duration,
        })
      }
      setLoading(false)
    }).catch(e => {

    })
  }

  const deleteDamage = (damage) => {
    dispatch(DestroyDamage(damage.id)).then(response => {
      getDamage()
    }).catch(e => {

    })
  }

  useEffect(() => {
    getJob()
  }, []);

  useEffect(() => {
    getDamage();
  }, [search.page]);

  return (
    <>
      <Container fluid style={{ padding: 0 }} className="damage-report-b2b">
        <Row className="section1">
          <Col lg={6} className="justify-content-start order-2 order-lg-1">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="align-items-center">
                <Col xs={12} lg={6} className="justify-content-start mt-2 mb-2">
                  <Select
                    placeholder="เลขที่ Job"
                    options={jobs}
                    getOptionLabel={(option) => option.job_code}
                    getOptionValue={(option) => option.id}
                    value={data.job_id ? jobs.find((item) => {return item.id === data.job_id}) : null}
                    onChange={(j) => { setData({...data, job_id: j.id, date: dayjs(j.created_at).format("YYYY-MM-DD") }) }}
                    className={`select-list `}
                  />
                </Col>

                <Col lg={6} className=" justify-content-start mt-2 ">
                  <DatePicker
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    className={(!data.date && validated ? 'validate-fail' : '') }
                    value={data.date ? moment(data.date, DateFormat) : null}
                    onChange={(date) => setData({...data, date: dayjs(date).format("YYYY-MM-DD") })}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={12} className="d-flex justify-content-start">
                  <Form.Control
                    as="textarea"
                    placeholder="ความเสียหาย"
                    className="text-area"
                    rows={8}
                    required
                    value={data.detail ? data.detail : ''}
                    onChange={(e) => setData({...data, detail: e.target.value})}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={6} sm={6} md={6} lg={5} className='d-flex'>
                  <Form.Control
                    type="text"
                    name="damagereport"
                    placeholder="มูลค่าความเสียหาย"
                    className="input-damage"
                    required
                    value={data.value ? data.value : ''}
                    onChange={(e) => setData({...data, value: e.target.value})}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></Form.Control>
                  <p className='ml-2 m-0 d-flex align-items-center'>บาท</p>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="col-hour">
                  <Form.Control
                    type="text"
                    name="hour"
                    placeholder="ชั่วโมง"
                    className={`input-damage`}
                    required={!data.minute && validated}
                    value={data.hour ? data.hour : ''}
                    onChange={(e) => setData({...data, hour: e.target.value})}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></Form.Control>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} className="col-minute">
                  <Form.Control
                    type="text"
                    name="minute"
                    placeholder="นาที"
                    className={`input-damage`}
                    required={!data.hour && validated}
                    value={data.minute ? data.minute : ''}
                    onChange={(e) => setData({...data, minute: e.target.value})}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="d-flex justify-content-start set-button-position">
                  <Button type="submit" className="increase">
                    <span> <img src={Add} /> &nbsp; เพิ่ม </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={6} className="d-flex justify-content-end order-1 order-lg-2">
            <div className="view-damage-hour">
              <Row>
                <Col xs={6} lg={6} className="d-flex justify-content-center">
                  <Row>
                    <p className="title">มูลค่าความเสียหาย</p>
                    <p className="point">{ display.value }</p>
                    <p className="unit">บาท</p>
                  </Row>
                </Col>
                <Col xs={6} lg={6} className="d-flex justify-content-center">
                  <Row>
                    <p className="title">ระยะเวลา </p>
                    <p className="point">{ display.duration && display.duration.toFixed(2) && display.duration.toFixed(2).replaceAll('.', ':') }</p>
                    <p className="unit">ชั่วโมง</p>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Row className="align-items-center">
              <Col xs={6} lg={3} className="justify-content-start">
                <DatePicker.RangePicker
                  placeholder=""
                  suffixIcon={<img src={Calendar} />}
                  // value={search.date ? moment(search.date, DateFormat) : null}
                  onChange={(date) => setSearch({...search, date: [date[0] && dayjs(date[0]).format(DateFormat), date[1] && dayjs(date[1]).format(DateFormat)]})}
                />
              </Col>
              <Col xs={6} lg={3} className="justify-content-start ">
                <Button type="button" className="increase search-button m-0" onClick={() => getDamage()}>
                  <img src={SearchIcon} />
                  <span> ค้นหา </span>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Table responsive className="table-custom text-nowrap mt-4">
              <thead>
                <tr>
                  <th>วันที่</th>
                  <th>เลขที่ Job</th>
                  <th>รายละเอียด</th>
                  <th>มูลค่าความเสียหาย</th>
                  <th>ระยะเวลา(ชั่วโมง)</th>
                </tr>
              </thead>
              <tbody>
                {
                  damages.map( (damage, key) => {
                    return (
                      <tr key={key}>
                        <td>{ damage.date }</td>
                        <td>{ damage.job?.job_code }</td>
                        <td>{ damage.detail }</td>
                        <td>{ damage.value }</td>
                        <td>{ damage.duration.replaceAll('.', ':') }</td>
                        <td>
                          <span 
                            onClick={ () => deleteDamage(damage) }
                            style={{ cursor:'pointer'}}
                          >
                            คืนค่า
                          </span>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Col>
          <Col
            className="d-flex justify-content-center mt-4 mb-2"
            style={{ padding: 0 }}
          >
            <div className="box-paginate">
              {
                !isLoading && <MyPagination totPages={search.rows} currentPage={search.page} pageClicked={(e) => { setSearch({...search, page: e }) }}></MyPagination>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
