import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, 
  // Pagination, 
  Form } from 'react-bootstrap';
import Service from 'assets/icon/appwizard_list.png';
import Footer from 'Components/Footer';

import { useHistory, useParams, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
// import GroupButtonStock from 'MiddleSystems/Components/layout/GroupButtonStock';
import Back from 'assets/icon/back.png';
import Select from 'react-select';

import Save from 'assets/icon/new-save.png';

// import CanCel from 'assets/icon/new-cancel.png';

import Return from 'assets/icon/new-re-turn.png';

import { useDispatch } from 'react-redux';

//API
import { OrderFind
  // ,UpdateOrder 
} from 'actions/orders';
import { loadAuth } from 'actions/auth';
import dayjs from 'dayjs';

export default function RequestOrderById() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const [statepage, setStatepage] = useState('');
  const [request, setRequest] = useState([
    { value: 'pending', label: 'รออนุมัติ' },
    { value: 'approved', label: 'อนุมัติ' },
    { value: 'rejected', label: 'ยกเลิก' },
  ]);
  const [useStatus, setUseStatus] = useState([
    { value: 'order_stock', label: 'เข้าสต็อก' },
    { value: 'use_now', label: 'ใช้ทันที' },
  ]);

  
  const [order,setOrder] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  // const [Data,setData] = useState([]);

  async function getOrderFind(){
    setIsLoading(true)
    try {
      const order_id = id
      const res = await dispatch(OrderFind(order_id))
      setOrder(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setIsLoading(false)
    }

  }


  const getAuth = async () => {
    try {
      const params = {
        name: ['ลงชื่อ/รับเข้า(stock)'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function closePage() {
    let path = `/stock/request-order`;
    history.push(path);
  }

  useEffect( () => {
    getOrderFind()
    getAuth()
  },[]);
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content mb-5">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b> <Link to={`/stock`} className='mr-2'>Stock</Link> &gt; คำร้องสั่งสินค้า</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b> <Link to={`/stock`} className='mr-2'>Stock</Link> &gt; คำร้องสั่งสินค้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form className="Stock-Request-Order-Branch mt-2 mb-2">
                  <Row className="g-0 justify-content-center">
                    <Col lg={6} className="">
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>เลขที่ใบสั่งซื้อ</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                            {/* {
                              level.middle ? (
                                <p>{order.order_number}</p>
                              ): (
                                <p>{order.stock_code}</p>
                              )
                            } */}
                            {order.stock_code}
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>วันที่สั่ง</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                        <p>{dayjs(order.order_date).format('DD-MM-YYYY')}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                        <p>{order.employee && order.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>สาขา</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                        <p>{order.branche && order.branche.name}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>ผู้บันทึก</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                        <p>{order.user && order.user.employee && order.user.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center justify-content-end set-label"
                        >
                          <Form.Label>การใช้งาน</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          {/* <Select
                            className="text-left select-style select-list"
                            aria-label="Default select example"
                            placeholder=""
                            options={use}
                          /> */}
                          <Select
                            className="text-left select-style select-list"
                            aria-label="Default select example"
                            placeholder=""
                            options={useStatus}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            value={
                              order.type_use
                                ? useStatus.find((data) => {
                                    return data.value === order.type_use;
                                  })
                                : null
                            }
                            isDisabled
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="">
                      <Row className="justify-content-end">
                        <Col lg={6} className=""></Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center">
                    <Row
                      className=" mt-3"
                      style={{ overflow: 'scroll', height: 'auto', maxHeight: '200px' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th>รหัสสินค้า</th>
                            <th>ชื่อสินค้า</th>
                            <th>จำนวน</th>
                            <th>หน่วย</th>

                            {/* <th>จำนวนที่ส่งกลับ</th> */}
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                        {order.details?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td >{item.product.code}</td>
                                <td >{item.name}</td>
                                <td >{item.quantity}</td>
                                <td >{item.product && item.product.unit && item.product.unit.name_buy}</td>
                                {/* <td className="d-flex justify-content-center">
                                  <Form.Control className="input-count"></Form.Control>
                                </td> */}
                              </tr>
                            );
                          })}
                          {/* <tr>
                            <td></td>
                            <td>น้ำยาเคลือบรถ</td>
                            <td>1</td>
                            <td>ขวด</td>
                            <td className="d-flex justify-content-center">
                              <Form.Control className="input-count"></Form.Control>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            value={order.note}
                            as="textarea"
                            className="text-area"
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center set-row-botton-request">
                    <Col lg={6}>
                      <Row className="d-flex justify-content-start">
                        {
                          order.status !== 'done' ? (
                            <Row className="d-flex justify-content-start mb-3">
                              <Col xs={12} lg={4}>
                                <Button className="button">
                                  <img src={Save} /> &nbsp; บันทึก
                                </Button>
                              </Col>
                              {/* <Col xs={6} lg={4}>
                                <Button className="button">
                                  <img src={CanCel} /> &nbsp; ยกเลิก
                                </Button>
                              </Col> */}
                            </Row>
                          ) : null
                        }
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={0}
                          className="set-hidden-return d-block  d-lg-none"
                        >
                          <Button className="button" type='button' onClick={() => {closePage()}}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row className="d-flex justify-content-end">
                        <Col xs={6} lg={4} className="set-hidden-return">
                          <Button className="button" type='button' onClick={() => {closePage()}}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
