import Footer from 'Components/Footer';
import React, { useState, useEffect, useRef  } from 'react';

// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import * as htmlToImage from 'html-to-image';

import { Bill1 } from './Component/Bill1'
import { Bill2 } from './Component/Bill2'
import { Bill3 } from './Component/Bill3'
import { Bill4 } from './Component/Bill4'
import { Bill5 } from './Component/Bill5'
import { Bill6 } from './Component/Bill6'
import { Bill7 } from './Component/Bill7'
import { Bill8 } from './Component/Bill8'

import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js'
export default function Index() {

  const [selectedBill, setSelectedBill] = useState('bill-4');
  const [downloadType, setDownloadType] = useState('pdf');

  const DownloadImage = () => {
    htmlToImage.toPng(document.getElementById(selectedBill), { quality: 1 })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = `${selectedBill}.png`;
      link.href = dataUrl;
      link.click();
    });
  }

  const BillList = [
    {
      title: 'bill-1',
      type: 'pdf',
      orientation: 'l'
    },
    {
      title: 'bill-2',
      type: 'pdf',
      orientation: 'p'
    },
    {
      title: 'bill-3',
      type: 'image'
    },
    {
      title: 'bill-4',
      type: 'pdf',
      orientation: 'l'
    },
    {
      title: 'bill-5',
      type: 'pdf',
      orientation: 'p'
    },
    {
      title: 'bill-6',
      type: 'pdf',
      orientation: 'p'
    },
    {
      title: 'bill-7',
      type: 'pdf',
      orientation: 'l'
    },
    {
      title: 'bill-8',
      type: 'pdf',
      orientation: 'p'
    }
  ]

  const selector = (selected) => {
    let find = BillList.find(item => item.title === selected)
    setSelectedBill(selected)
    setDownloadType(find.type)
  }

  const componentRef = useRef();

  // const onBeforeGetContentResolve = useRef(null);

  const [loading, setLoading] = useState(false);
  // const [text, setText] = useState("old boring text");

  // const handleAfterPrint = React.useCallback(() => {
  //   console.log("`onAfterPrint` called");
  // }, []);

  // const handleBeforePrint = React.useCallback(() => {
  //   console.log("`onBeforePrint` called");
  // }, []);

  // const handleOnBeforeGetContent = React.useCallback(() => {
  //   console.log("`onBeforeGetContent` called");

  //   setLoading(true);

  //   setText("Loading new text...");

  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;

  //     setTimeout(() => {
  //       setLoading(false);
  //       setText("New, Updated Text!");
  //       resolve();
  //     }, 2000);
  //   });
  // }, [setLoading, setText]);

  useEffect(() => {
  //   if (
  //     text === "New, Updated Text!" &&
  //     typeof onBeforeGetContentResolve.current === "function"
  //   ) {
  //     onBeforeGetContentResolve.current();
  //   }
  // }, [onBeforeGetContentResolve.current, text]);

  // const reactToPrintContent = React.useCallback(() => {
  //   return componentRef.current;
  // }, [componentRef.current]);

  // const reactToPrintTrigger = React.useCallback(() => {
  //   return <button className='preview-btn-action'>
  //     <p>พิมพ์</p>
  //   </button>;
  }, []);

  const handlePDF = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
		print: async (printIframe) => {
			const document = printIframe.contentDocument;
			if (document) {
        const html = document.getElementsByClassName(selectedBill)[0];
        const option = BillList.find(item => item.title === selectedBill)
        console.log('option handlePDF', option, html)
        var opt = {
          filename:     `${selectedBill}.pdf`,
          image:        { type: 'png', quality: 1 },
          html2canvas:  { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: option.orientation }
        };
				await html2pdf().set(opt).from(html).save();
			}
		}
  });

  const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
		print: async (printIframe) => {
			const document = printIframe.contentDocument;
			if (document) {
        const html = document.getElementsByClassName(selectedBill)[0];
        const option = BillList.find(item => item.title === selectedBill)
        console.log('option handlePrint', option, html)
        var opt = {
          filename:     `${selectedBill}.pdf`,
          image:        { type: 'png', quality: 1 },
          html2canvas:  { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: option.orientation }
        };

        await html2pdf().set(opt).from(html).toPdf().get('pdf').then(function (pdfObj) 
        {
          pdfObj.autoPrint();
          window.open(pdfObj.output('bloburl'), '_blank');
        });
			}
		}
  });
  
  return (
    <>
    <div style={{ minHeight: '100vh', backgroundColor: 'lightgray'}}>
      <div style={{ overflow: 'hidden', backgroundColor: 'darkgray' }}>
        <div className="row m-0 gx-0 justify-content-center">
          <div className="col-12 mt-1 p-0">
            <div className='preview-group'>
              <button onClick={() => selector('bill-1')} className={`preview-btn  ${selectedBill === 'bill-1'  ? 'active' : ''}`} style={{ border: 'none', backgroundColor: 'green', color: 'white' }}>Bill-1</button>
              <button onClick={() => selector('bill-2')} className={`preview-btn  ${selectedBill === 'bill-2' ? 'active' : ''}`} style={{ border: 'none'}}>Bill-2</button>
              <button onClick={() => selector('bill-3')} className={`preview-btn  ${selectedBill === 'bill-3' ? 'active' : ''}`} style={{ border: 'none', backgroundColor: 'green', color: 'white' }}>Bill-3</button>
              <button onClick={() => selector('bill-4')} className={`preview-btn  ${selectedBill === 'bill-4' ? 'active' : ''}`} style={{ border: 'none' }}>Bill-4</button>
              <button onClick={() => selector('bill-5')} className={`preview-btn  ${selectedBill === 'bill-5' ? 'active' : ''}`} style={{ border: 'none' }}>Bill-5</button>
              <button onClick={() => selector('bill-6')} className={`preview-btn  ${selectedBill === 'bill-6' ? 'active' : ''}`} style={{ border: 'none' }}>Bill-6</button>
              <button onClick={() => selector('bill-7')} className={`preview-btn  ${selectedBill === 'bill-7' ? 'active' : ''}`} style={{ border: 'none', backgroundColor: 'green', color: 'white' }}>Bill-7</button>
              <button onClick={() => selector('bill-8')} className={`preview-btn  ${selectedBill === 'bill-8' ? 'active' : ''}`} style={{ border: 'none' }}>Bill-8</button>
            </div>
          </div>

          {
            downloadType === 'pdf' &&
            <div className="col-12 mt-2 p-0 d-flex justify-content-end">
              <div style={{ overflowX: 'hidden' }}>
                <button className='preview-btn-action' onClick={() => handlePDF()}><p>PDF</p></button>
                {/* <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="AwesomeFileName"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                /> */}
                <button className='preview-btn-action' onClick={() => handlePrint()}><p>พิมพ์</p></button>

                {loading && <p className="indicator">กำลังโหลดข้อมูล...</p>}
              </div>
            </div>
          }
          {
            downloadType === 'image' &&    
            <div className="col-12 mt-3 p-0 d-flex justify-content-end" style={{ overflowX: 'hidden' }}>
              <button className='preview-btn-action' onClick={() => DownloadImage()}><p>ดาวน์โหลด</p></button>
            </div>
          }
        </div>
      </div>
      
      <div style={{ backgroundColor: 'lightgray'}}>
        <div style={{ height: '100%'}} className='mt-2'>
          {
            selectedBill === 'bill-1' &&
            <div className='preview-l'>
              <Bill1 ref={componentRef} />
            </div>
          }
          {
            selectedBill === 'bill-2' &&
            <div className='preview-p'>
              <Bill2 ref={componentRef} Set={1}/>
            </div>
          }
          {
            selectedBill === 'bill-3' &&
            <div className='preview-2'>
              <Bill3 Bill={null} ref={componentRef} />
            </div>
          }
          {
            selectedBill === 'bill-4' &&
            <div className='preview-l'>
              <Bill4 ref={componentRef} />
            </div>
          }
          {
            selectedBill === 'bill-5' &&
            <div className='preview-p'>
              <Bill5 ref={componentRef} />
            </div>
          }
          {
            selectedBill === 'bill-6' &&
            <div className='preview-p'>
              <Bill6 ref={componentRef} />
            </div>
          }
          {
            selectedBill === 'bill-7' &&
            <div className='preview-l'>
              <Bill7 ref={componentRef} Type={['preview']} />
            </div>
          }
          {
            selectedBill === 'bill-8' &&
            <div className='preview-p'>
              <Bill8 ref={componentRef} />
            </div>
          }
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
