import React, { useState , useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { Container, Row, Col } from 'react-bootstrap';

import { FindEmployee} from 'actions/employee/index';

import VEIN from "assets/images/MRK-VEIN.png";
import MARGETING from "assets/images/digital-margeting.png";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [Employee, setEmployee] = useState(null);
  const [Dashboard, setDashboard] = useState(null);
  const GetEmployee = () => {
    const params = {
      isDashboard: true
    }
    dispatch(FindEmployee({id, params})).then( response => {
      setEmployee(response.data.data)
      setDashboard(response.data.dashboard)
    }).catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    GetEmployee();
  }, []);

  useEffect(() => {
    if (Employee){
      const interval = setInterval(() => {
        displayDate()
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [Employee]);

  
  const [FromNow, setFromNow] = useState(null);
  const displayDate = () => {
    const date = Employee?.start_date
    if(date){
      const now = dayjs()
      let asSecond = now.diff(dayjs(date), 'second')
      const SecondToYear = 31556926
      const SecondToDay = SecondToYear / 365
      const SecondToHour = SecondToDay / 24
      const SecondToMinute = SecondToHour / 60
      
      const years = Math.floor(asSecond / SecondToYear)
      asSecond = asSecond - (years * SecondToYear)
      const days = Math.floor(asSecond / SecondToDay)
      asSecond = asSecond - (days * SecondToDay)
      const hours = Math.floor(asSecond / SecondToHour)
      asSecond = asSecond - (hours * SecondToHour)
      const minutes = Math.floor(asSecond / SecondToMinute)
      asSecond = asSecond - (minutes * SecondToMinute)
      const secounds = Math.floor(asSecond)
      
      setFromNow(
        `
          ${years ? `${years} ปี` : ''}
          ${days ? `${days} วัน` : ''}
          ${hours ? `${hours} ชั่วโมง` : ''}
          ${minutes ? `${minutes} นาที` : ''}
          ${secounds ? `${secounds} วินาที` : '0 วินาที'}
        `
      )
    }
  }
  
  return (
    <Container fluid className="employee-dashboard">
      <Row>
        <Col lg={{ order: 1 , span: 7}} md={{ order: 2 , span: 12}}>
          <h1>{Employee?.employee_code}</h1>
          <h4>สุขภาพของ {Employee?.fullname} (เอเคเอ : {Employee?.aka})</h4>
          <h4>DEPARTMENT OF {Employee?.department?.name}</h4>
          <div className='d-flex mt-1'>
            <div className='group'>
              <div className='vision'>
                <label>VISION :</label>
                <p>{Employee?.motto}</p>
              </div>
              <div className='honor-prize'>
                <label>HONOR PRIZE :</label>
                <img src={VEIN} alt="HONOR PRIZE" />
              </div>
            </div>
            <div className='hyper-link mx-3'>
               {
                Employee?.personal_documents.length > 0 ? (
                  <>
                  {/* <img src={'data:image/png;base64,'+Employee?.personal_documents[0].image} alt="HyperLink ของ" /> */}
                  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      {
                        Employee?.personal_documents.map((item, index) => {
                          return (
                            <div class={index == 0 ? 'carousel-item active' : 'carousel-item'}  data-bs-interval="5000">
                              <img src={'data:image/png;base64,'+item.image} class="d-block w-100" alt="..." />
                            </div>
                          )
                        })
                      }
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                  </>
                ) : (
                  <img src="https://img.freepik.com/free-vector/hand-painted-watercolor-galaxy-background_52683-63441.jpg?w=2000" alt="HyperLink ของ" />
                )
              }
            </div>
          </div>
        </Col>

        <Col lg={{ order: 2, span: 5}} md={{ order: 1, span: 12}} className='d-flex'>
          <div className='planetary-ring'>
            <div className='second-planetary-ring'>
              <img src={Employee?.avatar} />
            </div>
          </div>
          <div className='employee-info'>
            <div className='branch-code'>
              {Employee?.branch?.branche_code}
            </div>
            <h3>หน่วยงาน : {Employee?.branch?.name}</h3>
            <h3>16 PERSONALITY : {Employee?.personality}</h3>
          </div>
        </Col>

        <Col xs={12} md={{ order: 3 }}>
          <h5>ร่วมภารกิจกับเรามาแล้ว</h5>
          <h2>MISSION LIFE TIME</h2>
          <div className='mission-life-time'>
            <h2>{ FromNow }</h2>
          </div>
        </Col>

        <Col xs={12} md={{ order: 4 }} className='mt-5'>
          <h2>{Employee?.employee_code}</h2>
          <h5>สุขภาพของ {Employee?.fullname} (เอเคเอ : {Employee?.aka})</h5>
          <h5>DEPARTMENT OF {Employee?.department?.name}</h5>
        </Col>

        <Col xs={12} md={{ order: 5 }}>
          <div className='border-data'>
            <h2>EXPERIENCE</h2>
            <Row>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>JOB EXPERIENCE</h5>
                  <h1>{Dashboard?.job_experience}</h1>
                  <h5>JOBS</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>JOB ASSIST</h5>
                  <h1>{Dashboard?.job_assist}</h1>
                  <h5>JOBS</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data-image'>
                  <div>
                    <img src={VEIN} />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data-image'>
                  <img src={MARGETING} />
                </div>
              </Col>
            </Row>
            <h2>VALUATION</h2>
            <Row className='gy-3'>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME JOB MAKE</h5>
                  <h1>{Dashboard?.income_job_make}</h1>
                  <h5>JOBS</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME ASSIST MAKE</h5>
                  <h1>{Dashboard?.income_assist_make}</h1>
                  <h5>JOBS</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME MAKE</h5>
                  <h1>{Dashboard?.income_make}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>ASSIST MAKE</h5>
                  <h1>{Dashboard?.assist_make}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME PER YEAR</h5>
                  <h1>{Dashboard?.income_per_year?.toFixed(2) || '-'}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME PER MONTH</h5>
                  <h1>{Dashboard?.income_per_month?.toFixed(2) || '-'}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME PER DAY</h5>
                  <h1>{Dashboard?.income_per_day?.toFixed(2)}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
              <Col xs={3}>
                <div className='group-data'>
                  <h5>INCOME PER HOUR</h5>
                  <h1>{Dashboard?.income_per_hour?.toFixed(2)}</h1>
                  <h5>THAI BAHT</h5>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
