import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import Select from 'react-select';

import MyPagination from 'Components/MyPagination'
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import ReportImage from 'assets/icon/report-menu.png'
import Back from 'assets/icon/back.png';
// image
import CALENDAR from 'assets/icon/Calendar.png';

import { useDispatch, useSelector } from 'react-redux';
//API
import { loadAuth } from 'actions/auth';
import { LoadProcessStatus } from 'actions/reports/index'

import { DatePicker } from 'antd';
import moment from 'moment';


export default function Stock() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { RangePicker } = DatePicker;

  // const getAuth = async () => {
  //   try {
  //     const response = await dispatch(loadAuth());
  //     if(response.data.data === 'Unauthorized'){
  //       alert(response.data.message)
  //       history.push('/report');
  //     }
  //   } catch (error) {
  //     history.push('/report');
  //     console.error(error);
  //   }
  // };

  const [errors, setErrors] = useState([]);

  const [Loading,setLoading] = useState(false);

  const [ReportStock,setReportStock] = useState([])


  const getReportProcessStatus = async () => {
    try {
      const response = await dispatch(LoadProcessStatus());
			console.log(response.data.data);
			setReportStock([]);
			setReportStock(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }


  useEffect( () =>{
    // getAuth()
		getReportProcessStatus();
		setInterval(() => {
			getReportProcessStatus();
			console.log('Interval triggered');
		}, 60000);
  },[]);

  function close() {
    history.push(`/report`);
  }
  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Bill Report bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; Process
                    </b>
                </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b>
                      <Link to={`/report`} className='mr-2'>รายงาน</Link>
                      &gt; Process
                    </b>
                </h5>
              </div>
              <div className="modal-body">

                <Row className="g-0 justify-content-center mt-3 m-0">
                  <Col className="set-table p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th><h4 className="mb-0">ทะเบียน</h4></th>
													<th><h4 className="mb-0">สีรถ</h4></th>
													<th><h4 className="mb-0">ยี่ห้อรถ/รุ่นรถ</h4></th>
                          <th><h4 className="mb-0">รายการ</h4></th>
                          <th><h4 className="mb-0">เวลารับรถ</h4></th>
                          <th><h4 className="mb-0">วันที่รับรถ</h4></th>
                          <th><h4 className="mb-0">หมายเหตุ</h4></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package">
                        {
                          ReportStock?.map((item,index) => {
                            return(
                              item.job.job_assgin !== null && item.job.job_assgin.pickup_date !== null ?
                                <tr key={item.id}>
                                <td className="text-left" style={{width: '170px',fontSize: '24px'}}>{index + 1}. {item.job.car_owner.license_plate}</td>
                                <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.color?.name}</td>
                                <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.car_serie?.car_model?.car_brand?.name + ' ' + item?.job?.car_owner?.car_serie?.car_model?.model + '-' + item?.job?.car_owner?.car_serie?.code}</td>
                                <td className="text-left">
                                    <h4 className="mb-0">
                                      {
                                        item.package_id !== null ?
                                          `${item.service_appointment && item.service_appointment !== null ? item.service_appointment.use_type : '()'} - ${item.package.name}`
                                        :
                                          item.service_id !== null ? item.service.name : '()'
                                      }
                                    </h4>
                                  </td>
                                {
                                  item.job.job_assgin !== null && item.job.job_assgin.pickup_date !== null ?
                                    dayjs().format('YYYY-MM-DD') === item.job.job_assgin.pickup_date ?
                                    <td style={item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? {backgroundColor: '#56BB37'} : {backgroundColor: '#BB271B'}}><h3 className="mb-0 text-light">{ item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? item?.job?.job_assgin?.pickup_hours + ':' + item?.job?.job_assgin?.pickup_minutes : 'รอรับ'}</h3></td>
                                    : <td></td>
                                  :
                                    item?.job?.status === 'pending' ?
                                      <td style={{backgroundColor: '#655967'}}><h3 className="mb-0 text-light">รอดำเนินการ</h3></td>
                                    :
                                      item?.job?.status === 'approved' ?
                                        <td style={{backgroundColor: '#3F3F4E'}}><h3 className="mb-0 text-light">กำลังดำเนินการ</h3></td>
                                      : 
                                        item?.job?.status === 'pending_approved' ?
                                          // <td style={{backgroundColor: '#41424C'}}><h3 className="mb-0 text-light">รออนุมัติ</h3></td>
                                          ''
                                        :
                                          item?.job?.status === 'done' ?
                                            // <td style={{backgroundColor: '#710193'}}><h3 className="mb-0 text-light">เสร็จสิ้น</h3></td>
                                            '' 
                                          :
                                            item?.job?.status === 'rejected' ?
                                              // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">ยกเลิก</h3></td>
                                              '' 
                                            : 
                                              ''
                                              // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">-</h3></td> 
                                } 
                                  <td className="text-left" style={{width: '270px', fontSize: '24px'}}>{dayjs().format('YYYY-MM-DD') === item.job.job_assgin.pickup_date ? '-' : dayjs(item.job.job_assgin.pickup_date).format('DD/MM/YYYY')}</td>
                                  <td className="text-left" style={{width: '270px', fontSize: '24px'}}>{item?.job?.note}</td>
                                </tr>
                              :
                                item?.job?.status === 'pending' ?
                                <tr key={item.id}>
                                <td className="text-left" style={{width: '170px',fontSize: '24px'}}>{index + 1}. {item.job.car_owner.license_plate}</td>
                                <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.color?.name}</td>
                                <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.car_serie?.car_model?.car_brand?.name + ' ' + item?.job?.car_owner?.car_serie?.car_model?.model + '-' + item?.job?.car_owner?.car_serie?.code}</td>
                                <td className="text-left">
                                    <h4 className="mb-0">
                                      {
                                        item.package_id !== null ?
                                          `${item.service_appointment && item.service_appointment !== null ? item.service_appointment.use_type : '()'} - ${item.package.name}`
                                        :
                                          item.service_id !== null ? item.service.name : '()'
                                      }
                                    </h4>
                                  </td>
                                {
                                  item.job.job_assgin !== null && item.job.job_assgin.pickup_hours !== null ?
                                    <td style={item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? {backgroundColor: '#56BB37'} : {backgroundColor: '#BB271B'}}><h3 className="mb-0 text-light">{ item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? item?.job?.job_assgin?.pickup_hours + ':' + item?.job?.job_assgin?.pickup_minutes : 'รอรับ'}</h3></td>
                                  :
                                    item?.job?.status === 'pending' ?
                                      <td style={{backgroundColor: '#655967'}}><h3 className="mb-0 text-light">รอดำเนินการ</h3></td>
                                    :
                                      item?.job?.status === 'approved' ?
                                        <td style={{backgroundColor: '#3F3F4E'}}><h3 className="mb-0 text-light">กำลังดำเนินการ</h3></td>
                                      : 
                                        item?.job?.status === 'pending_approved' ?
                                          // <td style={{backgroundColor: '#41424C'}}><h3 className="mb-0 text-light">รออนุมัติ</h3></td>
                                          ''
                                        :
                                          item?.job?.status === 'done' ?
                                            // <td style={{backgroundColor: '#710193'}}><h3 className="mb-0 text-light">เสร็จสิ้น</h3></td>
                                            '' 
                                          :
                                            item?.job?.status === 'rejected' ?
                                              // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">ยกเลิก</h3></td>
                                              '' 
                                            : 
                                              ''
                                              // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">-</h3></td> 
                                } 
                                  <td className="text-left" style={{width: '270px', fontSize: '24px'}}>{item?.job?.note}</td>
                                </tr>
                                :
                                  item?.job?.status === 'approved' ?
                                  <tr key={item.id}>
                                  <td className="text-left" style={{width: '170px',fontSize: '24px'}}>{index + 1}. {item.job.car_owner.license_plate}</td>
                                  <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.color?.name}</td>
                                  <td style={{fontSize: '24px'}}>{item?.job?.car_owner?.car_serie?.car_model?.car_brand?.name + ' ' + item?.job?.car_owner?.car_serie?.car_model?.model + '-' + item?.job?.car_owner?.car_serie?.code}</td>
                                  <td className="text-left">
                                    <h4 className="mb-0">
                                      {
                                        item.package_id !== null ?
                                          `${item.service_appointment && item.service_appointment !== null ? item.service_appointment.use_type : '()'} - ${item.package.name}`
                                        :
                                          item.service_id !== null ? item.service.name : '()'
                                      }
                                    </h4>
                                  </td>
                                  {
                                    item.job.job_assgin !== null && item.job.job_assgin.pickup_hours !== null ?
                                      <td style={item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? {backgroundColor: '#56BB37'} : {backgroundColor: '#BB271B'}}><h3 className="mb-0 text-light">{ item?.job?.job_assgin?.pickup_hours !== null && item?.job?.job_assgin?.pickup_minutes !== null ? item?.job?.job_assgin?.pickup_hours + ':' + item?.job?.job_assgin?.pickup_minutes : 'รอรับ'}</h3></td>
                                    :
                                      item?.job?.status === 'pending' ?
                                        <td style={{backgroundColor: '#655967'}}><h3 className="mb-0 text-light">รอดำเนินการ</h3></td>
                                      :
                                        item?.job?.status === 'approved' ?
                                          <td style={{backgroundColor: '#3F3F4E'}}><h3 className="mb-0 text-light">กำลังดำเนินการ</h3></td>
                                        : 
                                          item?.job?.status === 'pending_approved' ?
                                            // <td style={{backgroundColor: '#41424C'}}><h3 className="mb-0 text-light">รออนุมัติ</h3></td>
                                            ''
                                          :
                                            item?.job?.status === 'done' ?
                                              // <td style={{backgroundColor: '#710193'}}><h3 className="mb-0 text-light">เสร็จสิ้น</h3></td>
                                              '' 
                                            :
                                              item?.job?.status === 'rejected' ?
                                                // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">ยกเลิก</h3></td>
                                                '' 
                                              : 
                                                ''
                                                // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">-</h3></td> 
                                  } 
                                    <td className="text-left" style={{width: '270px', fontSize: '24px'}}>{item?.job?.note}</td>
                                  </tr>
                                  : 
                                    item?.job?.status === 'pending_approved' ?
                                      // <td style={{backgroundColor: '#41424C'}}><h3 className="mb-0 text-light">รออนุมัติ</h3></td>
                                      ''
                                    :
                                      item?.job?.status === 'done' ?
                                        // <td style={{backgroundColor: '#710193'}}><h3 className="mb-0 text-light">เสร็จสิ้น</h3></td>
                                        '' 
                                      :
                                        item?.job?.status === 'rejected' ?
                                          // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">ยกเลิก</h3></td>
                                          '' 
                                        : 
                                          ''
                                          // <td style={{backgroundColor: '#373737'}}><h3 className="mb-0 text-light">-</h3></td> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>

              
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
