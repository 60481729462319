import axios from 'axios';

export const LoadReportStock = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/stock`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadProcessStatus = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/dashboard/process-status`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadBranchSalesReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/branch-sales-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadAllBranchSalesReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/all-branch-sales-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadCustomerServiceList = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/customer-service-list`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadCarServiceReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/car-service-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadShowroomSalesReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/showroom-sales-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadProductCostReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/product-cost-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadIncomeReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/income-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadTechnicianQueueReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/technician-queue-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadAllBranchSummaryReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/all-branch-summary-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadAccruedBalanceReport = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/accrued-balance-report`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadSalesReportForPeak = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/report/sales-report-for-peak`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};