import axios, { post } from 'axios';

export const CarOwnerList = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/car-owners`,
      { params },
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const FindCarOwner = ({id}) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/car-owners/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CreateCarOwner = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/car-owners`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};
export const CheckCarOwner = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/check-car-owners`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateCarOwner = ({id, formData}) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/car-owners/${id}`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const DestroyCarOwner = (id) => async (dispatch) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/car-owners/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};
