import React, { Component, useState, useEffect } from 'react';
import { Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { Container, Row, Col, Form, Modal, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Search from '../assets/icon/search.png';
import Save from '../assets/icon/save.png';
import Return from '../assets/icon/getout.png';

import { Province, Amphure } from 'actions/data/index';
import { CreateCustomer } from 'actions/customer/index';
import { SegmentList } from 'actions/settings/segment';
import { Customer } from 'actions/customer/index';

export default function AddOrdinaryPart({ props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const DisplayNumber = async () => {
    if(GNumber.length === 0)GetNumber();
    setShow(true)
  }

  const close = () => {
    setShow(false);
  };

  const Pad = async (num = 1, size = 6) => {
    num = num.toString();
        num = "1" + num;
    while (num.length < size) num = "0" + num;
    return num
  }
  
  const [GNumber, setGNumber] = useState([]);

  const GetNumber = async () => {
    let numbers = []
    for (let index = 0; index < 1200000; index++) {
      numbers.push(await Pad(index+1, 6))
    }
    setGNumber(numbers)

    let limit = 9999999
    let count = 0
    const result =  numbers.filter(item => {
      if(count <= limit){
        count++
        return true
      }else{
        return false
      }
    });
    
    setSegmentNumber(result)
  }

  const [SegmentNumber, setSegmentNumber] = useState([]);
  const [SearchSegment, setSearchSegment] = useState({
    Keyword: ''
  });

  const NumberList = async (e, action) => {
    e.preventDefault();
    if(action === 'reset') setSearchSegment({Keyword: '' })
    
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    const keyword = action !== 'reset' ? SearchSegment.Keyword : ''
    let limit = 9999999
    let count = 0
    const result =  GNumber.filter(item => {
      if(item.includes(keyword.toString()) && count <= limit){
        count++
        return true
      }else{
        return false
      }
    });
    
    setSegmentNumber(result)
  }

  const Prefixes = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    },
    {
      value: 'ไม่ระบุ',
      label: 'ไม่ระบุ',
    },
  ];

  const Channels = [
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Offline', label: 'Offline' },
    { value: 'Connection', label: 'Connection' },
    { value: 'ไม่ระบุ', label: 'ไม่ระบุ' }
  ];

  const [validated, setValidated] = useState(false);
  const [Segments, setSegments] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Customers, setCustomers] = useState([]);

  const [Data, setData] = useState({
    SegmentId: null,
    SegmentNumber: null,
    Prefix: null,
    FirstName: null,
    LastName: null,
    NickName: null,
    AKA: null,
    CitizenId: null,
    PostCode: null,
    Phones: [],
    ProvinceId: null,
    AmphureId: null,
    PostCode: null,
    Channel: null,
    CustomerId: null,
    Note: null,
    SpecialNeed: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    console.log(form.checkValidity())
    setValidated(true);

    if (form.checkValidity()) {
      let formData = new FormData();
      formData.append('type', 'บุคคลธรรมดา')
      if(Data.SegmentId) formData.append('segment_id', Data.SegmentId)
      if(Data.SegmentNumber) formData.append('segment_number', Data.SegmentNumber)
      if(Data.Prefix) formData.append('prefix', Data.Prefix)
      if(Data.FirstName) formData.append('first_name', Data.FirstName)
      if(Data.LastName) formData.append('last_name', Data.LastName)
      if(Data.NickName) formData.append('nickname', Data.NickName)
      if(Data.CustomerId) formData.append('recommender', Data.CustomerId)
      if(Data.Phone) formData.append('recommender', Data.Phone)
      if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId)
      if(Data.PostCode) formData.append('postcode', Data.PostCode)
      if(Data.Phones) formData.append('phone', JSON.stringify(Data.Phones))
      if(Data.Channel) formData.append('channel', Data.Channel)
      if(Data.Note) formData.append('note', Data.Note)
      if(Data.SpecialNeed) formData.append('special_need', Data.SpecialNeed)
      if(Data.CitizenId) formData.append('citizen_id', Data.CitizenId)
      
      try {
        const response = await dispatch(CreateCustomer(formData));
       
        if(response){
          // history.push('/member');

          if(response.data.message == 'บันทึกสำเร็จ'){
            GetProvince();
            GetSegment();
            alert('บันทึกเสร็จสิ้น')
            setData({
              SegmentId: null,
              SegmentNumber: null,
              Prefix: null,
              FirstName: null,
              LastName: null,
              NickName: null,
              AKA: null,
              CitizenId: null,
              PostCode: null,
              Phones: [],
              ProvinceId: null,
              AmphureId: null,
              PostCode: null,
              Channel: null,
              CustomerId: null,
              Note: null,
              SpecialNeed: null,
            })
          }else{
            console.log(response)
            let text = '';
            Object.values(response.data.message).map((item, index) => {
              if(item.length > 0){
                item.map(i => {
                  text = text + i + '\n'
                })
              }else{
                text = text + item + '\n'
              }
            });
            alert(text);
          }

        }else{
          console.log('failed')
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
    }
  };

  useEffect(() => {
    // GetNumber();
    GetProvince();
    GetSegment();
    GetCustomer();
  }, []);

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetAmphure = async (data) => {
    setData({ ...Data, ProvinceId: data.id })
    const params = {
      ProvinceId: data.id
    }
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetSegment = async (data) => {
    try {
      const response = await dispatch(SegmentList());
      setSegments(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async () => {
    try {
      const response = await dispatch(Customer());
      setCustomers(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const SetSegmentNumber = (gnumber) => {
    setData({ ...Data, SegmentNumber: gnumber });
    setShow(false);
  }
  function closePage() {
    history.push(`/member`);
  }
  return (
    <>
      <Container fluid className="component-create-member">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="row-form-create-memeber ">
            <Col xs={12} lg={3} className=" ">
              <Row className="align-items-center">
                <Col xs={4} lg={5} className="text-left mt-3 mb-3 text-lg-right pr-lg-0">
                  <Form.Label>Segment</Form.Label>
                </Col>
                <Col className="mt-3 mb-3">
                  <Select
                    options={Segments}
                    placeholder="Segment"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={false}
                    required
                    value={Data.SegmentId ? Segments.find((data) => {return data.id === Data.SegmentId}) : null}
                    onChange={(data) => setData({ ...Data, SegmentId: data.id })}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className=" pass-segment">
              <Row className="align-items-center">
                <Col xs={4} xl={3} className="mt-3 mb-3 text-left">
                  <Button
                    type="button"
                    className="pick-segment-button"
                    onClick={DisplayNumber}
                  >
                    <p className='p-0 m-0'>เลือกรหัส</p>
                  </Button>
                </Col>
                <Col xs={12} sm={8} className="mt-3 mb-3 text-left p-0">
                  <p className="m-0 ml-4">Ex.  Segment: {Data.SegmentId && Segments.length > 0 ? Segments.find((data) => {return data.id === Data.SegmentId}).name : null} {Data.SegmentNumber}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={5} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>คำนำหน้าชื่อ</Form.Label>
                </Col>
                <Col className="">
                  <Select
                    options={Prefixes}
                    placeholder="คำนำหน้าชื่อ"
                    isClearable={false}
                    isSearchable={false}
                    value={Data.Prefix ? Prefixes.find((data) => {return data.value === Data.Prefix}) : null}
                    onChange={(data) => setData({ ...Data, Prefix : data.value })}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    placeholder="ชื่อ"
                    required
                    value={Data.FirstName}
                    onChange={(e) => setData({ ...Data, FirstName : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={4} xl={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} xl={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>นามสกุล</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    placeholder="นามสกุล"
                    required
                    value={Data.LastName}
                    onChange={(e) => setData({ ...Data, LastName : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            {/* <Col lg={4} xl={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} xl={3} className="text-left  text-lg-right pr-lg-0">
                  <Form.Label>ชื่อเล่น</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    placeholder="ชื่อเล่น"
                    value={Data.NickName}
                    onChange={(e) => setData({ ...Data, NickName : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={5} className="text-left  text-lg-right pr-lg-0">
                  <Form.Label>ชื่อเล่น</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    placeholder="ชื่อเล่น"
                    value={Data.NickName}
                    onChange={(e) => setData({ ...Data, NickName : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อAKA</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    required
                    placeholder="ชื่อAKA"
                    value={Data.AKA}
                    onChange={(e) => setData({ ...Data, AKA : e.target.value }) }
                    className="aka-form"
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>เบอร์โทรศัพท์</Form.Label>
                </Col>
                <Col className="">
                  <ReactTagInput
                    placeholder="เบอร์โทรศัพท์"
                    
                    editable={true}
                    removeOnBackspace={true}
                    maxTags={2}
                    tags={Data.Phones}
                    onChange={(newTags) => setData({ ...Data, Phones : newTags }) }
                    className="input-tage"
                    required
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={5} className="text-left pr-lg-0">
                  <Form.Label>เลขบัตรประชาชน/พาสปอร์ต</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="number"
                    placeholder="เลขบัตรประชาชน/พาสปอร์ต"
                    required
                    value={Data.CitizenId}
                    onChange={(e) => setData({ ...Data, CitizenId : e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={4}  className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>จังหวัด</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Select
                    options={Provinces}
                    placeholder="จังหวัด"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={true}
                    defaultValue={Data.ProvinceId}
                    onChange={(data) => { GetAmphure(data) }}
                    className="select-list w-100"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>อำเภอ/เขต</Form.Label>
                </Col>
                <Col>
                  <Select
                    options={Amphures}
                    placeholder="อำเภอ/เขต"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={true}
                    defaultValue={Data.AmphureId}
                    onChange={(data) => { setData({ ...Data, AmphureId: data.id }) }}
                    isDisabled={!Data.ProvinceId}
                    className="select-list w-100"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>รหัสไปรษณีย์</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="number"
                    placeholder="รหัสไปรษณีย์"
                    required
                    value={Data.PostCode}
                    onChange={(e) => setData({ ...Data, PostCode: e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          
          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>ช่องทางการติดต่อ</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                  <Select
                    placeholder="ช่องทางการติดต่อ"
                    options={Channels}
                    isClearable={false}
                    isSearchable={false}
                    value={Data.Channel ? Channels.find((data) => {return data.value === Data.Channel}) : null}
                    onChange={(data) => setData({ ...Data, Channel: data.value }) }
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left text-lg-right">
                  <Form.Label>ชื่อผู้แนะนำ</Form.Label>
                </Col>
                <Col className="pl-lg-0">
                <Form.Control
                    type="text"
                    placeholder="ชื่อผู้แนะนำ"
                    required
                    value={Data.CustomerId}
                    onChange={(data) => setData({ ...Data, CustomerId: data.id }) }
                  ></Form.Control>
                  
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={5} className="mt-3 mb-3">
              <Row>
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    as="textarea"
                    
                    placeholder="หมายเหตุ"
                    style={{ height: '121px' }}
                    value={Data.Note}
                    onChange={(e) => setData({ ...Data, Note: e.target.value }) }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="mt-3 mb-3">
              <Row>
                <Col lg={4} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ความต้องการพิเศษ</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    as="textarea"
                    placeholder="ความต้องการพิเศษ"
                    style={{ height: '121px' }}
                    value={Data.SpecialNeed}
                    onChange={(e) => setData({ ...Data, SpecialNeed: e.target.value }) }
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-between mt-5 mb-5">
            <Col xs={6} md={4} lg={3}>
              <Button type="submit" className="button">
                <img src={Save} /> &nbsp; บันทึก
              </Button>
            </Col>
            <Col xs={6} md={4} lg={3}>
              <Button type="button" className="button" onClick={closePage}>
                <img src={Return} /> &nbsp; ออก
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      
      <Modal
        className="modal-segment"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-segment">
          <p>
            <b>เลือกรหัส</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={NumberList}>
            <Row className="set-row-search-box">
              <Col lg={3} className="d-flex justify-content-center">
                <Form.Control
                  type="number"
                  placeholder="ค้นหารหัสว่าง3"
                  required
                  value={SearchSegment.Keyword ? SearchSegment.Keyword : ''}
                  onChange={(e) => setSearchSegment({Keyword: e.target.value})}
                  className="search-code"
                ></Form.Control>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='submit' className="button-modal-segment">
                  <img src={Search} /> &nbsp; ค้นหา
                </Button>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='button' className="button-modal-segment" onClick={(e) => NumberList(e, 'reset')}>คืนค่า</Button>
              </Col>
            </Row>
          </Form>
          <Row className="set-box-value">
            <Col className="colum-value">
            { 
              SegmentNumber?.map(gnumber => {
                return (
                  <Button type='button' className="put-value-segment" onClick={(e) => SetSegmentNumber(gnumber)} key={gnumber}>{gnumber}</Button>
                )
              })
            }
            </Col>
            ;
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
