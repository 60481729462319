import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Pagination, Form } from 'react-bootstrap';
import Service from 'assets/icon/management.png';
import Footer from '../../Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';

import Back from 'assets/icon/back.png';
import StockImage from 'assets/icon/appwizard_list.png'

import Select, { components } from 'react-select';

import Cancel from 'assets/icon/new-cancel.png';


import Search from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';

import PurchaseOrder from 'assets/stock/purchase-order-new.png';
import View from 'assets/icon/view-data.png';
import { LinkContainer } from 'react-router-bootstrap';

export default function ReceiptOrder() {
  const history = useHistory();

  const [statepage, setStatepage] = useState('');
  const [page, setPage] = useState([
    { value: 'ทั้งหมด', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);
  const handleSelectPage = (e) => setStatepage(e);
  function closePage() {
    let path = `/branch/stock`;
    history.push(path);
  }
  const [order, setOrder] = useState([
    {
      id_order: 'PO2021070001',
      id_receive: 'PU2021070005',
      date: '11/06/2564',
      menu_emaxple: 'ฟิล์มติดระจก(ดำ100%)',
      people: 'อวิกา ศรีโพธิ์ทอง',
      status: 'รอรับ',
    },
    {
      id_order: 'PO2021070001',
      id_receive: 'PU2021070005',
      date: '11/06/2564',
      menu_emaxple: 'ฟิล์มติดระจก(ดำ100%)',
      people: 'อวิกา ศรีโพธิ์ทอง',
      status: 'รออนุมัติ',
    },
    {
      id_order: 'PO2021070001',
      id_receive: 'PU2021070005',
      date: '11/06/2564',
      menu_emaxple: 'ฟิล์มติดระจก(ดำ100%)',
      people: 'อวิกา ศรีโพธิ์ทอง',
      status: 'ไม่อนุมัติ',
    },
    {
      id_order: 'PO2021070001',
      id_receive: 'PU2021070005',
      date: '11/06/2564',
      menu_emaxple: 'ฟิล์มติดระจก(ดำ100%)',
      people: 'อวิกา ศรีโพธิ์ทอง',
      status: 'สำเร็จ',
    },
    {
      id_order: 'PO2021070001',
      id_receive: 'PU2021070005',
      date: '11/06/2564',
      menu_emaxple: 'ฟิล์มติดระจก(ดำ100%)',
      people: 'อวิกา ศรีโพธิ์ทอง',
      status: 'ยกเลิก',
    },
  ]);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [button_Search, setButton_Search] = useState('');
  const handleSelectType = (e) => setType(e);
  const handleSelectCategory = (e) => setCategory(e);

  const [display, setDisplay] = useState([
    {
      value: 'ทั้งหมด',
      label: 'ทั้งหมด',
    },
    {
      value: 'รออนุมัติ',
      label: 'รออนุมัติ',
    },
    {
      value: 'อนุมัติ',
      label: 'อนุมัติ',
    },
    {
      value: 'ไม่อนุมัติ',
      label: 'ไม่อนุมัติ',
    },
    {
      value: 'รอรับ',
      label: 'รอรับ',
    },
    {
      value: 'สำเร็จ',
      label: 'สำเร็จ',
    },
    {
      value: 'ยกเลิก',
      label: 'ยกเลิก',
    },
  ]);
  const reset_value = () => {
    setSearch('');
  };
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รายการรับเข้า/สั่งซื้อ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รายการรับเข้า/สั่งซื้อ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยรหัสสินค้า,ประเภท,หมวดหมู่"
                      // defaultValue={statepage[0]}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="input"
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      className=""
                      aria-label="Default select example"
                      placeholder="ทั้งหมด"
                      options={display}
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Link to="/stock/receive/order-to-stock">
                      <Button className="button-menu">
                        <img src={PurchaseOrder} /> &nbsp; สั่งเข้าสต๊อก
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Button className="button-menu">
                      <img src={Search} /> &nbsp; ค้นหา
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={reset_value}>
                      <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>
                </Row>
                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0 pt-3">
                    <table className="table table-responsive table-wash-part table-stock">
                      <thead>
                        <tr className="t-header-package">
                          <th>เลขที่สั่งซื้อ</th>
                          <th>เลขที่การรับเข้า</th>
                          <th>วันที่</th>

                          <th>รายการตัวอย่าง</th>
                          <th>ผู้ทำรายการ</th>
                          <th>สถานะ</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="t-body-package">
                        {order.map((item, i) => {
                          return (
                            <LinkContainer
                              to={
                                item.status === 'รอรับ'
                                  ? '/branch/stock/receive/order-to-stock/:id'
                                  : '/branch/stock/receive/admit'
                              }
                            >
                              <tr key={item}>
                                <td>{item.id_order}</td>
                                <td>{item.id_receive}</td>
                                <td>{item.date}</td>
                                <td>{item.menu_emaxple}</td>
                                <td>{item.people}</td>
                                <td>{item.status}</td>
                                <td>
                                  {item.status === 'รอรับ' ? (
                                    <Button
                                      type="button"
                                      className="button-package"
                                    >
                                      <img src={View} />
                                    </Button>
                                  ) : item.status === 'รออนุมัติ' ? (
                                    <Button
                                      type="button"
                                      className="button-cancel"
                                    >
                                      <img src={Cancel} /> &nbsp; ยกเลิก
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            </LinkContainer>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder=""
                          options={page}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>

                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>

                        <Pagination.Ellipsis />
                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
