import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Pie from 'react-apexcharts';
import 'antd/dist/antd.css';
import ReactApexChart from 'react-apexcharts';
import { DatePicker, Space, Input, Select, AutoComplete, Cascader } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

import Down from 'assets/icon/down.png';
import { OverView as CustomerOverView } from 'actions/customer/index';

export default function OverView(props) {
  const dispatch = useDispatch();
  const { Customer } = props

  const [Search, setSearch] = useState({
    year: moment()
  })
  const [isLoadingPie, setLoadingPie] = useState(false)
  const [isLoadingBar, setLoadingBar] = useState(false)
  const [isLoadingBranch, setLoadingBranch] = useState(false)

  const [Data, setData] = useState(null)

  const [BarOptions, setBarOptions] = useState({
    colors: ['#422F8A', '#ABA2D2', '#5943BA', '#FF7A33'],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    labels: [],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 1, 4],
    },
    title: {
      text: `ยอดใช้บริการ ${Customer.organization ? Customer.organization : `คุณ ${Customer?.first_name} ${Customer?.last_name}`}`,
      align: 'left',
      offsetX: 110,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB',
        },
        labels: {
          style: {
            colors: '#008FFB',
          },
        },
        title: {
          text: '',
          style: {
            color: '#008FFB',
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: 'Income',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396',
        },
        labels: {
          style: {
            colors: '#00E396',
          },
        },
        title: {
          text: '',
          style: {
            color: '#00E396',
          },
        },
      },
      {
        seriesName: 'Revenue',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019',
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: '',
          style: {
            color: '#FEB019',
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });
  const [BarSeries, setBarSeries] = useState([]);

  const [PieOptions, setPieOptions] = useState({
    colors: ['#4E79A7', '#F28E2B', '#E15759'],
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: ['แพคเกจ', 'สินค้า', 'บริการ'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });
  const [PieSeries, setPieSeries] = useState([]);

  const [BranchOptions, setBranchOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: [],
  });
  const [BranchSeries, setBranchSeries] = useState([]);

  const GetOverView = (y = null) => {

    const year = y ? y : moment(Search?.year).format('YYYY')
    const params = {
      year,
      customer_id: Customer.id,
      type: 'B2C'
    }

    dispatch(CustomerOverView(params)).then( response => {
      setData(response.data.data)

      setLoadingBar(true)

      const THMonths = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];

      const range = getMonths(response.data.data.total_sales, year)

      setBarOptions({...BarOptions, labels: THMonths })
      setBarSeries([
        {
          name: 'แพคเกจ',
          type: 'column',
          data: range.dataPackage,
        },
        {
          name: 'สินค้า',
          type: 'column',
          data: range.dataProduct,
        },
        {
          name: 'บริการ',
          type: 'column',
          data: range.dataService,
        },
        {
          name: 'ยอดสะสม',
          type: 'line',
          data: range.total,
        },
      ])

      setTimeout(() => {
        setLoadingBar(false)
      }, 500);

      setLoadingPie(true)

      setPieSeries([
        response.data.data.package,
        response.data.data.product,
        response.data.data.service,
      ])
      setTimeout(() => {
        setLoadingPie(false)
      }, 500);


      setLoadingBranch(true)

      setBranchOptions({...BranchOptions , labels: Object.keys(response.data.data.branches).map((key) => response.data.data.branches[key].name ) })
      setBranchSeries([
        {
          name: 'จำนวนครั้ง',
          data: Object.keys(response.data.data.branches).map((key) => response.data.data.branches[key].job_count ),
        }
      ])

      setTimeout(() => {
        setLoadingBranch(false)
      }, 500);


    }).catch(e => {
      console.log(e);
    })
  }
  
  useEffect(() => {
    GetOverView()
  }, []);

  function getMonths(total_sales, year) {
    
    let dateArray = [];
    let dataPackage = [];
    let dataService = [];
    let dataProduct = [];
    let total = [];
    let currentYear = year;

    for (let index = 0; index < 12; index++) {
      const month = moment().month(index).year(currentYear).format('MM-YYYY')
      const temp = total_sales.filter(item => moment(item.created_at).format('MM-YYYY') === month)
      if (temp && temp.length > 0) {
        const sumPackage = temp.filter(item => item.package_id).map(item => item.grand_total).reduce((prev, next) => Number(prev) + Number(next), 0)
        const sumService = temp.filter(item => item.service_id).map(item => item.grand_total).reduce((prev, next) => Number(prev) + Number(next), 0)
        const sumProduct = temp.filter(item => item.product_id).map(item => item.grand_total).reduce((prev, next) => Number(prev) + Number(next), 0)
        if(sumPackage) dataPackage.push(sumPackage)
        if(sumService) dataService.push(sumService)
        if(sumProduct) dataProduct.push(sumProduct)
        total.push(sumPackage+sumService+sumProduct)
      }else{
        dataPackage.push(0)
        dataService.push(0)
        dataProduct.push(0)
        total.push(0)
      }
    }

    return { dateArray, dataPackage, dataService, dataProduct, total };
  }

  const ChangeYear = (year) => {
    setSearch({...Search, year: moment(year).format('YYYY')})
    GetOverView(moment(year).format('YYYY'))
  }

  return (
    <>
      <Container fluid className="view-all-b2b">
        <div className='set-dashboard'>
          <Row className="mr-4 ml-4 mt-3 mb-3 justify-content-between">
            <Col xs={6} lg={4} xl={3} className="set-col-b2b income mt-3 mb-3">
              <div className="set-div">
                <p style={{ fontSize: '26px' }}>
                  <b>{`${Customer.organization ? Customer.organization : `คุณ ${Customer?.first_name} ${Customer?.last_name}`} `}</b>
                </p>
                <p>
                  {
                    Customer?.recommender_data &&
                    <>
                      <b className='mr-2'>ถูกแนะนำโดย</b> 
                      {
                        `${Customer.recommender_data &&  Customer.recommender_data.organization ? Customer.recommender_data.organization : `${Customer.recommender_data.first_name} ${Customer.recommender_data.last_name}` }`
                      }
                    </>
                  }
                </p>
              </div>
            </Col>
            <Col xs={6} lg={4} xl={2} className="set-col-b2b income mt-3 mb-3">
              <div className="set-div">
                <p>อายุสมาชิก</p>
                <p>{ Customer.created_at && dayjs(Customer.created_at).fromNow(true) }</p>
              </div>
            </Col>
            <Col xs={6} lg={4} xl={2} className="set-col-b2b income mt-3 mb-3">
              <div className="set-div">
                <p>จำนวนรถ</p>
                <p>{ Customer?.cars_count } คัน</p>
              </div>
            </Col>
            <Col
              xs={6}
              lg={4}
              xl={2}
              className="set-col-b2b number-car mt-3 mb-3"
            >
              <div className="set-div">
                <p>แนะนำผู้ใช้</p>
                <p>{ Customer?.invitation_count } ราย</p>
              </div>
            </Col>
            <Col
              xs={6}
              lg={4}
              xl={2}
              className="set-col-b2b number-car mt-3 mb-3"
            >
              <div className="set-div">
                <p>มูลค่าใช้บริการ</p>
                <p>{ Number(Data?.total).toLocaleString('en-US')} บาท</p>
              </div>
            </Col>
            <Col
              xs={6}
              lg={4}
              xl={2}
              className="set-col-b2b number-car mt-3 mb-3"
            >
              <div className="set-div">
                <p>มูลค่าแนะนำ</p>
                <p>{ Data?.recommended_value} บาท</p>
              </div>
            </Col>
          </Row>
          <Row className="set-row-chart ">
            <div className="set-div-chart bar-chart">
              <Row>
                <Col sm={6} lg={6} className="d-flex justify-content-start">
                  <p className="sales-title">
                    ยอดใช้บริการ {`${Customer.organization ? Customer.organization : `คุณ ${Customer?.first_name} ${Customer?.last_name}`} `}
                  </p>
                </Col>
                <Col sm={6} lg={6}>
                  <Row>
                    <Col></Col>
                    <Col>
                      <DatePicker
                        picker="year"
                        suffixIcon={<img src={Down} />}
                        style={{
                          borderRadius: '20px',
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                        allowClear={false}
                        value={Search.year ? moment(Search.year) : null }
                        onChange={(year) => ChangeYear(year) }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <center>
                    {
                      !isLoadingBar && Data &&
                      <ReactApexChart
                        options={BarOptions}
                        series={BarSeries}
                        type="bar"
                        width="100%"
                      />
                    }
                  </center>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="">
            <Col>
              <Row className="set-row-chart">
                <div className="set-div-chart">
                  <Row>
                    <Col className="text-left">
                      <p className="sales-title">
                        เปรียบเทียบยอดขายแต่ละบริการ
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <Row className="justify-content-center">
                        <Col lg={10}>
                          <center>
                            {
                              !isLoadingPie && Data &&                           
                              <Pie
                                options={PieOptions}
                                series={PieSeries}
                                type="pie"
                                width="100%"
                              />
                            }

                          </center>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center">
                          <div className="sum-all text-left">
                            <p className="sum-sales">รวมยอดขาย {  Number(Data?.total).toLocaleString('en-US') } บาท</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
            <Col className="set-branch-member">
              <Row className="set-row-chart">
                <div className="set-div-chart">
                  <Row>
                    <Col className="text-left">
                      <p className="sales-title">สาขาที่ใช้บริการ</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <Row className="justify-content-center">
                        <Col lg={10}>
                          <center>
                            {
                              !isLoadingBranch && Data &&
                              <ReactApexChart
                                options={BranchOptions}
                                series={BranchSeries}
                                type="bar"
                                width="100%"
                              />
                            }
                          </center>
                        </Col>
                        <Col
                          xs={12}
                          className="d-flex align-items-center"
                        ></Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
