// import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import MrkImage from 'assets/icon/dashboard.png'
import MemberImage from 'assets/icon/member.png'
import UserImage from 'assets/icon/user.png'
import ServiceImage from 'assets/icon/management.png'
import NotificationImage from 'assets/icon/notification.png'
import B2BImage from 'assets/icon/address_book_home.png'
import StockImage from 'assets/icon/appwizard_list.png'
import PackageImage from 'assets/icon/package-manage.png'
import AppointmentImage from 'assets/icon/purchase.png'
import PropertyImage from 'assets/icon/asset.png'
import ProductImage from 'assets/icon/product-manage.png'
import 'assets/scss/style-component.scss';

import FilmImage from 'assets/icon/glass-staff.png'
import Branch from 'assets/icon/branch-manage.png';
import Setting from 'assets/icon/setting.png';
// import Family from 'assets/icon/dashboard.png';
import Text from 'text-setting';

export default function TabsIconMember() {
  return (
    <Row className="g-0 d-flex justify-content-start m-0">
      <Col className="d-flex tabs-icon mb-4">
        <Link className='link-button' to="/">
          <Button className='button-menu-page p-1'>
          <img src={MrkImage} width="100%"  alt="" title={Text.menuName.mrk} />
          </Button>
          </Link>
          <Link className='link-button' to="/member">
          <Button className='button-menu-page p-1'>
            <img src={MemberImage} width="100%"  alt="" title={Text.menuName.member} />
          </Button>
          </Link>
          <Link className='link-button' to="/user">
          <Button className='button-menu-page p-1'>
            <img src={UserImage} width="100%"  alt="" title={Text.menuName.user} />
          </Button>
          </Link>
          <Link className='link-button' to="/service">
          <Button className='button-menu-page p-1'>
            <img src={ServiceImage} width="100%"  alt="" title={Text.menuName.service} />
          </Button>
          </Link>
          <Link className='link-button' to="/notification">
          <Button className='button-menu-page p-1'>
            <img src={NotificationImage} width="100%"  alt="" title={Text.menuName.notification} />
          </Button>
          </Link>
          <Link className='link-button' to="/b2b">
          <Button className='button-menu-page p-1'>
            <img src={B2BImage} width="100%"  alt="" title={Text.menuName.b2b} />
          </Button>
          </Link>
          <Link className='link-button' to="/stock">
          <Button className='button-menu-page p-1'>
            <img src={StockImage} width="100%"  alt="" title={Text.menuName.stock} />
          </Button>
          </Link>
          <Link className='link-button' to="/package/บริการล้างและเคลือบแว๊กซ์">
          <Button className='button-menu-page p-1'>
            <img src={PackageImage} width="100%"  alt="" title={Text.menuName.package} />
          </Button>
          </Link>
          <Link className='link-button' to="/appointment">
          <Button className='button-menu-page p-1'>
            <img src={AppointmentImage} width="100%"  alt="" title={Text.menuName.appointment} />
          </Button>
          </Link>
          <Link className='link-button' to="/property">
          <Button className='button-menu-page p-1'>
            <img src={PropertyImage} width="100%"  alt="" title={Text.menuName.property} />
          </Button>
          </Link>
          <Link className='link-button' to="/product">
          <Button className='button-menu-page p-1'>
            <img src={ProductImage} width="100%"  alt="" title={Text.menuName.product} />
          </Button>
        </Link>
        <Link className='link-button' to="/technician-queue">
      <Button className='button-menu-page p-1'>
        <img src={FilmImage} width="100%"  alt="" title={Text.menuName.technicianQueue} />
      </Button>
      </Link>
      <Link className='link-button' to="/management">
      <Button className='button-menu-page p-1'>
        <img src={Branch} width="100%"  alt="" title={Text.menuName.management} />
      </Button>
      </Link>
      <Link className='link-button' to="/setting">
        <Button className='button-menu-page p-1'>
          <img src={Setting} width="100%"  alt="" title={Text.menuName.setting} />
        </Button>
      </Link>

        <Link to="/member/add">
          <Button className="increase">เพิ่มรายชื่อ</Button>
        </Link>
      </Col>
    </Row>
  );
}
