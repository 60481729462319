import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import WashPart from './WashPart';
import Lightfilter from './Lightfilter';
import CoatingService from './CoatingService';
import Protective from './Protective';
import ServiceFilter from './ServiceFilter';

//API
import { FindPackageType } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';

export default function SpecialPackage(props) {

  const dispatch = useDispatch()
  const history = useHistory();
  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

  const [Data,setData] =useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);

  const [serviceMore, setServiceMore] = useState(true);
  const [fastService, setFastService] = useState(props.data && props.data.service_now ? props.data.service_now : false);
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [washPart,setWashPart] = useState([]);
  const [lightfilter,setLightfilter] = useState([]);
  const [coatingService,setCoatingService] = useState([]);
  const [protective,setProtective] = useState([]);
  const [serviceFilter,setServiceFilter] = useState([]);
  const [PointCoatingPackage,setPointCoatingPackage] = useState([]);
  const [pointCoatings, setPointCoatings] = useState([]);

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function SetPointCoating(data) {
    console.log('data', data)
    setPointCoatings(data);
  }

  const handleFastService = () => {
    setFastService(!fastService);
  };

  const optionService = [
    { value: 'ติดตั้งใหม่', label: 'ติดตั้งใหม่' },
    {
      value: 'ลอกติดตั้ง',
      label: 'ลอกติดตั้ง',
    }
  ];

  const TypeWorkList= [{id: 'ติดตั้งใหม่', name: 'ติดตั้งใหม่' },{id: 'ลอกอันเก่าและติดใหม่', name: 'ลอกอันเก่าและติดใหม่'}];
  function SetService(data) {
    resetSelectSpecialPackage();
    setService({ value: data.id, label: data.name, data });
      data.special_packages?.map((item) => {
        switch (item.package.package_type.name) {
          case 'บริการล้างและเคลือบแว๊กซ์':
              setWashPart({name: item.package.package_type.name, data: item})
             
            break;
          case 'บริการฟิล์มกรองแสง':
            setLightfilter({name: item.package.package_type.name, data: item})
           
            break;
          case 'บริการเคลือบเหมา':
            setCoatingService({name: item.package.package_type.name, data: item})
           
            break;
          case 'บริการเคลือบเฉพาะจุด':
            setProtective({name: item.package.package_type.name, data: item})
           
            break;
          case 'บริการฟิล์มกันรอย':
            setServiceFilter({name: item.package.package_type.name, data: item})
            setPointCoatingPackage(item.package?.product_quantities ? item.package.product_quantities : []);
           
            break;
        
          default:
            break;
        }
      });
    
  }

  function resetSelectSpecialPackage(package_name){
    const reset = [{name: '', data: ''}];
      setWashPart(reset);
      setLightfilter(reset);
      setCoatingService(reset);
      setProtective(reset);
      setServiceFilter(reset);
      setPointCoatingPackage([]);
  }
  
  function UpdateService(data,item,type) {
    
    let insert = []
        switch (item.package.package_type.name) {
          case 'บริการล้างและเคลือบแว๊กซ์':
            insert = item
              setWashPart({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการฟิล์มกรองแสง':
            insert = item
            if(type === 'TypeWork'){
              insert.typework = data.name
            }
            if(type === 'FilmThickness'){
              insert.film_thickness = data.target.value
            }
            setLightfilter({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการเคลือบเหมา':
            insert = item
            setCoatingService({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการเคลือบเฉพาะจุด':
            insert = item
            setProtective({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการฟิล์มกันรอย':
            insert = item
            insert.typework = data.value
            setServiceFilter({name: item.package.package_type.name, data: insert})
            setPointCoatingPackage(item.package?.product_quantities ? item.package.product_quantities : []);
            break;
        
          default:
            break;
        }
  }


  const StorageModel = {
    key: 0,
    PackageId: '',
    PackageCode: '',
    PackageName: '',
    PackageTypeId: '',
    PackageTypeName: '',
    form: 'package',
    Status: 'new',
    Quantity: '',
    ServiceNow: false,
  }

  function InsertPackage(){
    if(service.data && service.data.id){
      if(service.data.category === "ธรรมดา"){
        //! Normal Package
        let insert = StorageModel
        insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
        insert.PackageId = service.data.id
        insert.PackageCode = service.data.code
        insert.PackageName = service.data.name
        insert.PackageTypeId = props.data.id 
        insert.PackageTypeName = props.data.name
        insert.ServiceNow = fastService
        const CarSize = CarOwner.car_serie.size
        const MiddleSize = service.data.middle_sizes
        const resultCarSize = MiddleSize.find((item) => {
          return item.size === CarSize
        })
        if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
          insert.Price = MiddleSize[0].price
        }else{
          insert.Price = resultCarSize.price ? resultCarSize.price : 0 
        }
        insert.FilmThickness = null
        insert.TypeWork = null
        insert.PackageType = service.data.category
        insert.TextDetails = null
        insert.PositionUse = null

        //! for Appointment
        insert.CleanService = null
        insert.Treatment = null // TreatmentTime * Quantity
        insert.TreatmentTime = service.data.treatment_time
        insert.TreatmentRanges = service.data.treatment_ranges
        insert.Setup = service.data.setup
        insert.Schedule = service.data.schedule
        insert.PackageTypeId = service.data.package_type_id
        
        setData({ ...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

        //!for remove select form select list
        // const result = Packages.filter((item)=> {
        //   return item.id !== service.data.id
        // });
        // setPackages(result);

        const package_list = Data.PackageStorageList
        package_list.push(insert)
        
        parseDataJob.PackageList = package_list;
        parseDataJob.PackageListAppointment = [];
        localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
      }else{
        //! Special Package

        let insert = StorageModel
        insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
        insert.PackageId = service.data.id
        insert.PackageCode = service.data.code
        insert.PackageName = service.data.name
        insert.PackageTypeId = props.data.id 
        insert.PackageTypeName = props.data.name
        insert.ServiceNow = fastService
        const CarSize = CarOwner.car_serie.size
        const MiddleSize = service.data.middle_sizes
        const resultCarSize = MiddleSize.find((item) => {
          return item.size === CarSize
        })
        if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
          insert.Price = MiddleSize[0].price
        }else{
          insert.Price = resultCarSize.price ? resultCarSize.price : 0 
        }
        insert.FilmThickness = null
        insert.PackageType = service.data.category


        const SpecialPackages = []
        const special_package_list = service.data.special_packages
        // console.log('special_package_list',special_package_list);
        special_package_list.map((item) => {
          item.FilmThickness = null
          //! for Appointment
          item.CleanService = null
          item.Treatment = null // TreatmentTime * Quantity
          item.TreatmentTime = item.package.treatment_time
          item.TreatmentRanges = item.package.treatment_ranges
          item.Setup = item.package.setup
          item.Schedule = item.package.schedule
          item.PackageTypeId = item.package.package_type_id
         

          SpecialPackages.push(item)
          });
          
          insert.SpecialPackages = SpecialPackages
          //! setData
          setData({ ...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

          //!set to Storage
          const package_list = Data.PackageStorageList
          package_list.push(insert)
          parseDataJob.PackageList = package_list;
          parseDataJob.PackageListAppointment = [];
          localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
      }

    }else{
      alert('เลือกรายการ')
    }
  }

  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'Quantity':
        setData(prevState => ({...Data,
          PackageStorageList: prevState.PackageStorageList.map(
            el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
          )}))
    
        item.Quantity = Number(data.target.value)
        break;
      case 'note':
        setData({...Data, note: data.target.value })
        Data.note = data.target.value
        break;
      case 'insite_note':
        setData({...Data, insite_note: data.target.value })
        Data.insite_note = data.target.value
        break;
        case 'things_in_car':
          setData({...Data, things_in_car: data })
          Data.things_in_car = data
          break;
        case 'defect_car':
          setData({...Data, defect_car: data })
          Data.defect_car = data
          break;
        case 'area':
          setData({...Data, area: data.target.value })
          Data.area = data.target.value
          break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = result;
    parseDataJob.note = Data.note
    parseDataJob.insite_note = Data.insite_note
    parseDataJob.area = Data.area
    parseDataJob.things_in_car = Data.things_in_car
    parseDataJob.defect_car = Data.defect_car
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.PackageId
    })
    Packages.push(result);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('item',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }


  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[])
  return (
    <>
      <Form>
        <Container fluid className="pl-3 pr-3">
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <Select
                    options={Packages}
                    placeholder="เลือกบริการ"
                    getOptionValue={(option)=> option.id}
                    getOptionLabel={(option) => option.name}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(data) => SetService(data)}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-end">
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={fastService}
                      className="checkbox-input mt-3"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 justify-content-start">

            <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-4 d-lg-none">
              {/* <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={fastService}
                className="checkbox-input"
              /> */}
            </Col>
          </Row>
          <Row className="mt-3 mb-3 g-0 justify-content-center">
            {
              washPart.name === 'บริการล้างและเคลือบแว๊กซ์' ? (
                <Row className="g-0 justify-content-start">
                  <Row>
                    <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            { washPart.data.package.name }
                          </Col>
                        </Row>
                      </Col>  
                  </Row>    
                </Row>
              ) : ''
            }
            {
                lightfilter.name === 'บริการฟิล์มกรองแสง' ? (
                  <Row className="g-0 justify-content-start">
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            { lightfilter.data.package.name }
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            <Select
                              options={TypeWorkList}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              placeholder="ประเภทงาน"
                              isClearable={false}
                              isSearchable={false}
                              // onChange={(data) => SetTypeWork(data)}
                              onChange={(data) => UpdateService(data,lightfilter.data,'TypeWork')}
                              className="select-list"
                            />
                          </Col>
                          <Col lg={5} className="mt-2 mb-2">
                            <Form.Control className="input-table2"
                              placeholder="ความหนาของฟิล์ม"
                              onChange={(data) => UpdateService(data,lightfilter.data,'FilmThickness')}
                              // value={filmThickness.value}
                              // onChange={(e) => SetFilmThickness(e)}
                              type='number'
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                ) : ''
            }
            {
                coatingService.name === 'บริการเคลือบเหมา' ? (
                  <Row className="g-0 justify-content-start">
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            { coatingService.data.package.name }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                ) : ''
            }
            {
                protective.name === 'บริการเคลือบเฉพาะจุด' ? (
                  <Row className="g-0 justify-content-start">
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            { protective.data.package.name }
                          </Col>
                          <Col lg={5} className="mt-2 mb-2">
                            <Select
                              options={PointCoatingPackage}
                              getOptionValue={(option)=> option.id}
                              getOptionLabel={(option) => option.size}
                              // value={Data.spray_coating}
                              defaultValue={pointCoatings}
                              isMulti
                              placeholder="เลือกจุดที่ต้องการเคลือบ"
                              isClearable={false}
                              isSearchable={false}
                              isDisabled={PointCoatingPackage.length < 1 ? true :false}
                              onChange={(data) => SetPointCoating(data)}
                              className="select-list"
                            />
                          </Col>
                        </Row>
                      </Col>
                      
                    </Row>
                  </Row>
                ) : ''
            }
            {
                serviceFilter.name === 'บริการฟิล์มกันรอย' ? (
                  <Row className="g-0 justify-content-start">
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            { serviceFilter.data.package.name }
                          </Col>
                          
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10}>
                            <Select
                              options={optionService}
                              placeholder="ประเภทงาน"
                              isClearable={false}
                              isSearchable={false}
                              onChange={(data) => UpdateService(data,serviceFilter.data,'TypeWork')}
                              className="select-list"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                ) : ''
            }
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col lg={3} xl={2} className="mt-3 mb-3">
                <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                    เพิ่ม
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td>{item.PackageName}
                          <p>
                            { getTextByPackage(item)}
                            { getDisplayTextPositionCoating(item) }
                            { getDisplayTextPositionUse(item)}
                            { item.TextDetails }
                          </p>
                          </td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            type="number"
                            required
                            disabled={item.Status === 'appointment'}
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type="" className="button-package"
                             disabled={item.Status === 'appointment'}
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="set-row-button-create justify-content-center">
            <Col sm={4} lg={2}>
              <Button type="button" className="button">
                สร้าง
              </Button>
            </Col>
          </Row> */}
        </Container>
      </Form>
    </>
  );
}
