import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  // Pagination,
  Form,
  Modal,
} from 'react-bootstrap';
import Service from 'assets/icon/appwizard_list.png';
import Footer from 'Components/Footer';

import { useHistory, Link, useParams } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
// import GroupButtonStock from 'MiddleSystems/Components/layout/GroupButtonStock';
import Back from 'assets/icon/back.png';
import Select from 'react-select';

import Save from 'assets/icon/new-save.png';

// import CanCel from 'assets/icon/new-cancel.png';

import Return from 'assets/icon/new-re-turn.png';

//API
import { OrderFind,UpdateOrder } from 'actions/orders';
import { ProductList } from 'actions/product'


import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

export default function RequestOrderStatus() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [FormEdit, setFormEdit] = useState([]);

  // const [statepage, setStatepage] = useState('');
 
  const [request, setRequest] = useState([
    { value: 'pending', label: 'รออนุมัติ' },
    { value: 'approved', label: 'อนุมัติ' },
    { value: 'delivery', label: 'จัดส่ง' },
    { value: 'rejected', label: 'ยกเลิก' },
  ]);
  const [status, setStatus] = useState([
    { value: 'approved', label: 'อนุมัติ' },
    { value: 'delivery', label: 'จัดส่ง' },
    { value: 'rejected', label: 'ยกเลิก' },
  ]);
  const [useStatus, setUseStatus] = useState([
    { value: 'order_stock', label: 'เข้าสต็อก' },
    { value: 'use_now', label: 'ใช้ทันที' },
  ]);

  const [validated, setValidated] = useState(false);

  const [order,setOrder] = useState([]);
  const [details, setDetails] =  useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [Data,setData] = useState([]);
  const [Disable,setDisable] = useState([]);
  const StorageModel = {
    key: 0,
    ProductId: null,
    ProductCode: null,
    ProductName: null,
    Quantity: null,
    UnitId: null, 
  }
  const [products, setProducts] = useState([]);
  
  
  async function getOrderFind(){
    setIsLoading(true)
    try {
      const order_id = id
      const res = await dispatch(OrderFind(order_id))
      const details = res.data.data.details
      setOrder(res.data.data);
      setDetails(details)
      let count = [];
      details?.map((item) => {
        if(item.product_id === null){
          count.push(item)
        }
      });
      setDisable(count.length > 0);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setIsLoading(false)
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);
    
    if (form.checkValidity()) {

      if(Disable === false ){
        const formData = {
          id: id,
          data: {
            status: Data.Status,
            note: Data.Note,
            form_reqeust_order:true, 
          }
        }
        try {
          const response = await dispatch(UpdateOrder(formData));
         
          if(response){
            history.push('/stock/request-order');
          }
        } catch (error) {
          console.error(error);
         
        } finally {
          console.log('finally')
        }
      }else{
        alert('กรุณาระบุรายการสินค้า');
      }

      
    }
  };

  const updateProduct = async (e)=> {
    setValidated(true);
      // console.log(details);
        const formData = {
          id: id,
          data: {
            status: 'pending',
            note: Data.Note,
            form_reqeust_order:true, 
            order_details: details,
            form: 'update_product'
          }
        }
        try {
          const response = await dispatch(UpdateOrder(formData));
         
          if(response){
            // history.push('/stock/request-order');
            getOrderFind()
            close()
          }
        } catch (error) {
          console.error(error);
         
        } finally {
          console.log('finally')
        }
      
  }

  const GetProducts = async () => {
    setIsLoading(true)
    try {
      const params = {
        keyword: '',
      }
      const response = await dispatch(ProductList(params));
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function setShowEdit(item){
    setFormEdit(item)
  }

  useEffect( () => {
    getOrderFind()
    GetProducts();
  },[]);

  function closePage() {
    let path = `/stock/request-order`;
    history.push(path);
  }
  function close() {
    setShow(false);
  }
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content mb-5">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; คำร้องสั่งสินค้า</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; คำร้องสั่งสินค้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form 
                  noValidate validated={validated} onSubmit={handleSubmit}
                  
                  className="Stock-Request-Order-Branch mt-2 mb-2"
                  style={{ height: '700px' }}
                >
                  <Row className="g-0 justify-content-center">
                    <Col lg={6} className="order-2 order-lg-1">
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>เลขที่ใบสั่งซื้อ</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.stock_code}</p>
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>วันที่สั่ง</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{dayjs(order.order_date).format('DD-MM-YYYY')}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.employee && order.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>สาขา</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.branche && order.branche.name}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex justify-content-end set-label"
                        >
                          <Form.Label>ผู้บันทึก</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.user && order.user.employee && order.user.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center justify-content-end set-label"
                        >
                          <Form.Label>การใช้งาน</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <Select
                            className="text-left select-style select-list"
                            aria-label="Default select example"
                            placeholder=""
                            options={useStatus}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            value={
                              order.type_use
                                ? useStatus.find((data) => {
                                    return data.value === order.type_use;
                                  })
                                : null
                            }
                            isDisabled={order.status === 'rejected'}
                            // isDisabled
                          />
                           {/* <p>{order.type_use}</p> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="order-1 order-lg-2 set-status-colum">
                      <Row className="justify-content-end set-row-status">
                        <Col xs={6} md={4} lg={6} xl={5} className="">
                          <Select
                            className="text-left select-style select-list"
                            aria-label="Default select example"
                            placeholder="รออนุมัติ"
                            options={order.status === 'approved' ? status : request}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            isDisabled={order.status === 'rejected'}
                            value={
                              order.status
                                ? request.find((data) => {
                                    return data.value === order.status;
                                  })
                                : null
                            }
                            onChange={(e) =>
                              {
                                setData({
                                  ...Data,
                                  Status: e.value,
                                });
                                setOrder({...order, status: e.value});
                              }
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center">
                    <Row
                      className=" mt-3"
                      style={{ overflow: 'scroll', height: 'auto', maxHeight: '200px' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th>รหัสสินค้า</th>
                            <th>ชื่อสินค้า</th>
                            <th>จำนวน</th>
                            <th>หน่วย</th>
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                        {details?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td >{item.product && item.product.code}</td>
                                <td >{item.name}</td>
                                <td >{item.quantity}</td>
                                <td >{item.product && item.product.unit && item.product.unit.name_buy}</td>
                                {
                                  item.type === 'special' && order.status === 'pending' ? (
                                    <td >
                                      <a type='button' className="link" onClick={() => {
                                        handleShow();setShowEdit(item);
                                      }}>
                                        เลือกสินค้า
                                      </a>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )
                                }
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            value={order.note}
                            onChange={(e) =>
                              {
                                setOrder({...order, note: e.target.value});
                                setData({
                                  ...Data,
                                  Note: e.target.value,
                                });
                              }
                            }
                            as="textarea"
                            className="text-area"
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="g-0 justify-content-center set-row-botton-request">
                    <Col lg={6}>
                      <Row className="d-flex justify-content-start">
                        <Col xs={12} lg={4} className="mt-2 mb-2">
                          <Button className="button" type='submit'>
                            <img src={Save} /> &nbsp; บันทึก
                          </Button>
                        </Col>
                        {/* <Col xs={6} lg={4} className="mt-2 mb-2">
                          <Button className="button">
                            <img src={CanCel} /> &nbsp; ยกเลิก
                          </Button>
                        </Col> */}
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={0}
                          className="set-return-hidden  mt-2 mb-2"
                        >
                          <Button className="button" onClick={closePage}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="d-none  d-lg-block">
                      <Row className="d-flex justify-content-end">
                        <Col xs={6} lg={4} className="set-hidden-return">
                          <Button className="button" onClick={closePage}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
      <Modal className="modal-style" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-style">
          <p>
            <b>เลือกสินค้า</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center mt-3">
            {/* <Form.Control placeholder="ค้นหารายการสินค้า"></Form.Control> */}
            <Col className="col-12 pt-3">
              <Select
                options={products}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                className="text-left select-style select-list"
                isClearable={false}
                isSearchable={true}
                placeholder="ค้นหาและเลือกสินค้า"
                onChange={
                  (e) => setDetails(details?.map(
                      el => el.id === FormEdit.id ? { ...el, product_id: e.id , 
                        product_code: e.code, name: e.name, unit_id : e.unit.id}: el
                    )
                  )
                }
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8} className="mt-2 mb-2">
              <Row className="justify-content-center">
                <Col xs={6} lg={6} className="mt-2 mb-4">
                  {/* <Link to="/stock/request-order"> */}
                    <Button className="button button-width" onClick={updateProduct}>
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  {/* </Link> */}
                </Col>
                <Col xs={6} lg={6} className="mt-2 mb-2">
                  <Button className="button " onClick={close}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
