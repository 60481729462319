import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Select, { components } from 'react-select';
import { Switch, DatePicker, Space } from 'antd';
import { Container, Row, Col, Form, Tab, Tabs, Button, Table, Alert, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

// Custom Component
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Footer from 'Components/Footer';

// Icon
import Back from 'assets/icon/back.png';
import Package_image from 'assets/icon/package.png';
import Save from 'assets/icon/new-save.png';
import Turnoff from 'assets/icon/getout.png';
import WriteEdit from 'assets/icon/key-fix.png';
import DeleteWash from 'assets/icon/delete.png';
import Calendar from 'assets/icon/Calendar.png';
import Delete from 'assets/icon/delete.png';
import Increase from 'assets/icon/add-staff.png';
import uploadImage from 'assets/icon/upload.png';
// CSS
import 'assets/scss/style.scss';

// API
import { ProductList } from 'actions/product';
import { PackageList, UpdatePackage, FindPackage, UpdateB2BPrice, DestroyPackage, DestroyPackageB2B, DeleteB2bProduct } from 'actions/package';
import { Customer } from 'actions/customer/index';
import { LoadServices } from 'actions/service'
import { PackageTypeList } from 'actions/package';
import { loadAuth } from 'actions/auth';

export default function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { type, name, id } = useParams();
  const LogoFileRef = useRef(null);
  const productRef = useRef(null)
  const productRefScroll = () => productRef.current.scrollIntoView()
  const SizeModel = [
    {
      size: 'S',
      price: null
    },
    {
      size: 'M',
      price: null
    },
    {
      size: 'L',
      price: null
    },
    {
      size: 'TRUCK CAB',
      price: null
    },
    {
      size: 'SUV',
      price: null
    },
    {
      size: 'TRUCK 4 DOOR',
      price: null
    },
    {
      size: 'XL',
      price: null
    },
    {
      size: 'VAN',
      price: null
    }
  ]

  const ProcessingTime = [
    {
      key: 0,
      size: 'S',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      size: 'M',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      size: 'L',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      size: 'SUV',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      size: 'XL',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 7,
      size: 'VAN',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const ProcessingTimeModel = [
    {
      key: 0,
      model: null,
      code: null,
      size: 'S',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      model: null,
      code: null,
      size: 'M',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      model: null,
      code: null,
      size: 'L',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      model: null,
      code: null,
      size: 'TRUCK CAB',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      model: null,
      code: null,
      size: 'SUV',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      model: null,
      code: null,
      size: 'TRUCK 4 DOOR',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      model: null,
      code: null,
      size: 'XL',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 7,
      model: null,
      code: null,
      size: 'VAN',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const PackageOnlyPart = [
    {
      key: 0,
      size: 'กระโปรงหน้า',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 1,
      size: 'กระโปรงหลัง',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 2,
      size: 'ประตูหน้า',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 3,
      size: 'ประตูหลัง',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 4,
      size: 'กันชน',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 5,
      size: 'หลังคา',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    },
    {
      key: 6,
      size: 'แก้ม',
      price: null,
      process_times: null,
      treatment_price: null,
      active: 1
    }
  ]

  const ProductSizesModel = [
    {
      key: 0,
      size: 'S',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 1,
      size: 'M',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 2,
      size: 'L',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 4,
      size: 'SUV',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 6,
      size: 'XL',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    },
    {
      key: 7,
      size: 'VAN',
      quantity: null,
      product_id: null,
      product: null,
      active: 1
    }
  ]

  const ProductPartsModel = [
    {
      key: 0,
      size: 'กระโปรงหน้า',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 1,
      size: 'กระโปรงหลัง',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 2,
      size: 'ประตูหน้า',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 3,
      size: 'ประตูหลัง',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 4,
      size: 'กันชน',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 5,
      size: 'หลังคา',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    },
    {
      key: 6,
      size: 'แก้ม',
      quantity: null,
      product_id: null,
      product: null,
      active: 1,
      price: null,
    }
  ]

  const B2BPrice = [
    {
      key: 0,
      size: 'S',
      model: null,
      code: null,
      price: null
    },
    {
      key: 1,
      size: 'M',
      model: null,
      code: null,
      price: null
    },
    {
      key: 2,
      size: 'L',
      model: null,
      code: null,
      price: null
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      model: null,
      code: null,
      price: null
    },
    {
      key: 4,
      size: 'SUV',
      model: null,
      code: null,
      price: null
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      model: null,
      code: null,
      price: null
    },
    {
      key: 6,
      size: 'XL',
      model: null,
      code: null,
      price: null
    },
    {
      key: 7,
      size: 'VAN',
      model: null,
      code: null,
      price: null
    }
  ]

  const B2BPricePartsModel = [
    {
      key: 0,
      size: 'กระโปรงหน้า',
      price: null
    },
    {
      key: 1,
      size: 'กระโปรงหลัง',
      price: null
    },
    {
      key: 2,
      size: 'ประตูหน้า',
      price: null
    },
    {
      key: 3,
      size: 'ประตูหลัง',
      price: null
    },
    {
      key: 4,
      size: 'กันชน',
      price: null
    },
    {
      key: 5,
      size: 'หลังคา',
      price: null
    },
    {
      key: 6,
      size: 'แก้ม',
      price: null
    }
  ]

  const PeriodType = [
    {
      label: 'เดือน',
      value: 'เดือน'
    }
  ]

  const [Data, setData] = useState({
    name: null,
    code: null,
    period: null,
    period_type: null,
    service_time: null,
    treatment_time: null,
    warranty_period: null,
    schedule: null,
    setup: null,
    sizes: SizeModel,
    times: [],
    b2b_prices: [],
    products: [],
    product_times: [],
    active: false,
    range1: null,
    range2: null,
    renew_price: null,
    // for display
    b2b_price_list: [],
    product_list: [],
    model_list: [],
  });

  const [B2BData, setB2BData] = useState({
    customer_id: null,
    claim_price: null,
    b2b_prices: [],
    b2b_price_list: [],
    active_b2b: false,
    b2b_showroom: []
  });

  const [validated, setValidated] = useState(false);
  const [validatedB2B, setValidatedB2B] = useState(false);
  const [ValidatedProduct, setValidatedProduct] = useState(false);

  const [UnitSales, setUnitSales] = useState([])
  const [ProductId, setProductId] = useState(null)
  const [ProductModel, setProductModel] = useState(null)
  const [DataProductCode, setDataProductCode] = useState([])
  const [ProductCode, setProductCode] = useState(null)
  const [UnitSaleId, setUnitSaleId] = useState(null)
  const [Model, setModel] = useState(null)
  const [Code, setCode] = useState(null)
  const [Errors, setErrors] = useState([])
  const [DisableProduct,setDisableProduct] = useState(false);
 
  let modelChoice = []
  let ProductSizeChoice = []
  switch (name) {
    case 'บริการฟิล์มกรองแสง':
      modelChoice = ProcessingTimeModel
      ProductSizeChoice = ProductSizesModel
      break;
    case 'บริการเคลือบเฉพาะจุด':
      modelChoice = PackageOnlyPart
      ProductSizeChoice = ProductPartsModel
      break;
  
    default:
      modelChoice = ProcessingTime
      ProductSizeChoice = ProductSizesModel
      break;
  }

  const [ProductSizes, setProductSizes] = useState(ProductSizeChoice);
  const [ProductTimes, setProductTimes] = useState(modelChoice);

  const [B2BPrices, setB2BPrices] = useState(B2BPrice);
  const [B2BPricePartsModels, setB2BPricePartsModels] = useState(B2BPricePartsModel);

  const [ProductPartsModels, setProductPartsModels] = useState(ProductPartsModel);

  let usingType = [
    { value: 'ครั้งแรก',
      label: 'ครั้งแรก',
      used:[
        'บริการล้างและเคลือบแว๊กซ์',
        'บริการฟิล์มกรองแสง',
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด',
        'บริการฟิล์มกันรอย'
      ] 
    },
    { value: 'ตามนัด',
      label: 'ตามนัด',
      used:[
        'บริการล้างและเคลือบแว๊กซ์'
      ]
    },
    { 
      value: 'เช็คการเซ็ตตัว',
      label: 'เช็คการเซ็ตตัว',
      used:[
        'บริการฟิล์มกรองแสง',
        'บริการฟิล์มกันรอย'
      ]
    },
    { 
      value: 'พ่นเคลือบ',
      label: 'พ่นเคลือบ',
      used:[
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด'
      ] 
    },
    { value: 'เก็บงาน',
      label: 'เก็บงาน',
      used:[
        'บริการฟิล์มกันรอย'
      ] 
    },
    { value: 'ทรีตเมนต์',
      label: 'ทรีตเมนต์',
      used:[
        'บริการฟิล์มกันรอย'
      ] 
    },
    { 
      value: 'ล้างรถ',
      label: 'ล้างรถ',
      used:[
        'บริการล้างและเคลือบแว๊กซ์',
        'บริการฟิล์มกรองแสง',
        'บริการเคลือบเหมา',
        'บริการเคลือบเฉพาะจุด',
        'บริการฟิล์มกันรอย'
      ] 
    },
    { value: 'เคลือบ',
      label: 'เคลือบ',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ล้าง',
      label: 'ล้าง',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ขัดสี',
      label: 'ขัดสี',
      used:[
        'Box Set'
      ] 
    },
    { value: 'ซักเบาะ',
      label: 'ซักเบาะ',
      used:[
        'Interior'
      ] 
    },
    { value: 'ซักแผงประตู',
      label: 'ซักแผงประตู',
      used:[
        'Interior'
      ] 
    },
    { value: 'ซักสายเข็มขัด',
      label: 'ซักสายเข็มขัด',
      used:[
        'Interior'
      ] 
    },
    { value: 'เคลือบภายในรถ',
      label: 'เคลือบภายในรถ',
      used:[
        'Interior'
      ] 
    },
    { value: 'ล้างรถ',
      label: 'ล้างรถ',
      used:[
        'Interior'
      ] 
    },
    { value: 'อบโอโซน',
      label: 'อบโอโซน',
      used:[
        'Interior'
      ] 
    },
  ]

  const [useType, setUseType] = useState(usingType.filter(item => item.used.includes(name)));
  const [usedType, setUsedType] = useState([])

  const InsertProductSize = async (e) => {
    let valid = false

    if (!ProductId) valid = true
    if (!UnitSaleId) valid = true

    if(valid) productRefScroll()
    setValidatedProduct(valid);
    
    if (!valid) {
      let obj = {}
      const data = []
      ProductSizes.forEach((product, key) => {
        obj.usedType = usedType
        obj[product.size] = Number(product.quantity) || null
        obj.product_id = ProductId
        obj.unit_sale_id = UnitSaleId
        product.usedType = usedType
        product.product_id = ProductId
        product.unit_sale_id = UnitSaleId
        obj.key = Data.product_list.length
        data.push(product)
      });

      obj.product = Products.find((item) => item.id === ProductId)
      obj.product_id = ProductId

      // delete all old data by product_id
      let filter = Data.products.filter((item) => item.product_id !== obj.product_id);
      let result = filter.concat(data)

      setData({...Data, product_list: [...Data.product_list, obj ], products: result})
      
      setProductSizes(ProductSizeChoice)
      setProducts([...Products.filter(item => item.id !== obj.product_id)])
      setUsedType([])
      setValidatedProduct(false)
      setProductId(null)
      setUnitSaleId(null)
    }
  }

  const InsertProductTime = async (e) => {
    let valid = false
    setValidatedProduct(valid);
    
    if (!valid) {

      let array = Data.model_list
      let data = []

      ProductTimes.forEach((product, key) => {
        data.push({
          model: Model,
          code: Code,
          [product.size]: Number(product.price) || null
        })
        product.model = Model
        product.code = Code
      });

      const checkExist = array.find(item => item.model === Model)

      if (!checkExist) {
        array.push(
          {
            model: Model,
            data: [data]
          }
        )

        setData({...Data, model_list: array, product_times: [...Data.product_times, ...ProductTimes]})
        setProductTimes(modelChoice)
        setValidatedProduct(false);
      }else{
        // exist
        let result = true
        checkExist.data.forEach(item => {
          const value = item.find(item => item.code === Code)
          if(value) result = false
        });

        if (result) {
          const index = array.findIndex(item => item.model === Model)
          array[index].data = [
            ...array[index].data,
            data
          ]

          setData({...Data, model_list: array, product_times: [...Data.product_times, ...ProductTimes]})
          setProductTimes(modelChoice)
          setValidatedProduct(false);
        }
      }
    }
  }

  const [ProductTimeEditMode, setProductTimeEditMode] = useState(false);
  const [ValidatedProductTime, setValidatedProductTime] = useState(false);
  
  const UpdateProductTimeState = () => {

    let valid = false
    // validate
    // ProductTimes.forEach(time => {
    //   if (!time.price) {
    //     valid = true
    //   }
    // });

    setValidatedProductTime(valid);
    
    if (!valid) {
      let obj = {}
      let edited = []

      ProductTimes.forEach((time, key) => {
        time[time.size] = time.price
        if(!time.model) time.model = Model
        if(!time.code) time.code = Code
        edited.push(time)
      });

      obj.model = Model
      obj.code = Code
      
      let filter = Data.product_times.filter((item) => item.model !== obj.model || item.code !== obj.code);
      let result = filter.concat(edited)

      let model_list = Data.model_list
      let list = model_list.find(item => item.model === obj.model)
      
      if (list) {
        list?.data.forEach((item, key) => {
          let index = item.findIndex(x => { return x.code === obj.code })
          if (index !== -1) {
            list.data[key] = edited
          }
        });
      }else{
        list = model_list.find(item => !item.model)
        list.model = Model
        list?.data.forEach((item, key) => {
          let index = item.findIndex(x => { return !x.code })
          if (index !== -1) {
            list.data[key] = edited
          }
        });
      }
      console.log('result',result);
      setData({
        ...Data,
        model_list: model_list,
        product_times: result
      }) 

      setProductTimes(modelChoice)
      setValidatedProductTime(false);
      setModel(null);
      setCode(null);
      setProductTimeEditMode(false)
    }
  }

  const EditProductTime = (product) => {
    const data = []

    let model = null
    let code = null
    ProductTimes.forEach((item, key) => {
      let value = product.find(i => i.size === item.size)
      let time = {}
      time.key = key
      
      if (value) {
        if(value.id) time.id = value.id
        if(value.package_id) time.package_id = value.package_id
        model = value.model
        code = value.code
        time.model = value.model
        time.code = value.code
        time.size = value.size
        time.price = value.price
        time.process_times = value.process_times
        time.treatment_price = value.treatment_price
        data.push(time)
      }else{
        modelChoice.forEach(element => {
          const exists = data.find(x => x.size === element.size)
          if (!exists) {
            data.push(element)
          }
        });
      }
      
    });

    setModel(model)
    setCode(code)

    setProductTimes(data)
    setProductTimeEditMode(true)
  }

  const B2bEditProductTime = (product) => {
    const b2b_price_data = []

    let model = null
    let code = null
    let customer_id = null
    ProductTimes.forEach((item, key) => {
      let value = product.find(i => i.size === item.size)
      let time = {}
      time.key = key
      
      if (value) {
        if(value.id) time.id = value.id
        if(value.package_id) time.package_id = value.package_id
        model = value.model
        code = value.code
        customer_id = value.customer_id
        time.model = value.model
        time.code = value.code
        time.size = value.size
        time.price = value.price
        time.process_times = value.process_times
        time.treatment_price = value.treatment_price
        time.customer_id = value.customer_id
        b2b_price_data.push(time)
      }else{
        modelChoice.forEach(element => {
          const exists = b2b_price_data.find(x => x.size === element.size)
          if (!exists) {
            b2b_price_data.push(element)
          }
        });
      }
      
    });

    setProductModel(model)
    setDataProductCode([{ label: code, value: code}])
    setProductCode(code)

    
    setB2BPrices(b2b_price_data)
    setB2BData({...B2BData, customer_id: customer_id});
    setB2BPriceEditMode(true);
  }

  const B2bInsertProductTime = async (e) => {
    let valid = false
    setValidatedProduct(valid);
    
    if (!valid) {

      let array = B2BData.b2b_model_list
      let data = []
      // let price_data = []

      B2BPrices.forEach((product, key) => {
        data.push({
          model: ProductModel,
          claim_price: B2BData.claim_price,
          customer_id: B2BData.customer_id,
          code: ProductCode,
          showroom: Customers.find((item) => item.id === B2BData.customer_id),
          [product.size]: Number(product.price) || null,
          size: product.size,
          price: Number(product.price) || null,
        })
        product.model = ProductModel
        product.code = ProductCode
        // console.log(product)
      });

      // console.log(B2BPrices)

      console.log(B2BData);
      const checkExist = array.find(item => item.model === ProductModel)

      if (!checkExist) {
        array.push(
          {
            model: ProductModel,
            data: [data]
          }
        )

        // setB2BPrices(...B2BPrice, data)
        data.forEach ((item) => {
          B2BData.b2b_prices.push(item)
        })
        console.log(B2BPrices)
        setB2BData({...B2BData, b2b_model_list: array, b2b_product_times: [...B2BData.b2b_product_times, ...ProductTimes]})
        setProductTimes(modelChoice)
        setValidatedProduct(false);
        console.log(B2BData)
      }else{
        // exist
        let result = true
        checkExist.data.forEach(item => {
          console.log(item)
          const checkExistShowRoom = B2BData.b2b_showroom.find(item => item.id === item.showroom?.id)
          const value = item.find(item => item.code === ProductCode)
          if(value && checkExistShowRoom) result = false
        });

        if (result) {
          const index = array.findIndex(item => item.model === ProductModel)
          array[index].data = [
            ...array[index].data,
            data
          ]

          // setB2BPrices(...B2BPrice, data)
          // console.log(B2BPrices)
          data.forEach ((item) => {
            B2BData.b2b_prices.push(item)
          })
          setB2BData({...B2BData, b2b_model_list: array, b2b_product_times: [...B2BData.b2b_product_times, ...ProductTimes]})
          setProductTimes(modelChoice)
          setValidatedProduct(false);
          console.log(B2BData)
        }
      }
    }

    // console.log(ProductCode)
    // console.log(B2BData.b2b_prices)
  }

  const UpdateB2BProductTimeState = () => {

    let valid = false
    // validate
    // ProductTimes.forEach(time => {
    //   if (!time.price) {
    //     valid = true
    //   }
    // });

    setValidatedProductTime(valid);
    
    if (!valid) {
      let obj = {}
      let edited = []

      B2BPrices.forEach((time, key) => {
        console.log(time)
        time[time.size] = time.price
        if(!time.model) time.model = Model
        if(!time.code) time.code = Code
        if(!time.customer_id) time.customer_id = B2BData.customer_id
        edited.push(time)
      });

      obj.model = ProductModel
      obj.code = ProductCode
      
      let filter = B2BData.b2b_product_times.filter((item) => item.model !== obj.model || item.code !== obj.code);
      let result = filter.concat(edited)

      let b2b_model_list = B2BData.b2b_model_list
      let list = b2b_model_list.find(item => item.model === obj.model)
      
      if (list) {
        list?.data.forEach((item, key) => {
          let index = item.findIndex(x => { return x.code === obj.code && x.showroom.id == B2BData.customer_id })
          if (index !== -1) {
            list.data[key] = edited
          }
        });
      }else{
        list = b2b_model_list.find(item => !item.model)
        list.model = ProductModel
        list?.data.forEach((item, key) => {
          let index = item.findIndex(x => { return !x.code && x.showroom.id == B2BData.customer_id })
          if (index !== -1) {
            list.data[key] = edited
          }
        });
      }
      console.log('result',result);
      console.log('edited',edited);
      setB2BData({
        ...B2BData,
        b2b_model_list: b2b_model_list,
        b2b_product_times: result,
        b2b_prices: result
      }) 
      console.log(B2BData)

      setProductTimes(modelChoice)
      setValidatedProductTime(false);
      setProductTimeEditMode(false)
    }
  }

  const InsertB2BPrice = async (e) => {
    // let valid = false

    if (!B2BData.customer_id) return false

    // B2BPrices.forEach(item => {
    //   if (!item.price) {
    //     valid = true
    //   }
    // });

    // setValidatedProduct(valid);
    
    // if (!valid) {
      let obj = {}
      B2BPrices.forEach((product, key) => {
        product.customer_id = B2BData.customer_id
        product.claim_price = B2BData.claim_price
        obj.customer_id = B2BData.customer_id
        obj.showroom = Customers.find((item) => item.id === B2BData.customer_id)
        obj[product.size] = Number(product.price) || null
        obj.ref = B2BPrices
        obj.ref.forEach((data) => {
          data.model = ProductModel
          data.code = ProductCode
        })
        product.edit = false
      });
  
      setB2BData({...B2BData, b2b_price_list: [...B2BData.b2b_price_list, obj ], b2b_prices: [...B2BData.b2b_prices, B2BPrices], customer_id: null,  claim_price: null})
      setB2BPrices(B2BPrice)
      setCustomers([...Customers.filter(item => item.id !== B2BData.customer_id)])
      // setValidatedProduct(false);
      console.log(obj)
    // }
  }

  const InsertB2BPricePartsModel = async (e) => {
    // let valid = false

    if (!B2BData.customer_id) return false

    // B2BPrices.forEach(item => {
    //   if (!item.price) {
    //     valid = true
    //   }
    // });

    // setValidatedProduct(valid);
    
    // if (!valid) {
      let obj = {}
      B2BPricePartsModels.forEach((product, key) => {
        product.customer_id = B2BData.customer_id
        product.claim_price = B2BData.claim_price
        obj.customer_id = B2BData.customer_id
        obj.showroom = Customers.find((item) => item.id === B2BData.customer_id)
        obj[product.size] = Number(product.price) || null
        obj.ref = B2BPricePartsModels
        product.edit = false
      });
  
      setB2BData({...B2BData, b2b_price_list: [...B2BData.b2b_price_list, obj ], b2b_prices: [...B2BData.b2b_prices, B2BPricePartsModels], customer_id: null,  claim_price: null})
      setB2BPricePartsModels(B2BPricePartsModel)
      setCustomers([...Customers.filter(item => item.id !== B2BData.customer_id)])
  }

  const [Products, setProducts] = useState([]);
  const [Customers, setCustomers] = useState([]);

  const [dataModelOption, setdataModelOption] = useState([]);

  const GetPackageDetail = async (data) => {
    dispatch(FindPackage(id)).then( response => {
      const data = response.data.data
      // console.log('GetPackageDetail',data);
      // setProductTimes(data.middle_sizes)

      console.log(Data)
      
      let temp = Data.model_list
      let templist = []
      let tempsize = []

      setdataModelOption(Data.model_list)

      if ((['แพ็กเกจ']).includes(name)) {
        let specials = []
        data.special_packages.forEach(item => {
          let exist = null
          let obj = {}
          
          switch (item.type) {
            case 'สินค้า':
              exist = Special.Products.find(item => item.id === item.product_id)
              break;
            case 'บริการ':
              exist = Special.Products.find(item => item.id === item.service_id)
              break;
            case 'แพ็กเกจ':
              exist = Special.Products.find(item => item.id === item.package_id)
              break;
          }

          if (!exist) {
            obj.key = specials.length
            if(item.id) obj.id = item.id
            obj.quantity = item.quantity

            if (item.product_unit_sale_id) {
              obj.product_unit_sale_id = item.product_unit_sale_id
              obj.unit_sale = item.product_unit_sale.unit_sale
            }else{
              obj.product_unit_sale_id = null
            }

            obj.sale_price = 0
            obj.total = 0
            obj.type = item.type
      
            if (item.type === 'สินค้า') {
              obj.product_id = item.product_id
              obj.name = item.product.name
              obj.unit_sales = item.product.unit_sales
              obj.service_id = null
              obj.package_id = null
            }
            if (item.type === 'บริการ') {
              obj.service_id = item.service_id
              obj.name = item.service.name
              obj.package_id = null
              obj.product_id = null
            }
            if (item.type === 'แพ็กเกจ') {
              obj.package_id = item.package_id
              obj.name = item.package.name
              obj.service_id = null
              obj.product_id = null
            }
            specials.push(obj)
          }
        });
        
        const all = data.middle_sizes.find(item => item.size === 'ราคาเหมา' && item.active)
        if(all){
          all.key = 0
          tempsize.push(all)
        }else{
          let all_p = data.middle_sizes.find(item => item.size === 'ราคาเหมา')
          if (all_p) {
            all_p.key = 0
            tempsize.push(all_p)
          }else{
            tempsize = Special.Data.product_times
          }
        }

        setSpecial({...Special, Products: specials, Data:{...Special.Data, product_times: tempsize , all_size: Boolean(all) }})

        tempsize = []
      }

      if ((['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name)) {
        data.middle_sizes.forEach((product, key) => {
          const checkExist = temp.find(item => item.model === product.model)

          if (!checkExist) {
            templist = []
            let list = data.middle_sizes.filter((item) => item.model === product.model && item.code === product.code);
            list.forEach(item => {
              templist.push({
                id: item.id,
                package_id: item.package_id,
                model: item.model,
                code: item.code,
                size: item.size,
                price: item.price,
                process_times: item.process_times,
                treatment_price: item.treatment_price,
                [item.size]: Number(item.price) || null
              })
            });

            temp.push(
              {
                model: product.model,
                data: [templist]
              }
            )

          }else{
            let result = true
            templist = []
            checkExist.data.forEach(item => {
              const value = item.find(item => item.code === product.code)
              if(value) result = false
            });

            if (result) {
              let list = data.middle_sizes.filter((item) => item.model === product.model && item.code === product.code);
              list.forEach(item => {
                templist.push({
                  id: item.id,
                  package_id: item.package_id,
                  model: item.model,
                  code: item.code,
                  size: item.size,
                  price: item.price,
                  process_times: item.process_times,
                  [item.size]: Number(item.price) || null
                })
              });

              const index = temp.findIndex(item => item.model === product.model)
              temp[index].data = [
                ...temp[index].data,
                templist
              ]
            }
          }
        })
      }

     

      if (!(['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name)) {
        data.middle_sizes.filter(item => item.size !== 'ราคาเหมา').forEach((item, key) => {
          let sizeobj = {}
          sizeobj.key = key
          sizeobj.id = item.id
          sizeobj.package_id = item.package_id
          sizeobj.size = item.size
          sizeobj.price = item.price
          sizeobj.process_times = item.process_times
          sizeobj.treatment_price = item.treatment_price
          sizeobj.active = item.active
          sizeobj.edit = false
          tempsize.push(sizeobj)
        });

        if(tempsize.length > 0){
          setProductTimes(tempsize)
        }
      }

      tempsize = []
    
      let exist_product = []
      let pq = []
      
      let products = []
      data.product_quantities.forEach(item => {
        if (!exist_product.includes(item.product_id)) {
          exist_product.push(item.product_id)
          let select_products = data.product_quantities.filter(value => value.product_id === item.product_id);
          let obj = {}
          select_products.forEach((element, key) => {
            obj[element.size] = Number(element.quantity) || null
            obj.product = element.product
            obj.product_id = element.product_id
            obj.unit_sale_id = element.unit_sale_id
            obj.ref = select_products
            obj.usedType = []
            element.ref = select_products
            element.key = key
            element.usedType = []
            if (element.type && Array.isArray(element.type)) {
              element.type.forEach(item => {
                element.usedType.push(
                  useType.find(data => data.value === item)
                )
                obj.usedType.push(
                  useType.find(data => data.value === item)
                )
              });
            }else{
              if (element.type) {
                JSON.parse(element.type).forEach(item => {
                  element.usedType.push(
                    useType.find(data => data.value === item)
                  )
                  obj.usedType.push(
                    useType.find(data => data.value === item)
                  )
                });
              }
            }
            products.push(element)
          });
          
          obj.key = pq.length
          pq.push(obj);
        }
      });

      let exist = []
      let b2b_temp = []
      let b2b_prices = []
      let b2b_obj = {}

      data?.b2b_sizes.forEach(item => {
        if (!exist.includes(item.customer_id)) {
          exist.push(item.customer_id)
          let select_customer = data.b2b_sizes.filter(value => value.customer_id === item.customer_id);
          select_customer.forEach(element => {
            b2b_obj.id = data.id
            b2b_obj.customer_id = element.customer_id
            b2b_obj.showroom = item.showroom
            b2b_obj[element.size] = Number(element.price) || null
            b2b_prices.push(element)
          });

          b2b_obj.ref = select_customer
          b2b_obj.key = b2b_temp.length

          b2b_temp.push(b2b_obj)

          b2b_obj = {}
        }
      });


      //! Code B2B Model List
      let b2b_temp_list = [];
      let b2b_temp_model = [];
      let b2b_temp_showroom = [];

      if ((['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย', 'บริการเคลือบเหมา']).includes(name)) {
        data.b2b_sizes.forEach((product, key) => {
          const checkExist = b2b_temp_model.find(item => item.model === product.model)
          // console.log('TEST', data.b2b_sizes)

          // console.log(product.showroom);
          const checkExistShowRoom = b2b_temp_showroom.find(item => item.id === product.showroom.id)
          if(!checkExistShowRoom){
            // console.log('showroom not exists')
            b2b_temp_showroom.push(product.showroom)
          }

          if (!checkExist) {
            b2b_temp_list = []
            let list = data.b2b_sizes.filter((item) => item.model === product.model && item.code === product.code && item.showroom.id === product.showroom.id);
            list.forEach(item => {
              // console.log(item)
              b2b_temp_list.push({
                id: item.id,
                package_id: item.package_id,
                model: item.model,
                code: item.code,
                size: item.size,
                showroom: item.showroom,
                price: item.price,
                process_times: item.process_times,
                treatment_price: item.treatment_price,
                customer_id: item.customer_id,
                [item.size]: Number(item.price) || null
              })
            });

            b2b_temp_model.push(
              {
                model: product.model,
                data: [b2b_temp_list]
              }
            )

          }else{
            let result = true
            b2b_temp_list = []
            checkExist.data.forEach(item => {
              const value = item.find(item => item.code === product.code && item.showroom.id === product.showroom.id)
              if(value) result = false
            });

            if (result) {
              let list = data.b2b_sizes.filter((item) => item.model === product.model && item.code === product.code && item.showroom.id === product.showroom.id);
              list.forEach(item => {
                console.log(item)
                b2b_temp_list.push({
                  id: item.id,
                  package_id: item.package_id,
                  model: item.model,
                  code: item.code,
                  size: item.size,
                  showroom: item.showroom,
                  price: item.price,
                  process_times: item.process_times,
                  [item.size]: Number(item.price) || null
                })
              });

                const index = b2b_temp_model.findIndex(item => item.model === product.model)
                b2b_temp_model[index].data = [
                  ...b2b_temp_model[index].data,
                  b2b_temp_list
                ]
            }

          }
        })
      }

      
      // console.log('tempsize',tempsize);
      setB2BData({...B2BData, 
        b2b_prices: b2b_prices,
        b2b_price_list: b2b_temp,
        active_b2b: data.active_b2b,
        b2b_model_list: b2b_temp_model,
        b2b_product_times: data.b2b_sizes,
        b2b_showroom: b2b_temp_showroom
      })
      console.log('Debug')
      console.log(B2BData)
      setData({
        name: data.name,
        code: data.code,
        period: data.period,
        period_type: data.period_type,
        service_time: data.service_time,
        treatment_time: data.treatment_time,
        warranty_period: data.warranty_period,
        schedule: data.schedule,
        setup: data.setup,
        sizes: SizeModel,
        times: [],
        products: products,
        enamel: data.enamel,
        wash: data.wash,
        wash_upholstery: data.wash_upholstery,
        wash_door: data.wash_door,
        wash_belt: data.wash_belt,
        // product_times: tempsize,
        product_times: data.middle_sizes,
        active: data.active,
        range1: data.treatment_ranges ? JSON.parse(data.treatment_ranges)[0] : null,
        range2: data.treatment_ranges ? JSON.parse(data.treatment_ranges)[1] : null,
        renew_price: data.renew_price,
        // for display
        product_list: pq,
        model_list: temp,
        
      });

      GetCustomer(exist)

      GetProduct(exist_product)

      setFileName({...FileName, Image: [data.logo_url]})

      // console.log(B2BData);

    }).catch(e => {

    })
  }

  const GetProduct = async (exist) => {

    try {
      const response = await dispatch(ProductList({ has_sales: true }));
      if (response) {
        setProducts(response.data.data.filter(item => !exist.includes(item.id)));
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async (exist) => {
    const params = {
      type: ['โชว์รูม']
    }
    try {
      const response = await dispatch(Customer(params));
      if (response) {
        setCustomers(response.data.data);
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    
    let formData = new FormData();
    if(name === 'แพ็กเกจ'){
      formData.append('type', 'แพ็คเกจพิเศษ');
    }else{
      formData.append('type', name);
    }


    if (name === 'แพ็กเกจ') {
     
      formData.append('category', 'พิเศษ');
      let i = 0 ;
      while (i < Special.Data.product_times.length){
        if(Special.Data.product_times[i].id) formData.append(`product_times[${i+ProductTimes.length}][id]`, Special.Data.product_times[i].id);
        if(Special.Data.product_times[i].size) formData.append(`product_times[${i+ProductTimes.length}][size]`, Special.Data.product_times[i].size);
        if(Special.Data.product_times[i].price) formData.append(`product_times[${i+ProductTimes.length}][price]`, Special.Data.product_times[i].price);
        if(Special.Data.product_times[i].treatment_price) formData.append(`product_times[${i+ProductTimes.length}][treatment_price]`, Special.Data.product_times[i].treatment_price);
        if(Special.Data.product_times[i].process_times) formData.append(`product_times[${i+ProductTimes.length}][process_times]`, Special.Data.product_times[i].process_times);
        formData.append(`product_times[${i+ProductTimes.length}][active]`, Special.Data.product_times[i].active);
        i++;
      }

      i = 0 ;
      while (i < Special.Products.length){
        const product = Special.Products[i]
        formData.append(`special_products[${i}][type]`, product.type);
        if(product.id) formData.append(`special_products[${i}][id]`, product.id);
        if(product.product_id) formData.append(`special_products[${i}][product_id]`, product.product_id);
        if(product.service_id)formData.append(`special_products[${i}][service_id]`, product.service_id);
        if(product.package_id) formData.append(`special_products[${i}][package_id]`, product.package_id);
        formData.append(`special_products[${i}][quantity]`, product.quantity);
        
        if(product.product_unit_sale_id) formData.append(`special_products[${i}][product_unit_sale_id]`, product.product_unit_sale_id);
        i++;
      }
    }else{
      formData.append('category', 'ธรรมดา');
    }
    if(Data.name) formData.append('name', Data.name);
    if(Data.code) formData.append('code', Data.code);
    if(Data.period) formData.append('period', Data.period);
    if(Data.period_type) formData.append('period_type', Data.period_type);
    if(Data.service_time) formData.append('service_time', Data.service_time);
    if(Data.schedule) formData.append('schedule', Data.schedule);
    if(Data.setup) formData.append('setup', Data.setup);
    if(Data.enamel) formData.append('enamel', Data.enamel);
    if(Data.wash) formData.append('wash', Data.wash);
    if(Data.wash_upholstery) formData.append('wash_upholstery', Data.wash_upholstery);
    if(Data.wash_door) formData.append('wash_door', Data.wash_door);
    if(Data.wash_belt) formData.append('wash_belt', Data.wash_belt);
    if(Data.warranty_period) formData.append('warranty_period', Data.warranty_period);
    if(Data.treatment_time) formData.append('treatment_time', Data.treatment_time);
    if(Data.range1 && Data.range2) formData.append('treatment_ranges', JSON.stringify([Data.range1 , Data.range2]));
    formData.append('active', Data.active);
    const check = ProductTimes.map((item) => {
      let result = Data.product_times.find((i) => {
        return i.size === item.size 
      })
      return result.price !== item.price || result.process_times !== item.process_times
    })
    let checker = check.every(v => v === true);
    // console.log('checker',checker);
    if(Data.product_times && Data.product_times.length > 0 && checker){
      let i = 0 ;
      while (i < Data.product_times.length){
        if(Data.product_times[i].model) formData.append(`product_times[${i}][model]`, Data.product_times[i].model);
        if(Data.product_times[i].code) formData.append(`product_times[${i}][code]`, Data.product_times[i].code);
        if(Data.product_times[i].id) formData.append(`product_times[${i}][id]`, Data.product_times[i].id);
        if(Data.product_times[i].package_id) formData.append(`product_times[${i}][package_id]`, Data.product_times[i].package_id);
        if(Data.product_times[i].size && Data.product_times[i].price) formData.append(`product_times[${i}][size]`, Data.product_times[i].size);
        if(Data.product_times[i].price) formData.append(`product_times[${i}][price]`, Data.product_times[i].price);
        if(Data.product_times[i].treatment_price) formData.append(`product_times[${i}][treatment_price]`, Data.product_times[i].treatment_price);
        if(Data.product_times[i].process_times) formData.append(`product_times[${i}][process_times]`, Data.product_times[i].process_times);
        formData.append(`product_times[${i}][active]`, Data.product_times[i].active);
        i++;
      }
    }else{
      let i = 0 ;
      while (i < ProductTimes.length){
        if(ProductTimes[i].model) formData.append(`product_times[${i}][model]`, ProductTimes[i].model);
        if(ProductTimes[i].code) formData.append(`product_times[${i}][code]`, ProductTimes[i].code);
        if(ProductTimes[i].id) formData.append(`product_times[${i}][id]`, ProductTimes[i].id);
        if(ProductTimes[i].package_id) formData.append(`product_times[${i}][package_id]`, ProductTimes[i].package_id);
        if(ProductTimes[i].size) formData.append(`product_times[${i}][size]`, ProductTimes[i].size);
        if(ProductTimes[i].price) formData.append(`product_times[${i}][price]`, ProductTimes[i].price);
        if(ProductTimes[i].treatment_price) formData.append(`product_times[${i}][treatment_price]`, ProductTimes[i].treatment_price);
        if(ProductTimes[i].process_times) formData.append(`product_times[${i}][process_times]`, ProductTimes[i].process_times);
        formData.append(`product_times[${i}][active]`, ProductTimes[i].active);
        i++;
      }
    }

    if(Data.products && Data.products.length > 0){
      let i = 0;
      let key = 0;
      while (i < Data.products.length){
          let product = Data.products[i]

          if(product.id) formData.append(`products[${key}][id]`, product.id);
          if(product.package_id) formData.append(`products[${key}][package_id]`, product.package_id);
          formData.append(`products[${key}][size]`, product.size);
          formData.append(`products[${key}][quantity]`, product.quantity);
          formData.append(`products[${key}][product_id]`, product.product_id);
          formData.append(`products[${key}][unit_sale_id]`, product.unit_sale_id);
          let type = []
          product.usedType.forEach(element => {
            type.push(element.value)
          });
          formData.append(`products[${key}][type]`, JSON.stringify(type));
          key++;
        i++;
      }
    }

    if(Data.logo) formData.append('logo', Data.logo[0]);
    formData.append('_method', 'put');

    let data = {
      id,
      formData
    }

    dispatch(UpdatePackage(data)).then(response => {
      setErrors([])
      // history.push(`/package/${name}`);
      GetPackageDetail();
    }).catch(e => {
      let errors = []
      if (e.response && e.response.data && e.response.data.messages) {
        e.response.data.messages.forEach(element => {
          errors.push(element)
        });
        setErrors(errors)
      }
    })
  };

  const EditB2BPrice = async (e) => {
    e.preventDefault();

    // console.log(Customers);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidatedB2B(true);

    let formData = new FormData();
    formData.append('type', name);
    formData.append('category', 'ธรรมดา');
    if(B2BData.b2b_prices && B2BData.b2b_prices.length > 0){
      let i = 0;
      let key = 0;

      while (i < B2BData.b2b_prices.length){
          if (B2BData.b2b_prices[i] && B2BData.b2b_prices[i].length) {
            console.log(B2BData.b2b_prices[i]);
            B2BData.b2b_prices[i].forEach(product => {
              if(product.id) formData.append(`b2b_prices[${key}][id]`, product.id);
              formData.append(`b2b_prices[${key}][type]`, 'ราคาB2B');
              formData.append(`b2b_prices[${key}][model]`, product.model);
              formData.append(`b2b_prices[${key}][code]`, product.code);
              formData.append(`b2b_prices[${key}][package_id]`, id);
              formData.append(`b2b_prices[${key}][claim_price]`, product.claim_price);
              formData.append(`b2b_prices[${key}][customer_id]`, product.customer_id);
              if(product.price) formData.append(`b2b_prices[${key}][price]`, product.price);
              formData.append(`b2b_prices[${key}][size]`, product.size);
              formData.append(`b2b_prices[${key}][active]`, 1);
              key++;
            });
          }else{
            let product =  B2BData.b2b_prices[i]
            // console.log(product);
            if(product.id) formData.append(`b2b_prices[${key}][id]`, product.id);
            formData.append(`b2b_prices[${key}][type]`, 'ราคาB2B');
            formData.append(`b2b_prices[${key}][model]`, product.model);
            formData.append(`b2b_prices[${key}][code]`, product.code);
            formData.append(`b2b_prices[${key}][package_id]`, id);
            formData.append(`b2b_prices[${key}][claim_price]`, product.claim_price);
            formData.append(`b2b_prices[${key}][customer_id]`, product.customer_id);
            if(product.price) formData.append(`b2b_prices[${key}][price]`, product.price);
            formData.append(`b2b_prices[${key}][size]`, product.size);
            formData.append(`b2b_prices[${key}][active]`, 1);
            key++;
          }
        i++;
      }
    }
    
    formData.append('active_b2b', B2BData.active_b2b);
    formData.append('_method', 'put');
    
    const data = {
      id,
      formData
    }

    try {
      const response = await dispatch(UpdateB2BPrice(data));
     
      if(response){
        setValidatedB2B(false);
        // history.push(`/package/${name}`);
        GetPackageDetail();
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const DeletePackage = async () => {
    setModalConfirmDelete(false)
    try {
      const response = await dispatch(DestroyPackage(id))
      if (response) {
        history.push(`/package/${name}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const DeletePackageB2B = async () => {
    setModalConfirmDeleteB2B(false)
    try {
      const response = await dispatch(DestroyPackageB2B(id))
      if (response) {
        history.push(`/package/${name}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const DeleteProductB2B = async (product) => {
    const response = await dispatch(DeleteB2bProduct(product))
    if (response) {
      // history.push(`/package/${name}/${id}/edit/b2b`);
      GetPackageDetail();
    }
  };

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการแพ็คเกจ'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push(`/package/${name}`);
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  useEffect(() => {
    getAuth();
    GetPackageDetail();
    // Special
    if ((['แพ็กเกจ']).includes(name)) {
      GetService();
      GetPackageType();
    }
  }, []);

  useEffect(() => {
    if(UnitSaleId !== null){
      setDisableProduct(true)
    }else{
      setDisableProduct(false)
    }
    
  },[UnitSaleId])



  const [FileName, setFileName] = useState({
    Logo: '',
    Image: []
  });

  const ToggleDocumentFile = () => {
    if(LogoFileRef.current) LogoFileRef.current.click();
  }

  const SetLogoFile = (e) => {
    setData({ ...Data, logo : e.target.files})
    const name = []
    const logo = []
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      logo.push(URL.createObjectURL(item))
      name.push(item.name)
    }
    setFileName({ ...FileName, Logo : name, Image: logo})
  }

  const [ProductEditMode, setProductEditMode] = useState(false);

  const EditProduct = (product, table_key) => {
    const data = []

    let temp = {}
    ProductSizes.forEach(item => {
      let obj = {}
      if (product.ref) {
        let data = product.ref.find(value => value.size === item.size)
        obj.id = data.id
        obj.package_id = data.package_id
      }
      obj.usedType = product.usedType
      obj.size = item.size
      obj.quantity = product[item.size]
      obj.product_id = product.product_id
      obj.unit_sale_id = product.unit_sale_id
      temp = {
        product_id: product.product_id,
        unit_sale_id: product.unit_sale_id
      }
      obj.product = product.product
      obj.key = data.length
      obj.table_key = table_key
      data.push(obj)
    });
    
    const UsingType = product.usedType
    setUsedType(UsingType)

    const sales = product.product.unit.sales
    setUnitSales(sales)
    setUnitSaleId(temp.unit_sale_id)
   
    const pd = Products.filter(item => item.id === product.product_id)
    if (pd.length === 0) {
      setProducts([...Products, product.product])
    }
    
    setProductId(product.product_id)
    setProductSizes(data)
    setProductEditMode(true)
  }

  const DeleteProduct = (product, key) => {
    setData({...Data, products: Data.products.filter(item => item.product_id !== product.product_id),  product_list: Data.product_list.filter(item => item.product_id !== product.product_id) })
    setProducts([...Products, product.product]) 
  }

  const DeleteProductTime = (product, key) => {
    // console.log(1)

    let array = ProductTimes;
    // console.log(array);
    array.splice(key, 1);
    // console.log(array);

    // setData({...Data, products: Data.products.filter(item => item.product_id !== product.product_id),  product_list: Data.product_list.filter(item => item.product_id !== product.product_id) })
    setProductTimes([array]) 
  }

  const UpdateProductState = () => {
    let obj = {}
    let edited = []

    ProductSizes.forEach(product => {
      obj.usedType = usedType
      obj[product.size] = Number(product.quantity) || null
      obj.product_id = product.product_id
      obj.sale_unit_id = product.sale_unit_id
      obj.unit_sale_id = product.unit_sale_id
      obj.key = product.table_key
      obj.product = product.product
      product.usedType = usedType
      edited.push(product)
    });

    // delete all old data by product_id
    let filter = Data.products.filter((item) => item.product_id !== obj.product_id);
    let result = filter.concat(edited)

    setData(prevState => ({
      ...Data,
      product_list: prevState.product_list.map(
        el => el.key === obj.key ? obj : el
      ),
      products: result
    }))

    setProductSizes(ProductSizeChoice)
    setValidatedProduct(false);
    setProductId(null);
    setUnitSaleId(null);
    setUsedType([])
    setProductEditMode(false)
  }

  const [B2BPriceEditMode, setB2BPriceEditMode] = useState(false);

  const EditB2BPriceState = (b2b) => {
    let customer_temp = Customers.filter(item => item.temp !== true)
    let temp = {}
    if (b2b && b2b.ref) {
      b2b.ref.forEach(item => {
        temp.customer_id = item.customer_id
        temp.claim_price = item.claim_price
      });
    }
    
    let obj = {}
    const data = []
    b2b.ref.forEach((price, key) => {
      obj.showroom = b2b.showroom
      obj[price.size] = Number(price.price) || null

      price.size = price.size
      price.price = b2b[price.size]
      price.key = key
      data.push(price)
    });

    if (!b2b.showroom.temp) b2b.showroom.temp = true

    setCustomers([...customer_temp, b2b.showroom])

    setB2BData(prevState => ({
      ...B2BData,
      customer_id: temp.customer_id,
      claim_price: temp.claim_price
    }))
    
    setB2BPrices(data)
    setB2BPriceEditMode(true);
  }

  const DeleteB2BPriceState = (b2b) => {
    setB2BData({...B2BData, b2b_price_list: [...B2BData.b2b_price_list.filter(item => item.customer_id !== b2b.customer_id) ], b2b_prices: [...B2BData.b2b_prices.filter(item => item.customer_id !== b2b.customer_id) ]})
    setCustomers([...Customers, b2b.showroom])
  }

  const UpdateB2BPriceState = () => {
    let obj = {}
    let edited = []

    B2BPrices.forEach((item, key) => {
      item[item.size] = item.price
      edited.push(item)
    });

    obj.customer_id = B2BData.customer_id
    obj.claim_price = B2BData.claim_price
    
    let filter = B2BData.b2b_prices.filter((item) => item.customer_id !== obj.customer_id);
    let result = filter.concat(edited)

    let price_list = B2BData.b2b_price_list
    let list = price_list.find(item => item.customer_id === obj.customer_id )

    list.ref.forEach((item, key) => {
      let data = edited.find(x =>  x[item.size] )
      if (data) {
        item[item.size] = data.price
        item.price = data.price
        list[item.size] = data.price
      }
    });

    setB2BData({...B2BData, customer_id: null, claim_price: null, b2b_price_list: price_list, b2b_prices: result})
    setB2BPrices(B2BPrice)
    setB2BPriceEditMode(false)
  }

  // Special
  const [SelectOption, setSelectOption] = useState('product')
  const [Special, setSpecial] = useState({
    ProductId: null,
    Products: [],
    Data: {
      all_size: false,
      product_times: [
        {
          key: 0,
          size: 'ราคาเหมา',
          price: null,
          process_times: null,
          treatment_price: null
        },
      ]
    },
    ServiceId: null,
    PackageId: null,
    Total: 0,
  });

  const SelectUnit = (data, key) => {
    setSpecial(prevState => ({...Special,
      Products: prevState.Products.map(
        el => el.key === key ? { ...el, unit_sale: data, product_unit_sale_id: data.pivot.id, sale_price: data.pivot.price, total: data.pivot.price * el.quantity  }: el
      )
    })) 
  }

  const Changequantity = (value, key) => {
    setSpecial(prevState => ({...Special,
      Products: prevState.Products.map(
        el => el.key === key ? { ...el, quantity: value, total: el.sale_price * value }: el
      )
    }))
  }

  const SwitchPrice = (checked) => {
    let ChangeMiddleStatus = [...ProductTimes]
    if (checked) {
      ChangeMiddleStatus.forEach(item => {
        item.active = 0
      });

      setSpecial({...Special,
        Data:{
          ...Special.Data,
          all_size: checked,
          product_times: Special.Data.product_times.map(
            el => el.key === 0 ? { ...el, active: 1 }: el
          )
        }
      })
    }else{
      ChangeMiddleStatus.forEach(item => {
        item.active = 1
      });

      setSpecial({...Special,
        Data:{
          ...Special.Data,
          all_size: checked,
          product_times: Special.Data.product_times.map(
            el => el.key === 0 ? { ...el, active: 0 }: el
          )
        }
      })
    }

    setProductTimes(ChangeMiddleStatus)
  }

  const InsertSpecial = (data, type) => {
    let exist = null
    switch (type) {
      case 'สินค้า':
        exist = Special.Products.find(item => item.id === data.product_id)
        break;
      case 'บริการ':
        exist = Special.Products.find(item => item.id === data.service_id)
        break;
      case 'แพ็กเกจ':
        exist = Special.Products.find(item => item.id === data.package_id)
        break;
    }

    if (!exist) {
      data.key = Special.Products.length
      data.quantity = 1
      data.product_unit_sale_id = null
      data.sale_price = 0
      data.total = 0
      data.type = type

      if (type === 'สินค้า') {
        data.product_id = data.id
        data.service_id = null
        data.package_id = null
        data.product = data
        if(data.id) delete data.id
        setProducts([...Products.filter(item => item.id !== data.id)])
      }
      if (type === 'บริการ') {
        data.service_id = data.id
        data.package_id = null
        data.product_id = null
        data.service = data
        if(data.id) delete data.id
        setServices([...Services.filter(item => item.id !== data.id)])
      }
      if (type === 'แพ็กเกจ') {
        data.package_id = data.id
        data.service_id = null
        data.product_id = null
        data.package = data
        if(data.id) delete data.id
        setPackages([...Packages.filter(item => item.id !== data.id)])
      }

      setSpecial({...Special, Products: [...Special.Products, data]})
      setPackage(null)
    }
  }

  const RemoveSpecial = (data) => {
    let result = null

    switch (data.type) {
      case 'สินค้า':
        result = Special.Products.filter((item) => item.product_id !== data.product_id)
        setProducts([...Products, data.product])
        break;
      case 'บริการ':
        result = Special.Products.filter((item) => item.service_id !== data.service_id)
        setServices([...Services, data.service])
        break;
      case 'แพ็กเกจ':
        result = Special.Products.filter((item) => item.package_id !== data.package_id)
        setPackages([...Packages, data.package])
        break;
    }
    setSpecial({...Special, Products: result })
  }

  const [PackageType, setPackageType] = useState([]);

  const GetPackageType = async (data) => {
    try {
      const response = await dispatch(PackageTypeList());
      if (response) {
        let data = response.data.data
        let result = []
        data.forEach(item => {
          if (item.name !== 'บริการเคลือบเฉพาะจุด' && item.name !== 'แพ็คเกจพิเศษ') {
            result.push(item)
          }
        });
        setPackageType(result)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [Packages, setPackages] = useState([]);
  const [Package, setPackage] = useState([]);
  const GetPackage = async (type) => {
    try {
      const params = {
        type
      }
      const response = await dispatch(PackageList(params))
      if (response) {
        setPackages(response.data.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [Services, setServices] = useState([]);
  async function GetService(){
    try {
      const response = await dispatch(LoadServices());
      const service = response.data.data
      setServices(service);
    }  catch (error) {
      console.error(error);
    } finally {

    }
  }

  function close() {
    history.push(`/`);
  }

  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);

  const ConfirmDelete = (event) => {
    setModalConfirmDelete(true)
  }

  const [modalConfirmDeleteB2B, setModalConfirmDeleteB2B] = useState(false);

  const ConfirmDeleteB2B = (event) => {
    setModalConfirmDeleteB2B(true)
  }

  const DisplayPrice = (name, sizes) => {
    const result = sizes.find(item => item[name])
    return result && result[name] ? result[name] : ''
  }

  const DisplayShowroom = (item) => {
    const result = item.find(item => item.showroom.organization)
    return result && result.showroom.organization ? result.showroom.organization : ''
  }

  const DisplayCode = (item) => {
    const result = item.find(item => item.code)
    return result && result.code ? result.code : ''
  }


  const ProductModelSelect = (data) => {
    let Code = []
    setProductModel(data.model) 
    data.data.forEach((data, key) => {
      Code.push({ label: data[0].code, value: data[0].code})
    })
    setDataProductCode(data?.data.length > 0 ? Code : null)
    console.log(DataProductCode)
  }

  function exit() {
    history.push(`/package/${name}`);
  }
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Package_image} />
                  <LinkContainer to={`/package/${name}`} className="cursor-pointer">
                    <b>แพ็คเกจ &gt; { name }</b> 
                  </LinkContainer>
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Package_image} />
                  <b>แพ็คเกจ &gt; { name }</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="tabsicon">
                  <TabsIcon />
                </Row>
                <Row className="tabs-button">
                  <Col>
                    <Tabs
                      defaultActiveKey={type}
                      transition={false}
                      id="noanim-tab-example"
                      className="tabs-package mb-3"
                    >

                      <Tab eventKey="middle" title="ราคากลาง">
                        {
                          Errors.length > 0 &&
                          <Alert variant="danger">
                            <Alert.Heading>เกิดข้อผิดพลาด !</Alert.Heading>
                            <div className='text-left'>
                            {
                              Errors.map((error, key) => {
                                return(
                                  <p>{error}</p>
                                )
                              })
                            }
                            </div>
                          </Alert>
                        }
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Container
                            fluid
                            className="package-container Service Stock User set-heght"
                          >
                            {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
                              <p className="form-title">ข้อมูลสินค้า</p>
                              {/* <Form.Group className="package-data" controlId="formBasicEmail"> */}
                                <Row className="g-0">
                                  <Col xs={2} sm={3} md={3} lg={2} xl={2} className="text-title">
                                    <p>ชื่อสินค้า</p>
                                  </Col>
                                  <Col xs={10} md={6} lg={5} xl={4}>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={Data.name}
                                      required
                                      onChange={(e) => setData({...Data, name: e.target.value})}
                                      className="input-middle-price distance-field my-1"
                                    ></Form.Control>
                                  </Col>
                                  <Col className="set-col-button-delete">
                                    <Button type="button" className="button-package-save text-dark" onClick={() => ConfirmDelete()}>
                                      <img src={Delete} className="" /> &nbsp; ลบสินค้า
                                    </Button>
                                  </Col>
                                </Row>

                                <Row className="g-0">
                                  <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                    <p>รหัสสินค้า</p>
                                  </Col>
                                  <Col xs={8} md={8} lg={3} xl={2}>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={Data.code}
                                      required
                                      onChange={(e) => setData({...Data, code: e.target.value})}
                                      className="input-middle-price my-1"
                                    ></Form.Control>
                                  </Col> 
                                    
                                  {
                                    (['บริการฟิล์มกรองแสง', 'บริการเคลือบเหมา','บริการเคลือบเฉพาะจุด', 'บริการฟิล์มกันรอย']).includes(name)
                                    &&
                                    <>
                                      <Col xs={4} sm={4} md={4} lg={3} xl={3} className="text-title">
                                        <p>{(['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ? 'กำหนดการเซ็ตตัว': 'กำหนดพ่นเคลือบหลังติดตั้ง'}</p>
                                      </Col>

                                      <Col xs={8} md={8} lg={4} xl={3}>
                                        <Form.Control
                                          type="text"
                                          placeholder=""
                                          value={Data.setup ? Data.setup : ''}
                                          required
                                          onChange={(e) => setData({...Data, setup: e.target.value})}
                                          className="input-middle-price"
                                        ></Form.Control>
                                      </Col>

                                      <Col className="text-title">
                                        <p>วัน (แจ้งเตือน)</p>
                                      </Col>
                                    </>
                                  }

                                </Row>

                                {
                                  name === 'บริการล้างและเคลือบแว๊กซ์'
                                  &&
                                  <>        
                                    <Row className="g-0">
                                      <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                        <p>ระยะเวลาให้บริการ</p>
                                      </Col>
                                      <Col xs={8} md={8} lg={6} xl={4}>
                                        <Row>
                                          <Col>
                                            <Form.Control
                                              type="number"
                                              placeholder="ใส่ตัวเลข"
                                              value={Data.period}
                                              // required
                                              min={0}
                                              onWheel={(e) => e.target.blur() }
                                              onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                              onChange={(e) => setData({...Data, period: e.target.value})}
                                              className="input-middle-price"
                                            ></Form.Control>
                                          </Col>
                                          <Col>
                                            <Select
                                              placeholder="เดือน"
                                              isClearable={false}
                                              options={PeriodType}
                                              // required
                                              value={Data.period_type ? PeriodType.find(item => item.value === Data.period_type) : null}
                                              onChange={(data) => setData({...Data, period_type: data.value})}
                                              className="select-list"
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row className="g-0 pt-2">
                                      <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                        <p>จำนวนครั้งที่รับบริการ</p>
                                      </Col>
                                      <Col xs={8} md={8} lg={6} xl={4}>
                                        <Form.Control
                                          type="number"
                                          placeholder=""
                                          value={Data.service_time}
                                          required
                                          min={0}
                                          onWheel={(e) => e.target.blur() }
                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                          onChange={(e) => setData({...Data, service_time: e.target.value})}
                                          className="input-middle-price"
                                        ></Form.Control>
                                      </Col>
                                    </Row>
                                  </>
                                }

                              {
                                name === 'Box Set'
                                &&
                                <>
                                  <Row className="g-0 mt-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>จำนวนครั้งรับบริการ</p>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>เคลือบ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.enamel}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, enamel: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 mb-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ล้าง</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </>
                              }

                              {
                                name === 'Interior'
                                &&
                                <>
                                  <Row className="g-0 mt-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>จำนวนครั้งรับบริการ</p>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักเบาะ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_upholstery}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_upholstery: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักสายเข็มขัด</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_belt}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_belt: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ซักแผงประตู</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash_door}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash_door: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>เคลือบภายใน</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.enamel}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, enamel: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 mb-3">
                                    <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                      <p>ล้างรถ</p>
                                    </Col>
                                    <Col xs={8} md={8} lg={6} xl={2}>
                                      <Form.Control
                                        type="number"
                                        placeholder=""
                                        value={Data.wash}
                                        required
                                        min={0}
                                        onWheel={(e) => e.target.blur() }
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                        onChange={(e) => setData({...Data, wash: e.target.value})}
                                        className="input-middle-price my-1"
                                      ></Form.Control>
                                    </Col>
                                  </Row>
                                </>
                              }

                                {
                                  name === 'บริการฟิล์มกันรอย'
                                  &&
                                  <>
                                    <Row className="g-0">
                                      <Col xs={2} sm={3} md={3} lg={2} xl={2} className="text-title">
                                        <p>กำหนดการเรียกเก็บงาน</p>
                                      </Col>
                                      <Col xs={8} md={8} lg={6} xl={4}>
                                        <Row>
                                          <Col>
                                            <Form.Control
                                              type="number"
                                              placeholder=""
                                              value={Data.schedule ? Data.schedule : ''}
                                              required
                                              min={0}
                                              onWheel={(e) => e.target.blur() }
                                              onChange={(e) => setData({...Data, schedule: e.target.value})}
                                              className="input-middle-price"
                                            ></Form.Control>
                                          </Col>
                                          <Col className="text-title">
                                            <p>วัน</p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                }

                                {
                                  (['บริการเคลือบเหมา','บริการเคลือบเฉพาะจุด', 'บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name)
                                  &&
                                  <>        
                                    <Row className="g-0">
                                      <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                        <p>ระยะเวลารับประกัน</p>
                                      </Col>

                                      <Col xs={8} md={8} lg={6} xl={4}>
                                        <Row>
                                          <Col>
                                            <Form.Control
                                              type="number"
                                              placeholder="ใส่ตัวเลข"
                                              value={Data.warranty_period ? Data.warranty_period : ''}
                                              required
                                              min={0}
                                              onWheel={(e) => e.target.blur() }
                                              onChange={(e) => setData({...Data, warranty_period: e.target.value})}
                                              className="input-middle-price"
                                            ></Form.Control>
                                          </Col>
                                          <Col className="text-title">
                                            <p>ปี</p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    {
                                      (['บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name)
                                      &&
                                      <>
                                        <Row className="g-0">
                                          <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                            <p>ราคาเคลมต่ออายุประกัน</p>
                                          </Col>

                                          <Col xs={8} md={8} lg={6} xl={4}>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={Data.period}
                                                  required
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onChange={(e) => setData({...Data, period: e.target.value})}
                                                  className="input-middle-price"
                                                ></Form.Control>
                                              </Col>
                                              <Col className="text-title">
                                                <p>บาท</p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                  </>
                                }

                                <Row className="g-0">
                                  <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                                    <p>อัพโหลดโลโก้</p>
                                  </Col>
                                  <Col xs={12} md={6} lg={5} xl={4} className="">
                                    <Row className='align-items-center justify-content-center pt-2'>
                                    <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={FileName.Logo}
                                        className="distance-field mr-3 my-1"
                                        readOnly
                                      ></Form.Control>
                                      </Col>
                                      <Col > 
                                      <Button type="button" className="button-package-save distance-field my-1 text-dark" onClick={ToggleDocumentFile}>
                                        <img src={uploadImage} /> &nbsp; อัพโหลดโลโก้
                                      </Button>
                                      </Col>
                                    </Row>
                                    {/* hidden file input */}
                                    <Form.Control
                                      type="file"
                                      className="d-none"
                                      id="document-files"
                                      ref={LogoFileRef}
                                      label={Data.Logo}
                                      onChange={(e) => SetLogoFile(e) }
                                    />
                                    {
                                      FileName.Image.map((logo, key) => {
                                        return(
                                          <img src={logo} width="100%"  alt=""  />
                                        )
                                      })
                                    } 
                                  </Col>
                                </Row>
                              {/* </Form.Group> */}

                              {
                                (['บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name) && 
                                <>
                                  <p className="form-title">ทรีตเมนต์</p>
                                  <Row className="g-0">
                                    <Col lg={2} className="text-title">
                                      <p>จำนวน</p>
                                    </Col>

                                    <Col lg={2}>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={Data.treatment_time ? Data.treatment_time : ''}
                                        required
                                        onChange={(e) => setData({...Data, treatment_time: e.target.value})}
                                        className="input-middle-price"
                                      ></Form.Control>
                                    </Col>

                                    <Col className="text-title">
                                      <p>ครั้ง</p>
                                    </Col>
                                  </Row>

                                  <Row className="g-0 mt-2">
                                    <Col lg={2} className="text-title">
                                      <p>ช่วงเข้ารับบริการ</p>
                                    </Col>

                                    <Col lg={2} className='d-flex'>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={Data.range1 ? Data.range1 : ''}
                                        required
                                        onChange={(e) => setData({...Data, range1: e.target.value})}
                                        className="input-middle-price"
                                      ></Form.Control>

                                      <div className="text-title mx-2">
                                        <p>-</p>
                                      </div>
                                      
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={Data.range2 ? Data.range2 : ''}
                                        required
                                        onChange={(e) => setData({...Data, range2: e.target.value})}
                                        className="input-middle-price"
                                      ></Form.Control>
                                    </Col>

                                    <Col className="text-title">
                                      <p>เดือน</p>
                                    </Col>
                                  </Row>
                                </>
                              }
                              
                              {
                                (['แพ็กเกจ']).includes(name) &&
                                <Row className="g-0">
                                  <Col className="d-flex justify-content-start set-position-radio">
                                  <Form.Group className="package-data" controlId="formbasicEmail">
                                    {
                                      ['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        
                                          <Form.Check
                                            inline
                                            label="วัสดุ/อุปกรณ์"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={SelectOption === 'product'}
                                            onChange={(e) => setSelectOption('product')}
                                          />
                                          <Form.Check
                                            inline
                                            label="บริการ"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={SelectOption === 'service'}
                                            onChange={(e) => setSelectOption('service')}
                                          />
                                          <Form.Check
                                            inline
                                            label="สินค้า"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-3`}
                                            checked={SelectOption === 'package'}
                                            onChange={(e) => setSelectOption('package')}
                                          />
                                        </div>
                                      ))
                                    }
                                    </Form.Group>
                                  </Col>
                                  {
                                    SelectOption === 'product' &&
                                    <Col lg={12} className="mt-2 mb-2">
                                      <Select
                                        options={Products}
                                        placeholder="ค้นหาสินค้าด้วยรหัสสินค้า, ชื่อสินค้า"
                                        isClearable={false}
                                        className="select-list"
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={(data) => { InsertSpecial(data, 'สินค้า')}}
                                      />
                                    </Col>
                                  }

                                  {
                                    SelectOption === 'service' &&
                                    <Col lg={12} className="mt-2 mb-2">
                                      <Select
                                        options={Services}
                                        placeholder="ค้นหาสินค้าด้วยรหัสบริการ, ชื่อบริการ"
                                        isClearable={false}
                                        className="select-list"
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={(data) => { InsertSpecial(data, 'บริการ')}}
                                      />
                                    </Col>
                                  }

                                  {
                                    SelectOption === 'package' &&
                                    <Col lg={12} className="mt-2 mb-2">
                                      <Select
                                        options={PackageType}
                                        placeholder="เลือกประเภท"
                                        isClearable={false}
                                        className="select-list"
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={(data) => GetPackage(data.name)}
                                      />
                                      <Select
                                        options={Packages}
                                        placeholder="เลือกสินค้า"
                                        isClearable={false}
                                        className="select-list"
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        value={Package}
                                        onChange={(data) => { InsertSpecial(data, 'แพ็กเกจ')}}
                                      />
                                    </Col>
                                  }

                                  <Col lg={12}>
                                    <table className="table table-responsive table-wash-part ">
                                      <thead>
                                        <tr className="t-header-package">
                                          <th>ชื่อรายการ</th>
                                          <th>จำนวน</th>
                                          <th>เลือกหน่วย</th>
                                          {/* <th>ราคาต่อหน่วย</th>
                                          <th>ยอดรวม</th> */}
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody className="t-body-package ">
                                        { 
                                          Special.Products.map((product, key) => {
                                            return (
                                              <tr key={ `special-${key}` }>
                                                <td>{ `${product.name}` }</td>
                                                <td>
                                                  { product.type !== 'สินค้า' }
                                                  <Form.Control
                                                    type="number"
                                                    placeholder=""
                                                    required
                                                    min={0}
                                                    onWheel={(e) => e.target.blur() }
                                                    disabled={product.type !== 'สินค้า'}
                                                    className="input-middle-price"
                                                    value={product.type !== 'สินค้า' ? null : product.quantity}
                                                    onChange={
                                                      (e) => Changequantity(e.target.value, key)
                                                    }
                                                  ></Form.Control>
                                                </td>
                                                <td>
                                                  {
                                                    product.type === 'สินค้า' &&
                                                    <Select
                                                      options={product.unit_sales}
                                                      placeholder="หน่วยขาย"
                                                      required
                                                      isClearable={false}
                                                      isSearchable={false}
                                                      className={`select-list ` + (!product.product_unit_sale_id && validated ? 'validate-fail' : '') }
                                                      value={product.unit_sale}
                                                      getOptionLabel={option => option.name_sale}
                                                      getOptionValue={option => option.id}
                                                      onChange={
                                                        (data) => SelectUnit(data, key)
                                                      }
                                                    />
                                                  }
                                                </td>
                                                <td>
                                                  <img src={Delete} onClick={() => RemoveSpecial(product)} />
                                                </td>
                                              </tr>
                                            );
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </Col>
                                </Row>
                              }


                              {
                                (['บริการเคลือบเฉพาะจุด']).includes(name) && <p className="form-title">ขนาดรถและราคาเฉพาะจุด</p>
                              }

                              {
                                (['บริการฟิล์มกรองแสง']).includes(name) && <p className="form-title">ตั้งราคา</p>
                              }

                              {
                                (['บริการล้างและเคลือบแว๊กซ์', 'บริการเคลือบเหมา']).includes(name) && <p className="form-title">ขนาดรถและราคา2</p>
                              }

                              {
                                <Form.Group className="package-data" controlId="formBasicEmail">
                                  {
                                    (['แพ็กเกจ']).includes(name) &&
                                    Special.Data.product_times?.map((time, key) => {
                                      return(
                                        <Row>
                                          <Col lg={6}>
                                            <Row>
                                              <Col
                                                xs={4}
                                                sm={3}
                                                md={3}
                                                lg={4}
                                                xl={4}
                                                className="text-title"
                                              >
                                                <p>{Special.Data.all_size ? 'ราคาเหมา' : '' }</p> <Switch checked={Special.Data.all_size} onChange={(checked) => SwitchPrice(checked) } /> {' '}
                                              </Col>
                                              <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={time.price ? time.price : ''}
                                                  required
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                  disabled={!Special.Data.all_size}
                                                  onChange={
                                                    (e) => setSpecial({...Special,
                                                      Data:{
                                                        ...Special.Data,
                                                        product_times: Special.Data.product_times.map(
                                                          el => el.key === key ? { ...el, price: e.target.value }: el
                                                        )
                                                      }
                                                    })
                                                  }
                                                  className="input-search-add-wash-part"
                                                ></Form.Control>
                                              </Col>
                                              <Col xs={1} sm={2} className="text-bath">
                                                <p>บาท</p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col lg={6}>
                                            <Row>
                                              <Col
                                                xs={4}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                                className="text-title"
                                              >
                                                <p>เวลาดำเนินการ</p>
                                              </Col>
                                              <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={time.process_times ? time.process_times : ''}
                                                  required
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                  disabled={!Special.Data.all_size}
                                                  onChange={
                                                    (e) => setSpecial({...Special,
                                                      Data:{
                                                        ...Special.Data,
                                                        product_times: Special.Data.product_times.map(
                                                          el => el.key === key ? { ...el, process_times: e.target.value }: el
                                                        )
                                                      }
                                                    })
                                                  }
                                                  className="input-search-add-wash-part"
                                                ></Form.Control>
                                              </Col>
                                              <Col xs={1} sm={2} className="text-bath">
                                                <p>นาที</p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      )
                                    })
                                  }
                                </Form.Group>
                              }
                              
                              {
                                (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                                <Row className="g-0">
                                  <Col lg={12}>
                                    <div className='package-size-group'>
                                      <div className='package-size-col'>
                                        <p>รุ่น</p>
                                        <Form.Control
                                          type="text"
                                          placeholder=""
                                          value={Model ? Model : ''}
                                          required
                                          onChange={(e) => setModel(e.target.value)}
                                          className="input-middle-price my-1"
                                        ></Form.Control>
                                      </div>

                                      <div className='package-size-col'>
                                        <p>เลขรุ่น</p>
                                        <Form.Control
                                          id="code"
                                          type="text"
                                          placeholder=""
                                          value={Code ? Code : ''}
                                          required
                                          onChange={(e) => setCode(e.target.value)}
                                          className="input-middle-price my-1"
                                        ></Form.Control>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              }

                              <Form.Group className="package-data" controlId="formBasicEmail">
                                {
                                  ProductTimes?.map((time, key) => {
                                    return(
                                      <Row>
                                        <Col lg={12}>
                                          <div className='package-size-group'>
                                            <div className='package-size-col'>
                                              <p>{time?.size}</p>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time?.price ? time.price : ''}
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={Special.Data.all_size}
                                                onChange={
                                                  (e) => setProductTimes(ProductTimes.map(
                                                    el => el.key === key ? { ...el, price: e.target.value }: el
                                                  )) 
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                              <p className='text-end'>บาท</p>
                                            </div>
                                            
                                            <div className='package-size-col'>
                                              <p>เวลาดำเนินการ</p>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                value={time?.process_times ? time?.process_times : ''}
                                                // required
                                                min={0}
                                                onWheel={(e) => e.target.blur() }
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                disabled={Special.Data.all_size}
                                                onChange={
                                                  (e) => setProductTimes(ProductTimes.map(
                                                    el => el.key === key ? { ...el, process_times: e.target.value }: el
                                                  )) 
                                                }
                                                className="input-search-add-wash-part"
                                              ></Form.Control>
                                              <p className='text-end'>นาที</p>
                                            </div>

                                            {
                                              (['บริการเคลือบเหมา', 'บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                                              <div className='package-size-col'>
                                                <p>ราคาทรีทเม้นท์</p>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={time?.treatment_price ? time.treatment_price : ''}
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                  // disabled={Special.Data.all_size}
                                                  onChange={
                                                    (e) => setProductTimes(ProductTimes.map(
                                                      el => el.key === key ? { ...el, treatment_price: e.target.value }: el
                                                    )) 
                                                  }
                                                  className="input-search-add-wash-part"
                                                ></Form.Control>
                                                <p className='text-end'>บาท</p>
                                              </div>
                                            }                  
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                              </Form.Group>

                            {
                              (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                              <Row className="g-0">
                                <Col xs={0} sm={3} md={3} lg={2} xl={2} className="text-title">
                                </Col>
                                <Col
                                  xs={12}
                                  sm={4}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  className="set-row-group-input"
                                >
                                  {
                                    ProductTimeEditMode 
                                    ?
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      style={{ margin: 0 }}
                                      onClick={ () => UpdateProductTimeState()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                                    </Button>
                                    :
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      style={{ margin: 0 }}
                                      onClick={ () => InsertProductTime()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                    </Button>
                                  }
                                </Col>
                              </Row>
                            }

                            {
                              (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                              Data.model_list?.map((model, index) => {
                                return(
                                  <Row className="g-0">
                                    <Col>
                                      <Table className="table table-responsive table-wash-part ">
                                        <thead>
                                          <tr className="t-header-package">
                                            <th>รุ่น</th>
                                            <th>เลขรุ่น</th>
                                            <th>S</th>
                                            <th>M</th>
                                            <th>L</th>
                                            <th>TRUCK CAB</th>
                                            <th>SUV</th>
                                            <th>TRUCK 4 DOOR </th>
                                            <th>XL</th>
                                            <th>VAN</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody className="t-body-package">
                                          {
                                            model.data?.map((product, key) => {
                                              // console.log(product)
                                              return(
                                                <tr key={`column-${key}`}>
                                                  <td>
                                                    { !key ? model.model : ''}
                                                  </td>
                                                  <td>{product[key].code}</td>
                                                  <td>{DisplayPrice('S', product)}</td>
                                                  <td>{DisplayPrice('M', product)}</td>
                                                  <td>{DisplayPrice('L', product)}</td>
                                                  <td>{DisplayPrice('TRUCK CAB', product)}</td>
                                                  <td>{DisplayPrice('SUV', product)}</td>
                                                  <td>{DisplayPrice('TRUCK 4 DOOR', product)}</td>
                                                  <td>{DisplayPrice('XL', product)}</td>
                                                  <td>{DisplayPrice('VAN', product)}</td>
                                                  <td>
                                                    <img src={Increase} onClick={() => EditProductTime(product)}/> 
                                                    <img src={Delete} onClick={() => DeleteProductB2B(product)}/>
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                )
                              })
                            }

                            { 
                              !(['แพ็กเกจ']).includes(name) &&
                              <>
                                <p ref={productRef} className="form-title">วัสดุ/อุปกรณ์อ้างอิง</p>
                                <Row>
                                  <Col lg={6}>
                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          isMulti
                                          placeholder="เลือก"
                                          options={useType}
                                          value={usedType}
                                          onChange={(data) => setUsedType(data)}
                                          required={!validated || ValidatedProduct}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                        />
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          options={Products}
                                          placeholder="ค้นหาสต๊อก"
                                          isClearable={true}
                                          isSearchable={true}
                                          isDisabled={DisableProduct}
                                          required={!validated || ValidatedProduct}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                          getOptionLabel={option => option.name}
                                          getOptionValue={option => option.id}
                                          value={ProductId ? Products.find((item) => item.id === ProductId) : null }
                                          onChange={(data) => {console.log(data); setProductId(data?.id ? data.id : null); setUnitSales(data?.unit_sales ? data.unit_sales : null)}}
                                        />
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col xs={8} className="mt-2 mb-2">
                                        <Select
                                          options={UnitSales}
                                          placeholder="เลือกหน่วยขาย"
                                          isClearable={true}
                                          isSearchable={true}
                                          required={!validated || ValidatedProduct}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                          getOptionLabel={option => option.name_sale}
                                          getOptionValue={option => option.id}
                                          value={UnitSaleId ? UnitSales.find((item) => item.id === UnitSaleId) : null }
                                          isDisabled={!ProductId || UnitSales && UnitSales.length === 0 }
                                          onChange={(data) => setUnitSaleId(data?.id ? data.id : null)}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                              {
                                ProductSizes?.map((product, key) => { 
                                  return(
                                    <Row className="g-0">
                                      <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                                        <p>{product.size}</p>
                                      </Col>
                                      <Col xs={9} sm={4} md={4} lg={3} xl={2} className="input-stock">
                                        <Form.Control
                                          type="number"
                                          name={product.size}
                                          placeholder=""
                                          className="input-search-add-wash-part"
                                          value={product.quantity ? product.quantity : ''}
                                          required={!validated || ValidatedProduct}
                                          min={0}
                                          onWheel={(e) => e.target.blur() }
                                          isInvalid={!product.quantity && ValidatedProduct}
                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                          onChange={
                                            (e) => setProductSizes(ProductSizes.map(
                                              el => el.key === key ? { ...el, quantity: e.target.value }: el
                                            )) 
                                          }
                                        ></Form.Control>
                                      </Col>
                                    </Row>
                                  )
                                })
                              }

                                <Row className="g-0">
                                <Col xs={0} sm={3} md={3} lg={2} xl={2} className="text-title">
                                </Col>
                                <Col
                                  xs={12}
                                  sm={4}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  className="set-row-group-input"
                                >
                                  {
                                    ProductEditMode 
                                    ?
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      // style={{ margin: 0 }}
                                      onClick={ () => UpdateProductState()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                                    </Button>
                                    :
                                    <Button
                                      type="button"
                                      className="button-package-save mt-4 mb-4"
                                      // style={{ margin: 0 }}
                                      onClick={ () => InsertProductSize()}
                                    >
                                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                    </Button>
                                  }
                                </Col>
                                </Row>

                                <Row className="g-0" style={{ overflow: 'scroll', height: '200px' }}>
                                  <Col>
                                    <Table className="table table-responsive table-wash-part ">
                                      <thead>
                                        {
                                          !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                            <tr className="t-header-package">
                                              <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                                              <th>ประเภทงาน</th>
                                              <th>S</th>
                                              <th>M</th>
                                              <th>L</th>
                                              <th>TRUCK CAB</th>
                                              <th>SUV</th>
                                              <th>TRUCK 4 DOOR </th>
                                              <th>XL</th>
                                              <th>VAN</th>
                                              <th></th>
                                              <th></th>
                                            </tr>
                                          :
                                          <tr className="t-header-package">
                                            <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                                            <th>ประเภทงาน</th>
                                            <th>กระโปรงหน้า</th>
                                            <th>กระโปรงหลัง</th>
                                            <th>ประตูหน้า</th>
                                            <th>ประตูหลัง</th>
                                            <th>กันชน</th>
                                            <th>หลังคา</th>
                                            <th>แก้ม</th>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                        }
                                      </thead>
                                      <tbody className="t-body-package">
                                        {
                                          !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                          Data.product_list?.map((product, key) => {
                                            return(
                                              <tr key={`column-${key}`}>
                                                <td>{product.product.name}</td>
                                                <td>
                                                    {
                                                      product.usedType?.map((type, key) => {
                                                        return(
                                                          <>
                                                            { type.value }
                                                            { key + 1 < product.usedType.length && ',' }
                                                          </>
                                                        )
                                                      })
                                                    }
                                                </td>
                                                <td>{product.S}</td>
                                                <td>{product.M}</td>
                                                <td>{product.L}</td>
                                                <td>{product['TRUCK CAB']}</td>
                                                <td>{product['SUV']}</td>
                                                <td>{product['TRUCK 4 DOOR']}</td>
                                                <td>{product['XL']}</td>
                                                <td>{product['VAN']}</td>
                                                <td><img src={Increase} onClick={() => EditProduct(product, key)} /></td>
                                                <td>                                    
                                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>.
                                                </td>
                                              </tr>
                                            )
                                          })
                                          :
                                          Data.product_list?.map((product, key) => {
                                            return(
                                              <tr key={`column-${key}`}>
                                                <td>{product.product.name}</td>
                                                <td>
                                                    {
                                                      product.usedType?.map((type, key) => {
                                                        return(
                                                          <>
                                                            { type.value }
                                                            { key + 1 < product.usedType.length && ',' }
                                                          </>
                                                        )
                                                      })
                                                    }
                                                </td>
                                                <td>{product['กระโปรงหน้า']}</td>
                                                <td>{product['กระโปรงหลัง']}</td>
                                                <td>{product['ประตูหน้า']}</td>
                                                <td>{product['ประตูหลัง']}</td>
                                                <td>{product['กันชน']}</td>
                                                <td>{product['หลังคา']}</td>
                                                <td>{product['แก้ม']}</td>
                                                <td><img src={Increase} onClick={() => EditProduct(product, key)} /></td>
                                                <td>                                    
                                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>.
                                                </td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </>
                            }

                            <Row>
                              <Col className="colum-open-service">
                                <p>{Data.active ? 'เปิด' : 'ปิด' } ให้บริการ</p> <Switch checked={Data.active} onChange={(checked) => setData({...Data, active: checked})} /> {' '}
                              </Col>
                            </Row>

                            <Row className="g-0 d-flex justify-content-center row-delete-mobile">
                              <Button type="button" className="button-package-save" onClick={() => ConfirmDelete()}>
                                <img src={Delete} className="" /> &nbsp; ลบสินค้า
                              </Button>
                            </Row>

                            <Row className="g-0 mt-5 mb-5">
                              <Col className="d-flex justify-content-center">
                                <Button type="submit" className="button-package-save">
                                  <img src={Save} /> &nbsp; &nbsp; บันทึก
                                </Button>
                                <Button type="button" className="button-package-save" onClick={exit}>
                                  <img src={Turnoff} className="" /> &nbsp; &nbsp; ออก
                                </Button>
                              </Col>
                            </Row>

                          </Container>
                        </Form>
                      </Tab>
                      
                      {
                        !(['แพ็กเกจ']).includes(name) &&
                        <Tab eventKey="b2b" title="ราคา B2B">
                          <Container
                            fluid
                            className="package-container Service Stock User set-heght"
                          >
                            <Form noValidate validated={validatedB2B} onSubmit={EditB2BPrice}>
                              <p className="form-title">ข้อมูลสินค้า</p>
                              <Form.Group className="package-data" controlId="formBasicEmail">
                                <Row className="g-0 align-items-center">
                                  <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                                    <p>โชว์รูม</p>
                                  </Col>
                                  <Col className="mt-2 mb-2">
                                    <Select
                                      options={Customers}
                                      placeholder=""
                                      isClearable={false}
                                      getOptionLabel={option => option.organization}
                                      getOptionValue={option => option.id}
                                      value={B2BData.customer_id ? Customers.find((item) => item.id === B2BData.customer_id) : null }
                                      onChange={(data) => setB2BData({...B2BData, customer_id: data.id})}
                                      className="select-list"
                                    />
                                  </Col>
                                  <Col className="set-col-button-delete justify-content-center">
                                    <Button type="button" className="delete-package text-dark" onClick={() => ConfirmDeleteB2B()}>
                                      <img src={Delete} className="" /> &nbsp; ลบสินค้า
                                    </Button>
                                  </Col>
                                </Row>
                              </Form.Group>

                              {
                                !['บริการล้างและเคลือบแว๊กซ์'].includes(name) &&
                                <Form.Group className="package-data" controlId="formBasicEmail">
                                <Row className="g-0">
                                  <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                                    <p>ราคาเคลม</p>
                                  </Col>
                                  <Col xs={7} sm={4} md={4} lg={3} xl={2}>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      value={B2BData.claim_price ? B2BData.claim_price : ''}
                                      onChange={(e) => setB2BData({...B2BData, claim_price: e.target.value})}
                                      className="input-middle-price"
                                    ></Form.Control>
                                  </Col>
                                  <Col xs={2} className="text-bath">
                                    <p>บาท</p>
                                  </Col>
                                </Row>
                              </Form.Group>
                              }

                              <p className="form-title">ขนาดรถและราคา</p>

                              {
                                (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) &&
                                <Row className="g-0">
                                  <Col lg={12}>
                                    <div className='package-size-group'>
                                      <div className='package-size-col mb-2'>
                                        <p>รุ่น</p>
                                        <Select
                                          options={dataModelOption}
                                          placeholder="เลือกรุ่น"
                                          isSearchable={true}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                          getOptionLabel={option => option.model}
                                          getOptionValue={option => option.model}
                                          value={ProductModel ? dataModelOption.find((item, key) => item.model === ProductModel) : null }
                                          onChange={(data) => ProductModelSelect(data)}
                                        />
                                      </div>

                                      <div className='package-size-col mb-2'>
                                        <p>เลขรุ่น</p>
                                        <Select
                                          options={DataProductCode}
                                          placeholder="เลือกเลขรุ่น"
                                          isSearchable={true}
                                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                                          getOptionLabel={option => option.label}
                                          getOptionValue={option => option.value}
                                          value={ProductCode ? DataProductCode.find((item) => item.value === ProductCode) : null }
                                          onChange={(data) => setProductCode(data ? data.value : null)}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              }

                              <Form.Group className="package-data " controlId="formBasicEmail">
                                {
                                  !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                      B2BPrices?.map((b2b, key) => {
                                        return(
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col
                                                  xs={4}
                                                  sm={3}
                                                  md={3}
                                                  lg={4}
                                                  xl={4}
                                                  className="text-title"
                                                >
                                                  <p>{b2b.size}</p>
                                                </Col>
                                                <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                                  <Form.Control
                                                    type="number"
                                                    placeholder=""
                                                    value={b2b.price ? b2b.price : ''}
                                                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                    min={0}
                                                    onWheel={(e) => e.target.blur() }
                                                    onChange={
                                                      (e) => setB2BPrices(B2BPrices.map(
                                                        el => el.key === key ? { ...el, price: e.target.value }: el
                                                      )) 
                                                    }
                                                    className="input-search-add-wash-part"
                                                  ></Form.Control>
                                                </Col>
                                                <Col xs={1} sm={2} className="text-bath">
                                                  <p>บาท</p>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )
                                      })
                                  :
                                    B2BPricePartsModels?.map((b2b, key) => {
                                      return(
                                        <Row>
                                          <Col lg={6}>
                                            <Row>
                                              <Col
                                                xs={4}
                                                sm={3}
                                                md={3}
                                                lg={4}
                                                xl={4} 
                                                className="text-title"
                                              >
                                                <p>{b2b.size}</p>
                                              </Col>
                                              <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  value={b2b.price ? b2b.price : ''}
                                                  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                  min={0}
                                                  onWheel={(e) => e.target.blur() }
                                                  onChange={
                                                    (e) => setB2BPricePartsModels(B2BPricePartsModels.map(
                                                      el => el.key === key ? { ...el, price: e.target.value }: el
                                                    )) 
                                                  }
                                                  className="input-search-add-wash-part"
                                                ></Form.Control>
                                              </Col>
                                              <Col xs={1} sm={2} className="text-bath">
                                                <p>บาท</p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      )
                                    })
                                    
                                }
                              </Form.Group>

                              <Row className="g-0">
                                <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title"></Col>
                                <Col
                                  xs={7}
                                  sm={4}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  className="set-row-group-input"
                                >
                                  {
                                    B2BPriceEditMode 
                                    ?
                                      (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ?
                                        <Button
                                          type="button"
                                          className="button-package-save mt-4 mb-4"
                                          style={{ margin: 0 }}
                                          onClick={ () => UpdateB2BProductTimeState()}
                                        >
                                          <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                                        </Button>
                                      :
                                        <Button
                                          type="button"
                                          className="button-package-save mt-4 mb-4"
                                          style={{ margin: 0 }}
                                          onClick={ () => UpdateB2BPriceState()}
                                        >
                                          <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                                        </Button>
                                    :
                                      !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                        
                                        (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ?
                                          <Button
                                            type="button"
                                            className="button-package-save mt-4 mb-4"
                                            style={{ margin: 0 }}
                                            onClick={ () => B2bInsertProductTime()}
                                          >
                                            <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                          </Button>
                                        :
                                          <Button
                                            type="button"
                                            className="button-package-save mt-3 mb-3"
                                            style={{ margin: 0 }}
                                            onClick={ () => InsertB2BPrice() }
                                          >
                                            <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                          </Button>
                                      :
                                        <Button
                                          type="button"
                                          className="button-package-save mt-3 mb-3"
                                          style={{ margin: 0 }}
                                          onClick={ () => InsertB2BPricePartsModel() }
                                        >
                                          <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                                        </Button>
                                  }
                                </Col>
                                <Col xs={2} className="text-bath"></Col>
                              </Row>
                              {

                              !(['บริการเคลือบเฉพาะจุด']).includes(name) ?
                                // (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ?
                                //     <Row className="g-0">
                                //       <Col>
                                //         <Table className="table table-responsive table-wash-part ">
                                //           <thead>
                                //             <tr className="t-header-package">
                                //               <th style={{ width: '15%' }}>โชว์รูม1</th>
                                //               <th>รุ่น</th>
                                //               <th>เลขรุ่น</th>
                                //               <th>S</th>
                                //               <th>M</th>
                                //               <th>L</th>
                                //               <th>TRUCK CAB</th>
                                //               <th>SUV</th>
                                //               <th>TRUCK 4 DOOR </th>
                                //               <th>XL</th>
                                //               <th>VAN</th>
                                //               <th></th>
                                //             </tr>
                                //           </thead>
                                //           <tbody className="t-body-package">
                                //             {
                                //               B2BData.b2b_price_list?.map((b2b, key) => {
                                //                 // console.log(b2b)
                                //                 return(
                                //                   <tr key={`column-${key}`}>
                                //                     <td>{ b2b?.showroom?.organization }</td>
                                //                     {/* <td>
                                //                       { !key ? model.model : ''}
                                //                     </td>
                                //                     <td>{product[key].code}</td> */}
                                //                     <td>{b2b.S}</td>
                                //                     <td>{b2b.M}</td>
                                //                     <td>{b2b.L}</td>
                                //                     <td>{b2b['TRUCK CAB']}</td>
                                //                     <td>{b2b['SUV']}</td>
                                //                     <td>{b2b['TRUCK 4 DOOR']}</td>
                                //                     <td>{b2b['XL']}</td>
                                //                     <td>{b2b['VAN']}</td>
                                //                     <td>
                                //                       <img src={Increase} onClick={() => EditProductTime(b2b)}/>
                                //                     </td>
                                //                   </tr>
                                //                 )
                                //               })
                                //             }
                                //           </tbody>
                                //         </Table>
                                //       </Col>
                                //     </Row>
                                //     :
                                (['บริการฟิล์มกรองแสง', 'บริการฟิล์มกันรอย']).includes(name) ?
                                  B2BData.b2b_model_list?.map((model, index) => {
                                    // console.log('B2B Model List',B2BData.b2b_model_list)
                                    return(
                                      <Row className="g-0">
                                        <Col>
                                          <Table className="table table-responsive table-wash-part ">
                                            <thead>
                                              <tr className="t-header-package">
                                                <th style={{ width: '15%' }}>โชว์รูม</th>
                                                <th>รุ่น</th>
                                                <th>เลขรุ่น</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>TRUCK CAB</th>
                                                <th>SUV</th>
                                                <th>TRUCK 4 DOOR </th>
                                                <th>XL</th>
                                                <th>VAN</th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody className="t-body-package">
                                              {
                                                model.data?.map((product, key) => {
                                                  console.log(product[key])
                                                  return(
                                                    <tr key={`b2b-prices-${key}`}>
                                                      <td>{ DisplayShowroom(product) }</td>
                                                      <td>
                                                        { !key ? model.model : ''}
                                                      </td>
                                                      <td>{DisplayCode(product)}</td>
                                                      <td>{DisplayPrice('S', product)}</td>
                                                      <td>{DisplayPrice('M', product)}</td>
                                                      <td>{DisplayPrice('L', product)}</td>
                                                      <td>{DisplayPrice('TRUCK CAB', product)}</td>
                                                      <td>{DisplayPrice('SUV', product)}</td>
                                                      <td>{DisplayPrice('TRUCK 4 DOOR', product)}</td>
                                                      <td>{DisplayPrice('XL', product)}</td>
                                                      <td>{DisplayPrice('VAN', product)}</td>
                                                      <td>
                                                        <img src={Increase} onClick={() => B2bEditProductTime(product)}/> 
                                                        <img src={Delete} onClick={() => DeleteProductB2B(product)}/>
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                              }
                                            </tbody>
                                          </Table>
                                        </Col>
                                      </Row>
                                    )
                                })
                                :

                                      <Row
                                        className="g-0 set-table-width"
                                        style={{ overflow: 'scroll', height: '200px' }}
                                      >
                                        <table className="table table-responsive table-wash-part ">
                                          <thead>
                                            <tr className="t-header-package">
                                              <th style={{ width: '15%' }}>โชว์รูม</th>
                                              <th>S</th>
                                              <th>M</th>
                                              <th>L</th>
                                              <th>TRUCK CAB</th>
                                              <th>SUV</th>
                                              <th>TRUCK 4 DOOR </th>
                                              <th>XL</th>
                                              <th>VAN</th>
                                              <th>แก้ไข</th>
                                              <th>ลบ</th>
                                            </tr>
                                          </thead>
                                          
                                          <tbody className="t-body-package ">
                                            {
                                              B2BData.b2b_price_list?.map((b2b, key) => {
                                                return(
                                                  <tr key={`b2b-prices-${key}`}>
                                                    <td>{ b2b?.showroom?.organization }</td>
                                                    <td>{b2b.S}</td>
                                                    <td>{b2b.M}</td>
                                                    <td>{b2b.L}</td>
                                                    <td>{b2b['TRUCK CAB']}</td>
                                                    <td>{b2b['SUV']}</td>
                                                    <td>{b2b['TRUCK 4 DOOR']}</td>
                                                    <td>{b2b['XL']}</td>
                                                    <td>{b2b['VAN']}</td>
                                                    <td>
                                                      <Button type="button" className="button-package" onClick={ () => EditB2BPriceState(b2b)}>
                                                        <img src={WriteEdit} />
                                                      </Button>
                                                    </td>
                                                    <td>
                                                      <Button type="button" className="button-package" onClick={ () => DeleteB2BPriceState(b2b)}>
                                                        <img src={DeleteWash} />
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            }
                                          </tbody>
                                        </table>
                                      </Row>
                              :
                                <Row
                                  className="g-0 set-table-width"
                                  style={{ overflow: 'scroll', height: '200px' }}
                                >
                                  <table className="table table-responsive table-wash-part ">
                                    <thead>
                                      <tr className="t-header-package">
                                        <th style={{ width: '15%' }}>โชว์รูม</th>
                                        <th>กระโปรงหน้า</th>
                                        <th>กระโปรงหลัง</th>
                                        <th>ประตูหน้า</th>
                                        <th>ประตูหลัง</th>
                                        <th>กันชน</th>
                                        <th>หลังคา</th>
                                        <th>แก้ม</th>
                                        <th>แก้ไข</th>
                                        <th>ลบ</th>
                                      </tr>
                                    </thead>
                                    
                                    <tbody className="t-body-package ">
                                      {
                                        B2BData.b2b_price_list?.map((b2b, key) => {
                                          console.log(B2BData.b2b_price_list)
                                          return(
                                            <tr key={`b2b-prices-${key}`}>
                                              <td>{ b2b?.showroom?.organization }</td>
                                              <td>{b2b['กระโปรงหน้า']}</td>
                                              <td>{b2b['กระโปรงหลัง']}</td>
                                              <td>{b2b['ประตูหน้า']}</td>
                                              <td>{b2b['ประตูหลัง']}</td>
                                              <td>{b2b['กันชน']}</td>
                                              <td>{b2b['หลังคา']}</td>
                                              <td>{b2b['แก้ม']}</td>
                                              <td>
                                                <Button type="button" className="button-package" onClick={ () => EditB2BPriceState(b2b)}>
                                                  <img src={WriteEdit} />
                                                </Button>
                                              </td>
                                              <td>
                                                <Button type="button" className="button-package" onClick={ () => DeleteB2BPriceState(b2b)}>
                                                  <img src={DeleteWash} />
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </Row>
                              }

                              <Row>
                                <Col className="d-flex mt-5 colum-open-service">
                                  <p>{B2BData.active_b2b ? 'เปิด' : 'ปิด' } ให้บริการ</p> <Switch checked={B2BData.active_b2b} onChange={(checked) => setB2BData({...B2BData, active_b2b: checked}) } /> {' '}
                                </Col>
                              </Row>
                              
                              <Row className="g-0 d-flex justify-content-center row-delete-mobile">
                                <Button type="button" className="button-package-save" onClick={() => ConfirmDeleteB2B()}>
                                  <img src={Delete} className="" /> &nbsp; ลบสินค้า
                                </Button>
                              </Row>

                              <Row className="g-0 mt-5 mb-5">
                                <Col className="d-flex justify-content-center">
                                  <Button type="submit" className="button-package-save">
                                    <img src={Save} className="" /> &nbsp; &nbsp; บันทึก
                                  </Button>
                                  <Button type="button" className="button-package-save" onClick={exit}>
                                    <img src={Turnoff} className="" /> &nbsp; &nbsp; ออก
                                  </Button>
                                </Col>
                              </Row>

                            </Form>
                          </Container>
                        </Tab>
                      }
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />

      <Modal
        className="modal-style"
        centered
        show={modalConfirmDelete}
        onHide={ () => setModalConfirmDelete(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบสินค้า { Data.name }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setModalConfirmDelete(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยินยันการลบสินค้า { Data.name }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeletePackage() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setModalConfirmDelete(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={modalConfirmDeleteB2B}
        onHide={ () => setModalConfirmDeleteB2B(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการข้อมูล { Data.name }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setModalConfirmDeleteB2B(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการข้อมูล B2B ของ { Data.name }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeletePackageB2B() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setModalConfirmDeleteB2B(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}