import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab
} from 'react-bootstrap';

import Footer from 'Components/Footer';

import { useHistory , Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import Service from 'assets/icon/management.png';
import AddData from './Components/AddData';

export default function Index() {
  const history = useHistory();
  const [key, setKey] = useState('Adddata');
  
  function close() {
    history.push(`/staff`);
  }
  return (
    <>
      
      <Container fluid className="package-container Stocks Service Staff bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to="/staff" className='link-pagination'>ครอบครัว(MRKfam) </Link> &gt; เพิ่มครอบครัว(MRKfam)</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to="/staff" className='link-pagination'>พนักงาน &gt; รายชื่อพนักงาน</Link> &gt; เพิ่มพนักงาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-start ">
                  <TabsIcon />
                </Row>
                <Row className="g-0 justify-content-center Staff-tabs">
                  <Col className="justify-content-start  ">
                    <div className="box-typecar ">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-4"
                      >
                        <Tab eventKey="Adddata" title="ข้อมูลส่วนตัว">
                          <Row className="Data-height justify-content-center">
                            <AddData />
                          </Row>
                        </Tab>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3"></Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
