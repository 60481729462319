import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { FindPackageType } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';


export default function CoatingService(props) {

  const dispatch = useDispatch()
  const history = useHistory();
  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  
  const [Data,setData] =useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);

  const [serviceMore, setServiceMore] = useState(false);
  const [fastService, setFastService] = useState(false);
  const [sprayCoating, setSprayCoating] = useState(false);
  const [renewalClaim, setRenewalClaim] = useState(false);
  const [service, setService] = useState([{ value: '', label: '' }]);

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleSprayCoating = () => {
    setSprayCoating(!sprayCoating);
  };
  const handleRenewalClaim = () => {
    setRenewalClaim(!renewalClaim);
  };
  const handleFastService = () => {
    setFastService(!fastService);
  };

  const StorageModel = {
    PackageId: '',
    PackageCode: '',
    PackageName: '',
    PackageTypeId: '',
    PackageTypeName: '',
    form: 'package',
    Status: 'new',
    Quantity: '',
    sprayCoating: '',
    renewalClaim: '',
    ServiceNow: '',
  }
  function InsertPackage(){
    if(service.data && service.data.id){
      // console.log('service.data.category',service.data);
      let insert = StorageModel
      insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
      insert.PackageId = service.data.id
      insert.PackageCode = service.data.code
      insert.PackageName = service.data.name
      insert.PackageTypeId = props.data.id 
      insert.PackageTypeName = props.data.name
      insert.ServiceNow = fastService
      insert.sprayCoating = sprayCoating
      insert.renewalClaim = renewalClaim
      const CarSize = CarOwner.car_serie.size
      const MiddleSize = service.data.middle_sizes
      const resultCarSize = MiddleSize.find((item) => {
        return item.size === CarSize
      })
      if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
        insert.Price = MiddleSize[0].price
      }else{
        insert.Price = resultCarSize.price ? resultCarSize.price : 0 
      }
      insert.FilmThickness = null
      insert.TypeWork = null
      insert.PackageType = service.data.category
      insert.TextDetails = null
      insert.PositionUse = null

      // for Appointment
      insert.CleanService = null
      insert.Treatment = null
      insert.TreatmentTime = service.data.treatment_time
      insert.TreatmentRanges = service.data.treatment_ranges
      insert.Setup = service.data.setup
      insert.Schedule = service.data.schedule
      insert.PackageTypeId = service.data.package_type_id
      setData({...Data, PackageStorageList:[...Data.PackageStorageList, insert], PackageTypeId: '', PackageId: '',})

      // //for remove select form select list
      // const result = Packages.filter((item)=> {
      //   return item.id !== service.data.id
      // });
      // setPackages(result);

      //clear 
      setService({ value: '', label: ''})
      setFastService(false)

      // // console.log('Data',Data);
      const package_list = Data.PackageStorageList
      package_list.push(insert)

      parseDataJob.PackageList = package_list;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

    }else{
      alert('เลือกรายการ')
    }
  }
  function SetService(data) {
    // console.log('SetService',data);
    setService({ value: data.id, label: data.name, data });
  }

  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'Quantity':
        setData(prevState => ({...Data,
          PackageStorageList: prevState.PackageStorageList.map(
            el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
          )}))
    
        item.Quantity = Number(data.target.value)
        item.Treatment = item.TreatmentTime * item.Quantity
        break;
      case 'note':
        setData({...Data, note: data.target.value })
        Data.note = data.target.value
        break;
      case 'insite_note':
        setData({...Data, insite_note: data.target.value })
        Data.insite_note = data.target.value
        break;
      // case 'service_now':
      //     setData({...Data, service_now: data })
      //     Data.service_now = data
      //     break;
      case 'things_in_car':
          setData({...Data, things_in_car: data })
          Data.things_in_car = data
          break;
        case 'defect_car':
          setData({...Data, defect_car: data })
          Data.defect_car = data
          break;
        case 'area':
          setData({...Data, area: data.target.value })
          Data.area = data.target.value
          break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = result;
    parseDataJob.note = Data.note
    parseDataJob.insite_note = Data.insite_note
    parseDataJob.area = Data.area
    parseDataJob.things_in_car = Data.things_in_car
    parseDataJob.defect_car = Data.defect_car
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.package_id
    })
    Packages.push(result);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('item',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      console.log(' item?.PointCoatings', item?.PointCoatings);
      if(item?.PointCoatings){
        const result = item?.PointCoatings.map((item) => {
          return item.size 
        }).join(", ")
    
        return result ? 'บริเวณ :' + result : ''
      }else{
        return ''
      }
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[PackageTypeId])
  return (
    <>
      <Form>
        <Container fluid className="pl-3 pr-3">
          <Row className="g-0 justify-content-start">
            {/* <Col xs={8} sm={5} md={4} lg={3} xl={3} className="mt-4 mb-4 p-0">
              <Form.Check
                type="checkbox"
                label="พ่นเคลือบหลังติดตั้ง"
                onChange={handleSprayCoating}
                checked={sprayCoating}
                className="checkbox-input"
              />
            </Col> */}
            {/* <Col xs={8} sm={5} md={4} lg={3} xl={3} className="mt-4 mb-4 p-0">
              <Form.Check
                type="checkbox"
                label="เคลมต่ออายุประกัน"
                onChange={handleRenewalClaim}
                checked={renewalClaim}
                className="checkbox-input"
              />
            </Col> */}
            {/* <Col xs={8} sm={5} md={4} lg={3} xl={3} className="mt-4 mb-4 p-0">
              <Form.Check
                type="checkbox"
                label="บริการต่อเนื่อง"
                onChange={handleServiceMore}
                checked={serviceMore}
                className="checkbox-input"
              />
            </Col> */}
            <Col
              xs={8}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mt-4 mb-4 p-0 set-position-service "
            >
              <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={fastService}
                className="checkbox-input mt-3"
              />
            </Col>
          </Row>
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <Select
                    options={Packages}
                    getOptionValue={(option)=> option.id}
                    getOptionLabel={(option) => option.name}
                    // value={Data.PackageId ? Packages?.find((data) => {return data.PackageId === Data.PackageId}) : '' }
                    placeholder="เลือกประเภทการเคลือบ"
                    isClearable={false}
                    isSearchable={false}
                    onChange={(data) => SetService(data)}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-end">
                <Col lg={6} xl={5}>
                  {/* <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={fastService}
                      className="checkbox-input"
                    />
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
          
          <Row>
            <Col xs={12}>
              <Row>
                <Col lg={3} xl={2} className="mt-3 mb-3">
                <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                    เพิ่ม
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className=" p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr key={item.id}>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td>{item.PackageName}
                          <p>
                            { getTextByPackage(item)}
                            { getDisplayTextPositionCoating(item) }
                            { getDisplayTextPositionUse(item)}
                            { item.TextDetails }
                          </p>
                          </td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            type="number"
                            disabled={item.Status === 'appointment'}
                            required
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type="" className="button-package"
                             disabled={item.Status === 'appointment'}
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}
