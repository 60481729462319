// Tools
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Icon
import Back from 'assets/icon/back.png';
import SearchIcon from 'assets/icon/search.png';
import Edit from 'assets/icon/key-fix.png';
import Add from 'assets/icon/add_menu.png';
import Setting from 'assets/icon/setting.png';
import Delete from 'assets/icon/delete.png';
import Return from 'assets/icon/return-value.png'
// Custome Component
import Footer from 'Components/Footer';
import TabsIconSetBrandCar from 'Components/layout/TabsIconSetBrandCar';
import MyPagination from 'Components/MyPagination';

// API
import {
  CarBrandList,
  CreateCarBrand,
  UpdateCarBrand,
  DestroyCarBrand,
} from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { CarTypeList } from 'actions/settings/car_type';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ClearSearch, setClearSearch] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [Search, setSearch] = useState({
    CarBrandId: null,
    CarModelId: null,
    CarTypeId: null,
    PerPage: 10,
    CurrentPage: 1,
  });
  const [Rows, setRows] = useState(0);
  const [CarBrands, setCarBrands] = useState([]);
  const [Brands, setBrands] = useState([]);
  const [Type, setType] = useState([]);
  const [Models, setModels] = useState([]);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const GetCarBrand = async (data) => {
    setisLoading(true);
    const params = {
      car_brand_id: Search.CarBrandId,
      car_model_id: Search.CarModelId,
      car_type_id: Search.CarTypeId,
      limit: Search.PerPage,
      page: Search.CurrentPage,
    };
    try {
      const response = await dispatch(CarBrandList(params));
     
      if (response.data.data && response.data.data.data) {
        setCarBrands(response.data.data.data);
        setRows(response.data.data.last_page);
        setisLoading(false);
      } else {
        setCarBrands(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setBrands(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };
  
  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id,
    };

    try {
      const response = await dispatch(CarModelList(params));
      setModels(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const GetCarType = async (data) => {
    try {
      const response = await dispatch(CarTypeList());
      setType(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };
  const deleteCarBrand = async (e, id) => {
    e.preventDefault();

    try {
      const response = await dispatch(DestroyCarBrand(id));

      if (response) {
        GetCarBrand();
      } else {
        console.log('failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const SearchCarBrand = {
    CarBrandId: null,
    CarModelId: null,
    CarTypeId: null,
    PerPage: 10,
    CurrentPage: 1,
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'view'})
    if (!ClearSearch) GetCarBrand();
     setClearSearch(false)
   }, [Search.PerPage, Search.CurrentPage, ClearSearch]);

  const ResetSearch = async () => {
    setSearch(SearchCarBrand)
    setClearSearch(true)
    
    
  }
 

  useEffect(() => {
    GetBrand();
    GetCarType();
  }, []);

  function ChangeModel(data) {
    setSearch({ ...Search, CarModelId: null, CarBrandId: data.id });
    GetCarModel(data.id);
  }

  function close() {
    let path = `/setting`;
    history.push(path);
  }
  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ยี่ห้อรถ/รุ่นรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ยี่ห้อรถ/รุ่นรถ2</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIconSetBrandCar />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} md={3} lg={2} className="mt-3">
                        <Form.Label className="mt-2 mb-2 pl-2 text-left set-hidden-add">
                          ยี่ห้อ
                        </Form.Label>
                        {/* { Search.CarBrandId } */}
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="ยี่ห้อ"
                          options={Brands}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={
                            Search.CarBrandId
                              ? Brands.find((data) => {
                                  return data.id === Search.CarBrandId;
                                })
                              : null
                          }
                          onChange={(data) => ChangeModel(data)}
                        />
                      </Col>
                      <Col xs={12} md={3} lg={2} className="mt-3">
                        <Form.Label className="mt-2 mb-2 pl-2 text-left set-hidden-add">
                          รุ่นรถ/รหัสรุ่น
                        </Form.Label>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="รุ่นรถ/รหัสรุ่น"
                          options={Models}
                          getOptionLabel={(option) =>
                            `${option.model}-${option.car_serie.code}`
                          }
                          getOptionValue={(option) => option.id}
                          value={
                            Search.CarModelId
                              ? Models.find((data) => {
                                  return data.id === Search.CarModelId;
                                })
                              : null
                          }
                          isDisabled={!Search.CarBrandId}
                          onChange={(data) =>
                            setSearch({ ...Search, CarModelId: data.id })
                          }
                        />
                      </Col>
                      <Col xs={12} md={3} lg={2} className="mt-3">
                        <Form.Label className="mt-2 mb-2 pl-2 text-left set-hidden-add">
                          ประเภท
                        </Form.Label>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="ประเภท"
                          options={Type}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={
                            Search.CarTypeId
                              ? Type.find((data) => {
                                  return data.id === Search.CarTypeId;
                                })
                              : null
                          }
                          onChange={(data) =>
                            setSearch({ ...Search, CarTypeId: data.id })
                          }
                        />
                      </Col>
                      <Col xs={12} md={3} lg={2} className="mt-3">
                        <Button
                          className="button"
                          onClick={() => GetCarBrand()}
                        >
                          ค้นหา &nbsp;
                          <img src={SearchIcon} />
                        </Button>
                      </Col>
                      <Col xs={12} md={3} lg={2} className="mt-3">
                        <Button type="" className="button" onClick={() => ResetSearch()}>
                          <img src={Return} /> &nbsp; คืนค่า
                          
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        md={9}
                        lg={3}
                        className="mt-3 set-hidden-add"
                      >
                        <Button type="" className="button">
                          <img src={Add} />
                          &nbsp; เพิ่มรายการ
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-4'></Row>
                <div className="set-height">
                  <Row className="g-0  justify-content-center">
                    <Row className="">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">ยี่ห้อ</th>
                            <th className="text-left">รุ่น</th>
                            <th>รหัสรุ่นรถยนต์</th>
                            <th>ปี</th>
                            <th>ประเภท</th>
                            <th>ขนาดรถ</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {CarBrands?.map((brand, index) => {
                            return (
                              <tr className="t-tr-setting">
                                <td className="text-left">{brand.name}</td>
                                <td className="text-left">
                                  {brand.car_models?.map((model, modelKey) => {
                                    return <p key={modelKey}>{model?.model}</p>;
                                  })}
                                </td>
                                <td>
                                  {brand.car_models?.map((model, modelKey) => {
                                    return (
                                      <p key={modelKey}>
                                        {model.car_serie?.code}
                                      </p>
                                    );
                                  })}
                                </td>
                                <td>
                                  {brand.car_models?.map((model, modelKey) => {
                                    return (
                                      <p key={modelKey}>
                                        {model.car_serie?.year}
                                      </p>
                                    );
                                  })}
                                </td>
                                <td>
                                  {brand.car_models?.map((model, modelKey) => {
                                    return (
                                      <p key={modelKey}>
                                        {model.car_serie?.car_type?.name}
                                      </p>
                                    );
                                  })}
                                </td>
                                <td>
                                  {brand.car_models?.map((model, modelKey) => {
                                    return (
                                      <p key={modelKey}>
                                        {model.car_serie?.size}
                                      </p>
                                    );
                                  })}
                                </td>
                                <td className="text-left">
                                  <Link
                                    to={`/setting/car-brand/${brand.id}/edit`}
                                  >
                                    <Button
                                      type="button"
                                      className="button-image mr-2"
                                    >
                                      <img src={Edit} />
                                    </Button>
                                  </Link>
                                  &nbsp;
                                  <Button
                                    className="button-package"
                                    onClick={(e) => deleteCarBrand(e, brand.id)}
                                  >
                                    <img src={Delete} />
                                  </Button>
                                </td>
                                
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) =>
                            setSearch({ ...Search, PerPage: data.value })
                          }
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {!isLoading ? (
                        <MyPagination
                          totPages={Rows}
                          currentPage={Search.CurrentPage}
                          pageClicked={(e) => {
                            setSearch({ ...Search, CurrentPage: e });
                          }}
                        ></MyPagination>
                      ) : (
                        ''
                      )} */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
