import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Container, Row, Col, Dropdown, Form, Button } from 'react-bootstrap';

import { Switch } from 'antd';
import Select, { components } from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom';

import SearchIcon from 'assets/icon/search.png';
import Add_member from 'assets/icon/add_menu.png';
import Delete from 'assets/icon/delete.png';
import Write from 'assets/icon/key-fix.png';
import Save from 'assets/icon/flat-style-circle-save.png';

// API
import { PackageList, UpdatePackageStatus, DestroyPackage } from 'actions/package';

export default function List(props) {
  const { name, current } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const [Package, setPackage] = useState([])
  const [PackageOptions, setPackageOptions] = useState([])
  const [Rows, setRows] = useState(0);
  const [Search, setSearch] = useState({
    Type: name,
    Keyword: null
  });
  
  const GetPackage = async () => {
    try {
      const params = {
        keyword: Search.Keyword,
        type: Search.Type === 'แพ็กเกจ' ? 'แพ็คเกจพิเศษ' : Search.Type,
        limit: 999999999999,
        page: 1
      }
      const response = await dispatch(PackageList(params))
      if (response) {
        const data = response.data.data.data
        setPackage(data);
        setRows(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetPackageSelect = async () => {
    const params = {
      keyword: Search.Keyword,
      type: Search.Type === 'แพ็กเกจ' ? 'แพ็คเกจพิเศษ' : Search.Type,
    }
    try {
      const response = await dispatch(PackageList(params))
      if (response) {
        setPackageOptions(response.data.data);
        setRows(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const EditPackageStatus = async (id, active) => {
    const form = {
      id,
      data:{
        active
      }
    }
    try {
      const response = await dispatch(UpdatePackageStatus(form))
      if (response) {
        GetPackage()
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  // const DeletePackage = async (id) => {
  //   try {
  //     const response = await dispatch(DestroyPackage(id))
  //     if (response) {
  //       GetPackage()
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     console.log('finally')
  //   }
  // }
  
  const DisplayPrice = (name, sizes) => {
    const result = sizes.find(item => item.size === name)
    if (result) {
      return result.price
    }else{
      return ''
    }
  }

  const ChangePackageStatus = async(item, checked) => {
    EditPackageStatus(item.id, checked)
    setPackage([
      ...Package.map(
        el => el.id === item.id ? { ...el, active: checked }: el
      )
    ])
  }

  const OnEnterSearch = (event) => {
    if (event.key === 'Enter') {
      GetPackage();
    }
  }

  const OnEmptySearch = (event) => {
    if (event.target.value.length === 0) {
      GetPackage();
    }
  }

  useEffect(() => {
    console.log(name , current)
    if (name === current) {
      GetPackageSelect();
      GetPackage();
    }
  }, [name, current]);

  return (
    <div className="div-tab-all ">
      <br />
      <div className="width-table">
        <table className="table table-responsive">
          <thead className="t-header-package">
            <tr>
              <th>
                <div className="div-input-search">
                  <Form.Control
                    type="text"
                    placeholder="ค้นหา"
                    value={Search.Keyword ? Search.Keyword : ''}
                    onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                    onKeyDown={OnEnterSearch}
                    onKeyUp={OnEmptySearch}
                    className="input-search"
                  ></Form.Control>
                </div>
              </th>
              <th>
                <Button type="button" className="button" onClick={() => GetPackage()}>
                  <img src={SearchIcon} className="icon-button" /> &nbsp; ค้นหา
                </Button>
              </th>
              <th>
                <Link to={`/package/${name}/add`}>
                  <Button type="button" className="button">
                    <img src={Add_member} className="icon-button" /> &nbsp;
                    เพิ่มรายการ
                  </Button>
                </Link>
              </th>
            </tr>
          </thead>
        </table>
        <table className="table table-responsive ">
          <thead>
            {
              (['บริการเคลือบเฉพาะจุด']).includes(name)
              ?
              <tr className="t-header-package">
                <th>โลโก้</th>
                <th>ชื่อสินค้า</th>
                <th>กระโปรงหน้า</th>
                <th>กระโปรงหลัง</th>
                <th>ประตูหน้า</th>
                <th>ประตูหลัง</th>
                <th>กันชน</th>
                <th>หลังคา</th>
                <th>แก้ม</th>
                {/* <th>บริการเปิด/ปิด</th> */}
                <th></th>
                <th></th>
              </tr>
              :
              <tr className="t-header-package">
                <th>โลโก้</th>
                <th>ชื่อสินค้า</th>
                {
                  (['แพ็กเกจ']).includes(name)
                  &&
                  <th>ราคาเหมา</th>
                }
                {
                  (['บริการฟิล์มกรองแสง', 'บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name)
                  &&
                  <th>รับประกัน</th>
                }
                <th>S</th>
                <th>M</th>
                <th>L</th>
                <th>TRUCK CAB</th>
                <th>SUV</th>
                <th>TRUCK 4 DOOR </th>
                <th>XL</th>
                <th>VAN</th>
                {/* <th>บริการเปิด/ปิด</th> */}
                <th></th>
                <th></th>
              </tr>
            }

          </thead>
          <tbody className="t-body-package">
            {
              Package?.map((item, key) => {
                return(
                  (['บริการเคลือบเฉพาะจุด']).includes(name)
                  ?
                  <>
                  
                  <tr key={`middle-price-${key}`}>
                    <td>
                      <div className='package-logo-field'>
                        {
                          item.logo && <img src={item.logo_url} width="80" className="package-logo"/>
                        }
                      </div>
                    </td>
                    <td className='text-left'>{ item.name }</td>
                    <td>{ DisplayPrice('กระโปรงหน้า', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('กระโปรงหลัง', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('ประตูหน้า', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('ประตูหลัง', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('กันชน', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('หลังคา', item.middle_sizes) }</td>
                    <td>{ DisplayPrice('แก้ม', item.middle_sizes) }</td>
                    {/* <td>
                      <Switch checked={item.active} />
                    </td> */}
                    <td>
                      <Link to={`/package/${name}/${item.id}/edit/middle`}>
                        <Button type="button" className="button-package">
                          <img src={Write} />
                        </Button>
                      </Link>
                    </td>
                    <td>
                      {
                        !(['แพ็กเกจ']).includes(name) &&
                        
                        <Link to={`/package/${name}/${item.id}/edit/b2b`} className="block-underline">
                          <div className="b2b-button">
                            <div class="side">
                              <div className="field">
                                B2B
                              </div>
                            </div>
                          </div>
                        </Link>
                      }
                    </td>
                  </tr>
                  </>          
                  :
                  <>
                    <tr key={`middle-price-${key}`}>
                      <td>
                        <div className='package-logo-field'>
                          {
                            item.logo && <img src={item.logo_url} width="80" className="package-logo"/>
                          }
                        </div>
                      </td>
                      <td className='text-left'>{ item.name }</td>
                      {
                        (['บริการฟิล์มกรองแสง', 'บริการเคลือบเหมา', 'บริการฟิล์มกันรอย']).includes(name)
                        &&
                        <td>{item.warranty_period}</td>
                      }
                      {
                        (['แพ็กเกจ']).includes(name)
                        &&
                        <td>{ DisplayPrice('ราคาเหมา', item.middle_sizes) }</td>
                      }
                      <td>{ DisplayPrice('S', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('M', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('L', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('TRUCK CAB', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('SUV', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('TRUCK 4 DOOR', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('XL', item.middle_sizes) }</td>
                      <td>{ DisplayPrice('VAN', item.middle_sizes) }</td>
                      <td>
                        <Link to={`/package/${name}/${item.id}/edit/middle`}>
                          <Button type="button" className="button-package">
                            <img src={Write} />
                          </Button>
                        </Link>
                      </td>
                      <td className='p-0'>
                        {
                          !(['แพ็กเกจ']).includes(name) &&
                          
                          <Link to={`/package/${name}/${item.id}/edit/b2b`} className="block-underline">
                            <div className="b2b-button">
                              <div class="side">
                                <div className="field">
                                  B2B
                                </div>
                              </div>
                            </div>
                          </Link>
                        }
                      </td>
                    </tr>
                  </>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}