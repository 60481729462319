import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ToastContainer, 
  Toast
} from 'react-bootstrap';
import Setting from 'assets/icon/setting.png';
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'Components/layout/TabsIcon';
import { Switch } from 'antd';
import Back from 'assets/icon/back.png';
import Save from 'assets/icon/save.png';

import { CustomPomermissions,CreateLogPermission } from '../../actions/settings/permission';


export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [Rerender,setRerender] = useState(false);

  const [statusService, setStatusService] = useState(false);
  const statusServiceToggler = () => {
    statusService ? setStatusService(false) : setStatusService(true);
  };

  const [Data,setData] = useState('');

  const [statepage, setStatepage] = useState('');
  const [page, setPage] = useState([
    { value: 'ทั้งหมด', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [validated, setValidated] = useState(false);
  const [Rows, setRows] = useState(0);

  const [isLoading, setisLoading] = useState(false);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });
  const [Middle,setMiddle] = useState(false);
  const [Permissons,setPermissons] = useState([]);
  const [SelectList,setSelectList] = useState([]);

  const handleOnChangeMiddle = () => {
    setMiddle(!Middle);
    setRerender(true);
  };
  
  useEffect(() => {
    if (!Rerender) GetPermissons();
    setRerender(false)
   }, [Rerender]);

  const [errors, setErrors] = useState([]);
  const handleOnChangePermissons = (status, checked, index) =>{
    if(status === 'All'){
      let obj = {}
      for (var key in Permissons[index]) {
        if (!['PermissionId', 'Name'].includes(key)) {
          obj[key] = checked
        }
      }
      setPermissons(Permissons.map(
        (el, key) => key === index ? {...el, ...obj} : el
      ))
    }else{
      setPermissons(Permissons.map(
        (el, key) => key === index ? {...el, [status]: checked} : el
      ))
    }
    // if(status === 'All'){
    //   let data = {...Permissons[index]};
     
    //   if(e.target.checked === true){
    //     data.All = true
    //     data.View = true
    //     data.Add = true
    //     data.Edit = true
    //     data.Delete = true
    //   }
    //   Permissons[index] = data;
    //   setPermissons(Permissons);
    // }else{
    //   let data = {...Permissons[index]};
    //   data.All = false
    //   data.View = status === 'View' ? e.target.checked : item.View
    //   data.Add = status === 'Add' ? e.target.checked : item.Add
    //   data.Edit = status === 'Edit' ? e.target.checked : item.Edit
    //   data.Delete = status === 'Delete' ? e.target.checked : item.Delete
    //   Permissons[index] = data;
    //   setPermissons(Permissons);
    // }
  }

  const GetPermissons = async (data) => {
    setisLoading(true)
    try {
      const response = await dispatch(CustomPomermissions());
      if(response.data.data && response.data.data.data){
        setPermissons(response.data.data.data);
        setRows(response.data.data.data.last_page);
        setisLoading(false)
      }else{
        const data = Middle === true ? response.data.middle : response.data.branche
        const newForm = data.map( i => {
          return {
            PermissionId: i.id,
            Name: i.name,
            All: false,
            View: false,
            Add: false,
            Edit: false,
            Delete: false,
          }
        });
        // console.log('newForm',newForm);
        setPermissons(newForm);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    
    if (form.checkValidity()) {
      let formData = new FormData();
      formData.append('name', Data.Name)
      formData.append('active', statusService)
      formData.append('middle', Middle)
      if(Permissons) formData.append('setting_permission', JSON.stringify(Permissons))
      
      dispatch(CreateLogPermission(formData)).then(response => {
        history.push('/setting/permission')
      }).catch(e => {
        if(e.response && e.response.data){
          if(e.response.data.messages){
            setErrors([...errors, ...e.response.data.messages])
          }
        }
      })
    }
  };

 

  useEffect(() => {
    GetPermissons();
  }, []);

  useEffect(() => {
    // GetPermissons();
  }, [Search.PerPage, Search.CurrentPage]);

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    let path = `/setting/permission`;
    history.push(path);
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <ToastContainer position='top-end'>
          {
            errors?.map((error, key) => {
              return (
                <Toast show={true} key={key} delay={2500} autohide onClose={() => setErrors([])}>
                  <Toast.Header>
                    <strong className="me-auto">แจ้งเตือน</strong>
                  </Toast.Header>
                  <Toast.Body>{ error }</Toast.Body>
                </Toast>
              )
            })
          }
        </ToastContainer>
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Row className="g-0 justify-content-center mb-3">
                  <Col xs={12}>
                    <Row>
                      <Col xs={8} md={9} lg={5}>
                        {/* <Form.Control placeholder="ชื่อประเภท"  ></Form.Control> */}
                        <Form.Control
                          type="text"
                          name="name"
                          required
                          value={Data.Name}
                          onChange={(e) => setData({ ...Data, Name: e.target.value })}
                          placeholder="ชื่อระดับ" 
                        ></Form.Control>
                      </Col>
                      <Col
                        xs={4}
                        md={3}
                        lg={3}
                        xl={1}
                        className="d-flex align-items-center"
                      >
                        <Switch onClick={statusServiceToggler} />
                        {/* {statusService ? <span>well</span> : <span>Bad</span>} */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                  <div className="set-height">
                    <Row className="g-0   mt-3 justify-content-center">
                      <Col lg={12} className="d-flex mt-2 mb-2">
                        <Form.Check inline label="" name="check" 
                        checked={Middle}
                        onChange={handleOnChangeMiddle}

                        />
                        <p>เฉพาะระบบกลาง 
                          {/* { Middle === true ? 'true' : 'false' }  */}
                          </p>
                      </Col>
                      <Col lg={12} className="d-flex mt-2 mb-2">
                        <p className="font-weight-bold">ตั้งค่าการใช้งาน</p>
                      </Col>
                      <Row className="">
                        <table className="table table-responsive ">
                          <thead>
                            <tr className="t-header-setting">
                              <th className="text-left" width="50%">
                                ชื่อเมนู
                              </th>
                              <th>ทั้งหมด</th>
                              <th>ดู</th>
                              <th>สร้าง</th>
                              <th>แก้ไข</th>
                              <th>ลบ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            { 
                              Permissons?.map( (item, index) => {
                                return (
                                  <tr
                                    key={`inline-checkbox${index}`}
                                    className="t-tr-setting"
                                  >
                                    <td className="text-left">{item.Name}</td>

                                    <td>
                                      <Form.Check
                                        inline
                                        // label="all"
                                        name="all"
                                        type='checkbox'
                                        checked={item.All}
                                        onChange={(e) => handleOnChangePermissons('All', e.target.checked, index)}
                                        id={`inline-checkbox-box-1`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        // label="view"
                                        name="view"
                                        type='checkbox'
                                        checked={item.View}
                                        onChange={(e) => handleOnChangePermissons('View', e.target.checked, index)}
                                        id={`inline-checkbox-box-2`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        // label="add"
                                        name="add"
                                        type='checkbox'
                                        checked={item.Add}
                                        onChange={(e) => handleOnChangePermissons('Add', e.target.checked, index)}
                                        id={`inline-checkbox-box-3`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        // label="edit"
                                        name="edit"
                                        type='checkbox'
                                        checked={item.Edit}
                                        onChange={(e) => handleOnChangePermissons('Edit', e.target.checked, index)}
                                        id={`inline-checkbox-box-4`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        // label="delete"
                                        name="delete"
                                        type='checkbox'
                                        checked={item.Delete}
                                        onChange={(e) => handleOnChangePermissons('Delete', e.target.checked, index)}
                                        id={`inline-checkbox-box-5`}
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              })
                            }
                            
                             
                          </tbody>
                        </table>
                      </Row>
                    </Row>
                  </div>

                  <Row className="justify-content-center mt-3 mb-2">
                    <Col md={3} lg={2}>
                      <Button type="submit" className="button">
                        <img src={Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
