import axios, { post } from 'axios';

export const BillList = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/bills`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};
export const DebtorStatus = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/bill/debtor-status`,
    { params },
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindBill = (id) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/bills/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CancelPaidBill = (id) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/bill/cancel/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const SendRequestApprovedDiscount = (formData) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/bill_send_request_approved_discount`,
    formData
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

