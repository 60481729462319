import axios from 'axios';

export const LevelUserList = (params) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/levels`,
      { params }
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};