import React, { useState, useEffect, useRef} from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Select from 'react-select';

import Footer from 'Components/Footer';
import TabsIcon from 'Components/layout/TabsIcon';
import save from 'assets/icon/new-save.png';
import cancel from 'assets/icon/new-cancel.png';
import delete_button from 'assets/icon/delete.png';
import Delete from 'assets/icon/delete.png';
import Service from 'assets/icon/management.png';
import Back from 'assets/icon/back.png';
import uploadImage from 'assets/icon/upload.png';
import { ModifierFlags } from 'typescript';

import { Province } from 'actions/data/index';
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { FindCarOwner, UpdateCarOwner, DestroyCarOwner } from 'actions/customer/car';
import { CarTypeList } from 'actions/settings/car_type';
import { CarColorList } from 'actions/settings/car_color';

export default function AddCar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id, car_owner_id } = useParams();
  const ImageFileRef = useRef(null);

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [Brands, setBrands] = useState([]);
  const [ColorList, setColorList] = useState([]);
  const [Models, setModels] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [CarType, setCarType] = useState([]);
  
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);
  
  const [PlateType, setPlateType] = useState([
    { label: 'ป้ายแดง', value: 'ป้ายแดง' },
    { label: 'ป้ายธรรมดา', value: 'ป้ายธรรมดา' },
    { label: 'ไม่มีป้าย', value: 'ไม่มีป้าย' },
    { label: 'ป้ายขาว', value: 'ป้ายขาว' },
    { label: 'ป้ายประมูล', value: 'ป้ายประมูล' },
  ]);

  const [ValidateLicensePlate,setValidateLicensePlate] = useState([]);

  const model = {
    CustomerId: id,
    CarTypeId: null,
    CarBrandId: null,
    CarSerieId: null,
    Year: null,
    // Size: null,
    Engine: null,
    Color: null,
    ProvinceId: null,
    PlateType: null,
    LicensePlate: null,
    ChassisNumber: null,
    PaintCoating: null,
    Mileage: null,
    Note: null,
    Images: [],
    ImagesDisplay:[],
    ImagesRemove: [],
  }
  const [Data, setData] = useState(
    model
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closed = () => {
    setShow(false);
  };

  // const [FileName, setFileName] = useState({
  //   Image: ''
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(Data.PlateType === null){
      alert('กรุณาเลือกประเภทแผ่นป้าย');
    }

    if(Data.PlateType != "ไม่มีป้าย" && Data.LicensePlate === null){
      alert('กรุณากรอกป้ายทะเบียน');
    }

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    console.log(Data)

    let formData = new FormData();
    if(Data.CustomerId) formData.append('customer_id', Data.CustomerId);
    if(Data.CarSerieId) formData.append('car_serie_id', Data.CarSerieId);
    if(Data.CarTypeId) formData.append('car_type_id', Data.CarTypeId);
    if(Data.ProvinceId) formData.append('province_id', Data.ProvinceId);
    if(Data.Year) formData.append('year', Data.Year);
    // if(Data.Size) formData.append('size', Data.Size);
    if(Data.Engine) formData.append('engine', Data.Engine);
    if(Data.Color) formData.append('color', Data.Color);
    if(Data.PlateType) formData.append('plate_type', Data.PlateType);
    if(Data.LicensePlate) formData.append('license_plate', Data.LicensePlate);
    if(Data.ChassisNumber) formData.append('chassis_number', Data.ChassisNumber);
    if(Data.PaintCoating) formData.append('paint_coating', Data.PaintCoating);
    if(Data.Mileage) formData.append('mileage', Data.Mileage);
    if(Data.Note) formData.append('note', Data.Note);
    if(Data.Images.length > 0) {
      let i = 0 ;
      while (i < Data.Images.length){
        formData.append('images[]', Data.Images[i]);
        i++;
      }
    }
    if(Data.ImagesRemove) formData.append('images_remove',Data.ImagesRemove);
    formData.append('_method', 'PUT');

    const result = {
      id: car_owner_id,
      formData
    }

    try {
      const response = await dispatch(UpdateCarOwner(result));
     
      if(response){
        setData(model);
        setValidated(false);
        history.push(`/member/edit/${id}?activeTab=2`);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const GetCarOwner = async () => {
    try {
      const response = await dispatch(FindCarOwner({ id: car_owner_id }));
      GetCarModel(response.data.data?.car_serie?.car_model?.car_brand_id)
      // console.log('response.data.data.carImage',response.data.data.car_image);
      setData({
        CustomerId: response.customer_id,
        CarTypeId: response.data.data?.car_type_id,
        CarBrandId: response.data.data?.car_serie?.car_model?.car_brand_id,
        CarSerieId: response.data.data?.car_serie_id,
        Year: response.data.data?.year,
        // Size: response.data.data.car_serie.size,
        Engine: response.data.data?.engine,
        Color: response.data.data?.color,
        ProvinceId: response.data.data?.province_id,
        PlateType: response.data.data?.plate_type,
        LicensePlate: response.data.data?.license_plate,
        ChassisNumber: response.data.data?.chassis_number,
        PaintCoating: response.data.data?.paint_coating,
        Mileage: response.data.data?.mileage,
        Note: response.data.data?.note,
        Images: [],
        ImagesDisplay: arrImage(response.data.data.car_image),
        // ImagesDisplay: response.data.data.car_image,
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function arrImage(data){
    const arr = []
    data.map( i => {
      arr.push({image:i.image,status:'old',id:i.id})
    })
    return arr
  }

  const deleteCarOwner = async (e) => {
    e.preventDefault();
    console.log('in')
    try {
      const response = await dispatch(DestroyCarOwner(car_owner_id));
     
      if(response){
        history.push(`/member/edit/${id}?activeTab=2`);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetColor = async (data) => {
    try {
      const response = await dispatch(CarColorList());
      setColorList(response.data.raw);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarType = async (data) => {
    try {
      const response = await dispatch(CarTypeList());
      setCarType(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetCarOwner();
    GetBrand();
    GetProvince();
    GetCarType();
    GetColor();
  }, []);

  function ChangeModel(data) {
    setData({...Data, CarBrandId: data.id })
    GetCarModel(data.id)
  }

  function ChangeColor(data) {
    setData({...Data, Color: data.id})
  }

  const ToggleImageFile = () => {
    if(ImageFileRef.current) ImageFileRef.current.click();
  }

  const SetImageFiles = (e) => {
    // set upload file
    const files = [...Data.Images];
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      files.push(item)
    }
    //set display
    const url_image = [...Data.ImagesDisplay]
    const newfilter = url_image.filter( item => {
      return item.status !== 'new'
    });
    
    for (let index = 0; index < files.length; index++) {
      const item = files[index]
      const url = URL.createObjectURL(item)
      newfilter.push({image:url, name:item.name, status:'new'})
    }
    setData({ ...Data, Images: files, 
      ImagesDisplay: newfilter 
    });

  }

  
  const deleteFile = (index,item) => {
    switch (item.status) {
      case 'new':
          const getIndex = Data.Images.findIndex(
            file => file.name === item.name
          );
      // 
          setData({ ...Data, Images: Data.Images.filter(function(_, i) { 
              return i !== getIndex
            }),
            ImagesDisplay: Data.ImagesDisplay.filter(function(_, key) { 
              return key !== index
            })
          })
        break;
      case 'old':
        const getIndexOld = Data.Images.findIndex(
          file => file.id === item.id
        );

        const getRemove = Data.ImagesDisplay.filter(
          file => file.id === item.id
        );
          console.log('getRemove.id',getRemove);
        setData({ ...Data, Images: Data.Images.filter(function(_, i) { 
            return i !== getIndexOld
          }),
          ImagesDisplay: Data.ImagesDisplay.filter(function(_, key) { 
            return key !== index
          }),
          ImagesRemove: [getRemove[0].id],
        })

      break;
      default:
        break;
    }
  }

  function goToJob(l,y){
    const data = {
      customer_id : '',
      car_id: '',
      status_job: 'new',
      type: y,
      PackageList:[],
      show_room_employee_id: '',
      company_id: '',
    }
    localStorage.setItem('job-inside-data',JSON.stringify(data));
    history.push(l);
  }

  function close() {
    history.push(`/member/edit/${id}?activeTab=2`);
  }

  function exit() {
    history.push(`/member/edit/${id}?activeTab=2`);
  }

  function addCommas(x) {
    return parseInt(x.replaceAll(',', '')).toLocaleString()
  }

  return (
    <>
      
      <Container fluid className=" package-container Service Stock User bg-mrk">
        <div className="package ">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>ลูกค้า &gt; สร้างข้อมูลรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>ลูกค้า &gt; สร้างข้อมูลรถ</b>
                </h5>
              </div>
              <div className="modal-body add-car">
                <Row className="m-0">
                  <TabsIcon />
                </Row>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <p className="title-addcar">เพิ่มข้อมูลรถ</p>
                  </Col>
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ยี่ห้อ</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Brands}
                                placeholder=""
                                className="select-add-member"
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                value={Data.CarBrandId ? Brands.find((data) => {return data.id === Data.CarBrandId}) : null}
                                onChange={(data) => ChangeModel(data)}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>รุ่นและรหัสรุ่น</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Models}
                                placeholder=""
                                className="select-add-member"
                                getOptionLabel={option => `${option.model}-${option.car_serie?.code}`}
                                getOptionValue={option => option.id}
                                isDisabled={!Data.CarBrandId}
                                value={Data.CarSerieId ? Models.find((data) => {return data?.car_serie?.id === Data.CarSerieId}) : null}
                                onChange={(data) => setData({...Data, CarSerieId: data?.car_serie?.id })}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ประเภท</Form.Label>
                            </Col>
                            <Col>
                              <Form.Select value={Data.CarTypeId} onChange={(e) => setData({...Data, CarTypeId: e.target.value })}>
                                <option hidden selected>เลือกประเภทรถ</option>
                                {
                                  CarType?.map(item => {
                                    return (
                                      <option value={item.id}>{item.name}</option>
                                    )
                                  })
                                }
                              </Form.Select>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ปีที่ออกรถ</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Year}
                                onChange={(e) => setData({...Data, Year: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>สีรถ</Form.Label>
                            </Col>
                            <Col>
                              {/* <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Color}
                                onChange={(e) => setData({...Data, Color: e.target.value })}
                              ></Form.Control> */}
                              <Select
                                options={ColorList}
                                placeholder=""
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                value={ColorList.find((data) => {return data.id == Data.Color})}
                                onChange={(data) => ChangeColor(data)}
                                className={`select-add-member select-list ` + (!Data.Color && validated ? 'validate-fail' : '') }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>ขนาดเครื่องยนต์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Engine}
                                onChange={(e) => setData({...Data, Engine: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ประเภทป้าย</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={PlateType}
                                placeholder=""
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.id}
                                value={Data.PlateType ? PlateType.find((data) => {return data.value === Data.PlateType}) : null}
                                onChange={(data) => {setData({...Data, PlateType: data.value });setValidateLicensePlate({...ValidateLicensePlate, RequestLicensePlate: data.value !== 'ไม่มีป้าย' ? true  :false, RequestProvinces: data.value !== 'ไม่มีป้าย' ? true : false})}}
                                className="select-add-member"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>เลขทะเบียน</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.LicensePlate}
                                required={ValidateLicensePlate.RequestLicensePlate ? ValidateLicensePlate.RequestLicensePlate : false}
                                onChange={(e) => setData({...Data, LicensePlate: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>จังหวัด</Form.Label>
                            </Col>
                            <Col>
                              <Select
                                options={Provinces}
                                placeholder="จังหวัด"
                                getOptionLabel={option => option.name_th}
                                getOptionValue={option => option.id}
                                isClearable={false}
                                className={`select-add-member select-list ` + (!Data.ProvinceId && validated ? 'validate-fail' : '') }
                                value={Data.ProvinceId ? Provinces.find((data) => {return data.id === Number(Data.ProvinceId)}) : null}
                                onChange={(data) => setData({...Data, ProvinceId: data.id })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center ">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>หมายเลขตัวถัง</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.ChassisNumber}
                                onChange={(e) => setData({...Data, ChassisNumber: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>เลขไมล์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.Mileage ? addCommas(Data.Mileage) : ''}
                                onChange={(e) => setData({...Data, Mileage: e.target.value.replaceAll(',', '') })}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>ความหนาสีรถยนต์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Data.PaintCoating}
                                onChange={(e) => setData({...Data, PaintCoating: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={9} className="d-flex justify-content-start">
                          <div className="scale-image multi-preview">
                            <div className="result">
                              <Row>
                                {
                                  Data.ImagesDisplay.map((item, index) => {
                                    return (
                                      <Col xs="auto" key={`preview-${index}`} className="mt-3 ">
                                        <img src={item.image} width="150px" height="100%"/>
                                        <button type='button' onClick={() => deleteFile(index,item)} className="position-absolute-button-image">
                                          <img src={Delete} />
                                        </button>
                                      </Col>
                                    );
                                  })
                                }
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="d-flex justify-content-center mt-3 mb-3">
                          <div className="label-holder">
                            <label htmlFor="file" className="label" onClick={ToggleImageFile}>
                              <img src={uploadImage} /> &nbsp; อัพโหลดภาพ
                            </label>
                          </div>
                          <Form.Control
                            type="file"
                            className="d-none"
                            id="avatar-file"
                            ref={ImageFileRef}
                            multiple
                            onChange={(e) => SetImageFiles(e) }
                          />
                        </Col>
                      </Row>
                      <Row className="row-form-create-memeber align-items-center">
                        <Col lg={4} className="mt-2 mb-2">
                          <Row>
                            <Col lg={3} className="text-left">
                              <Form.Label>หมายเหตุ</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                value={Data.Note}
                                onChange={(e) => setData({...Data, Note: e.target.value })}
                                rows={3}
                                className="text-area"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col xs={12} lg={6}>
                          <Row>
                            <Col xs={12} lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="submit"
                                className="btn-upload-image"
                              >
                                <img src={save} />
                                &nbsp; บันทึก
                              </Button>
                              { level.middle !== true ? (<>
                <Button type="button" className="button mt-3" onClick={handleShow}>
                  JOB หน้าร้าน
                </Button>
                <Modal className="modal-style" centered show={show} onHide={handleClose}>
                <Modal.Header className="modal-header-style">
                  <p>
                    <b>JOB</b>
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closed}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <Row className="justify-content-center set-modal-body-job">
                  <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/inside-data', 'b2c') }} className="button">
                        หน้าร้าน
                      </Button>
                    </Col>

                    <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/company-b2b-show-room', '') }} className="button">
                        Showroom (B2B)
                      </Button>
                    </Col>

                    <Col lg={3}>
                      <Button type="button" onClick={() => { goToJob('/job/inside-data-old', 'b2c') }} className="button">
                        ลูกค้าเก่า
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
              </>) : null}
                            </Col>
                            <Col xs={12} lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="button"
                                className="btn-upload-image"
                                onClick={(e) => deleteCarOwner(e)}
                              >
                                <img src={delete_button} />
                                &nbsp; ลบ
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Row className="justify-content-end">
                            <Col lg={6} xl={4} className="mt-2 mb-2">
                              <Button
                                type="button"
                                className="btn-upload-image"
                                onClick={exit}
                              >
                                <img src={cancel} />
                                &nbsp; ออก
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
