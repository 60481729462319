import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, Row, Col, Button, Form,Text } from 'react-bootstrap';
import Profile from 'assets/icon/Profile-logo.png';
import { updateUser } from 'slice/userSlice';

//api
import {UpdateUserProfile} from 'actions/user/index'
import { SignOut } from 'actions/auth';
import { message } from 'antd';

export default function Edit(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [Employee,setEmployee] = useState({
      id: null,
      fullname: null,
      username: null,
      password: null,
      password_confirmation : null,
      active: false,
      avatar: null,
    });

  const [Validate,setValidate] = useState()
  useEffect(() => {
    setEmployee({
      id: props.data.UserId,
      fullname: props.data.Employee && props.data.Employee.fullname,
      username: props.data.Username,
      active: props.data.Active,
      avatar: props.data.Avatar
    });
  }, []);

  const HandleSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('username', Employee.username);
    if(Employee.password) formData.append('password', Employee.password);
    if(Employee.password_confirmation) formData.append('password_confirmation', Employee.password_confirmation );
    formData.append('active', Employee.active === true ? 1 : 0 );
    formData.append('_method', 'put');

    const data = {
      id: Employee.id,
      data: formData
    }
    try {
      const response = await dispatch(UpdateUserProfile(data));
      if(response){
          alert(response.data.message)
          setEmployee({
            password:'',
            password_confirmation:'',
          });
          props.GetProfile();
          props.changeFormToEdit(false);
          Logout()
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
      // alert(error.data)
    } finally {
      console.log('finally')
    }
  }
  async function Logout() {
    try {
      const response = await dispatch(SignOut());
      dispatch(updateUser(null))
      alert('ข้อมูลมีการเปลี่ยนแปลง กรุณาเข้าสู่ระบบใหม่')
      history.push('/login');
    } catch (error) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('profile');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('level');
      history.push('/login');

    }
  }

  // const onchangeform = () => {
  //   props.dchangeFormToEdit();
  // };

  return (
    <>
      <Form onSubmit={HandleSubmit}>
        <Row className="justify-content-center ">
          <Col xs={12} sm={12} md={12} lg={6}>
            <Row className="">
              <Col lg={4}>
                <img width={150} height={150}  src={Employee.avatar ? Employee.avatar : Profile} />
              </Col>
              <Col lg={8}>
                <Row className="justify-content-center">
                  <Col xs={12} lg={12}>
                    <Row className="justify-content-center set-row-Showid">
                      <Col xs={3} md={2} lg={4} className="text-left">
                        <Form.Label>ชื่อ-นามสกุล</Form.Label>
                      </Col>
                      <Col xs={4} md={3} lg={6} className="text-left">
                        <p>{ Employee.fullname }</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={12}>
                    <Row className="justify-content-center set-row-Showid">
                      <Col xs={3} md={2} lg={4} className="text-left set-text">
                        <Form.Label >ชื่อผู้ใช้</Form.Label>
                      </Col>
                      <Col xs={4} md={3} lg={6} className="text-left">
                        <Form.Control 
                          className="input"
                          value={Employee.username}
                          onChange={(e) => setEmployee({...Employee, username:e.target.value})}
                        >
                          
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="justify-content-center set-row-Showid">
                      <Col xs={3} md={2} lg={4} className="text-left set-text">
                        <Form.Label>รหัสผ่าน</Form.Label>
                      </Col>
                      <Col xs={4} md={3} lg={6} className="text-left">
                        <Form.Control className="input"
                          type="password"
                          value={Employee.password}
                          onChange={(e) => setEmployee({...Employee,password:e.target.value})}
                        ></Form.Control>
                        <Form.Text  muted>
                          {Validate}
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="justify-content-center set-row-Showid">
                      <Col xs={3} md={2} lg={4} className="text-left set-text">
                        <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                      </Col>
                      <Col xs={4} md={3} lg={6} className="text-left">
                        <Form.Control className="input"
                          type="password"
                          value={Employee.password_confirmation }
                          onChange={(e) => setEmployee({...Employee,password_confirmation :e.target.value})}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={7} className="d-flex justify-content-center">
                    <Button type="submit" className="button-edit" >
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
}
