import axios, { post } from 'axios';

export const PaymentHistory = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/payment_historys`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreatePaymentHistory = (data) => async (dispatch) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/payment_historys`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};
