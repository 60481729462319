import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Pagination, Form, Modal } from 'react-bootstrap';
import StockImage from 'assets/icon/appwizard_list.png'
import Footer from 'Components/Footer';

import { Link, useHistory, useParams } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';

import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import MyPagination from 'Components/MyPagination'

import Search from 'assets/icon/view-id.png';
import { useDispatch, useSelector } from 'react-redux';

import Save from 'assets/icon/new-save.png';
import getout from 'assets/icon/getout.png';
import Document from 'assets/stock/cut-stock.png';

import {LoadStockList,saveStorage,LoadNewStockCode} from 'actions/stock/index' 
import { UnitSale } from 'actions/settings/unit'; 

//API
import { ProductHistory } from '../../actions/product/index';
import { loadAuth } from 'actions/auth';
import { Item } from 'semantic-ui-react';

import { List } from 'actions/employee';

import Arrow from 'assets/stock/adjust-stock.png';
import moment from 'moment';

var dayjs = require('dayjs')

export default function History() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { employee } = useSelector( (state) => state.user)
  const { product_id } = useParams();
  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const { RangePicker } = DatePicker;
  
  const dateFormat = 'YYYY/MM/DD';

  const [causeAdjust, setCauseAdjust] = useState([
    { value: 'lost_product', label: 'สินค้าหาย' },
    { value: 'excess_product', label: 'สินค้าเกิน' },
    { value: 'other', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const [showCutStock, setShowCutStock] = useState(false);
  const [showAdjustStock, setShowAdjustStock] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleAdjustStockClose = () => setShowAdjustStock(false);
  const handleAdjustStockShow = () => setShowAdjustStock(true);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: '', label: '' }]);
  const handleCutStockClose = () => setShowCutStock(false);
  const handleCutStockShow = () => setShowCutStock(true);
  const [search, setSearch] = useState({
    Date: [],
    Type: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });
  const [type, setType] = useState([{ value: '', label: '' }]);
  const [productHistory, setProductHistory] = useState([]);
  const [productHistoryStocks,setProductHistoryStocks] = useState([]);
  function onChangeDate(date) {
    if(date !== null){
      setSearch({...search, Date: date});
    }else{
      setSearch({...search, Date: []});
    }
  }
  const [statepage, setStatepage] = useState('');
  const [Pages, setPage] = useState([
    { value: '0', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const OptionUpdate = [
    { label: 'ทั้งหมด', value: null },
    { label: 'รับเข้า', value: 'import' },
    { label: 'เอาออก', value: 'takeout' },
    { label: 'ปรับสต๊อก', value: 'adjust' },
    { label: 'ขายและใช้งาน', value: 'cut_stock' },
  ];

  const OptionUpdateBranch = [
    { label: 'ทั้งหมด', value: null },
    { label: 'รับเข้า', value: 'import' },
    { label: 'เอาออก', value: 'takeout' },
    { label: 'ปรับสต๊อก', value: 'adjust' },
    { label: 'ขายและใช้งาน', value: 'cut_stock' },
    { label: 'โอน', value: 'transfer' }
  ];

  const [isLoading, setLoading] = useState(true);
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);

  const handleSelectPage = (e) => setStatepage(e);

  const GetProductHistory = async (params) => {
    setLoading(true);
    const newParams = {
      product_id: product_id,
      start_date: search?.Date.length > 0 ? moment(search?.Date[0]).format('YYYY-MM-DD') : null,
      end_date: search?.Date.length > 0 ?moment(search?.Date[1]).format('YYYY-MM-DD') : null,
      type: search.Type,
      limit: search.PerPage,
      page: search.CurrentPage
      
    }
    try {
      const response = await dispatch(ProductHistory(newParams));
        
      if(search.PerPage > 0){
        setProductHistory(response.data.data);
        setProductHistoryStocks(response.data.stocks.data)
        setSearch({ ...search, 
          Rows: response.data.stocks.last_page,
          CurrentPage: response.data.stocks.current_page 
        })
        setLoading(false);
      }else{
        setProductHistory(response.data.data);
        setProductHistoryStocks(response.data.stocks)
        setLoading(false);
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  const getparamsAuth = async (data) => {
    // console.log(data.name);
    try {
      const params = {
        name: data.name,
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        // history.push('/stock');
        setShowAdjustStock(false)
        setShowCutStock(false)
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  }

  const StorageModel = {
    key: 0,
    ProductId: null,
    TotalQuantity: 0,
    UnitName: null,
    Admit: true,
    TakeOut: false,
    Quantity: null,
    UnitType: null,
    UnitId: null,
    UnitSaleId: null,
    CountBy: null,
    Note: null,
    StockDate: null,
    reasonType: null,
  }
  const [Data,SetData] = useState({
    StorageModel
  });

  const setModalAdjustStock = (item) => {
    SetData({
      ProductId: item.id,
      Code: item.code,
      Name: item.name,
      TotalQuantity: item.total_quantity,
      UnitName: item.unit && item.unit.name_buy,
      Admit: true,
      TakeOut: false,
      Quantity: null,
      UnitType: null,
      UnitId: null,
      UnitSaleId: null,
      CountBy: null,
      Note: null,
      reasonType: null,
      StockDate: null,
      Type:'adjust',
    });
  }


  
  const setModalCutStock = (item) => {
    SetData({
            ProductId: item.id,
            Code: item.code,
            Name: item.name,
            TotalQuantity: item.total_quantity,
            UnitName: item.unit && item.unit.name_buy,
            Admit: false,
            TakeOut: true,
            Quantity: null,
            UnitType: null,
            UnitId: null,
            UnitSaleId: null,
            CountBy: profile.id,
            Note: null,
            reasonType: null,
            StockDate: null,
            Type: 'cut_stock',
          });
  }


  const [StockCode,setStockCode] = useState([]);
  async function getNewStockCode(type){
    setLoading(true)
      try {
        const params = {
          type: type,
        }
        const response = await dispatch(LoadNewStockCode(params));
        setStockCode(response.data.data);
        setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }


  const [unitSales, setUnitSales] = useState([]);
  async function getUnitSale(unit_id){
    setLoading(true)
    try {
      const params = {
        unit_id: unit_id,
      }
        const response = await dispatch(UnitSale(params));
        setUnitSales(response.data.data)
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }

  }

  function getUpdateBy(stock){
    // console.log('order_by',stock.order_by);
    // console.log('user',stock.user);
    const name = stock?.order_by !== null ? stock.order_by?.employee?.fullname : stock?.user?.employee?.fullname
    return name
  }


  async function saveStorageList(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    let formData = new FormData();
    formData.append(`stock_list[0]`, JSON.stringify(Data));

    try {
      const response = await dispatch(saveStorage(formData));
      if(response?.data?.message === 'success'){
        // history.push(`/stock`);
        SetData(StorageModel)
        // setClearSearch(true)
        setShowAdjustStock(false)
        setShowCutStock(false)
        GetProductHistory()
      }else if(response?.data?.message === 'errors'){
        const report = response.data.data
        report.map((item) => {
          // setReport(true)
        });
      }else if(response?.data?.message === 'errors_empty'){
        console.log('errors_empty')
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
      // setReport(false)
    } finally {
      console.log('finally')
    }


  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['ลงชื่อ/รับเข้า(stock)','ปรับ/ตัด(stock)'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function closeAdjustStock() {
    setShowAdjustStock(false);
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  function closeCutStock() {
    setShowCutStock(false);
  }

  const [employees,setEmployees] = useState([]);

  const GetEmployee = async () => {
      setLoading(true)
      try {
        const params = {
          keyword: '',
          branch_id : profile.branch_id
        }
        const response = await dispatch(List(params));
        setEmployees(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

  function typeTh(data,import_type,i){
    // console.log(data,i);
    if(data){
      switch (data) {
        case 'import':
              return 'รับเข้า'
        case 'takeout':
              return 'นำออก'
        case 'adjust':
            if(search.Type === 'import'){
              return 'รับเข้า'
            }else if(search.Type === 'takeout'){
              return 'นำออก'
            }else{
              return `ปรับสต๊อก ${import_type === 'import' ? '(รับเข้า)' : '(นำออก)'}` 
            }
        case 'cut_stock':
          if(i.order !== null){
            return `อนุมัติสาขา ${i.order.branche.name} (${i.order.employee.fullname})`;
          }else{
            return 'ตัดสต๊อก'
          }
        case 'transfer':
                return `หน้าร้าน ${import_type === 'import' ? '(นำออกจากหน้าร้าน)' : '(โอนไปหน้าร้าน)'}`
        case 'storefront':
                  return `สินค้าหน้าร้าน`
      
        default:
          break;
      }
    }else{
      return null;
    }

  }

  useEffect(() => {
    getAuth()
  }, []);
  

  useEffect(() => {
    GetProductHistory();
    GetEmployee();
  }, [search.Date, search.Type,search.PerPage,search.CurrentPage]);

  function closePage() {
    let path = `/stock`;
    history.push(path);
  }
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ประวัติการเคลื่อนไหวสินค้า</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ประวัติการเคลื่อนไหวสินค้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="g-0 justify-content-center ">
                  <Col xs={12} className="text-left mt-3 mb-3">
                    <Form.Label className="font-weight-bold m-0" onClick={GetProductHistory}>
                      ข้อมูลสินค้า
                    </Form.Label>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <div className="bg-white pt-3 pb-3">
                      <Col lg={6}>
                        <Row>
                          <Col xs={6} className="text-left ">
                            <Row>
                              <Col xs={5}>
                                <Form.Label className="font-weight-bold">
                                  ชื่อสินค้า
                                </Form.Label>
                              </Col>
                              <Col>
                                <Form.Label>: {productHistory.name}</Form.Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} className="text-left ">
                            <Row>
                              <Col xs={5}>
                                <Form.Label className="font-weight-bold">
                                  คลังสินค้า
                                </Form.Label>
                              </Col>
                              <Col>
                                <Form.Label>: {productHistory.total_quantity ? (productHistory.total_quantity - productHistory.storefront_quantity).toFixed(2) : 0}</Form.Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} className="text-left ">
                            <Row>
                              <Col xs={5}>
                                <Form.Label className="font-weight-bold">
                                  รหัสสินค้า
                                </Form.Label>
                              </Col>
                              <Col>
                                <Form.Label>: {productHistory.code}</Form.Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} className="text-left">
                            {
                              level.middle === false ? (
                                <Row>
                                  <Col xs={5}>
                                    <Form.Label className="font-weight-bold">
                                      หน้าร้าน
                                    </Form.Label>
                                  </Col>
                                  <Col>
                                    <Form.Label>: {productHistory.storefront_quantity ? (productHistory.storefront_quantity).toFixed(2): 0}</Form.Label>
                                  </Col>
                                </Row>
                              ) : null
                            }
                          </Col>
                          <Col xs={6} className="text-left ">
                            <Row>
                              <Col xs={5}>
                                <Form.Label className="font-weight-bold">
                                  {
                                    level.middle === true ? 'จำนวน' : 'คงเหลือ'
                                  }
                                </Form.Label>
                              </Col>
                              <Col>
                                <Form.Label>: {productHistory.total_quantity ? (productHistory.total_quantity).toFixed(2) : 0} {productHistory.unit && productHistory.unit.name_buy}</Form.Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <Row className="align-items-center">
                      <Col
                        xs={4}
                        md={3}
                        lg={3}
                        className="d-flex align-items-center text-left"
                      >
                        <Form.Label>ค้นหาวันที่</Form.Label>
                      </Col>
                      <Col xs={8} md={9}>
                        <RangePicker
                          ranges={{
                            Today: [moment(), moment()],
                            'This Month': [
                              moment().startOf('month'),
                              moment().endOf('month'),
                            ],
                          }}
                          separator="-"
                          onChange={(date) => onChangeDate(date)}
                          placeholder=""
                          suffixIcon={<img src={Calendar} />}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Row className="align-items-center">
                      <Col className="mt-2 mb-3">
                        <Select
                          options={level.middle === true ? OptionUpdate : OptionUpdateBranch}
                          isClearable={false}
                          isSearchable={false}
                          value={OptionUpdate.find( item =>  { return item.value === search.Type})}
                          onChange={(data) => setSearch({...search, Type: data.value})}
                          className="select-list"
                          placeholder="ประเภทอัปเดท"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} lg={2}>
                    <Row className="align-items-center">
                      <Col className="mt-2 mb-3">
                        <Button
                          className="button-menu"
                          type='button'
                          onClick={() => {handleAdjustStockShow();setModalAdjustStock(productHistory);
                            getUnitSale(productHistory.unit_id)
                            getparamsAuth({name: ['ปรับ/ตัด(stock)'],permission : 'add'});
                            getNewStockCode('adjust');
                          }}
                        >
                          <img src={Arrow} /> &nbsp; ปรับสต็อก
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} lg={2}>
                    <Row className="align-items-center">
                      <Col className="mt-2 mb-3">
                        <Button
                            className="button-menu"
                            type='button'
                            onClick={() => {handleCutStockShow();setModalCutStock(productHistory)
                              getUnitSale(productHistory.unit_id)
                              getparamsAuth({name: ['ปรับ/ตัด(stock)'],permission : 'add'});
                              getNewStockCode('cut_stock');
                            }}
                          >
                            <img src={Document} /> &nbsp; ตัดสต็อก
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th className="text-left">วันเวลา</th>
                          <th>เลขที่ Job</th>
                          <th>ประเภทการอัพเดท</th>
                          <th>จำนวน</th>
                          <th>หน่วยการใช้</th>
                          <th>คงเหลือ</th>
                          <th>หมายเหตุ</th>
                          <th className="text-left">ผู้อัพเดท</th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                          {
                            productHistoryStocks.map((stock,index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-left">{dayjs(stock.stock_date).format('DD-MM-YYYY HH:mm')}</td>
                                  <td>{stock.code}</td>
                                  <td>{typeTh(stock.type,stock.import_type,stock)}</td>
                                  <td>{stock.quantity}</td>
                                  <td>{stock.unit_sale && stock.unit_sale.name_sale ? stock.unit_sale.name_sale : stock.unit && stock.unit.name_buy}</td>
                                  <td>{stock.sum_running_quantity ? stock.sum_running_quantity.toFixed(2) : ''}</td>
                                  <td>{stock.note}</td>
                                  <td className="text-left">{getUpdateBy(stock)}</td>
                              </tr>
                              );
                            })
                          }
                        
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          placeholder=""
                          options={Pages}
                          onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                  <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> : ''
                      } */}
                      {
                        !isLoading ? 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>



        <Modal
          className="modal-adjuststock"
          centered
          show={showAdjustStock}
          onHide={handleAdjustStockClose}
        >
          <Modal.Header className="modal-header-adjuststock">
            <p>
              <b>ปรับสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeAdjustStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>{Data.Code}</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{Data.Name}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                   <p>{ Data.TotalQuantity && Data.TotalQuantity.toFixed(2) } {Data.UnitName}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 justify-content-start">
                  <Col xs={6} lg={8} className="">
                    {['radio'].map((type) => (
                      <Row
                        key={`inline-${type}`}
                        className="justify-content-start"
                      >
                        <Col xs={6} lg={4} className="d-flex">
                          <Form.Check
                            inline
                            label="รับเข้า"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                            // onChange={(e) => setAdmit(e.target.value)}
                            onChange={
                              (e) => SetData({...Data, Admit: e.target.value,TakeOut: false })
                            }
                            checked={Data.Admit}
                          />
                        </Col>
                        <Col xs={6} lg={3} className="d-flex">
                          <Form.Check
                            inline
                            label="นำออก"
                            name="group1"
                            type={type}
                            id={`inline-${type}-2`}
                            onChange={
                              (e) => SetData({...Data, Admit: false, TakeOut: e.target.checked })
                            }
                            checked={Data.takeOut}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>หน่วย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={unitSales}
                      getOptionLabel={(option) => option.name_sale}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, UnitSaleId: e.id })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      className="form-input"
                      value={commaCase(Data.Quantity)}
                      onFocus={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={
                        (e) => SetData({...Data, Quantity: e.target.value.replaceAll(',', '') })
                      }
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    lg={2}
                    className="d-flex align-items-center text-left  "
                  >
                    <Form.Label>นับโดย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list set-respon"
                      aria-label="Default select example"
                      placeholder=""
                      options={employees}
                      getOptionLabel={(option) => option.fullname}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, CountBy: e.id })
                      }
                    />
                  </Col>
                </Row>

                {
                  // Report === true ? <p style={{color: 'red'}}>จำนวนคงเหลือไม่พอ</p> : ''
                }

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นับสต็อก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      format={dateFormat}
                      value={Data.StockDate}
                      onChange={
                        (e) => SetData({...Data, StockDate: e })
                      }
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                    
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    {/* { Data.Reason} */}
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={causeAdjust}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={
                        (e) => SetData({...Data, reasonType: e.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                      value={Data.Note}
                      onChange={
                        (e) => SetData({...Data, Note: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {StockCode}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ปรับ</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {dayjs().format('DD-MM-YYYY')}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {profile.fullname}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" onClick={saveStorageList} className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button"  onClick={closeAdjustStock} className="button">
                      <img src={getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>


        <Modal
          className="modal-cutstock"
          centered
          show={showCutStock}
          onHide={handleCutStockClose}
        >
          <Modal.Header className="modal-header-cutstock">
            <p>
              <b>ตัดสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeCutStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>{Data.Code}</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{Data.Name}</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>{ Data.TotalQuantity && Data.TotalQuantity.toFixed(2) } {Data.UnitName}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวนนำออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      className="form-input"
                      value={commaCase(Data.Quantity)}
                      onFocus={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null })
                      }
                      onBlur={
                        (e) => SetData({...Data, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 })
                      }
                      onChange={
                        (e) => SetData({...Data, Quantity: e.target.value.replaceAll(',', '') })
                      }
                      onKeyPress={(event) => {
                        if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                          event.preventDefault();
                        }
                      }}
                    ></Form.Control>
                  </Col>
                  {
                    // Report === true ? <p style={{color: 'red'}}>จำนวนคงเหลือไม่พอ</p> : ''
                  }
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>หน่วย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={unitSales}
                      getOptionLabel={(option) => option.name_sale}
                      getOptionValue={(option) => option.id}
                      onChange={
                        (e) => SetData({...Data, UnitSaleId: e.id })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นำสินค้าออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      format={dateFormat}
                      value={Data.StockDate}
                      onChange={
                        (e) => SetData({...Data, StockDate: e })
                      }
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={causeAdjust}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={
                        (e) => SetData({...Data, reasonType: e.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                      value={Data.Note}
                      onChange={
                        (e) => SetData({...Data, Note: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: {StockCode}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ตัด</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {dayjs().format('DD-MM-YYYY')}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                  <p>: {profile.fullname}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" className="button" onClick={saveStorageList}>
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button"  onClick={closeCutStock} className="button">
                      <img src={getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>


      </Container>
      <Footer />
    </>
  );
}
