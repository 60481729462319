import axios, {post, put} from 'axios';

export const LoadAppointment = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/appointments`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindAppointment = (id) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/appointments/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateAppointment = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/appointments`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateAppointment = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/appointments/${id}`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateAppointmentStatus = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/appointments_status/${id}`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateAppointmentLog = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/appointments/${id}/create-log`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};