import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory , Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Modal,
  ToastContainer, 
  Toast
} from 'react-bootstrap';

// Custom Component
import Footer from 'Components/Footer';
import TabsIcon from 'Components/layout/TabsIcon';
import MyPagination from 'Components/MyPagination'

// Icon
import Back from 'assets/icon/back.png';
import Edit from 'assets/icon/key-fix.png';
import Add from 'assets/icon/add_menu.png';
import Cancel from 'assets/icon/delete.png';
import Setting from 'assets/icon/setting.png';

// API
import { CategoryList, CreateCategory, UpdateCategory, DestroyCategory } from 'actions/settings/category';
import { loadAuth } from 'actions/auth';

export default function CategoryAndService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const model = {
    name: null
  }
  const [Data, setData] = useState(model);

  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Rows, setRows] = useState(0);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });
  const [errors, setErrors] = useState([]);

  const [Categories, setCategories] = useState([]);

  const GetCategory = async (data) => {
    setisLoading(true)
    const params = {
      limit: Search.PerPage,
      page: Search.CurrentPage
    }
    try {
      const response = await dispatch(CategoryList(params));
      if (response) {
        if (Search.PerPage) {
          const categories = response.data.data.data.map(obj=> ({ ...obj, isEdit: false }))
          setCategories(categories);
          setRows(response.data.data.last_page);
          setisLoading(false)
        }else{
          const categories = response.data.data.map(obj=> ({ ...obj, isEdit: false }))
          setCategories(categories);
          setRows(0);
          setisLoading(false)
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    try {
      const response = await dispatch(CreateCategory(Data));
      if(response){
        if(response.data.data === 'Unauthorized'){
          window.location.reload(false);
          alert(response.data.message)
          history.push('/setting/category');
          GetCategory();
        }
        setData(model);
        setValidated(false);
        GetCategory();
        window.location.reload(false);
        
      }else{
        console.log('failed')
        console.log('error', response)

      }
    } catch (error) {
      console.error(error);
      console.log(error.response.data.message.name) 
      let data = error.response&&error.response.data&&error.response.data.message&&error.response.data.message.name;
      data.filter(i => {
        console.log(i === 'The name has already been taken.')
        if (i === 'The name has already been taken.') setShowAlert(true);
        else setShowAlert(false);
      })

      console.log('data', Data)
      console.log('Categories', Categories)

    } finally {
      console.log('finally')
    }
  };

  const ToggleEdit = async (index, category) => {
    let items = [ ...Categories ];
    items[index] = {...items[index], isEdit: !category.isEdit};
    setCategories(items);

    if (category.isEdit) {
      const form = {
        id: category.id,
        data: category
      }
      try {
        const response = await dispatch(UpdateCategory(form));
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          history.push('/setting/category');
          GetCategory();
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
    }
  }

  function SetEditValue(index, value) {
    let items = [ ...Categories ];
    items[index] = {...items[index], name: value};
    setCategories(items);
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'view'});
    GetCategory();
  }, [Search.PerPage, Search.CurrentPage]);

  function close() {
    let path = `/setting`;
    history.push(path);
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);
  // const [errors, setErrors] = useState([]);

  function deleteConfirmation(department) {
    setDeleting(department)
    setConfirmation(true)
  }

  const DeleteCategory = () => {
    dispatch(DestroyCategory(deleting.id)).then( response => {
      GetCategory()
      setConfirmation(false)
      setDeleting(null)
    }).catch(e => {
      alert('หมวดหมู่นี้ถูกใช้งานอยู่');
      console.log(e)
    })
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <ToastContainer position='top-end'>
          {
            errors?.map((error, key) => {
              return (
                <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                  <Toast.Header>
                    <strong className="me-auto">แจ้งเตือน</strong>
                  </Toast.Header>
                  <Toast.Body>{ error }</Toast.Body>
                </Toast>
              )
            })
          }
        </ToastContainer>
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; หมวดหมู่สินค้าและบริการ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; หมวดหมู่สินค้าและบริการ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} md={9} lg={4}>
                          <Form.Control 
                            placeholder="หมวดหมู่สินค้าและบริการ"
                            value={Data.name ? Data.name : ''}
                            required
                            onChange={(e) => setData({...Data, name: e.target.value}) }
                          ></Form.Control>
                        </Col>
                        <Col md={3} lg={3} xl={2}>
                          <Button type="submit" className=" button" >
                            <img src={Add} /> &nbsp; เพิ่มรายการ
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                
                <Row className='mt-4 g-0'>
                  <Col>
                  { showAlert === true ? 
                    <Alert variant="danger" onClose={() => setShowAlert(false)} >
                      <p className='text-left'>
                        ชื่อนี้ถูกใช้ไปแล้ว
                        </p>
                        <Button className='button-alert button-image text-dark ' onClick={() => setShowAlert(false)}>X</Button>
                    </Alert>
                      : null}
                  </Col>
                </Row>
                <div className="set-height">
                  <Row className="g-0  justify-content-center">
                    <Row className="">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">ชื่อหมวดหมู่สินค้าและบริการ</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            Categories?.map((category, key) => {
                              return(
                                <tr className="t-tr-setting">
                                  <td className="text-left">
                                    {
                                      category.isEdit ?
                                      <Form.Control 
                                        required
                                        value={category.name ? category.name : ''}
                                        onChange={(e) => SetEditValue(key, e.target.value)}
                                      ></Form.Control>
                                      : `${category.name}`
                                    }
                                  </td>
                                  <td className="text-left">
                                    <Button
                                      type="button"
                                      className="button-image mr-2"
                                      onClick={() => ToggleEdit(key, category)}
                                    >
                                      <img src={Edit} />
                                    </Button>
                                    &nbsp; &nbsp;
                                    <img src={Cancel} onClick={ () => deleteConfirmation(category)} style={{ cursor: 'pointer' }}/>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          isSearchable={false}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) => setSearch({...Search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e}) }}></MyPagination> : ''
                      } */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบหมวดหมู่ { `${deleting?.name}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบหมวดหมู่ { `${deleting?.name}` }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteCategory() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
