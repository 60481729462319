import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
} from "react-bootstrap";
// import Service from "assets/icon/management.png";
import Footer from "Components/Footer";

import { useHistory, Link } from "react-router-dom";
import TabsIcon from "../Components/layout/TabsIcon";

import Back from "assets/icon/back.png";
import Select, { components } from "react-select";
import MenuBill from "../Components/layout/MenuBill";
import Search from "assets/icon/search.png";
import SearchImage from 'assets/icon/search.png';
import Return from "assets/icon/return-value.png";
import Money from "assets/icon/money-bill.png";
import "assets/scss/style-component.scss";
import { useDispatch } from 'react-redux';
import BillImage from 'assets/icon/job.png'

import MyPagination from 'Components/MyPagination';

//API
import { BillList, DebtorStatus } from 'actions/bill'
import { loadAuth } from 'actions/auth';
import dayjs from "dayjs";
//
export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCutStock, setShowCutStock] = useState(false);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: "", label: "" }]);

  const [page, setPage] = useState({ value: "", label: "" });

  const [Search, setSearch] = useState({
    Keyword: '',
    StatusPay: 'pending',
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [button_Search, setButton_Search] = useState("");
  const [Bills,setBills] = useState([]);

  const [statusBill, setStatusBill] = useState({ value: "", label: "" });
  const [isLoading, setLoading] = useState(false);
  const OptionPage = [
    { value: "ทั้งหมด", label: "ทั้งหมด" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
  ];
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);
  function SetPage(data) {
    setPage({ value: data.value, label: data.value });
  }
  function close() {
    let path = `/`;
    history.push(path);
  }

  const OptionsStatus = [
    {
      value: "pending",
      label: "รอชำระ",
    },
    {
      value: "discount_approved",
      label: "รออนุมัติส่วนลด",
    },
    {
      value: "paid",
      label: "ชำระแล้ว",
    },
  ];

  const getAuth = async () => {
    try {
      const params = {
        name: ['Bill'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function BillStatusTextTh(data){
    switch (data) {
      case 'pending':
        return 'รอชำระ'
      case 'discount_approved':
        return 'รออนุมัติส่วนลด'
      case 'paid':
        return 'ชำระแล้ว'
      case 'cancel':
        return 'ยกเลิก'
    
      default:
        break;
    }
  }

  // const reset_value = () => {
  //   setSearch("");
  //   setType("");
  //   setCategory("");
  // };

  async function getBills(){
    setLoading(true)
      try {
        const params = {
          keyword: Search.Keyword,
          limit: Search.PerPage,
          page: Search.Keyword === '' ? Search.CurrentPage : 1,
          status_pay: Search.StatusPay,
        };
        const response = await dispatch(BillList(params));
        
        if(response.data.data && response.data.data.data){
          const bills = response.data.data.data
          setBills(bills);
          setSearch({ ...Search, 
            Rows: response.data.data.last_page,
            CurrentPage: response.data.data.current_page 
          })
          setLoading(false);
        }else{
          const bills = response.data.data
          setBills(bills);
          setSearch({ ...Search, 
            Rows: 0,
            CurrentPage: 1 
          })
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({
      Keyword: '',
      StatusPay: 'pending',
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
    });
    setClearSearch(true);
  }

  useEffect(() => {
    getBills()
    getAuth()
  }, [Search.PerPage, Search.CurrentPage, ClearSearch , ]);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  async function checkBoxBill(item, id){
    try {
      let status = item === true ? true : false;
      const params = {
        debtor_status: status,
        id: id,
      };
      const response = await dispatch(DebtorStatus(params));
      if(response.data.message === 'success'){
        alert('แก้ไขสถานะลูกหนี้แล้ว');
        getBills();
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Job Bill bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b> Bill</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b> Bill</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0 justify-content-start">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อลูกค้า,ชื่อโชว์รูม,เลขทะเบียน,ตัวถัง"
                      // defaultValue={statepage[0]}
                      value={Search.Keyword ? Search.Keyword : ''}
                      onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                      className="input"
                    ></Form.Control>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    {/* <Select
                      className=""
                      aria-label="Default select example"
                      placeholder="สถานะ "
                      options={OptionsStatus}
                    /> */}
                    <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="สถานะ "
                          options={OptionsStatus}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          defaultValue={{ value: 'penging', label: 'รอชำระ' }}
                          onChange={
                            (e) => {setSearch({...Search, StatusPay: e.value });}
                          }
                        />
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu"  onClick={getBills}>
                      <img src={SearchImage} />
                      <p>ค้นหา</p>
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={ResetSearch}>
                      <img src={Return} />
                      <p>คืนค่า</p>
                    </Button>
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center mt-3 m-0">
                  <Col className="set-table p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th className="text-left">เลขที่บิล</th>
                          <th className="text-left">เลขที่ JOB</th>
                          <th className="text-left">วันที่</th>
                          {/* <th className="text-left">รหัสลูกค้า</th> */}
                          <th className="text-left"> ชื่อลูกค้า</th>
                          <th className="text-left">ชื่อผู้ชำระ</th>
                          <th className="text-left">พนักงานที่ดูแล</th>
                          <th className="text-left">ทะเบียนรถ </th>
                          <th className="text-left">ข้อมูลรถ</th>
                          <th className="text-left">ยอดรวม</th>
                          <th className="text-left">การชำระ</th>
                          <th className="text-left">ลูกหนี้</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                      {
                          Bills?.map((item,index) => {
                            return(
                              <tr>
                                <td className="text-left">{item.bill_code}</td>
                                <td className="text-left">{item.job && item.job.job_code}</td>
                                <td className="text-left">{dayjs(item.created_at).format('DD-MM-YYYY')}</td>
                                {/* <td className="text-left">{item.job && item.job.customer && item.job.customer.customer_code}</td> */}
                                <td className="text-left">
                                  {
                                    item.job.car_owner.cus !== null ?
                                      item.job.car_owner.cus.first_name === null && item.job.car_owner.cus.last_name === null ?
                                        item.job.car_owner.cus.organization !== null ?
                                          item.job.car_owner.cus.organization
                                        :
                                        '-'
                                      :
                                      item?.job.car_owner.cus?.first_name + ' ' + item?.job.car_owner.cus?.last_name +'('+item?.job.car_owner.cus?.nickname+')'
                                    : '-'
                                  }
                                  {/* { `${item.job && item.job.customer && item.job.customer.first_name} ${item.job && item.job.customer && item.job.customer.last_name} (${item.job && item.job.customer && item.job.customer.nickname})` } */}
                                </td>
                                <td className="text-left">
                                    {
                                      item.job.type !== 'show_cost' ?
                                        item.job.car_owner.cus !== null ?
                                          item.job.car_owner.cus.first_name === null && item.job.car_owner.cus.last_name === null ?
                                            item.job.car_owner.cus.organization !== null ?
                                              item.job.car_owner.cus.organization
                                            :
                                            '-'
                                          :
                                            item?.job.car_owner.cus?.first_name + ' ' + item?.job.car_owner.cus?.last_name +'('+item?.job.car_owner.cus?.nickname+')'
                                        : '-'
                                      : item.job.showroom_contact !== null ?
                                          item.job.showroom_contact.customer.organization
                                        : item.job.create_by.employee.fullname

                                    }
                                  {/* { `${item.job && item.job.payer_by && item.job.payer_by.first_name} ${item.job && item.job.payer_by && item.job.payer_by.last_name} (${item.job && item.job.payer_by && item.job.payer_by.nickname})` } */}
                                </td>
                                
                                <td className="text-left">
                                  {
                                    item.job.create_by.employee.fullname
                                  }
                                </td>
                                
                                <td className="text-left">
                                  {`${item.job && item.job.car_owner && item.job.car_owner.license_plate} ${item.job && item.job.car_owner 
                                    && item.job.car_owner && item.job.car_owner.province && item.job.car_owner.province.name_th}`}
                                </td>
                                <td className="text-left">{`${item.job && item.job.car_owner && item.job.car_owner.car_serie && item.job.car_owner.car_serie.car_model
                                  && item.job.car_owner.car_serie.car_model.car_brand && item.job.car_owner.car_serie.car_model.car_brand.name} 
                                  ${item.job && item.job.car_owner && item.job.car_owner.car_serie && item.job.car_owner.car_serie.car_model
                                  && item.job.car_owner.car_serie.car_model.model} 
                                  ${item.job && item.job.car_owner && item.job.car_owner.car_serie && item.job.car_owner.car_serie.year}`} 
                                </td>
                                <td className="text-left">{item.job && commaCase(item.job.total_price)}</td>
                                <td className="text-left">{BillStatusTextTh(item.status_pay)}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    style={{transform: 'scale(1.7)'}}
                                    checked={item.debtor_status === 1 ? true : false}
                                    onChange={(e) => checkBoxBill(e.target.checked, item.id)}
                                  ></input>
                                </td>
                                <td className="text-left">
                                  <Link to={`/bill/${item.id}`} className="">
                                    <Button type="button" className="button-package">
                                      <img src={Money} />
                                    </Button>
                                  </Link>
                                </td>
                              </tr> 
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="g-0 mt-3 mb-3">
                  <Col sm={6} md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col sm={5} md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          placeholder="การแสดงผล"
                          options={Pages}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          defaultValue={{ value: 10, label: '10' }}
                          onChange={
                            (e) => {setSearch({...Search, PerPage: e.value });}
                          }
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                  <div className="box-paginate">
                    {
                      !isLoading ? 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                      : 
                        Search.Rows > 1 && Search.PerPage > 0 ?
                          <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                    }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
