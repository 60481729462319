import dayjs from 'dayjs';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import PenddingApproved from'assets/icon-job/pending_approved.png';
import Pending from'assets/icon-job/pending.png';
import Approved from'assets/icon-job/approved.png';
import Rejected from'assets/icon-job/rejected.png';
import Done from'assets/icon-job/done.png';

export default function StatusLog(props) {

  function getIconStatus(data){
    switch (data) {
      case 'รอดำเนินการ':
        return  <><img src={Pending} className="icon-job" /><label>รอดำเนินการ</label></>
        case 'รออนุมัติ':
        return <><img src={PenddingApproved} className="icon-job" /><label>รออนุมัติ</label></>
        case 'อนุมัติ':
        return <><img src={Approved} className="icon-job" /><label>รอดำเนินการ</label></>
        case 'ยกเลิก':
        return <><img src={Rejected} className="icon-job" /><label>ยกเลิก</label></>
        case 'เสร็จสิ้น':
        return  <><img src={Done} className="icon-job" /><label>เสร็จสิ้น</label></>
    
      default:
        break;
    }
  }
  return (
    <>
      <Container fluid className="set-height-order">
        <Row>
          <Col className="set-table" style={{ padding: 0 }}>
            <table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th className="text-left" style={{ width: '15%' }}>
                    วันที่
                  </th>
                  <th>เวลา</th>
                  <th>รายละเอียด</th>
                  <th className="text-left" style={{ width: '15%' }}>
                    สถานะ
                  </th>
                  <th>ผู้ทำรายการ</th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
                {
                  props.JobLogState.map((item,index) => {
                    return (
                        <tr>
                          <td className="text-left">{dayjs(item.created_at).format('DD/MM/YYYY')}</td>
                          <td>{dayjs(item.created_at).format('HH:mm')}</td>
                          <td>{item.detail}</td>
                          <td className="text-left">{getIconStatus(item.status_text)} </td>
                          <td>{item.user && item.user.employee && item.user.employee.fullname }</td>
                        </tr> 
                    )
                  })
                }
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
