import axios, { post, put } from 'axios';

export const PackageTypeList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/package-type`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindPackageType = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/package-type/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const PackageList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/packages`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const FindPackage = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/packages/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const GetFilmModel = (id) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/package_model/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const GetFilmSizeCodeByModel = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/package_size_code_by_model`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreatePackage = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/packages`, data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdatePackage = ({id, formData}) => async () => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/packages/${id}`, formData, config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateB2BPrice = ({id, formData}) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/package-b2b-price/${id}`, formData
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdatePackageStatus = ({id, data}) => async () => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/packages/${id}/status`, data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyPackage = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/packages/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyPackageB2B = (id) => async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/packages/${id}/b2b`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DeleteB2bProduct = (data) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/packages/delete-products`, data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};