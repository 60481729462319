import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Footer from 'Components/Footer';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import ReportImage from 'assets/icon/report-menu.png'
import Back from 'assets/icon/back.png';

import { useDispatch, useSelector } from 'react-redux';
//API
import { loadAuth } from 'actions/auth';





export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { employee } = useSelector( (state) => state.user)

  const permission = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
  const { log_permissions } = useSelector( (state) => state.user.permissions)
  const report_permission = log_permissions.find(item => item.permission.name === 'รายงาน' && item.middle === permission.middle)


  const getAuth = async () => {
    try {
      const params = {
        name: ['รายงาน'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      history.push('/');
      console.error(error);
    }
  };


  useEffect( () =>{
    getAuth()
  },[]);

  function close() {
    history.push(`/`);
  }

  function linkTo(url){
    history.push(url)
  }
  return (
    <>
      
      <Container
        fluid
        className="package-container Service Stock Bill Report bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b> รายงาน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={ReportImage} />
                  <b> รายงาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="g-0 set-body-height">
                  {
                    permission.middle === true ? (
                      <Col xs={12} className="">
                        <div className="bg-white mt-3 mb-3">
                          <Col className="col-title text-left d-flex align-items-center">
                            <Form.Label onClick={(() => {linkTo('/report/all-branch-summary-report')})} className="font-weight-bold">
                              รายงานสรุปยอดรวมสาขา (ทุกสาขา)
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/all-branch-sales-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานการทำงานของครอบครัว (MRKfam) 
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/product-sales-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดขายสินค้า
                            </Form.Label>
                          </Col>
                          <Col className="col-text text-left d-flex align-items-center">
                            <Form.Label onClick={(() => {linkTo('/report/customer-service-list')})} className="font-weight-bold">
                              รายการการใช้บริการของลูกค้า
                            </Form.Label>
                          </Col>
                          {/* <Col className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายการการใช้บริการของลูกค้า
                            </Form.Label>
                          </Col> */}
                          <Col onClick={(() => {linkTo('/report/car-service-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานการใช้บริการของรถ
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/showroom-sales-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดขายของ Showroom
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/technician-queue-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานคิวช่างกระจก
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/product-cost-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานต้นทุนสินค้า
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/income-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานสรุปยอดขาย 
                            </Form.Label>
                          </Col>
                          {/* <Col onClick={(() => {linkTo('/report/accrued-balance-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดค้างจ่าย (สำหรับให้ owner ดูรายละเอียด)			
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/sales-report-for-peak')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดขาย (สำหรับลง Peak account)				
                            </Form.Label>
                          </Col> */}
                        </div>
                      </Col>
                    ) : (
                      <Col xs={12} className="">
                        <div className="bg-white mt-3 mb-3">
                          <Col className="col-title text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              ระบบสาขา
                            </Form.Label>
                          </Col>
                          <Col className="col-title text-left d-flex align-items-center">
                            <Form.Label onClick={(() => {linkTo('/report/all-branch-summary-report')})} className="font-weight-bold">
                              รายงานสรุปยอดรวมสาขา
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/branch-sales-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดขายพนักงาน
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/stock')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold" >
                              รายงาน Stock
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/income-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานสรุปยอดขาย 
                            </Form.Label>
                          </Col>
                          {/* <Col onClick={(() => {linkTo('/report/accrued-balance-report')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดค้างจ่าย (สำหรับให้ owner ดูรายละเอียด)			
                            </Form.Label>
                          </Col>
                          <Col onClick={(() => {linkTo('/report/sales-report-for-peak')})} className="col-text text-left d-flex align-items-center">
                            <Form.Label className="font-weight-bold">
                              รายงานยอดขาย (สำหรับลง Peak account)				
                            </Form.Label>
                          </Col> */}
                        </div>
                      </Col>
                    )
                  }
                  
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
