import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Dropdown,
  Form,
  Table,
  Alert,
  Modal
} from 'react-bootstrap';
import Footer from 'Components/Footer';

import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import Service from 'assets/icon/management.png';

import Delete from 'assets/icon/deletes.png';
import Increase from 'assets/icon/add-staff.png';
import { Switch } from 'antd';
import 'antd/dist/antd.css';

import Save from 'assets/icon/new-save.png';
import Turnoff from 'assets/icon/new-re-turn.png';
import Select, { components } from 'react-select';

//api 
import {LoadProductCategories} from 'actions/product_category'
import {LoadService,UpdateService, DestroyService} from 'actions/service'
import { loadAuth } from 'actions/auth';
import { ProductList } from 'actions/product';


export default function EditService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const productRef = useRef(null)
  const productRefScroll = () => productRef.current.scrollIntoView()
  let { id } = useParams();

  const [isLoading, setLoading] = useState(true);

  const [Options, setServicesOptions] = useState([]);
  const [service, setService] = useState([{ value: '', label: '' }]);

  const [validated, setValidated] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState([]);

  const [statusService, setStatusService] = useState(false);
  const [ValidatedProduct, setValidatedProduct] = useState(false);

  const [ form, setForm ] = useState({})
  // const [ errors, setErrors ] = useState({})
  const [Errors, setErrors] = useState([])
  const setField = (field, value) => {
    console.log(field, value)
    setForm({...form,[field]: value })
    // Check and see if errors exist, and remove them from the error object:
    // if ( !!errors[field] ) setErrors({
    //   ...errors,
    //   [field]: null
    // })
  }

  const statusServiceToggler = () => {
    statusService ? setStatusService(false) : setStatusService(true);
  };

  function SetService(data) {
    // console.log('SetService',data);
    setService({ value: data.value, label: data.value });
  }

  const [switchFrom, setSwitchFrom] = useState(false);
  const statusSwitchFrom = () => {
    switchFrom ? setSwitchFrom(false) : setSwitchFrom(true);
  };

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการบริการ'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };
  
  const [Products, setProducts] = useState([]);
  const [UnitSales, setUnitSales] = useState([])
  const [ProductEditMode, setProductEditMode] = useState(false);

  const ProductSizesModel = [
    {
      key: 0,
      size: 'S',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 1,
      size: 'M',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 2,
      size: 'L',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 4,
      size: 'SUV',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 6,
      size: 'XL',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 7,
      size: 'VAN',
      quantity: null,
      product_id: null,
      product: null
    }
  ]

  const [ProductSizes, setProductSizes] = useState(ProductSizesModel);

  const GetProduct = (exists) => {
    dispatch(ProductList({ has_sales: true })).then(response => {
      setProducts(response.data.data.filter(value => !exists.includes(value.id) ));
    }).catch(e => {
      console.error(e);
    })
  }

  useEffect(() => {
    GetService();
    GetServiceOptions();
    getAuth();
  }, []);

  function closePackage() {
    let path = `/`;
    history.push(path);
  }
  async function GetService(){
    setLoading(true)
    try {
      const response = await dispatch(LoadService(id));
      const res_service = response.data.data
      setSwitchFrom(res_service.active_sale)
      setStatusService(res_service.active)
      SetService({ value: res_service.product_category_id , label: res_service.product_category.name})
   
      const t = []
      var element = {}
      if(res_service.service_sale_type.length > 0){
        res_service.service_sale_type.forEach(item => {
          switch (item.size) {
            case 'S':
              element.car_size_S = item.amount;
              element.time_car_size_S = item.processing_time;
              break;
            case 'M':
              element.car_size_M = item.amount;
              element.time_car_size_M = item.processing_time;
              break;
            case 'L':
              element.car_size_L = item.amount;
              element.time_car_size_L = item.processing_time;
              break;
            case 'XL':
              element.car_size_XL = item.amount;
              element.time_car_size_XL = item.processing_time;
              break;
            case 'VAN':
              element.car_van = item.amount;
              element.time_car_van = item.processing_time;

              break;
            case 'TRUCK CAB':
              element.car_truck_cab = item.amount;
              element.time_car_truck_cab = item.processing_time;
              break;
            case 'TRUCK 4 DOOR':
              element.car_truck4Door = item.amount;
              element.time_car_truck4Door = item.processing_time;
              break;
            case 'SUV':
              element.car_suv = item.amount;
              element.time_car_suv = item.processing_time;
              break;
            default:
              break;
          }
         
        });
        t.push(element)
      }

      let detail = t[0]
      setForm({
        package_Name:res_service.name,
        price_all: res_service.all_price,
        runtime: res_service.processing_time,
        car_size_S: detail && detail.car_size_S ? detail.car_size_S : null,
        car_size_M: detail && detail.car_size_M ? detail.car_size_M : null,
        car_size_L: detail && detail.car_size_L ? detail.car_size_L : null,
        car_size_XL: detail && detail.car_size_XL ? detail.car_size_XL : null,
        car_van: detail && detail.car_van ? detail.car_van : null,
        car_truck_cab: detail && detail.car_truck_cab ? detail.car_truck_cab : null,
        car_truck4Door: detail && detail.car_truck4Door ? detail.car_truck4Door : null,
        car_suv: detail && detail.car_suv ? detail.car_suv : null,

        time_car_size_S : detail && detail.time_car_size_S ? detail.time_car_size_S : null,
        time_car_size_M : detail && detail.time_car_size_M ? detail.time_car_size_M : null,
        time_car_size_L : detail && detail.time_car_size_L ? detail.time_car_size_L : null,
        time_car_size_XL : detail && detail.time_car_size_XL ? detail.time_car_size_XL : null,
        time_car_van : detail && detail.time_car_van? detail.time_car_van : null,
        time_car_truck_cab : detail && detail.time_car_truck_cab ? detail.time_car_truck_cab : null,
        time_car_truck4Door : detail && detail.time_car_truck4Door ? detail.time_car_truck4Door : null,
        time_car_suv : detail && detail.time_car_suv ? detail.time_car_suv : null,

      })

      let exist_product = []
      let pq = []
      let products = []
      res_service.service_products.forEach(item => {

        if (!exist_product.includes(item.product_id)) {
          exist_product.push(item.product_id)
          let select_products = res_service.service_products.filter(value => value.product_id === item.product_id);
          let obj = {}

          select_products.forEach((element, key) => {
            obj[element.size] = Number(element.quantity) || null
            obj.product = element.product
            obj.product_id = element.product_id
            obj.unit_sale_id = element.unit_sale_id
            obj.unit_sale = element.unit_sale
            obj.ref = select_products
            element.ref = select_products
            element.key = key
            element.unit_sale = element.unit_sale
            products.push(element)
          });
          
          obj.key = pq.length
          pq.push(obj);
        }
      });

      GetProduct(exist_product)

      setProductTable(pq)
      setProductData(products)

      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

  }

  async function GetServiceOptions(){
    setLoading(true)
    try {
     
      const response = await dispatch(LoadProductCategories());
      const options = []
      // const new_arr = 
      response.data.data.map(item => {
        const data = {
          value: item.id,
          label: item.name
        }
        options.push(data);
      })
      setServicesOptions(options);
      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function editService(e){
    e.preventDefault();
    const data = {
      package_Name : form.package_Name,
      service: service.value,
      active_type: switchFrom === true ? 'size' : 'all',
      active: statusService,
      price_all: form.price_all,
      runtime: form.runtime,
      car_size_S: {
        size: 'S',
        amount: form.car_size_S ? form.car_size_S :null,
        time: form.time_car_size_S ? form.time_car_size_S: null,
      },
      car_size_M:{
        size: 'M',
        amount: form.car_size_M ? form.car_size_M :null,
        time: form.time_car_size_M ? form.time_car_size_M :null
      },
      car_size_L:{
        size: 'L',
        amount:  form.car_size_L ? form.car_size_L :null,
        time: form.time_car_size_L? form.time_car_size_L :null
      },
      car_size_XL:{
        size: 'XL',
        amount: form.car_size_XL? form.car_size_XL :null,
        time: form.time_car_size_XL? form.time_car_size_XL :null
      },
      car_van:{
        size: 'VAN',
        amount: form.car_van ? form.car_van :null,
        time: form.time_car_van ? form.time_car_van :null
      },
      car_truck_cab:{
        size: 'TRUCK CAB',
        amount: form.car_truck_cab ? form.car_truck_cab :null,
        time: form.time_car_truck_cab ? form.time_car_truck_cab :null
      },
      car_truck4Door:{
        size: 'TRUCK 4 DOOR',
        amount: form.car_truck4Door ? form.car_truck4Door :null,
        time: form.time_car_truck4Door ? form.time_car_truck4Door :null
      },
      car_suv:{
        size: 'SUV',
        amount: form.car_suv ? form.car_suv :null,
        time: form.time_car_suv ? form.time_car_suv :null
      }
    }
    

      let formData = new FormData();
      formData.append('name', data.package_Name);
      formData.append('product_category_id', data.service);
      if(data.active_type === 'all')formData.append('all_price', data.price_all);
      if(data.active_type === 'all')formData.append('all_processing_time', data.runtime);
      formData.append('active', data.active);
      formData.append('sale_type', data.active_type )
      formData.append('active_sale', switchFrom )
  
      // make list start
      const detail = [];
      if(data.active_type === 'size' && data.car_size_S.amount && data.car_size_S.time) detail.push(data.car_size_S)
      if(data.active_type === 'size' && data.car_size_M.amount && data.car_size_M.time) detail.push(data.car_size_M)
      if(data.active_type === 'size' && data.car_size_L.amount && data.car_size_L.time) detail.push(data.car_size_L)
      if(data.active_type === 'size' && data.car_size_XL.amount && data.car_size_XL.time) detail.push(data.car_size_XL)
      if(data.active_type === 'size' && data.car_van.amount && data.car_van.time) detail.push(data.car_van)
      if(data.active_type === 'size' && data.car_truck_cab.amount && data.car_truck_cab.time) detail.push(data.car_truck_cab)
      if(data.active_type === 'size' && data.car_truck4Door.amount && data.car_truck4Door.time) detail.push(data.car_truck4Door)
      if(data.active_type === 'size' && data.car_suv.amount && data.car_suv.time) detail.push(data.car_suv)
      // make list end

      if(data.active_type === 'size' && detail.length > 0){
  
        for (let i = 0; i < detail.length; i++) {
          formData.append(`sale_type_detail[${i}]`, JSON.stringify(detail[i]));
        }
      }

      if(ProductData && ProductData.length > 0){
        console.log('ProductData', ProductData)
        let i = 0;
        let key = 0;
        while (i < ProductData.length){
            let product = ProductData[i]
            if(product.id) formData.append(`products[${key}][id]`, product.id);
            if(product.service_id) formData.append(`products[${key}][service_id]`, product.service_id);
            formData.append(`products[${key}][size]`, product.size);
            if(product.quantity) formData.append(`products[${key}][quantity]`, product.quantity);
            formData.append(`products[${key}][product_id]`, product.product_id);
            formData.append(`products[${key}][unit_sale_id]`, product.unit_sale_id);
            key++;
          i++;
        }
      }

      formData.append('_method', 'put');

      dispatch(UpdateService({id, formData})).then(response => {
        setErrors([])
        history.push('/service');
      }).catch(e => {
        let errors = []
        if (e.response && e.response.data && e.response.data.messages) {
          e.response.data.messages.forEach(element => {
            errors.push(element)
          });
          setErrors(errors)
        }
      })
  }

  const removeService = () => {
    dispatch(DestroyService(id)).then( response => {
      history.push('/service');
    }).catch((e) => {
      console.log(e)
    })
  };

  const [ProductId, setProductId] = useState(null);
  const [UnitSaleId, setUnitSaleId] = useState(null)
  const [ProductTable, setProductTable] = useState([])
  const [ProductData, setProductData] = useState([])
  
  const InsertProductSize = async (e) => {
    let valid = false
    
    if (!ProductId) valid = true
    if (!UnitSaleId) valid = true
  
    ProductSizes.forEach(product => {
      if (product.quantity === null) {
        valid = true
      }
    });
    if(valid) productRefScroll()
    setValidatedProduct(valid);

    if (!valid) {
      let obj = {}

      let data = []
      ProductSizes.forEach((product, key) => {
        obj[product.size] = Number(product.quantity) || null
        product.product_id = ProductId
        product.unit_sale_id = UnitSaleId
        data.push(product)
      });

      obj.product = Products.find((item) => item.id === ProductId)
      obj.product_id = ProductId
      obj.unit_sale_id = UnitSaleId
  
      setProductTable([...ProductTable, obj])
      setProductData(...ProductData, data)
      setProductSizes(ProductSizesModel)
      setValidatedProduct(false)
      setProducts(Products.filter(item => item.id !== ProductId))
      setProductId(null)
      setUnitSaleId(null)
    }
  }

  const EditProduct = (product) => {
    // console.log('product', product)
    const data = []

    let temp = {}
    ProductSizes.forEach(item => {
      let obj = {}
      if (product.ref) {
        let data = product.ref.find(value => value.size === item.size)
        obj.id = data.id
        obj.service_id = data.service_id
      }
      
      obj.size = item.size
      obj.quantity = product[item.size]
      obj.product_id = product.product_id
      obj.unit_sale_id = product.unit_sale_id
      temp = {
        product_id: product.product_id,
        unit_sale_id: product.unit_sale_id
      }
      obj.product = product.product
      obj.key = data.length
      obj.table_key = product.key
      data.push(obj)
    });

    const sales = product.product.unit_sales
    setUnitSales(sales)
    setUnitSaleId(temp.unit_sale_id)
    setValidatedProduct(false)
    setProductId(product.product_id)
    setProductSizes(data)
    setProductEditMode(true)
  }

  const UpdateProductState = () => {
    let valid = false
    ProductSizes.forEach(product => {
      if (product.quantity) {
        valid = true
      }
    });

    if (valid) {
      let obj = {}
      let edited = []

      ProductSizes.forEach(product => {
        obj[product.size] = Number(product.quantity) || null
        obj.product = product.product
        obj.product_id = product.product_id
        obj.sale_unit_id = product.sale_unit_id
        edited.push(product)
      });

      // delete all old data by product_id
      let filter = ProductData.filter((item) => item.product_id !== obj.product_id);
      let result = filter.concat(edited)
      
      obj.product = obj.product
      obj.product_id = ProductId

      setProductTable([...ProductTable.map(
        el => el.product_id === obj.product_id ? obj : el
      )])

      setProductData(result)
      setProductSizes(ProductSizesModel)
      setProductId(null);
      setUnitSaleId(null);
      setUnitSales(null);
      setProductEditMode(false)
      
    }
    
  }

  const DeleteProduct = (product, key) => {
    setProductTable([...ProductTable.filter((item, index) => index !== key)])
    setProductData([...ProductData.filter((item, index) => item.product_id !== product.product_id)])
    setProducts([...Products, product.product]) 
  }

  function exit() {
    history.push(`/service`);
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  function deleteConfirmation() {
    setDeleting()
    setConfirmation(true)
  }

  return (
    <>
      <Container fluid className="package-container Stock Service bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <img className="logo pr-2" src={Service} />
                    <b>
                      <Link to={`/service`} className='mr-2'>จัดการบริการ</Link>
                      &gt;
                      แก้ไข
                    </b>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closePackage}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>
                    <Link to={`/service`} className='mr-2'>จัดการบริการ</Link>
                    &gt;
                    แก้ไข
                  </b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                {
                  Errors.length > 0 &&
                  <Alert variant="danger">
                    <Alert.Heading>เกิดข้อผิดพลาด !</Alert.Heading>
                    <div className='text-left'>
                    {
                      Errors.map((error, key) => {
                        return(
                          <p>{error}</p>
                        )
                      })
                    }
                    </div>
                  </Alert>
                }
                <Form noValidate validated={validated} onSubmit={editService}>
                  <p className="form-title">รายละเอียดบริการ</p>
                  <Form.Group
                    className="package-data"
                    controlId="formBasicEmail"
                  >
                    <Row className="g-0">
                      <Col
                        xs={2}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        className="text-title"
                      >
                        <p>ชื่อบริการ </p>
                      </Col>
                      <Col xs={10} md={6} lg={5} xl={4}>
                        <Form.Control
                          required
                          type="text"
                          value={form.package_Name}
                          placeholder=""
                          onChange={ e => setField('package_Name', e.target.value) }
                          // isInvalid={ !!errors.package_Name }
                          className="input-middle-price"
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="g-0 mt-2 mb-2">
                      <Col
                        xs={2}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        className="text-title"
                      >
                        <p>หมวดหมู่</p>
                      </Col>
                      <Col xs={10} md={6} lg={5} xl={4}>
                          <Select
                          options={Options}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          isClearable={true}
                          isSearchable={true}
                          value={service.value ? Options.find((data) => {return data.value === service.value}) : null}
                          onChange={(data) => SetService(data)}
                          className="select-list"
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Row>
                    <Col lg={1} className="colum-open-service">
                      <Switch onClick={statusSwitchFrom} checked={switchFrom}/>
                    </Col>
                  </Row>

                  <Row className={(switchFrom === true ? 'bg-opacity':'')}>
                    <Col lg={6}>
                      <Row>
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={4}
                          xl={4}
                          className="text-title"
                        >
                          <p>ราคาเหมา</p>
                        </Col>
                        <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                          <Form.Control
                            type="text"
                            required
                            placeholder=""
                            className="input-search-add-wash-part"
                            value={commaCase(form.price_all)}
                            onChange={(e) => setField('price_all', e.target.value.replaceAll(',', ''))}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              switchFrom === true
                            }
                          ></Form.Control>
                        </Col>
                        <Col xs={1} sm={2} className="text-bath">
                          <p>บาท</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className="text-title"
                        >
                          <p>เวลาดำเนินการ</p>
                        </Col>
                        <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                          <Form.Control
                            type="text"
                            required
                            placeholder=""
                            className="input-search-add-wash-part"
                            value={commaCase(form.runtime)}
                            onChange={(e) => setField('runtime', e.target.value.replaceAll(',', ''))}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              switchFrom === true
                            }
                          ></Form.Control>
                        </Col>
                        <Col xs={1} sm={2} className="text-bath">
                          <p>นาที</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Form.Group
                    controlId="formBasicEmail"
                    className={`${(switchFrom === false ? 'bg-opacity':'')} "package-data"`}
                  >
                    <p className="form-title">ราคาตามขนาดรถ</p>
                    <Row mt-3>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>S</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_size_S)}
                              onChange={(e) => setField('car_size_S', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_size_S)}
                              onChange={(e) => setField('time_car_size_S', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>M</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_size_M)}
                              onChange={(e) => setField('car_size_M', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_size_M)}
                              onChange={(e) => setField('time_car_size_M', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>L</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_size_L)}
                              onChange={(e) => setField('car_size_L', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_size_L)}
                              onChange={(e) => setField('time_car_size_L', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>TRUCK CAB</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_truck_cab)}
                              onChange={(e) => setField('car_truck_cab', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_truck_cab)}
                              onChange={(e) => setField('time_car_truck_cab', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>SUV</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_suv)}
                              onChange={(e) => setField('car_suv', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_suv)}
                              onChange={(e) => setField('time_car_suv', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>TRUCK 4 DOOR </p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_truck4Door)}
                              onChange={(e) => setField('car_truck4Door', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_truck4Door)}
                              onChange={(e) => setField('time_car_truck4Door', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>XL</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_size_XL)}
                              onChange={(e) => setField('car_size_XL', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_size_XL)}
                              onChange={(e) => setField('time_car_size_XL', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>VAN</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.car_van)}
                              onChange={(e) => setField('car_van', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder=""
                              className="input-search-add-wash-part"
                              value={commaCase(form.time_car_van)}
                              onChange={(e) => setField('time_car_van', e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>

                <p ref={productRef} className="form-title">วัสดุ/อุปกรณ์อ้างอิง</p>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col xs={8} className="mt-2 mb-2">
                        <Select
                          options={Products}
                          placeholder="ค้นหาสต๊อก"
                          isClearable={true}
                          isSearchable={true}
                          required={ValidatedProduct}
                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          value={ProductId ? Products.find((item) => item.id === ProductId) : null }
                          onChange={(data) => {setProductId(data.id? data.id : null); setUnitSales(data.unit_sales ? data.unit_sales : null)}}
                        />
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col xs={8} className="mt-2 mb-2">
                        <Select
                          options={UnitSales}
                          placeholder="เลือกหน่วยขาย"
                          isClearable={true}
                          isSearchable={true}
                          required={ValidatedProduct}
                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                          getOptionLabel={option => option.name_sale}
                          getOptionValue={option => option.id}
                          value={UnitSaleId ? UnitSales.find((item) => item.id === UnitSaleId) : null }
                          isDisabled={!ProductId || UnitSales && UnitSales.length === 0 }
                          onChange={(data) => setUnitSaleId(data.id? data.id : null)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {
                  ProductSizes?.map((product, key) => {
                    return(
                      <Row className="g-0">
                        <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                          <p>{product.size}</p>
                        </Col>
                        <Col xs={9} sm={4} md={4} lg={3} xl={2} className="input-stock">
                         <Form.Control
                            type="text"
                            name={product.size}
                            placeholder=""
                            className="input-search-add-wash-part"
                            required={ValidatedProduct}
                            value={commaCase(product.quantity)}
                            onChange={
                              (e) => setProductSizes(ProductSizes.map(
                                el => el.key === key ? { ...el, quantity: e.target.value.replaceAll(',', '') }: el
                              )) 
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          ></Form.Control>
                        </Col>
                      </Row>
                    )
                  })
                }

                <Row className="g-0">
                  <Col xs={0} sm={3} md={3} lg={2} xl={2} className="text-title">
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={2}
                    className="set-row-group-input"
                  >
                  {
                    ProductEditMode 
                    ?
                    <Button
                      type="button"
                      className="button-package-save mt-4 mb-4"
                      style={{ margin: 0 }}
                      onClick={ () => UpdateProductState()}
                    >
                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                    </Button>
                    :
                    <Button
                      type="button"
                      className="button-package-save mt-4 mb-4"
                      style={{ margin: 0 }}
                      onClick={ () => InsertProductSize()}
                    >
                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                    </Button>
                  }
                  </Col>
                </Row>

                <Row className="g-0" style={{ overflow: 'scroll', height: '200px' }}>
                  <Col>
                    <Table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                          <th>S</th>
                          <th>M</th>
                          <th>L</th>
                          <th>TRUCK CAB</th>
                          <th>SUV</th>
                          <th>TRUCK 4 DOOR </th>
                          <th>XL</th>
                          <th>VAN</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package">
                        {
                          ProductTable?.map((product, key) => {
                            return(
                              <tr key={`column-${key}`}>
                                <td>{product.product.name}</td>
                                <td>{product.S}</td>
                                <td>{product.M}</td>
                                <td>{product.L}</td>
                                <td>{product['TRUCK CAB']}</td>
                                <td>{product['SUV']}</td>
                                <td>{product['TRUCK 4 DOOR']}</td>
                                <td>{product['XL']}</td>
                                <td>{product['VAN']}</td>
                                <td>
                                  <img src={Increase} onClick={() => EditProduct(product)}/>
                                </td>
                                <td>
                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>. 
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <Row>
                  <Col className="colum-open-service">
                    <p>เปิด/ปิด ให้บริการ</p>{' '}
                    <Switch onClick={statusServiceToggler} checked={statusService}  />{' '}
                  </Col>
                </Row>

                  <Row className="g-0 mt-5 mb-5">
                    <Col className="d-flex justify-content-center">
                      <Button type="submit" className="button-package-save">
                        <img src={Save} className="" /> &nbsp; &nbsp; บันทึก
                      </Button>
                      <Button type="button" className="button-package-save" onClick={deleteConfirmation}>
                        <img src={Delete} className="" /> &nbsp; &nbsp; ลบ
                      </Button>
                      <Button type="button" className="button-package-save" onClick={exit}>
                        <img src={Turnoff} className="" /> &nbsp; &nbsp; ออก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบรายการหรือไม่</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={removeService}>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  )

}