import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Dropdown,
  Form,
} from 'react-bootstrap';
import Return from 'assets/icon/return-value.png';
import Add from 'assets/icon/add-staff.png';

import { Link } from 'react-router-dom';

//API
import { LoadServices } from 'actions/service'
import { loadAuth } from 'actions/auth';
import { CategoryList } from 'actions/settings/category';

import Footer from 'Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIconAdd from 'MiddleSystems/Components/layout/TabsIconAdd';
import Back from 'assets/icon/back.png';
import SearchIcon from 'assets/icon/search.png';
import Service from 'assets/icon/management.png';
import View from 'assets/icon/view-data.png';
import Select, { components } from 'react-select';
import MyPagination from 'Components/MyPagination'
export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

 

  const [statepage, setStatepage] = useState('');
  const [isLoading, setLoading] = useState(true);

  const [Rows, setRows] = useState(0);
  const [Services, setServices] = useState([]);
  const [serviceType, setServiceType] = useState([{}]);
  const [serviceCategory, setServiceCategory] = useState([{}]);
  const [ProductCategories, setProductCategories] = useState([]);
  const [ClearSearch, setClearSearch] = useState(false);


  const OptionServiceType = [
    { value: '', label: '' },
  ];
  const OptionServiceCategory = [
    { value: '', label: '' },
  ];

  const SetServiceType = (data) => {
    setServiceType({ value: data.value, label: data.value });
  }

   const SetServiceCategory = (data) => {
    setServiceCategory({ value: data.value, label: data.value });
  }

  const [Search, setSearch] = useState({
    Keyword: '',
    ProductCategoryId : null,
    PerPage: 10,
    CurrentPage: 1
  });
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const SearchModel = {
    Keyword: '',
    ProductCategoryId: null,
    PerPage: 10,
    CurrentPage: 1
  }


  const handleSelectPage = (e) => setStatepage(e);

  async function GetServices(){
    setLoading(true)
    const params = {
      keyword: Search.Keyword,
      product_category_id: Search.ProductCategoryId,
      PerPage: Search.PerPage,
      CurrentPage: Search.keyword === '' ? Search.CurrentPage : 1
    }
    try {
     
      const response = await dispatch(LoadServices(params));
      const service = response.data.data.data

      if (Search.PerPage) {
        setServices(response.data.data.data);
        setRows(response.data.data.last_page);
        setLoading(false)
      }else{
        setServices(response.data.data);
        setRows(0);
        setLoading(false)
      }
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const GetCategory = async () => {
    try {
      const response = await dispatch(CategoryList());
      if (response) {
        setProductCategories(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการบริการ'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };

  const ResetSearch = () => {
    setSearch(SearchModel)
    setClearSearch(true)
  }

  useEffect(() => {
    if (!ClearSearch) GetServices();
    setClearSearch(false)
  }, [Search.PerPage, ClearSearch]);

  useEffect(() => {
    GetServices();
    GetCategory();
    getAuth();
  }, [Search.PerPage, Search.CurrentPage]);

  function closePage() {
    let path = `/`;
    history.push(path);
  }

  function addCommas(x) {
    console.log(x);
    if (typeof x === 'number') {
      return parseFloat(x).toLocaleString()
    }
    if (x === null) {
      return '0';
    }
    return parseFloat(x.replaceAll(',', '')).toLocaleString()
  }
  return (
    <>
      <Container fluid className="package-container Service bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>จัดการบริการ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>จัดการบริการ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIconAdd />
                </Row>
                <Row className="g-0 justify-content-center">
                  {/* <MenuInput /> */}
                  <Row className="g-0">
                    <Col lg={4} className="d-flex justify-content-center mt-2 mb-2">
                      <Form.Control
                        type="text"
                        placeholder="ค้นหาด้วยรหัสบริการ, ชื่อบริการ"
                        value={Search.Keyword ? Search.Keyword : ''}
                          onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                        className="input"
                      ></Form.Control>
                    </Col>
                    {/* <Col className=" my-auto mt-2 mb-2 set-dropdown">
                      <Select
                        // options={optionServiceType}
                        placeholder="ประเภท"
                        options={OptionServiceType}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(data) => SetServiceType(data)}
                        className="select-list"
                      />
                    </Col> */}
                    <Col className=" my-auto mt-2 mb-2 set-dropdown">
                      <Select
                        options={ProductCategories}
                        placeholder="หมวดหมู่"
                        isClearable={false}
                        isSearchable={false}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        value={Search.ProductCategoryId ? ProductCategories.find((item) => item.id === Search.ProductCategoryId) : null}
                        onChange={(data) => setSearch({...Search, ProductCategoryId: data.id})}
                        className="select-list"
                      />
                    </Col>
                    <Col xs={12} lg={2} className=" mt-2 mb-2">
                      <Button className="button-menu" onClick={() => GetServices()}>
                        <img src={SearchIcon} /> &nbsp; ค้นหา
                      </Button>
                    </Col>
                    <Col xs={6} lg={2} className=" mt-2 mb-2">
                      <Button className="button-menu"  onClick={ResetSearch}>
                        <img src={Return} /> &nbsp; คืนค่า
                      </Button>
                    </Col>
                    <Col xs={6} className=" mt-2 mb-2 d-md-none">
                      <Link to="/service/add-service">
                        <Button className="button-menu">
                            <img src={Add} /> &nbsp; เพิ่มบริการ
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Row>
                <Row className="g-0 justify-content-center">
                  <Row
                    className=" mt-3"
                    style={{ overflow: 'scroll'}}
                  >
                    <div style={{ height: '500px'}}>
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th style={{ width: '15%', textAlign: 'left' }}>
                              รหัสบริการ
                            </th>
                            <th>ชื่อบริการ</th>
                            <th>หมวดหมู่</th>
                            <th>ราคา </th>
                            <th>หมายเหตุ</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                        { 
                            Services.map(item => {
                              return (
                                <tr key={ item.id}>
                                  <td style={{ textAlign: 'left' }}>{item.code}</td>
                                  <td>{item.name}</td>
                                  <td>{item.product_category?.name}</td>
                                  <td>
                                    {
                                      (() => {
                                        switch (item.sale_type) {
                                          case "all": return `${addCommas(item.all_price)}`;
                                          case "size": 
                                          // item.service_sale_type.map(i => {
                                          //     return i.size
                                          // });
                                          return "ราคาตามขนาดรถ";
                                          break;
                                          default: return ;
                                        }
                                      })()
                                    }
                                  </td>
                                  <td>{item.note}</td>
                                  <td>
                                    <Link to={`service/edit-service/${item.id}`}>
                                      <Button className="button-package">
                                        <img src={View} />
                                      </Button>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </Row>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={5} xl={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          value={Search.PerPage ? Pages.find((data) => {return data.value === Search.PerPage}) : null}
                          onChange={(data) => setSearch({...Search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate" >
                    {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
