import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../assets/scss/style.scss';
import LogoutModal from './LogoutModal';
import Mr1 from '../../assets/icon/Mr1.png';
import Setting from '../../assets/icon/setting.png';
import Staff from '../../assets/icon/staff.png';
import Route ,{ useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from 'slice/userSlice';
import { SignOut } from 'actions/auth/index';

export default function Footer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const [showLogout, setShowLogout] = useState(false);
  const handleCloseLogout = () => setShowLogout(false);
  const handleShowLogout = () => setShowLogout(true);
  const handleProfile = () => Profile();

  function Profile(){
    // console.log('profile');
    history.push('/profile');
  }

  function close() {
    setShow(false);
  }
  function Logout() {
    dispatch(SignOut()).then(response => {
      dispatch(updateUser(null))
      history.push('/login');
    }).catch((e) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('profile');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('level');
      history.push('/login');
    })  
  }
  return (
    <>
    <div className="footer">
      <Container fluid className="pl-0 pr-0">
        {/* <Row>
          <Col className="button-mrk">
            <div className="start-button">
              <img src={Mr1} />
            </div>
          </Col>
          <Col col={6} sm={6} md={4} lg={4} xl={3} className="">
            <div className="set-button">
              <div className="mr-2"></div>
              <button type="button" className="button-profile ">
                <img src={Setting} onClick={handleShow} />
                <img src={Staff} />
                <span className="login-name">admin admin1</span>
              </button>
            </div>
          </Col>
        </Row> */}
        <nav className="navbar   navbar-expand-lg">
          <div className="navbar-nav d-none d-sm-block">
            <a href="https://human-intelligent.life/report/process-status"><button className="start-button">
              <img src={Mr1} />
            </button></a>
          </div>
          <div className="navbar-right d-flex align-items-center">
            <div className="d-none d-lg-flex">
              <LogoutModal />
            </div>
            <div className="set-button ml-2 mr-2">
              <button type="button" className="button-profile" >
                <img src={Setting} onClick={handleShow} />
                <img src={Staff} className="ml-2 mr-2" />
                {/* <span className="login-name">{profile.fullname}</span> */}
                <Link onClick={handleProfile} className="login-name">{profile && profile.fullname}</Link>
              </button>
            </div>
          </div>
        </nav>
        <Modal
          className="modal-Confirm-Admit"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="modal-header-Confirm-Admit">
            <img src={Setting} /> &nbsp;
            <p>
              <b>ตั้งค่า</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/car-type">
                  <Button className="button">ตั้งค่า (ประเภทรถ)</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/brand-car">
                  <Button className="button">ตั้งค่า (ยี่ห้อรถ/รุ่นรถ)</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/department">
                  <Button className="button">ตั้งค่า (แผนก)</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/unit">
                  <Button className="button">ตั้งค่า (หน่วย)</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/category">
                  <Button className="button">ตั้งค่า (หมวดหมู่สินค้า)</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/permission">
                  <Button className="button">ตั้งค่าการใช้งาน</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg={4}>
                <Link to="/setting/bank">
                  <Button className="button">ตั้งค่าธนาคาร</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-3">
              <Col lg={4}>
                <Link to="/setting/segment">
                  <Button className="button">ตั้งค่า Segment</Button>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-3">
              <Col lg={4}>
                <Link to="/setting/car-color">
                  <Button className="button">ตั้งค่าสีรถ</Button>
                </Link>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </div>

    <Modal
        id="_logout_modal"
        className="modal-Login"
        centered
        show={showLogout}
        onHide={handleCloseLogout}
      >
        <Modal.Header className="modal-header-login">
          <p className="title-header">
            <b>ออกระบบ</b>
          </p>
          <button
            type="button "
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseLogout}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="custom-setting set-logout-modal">
          <div className="logout-modal">
            <h5>คุณต้องการออกระบบหรือไม่</h5>
            <div className="confirm-logout">
              <Row>
              <Col className="mt-2 mb-2"> 
              <Button onClick={() => Logout()} className="text-dark button-logout">ใช่</Button>
              </Col>
              <Col className="mt-2 mb-2">
              <Button onClick={handleCloseLogout} className="text-dark button-logout">ไม่ใช่</Button>
              </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </>

  );
}
