import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import StockImage from 'assets/icon/appwizard_list.png'
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import Select, { components } from 'react-select';
import Back from 'assets/icon/back.png';

import Delete from 'assets/icon/delete.png';
import Increase from 'assets/icon/add-staff.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/new-re-turn.png';
import Calendar from 'assets/icon/calendar-bg.png';
import { DatePicker } from 'antd';

import { Formik, FieldArray } from 'formik';

//API
import {ProductList} from '../../actions/product/index';
import { UserList } from '../../actions/user/index'
import { saveStorage, LoadNewStockCode } from 'actions/stock'
import { loadAuth } from 'actions/auth';
import { UnitSale } from 'actions/settings/unit'; 


import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

export default function CutStock() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { employee } = useSelector( (state) => state.user)

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  // const [statepage, setStatepage] = useState('');
  // const [productName, setProductName] = useState([{ value: '', label: '' }]);
  // const [unit, setUnit] = useState([{ value: '', label: '' }]);

  // const handleSelectPage = (e) => setStatepage(e);
  const [Products, setProducts] = useState('');
  const [validated, setValidated] = useState(false);
  const [User,setUser] = useState([]);
  const [Loading,setisLoading] = useState(false);

  const optionUnit = [
    { value: 'buy', label: 'หน่วยซื้อ' },
    { value: 'sale', label: 'หน่วยขาย' },
  ];

  const optionReason = [
    { value: 'use', label: 'ใช้งาน' },
    { value: 'other', label: 'อื่นๆ' }
  ];

  const StorageModel = {
    key: 0,
    StockId: null,
    UnitName: null,
    ProductId: null,
    TotalQuantity: 0,
    Admit: false,
    TakeOut: true,
    Quantity: null,
    UnitType: null,
    UnitId: null,
    UnitSaleId: null,
    CountBy: profile.id,
    Note: '',
    StockDate: null,
    Type: 'cut_stock',
  }
  const [Data,SetData] = useState({
    StorageList:[
      StorageModel
    ],
  });

  const InsertStorage = (key) => {
    let insert = StorageModel
    // insert.key = Data.StorageList.length
    insert.key = Number(key) + 1 
    SetData({ ...Data, StorageList:[...Data.StorageList, insert]})
  }

  // function onChange(date, dateString) {
  //   console.log(date, dateString);
  // }
  async function saveStorageList(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    // console.log('Data.StorageList',Data.StorageList);
    let formData = new FormData();
    if(Data.StorageList.length > 0){
      for (let i = 0; i < Data.StorageList.length; i++) {
        formData.append(`stock_list[${i}]`, JSON.stringify(Data.StorageList[i]));
      }
    }

    try {
      const response = await dispatch(saveStorage(formData));
     
      if(response){
        history.push(`/stock`);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }


  }

  const GetProduct = async () => {
    setisLoading(true)
    try {
      const params = {

      }
      const response = await dispatch(ProductList(params));
      setProducts(response.data.data);
      setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['ปรับ/ตัด(stock)'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/stock');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetUser = async () => {
    setisLoading(true);
    try {
      const params = {
        has_lavel: true,
        permission:{
          adjust_stock:{
            name:'ปรับ/ตัด(stock)',
            add: true,
          },
        }
      }
      const response = await dispatch(UserList(params));
      setUser(response.data.data);
      setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function checkReason(e,item,key){
    if(item.reasonType === 'other'){
      SetData(prevState => ({...Data,
        StorageList: prevState.StorageList.map(
          el => el.key === item.key ? { ...el, Note: e.target.value }: el
        )
      }))
    }else{
      SetData(prevState => ({...Data,
        StorageList: prevState.StorageList.map(
          el => el.key === item.key ? { ...el, Note: e.target.value }: el
        )
      }))
    }
    
  }

  const RemoveStorage = (key) => {
    if(Data.StorageList.length > 1){
      const list = Data.StorageList
      const index = list.filter((item) => item.key !== key)
      const result = index
      SetData({ ...Data, StorageList: result })
    }else{
      alert('ไม่สามารถลบได้ ต้องมีมากกว่า 1 รายการ')
    }
    
  }
  const [StockCode,setStockCode] = useState([]);
  async function getNewStockCode(){
    setisLoading(true)
      try {
        const params = {
          type: 'cut_stock',
        }
        const response = await dispatch(LoadNewStockCode(params));
        setStockCode(response.data.data);
        setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [unitSales, setUnitSales] = useState([]);
  async function getUnitSale(unit_id){
    setisLoading(true)
    try {
      const params = {
        unit_id: unit_id,
      }
        const response = await dispatch(UnitSale(params));
        setUnitSales(response.data.data)
        setisLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }

  }

  function closePage() {
    let path = `/stock`;
    history.push(path);
  }
  const initialValues = {
    mainField: '',
    operation: '',
    value: [],
  };

  useEffect(() => {
    GetProduct();
    GetUser();
    getAuth()
    getNewStockCode();
  }, []);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }

  return (
    <>
      <Container
        fluid
        className="package-container Service Stock CutStock bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ตัดสต็อก</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ตัดสต็อก</b>
                </h5>
              </div>
              <div className="modal-body">
                <Form noValidate validated={validated} onSubmit={saveStorageList}>
                  <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                  </Row>
                  <Row className="g-0  justify-content-center  align-items-center   set-position padding-div">
                    <Col xs={12} lg={12}>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                          <Form.Label>เอกสารหมายเลข</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {StockCode}</p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <Form.Label>วันที่ตัด</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {dayjs().format('DD-MM-YYYY') }</p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <Form.Label>ผู้บันทึก</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {profile.fullname}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {
                    Data.StorageList?.map(  (item, key) => {
                      return (
                        <div>
                          <Row className="g-0 mt-2 mb-5">
                            <Col lg={10}>
                              <Row className="g-0">
                                <Col lg={9}>
                                  <Row>
                                    <Col
                                      xs={3}
                                      sm={3}
                                      lg={3}
                                      className="d-flex align-items-center justify-content-end set-Label"
                                    >
                                      <Form.Label>ชื่อสินค้า</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={5}>
                                      <Select
                                        options={Products}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isDisabled={Loading}
                                        placeholder={Loading ? 'กำลังโหลดข้อมูล' : 'ชื่อสินค้า'}
                                        isClearable={false}
                                        isSearchable={true}
                                        value={item.ProductId ? Products.find((data) => {return data.id === item.ProductId}) : null}
                                        onChange={
                                          (data) => {SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, ProductId: data.id,
                                              TotalQuantity: data.total_quantity,
                                              UnitName : data.unit && data.unit.name_buy ? data.unit.name_buy : null,
                                              UnitId: data.unit_id,UnitSaleId: null,
                                            }: el
                                            )
                                          })); getUnitSale(data.unit_id)}
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      lg={4}
                                      className="d-flex align-items-center font-weight-bold"
                                    >
                                      <p>ยอดปัจจุบัน : {item.TotalQuantity ? item.TotalQuantity.toFixed(2) : 0}  {item.UnitName} </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9}>
                                  <Row className="align-items-center">
                                    <Col
                                      xs={3}
                                      lg={3}
                                      className="d-flex align-items-center justify-content-end set-Label"
                                    >
                                      <Form.Label>จำนวนนำออก</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={4}>
                                      <Form.Control
                                        type="text"
                                        required
                                        placeholder="นำออก"
                                        className="input-form"
                                        value={commaCase(item.Quantity)}
                                        onFocus={
                                          (e) =>
                                          SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                            )
                                          }))
                                        }
                                        onBlur={
                                          (e) =>
                                          SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                            )
                                          }))
                                        }
                                        onChange={
                                          (e) => {
                                            SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, Quantity: e.target.value.replaceAll(',', '') }: el
                                              )
                                            })
                                          )}
                                        }
                                        onKeyPress={(event) => {
                                          if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      ></Form.Control>
                                    </Col>
                                    <Col xs={3} lg={1} className="set-Label">
                                      <Form.Label>หน่วย</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={4}>
                                      <Select
                                        options={unitSales}
                                        getOptionLabel={(option) => option.name_sale}
                                        getOptionValue={(option) => option.id}
                                        placeholder="หน่วย"
                                        isClearable={false}
                                        isSearchable={false}
                                        value={item.UnitSaleId ? unitSales.find((data) => {return data.id === item.UnitSaleId}) : null}
                                        onChange={
                                          (e) => SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, UnitSaleId: e.id }: el
                                            )
                                          }))
                                        }
                                        className="select-list"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9}>
                                  <Row className="align-items-center">
                                    <Col
                                      xs={4}
                                      lg={3}
                                      className="d-flex justify-content-end set-Label text-left"
                                    >
                                      <Form.Label>วันที่นำสินค้าออก1</Form.Label>
                                    </Col>
                                    <Col xs={8} lg={4}>
                                      <DatePicker
                                        // onChange={onChange}
                                          value={item.StockDate}
                                          onChange={
                                            (e) => SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, StockDate: e }: el
                                              )
                                            }))
                                          }
                                        placeholder="วันที่นำสินค้าออก"
                                        suffixIcon={<img src={Calendar} />}
                                      />
                                    </Col>
                                    <Col xs={3} lg={1} className="set-Label">
                                      <Form.Label>เหตุผล</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={4}>
                                      <Select
                                        options={optionReason}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        placeholder="เหตุผล"
                                        isClearable={false}
                                        isSearchable={false}
                                        // onChange={(data) => SetNumberPerPage(data)}
                                        value={item.reasonType ? optionUnit.find((data) => {return data.value === item.reasonType}) : null}
                                        onChange={
                                          (e) => SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, reasonType: e.value, }: el
                                            )
                                          }))
                                        }
                                        className="select-list"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mt-2 mb-2">
                                <Col lg={9}>
                                  <Row>
                                    <Col
                                      xs={3}
                                      lg={3}
                                      className="d-flex justify-content-end set-Label"
                                    >
                                      <Form.Label>หมายเหตุ</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={5}>
                                      <Form.Control
                                        as="textarea"
                                        placeholder="หมายเหตุ"
                                        rows={3}
                                        required={item.reasonType === 'other' ? true : false }
                                        className="text-area"
                                        value={item.Note}
                                        onChange={
                                          (e) =>  SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Note: e.target.value }: el
                                            )
                                          }))
                                        }
                                      ></Form.Control>
                                    </Col>
                                    <Col lg={4} xl={3}>
                                      <Button
                                        type="button"
                                        className="button-delete-menu-stock"
                                        onClick={() => RemoveStorage(item.key)}
                                      >
                                        <img src={Delete} /> &nbsp; ลบรายการ
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={3} xl={2}>
                                  {/* <Button
                                    type="button"
                                    className="button-delete-menu-stock"
                                    onClick={InsertStorage}
                                  >
                                    <img src={Increase} /> &nbsp; เพิ่มรายการ
                                  </Button> */}
                                  {
                                      (key+1) === Data.StorageList.length &&
                                      <Row className="align-items-center">
                                      <Col>
                                        <Button
                                          type="button"
                                          className="button-delete-menu-stock"
                                          onClick={() => {InsertStorage(item.key)}}
                                        >
                                          <img src={Increase} />{' '}
                                          &nbsp; เพิ่มรายการ
                                        </Button>
                                      </Col>
                                    </Row>
                                    }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="g-0 mt-2 mb-5g-0 mt-2 mb-5"
                            style={{ border: '1px solid #7A7A7A' }}
                          ></Row>
                        </div>
                      );
                    })
                  }
                  
                  <Row className="justify-content-between mt-3 mb-3">
                    <Col lg={2}>
                      <Button
                        type="button-save"
                        className="button-delete-menu-stock"
                      >
                        <img src={Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>
                    <Col lg={2}>
                      <Button
                        type="button-save"
                        className="button-delete-menu-stock"
                        onClick={closePage}
                      >
                        <img src={Return} /> &nbsp; ออก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
