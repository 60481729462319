import React, { useState, useEffect } from 'react';
import io from "socket.io-client";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs
} from 'react-bootstrap';
// import Service from 'assets/icon/management.png';
import NotificationImage from 'assets/icon/notification.png'
import Footer from 'Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';

import Back from 'assets/icon/back.png';

import All from 'MiddleSystems/Notification/Components/List';
import { useDispatch, useSelector } from 'react-redux';

//API
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch()
  const [tabs, setTabs] = useState('ทั้งหมด');
  const { employee } = useSelector( (state) => state.user)

  function close() {
    history.push(`/`);
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['notification'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  useEffect( () =>{
    // getAuth()
  },[]);

  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={NotificationImage} />
                  <b>Notification</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={NotificationImage} />
                  <b>Notification</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="g-0 justify-content-center ">
                  <Col>
                    {
                      employee?.is_middle &&
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={tabs}
                        onSelect={(tab) => setTabs(tab)}
                        className="mb-3"
                      >
                        <Tab eventKey="ทั้งหมด" title="ทั้งหมด">
                          <All name="ทั้งหมด" type={null} status={null} current={tabs}/>
                        </Tab>
                        <Tab eventKey="คำขอสั่งซื้อ" title="คำขอสั่งซื้อ">
                          <All name="คำขอสั่งซื้อ" type={'ใบสั่งซื้อ'} status={null} current={tabs}/>
                        </Tab>
                        <Tab eventKey="สินค้าใกล้หมด" title="สินค้าใกล้หมด">
                          <All name="สินค้าใกล้หมด" type={'สินค้า'} status={'ใกล้หมด'} current={tabs}/>
                        </Tab>
                        <Tab eventKey="สินค้าหมด" title="สินค้าหมด">
                          <All name="สินค้าหมด" type={'สินค้า'} status={'หมดแล้ว'} current={tabs}/>
                        </Tab>
                      </Tabs>
                    }
                    {
                      !employee?.is_middle &&
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={tabs}
                        onSelect={(tab) => setTabs(tab)}
                        className="mb-3"
                      >
                        <Tab eventKey="ทั้งหมด" title="ทั้งหมด">
                          <All name="ทั้งหมด" type={null} status={null} current={tabs}/>
                        </Tab>
                        <Tab eventKey="รับสินค้า" title="รับสินค้า">
                          <All name="รับสินค้า" type={'สินค้า'} status={'รับสินค้า'} current={tabs}/>
                        </Tab>
                        <Tab eventKey="อนุมัติเบิก" title="อนุมัติเบิก">
                          <All name="อนุมัติเบิก" type={'ส่วนลด'} status={'อนุมัติเบิก'} current={tabs}/>
                        </Tab>
                        <Tab eventKey="ยกเลิกเบิก" title="ยกเลิกเบิก">
                          <All name="ยกเลิกเบิก" type={'ส่วนลด'} status={'ยกเลิกเบิก'} current={tabs}/>
                        </Tab>
                      </Tabs>
                    }
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center mt-3"></Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
