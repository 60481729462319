import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import BillImage from 'assets/icon/job.png'
import Footer from '../Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/flat-style-circle-write.png';
import Arrow from '../assets/icon/arrow.png';
import AddLink from '../assets/icon/add-link.png';

import Document from '../assets/icon/document.png';
import Search from '../assets/icon/eyes-search.png';
import Save from '../assets/icon/flat-style-circle-save.png';
import Return from '../assets/icon/flat-style-circle-turn-on.png';
import Product_Component from '../Components/Job/Product';
import Service_Component from '../Components/Job/Service';
import Package_Component from '../Components/Job/Package';

// API
import { Customer } from 'actions/customer/index';
import Data from 'MiddleSystems/B2B/Components/Data';
import { ListConsumer } from 'antd/lib/list';

export default function CompanyB2BShowRoom() {
  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDisabled, setDisabled] = useState(false);
  const [isClearable, setClearable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isRtl, setRtl] = useState(false);
  const [isSearchable, setSearchable] = useState(true);

  
  const [employee, setEmployee] = useState([]);

  const [B2BListState, setB2BListState] = useState([]);
  const [Search, setSearch] = useState({
    Keyword: null,
  });

  const [DisplayDetail,setDisplayDetail] = useState({
    company_id: null,
    company_name: '',
    address: '',
    postcode: '',
    amphure: '',
    province: '',
    phone:'',
    show_room_employee_id: null,
});

  function close() {
    let path = `/job`;
    history.push(path);
  }

  function SetEmployee(e){
    setEmployee(e.showroom_contacts)
    setDisplayDetail({...DisplayDetail, company_id: e.id, 
      company_name: e.organization ? e.organization : '', 
      address: e.address ? e.address : '', 
      postcode: e.postcode,
      amphure: e.amphure.name_th, 
      province: e.amphure && e.amphure.province.name_th,
      phone: e.phone ? JSON.parse(e.phone) : '',
    })
  }

  function SetDetail(e){
    setDisplayDetail({...DisplayDetail, show_room_employee_id: e.id, name: e.name})
  }

  function Linkto(path){
    if(DisplayDetail.company_id === null){
      alert('เลือกโชว์รูม');
    }else if(DisplayDetail.show_room_employee_id === null){
      alert('เลือกพนักงาน');
    }else{
      const data = {
        customer_id : '',
        car_id: '',
        status_job: '',
        PackageList:[],
        type: 'show_room',
        company_id: DisplayDetail.company_id ? DisplayDetail.company_id : null,
        show_room_employee_id: DisplayDetail.show_room_employee_id ? DisplayDetail.show_room_employee_id : null, 
        
      }
      localStorage.setItem('job-inside-data',JSON.stringify(data));
      history.push(path)
    }
  }
  
  const GetCustomer = async () => {
    setLoading(true)
    const params = {
      type: ['โชว์รูม'],
      keyword: Search.Keyword,
      segment_id: Search.SegmentId,
      car_brand_id: Search.CarBrandId,
      car_model_id: Search.CarModelId,
    }
    try {
      const response = await dispatch(Customer(params));
      setB2BListState(response.data.data);
      if(parseDataJob.company_id ){
        let company_list = response.data.data
        let customer = parseDataJob.company_id ? company_list?.find((data) => { return data.id === parseDataJob.company_id} ) : ''

          setDisplayDetail({...Data , 
            company_id: parseDataJob.company_id,
            company_name : customer.organization,
            address: customer.address,
            postcode: customer.postcode,
            amphure: customer?.amphure?.name_th,
            province: customer?.amphure?.province?.name_th,
            phone: customer.phone,
            show_room_employee_id: parseDataJob.show_room_employee_id,
            type: "show_room",
          })

          setEmployee(customer.showroom_contacts)
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetCustomer()
    
  },[])

  return (
    <>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; Company (B2B)</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; Company (B2B)</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="justify-content-center set-height-order">
                  <Col lg={11}>
                    <Row className="mt-4 mb-4 justify-content-center">
                      <Col xs={12} lg={6}>
                        <Row className="justify-content-center">
                          <Col className="d-flex justify-content-start">
                            <p className="font-weight-bold">เลือกโชว์รูม</p>
                          </Col>
                        </Row>
                        <Row className=" mt-3 mb-3">
                          <Col lg={12}>
                            <Select
                              className="text-left select-style select-list"
                              aria-label="Default select example"
                              placeholder="ค้นหาด้วยชื่อ,รหัสลูกค้า,เบอร์โทรศัพท์"
                              options={B2BListState}
                              getOptionLabel={option => option.organization}
                              getOptionValue={option => option.id}
                              value={DisplayDetail.company_id ? B2BListState?.find((data) => {return data.id === DisplayDetail.company_id}) : ''}
                              onChange={
                                (e) => { SetEmployee(e)}
                              }
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row className=" mt-3 mb-3">
                          <Col lg={12}>
                            <Select
                              className="text-left select-style select-list"
                              aria-label="Default select example"
                              placeholder="เลือกพนักงานขายที่ดูแล"
                              options={employee}
                              getOptionLabel={option => `${option.name}`}
                              getOptionValue={option => option.id}
                              value={DisplayDetail.show_room_employee_id ? employee?.find((data) => { return data.id === DisplayDetail.show_room_employee_id}) : ''}
                              onChange={
                                (e) => { SetDetail(e)}
                              }
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={6}></Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={12} className="mt-3 mb-3">
                        <Row>
                          <Col xs={12} md={12} className="text-left">
                            <Form.Label className="font-weight-bold">
                              ข้อมูลโชว์รูม
                            </Form.Label>
                          </Col>
                          <Col md={4} className="ml-3  mt-3 text-left">
                            <p>{DisplayDetail.company_name}</p>
                            <p>
                              {`
                                ${DisplayDetail.address} 
                                ${DisplayDetail.amphure}
                                ${DisplayDetail.province} 
                                ${DisplayDetail.postcode}
                               `}
                            </p>

                            <p>{DisplayDetail.phone}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={12} className="mt-3 mb-3">
                        <Row>
                          <Col xs={12} md={12} className="text-left">
                            <Form.Label className="font-weight-bold">
                              พนักงานขายที่ดูแล
                            </Form.Label>
                          </Col>
                          <Col md={4} className="ml-3  mt-3 text-left">
                            <p>{DisplayDetail.name}</p>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={12} className="mt-3 mb-3">
                        <Row className="justify-content-center">
                          <Col xs={4} lg={3}>
                            {/* <a href="/job/company-b2b-member"> */}
                              <Button type="button" className="button" onClick={() => {
                                Linkto('/job/company-b2b-member')
                              }}>
                                ถัดไป
                              </Button>
                            {/* </a> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
