import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";
import moment from 'moment';

import 'Setting/PreviewBill/Component/css/bill-7.css';

moment.locale('th')

export class Bill7 extends React.PureComponent {
  render() {
    const { Job, Type } = this.props

    const PhoneAddCommas = (str) => {
      const regex = /\B(?=(\d{10})+(?!\d))/g;
      return str.toString().replace(regex, ',');
    }

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }
    return (
      <div id='bill-7' className='bill-7'>
        {
          Type?.map(data => {
            console.log(data);
            return(
              <div className='bill-7-field'>
                <div className='half-left'>
                  <div className='logo'>
                    <img src={LogoImage} />
                  </div>
      
                  <div className='customer-info'>
                    <label>ข้อมูลลูกค้า</label>
                    <p>ชื่อ { Job?.customer?.first_name }</p>
                    <p>ที่อยู่ { Job?.customer?.address }</p>
                    <div style={{ marginTop: '10px'}}>
                      <p>เบอร์โทร { Job && Job.customer && Job.customer.phone && JSON.parse(Job.customer.phone).join(', ') }</p>
                    </div>
                  </div>
      
                  <div className='car-info'>
                    <label>ข้อมูลรถ</label>
                    <p>ยี่ห้อ : { Job?.car_owner?.car_serie?.car_model?.car_brand?.name }</p>
                    <p>รุ่น : { Job?.car_owner?.car_serie?.car_model.model } </p>
                    <p>ทะเบียนรถ : { Job?.car_owner?.license_plate }</p>
                    <p>เลขตัวถัง : { Job?.car_owner?.chassis_number }</p>
                    <p>สีรถ : { Job?.car_owner?.color_job?.name }</p>
                    <div style={{ marginTop: '10px'}}>
                      <p>สิ่งของในรถ : </p>
                      <p>ตำหนิรถ : </p>
                      <p>บริเวณ : </p>
                    </div>
                  </div>
      
                  <div className='note'>
                    <label>หมายเหตุ</label>
                    <p>{ Job?.note }</p>
                  </div>
                </div>
      
                <div className='half-right'>
                  <div className='header'>
                    <div className='address'>
                      <div className='line'>
                        <div className='qr'>
                          <div className='phone'>
                            <img src={Phone} />
                            <p>{ Job?.branch?.phone && PhoneAddCommas(Job?.branch?.phone) }</p>
                          </div>
                          <img src={QRcode} />
                        </div>
                      </div>
                    </div>
      
                    <div className='order'>
                      <p className='department'>{ data }</p>
                      <div className='order-label'>
                        Job ORDER
                      </div>
                      <p>Job { Job?.job_code }</p>
      
                      <div className='inline'>
                        <p className='title'>วันที่ใช้บริการ</p>
                        <p>{ Job?.created_at && moment(Job?.created_at).add(543, 'year').format('DD/MM/YYYY') }</p>
                        <p>เวลา</p>
                        <p>{ Job?.created_at && moment(Job?.created_at).format('HH:mm') }</p>
                      </div>
      
                      <div className='inline'>
                        <p className='title'>วันที่รับรถ</p>
                        <p>{ Job?.job_assgin && Job?.job_assgin.pickup_date && moment(Job?.job_assgin.pickup_date).add(543, 'year').format('DD/MM/YYYY') }</p>
                        <p>เวลา</p>
                        <p>{ Job?.job_assgin && Job?.job_assgin.pickup_date && moment(Job?.job_assgin.pickup_date).add(543, 'year').format('HH:mm') }</p>
                      </div>
      
                    </div>
                  </div>
      
                  <div className='address-2'>
                    <p>Mr.Kleane สาขา { Job?.branch?.name }</p>
                    <p>ที่อยู่ { Job?.branch?.address }</p>
                  </div>
      
                  <div className='table-group'>
                    <div className='table-header'>
                      <div className='title'>รายการ</div>
                      <div className='title'>ราคา/หน่วย</div>
                      <div className='title'>จำนวน</div>
                      <div className='title'>จำนวนเงิน</div>
                    </div>
      
                    <div className='table-body'>
                      {
                        Job?.job_details?.map((data, index) => {
                          console.log(data)
                          return(
                            <div className='table-field'>
                              <div>{ renderProduct(data) }</div>
                              <div>{ data.price }</div>
                              <div>{ data.quantity }</div>
                              <div>{ (data.price * data.quantity).toFixed(2) }</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='total'>ยอดสุทธิ {Job?.total_price}</div>
      
                  <div className='signature'>
                    <div className='employee'>
                      ลงลายมือชื่อพนักงาน
                    </div>
                    {/* <div className='sale'>ลงชื่อ Sale</div> */}
                    <div className='car-owner'>ลงลายมือชื่อเจ้าของรถ</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}