import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Pagination, Form } from 'react-bootstrap';
import StockImage from 'assets/icon/appwizard_list.png'
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';

import Select, { components } from 'react-select';
import Search from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import PurchaseOrder from 'assets/stock/purchase-order.png';
import Cancel from 'assets/icon/new-cancel.png';
import Order_Stock from '../../BranchSystems/assets/stock/order-stock.png';
import MyPagination from 'Components/MyPagination'

//API
import {OrderList,UpdateOrder,CancelOrder} from 'actions/orders'
import { useDispatch, useSelector } from 'react-redux';
import { loadAuth } from 'actions/auth';
import dayjs from 'dayjs';

export default function ReceiptOrder() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { employee } = useSelector( (state) => state.user)

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const [statepage, setStatepage] = useState('');
  const [page, setPage] = useState([
    { value: '0', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);
  const handleSelectPage = (e) => setStatepage(e);
  function closePage() {
    let path = `/stock`;
    history.push(path);
  }

  // const searchModel = {
  //   Keyword: '',
  //   Status: '',
  //   PerPage: 10,
  //   CurrentPage: 1,
  //   Rows: 0,
  // }

  const [search, setSearch] = useState({
      Keyword: '',
      Status: '',
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
     
  });
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [button_Search, setButton_Search] = useState('');
  const handleSelectType = (e) => setType(e);
  const handleSelectCategory = (e) => setCategory(e);

  const [orders,setOrders] = useState([]);

  const [isLoading,setIsLoading] = useState(false)

  const getOrders = async() => {
  
      setIsLoading(true)
      try {
        const params = {
          limit: search.PerPage,
          page: search.Keyword === '' ? search.CurrentPage : 1,
          keyword: search.Keyword,
          status: search.Status,
          orderBy: 'DESC',
        }
        const response = await dispatch(OrderList(params));
        
        if(response.data.data && response.data.data.data){
          setOrders(response.data.data.data);
          setSearch({ ...search, 
            Rows: response.data.data.last_page,
            CurrentPage: response.data.data.current_page 
          })
        }else{
          setOrders(response.data.data);
          setSearch({ ...search, 
            Rows: 0,
            CurrentPage: 1 
          })
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error);
        
      } finally {
        console.log('finally')
        setIsLoading(false)
      }

  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['ลงชื่อ/รับเข้า(stock)'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/stock');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [ClearSearch, setClearSearch] = useState(false);

  const resetSearch = () => {
    setSearch({ ...search, 
      Keyword: '',
      Status: '',
      PerPage: 10,
      CurrentPage: 1,
      Rows: 0,
    })

    setClearSearch(true)
  }

  const rejectedOrder = async(id) => {
    const formData = {
      id: id,
      data: {
        status: 'rejected'
      }
    }
    try {
      const response = await dispatch(UpdateOrder(formData));
      setClearSearch(true)
        
    } catch (error) {
      console.error(error);
     
    } finally {
      console.log('finally')
    }
  }

  const toOrderDetail = (item) =>{
    if(level.middle=== true){
      item.status === 'pending' ?  history.push(`/stock/receipt-order/admit/${item.id}`)
    : history.push( `/stock/receipt-order/${item.id}`)
    }else{
    //   item.status === 'delivery' ? history.push(`/stock/receive/order-to-stock/${item.id}`) 
    // : item.status ==='done' ? history.push( `/stock/receipt-order/${item.id}`) 
    // : history.push( `/stock/receipt-order`)
      item.status !== 'done' ? history.push(`/stock/receive/order-to-stock/${item.id}`) 
    : history.push( `/stock/receipt-order/${item.id}`) 
    }

  }

  const toNewOrder = () => {
    level.middle === true ? history.push(`/stock/purchase-order`) : level.middle === false ? history.push(`/stock/receive/order-to-stock`) : alert('ไม่มีสิทธิ์ในการเข้าถึง');
  }

  const convertStatusText = (status) => {
    if(level.middle === true){
      switch (status){
        case 'pending' : 
        return (<font className="text-light">รอรับเข้า</font>)
        break
        case 'approved' : 
        return (<font className="text-light">อนุมัติ</font>)
        break
        case 'rejected' : 
        return (<font className="text-light">ยกเลิก</font>)
        break
        case 'done' : 
        return (<font className="text-light">เสร็จสิ้น</font>)
        break
      }
    }else{
      switch (status){
        case 'pending' : 
        return 'รออนุมัติ'
        break
        case 'approved' : 
        return 'อนุมัติ'
        break
        case 'rejected' : 
        return 'ยกเลิก'
        break
        case 'delivery' : 
        return 'รอรับเข้า'
        break
        case 'done' : 
        return 'เสร็จสิ้น'
        break
      }
    }
    
  }

  const [display, setDisplay] = useState([
    {
      value: '',
      label: 'ทั้งหมด',
    },
    {
      value: 'delivery',
      label: 'รอรับเข้า',
    },
    {
      value: 'pending',
      label: 'รออนุมัติ',
    },
    {
      value: 'approved',
      label: 'รับเข้า',
    },
    {
      value: 'rejected',
      label: 'ยกเลิก',
    },
    {
      value: 'done',
      label: 'สำเร็จ',
    },
  ]);
  const reset_value = () => {
    setSearch('');
  };

  useEffect(() => {
    getOrders()
    getAuth();
  },[search.PerPage, search.CurrentPage]);

  useEffect(() => {
    if (!ClearSearch) getOrders();
    setClearSearch(false)
  }, [search.PerPage, ClearSearch]);

  
  return (
    <>
      <Container fluid className="package-container Staff Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รายการรับเข้า/สั่งซื้อ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รายการรับเข้า/สั่งซื้อ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="g-0 justify-content-center">
                  <Col lg={4} className=" mt-2 mb-2">
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยรหัสสินค้า,ประเภท,หมวดหมู่"
                      // defaultValue={statepage[0]}
                      value={search.Keyword}
                      onChange={
                        (e) => setSearch({...search, Keyword: e.target.value })
                      }
                      className="input"
                    ></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      className=""
                      aria-label="Default select example"
                      placeholder="ทั้งหมด"
                      options={display}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={search.Status ? display.find((data) => {return data.value === search.Status}) : null}
                      onChange={
                        (e) => setSearch({...search, Status: e.value })
                      }
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    {/* <Link class="link-page" > */}
                      <Button className="button-menu" type='button' onClick={toNewOrder}>
                        <Row className="justify-content-center align-items-center">
                          <Col className="p-0">
                            <span>
                              <img src={level.middle === true ? PurchaseOrder : Order_Stock} /> &nbsp; { level.middle === true ? 'ใบสั่งซื้อ' : 'สั่งเข้าสต๊อก' }
                            </span>
                          </Col>
                        </Row>
                      </Button>
                    {/* </Link> */}
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={getOrders}>
                      <Row className="justify-content-center align-items-center">
                        <Col className="p-0 d-flex justify-content-end">
                          <img src={Search} />
                        </Col>
                        <Col className="p-0 d-flex justify-content-start">
                          <p>ค้นหา</p>
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={resetSearch}>
                      <Row className="justify-content-center align-items-center">
                        <Col className="p-0 ">
                          <span>
                            <img src={Return} /> คืนค่า
                          </span>
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                </Row>
                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0 pt-3">
                    <table className="table table-responsive table-wash-part table-stock">
                      <thead>
                        <tr className="t-header-package">
                          <th>เลขที่สั่งซื้อ</th>
                          <th>เลขที่การรับเข้า</th>
                          <th>วันที่</th>
                          {/* <th>คู่ค้า</th> */}
                          <th>รายการตัวอย่าง</th>
                          {
                            level.middle === true ? (
                              <th>ราคารวม</th> 
                            ) : ''
                          }
                          <th>ผู้ทำรายการ</th>
                          <th>สถานะ</th>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody className="t-body-package">
                        {orders.map((item, i) => {
                          return (
                              <tr key={i} >
                                <td onClick={() => {toOrderDetail(item)}}>{ item.middle === true ? item.order_number : item.stock_code}</td>
                                <td onClick={() => {toOrderDetail(item)}}>{item.stock && item.stock.code}</td>
                                <td onClick={() => {toOrderDetail(item)}}>{dayjs(item.order_date).format('DD-MM-YYYY')}</td>
                                {/* <td onClick={() => {toOrderDetail(item)}}>{item.partner && item.partner.name ? item.partner.name : null}</td> */}
                                <td onClick={() => {toOrderDetail(item)}}>{item && item.order_detail_example ? item.order_detail_example.name : null}</td>
                                {
                                  level.middle === true ? (
                                    <td onClick={() => {toOrderDetail(item)}}>{item.grand_total}</td>
                                  ) : ''
                                }
                                <td onClick={() => {toOrderDetail(item)}}>{item.employee && item.employee.fullname}</td>
                                {
                                  level.middle === true ? (
                                    <td className={item.status == 'pending' ? 'bg-danger' : item.status == 'approved' ? 'bg-primary' : item.status == 'rejected' ? 'bg-dark' : item.status == 'done' ? 'bg-success' : ''} onClick={() => {toOrderDetail(item)}}>{convertStatusText(item.status)}</td>
                                  ) : 
                                    <td onClick={() => {toOrderDetail(item)}}>{convertStatusText(item.status)}</td>
                                }
                                {/* <td></td> */}
                                <td >
                                {
                                  level.middle === true ? 
                                  (
                                    item.status === 'pending' || item.status === 'approved' ||item.status === 'delivery' ? (
                                   
                                      <Button
                                        type="button"
                                        className="button-cancel"
                                        onClick={() => {rejectedOrder(item.id)}}
                                        disabled={item.status === 'done' || item.status === 'rejected'}
                                      >
                                        <img src={Cancel} /> &nbsp; ยกเลิก
                                      </Button>
                                   
                                  ): null
                                  ) : (
                                    item.status === 'pending' ? (
                                   
                                      <Button
                                        type="button"
                                        className="button-cancel"
                                        onClick={() => {rejectedOrder(item.id)}}
                                        disabled={item.status === 'done' || item.status === 'rejected'}
                                      >
                                        <img src={Cancel} /> &nbsp; ยกเลิก
                                      </Button>
                                   
                                  ): null
                                  )
                                }
                                 </td>
                              </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 pt-5 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          options={page}
                          onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ{ search.Rows }</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {
                        !isLoading ? 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          search.Rows > 1 && search.PerPage > 0 ?
                            <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>   
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
