import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Sign from "Setting/PreviewBill/Component/image/Sign.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";
import 'Setting/PreviewBill/Component/css/bill-9.css';

import dayjs from 'dayjs'
const THBText = require('assets/libraries/thai-baht-text') 

export class Bill9 extends React.PureComponent {
  
  render() {

    const { order } = this.props

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    const displayPhone = (phone) => {
      if(phone && Array.isArray(phone)) return phone.join(', ')
      if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
    }

		console.log(order)
    return (
			<div class="main">
				<div class="row header-doc">
					<div class="column">
						<img class="logo-main" src={LogoImage} />
						<h4 class="mar-0"><b>{ order?.company ? order?.company?.name : '' }</b></h4>
						<p>{ order?.company ? order?.company?.address : '' }</p>
						<p>เลขประจำตัวผู้เสียภาษี { order?.company ? order.company?.tax_id : '' }</p>
						<p>โทรศัพท์: { order?.company?.phone ? order.company?.phone : '' }</p>
					</div>
					<div class="column">
						<div class="header-main">
							<h1 class="text-white">ใบสั่งซื้อ</h1>
						</div>
						<table class="header-table f-r header-mr t-c">
							<tr>
								<td>วันที่</td>
								<td>{dayjs(order?.created_at).format('DD/MM/YYYY')}</td>
							</tr>
							<tr>
								<td>เลขที่ใบสั่งซื้อ</td>
								<td>{ order ? order?.order_number : '' }</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="row header-cos">
					<div class="column">
						<div class="cos-main b-g"><h4 class="mar-0"><b>ผู้ขาย</b></h4></div>
						<p class="mar-0"><b>{ order ? order?.partner_name : '' }</b></p>
					</div>
					<div class="column">
						<div class="cos-main b-g"><h4 class="mar-0"><b>ส่งที่</b></h4></div>
						<p class="mar-0"><b>{ order?.company ? order?.company?.name : '' }</b></p>
						<p>{ order?.company ? order?.company?.address : '' }</p>
						<p>โทรศัพท์: { order?.company?.phone ? order.company?.phone : '' }</p>
					</div>
				</div>
				<div class ="header-pro">
					<table class="bill-9-tb w-100">
						<tr class="t-c">
							<th>เลขที่สินค้า</th>
							<th>รายละเอียด</th>
							<th>จำนวน</th>
							<th>หน่วย</th>
							<th>ราคาต่อหน่วย</th>
							<th>ยอดรวม</th>
						</tr>
						{
							order?.details?.map((data) => {
								return (
									<tr>
										<td class="t-c">{ data?.product?.code ? data?.product?.code : '' }</td>
										<td class="table-pro-name">{ data?.name ? data?.name : ''}</td>
										<td class="t-c">{ data?.quantity ? data?.quantity : ''}</td>
										<td class="t-c">{ data?.product?.unit?.name_buy ? data?.product?.unit?.name_buy : '' }</td>
										<td class="t-r table-main">{ data?.price ? data?.price.toFixed(2) : '' }</td>
										<td class="t-r table-sec">{ data?.total ? data?.total.toFixed(2) : '' }</td>
									</tr>
								)
							})
						}
						<tr>
							<td colspan="4">
								<div class="cos-main b-g"><h4 class="mar-0">หมายเหตุเพิ่มเติม</h4></div>
								<p>{ order?.note !== null ? order.note : '' }</p>
							</td>
							<td colspan="2" class="pad-0">
								<table class="w-100">
									<tr>
										<td class="table-main-2">รวมเป็นเงิน</td>
										<td class="table-sec t-r">{ order?.total ? order?.total : ''}</td>
									</tr>
									<tr>
										<td class="table-main-2">ภาษีมูลค่าเพิ่ม</td>
										<td class="table-sec t-r">{ order?.vat_calculate ? order?.vat_calculate : '' }</td>
									</tr>
									<tr>
										<td class="table-main-2">ส่วนลด</td>
										<td class="table-sec t-r">{ order?.total_discount ? order?.total_discount : ''}</td>
									</tr>
									<tr>
										<td class="table-main-2">รวมทั้งสิ้น</td>
										<td class="table-sec t-r">{ order?.grand_total ? order?.grand_total : ''}</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
				<div class="row header-foot">
					<div class="column"></div>
					<div class="column">
						<div class="w-70 t-c">
							<p>ผู้อนุมัติ</p>
							<div class="sign-bar">
								<img class="sign-img" src={Sign} />
							</div>
							<p>(สินีนาฎ สุวรรณพันธ์)</p>
							<p>วันที่ {dayjs().format('DD/MM/YYYY')}</p>
						</div>
					</div>
				</div>
				<div class="w-100 t-c">
					หากมีข้อสงสัย กรุณาติดต่อ 053-808900
				</div>
			</div>
		);
		}
	
	}