import React, { useState, useEffect, useRef  } from 'react';
import { Container, Row, Col, Form, Button, ToastContainer, Toast } from 'react-bootstrap';

import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';

import Calendar from '../../assets/icon/calendar-bg.png';
import { DatePicker, Space } from 'antd';
import Select, { components } from 'react-select';
import Delete from '../../assets/icon/delete.png';
import increate from 'assets/icon/increase.png';
import Print from 'assets/icon/print.png';

import Save from '../../assets/icon/save.png';

import moment from 'moment';

import { Bill7 } from 'Setting/PreviewBill/Component/Bill7'


// API
import { List } from 'actions/employee';
import { CreateAssignJob } from 'actions/jobs/assign';

export default function JobAssign(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { log_permissions } = useSelector( (state) => state.user.permissions)
  
  const job_permission = log_permissions.find(item => item.permission.name === 'job')
  const Edit = true
  if (job_permission.edit && !job_permission.view && !job_permission.add && !job_permission.delete && !job_permission.all) {
    Edit = false
  }

  let job = props.JobState
  const carSize = job?.car_owner?.car_serie?.size
  const packages = job.job_details.filter(item => item.package)
  let process_time = null
  packages?.forEach(item => {
    const packageSize = item?.package?.middle_sizes?.find(item => item.size === carSize)
    if(packageSize && packageSize.process_times) {
      if (!process_time) process_time = moment()
      process_time = process_time.add(packageSize.process_times, 'minutes')
    }
  });
  process_time = process_time ? process_time.format('DD/MM/YYYY HH:mm') : null

  const optionHours = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
  ];

  const optionMinutes = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
    { value: '60', label: '60' },
  ];

  const AssignModel = {
    work: null,
    person_in_charges: []
  }

  const [Alert, setAlert] = useState(false)

  let temp = []
  job?.delegates.forEach(delegate => {
    let exists = temp.find(item => item.work === delegate.pivot.work)
    if (exists) {
      exists.person_in_charges.push(delegate)
    }else{
      let obj = {}
      obj.work = delegate.pivot.work
      obj.person_in_charges = [delegate]
      temp.push(obj)
    }
  });

  const [data, setData] = useState({
    service_date: job && job.job_assgin ? job.job_assgin.service_date : null,
    service_hours: job && job.job_assgin ? job.job_assgin.service_hours : null,
    service_minutes: job && job.job_assgin ? job.job_assgin.service_minutes : null,
    pickup_date: job && job.job_assgin ? job.job_assgin.pickup_date : null,
    pickup_hours: job && job.job_assgin ? job.job_assgin.pickup_hours : null,
    pickup_minutes: job && job.job_assgin ? job.job_assgin.pickup_minutes : null,
    received_date: job && job.job_assgin ? job.job_assgin.received_date : null,
    received_hours: job && job.job_assgin ? job.job_assgin.received_hours : null,
    received_minutes: job && job.job_assgin ? job.job_assgin.received_minutes : null,
    works: temp && temp.length > 0 ? temp : [AssignModel]
  });

  const [employees, setEmployees] = useState([]);
  async function getEmployee(){
    dispatch(List({is_assign:true})).then(response => {
      setEmployees(response.data.data);
    }).catch( e => {

    })
  }

  // const displayDate = () => {
  //   if (data.pickup_date && data.pickup_hours && data.pickup_minutes) return moment(`${data.pickup_date} ${data.pickup_hours}:${data.pickup_minutes}`).format('DD/MM/YYYY HH:mm')
  // }

  const [validated, setValidated] = useState(false)
  const AssignJob = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true)

    const formData = new FormData()
    // forupdate
    if(job.job_assgin && job.job_assgin.id)formData.append('job_assign_id', job.job_assgin.id)
    formData.append('job_id', job.id)
    if(data.service_date) formData.append('service_date', data.service_date)
    if(data.service_hours) formData.append('service_hours', data.service_hours)
    if(data.service_minutes) formData.append('service_minutes', data.service_minutes)
    if(data.pickup_date) formData.append('pickup_date', data.pickup_date)
    if(data.pickup_hours) formData.append('pickup_hours', data.pickup_hours)
    if(data.pickup_minutes) formData.append('pickup_minutes', data.pickup_minutes)
    if(data.received_date) formData.append('received_date', data.received_date)
    if(data.received_hours) formData.append('received_hours', data.received_hours)
    if(data.received_minutes) formData.append('received_minutes', data.received_minutes)

    data.works.forEach(work => {
      work.person_in_charges.forEach(employee => {
        formData.append(`works[${employee.id}][work]`, work.work)
      });
    });

    dispatch(CreateAssignJob(formData)).then( () => {
      setAlert(true)
    }).catch(e => {

    })

  }

  const DeleteAssign = (work) => {
    const index = data.works.indexOf(work)
    setData({...data, works: data.works.filter(item => data.works.indexOf(item) !== index ) })
  }

  useEffect(() => {
    getEmployee()
  }, []);
  
  const printList = [
    'ช่างshop',
    'ธุรการ',
    'ลูกค้า',
  ]
  
  const [printType, setPrintType] = useState([]);
  const [reRender, setReRender] = useState(false);

  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  
  const OnlyShop = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ช่างshop
      </Button>
    )
  }, []);

  const OnlyAdminOfficer = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ธุรการ
      </Button>
    )
  }, []);

  const OnlyCustomer = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ลูกค้า
      </Button>
    )
  }, []);

  const AllPrint = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ทั้งหมด
      </Button>
    )
  }, []);


  const [waiting, setWaiting] = useState(false);

  const handleAfterPrint = React.useCallback(() => {
    setReRender(false)
  }, []);

  const handleBeforePrint = React.useCallback(() => {
  }, []);

  const handleOnBeforeGetContentShop = React.useCallback(() => {
    setPrintType(['ช่างshop'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentAdminOfficer = React.useCallback(() => {
    setPrintType(['ธุรการ'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentCustomer = React.useCallback(() => {
    setPrintType(['ลูกค้า'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentAll = React.useCallback(() => {
    setPrintType(printList)
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  return (
    <>
      <Container fluid className="set-height-order-component">
        <ToastContainer position='top-end'>
          {
            <Toast show={Alert} delay={2500} bg='success' autohide onClose={() => setAlert(false)}>
              <Toast.Header>
                <strong className="me-auto">แจ้งเตือน</strong>
              </Toast.Header>
              <Toast.Body>บันทึกรายการแล้ว</Toast.Body>
            </Toast>
          }
        </ToastContainer>
        <Form noValidate validated={validated} onSubmit={AssignJob}>
          <Row>
            <Col xs={{ order: 'last' }} md={{ order: 'first' }} lg={10}>
              <Row className="mt-3 mb-3">
                <Col className="text-left">
                  <p className="font-weight-bold">ข้อมูลการรับรถ</p>
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-3">
                <Col xs={4} lg={2} className="text-left">
                  <Form.Label>วันที่รับบริการ</Form.Label>
                </Col>
                <Col xs={8} lg={3}>
                  <DatePicker
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    value={data.service_date ? moment(data.service_date) : null}
                    onChange={(date) => setData({...data, service_date: moment(date).format('YYYY-MM-DD')})}
                  />
                </Col>
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionHours}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionMinutes[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="ชั่วโมง"
                    className="select-list"
                    value={data.service_hours ? optionMinutes.find(item => item.value === data.service_hours) : null }
                    onChange={(e) => setData({...data, service_hours: e.value})}
                  />
                </Col>
                :
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionMinutes}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionMinutes[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="นาที"
                    className="select-list"
                    value={data.service_minutes ? optionMinutes.find(item => item.value === data.service_minutes) : null }
                    onChange={(e) => setData({...data, service_minutes: e.value})}
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-3">
                <Col xs={4} lg={2} className="text-left mt-2  mb-2">
                  <Form.Label>เวลานัดรับรถ</Form.Label>
                </Col>
                <Col xs={8} lg={3} className="text-left  mt-2  mb-2">
                  <DatePicker
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    value={data.pickup_date ? moment(data.pickup_date) : null}
                    onChange={(date) => setData({...data, pickup_date: moment(date).format('YYYY-MM-DD')})}
                  />
                </Col>
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionHours}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionHours[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="ชั่วโมง"
                    className="select-list"
                    value={data.pickup_hours ? optionMinutes.find(item => item.value === data.pickup_hours) : null }
                    onChange={(e) => setData({...data, pickup_hours: e.value})}
                  />
                </Col>
                :
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionMinutes}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionMinutes[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="นาที"
                    className="select-list"
                    value={data.pickup_minutes ? optionMinutes.find(item => item.value === data.pickup_minutes) : null }
                    onChange={(e) => setData({...data, pickup_minutes: e.value})}
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-3">
                <Col xs={4} lg={2} className="text-left mt-2  mb-2">
                  <Form.Label>เวลามารับรถ</Form.Label>
                </Col>
                <Col xs={8} lg={3} className="text-left  mt-2  mb-2">
                  <DatePicker
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    value={data.received_date ? moment(data.received_date) : null}
                    onChange={(date) => setData({...data, received_date: moment(date).format('YYYY-MM-DD')})}
                  />
                </Col>
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionHours}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionHours[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="ชั่วโมง"
                    className="select-list"
                    value={data.received_hours ? optionMinutes.find(item => item.value === data.received_hours) : null }
                    onChange={(e) => setData({...data, received_hours: e.value})}
                  />
                </Col>
                :
                <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                  <Select
                    options={optionMinutes}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    defaultValue={optionMinutes[0]}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="นาที"
                    className="select-list"
                    value={data.received_minutes ? optionMinutes.find(item => item.value === data.received_minutes) : null }
                    onChange={(e) => setData({...data, received_minutes: e.value})}
                  />
                </Col>
                <Col xs={12} lg={2} className="text-left set-text-mobile">
                  <Form.Label>
                    {/* บันทึกโดย &nbsp; */}
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ order: 'first' }}
              md={{ order: 'last' }}
              lg={2}
              className="text-left"
            >
              <Col className="p-0 mt-2 mb-2">
                <Form.Label className="font-weight-bold">
                  ผู้ดูแล JOB
                </Form.Label>
              </Col>
              <Col className="p-0 mt-2 mb-2">
                <Form.Label>
                  { job?.create_by?.employee?.fullname }
                </Form.Label>
              </Col>
              <Col className="p-0 mt-2 mb-2">
                <Form.Label className="font-weight-bold">
                  วันที่สร้าง JOB
                </Form.Label>
              </Col>
              <Col className="p-0 mt-2 mb-2">
                <Form.Label>{ moment(job?.created_at).format('DD/MM/YYYY') }</Form.Label>
              </Col>
              <Col className="p-0 mt-2 mb-2">
                <Form.Label className="font-weight-bold">
                  เวลาที่คาดว่าจะเสร็จ
                </Form.Label>
              </Col>
              <Col className="p-0 mt-2 mb-2">
                <Form.Label>{ process_time }</Form.Label>
              </Col>
            </Col>
          </Row>

          {
            Edit &&
            <Row className="mt-4 ">
              <Col sm={12} lg={12}>
                <Row>
                  <Col xs={5} sm={3} xl={2} className="text-left">
                    <Form.Label>งาน</Form.Label>
                  </Col>
                  <Col xs={5} sm={5} lg={3} xl={2} className="text-left">
                    <Form.Label>ผู้รับผิดชอบ</Form.Label>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                {
                  data.works?.map((work, key) => {
                    return (
                      <Row className="align-items-center" key={key}>
                        <Col xs={5} lg={3} xl={2} className="mt-3 mb-3">
                          <Form.Control
                            type="text"
                            name="company"
                            placeholder=""
                            required
                            className="text-left search-btn-top"
                            value={work.work ? work.work : ''}
                            autoComplete="off"
                            onChange={(e) =>
                              setData((prevState) => ({
                                ...data,
                                works: prevState.works.map((el) =>
                                data.works.indexOf(el) === key
                                    ? { ...el, work: e.target.value }
                                    : el
                                )
                              }))
                            }
                          ></Form.Control>
                        </Col>
        
                        <Col className="mt-3 mb-3">
                          <Select
                            isMulti
                            name="colors"
                            options={employees}
                            getOptionLabel={option => option.fullname}
                            getOptionValue={option => option.id}
                            placeholder="พนักงาน"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={work.person_in_charges}
                            onChange={(newTags) =>
                              setData((prevState) => ({
                                ...data,
                                works: prevState.works.map((el) =>
                                data.works.indexOf(el) === key
                                    ? { ...el, person_in_charges: newTags }
                                    : el
                                )
                              }))
                            }
                          />
                        </Col>
        
                        <Col xs={1} lg={1} sm={1}>
                          {
                            key > 0 &&
                            <Button className="button-package" onClick={() => DeleteAssign(work)}>
                              <img src={Delete} />
                            </Button>
                          }
                        </Col>
        
                        <Col xs={2} lg={2} sm={1}>
                          {
                            (key + 1 === data.works?.length) &&
                            <Button
                              className="button"
                              onClick={() => setData({...data, works: [...data.works, AssignModel]})}
                            >
                              <img src={increate} /> &nbsp; เพิ่ม
                            </Button>
                          }
                        </Col>
                      </Row>
                    )
                  })
                }
              </Col>
            </Row>
          }

          <Row className="mt-4 justify-content-between">
            <Col lg={6}>
              <Row>
                <Col lg={3} xl={3} className="mt-3 mb-3">
                  <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={`Job order ช่างshop ${job?.job_code}`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentShop}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyShop}
                  />
                </Col>
                <Col lg={3} xl={3} className="mt-3 mb-3">
                  <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={`Job order ธุรการ ${job?.job_code}`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentAdminOfficer}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyAdminOfficer}
                  />
                </Col>
                <Col lg={3} xl={3} className="mt-3 mb-3">
                  <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={`Job order ลูกค้า ${job?.job_code}`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentCustomer}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyCustomer}
                  />
                </Col>
                <Col lg={3} xl={3} className="mt-3 mb-3">
                  <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={`Job order ทั้งหมด ${job?.job_code}`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentAll}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={AllPrint}
                  />
                </Col>
              </Row>
              <div style={{ display: 'none' }}>
                {
                  reRender &&
                  <Bill7 Job={job} Type={printType} ref={componentRef} />
                }
              </div>
            </Col>
            <Col lg={6}>
              <Row className="justify-content-end">
                <Col lg={4} xl={4} className=" mt-3 mb-3">
                    <Button type="button" className="button" onClick={() => history.push(`/technician-queue/${job?.id}`) }>
                      คิวช่างกระจก
                    </Button>
                </Col>
                {
                  Edit &&
                  <Col lg={4} xl={4} className=" mt-3 mb-3">
                    <Button type="submit" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                }
                </Row>
            </Col>
          </Row>

          {/* <Row>
            <Col lg={12}>Dispaly For Fill Data</Col>
            <Col lg={12}>
            {
              job && 
              <Bill7 Job={job} Type={printType} ref={componentRef} />
            }
            </Col>
          </Row> */}

          {/* <Row className="mt-4 justify-content-between">
            <Col lg={6}>
              <Row>
                <Col lg={4} xl={4} className=" mt-3 mb-3">
                    <Button type="button" className="button" onClick={() => history.push(`/technician-queue/${job?.id}`) }>
                      คิวช่างกระจก
                    </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="justify-content-end">
                <Col lg={4} xl={4} className=" mt-3 mb-3">
                  <Button type="submit" className="button">
                    <img src={Save} /> &nbsp; บันทึก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Form>
      </Container>
    </>
  );
}
