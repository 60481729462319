import { Row, Col, Form, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { LogsEmployee} from 'actions/employee/index';

import { Link, useHistory, useParams } from 'react-router-dom';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';

// import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import MyPagination from 'Components/MyPagination'
import Return from 'assets/icon/return-value.png';

// import Search from 'assets/icon/view-id.png';
import { useDispatch } from 'react-redux';

var dayjs = require('dayjs')

export default function Log() {

  const history = useHistory();
  const dispatch = useDispatch();
  // const { employee_id } = useParams();
  let { id } = useParams();

  const [search, setSearch] = useState({
    StartDate: null,
    EndDate: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const [employeeLog, setEmployeeLog] = useState([]);

  const [statepage, setStatepage] = useState('');
  const [Pages, setPage] = useState([
    { value: '0', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [isLoading, setLoading] = useState(true);
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);

  const handleSelectPage = (e) => setStatepage(e);

  const SearchModel = {
    StartDate: null,
    EndDate: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  }

  const ResetSearch = () => {
    setSearch(SearchModel)
    setClearSearch(true)
  }
  
  function onChangeStartDate(date) {
    setSearch({...search, StartDate: dayjs(date).format('YYYY-MM-DD')});
  }

  function onChangeEndDate(date) {
    setSearch({...search, EndDate: dayjs(date).format('YYYY-MM-DD')});
  }

  const GetEmployeeLog = async (params) => {
    setLoading(true);
    const newParams = {
      employee_id: id,
      start_date: search.StartDate,
      end_date: search.EndDate,
      limit: search.PerPage,
      page: search.CurrentPage
      
    }

    dispatch(LogsEmployee(newParams)).then(response => {
        
      if(search.PerPage > 0){
        // console.log(response.data.data.data);
        setEmployeeLog(response.data.data.data);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        // console.log(response.data.data);
        setEmployeeLog(response.data.data);
        setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
      
    }).catch(e => {
      console.log(e);
    })
  }

  const [ClearSearch, setClearSearch] = useState(false);
  // function ResetSearch() {
  //   setSearch({ ...search, 
  //     StartDate: null,
  //     EndDate: null,
  //     PerPage: 10 
  //   })
  //   setClearSearch(true)
  // }

  useEffect(() => {
    if (!ClearSearch) GetEmployeeLog();
    setClearSearch(false)
  }, [search.PerPage, ClearSearch]);

  useEffect(() => {
    // if (!ClearSearch) 
    GetEmployeeLog();
    //setClearSearch(false)
  }, [search.StartDate, search.EndDate, search.PerPage, search.CurrentPage]);

  function closePage() {
    let path = `/staff`;
    history.push(path);
  }

  return (
    <>
      <Row className="justify-content-center">
        <Row className="align-items-center">
          <Col xs={1} md={5}></Col>
          <Col xs={4} md={2}>
            <DatePicker
              onChange={(date) => onChangeStartDate(date)}
              placeholder=""
              suffixIcon={<img src={Calendar} />}
            />
          </Col>
          <Col xs={1} md={1}>
            ถึง
          </Col>
          <Col xs={4} md={2}>
            <DatePicker
              onChange={(date) => onChangeEndDate(date)}
              placeholder=""
              suffixIcon={<img src={Calendar} />}
            />
          </Col>
          <Col xs={2} lg={2} className=" mt-2 mb-2">
            <Button className="button-menu"  onClick={ResetSearch}>
              <img src={Return} /> &nbsp; คืนค่า
            </Button>
          </Col>
        </Row>
        <Row className="g-0 justify-content-center m-0">
          <Col
            className=" mt-3 p-0"
            style={{ overflow: 'scroll', height: '500px' }}
          >
            <table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th style={{ width: '30%', textAlign: 'left' }}>วันเวลา</th>
                  <th style={{ textAlign: 'left' }}>ประเภทการอัพเดท</th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
                {
                  employeeLog.map(log => {
                    return (
                      <tr key={ log.id }>
                        <td style={{ textAlign: 'left' }}>{dayjs(log.created_at).format('DD/MM/YYYY HH:mm')}</td>
                        <td style={{ textAlign: 'left' }}>{ `${log.content}` }</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="g-0 mt-3 mb-3">
          <Col lg={5} className=" set-page">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>แสดง</p>
              </Col>
              <Col lg={7}>
                <Select
                  className="text-left select-style select-list"
                  aria-label="Default select example"
                  placeholder=""
                  options={Pages}
                  onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                  defaultValue={{ value: 10, label: '10' }}
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <p style={{ margin: 0 }}>รายการ</p>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex justify-content-end set-paginate">
          <div className="box-paginate">
              {
                !isLoading ? 
                  search.Rows > 1 && search.PerPage > 0 ?
                    <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                  : ''
                : 
                  search.Rows > 1 && search.PerPage > 0 ?
                    <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                  : ''
              }
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
}
