import React, { useState, useEffect } from 'react';
import { useHistory ,Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ToastContainer, Toast
} from 'react-bootstrap';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { useDispatch } from 'react-redux';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';

// Icon
import Setting from 'assets/icon/setting.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/getout.png';
import Delete from 'assets/icon/delete.png';
import increate from 'assets/icon/add-staff.png';
import Back from 'assets/icon/back.png';

// API
import { CarBrandList, CreateCarBrand } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { CarSerieList } from 'actions/settings/car_serie';
import { CarTypeList } from 'actions/settings/car_type';
import { loadAuth } from 'actions/auth';

// Plugin
var dayjs = require('dayjs')

export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();

  const InputModel = {
    uid: null,
    CarModelName: null,
    CarModel: null,
    CarSerieName: null,
    CarSerie: null,
    CarTypeName: null,
    CarType: null,
    CarSize: null,
    CarSizeOBJ: null,
    CarYear: null,
    CarYearOBJ: null,
  };

  useEffect(() => {
    GetCarBrand();
    GetCarType();
    getAuth({name:'ตั้งค่า',permission:'add'});
  }, []);

  
  const [Data, setData] = useState({
    CarBrandName: null,
    Value: [
      {...InputModel, uid: (new Date()).getTime() }
    ]
  });

  const [CarBrands, setCarBrands] = useState([]);
  const [CarModels, setCarModels] = useState([]);
  const [CarSeries, setCarSeries] = useState([]);
  const [CarType, setCarType] = useState([]);

  const [errors, setErrors] = useState([]);

  const GetCarBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setCarBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setCarModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarSerie = async (car_model_id) => {
    const params = {
      car_model_id
    }

    try {
      const response = await dispatch(CarSerieList(params));
      setCarSeries(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarType = async (data) => {
    try {
      const response = await dispatch(CarTypeList());
      setCarType(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const Years = []
  let YearRange = (dayjs().year() - 100);
  const CurrentYear = dayjs().year()
  for (let index = YearRange; index <= CurrentYear; index++) {
    Years.push({ text: index, value: index })
  }
  Years.reverse();

  const CarSizes = [{
    id: 'S',
    name: 'S'
  }, {
    id: 'M',
    name: 'M'
  }, {
    id: 'L',
    name: 'L'
  }, {
    id: 'TRUCK CAB',
    name: 'TRUCK CAB'
  }, {
    id: 'SUV',
    name: 'SUV'
  }, {
    id: 'TRUCK 4 DOOR',
    name: 'TRUCK 4 DOOR'
  }, {
    id: 'XL',
    name: 'XL'
  }, {
    id: 'VAN',
    name: 'VAN'
  }];

  function SetCarBrandValue(data) {
    if (typeof data.id === 'string' || data.id instanceof String){
      setData({...Data, CarBrandName: data.name})
    }else{
      setData({...Data, CarBrandName: data.name});
      GetCarModel(data.id)
    }
  }

  function SetCarModelValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarModelName: null, CarModel: null };
      setData({...Data, Value: items});
    }else if (typeof data.id === 'string' || data.id instanceof String){
      items[index] = {...items[index], CarModelName: data.model, CarModel: data};
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarModelName: data.model, CarModel: data};
      setData({...Data, Value: items});
      GetCarSerie(data.id)
    }
  }

  function SetCarSerieValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarSerieId: null, CarSerieName: null, CarSerie: null };
      setData({...Data, Value: items});
    }else if (typeof data.id === 'string' || data.id instanceof String){
      items[index] = {...items[index], CarSerieId: null};
      items[index] = {...items[index], CarSerieName: data.code};
      items[index] = {...items[index], CarSerie: data};
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarSerieName: null};
      items[index] = {...items[index], CarSerieId: data.id};
      items[index] = {...items[index], CarSerie: data};
      setData({...Data, Value: items});
      GetCarSerie(data.id)
    }
  }

  function SetCarTypeValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarTypeName: null, CarType: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarTypeName: data.name, CarType: data};
      setData({...Data, Value: items});
    }
  }

  function SetCarSizeValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarSize: null, CarSizeOBJ: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarSize: data.name, CarSizeOBJ: data};
      setData({...Data, Value: items});
    }
  }

  function SetCarYearValue(index, data) {
    let items = [ ...Data.Value ];
    if(!data){
      items[index] = {...items[index], CarYear: null, CarYearOBJ: null };
      setData({...Data, Value: items});
    }else{
      items[index] = {...items[index], CarYear: data.value, CarYearOBJ: data};
      setData({...Data, Value: items});
    }
  }

  const [validated, setValidated] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const data = {
      car_brand_name: Data.CarBrandName,
      value: Data.Value
    }

    dispatch(CreateCarBrand(data)).then( () => {
      setData({ 
        CarBrandName: null,
        Value: [InputModel]
      });
      setValidated(false);
      history.push('/setting/car-brand');
    }).catch( e => {
      setErrors(['กรุณากรอกข้อมูลให้ครบถ้วน'])
    })

  };

  function removeColumn (uid) {
    setData({...Data, Value: Data.Value.filter(function(item) { 
      return uid !== item.uid
    })});
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  function close() {
    let path = `/setting/car-brand`;
    history.push(path);
  }

  function exit() {
    history.push('/setting/car-brand');
  }

  function ClearData() {
    setData({...Data,
      Value: [
        InputModel
      ]
    });
  }
  
  return (
    <>
      <Container fluid className="package-container Service Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/car-brand" className='link-pagination'> ยี่ห้อรถ/รุ่นรถ </Link> &gt; เพิ่มรายการรถ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; <Link to="/setting/car-brand" className='link-pagination'>ยี่ห้อรถ/รุ่นรถ</Link> &gt; เพิ่มรายการรถ</b>
                </h5>
              </div>
              <div className="modal-body">
                <ToastContainer position='bottom-end'>
                  {
                    errors?.map((error, key) => {
                      return (
                        <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                          <Toast.Header>
                            <strong className="me-auto">แจ้งเตือน</strong>
                          </Toast.Header>
                          <Toast.Body>{ error }</Toast.Body>
                        </Toast>
                      )
                    })
                  }
                </ToastContainer>
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="g-0  m-0 mt-3 mb-3 justify-content-center">
                    <Col className="text-left  set-bg d-flex align-items-center">
                      <Form.Label className="text-size">
                        เพิ่มรายการรถ
                      </Form.Label>
                    </Col>
                  </Row>
                  <div className="add-car-height">
                    <Row>
                      <Col lg={12} className="text-left mt-3 mb-3">
                        <Form.Label className="text-size  ml-3">
                          ยี่ห้อ
                        </Form.Label>
                      </Col>
                      <Col lg={5} className="text-left  mb-3 style-input-tag">
                        <Creatable
                          placeholder="ยี่ห้อ"
                          isClearable
                          required
                          options={CarBrands}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: optionLabel,
                          })}
                          value={Data.CarBrandName ? CarBrands?.find((data) => {return data.name === Data.CarBrandName}) : null}
                          onChange={(data) => SetCarBrandValue(data)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      { 
                        Data.Value?.map( (value, key) => {
                          return (
                            <Col
                              sm={12}
                              lg={12}
                              key={`mainfield-operation-value-${key}`}
                            >
                              <Row className="align-items-center">
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    รุ่นรถ
                                  </Form.Label>

                                  <Row>
                                    <Creatable
                                      placeholder="รุ่นรถ"
                                      isClearable
                                      required
                                      options={CarModels}
                                      getOptionLabel={option => option.model}
                                      getOptionValue={option => option.id}
                                      getNewOptionData={(inputValue, optionLabel) => ({
                                        id: inputValue,
                                        model: optionLabel,
                                      })}
                                      value={value.CarModel}
                                      onChange={(data) => SetCarModelValue(key, data)}
                                    />
                                  </Row>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    รหัสรุ่นรถ
                                  </Form.Label>
                                  <Creatable
                                    placeholder="รหัสรุ่นรถ"
                                    isClearable
                                    required
                                    options={CarSeries}
                                    getOptionLabel={option => option.code}
                                    getOptionValue={option => option.id}
                                    getNewOptionData={(inputValue, optionLabel) => ({
                                      id: inputValue,
                                      code: optionLabel,
                                    })}
                                    value={value.CarSerie}
                                    onChange={(data) => SetCarSerieValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ประเภทรถ
                                  </Form.Label>
                                  <Creatable
                                    placeholder="ประเภทรถ"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={CarType}
                                    required
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    getNewOptionData={(inputValue, optionLabel) => ({
                                      id: inputValue,
                                      name: optionLabel,
                                    })}
                                    value={value.CarType}
                                    onChange={(data) => SetCarTypeValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ขนาดรถ
                                  </Form.Label>
                                  <Select
                                    placeholder="ขนาดรถ"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={CarSizes}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    required
                                    value={value.CarSizeOBJ}
                                    onChange={(data) => SetCarSizeValue(key, data)}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label className="text-size ml-3">
                                    ปี
                                  </Form.Label>
                                  <Select
                                    placeholder="ปี"
                                    className="text-left select-style"
                                    aria-label="Default select example"
                                    options={Years}
                                    required
                                    getOptionLabel={option => option.text}
                                    getOptionValue={option => option.value}
                                    value={value.CarYearOBJ}
                                    onChange={(data) => SetCarYearValue(key, data)}
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={1}>
                                  {
                                    key === 0 && 
                                    (
                                      <Row className="justify-content-end">
                                        <Col className='set-hidden-delete'>
                                          <Button
                                            className="button set-hidden-delete"
                                            onClick={() => ClearData()}
                                          >
                                            คืนค่า
                                          </Button>
                                        </Col>
                                        <Col xs={12} className="d-md-none">
                                          <Form.Label className="text-size ml-3"></Form.Label>
                                          <Button
                                            className="button d-md-none"
                                            onClick={() => ClearData()}
                                          >
                                            คืนค่า
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                  {
                                    key > 0 && 
                                    (
                                      <Row className="justify-content-end g-0">
                                        <Col>
                                          <Button
                                            className="button-image set-hidden-delete"
                                            onClick={() => removeColumn(value.uid)}
                                          >
                                            <img src={Delete} />
                                          </Button>
                                        </Col>
                                        <Col xs={12}>
                                          <Button
                                            className="button d-md-none"
                                            onClick={() => removeColumn(value.uid)}
                                          >
                                            <img src={Delete} />
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                </Col>
                                <Col
                                  xs={6}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className=""
                                >
                                  {
                                    Data.Value.length - 1 === key && 
                                    (
                                      <Button
                                        className="button mt-3 mb-3"
                                        onClick={() =>
                                          setData({ ...Data, Value: [...Data.Value, {...InputModel, uid: (new Date()).getTime() }]})
                                        }
                                      >
                                        <img src={increate} /> &nbsp;
                                        เพิ่มรายการ
                                      </Button>
                                    )
                                  }
                                </Col>
                              </Row>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </div>
                  <Row className="g-0 mt-3 mb-3 justify-content-between">
                    <Col md={6} lg={6} className="mt-3 mb-3">
                      <Row>
                        <Col md={6} lg={4} className="mt-3 mb-3">
                          <Button className="button" type='submit'>
                            <img src={Save} /> &nbsp; บันทึก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} lg={6}>
                      <Row className="justify-content-end">
                        <Col md={6} lg={4} className="mt-3 mb-3">
                          <Button className="button" onClick={exit}>
                            <img src={Return} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
