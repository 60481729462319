import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Pagination, Form } from 'react-bootstrap';
import Service from 'assets/icon/appwizard_list.png';
import Footer from 'Components/Footer';

import { Link, useHistory, useParams } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import GroupButtonStock from 'MiddleSystems/Components/layout/GroupButtonStock';
import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import Print from 'assets/icon/print.png';
import { Bill9 } from 'Setting/PreviewBill/Component/Bill9'
import ReactToPrint, { PrintContextConsumer, useReactToPrint, generateAndSavePDF } from 'react-to-print';
import html2pdf from 'html2pdf.js'

//API
import { OrderFind } from 'actions/orders';

import Getout from 'assets/icon/getout.png';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

export default function Datadmit() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  const levelString = localStorage.getItem('level');
  const level = levelString && levelString !== "undefined" ? JSON.parse(levelString): '';

  const [statepage, setStatepage] = useState('');
  const [tax, setTax] = useState([
    { value: 'Exclude Vat', label: 'Exclude Vat' },
    { value: 'Include Vat', label: 'Include Vat' },
    { value: 'Non Vat', label: 'Non Vat' },
  ]);
  const [use, setUse] = useState([
    { value: 'เข้าสต็อก', label: 'เข้าสต็อก' },
    { value: 'ใช้ทันที', label: 'ใช้ทันที' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [order,setOrder] = useState([]);
  //end state`


  // function
  const getOrderFind = async () => {
    setIsLoading(true)
    try {
      const order_id = id
      const res = await dispatch(OrderFind(order_id))
      setOrder(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setIsLoading(false)
    }

  }

  const OrderUseTypeTH = (data) => {
    switch (data) {
      case 'order_stock':
        return 'เข้าสต็อก'
      case 'use_now':
          return 'ใช้ทันที'
      default:
        break;
    }
  }

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button className="button" onClick={() => handlePrint()}>
        <img src={Print} /> &nbsp; พิมพ์เอกสาร1
      </Button>
    )
  }, ['']);
  const [waiting, setWaiting] = useState(false);
  const reactToPrintContent = React.useCallback(() => {

    return componentRef.current;
  }, [componentRef.current]);
  const handleAfterPrint = React.useCallback(() => {
  }, []);
  const handleBeforePrint = React.useCallback(() => {
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);
  const [PContent,setPContent] = useState(null)
  async function prePDF(){
    await handleOnBeforeGetContent();
    handlePrint();

  }
  const handlePDF = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: false,
    onBeforeGetContent: handleOnBeforeGetContent,
		print: async (printIframe) => {
			const document = printIframe.contentDocument;
			if (document) {
        const html = document.getElementsByClassName('bill-9-save');
        console.log('option handlePDF', 123, html)
        var opt = {
          filename:     'ใบสั่งสินค้า.pdf',
          image:        { type: 'png', quality: 1 },
          html2canvas:  { scale: 2, logging: false },
          jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: 'p' }
        };
				await html2pdf().set(opt).from(html).save();
			}
		}
  });
  // onBeforeGetContent: () => handleOnBeforeGetContent(componentRef.current.outerHTML),
  const handlePrint =  () => {
  // useReactToPrint({
    // content: () => componentRef.current,
    // // onBeforeGetContent: () => handleOnBeforeGetContent(componentRef.current.outerHTML),
    // // onBeforeGetContent: handleOnBeforeGetContentAll,
    // // copyStyles: true,
    // onClick: async (printIframe) => {
      console.log(1)
      setWaiting(true);
      // const document = componentRef.current.contentDocument;
      // if (document) {
        const html = document.getElementById('bill-9')
        const element = componentRef.current;
        var opt = {
          filename:     `ใบสั่งของ.pdf`,
          image:        { type: 'png', quality: 1 },
          html2canvas:  { scale: 2, logging: false },
          jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: 'p' }
        };

        html2pdf().set(opt).from(html).toPdf().get('pdf').then(function (pdfObj) 
        {
          setWaiting(false);
          // pdfObj.autoPrint();
          window.open(pdfObj.output('bloburl'), '_blank');

          // a.href = pdfObj.output('bloburl');
          // a.download =  `ใบฟิล์มกรองแสง.pdf`;
          // a.click();

          // var myWindow = window.open();
          // myWindow.document.write(`<html><head><title>ใบฟิล์มกรองแสง</title></head><body style="margin: 0;"><embed width="100%" height="100%" name="plugin" src="${URL.createObjectURL(new File([pdfObj.output('blob')], 'TEST', {type: 'application/pdf'}))}" type="application/pdf" internalinstanceid="21" /></body></html>`);
          // myWindow.document.close();
        });
      // }else{
      //   console.log(document)
      // }
    // }
  };
  function genPDF() {
    const element = document.getElementById("genPDF");
    var opt = {
      filename:     `ใบสั่งของ.pdf`,
      image:        { type: 'png', quality: 1 },
      html2canvas:  { scale: 1, logging: false },
      jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: 'p' }
    };
    html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdfObj) 
    {
      window.open(pdfObj.output('bloburl'), '_blank');
    });
  } 

  const VatTypeTh = (data) =>{
    switch (data) {
      case 'non':
        return 'Non Vat'
      case 'include':
        return 'Include Vat'
      case 'exclude':
          return 'Exclude Vat'
    
      default:
        break;
    }
  }

  function closePage() {
    let path = `/stock/receipt-order`;
    history.push(path);
  }



  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  useEffect( () => {
    getOrderFind()
  },[]);

  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content mb-5">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รับเข้า</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รับเข้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form className="Stock-Request-Order-Branch mt-2 mb-2">
                  <Row className="g-0 justify-content-center">
                    <Col md={12} lg={6} className="">
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex  set-label"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                        <p>{order.employee && order.employee.fullname ? order.employee.fullname : ''}</p>
                        </Col>
                      </Row>
                      {
                        level.middle === true ? (
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              className="d-flex  set-label"
                            >
                              <Form.Label>ผู้จัดจำหน่าย</Form.Label>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                            {/* <p>{order.partner && order.partner.company_name ? order.partner.company_name : ''}</p> */}
                            <p>{order.partner_name ? order.partner_name : ''}</p>
                            </Col>
                          </Row>
                        ) : null
                      }
                      {/* {
                        level.middle === false ? ( */}
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              className="d-flex set-label"
                            >
                              <Form.Label>การใช้งาน</Form.Label>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                              <p>{OrderUseTypeTH(order.type_use)}</p>
                            </Col>
                          </Row>
                        {/* ) : null
                      } */}
                    </Col>
                    <Col md={12} lg={6} className="set-div-position ">
                      <Row className="justify-content-end set-row-status pt-4">
                        <Col xs={12} md={12} lg={8} xl={8} className="bg-admit">
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>เลขที่ใบสั่งซื้อ</Form.Label>
                            </Col>
                            <Col className="d-flex">
                            {
                              level.middle ? (
                                <p>{order.order_number}</p>
                              ): (
                                <p>{order.stock_code}</p>
                              )
                            }
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>วันรับเข้าระบบ</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p>{dayjs(order.order_date).format('DD-MM-YYYY')}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>วันที่บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p>{dayjs(order.created_at).format('DD-MM-YYYY')}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>ผู้บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                            <p className="text-p">: {order.employee && order.employee.fullname}</p>
                            </Col>
                          </Row>
                          {
                            level.middle === true ? (
                              <Row>
                                <Col
                                  xs={4}
                                  lg={5}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label>ชนิดภาษี</Form.Label>
                                </Col>
                                <Col className="d-flex">
                                  <p>{VatTypeTh(order.vat_type)}</p>
                                </Col>
                              </Row>
                            ) : null
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="g-0 justify-content-center">
                    <Row
                      className=" mt-3"
                      style={{ overflow: 'scroll', height: 'auto', maxHeight: '400px ' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th>รหัสสินค้า</th>
                            <th>ชื่อสินค้า</th>
                            <th>จำนวน</th>
                            <th>หน่วย</th>
                            {
                              (
                                order.middle === true ? (
                                  <th>ราคาต่อหน่วย</th>
                                ) : ''
                              )
                            }
                            {
                              (
                                order.middle === true ? (
                                  <th>ราคารวม</th>
                                ) : ''
                              )
                            }
                            
                            
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                        {order && order.details?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>{item.product.code}</td>
                                <td>{item.product.name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.product && item.product.unit && item.product.unit.name_buy}</td>
                                {
                                  (
                                    order.middle === true ? (
                                     <td>{item.price}</td>
                                   ) : ''
                                 )
                                }
                                {
                                  (
                                    order.middle === true ? (
                                      <td>{Number(item.total) - Number(item.discount_calculate)}</td>
                                    ) : ''
                                  )
                                }
                              </tr> 
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <p style={{ margin: 0 }}>{order.note}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className=" justify-content-center mt-4 mb-3">
                    <Col lg={9}>
                      <Row className="d-flex justify-content-start">
                        {/* <Col xs={6} lg={4} xl={3} className="mt-2">
                          <ReactToPrint
                            content={reactToPrintContent}
                            documentTitle={order.order_number}
                            onAfterPrint={handleAfterPrint}
                            onBeforeGetContent={handleOnBeforeGetContent}
                            onBeforePrint={handleBeforePrint}
                            removeAfterPrint
                            trigger={reactToPrintTrigger}
                          /> 
                        </Col> */}
                        <Col xs={6} lg={4} xl={3} className="mt-2 mb-3">
                            <Button className="button" onClick={() => genPDF()}>
                              <img src={Print} /> &nbsp; พิมพ์เอกสาร
                            </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <Row className="d-flex justify-content-end">
                        <Col className=" mt-2 mb-3">
                          <Button className="button" onClick={closePage}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: `none` }}>
          <div id="genPDF" style={{width: '210mm'}}><Bill9 order={order} ref={componentRef} /></div>
        </div>

      </Container>
      <Footer />
    </>
  );
}
