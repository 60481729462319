import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
// import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';

import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';

import SEARCH from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import Edit from 'assets/icon/key-fix.png';
import TabsIconUser from '../Components/layout/TabsIconUser';
import TabsIconUserB from 'BranchSystems/Components/layout/TabsIconUser';
import { Branch } from 'actions/branch/index';
import { Department } from 'actions/department/index';

import { useDispatch, useSelector } from 'react-redux';

import MyPagination from 'Components/MyPagination';

import { UserList, UpdateUserActive } from 'actions/user/index';
import UserImage from 'assets/icon/user.png'
import { loadAuth } from 'actions/auth';


// img
import Add from 'assets/icon/add-staff.png';


export default function Index() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [Users, setUsers] = useState([ 
  ]);
 
  const { employee } = useSelector( (state) => state.user)

  const levelString = localStorage.getItem('level');
  const level =JSON.parse(levelString);

  const [isLoading, setLoading] = useState(true);
  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);



  // search
  const [Keyword, setKeyword] = useState(null);
  const [DepartmentId, setDepartmentId] = useState('');
  const [BranchId, setBranchId] = useState('');

  const [Search, setSearch] = useState({
    Keyword: '',
    DepartmentId: null,
    BranchId: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });
  // pagination
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);

  const GetUser = () => {
    setLoading(true)
    const params = {
      keyword: Search.Keyword,
      branch_id: Search.BranchId,
      department_id: Search.DepartmentId,
      limit: Search.PerPage,
      page: Search.CurrentPage,
    };
    dispatch(UserList(params)).then(response => {
      if(response.data.data && response.data.data.data){
        const user = response.data.data.data
        setUsers(user);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        setUsers(response.data.data);
        setSearch({ ...Search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
    }).catch( e => {
      console.error(e);
      setLoading(false);
    })
  };
  
  
  const [Departments, setDepartments] = useState([]);
  const GetDepartment = (data) => {
    dispatch(Department()).then(response => {
      setDepartments(response.data.data);
    }).catch( e => {
      console.error(e);
    })
  };

  const [Branches, setBranches] = useState([]);
  const GetBranch = (data) => {
    dispatch(Branch()).then(response => {
      setBranches(response.data.data);
    }).catch( e => {
      console.error(e);
    })
  };

  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setKeyword(null);
    setDepartmentId(null);
    setBranchId('');
    setClearSearch(true);
  }

  const getAuth = () => {
    const params = {
      name: ['user'],
      permission : 'add',
    }

    dispatch(loadAuth(params)).then(response => {
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/user');
      }
    }).catch( e => {
      console.error(e);
    })
  };

  useEffect(() => {
    GetUser();
    GetDepartment();
    GetBranch();  
    getAuth();
  }, []);

  useEffect(() => {
    GetUser();
    setClearSearch(false);
  }, [Search.PerPage, Search.CurrentPage, ClearSearch , ]);

  function closePage() {
    let path = `/`;
    history.push(path);
  }

const SetStatusUser = (active, user) => {
  dispatch(UpdateUserActive({ id: user.id, data: { active } })).then(response => {
    setUsers(Users.map(el => el.id === user.id ? { ...el, active } : el))
  }).catch(e => {
    console.log(e)
  })
}

const optionStatusUser = [
    { value: false, label: 'ปิด' },
    { value: true, label: 'เปิด' },
];
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk ">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User &gt; รายชื่อผู้ใช้ระบบ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                    { 
                      employee.is_middle ?
                      <TabsIconUser />
                      :
                      <TabsIconUserB />
                    }
                </Row>
                <Row className="g-0 justify-content-start mb-3">
                  <Col
                    lg={5}
                    xl={4}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อพนักงาน"
                      value={Search.Keyword ? Search.Keyword : ''}
                      onChange={(e) => setSearch({...Search, Keyword: e.target.value})}
                      className="input"
                    ></Form.Control>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    className=" mt-2 mb-2"
                  >
                    <Select
                      options={Departments}
                      placeholder="แผนก"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={false}
                      isSearchable={false}
                      value={
                        Search.DepartmentId
                          ? Departments.find((data) => {
                              return data.id === Search.DepartmentId;
                            })
                          : null
                      }
                      // onChange={(data) => setDepartmentId(data.id)}
                      onChange={(e) => setSearch({...Search, DepartmentId: e.id})}
                      className="select-list"
                    />
                  </Col>

                  {
                    level.middle === true ? (
                      <Col
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        className=" mt-2 mb-2"
                      >
                        <Select
                          options={Branches}
                          placeholder="สาขา"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isClearable={false}
                          isSearchable={false}
                          value={
                           Search.BranchId
                              ? Branches.find((data) => {
                                  return data.id === Search.BranchId;
                                })
                              : null
                          }
                          // onChange={(data) => setBranchId(data.id)}
                          onChange={(e) => setSearch({...Search, BranchId: e.id})}
                          className="select-list"
                        />
                      </Col>
                    ) : ''
                  }

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={GetUser}>
                      <img src={SEARCH} /> &nbsp; ค้นหา
                    </Button>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={ResetSearch}>
                       <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>

                  <Col xs={6} className=" mt-2 mb-2 d-md-none">
                    <Link to="/user/add">
                      <Button className="button-menu">
                            <img src={Add} /> &nbsp; เพิ่มรายชื่อ
                      </Button>
                    </Link>
                  </Col>
                </Row>

                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>ชื่อพนักงาน</th>
                          <th>สาขา</th>
                          <th>แผนก</th>
                          <th>ชื่อผู้ใช้</th>
                          <th>เข้าใช้งานล่าสุด</th>
                          <th>Active</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {Users?.map((user) => {
                          return (
                            <tr key={user.id}>
                              <td>{`${user.employee?.fullname}`}</td>
                              <td>{`${user.employee?.branch.name}`}</td>
                              <td>{`${user.employee?.department?.name}`}</td>
                              <td>{`${user.username}`}</td>
                              <td>{`${
                                user.last_login_at ? user.last_login_at : '-'
                              }`}</td>
                              <td className='p-0'>
                                {
                                  user.branch_owner !== null ? '' : (
                                    <div className="div-select-table" >
                                      <Select
                                        options={optionStatusUser}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={ optionStatusUser.find(item => item.value === user.active)}
                                        onChange={(data) => SetStatusUser(data.value , user)}
                                        className={
                                          user.active ? 'green' : 'red'
                                        }
                                      />
                                    </div>
                                  )
                                }
                                
                              </td>
                              <td>
                                <Link to={`/user/edit/${user.id}`}>
                                  <Button className="button-package">
                                    <img src={Edit} />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={5} xl={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={7}>
                      <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          placeholder="การแสดงผล"
                          options={Pages}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          defaultValue={{ value: 10, label: '10' }}
                          onChange={
                            (e) => {setSearch({...Search, PerPage: e.value });}
                          }
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} 
                        pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> : ''
                      } */}
                      {
                        !isLoading ? 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Search.Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
