import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ToastContainer,
  Toast
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactTagInput from '@pathofdev/react-tag-input';
import Footer from 'Components/Footer';

import { useHistory, useParams , Link } from 'react-router-dom';
import Back from 'assets/icon/back.png';
import Branch_manage from 'assets/icon/branch-manage.png';
import Save from 'assets/icon/save.png';
import TabsIcon from 'Components/layout/TabsIcon';
import Increase from 'assets/icon/add_menu.png';
import Delete_Button from 'assets/icon/delete.png';
import Calendar from 'assets/icon/calendar-bg.png';
import { DatePicker, TimePicker, Switch } from 'antd';
//API
import { UpdateBranch, FindBranch, DestroyBranch } from 'actions/branch/index';
import { List } from 'actions/employee/index';
import { loadAuth } from 'actions/auth';

import moment from 'moment';
import { format } from 'path';
var dayjs = require('dayjs')
export default function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const TimeFormat = 'HH:mm';
  const DateFormat = 'YYYY-MM-DD';

  const ContactModel = {
    key: 0,
    name: null,
    phone: []
  }

  const FormModel = {
    active: false,
    name: null,
    phone: [],
    address: null,
    period: null,
    sales_target: null,
    open: null,
    closed: null,
    note: null,
    employee_id: null,
    bill_title: null,
    contacts: [
      ContactModel
    ]
  };

  const [Data, setData] = useState(FormModel);

  const [validated, setValidated] = useState(false);
  const [Employees, setEmployees] = useState([])
  const [errors, setErrors] = useState([]);

  const GetBranchInfo = async () => {
    try {
      const response = await dispatch(FindBranch(id));
      let data = response.data.data
      let contacts = []
      if (data.contacts && data.contacts.length > 0) {
        data.contacts.forEach((contact, key) => {
          let obj = {}
          obj.key = key
          obj.id = contact.id || null
          if(contact.branch_id) obj.branch_id = contact.branch_id
          obj.name = contact.name
          
          if (JSON.parse(contact.phone).length > 1) {
            obj.phone = JSON.parse(contact.phone)
          }else{
            obj.phone = JSON.parse(contact.phone)
          }
          contacts.push(obj)
        });
      }else{
        contacts.push(ContactModel)
      }
      console.log( )
      setData({
        active: data.active,
        name: data.name,
        phone: data.phone ? Array.isArray(data.phone) === true ? data.phone : [`${data.phone}`] : [],
        address: data.address,
        period: data.period_start && data.period_end &&[
          moment(data.period_start, DateFormat),
          moment(data.period_end, DateFormat)
        ],
        sales_target: data.sales_target,
        open: data.open ? moment(data.open, TimeFormat) : null,
        closed: data.closed ? moment(data.closed, TimeFormat) : null,
        note: data.note,
        employee_id: data.owner && data.owner[0] && data.owner[0].id ? data.owner[0].id : null,
        bill_title: data.bill_title,
        contacts: contacts,
        branche_code: data.branche_code,
        owner_current_branch: data.owner && data.owner[0] && data.owner[0].fullname
      });
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการสาขา'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/management');
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const contacts = []
    Data.contacts.forEach((contact, key) => {
      let obj = {}
      obj.name = contact.name
      obj.phone = JSON.stringify(contact.phone)
      contacts.push(obj)
    });
    
    const input = {...Data,
      period_start: (Data.period && Data.period[0]) ? dayjs(Data.period[0]).format('YYYY-MM-DD') : null,
      period_end: (Data.period && Data.period[1]) ? dayjs(Data.period[1]).format('YYYY-MM-DD') : null,
      open: (Data.open) ? dayjs(Data.open).format('HH:mm') : null,
      closed: (Data.closed) ? dayjs(Data.closed).format('HH:mm') : null,
      contacts: contacts
    };

    const result = {
      id: id,
      data: input
    }

    try {
      const response = await dispatch(UpdateBranch(result));
      setData(FormModel)
      if(response){
        history.push('/management');
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };
  
  const GetEmployee = async () => {
    const paramas = {
      branch_has_owner: true,
      user: true
    }
    try {
      const response = await dispatch(List(paramas));
      setEmployees(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const InsertContact = () => {
    const contacts = Data.contacts
    const model = {
      key: Data.contacts.length,
      name: null,
      phone: [],
    }
    contacts.push(model)
    const result = contacts

    setData({ ...Data, contacts: result })
  }

  const RemoveContact = (key) => {
    const contacts = Data.contacts.filter((item) => item.key !== key)
    setData({ ...Data, contacts: contacts })
  }


  useEffect(() => {
    GetBranchInfo();
    GetEmployee();
    getAuth();
  }, []);

  function closePage() {
    history.push(`/management`);
  }

  function checkTime(type, time) {
    switch (type) {
      case 'open':
        setData({...Data, open: time})
        break;
      case 'closed':
        if (Data.open && moment(time.format('HH:mm'), 'HH:mm') < moment(Data.open.format('HH:mm'), 'HH:mm')){
          setErrors([...errors, 'เวลาปิดร้านต้องไม่เร็วกว่าเวลาเปิดร้าน'])
          return false
        }
        setData({...Data, closed: time})
        break;
    }
  }

  function addCommas(x) {
    if (typeof x === 'number') {
      return parseInt(x).toLocaleString()
    }
    return parseInt(x.replaceAll(',', '')).toLocaleString()
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState({
    key: null
  });
  const [deleteValidated, setDeleteValidated] = useState(false);

  function deleteConfirmation() {
    // setDeleting(customer)
    setConfirmation(true)
  }

  const DeleteBranch = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setDeleteValidated(true);
    
    const params = {
      key: deleting.key
    }

    dispatch(DestroyBranch({id, params})).then( response => {
      setConfirmation(false)
      history.push('/management')
    }).catch(e => {
      console.log(e)
    })
  }

  return (
    <>
    <Container fluid className="package-container  management-edit bg-mrk">
    <div className="package">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <img className="logo pr-2" src={Branch_manage} />
              <b><Link to="/management" className='link-pagination'>การจัดการสาขา</Link> &gt; แก้ไขสาขา</b>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closePage}
            >
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div className="modal-header-mobile">
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closePage}
            >
              <img src={Back} />
            </button>
            <h5 className="modal-title" id="exampleModalLabel">
              <img className="logo pr-2" src={Branch_manage} />
              <b><Link to="/management" className='link-pagination'>การจัดการสาขา</Link> &gt; แก้ไขสาขา</b>
            </h5>
          </div>
          <div className="modal-body">
            <ToastContainer position='top-end'>
              {
                errors?.map((error, key) => {
                  return (
                    <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                      <Toast.Header>
                        <strong className="me-auto">แจ้งเตือน</strong>
                      </Toast.Header>
                      <Toast.Body>{ error }</Toast.Body>
                    </Toast>
                  )
                })
              }
            </ToastContainer>
            <Row className="g-0 tabsicon justify-content-center ">
              <TabsIcon />
            </Row>
            <Row className="line-top-form justify-content-center"></Row>
            <Row>
              <Col
                color={12}
                className="text-right"
              >
                <Button 
                  type="button"
                  className="in-save"
                  onClick={() => deleteConfirmation()}
                >
                  ลบ
                </Button>
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <Row className="d-md-block d-lg-none">
                    <Col className="d-flex justify-content-start ">
                      <Form.Label>
                        <Switch checked={Data.active} onChange={(boolean) => setData({...Data, active: boolean})} />
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start "
                        >
                          <Form.Label className="mt-3">ชื่อสาขา</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control 
                            className="input-search-add-staff"
                            value={Data.name}
                            required
                            onChange={(e) => setData({...Data, name: e.target.value})}
                          ></Form.Control>
                          <p className="text-right m-0">{Data.name && `${Data.name.length}/`}150</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg={5}
                      className="mt-2 d-flex justify-content-start   d-md-none d-lg-block"
                    >
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start"
                        >
                          <Form.Label>
                            <Switch 
                            checked={Data.active}
                            onChange={(boolean) => setData({...Data, active: boolean})} />
                          </Form.Label>
                        </Col>
                        <Col className="d-flex justify-content-start align-items-center">
                          <Form.Label className="text-bold">
                            {Data.active ? 'เปิดการใช้งาน': 'ปิดการใช้งาน'}
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <Form.Label>รหัสสาขา</Form.Label>
                        </Col>
                        <Col className="d-flex justify-content-end">
                        <Form.Control 
                              className="input-search-add-staff"
                              value={Data.branche_code}
                              required
                              onChange={(e) => setData({...Data, branche_code: e.target.value})}
                            ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <Form.Label>เบอร์โทรศัพท์</Form.Label>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <div className={'w-100 ' + (!Data.phone | Data.phone.length === 0 && validated ? 'validate-fail-tags' : '') }>
                            <ReactTagInput
                              placeholder="เบอร์โทรศัพท์"
                              editable={true}
                              removeOnBackspace={true}
                              maxTags={2}
                              tags={Data.phone}
                              onChange={(newTags) => setData({ ...Data, phone: newTags }) }
                              className="input-tage"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start"
                        >
                          <Form.Label className="mt-3">ที่อยู่</Form.Label>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className="input-area-box"
                            value={Data.address}
                            onChange={(e) => setData({...Data, address: e.target.value})}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row className="align-items-center">
                        <Col
                          lg={3}
                          className="d-flex justify-content-start"
                        >
                          <Form.Label>เวลาเปิด-ปิด</Form.Label>
                        </Col>
                        <Col className="">
                          <Row>
                            <Col xs={5} lg={5}>
                              <TimePicker placeholder='เวลาเปิด' format={TimeFormat} value={Data.open ? moment(Data.open, 'HH:mm') : null} onChange={(time) => checkTime('open', time)}/>
                            </Col>
                            <Col
                              xs={2}
                              lg={2}
                              className="d-flex justify-content-center align-items-center"
                            >
                              &ndash;
                            </Col>
                            <Col xs={5} lg={5}>
                              <TimePicker placeholder='เวลาปิด' format={TimeFormat} value={Data.closed ? moment(Data.closed, 'HH:mm') : null}  onChange={(time) => checkTime('closed', time)}/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  { 
                    Data.contacts?.map((contact, key) => {
                      return (
                        <>
                  <Row className="align-items-center mb-1">
                    <Col lg={10}>
                      <Row className="align-items-center">
                        <Col xs={12} sm={12} lg={6}>
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>ชื่อผู้ติดต่อ</Form.Label>
                            </Col>
                            <Col className='set-padding'>
                            <Form.Control 
                            className="input-search-add-staff" 
                            value={contact.name}
                            required
                            onChange={
                              (e) => setData(prevState => ({
                                ...Data,
                                contacts: prevState.contacts.map(
                                  el => el.key === key ? { ...el, name: e.target.value }: el
                                )
                              })) 
                            }
                          ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={5}>
                          <Row className="align-items-center">
                            <Col lg={3} className="text-left">
                              <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            </Col>
                            <Col lg={9}>
                              <ReactTagInput
                                placeholder="เบอร์โทรศัพท์"
                                editable={true}
                                readOnly={false}
                                removeOnBackspace={true}
                                maxTags={2}
                                tags={contact.phone && contact.phone.length > 0 ? contact.phone : []}
                                onChange={
                                  (newTags) => setData(prevState => ({
                                    ...Data,
                                    contacts: prevState.contacts.map(
                                      el => el.key === key ? { ...el, phone: newTags }: el
                                    )
                                  })) 
                                }
                                className="input-search-add-staff"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={1}  className="mt-2 mb-2">
                          {
                            key > 0 
                            &&
                            <Button
                              type="button"
                              className="button-package"
                              onClick={() => RemoveContact(contact.key)}
                            >
                              <img src={Delete_Button} />
                            </Button>
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2} className="mb-2">
                        {
                          Data.contacts?.length - 1 === key 
                          &&
                          <Button 
                            type="button" 
                            className="in-save" 
                            onClick={() => InsertContact()}
                          >
                            <img src={Increase} /> &nbsp; เพิ่มรายชื่อ
                          </Button>
                        }
                    </Col>
                  </Row>
                      </>
                      )
                    })
                  }
                  <Row className='mt-2'>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control 
                          as="textarea"
                          rows={3}
                          className="input-area-box"
                          value={Data.note}
                          onChange={(e) => setData({...Data, note: e.target.value})}
                        ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          ชื่อผู้ใช้ปัจุบัน
                        </Col>
                        <Col>
                          { Data.owner_current_branch}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          ชื่อผู้ใช้
                        </Col>
                        <Col>
                          
                          <Form.Select 
                            className="select-style-bootrap" 
                            value={Data.employee_id} 
                            required 
                            onChange={(e) => setData({...Data, employee_id: e.target.value })}
                            >
                            <option hidden selected>เลือกผู้ใช้ใหม่</option>
                            {
                              Employees?.map(item => {
                                return (
                                  <option value={item.id}>{item.fullname}</option>
                                )
                              })
                            }
                          </Form.Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <Row>
                        <Col
                          lg={3}
                          className="d-flex justify-content-start align-items-center"
                        >
                          หัวบิล
                        </Col>
                        <Col>
                          {/* <Form.Control 
                            className="input-search-add-staff"
                            value={Data.bill_title}
                            onChange={(e) => setData({...Data, bill_title: e.target.value})}
                          ></Form.Control> */}
                          <Form.Control 
                            as="textarea"
                            rows={3}
                            className="input-area-box"
                            value={Data.bill_title}
                            onChange={(e) => setData({...Data, bill_title: e.target.value})}
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={10}
                  lg={8}
                  className="border-colum-time mt-4"
                >
                  <Row >
                    <Col ms={12} sm={12} lg={6}>
                      <Row >
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          className="text-input d-flex justify-content-start align-items-center"
                        >
                          <p className='m-0'>เป้าขาย</p>
                        </Col>
                        <Col xs={8} sm={9} md={9} lg={9}>
                          <Form.Control
                            type="text"
                            placeholder=""
                            className="input-search-add-staff"
                            value={Data.sales_target ? addCommas(Data.sales_target) : '' }
                            onChange={(e) => setData({ ...Data, sales_target: e.target.value.replaceAll(',', '') })}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                    <Col ms={12} sm={12} lg={6}>
                      <Row>
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          className="text-input d-flex justify-content-start align-items-center"
                        >
                          <p className='m-0'>ช่วงเวลา</p>
                        </Col>
                        <Col xs={8} sm={9} md={9} lg={9}>
                          <DatePicker.RangePicker
                            value={Data.period ? Data.period : null}
                            onChange={(date) => setData({ ...Data, period: date })}
                            placeholder=""
                            suffixIcon={<img src={Calendar} />}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="mt-3 mb-3">
                  <Button type="submit" className="in-save ">
                    <img src={Save} /> &nbsp; บันทึก
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบสาขา { `${Data?.name}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={deleteValidated} onSubmit={DeleteBranch}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: 'auto' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบสาขา {  `${Data?.name}` }</h3>
              </Col>
              <Col xs={10} lg={10} className="mt-2 ">
                <Form.Control
                  type="password"
                  placeholder="รหัสลับ"
                  value={deleting.key ? deleting.key : ''}
                  required
                  className='m-0 mx-auto'
                  onChange={(e) => setDeleting({ ...deleting, key: e.target.value})}
                ></Form.Control>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center mt-4">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}