import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ToastContainer,
  Toast
} from 'react-bootstrap';
import Select, { components } from 'react-select';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';
import MyPagination from 'Components/MyPagination'

// Icon
import Setting from 'assets/icon/setting.png';
import Back from 'assets/icon/back.png';
import Edit from 'assets/icon/key-fix.png';
import Add from 'assets/icon/add_menu.png';
import Cancel from 'assets/icon/delete.png';

// API
import { UnitList, CreateUnit, DestroyUnit } from 'actions/settings/unit';
import { loadAuth } from 'actions/auth';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const model = {
    type: null,
    name_buy: null,
    value_buy: null,
    sales:[
      {
        key: 0,
        name_sale: null,
        value_sale: null
      }
    ]
  }

  const [Data, setData] = useState(model)

  const [Type, setType] = useState([
    { value: 'ชิ้น', label: 'ชิ้น' },
    { value: 'ความยาว', label: 'ความยาว' },
    { value: 'น้ำหนัก', label: 'น้ำหนัก' },
  ]);

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [isLoading, setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Rows, setRows] = useState(0);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });

  const [Units, setUnits] = useState([]);

  const GetUnit = async (data) => {
    setisLoading(true)
    const params = {
      limit: Search.PerPage,
      page: Search.CurrentPage
    }
    try {
      const response = await dispatch(UnitList(params));
      if (response) {
        if (Search.PerPage) {
          setUnits(response.data.data.data);
          setRows(response.data.data.last_page);
          setisLoading(false)
        }else{
          setUnits(response.data.data);
          setRows(0);
          setisLoading(false)
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    try {
      const response = await dispatch(CreateUnit(Data));
     
      if(response){
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          history.push('/setting');
        }
        setData(model);
        setValidated(false);
        GetUnit();
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  };

  const InsertSale = () => {
    const obj = {
      key: Data.sales.length,
      name_sale: null,
      value_sale: null
    }
    setData({...Data, sales:[...Data.sales, obj]})
  }

  const removeSale = (key) => {
    console.log(key)
    let data = Data.sales
    const index = data.findIndex(item=> item.key === key)
    data.splice(index, 1)
    data.forEach((item, key) => {
      item.key = key
    });
    // const obj = {
    //   key: Data.sales.length,
    //   name_sale: null,
    //   value_sale: null
    // }
    setData({...Data, sales: data})
  }

  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'view'});
    GetUnit();
  }, [Search.PerPage, Search.CurrentPage]);

  function close() {
    history.push(`/setting`);
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);
  // const [errors, setErrors] = useState([]);

  function deleteConfirmation(unit) {
    setDeleting(unit)
    setConfirmation(true)
  }

  const DeleteUnit = () => {
    dispatch(DestroyUnit(deleting.id)).then( response => {
      GetUnit()
      setDeleting(null)
      setConfirmation(false)
    }).catch(e => {
      console.log(e)
    })
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        {/* <ToastContainer position='top-end'>
          {
            errors?.map((error, key) => {
              return (
                <Toast show={true} key={key} delay={2500} autohide onClose={() => setErrors([])}>
                  <Toast.Header>
                    <strong className="me-auto">แจ้งเตือน</strong>
                  </Toast.Header>
                  <Toast.Body>{ error }</Toast.Body>
                </Toast>
              )
            })
          }
        </ToastContainer> */}
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b> <Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; หน่วย</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b> <Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; หน่วย</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="g-0 justify-content-center">
                    <Col xs={12}>
                      <Row className="justify-content-center">
                        <Col xs={12} md={9} lg={12}>
                          <Row className="mt-2 mb-2">
                            <Col
                              xs={4}
                              lg={2}
                              className="text-left d-flex align-items-center"
                            >
                              <Form.Label>ประเภทหน่วย</Form.Label>
                            </Col>
                            <Col xs={8} lg={3}>
                              <Form.Select 
                                required
                                onChange={(e) => setData({...Data, type: e.target.value })}
                              >
                                <option value="" hidden>เลือกประเภทหน่วย</option>
                                {
                                  Type?.map(item => {
                                    return (
                                      <option value={item.value}>{item.value}</option>
                                    )
                                  })
                                }
                              </Form.Select>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={9} lg={12}>
                          <Row className="mt-2 mb-2">
                            <Col
                              xs={4}
                              lg={2}
                              className="text-left d-flex align-items-center"
                            >
                              <Form.Label>หน่วยซื้อ</Form.Label>
                            </Col>
                            <Col xs={8} lg={3} className="mt-2 mb-2">
                              <Form.Control 
                                type='text'
                                placeholder="หน่วยซื้อ"
                                value={Data.name_buy ? Data.name_buy : ''}
                                onChange={(e) => setData({ ...Data, name_buy: e.target.value })}
                                required
                              ></Form.Control>
                            </Col>
                            <Col
                              xs={4}
                              lg={1}
                              className=" d-flex align-items-center"
                            >
                              <Form.Label>จำนวน</Form.Label>
                            </Col>
                            <Col xs={8} lg={3} className="mt-2 ">
                              <Form.Control
                                type='number'
                                placeholder=""
                                value={Data.value_buy ? Data.value_buy : ''}
                                disabled={!Data.name_buy}
                                required
                                onChange={(e) => setData({ ...Data, value_buy: e.target.value })}
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        {
                          Data.sales?.map((sale, key) => {
                            return(
                              <Col xs={12} md={9} lg={12} key={key}>
                                <Row className="mt-2 mb-2">
                                  <Col
                                    xs={4}
                                    lg={2}
                                    className="text-left d-flex align-items-center"
                                  >
                                    <Form.Label>หน่วยขาย { key + 1 }</Form.Label>
                                  </Col>
                                  <Col xs={8} lg={3} className="mt-2 mb-2">
                                    <Form.Control 
                                      type='text'
                                      placeholder="หน่วยขาย"
                                      value={sale.name_sale ? sale.name_sale : ''}
                                      required
                                      onChange={(e) => setData(prevState => ({...Data,
                                        sales: prevState.sales.map(
                                          el => el.key === key ? { ...el, name_sale: e.target.value }: el
                                        )
                                      }))}

                                    ></Form.Control>
                                  </Col>
                                  <Col
                                    xs={4}
                                    lg={1}
                                    className="text-left d-flex align-items-center"
                                  >
                                    <Form.Label>จำนวน</Form.Label>
                                  </Col>
                                  <Col xs={8} lg={3} className="mt-2 mb-2">
                                    <Form.Control
                                      type='number'
                                      placeholder=""
                                      value={sale.value_sale ? sale.value_sale : ''}
                                      disabled={!sale.name_sale}
                                      required
                                      onChange={(e) => setData(prevState => ({...Data,
                                        sales: prevState.sales.map(
                                          el => el.key === key ? { ...el, value_sale: e.target.value }: el
                                        )
                                      }))}
                                    ></Form.Control>
                                  </Col>
                                  <Col xs={12} lg={4} xl={3} className="mt-2 mb-2 d-flex">
                                    {
  
                                      <Row className="justify-content-around w-100">
                                        <Col xs={12} lg={12} className='d-flex w-100'>
                                          {
                                            key > 0
                                            &&
                                            <img src={Cancel} style={{width: '40px', height: '40px'}} onClick={() => removeSale(key)}/>
                                          }
                                          
                                          {
                                            (Data.sales.length - 1) === key
                                            &&
                                            <Button type="button" className="ml-4 button w-100" onClick={() => InsertSale()}>
                                              <img src={Add} /> &nbsp; เพิ่มหน่วยขาย
                                            </Button>
                                          }

                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            )
                          })
                        }
                      </Row>

                      <Row className="justify-content-around">
                        <Col xs={6} sm={12} md={12} lg={4} xl={3}>
                          <Button type="submit" className="button">
                            <img src={Add} /> &nbsp; เพิ่มรายการ
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <Row className='mt-4'></Row>                
                <div className="set-height">
                  <Row className="g-0  justify-content-center">
                    <Row className="">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">หน่วยซื้อ</th>
                            <th className="text-left">หน่วยขาย</th>
                            <th className="text-left">คำอธิบาย</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            Units?.map((unit, key) => {
                              return(
                                <tr className="t-tr-setting" key={key}>
                                  <td className="text-left">{unit.name_buy}</td>
                                  <td className="text-left">{
                                    unit.sales?.map((item, key) => {
                                      return(
                                        <p>{ `- ${item.name_sale}` }</p>
                                      )
                                    })
                                  }</td>
                                  <td className="text-left">
                                    <p>{`${unit.value_buy} ${unit.name_buy}`}</p>
                                    <p>แบ่งขาย</p>
                                    {
                                      unit.sales?.map((item, key) => {
                                        return(
                                          <p>{ `- ${item.value_sale} ${item.name_sale}` }</p>
                                        )
                                      })
                                    }
                                  </td>
                                  <td className="text-left">
                                    <Link to={`/setting/unit/${unit.id}/edit`}>
                                      <Button type="button" className="button-image">
                                        <img src={Edit} />
                                      </Button>
                                    </Link>
                                    &nbsp; &nbsp;
                                    <Button type="button" className="button-image" onClick={() => deleteConfirmation(unit)}>
                                      <img src={Cancel} />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          isSearchable={false}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) => setSearch({...Search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {
                        !isLoading ? <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e}) }}></MyPagination> : ''
                      } */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบหน่วย { `${deleting?.name_buy}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบหน่วย { `${deleting?.name_buy}` }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteUnit() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
