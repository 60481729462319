import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
  Dropdown
} from 'react-bootstrap';
import { Switch } from 'antd';

import BillImage from 'assets/icon/job.png'
import Footer from '../Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/flat-style-circle-write.png';
import Arrow from '../assets/icon/arrow.png';
import AddLink from '../assets/icon/add-link.png';
import Delete from '../../assets/icon/delete.png';

import Document from '../assets/icon/document.png';
import Search from '../assets/icon/eyes-search.png';
import Save from '../assets/icon/flat-style-circle-save.png';
import Return from '../assets/icon/flat-style-circle-turn-on.png';
import Product_Component from '../Components/Job/Product';
import Service_Component from '../Components/Job/Service';
import Package_Component from '../Components/Job/Package';
import { useDispatch } from 'react-redux';

//API
import { Customer } from 'actions/customer'
import { CarOwnerList } from 'actions/customer/car'
import { PackageList, PackageTypeList } from 'actions/package';
import { JobList } from 'actions/jobs'
import { LoadServiceAppointments } from 'actions/service_appointment'
import { loadAuth } from 'actions/auth';
import dayjs from 'dayjs';
import { data } from 'jquery';

import { List } from 'actions/employee'
import { Branch } from 'actions/branch'

export default function InsideData() {
  const history = useHistory();
  const dispatch = useDispatch();

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

  const [filterCustomers, setfilterCustomers] = useState([]);
  const [filterCustomersDefault, setfilterCustomersDefault] = useState([]);
  const [filterCustomersBySelect, setfilterCarOwnersBySelect] = useState([]);
  const [filterCarOwners, setfilterCarOwners] = useState([]);
  const [filterCarOwnersDefault, setfilterCarOwnersDefault] = useState([]);

  const [SearchCustomerName, setSearchCustomerName] = useState('');
  const [SearchCarLicensePlateName, setSearchCarLicensePlateName] = useState('');
  const [Data,setData] = useState({
    CustomerId: null,    
    CarId: '',
    PackageTypeId: '',
    PackageTypeName: '',
    PackageStorageList:[],
    PackageAppointment:[],
    PackageId: '',
    PackageName:'',
    CleanCar: true,
    ContinuousService: false,
    SprayCoating: false,
    RenewalClaim:false,
    SetUp:false,
    Treatment:false,
    KeepWork:false,
    enamel: null,
    wash: null,
    attrition: false,
    ozone_oven: false,
    wash_upholstery: null,
    wash_belt: null,
    wash_door: null,
  });
  const [NotHaveCar,setNotHaveCar] = useState(true);
  const [statusSwitch,setStatusSwitch] = useState('new');
  const [displayDetail,setDisplayDetail] = useState(
    {
      car_id: null,
      license_plate: '',
      car_brand_name: '',
      car_model_name: '',
      color: '',
      plate_type: '',
      province_name: '',
      chassis_number: '',
      special_need: '',
      note:'',
    }
  );
  const [AppointmentDisplay,setAppointmentDisplay] = useState([]);
  const [Job,setJob] = useState([]);
  const [PackageTypes,setPackageTypes] = useState([]);
  const [Packages,setPackages] = useState([]);
  // const [PackageAppointment, setPackageAppointment] = useState([])
  
  const [isLoading, setLoading] = useState(true);
  const [selectSearch, setSelectSearch] = useState({ kindOfStand: "customer"});
  const [ServiceAppointment,setServiceAppointment] = useState([]);
  const toppings = [
    {value: 'ล้างรถ', name: 'ล้างรถ'},
    {value: 'เช็คการเซตตัว', name: 'เช็คการเซตตัว'},
    {value: 'ทรีตเมนต์', name: 'ทรีตเมนต์'},
    {value: 'เก็บงาน', name: 'เก็บงาน'},
    {value: 'เคลมต่ออายุ', name: 'เคลมต่ออายุ'},
  ];

  const [CustomerKeyword,setCustomerKeyword] = useState('');
  const [CarOwnerPlateName,setCarOwnerPlateName] = useState('');
  const [CarOwnerCustomerId,setCarOwnerCustomerId] = useState('');
  const [CarSerieId,setCarSerieId] = useState('');
  
  const getAuth = async () => {
    try {
      const params = {
        name: ['job'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function clearSearchE() {
    setData({...Data, 
      employee_id: null,
      branch_id: null,
    })
  }

  const handleChange = e => {
    e.persist();
    // console.log(e.target.value);

    setSelectSearch(prevState => ({
      ...prevState,
      kindOfStand: e.target.value
    }));
    clearSearch()
    
  };
  function clearSearch(){
    
    setData({...Data, 
      CustomerId: null,
      CarId: '',
      CustomerFullname: null,
      CarOwnerLicensePlate: '',
      SpecialPackages: [],
      employee_id: null,
      branch_id: null,
    })
    setNotHaveCar(true)
    setDisplayDetail({...displayDetail,
      aka: '',
      phone: [],
      car_id:null,
      customer_code: '',
      license_plate: '',
      car_brand_name: '',
      car_model_name: '',
      color: '',
      plate_type: '',
      province_name: '',
      chassis_number: '',
      special_need: '',
      note:'',
    });

  }
  function setCustomersList(item, key) {
  
    if(selectSearch.kindOfStand === 'customer'){
      setCarOwnerCustomerId(item.id)
      setData({...Data, CustomerId: item.id ,
        // CarOwnerLicensePlate: '',
        CustomerFullname: item.type === 'บุคคลธรรมดา' ? item.full_name : item.type === 'นิติบุคคล' ? item.organization : item.first_name,
      })
      setNotHaveCar(item.cars.length < 1 ? true : false)
      setSearchCustomerName(item.type === 'บุคคลธรรมดา' ? item.full_name  : item.type === 'นิติบุคคล' ? item.organization : item.first_name)

      setfilterCarOwners(item.cars)
      setfilterCarOwnersDefault(item.cars)
    }else{
      setData({...Data, CustomerId: item.id ,
        CustomerFullname: item.type === 'บุคคลธรรมดา' ? item.full_name  : item.type === 'นิติบุคคล' ?
         item.organization : item.first_name,
      })
      setSearchCustomerName( item.type === 'บุคคลธรรมดา' ? item.full_name  : item.type === 'นิติบุคคล' ?
      item.organization : item.first_name)
      
    }
    // console.log('item.phone',item);
    setDisplayDetail({...displayDetail , 
      customer_code: item.customer_code, 
      aka: item.aka,
      phone: item.phone ? item.phone.replace(/"|\[|\]/g, '') : JSON.parse(item.phone), 
      note: item.note, 
      special_need: item.special_need,
    })
    
    
  }

  function setEmployeeList(item, key){
    // console.log(item)
    setData({...Data, employee_id: item.id})
    setSearchEmployeesName(item ? item.fullname + ' - ' + item.branch.name : '')
    parseDataJob.employee_id = item.id ? item.id : null
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function setBranchList(item, key){
    // console.log(item)
    setData({...Data, branch_id: item.id})
    setSearchBranchName(item ? item.name : '')
    parseDataJob.branch_id = item.id ? item.id : null
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function SetPackageType(e){
    // console.log('SetPackageType',Data);
    setData({...Data, PackageTypeId: e.id, PackageTypeName: e.name, PackageId: '', })
  }

  function SetDataPackage(e){
    setData({...Data, PackageId: e.id, PackageName: e.name, PackageCode: e.code, JobId: '',
     PackageType: e.category,
    });
    // getJob(e.id)
    getServiceAppointments(e.id);
    // console.log('SetDataPackage',Data);
  }

  const [attrition, setAttrition] = useState(false);
  const handleAttrition = () => {
    setAttrition(!attrition);
    updateLocalStorage(!attrition,null,'attrition');
  };
  const [ozone_oven, setOzoneOven] = useState(false);
  const handleOzoneOven = () => {
    setOzoneOven(!ozone_oven);
    updateLocalStorage(!ozone_oven,null,'ozone_oven');
  };
  function updateLocalStorage(data,item,form){

    switch (form) {
      case 'wash':
        setData({...Data, wash: data.target.value })
        Data.wash = data.target.value
        break;
      case 'enamel':
        setData({...Data, enamel: data.target.value })
        Data.enamel = data.target.value
        break;
      case 'attrition':
        setData({...Data, attrition: !attrition })
        Data.attrition = !attrition
        break;
      case 'wash_upholstery':
        setData({...Data, wash_upholstery: data.target.value })
        Data.wash_upholstery = data.target.value
        break;
      case 'wash_belt':
        setData({...Data, wash_belt: data.target.value })
        Data.wash_belt = data.target.value
        break;
      case 'wash_door':
        setData({...Data, wash_door: data.target.value })
        Data.wash_door = data.target.value
        break;
      case 'ozone_oven':
        setData({...Data, ozone_oven: !ozone_oven })
        Data.ozone_oven = !ozone_oven
        break;
      default:
        break;
    }

    const result = Data.PackageStorageList

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.wash = Data.wash
    parseDataJob.employee_id = null;
    parseDataJob.enamel = Data.enamel
    if(form === 'attrition') { parseDataJob.attrition = Data.attrition }

    parseDataJob.wash_upholstery = Data.wash_upholstery
    parseDataJob.wash_belt = Data.wash_belt
    parseDataJob.wash_door = Data.wash_door
    if(form === 'ozone_oven') { parseDataJob.ozone_oven = Data.ozone_oven }

    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

      // let DataJob = localStorage.getItem('job-inside-data')
      // let parseDataJob = JSON.parse(DataJob);
      // let parsePackageType = parseDataJob.PackageType;

      // const index =  parsePackageType.findIndex((findPackageType) => 
      // {return findPackageType.key === PackageKey})

      // parsePackageType[index] = Data;
      // parseDataJob.PackageType= parsePackageType;
      // localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
}

  function SetDataJob(e){

    //! ถ้าเป็น special package ต้อง setup ค่าของ package ทุกตัวที่อยู่ใน special package`
    if(e.job_detail.package_type_id === 6){
      let stack_special_package = []
      const special_package = e.job_detail.service_appointments
      special_package.map((item) => {
       
        let treatment_date_number = null
        let treatment_claim_renewal = null
        if(JSON.parse(item.package.treatment_ranges)){
          const ranges = JSON.parse(item.package.treatment_ranges)
          treatment_date_number = Number(ranges[0]) * 30 
          treatment_claim_renewal = Number(ranges[1]) * 30 + 1
        }
        let set_action_special_package = {
          job_id: e.job_detail.job_id ,
          job_detail_id: item.job_detail_id,
          package_id: item.package_id,
          package: item.package,
          setup: item.setup, spray_coating: item.spray_coating, treatment: item.treatment,
          treatment_ranges: item.treatment_ranges, 
          keep_work: item.keep_work, TypeWork: item.type, JobDetailId: e.job_detail_id,
          service_appointment_id: e.job_detail.service_appointment_id,
          clean_total: item.clean_total, clean_remaining: item.clean_remaining,
          CleanCar: true,
          appointment_date: dayjs(item.next_date).add(treatment_date_number, 'day').format('YYYY-MM-DD'),
          claim_renewal_date: dayjs(item.next_date).add(treatment_claim_renewal, 'day').format('YYYY-MM-DD'),
          day_now: dayjs().format('YYYY-MM-DD'),
          total: item.total,
          remaining: item.remaining,

        }

        stack_special_package.push(set_action_special_package)
      })
     
      
      setData({...Data, JobId: e.id ,SpecialPackages: stack_special_package,
        service_appointment_id: e.job_detail.service_appointment_id, 
        CleanCar: true,
      })
    }else{
      let treatment_date_number = null
      let treatment_claim_renewal = null
      if(JSON.parse(e.treatment_ranges)){
        const ranges = JSON.parse(e.treatment_ranges)
        treatment_date_number = Number(ranges[0]) * 30 
        treatment_claim_renewal = Number(ranges[1]) * 30 + 1
      }
      setData({...Data, JobId: e.id , setup: e.setup, spray_coating: e.spray_coating, treatment: e.treatment,
        treatment_ranges: e.treatment_ranges, 
        keep_work: e.keep_work, TypeWork: e.job_detail.type, JobDetailId: e.job_detail_id,
        service_appointment_id: e.job_detail.service_appointment_id,
        clean_total: e.clean_total, clean_remaining: e.clean_remaining,
        CleanCar: true,
        appointment_date: dayjs(e.next_date).add(treatment_date_number, 'day').format('YYYY-MM-DD'),
        claim_renewal_date: dayjs(e.next_date).add(treatment_claim_renewal, 'day').format('YYYY-MM-DD'),
        day_now: dayjs().format('YYYY-MM-DD'),
        total: e.total,
        remaining: e.remaining,
        // category: e.category,
      })
    }
    console.log(Data);

    // SpecialPackages: e.package_type_id === 6 ? e.special_packages : [],
  }

  function SetAppointmentSelect(e){
    // console.log(e.clean_total);
    setAppointmentDisplay({...AppointmentDisplay, e})
    console.log(AppointmentDisplay);
  }

  function setCarOwnerList(item,key){
    // console.log('item',item);
    setCarSerieId(item.car_serie_id);
    setSearchCarLicensePlateName(item.license_plate)
    setData({...Data, CarOwnerLicensePlate: item.license_plate })
    if(selectSearch.kindOfStand === 'car'){
      setfilterCustomers(item.customers)
      setfilterCarOwnersBySelect(item.customers)
      // setJob(item.customers.job)
    }
    
    setDisplayDetail({...displayDetail , 
      car_id: item.id,
      license_plate: item.license_plate ? item.license_plate : '',
      car_brand_name: item.car_serie && item.car_serie.car_model && item.car_serie.car_model.car_brand && item.car_serie.car_model.car_brand.name && item.car_serie.car_model.car_brand.name ? item.car_serie.car_model.car_brand.name : '',
      car_model_name: item.car_serie && item.car_serie.car_model && item.car_serie.car_model.model ? item.car_serie.car_model.model : '',
      color: item.color,
      plate_type: item.plate_type,
      province_name: item.province && item.province.name_th ? item.province.name_th : '',
      chassis_number: item.chassis_number,
    })
    setData({...Data, CustomerId:item.customer_id, CarId: item.id})
  }

  const [filterEmployees, setfilterEmployees] = useState([]);
  const [filterEmployeesD, setfilterEmployeesD] = useState([]);
  const GetEmployee = async () => {
    try {
      const pr = {
        all: true,
        keyword: EmployeesKeyword,
      }
      const res = await dispatch(List(pr));
      let data = res.data.data;
      setfilterEmployees(data)
      setfilterEmployeesD(data)
      // console.log(filterEmployeesD)
    } catch (e) {
      console.error(e)
    } finally {
      console.log('finally')
    }
  }

  const [filterBranch, setfilterBranch] = useState([]);
  const [filterBranchD, setfilterBranchD] = useState([]);
  const GetBranch = async () => {
    try {
      const res = await dispatch(Branch());
      let data = res.data.data;
      setfilterBranch(data)
      setfilterBranchD(data)
      // console.log(data)
    } catch (e) {
      console.error(e)
    } finally {
      console.log('finally')
    }
  }


  async function getJob(PackageId){
    // console.log('getJob',Data.CustomerId);
    setLoading(true);
    const params = {
      package_type_id: Data.PackageTypeId,
      package_id : PackageId,
      customer_id: Data.CustomerId,
      car_owner_id: displayDetail.car_id,
      status_switch: statusSwitch,
      job_continuos: true,
      
    }

    try {
      const response = await dispatch(JobList(params));
      setJob(response.data.data);
      const data = response.data.data
      if(data.length > 0 && data.length === 1)  {
        // console.log(data[0]);
        // setData({...Data, JobId: data[0].id})
      }
     
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }

  } 

  async function getServiceAppointments(PackageId){
    // console.log('getJob',Data.CustomerId);
    setLoading(true);
    const params = {
      package_id : PackageId,
      customer_id: Data.CustomerId,
      car_owner_id: displayDetail.car_id,
      status: 'ครบจำนวน',
      
    }

    try {
      const response = await dispatch(LoadServiceAppointments(params));
      setServiceAppointment(response.data.data);
      const data = response.data.data
      if(data.length > 0 && data.length === 1)  {
        // console.log(data[0]);
        // setData({...Data, JobId: data.id , setup: data.setup, spray_coating: data.spray_coating, treatment: data.treatment, keep_work: data.keep_work})
      }
     
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }

  } 

  const GetCustomer = async () => {
    setLoading(true)
    const params = {
      type: ['บุคคลธรรมดา','นิติบุคคล'],
      take: 10,
      customer_name: CustomerKeyword ,
      car_serie_id : CarSerieId? CarSerieId : '',
      // job: statusSwitch === 'appointment' ? 'job' : null,
    }
    try {
      const response = await dispatch(Customer(params));
        setfilterCustomers(response.data.data);
        setfilterCustomersDefault(response.data.data);
        // console.log(filterCustomers);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCarOwner = async () => {
    setLoading(true)
    try {
      const params = {
        have_owner: true,
        keyword: CarOwnerPlateName,
        customer_id : CarOwnerCustomerId? CarOwnerCustomerId : '',
        take: 10,
      }
      const response = await dispatch(CarOwnerList(params));
      setfilterCarOwners(response.data.data);
      setfilterCarOwnersDefault(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetPackageType = async () => {

    setLoading(true)
    try {
      const params = {
        customer_id: Data.CustomerId,
        car_id: Data.CarId,
      }
      const response = await dispatch(PackageTypeList(params));
      setPackageTypes(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetPackage = async (id) => {
    setLoading(true)
    try {
      const params = {
        package_type_id:id,
        customer_id: Data.CustomerId,
        car_id: Data.CarId,
      }
      const response = await dispatch(PackageList(params));
      setPackages(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function increasePackage(){
    if(statusSwitch === 'appointment'){

      if(Data.PackageTypeId === ''){
        return alert('เลือก package type');
      }
      // console.log('Data.PackageId ',Data.PackageId );
      if(Data.PackageId === ''){
        return alert('เลือก package');
      }

      if(Data.JobId === ''){
        return alert('เลือก job');
      }
      if(Data.PackageTypeId && Data.PackageId && Data.JobId ){
        setData({...Data, PackageAppointment:[...Data.PackageAppointment, {
          key: (Data.PackageAppointment.slice(-1).length > 0 ? Data.PackageAppointment.slice(-1)[0].key : 0) + 1,
          PackageTypeId: Data.PackageTypeId, 
          PackageTypeName: Data.PackageTypeName,
          PackageId: Data.PackageId,
          PackageName: Data.PackageName,
          PackageCode: Data.PackageCode,
          TypeWork: Data.TypeWork,
          JobId: Data.JobId, 
          JobDetailId: Data.JobDetailId,
          service_appointment_id: Data.service_appointment_id,
          Status: 'appointment',
          Quantity: 1,
          CleanCar: Data.CleanCar,
          ServiceNow: true,
          // ContinuousService: Data.ContinuousService, 
          SprayCoating: Data.SprayCoating,
          RenewalClaim: Data.RenewalClaim, 
          SetUp: Data.SetUp ? Data.SetUp : false, 
          Treatment: Data.Treatment? Data.Treatment : false,
          KeepWork: Data.KeepWork ? Data.KeepWork : false,
          PackageType: Data.PackageType,
          SpecialPackages: Data.PackageTypeId === 6 ? Data.SpecialPackages : [],
          enamel: Data.enamel,
          wash: Data.wash,
          attrition: Data.attrition,
          ozone_oven: Data.ozone_oven ? Data.ozone_oven : false,
          wash_upholstery: Data.wash_upholstery,
          wash_belt: Data.wash_belt,
          wash_door: Data.wash_door,
          
          
          setup: Data.setup,
          keep_work: Data.keep_work,
          treatment: Data.treatment,
          spray_coating: Data.spray_coating,
          form: 'package',
      }]})
      }
    }

  }

  function removeProduct(data){
    // console.log('data',data);
    const resultList = Data.PackageAppointment.filter( (storeList) => {
      return storeList.key !== data.key
    })
    setData({...Data, PackageAppointment: resultList, PackageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.employee_id = null;
    parseDataJob.PackageListAppointment = resultList;
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

  }

  function createForm(){
    console.log('data',Data.CustomerId,Data);
    if(statusSwitch === 'new'){
      if(Data.CustomerId === null){
        return alert('เลือกลูกค้า')
      }
      if(displayDetail.car_id === null){
        return alert('เลือกป้ายทะเบียน')
      }
      if(Data.CustomerId && displayDetail.car_id){
        const data = {
          customer_id : Data.CustomerId,
          car_id: displayDetail.car_id,
          status_job: statusSwitch,
          type: Data.Type,
          PackageList:[],
          employee_id: Data.employee_id,
          branch_id: Data.branch_id,
          show_room_employee_id: '',
          company_id: '',
        }
        localStorage.setItem('job-inside-data',JSON.stringify(data));
        history.push('/job/inside-create-old');
      }
      
    }else{
      const data = {
        customer_id : Data.CustomerId,
        car_id: displayDetail.car_id,
        status_job: statusSwitch,
        PackageList: Data.PackageAppointment,
        PackageListAppointment: Data.PackageAppointment,
        type: Data.Type,
        show_room_employee_id: '',
        employee_id: Data.employee_id,
        branch_id: Data.branch_id,
        company_id: '',
        note: ''
      }
      localStorage.setItem('job-inside-data',JSON.stringify(data));
      history.push('/job/inside-create-old');
    }
    
  }

  function LinkTo(url){
    history.push(url)
  }

  function getServiceName(item){
    const result = item.service_appointments.map(item => {
      return item.package.name
    })
    return result
  }

  function getActiveText (item){
    let textArr = []  
    if(item.CleanCar === true){
      textArr.push('ล้างรถ')
    }
    if(item.ContinuousService === true){
      textArr.push('บริการต่อเนื่อง')
    }
    if(item.SprayCoating === true){
      textArr.push('พ่นเคลือบหลังติดตั้ง')
    }
    if(item.RenewalClaim === true){
      textArr.push('เคลมต่ออายุประกัน')
    }
    if(item.SetUp === true){
      textArr.push('เช็คการเซ็ตตัว')
    }
    if(item.Treatment === true){
      textArr.push('ทรีตเมนต์')
    }
    if(item.KeepWork === true){
      textArr.push('เก็บงาน')
    }
    return textArr.length > 0 ? textArr.toString() : null
  }
  function findCustomerName(Data) {
    const result = filterCustomersDefault?.find((data) => {return data.id === Data.customer_id })
    setSearchCustomerName(result ? result.first_name + ' ' + result.last_name : '')
    return result ? result.first_name + ' ' + result.last_name : ''
  }

  function findCarOwnerLicensePlate(Data){
    const result = filterCarOwners?.find((data) => {return data.id === Data.CarId })
    setSearchCarLicensePlateName( result ? result.license_plate : '')
    return result ? result.license_plate : ''
  }
  
  function createCustomer(){
    history.push('/member/add')
  }

  function createCarOwner(){
    history.push(`/member/edit/${Data.CustomerId}/add-car`);
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    let path = `/job`;
    history.push(path);
  }

  function clearPackage(){
    setPackages([]);
  }

  function clearPackageType(){
    setPackageTypes([]);
  }

  function UnlockAppointment(e){
    // console.log('UnlockAppointment',Data);
    if(e === true){
      if(Data.CustomerId === null){
        setStatusSwitch('new')
        return alert('เลือกลูกค้า')
      }
      if(displayDetail.car_id === null){
        setStatusSwitch('new')
        return alert('เลือกป้ายทะเบียน')
      }

      if(Data.CustomerId !== null && displayDetail.car_id !== null ) {
        setStatusSwitch(statusSwitch === 'new' ? 'appointment' : 'new')
        setData({...Data, PackageTypeId: '', PackageId: '', JobId: ''})
      }
    }else{
      setStatusSwitch('new')
    }
      
  }
   
  

  function SearchfilterCustomers(value){

    setSearchCustomerName(value)
      setCustomerKeyword(value)
  }

  const [SearchEmployeesName, setSearchEmployeesName] = useState('');
  const [EmployeesKeyword,setEmployeesKeyword] = useState('');
  function SearchfilterEmployees(value){

    setSearchEmployeesName(value)
      setEmployeesKeyword(value)
  }
  
  const [SearchBranchName, setSearchBranchName] = useState('');
  const [BranchKeyword,setBranchKeyword] = useState('');
  function SearchfilterBranch(value){

    setSearchBranchName(value)
      setBranchKeyword(value)
  }

  function SearchfilterCarOwners(value){
    setSearchCarLicensePlateName(value)
    setCarOwnerPlateName(value)
  }

  useEffect(() => {
    if(CustomerKeyword){
      GetCustomer()
    }
  },[CustomerKeyword]);

  useEffect(() => {
    if(EmployeesKeyword){
      GetEmployee()
    }
  },[EmployeesKeyword]);

  useEffect(() => {
    if(CarOwnerPlateName){
      GetCarOwner()
    }
  },[CarOwnerPlateName]);

  useEffect(() => {
      console.log('PackageAppointment',Data.PackageAppointment);
  },[Data.PackageListAppointment])



  useEffect(() => {
    GetEmployee()
    GetBranch()
    // selectSearch.kindOfStand === 'customer' ?  GetCustomer() : GetCarOwner();
    GetCustomer() 
    // GetCarOwner()
    statusSwitch === 'appointment' ? GetCustomer() : clearPackage();
    statusSwitch === 'appointment' ? GetPackageType() : clearPackage();

    getAuth()
  }, [selectSearch.kindOfStand,statusSwitch,Data.PackageId]);

  useEffect(() => {
    //use one time for setup
      setStatusSwitch(parseDataJob?.status_job ? parseDataJob.status_job : 'new')
      // console.log('parseDataJob.type',parseDataJob);
      setData({
        CustomerId: parseDataJob.customer_id ? parseDataJob.customer_id : null,
        PackageStorageList: parseDataJob.PackageList && parseDataJob.PackageList.length > 0? parseDataJob.PackageList :[],
        PackageAppointment: parseDataJob.PackageListAppointment && parseDataJob.PackageListAppointment.length > 0 ? parseDataJob.PackageListAppointment :[],
        CarId: parseDataJob.car_id ? parseDataJob.car_id : null,
        CompanyId: parseDataJob.company_id ? parseDataJob.company_id : null,
        Type: parseDataJob.type ? parseDataJob.type : 'b2c',
      })
      setDisplayDetail({...displayDetail, 
        car_id: parseDataJob.car_id ? parseDataJob.car_id : null,
      });
      console.log(NotHaveCar);
  },[])

  useEffect( () => {
    if(parseDataJob.customer_id){
      const result = filterCustomersDefault?.find((data) => {return data.id === parseDataJob.customer_id })
      setSearchCustomerName(result ? result.first_name + ' ' + result.last_name : '')
      
    }

    if(parseDataJob.employee_id){
      const result = filterEmployeesD?.find((data) => {return data.id === parseDataJob.employee_id })
      setSearchEmployeesName(result ? result.fullname + ' - ' + result.branch.name : '')
      
    }

    if(parseDataJob.car_id){
      const result = filterCarOwners?.find((data) => {return data.id === parseDataJob.car_id })
      setSearchCarLicensePlateName( result ? result.license_plate : '')
    }
  },[filterCustomersDefault,filterCarOwners])

  return (
    <>
      <div className="box-paginate">
          {
            <div id="overlay" className={ isLoading ? 'd-block' : ''}>
            <div className='spinner-field'>
              <div className='spinner-border' role='status'></div>
            </div>
          </div>
          }
        </div>
      <Container fluid className="package-container Service Stock Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; ภายในร้าน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>JOB &gt; ภายในร้าน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="justify-content-center">
                  <Col lg={11}>
                    <Row className="mt-4 mb-4 justify-content-center">
                      <Col xs={12} lg={6}>
                        <Row className="justify-content-center">
                          <Col className="d-flex justify-content-start">
                            <p className="font-weight-bold">ค้นหาลูกค้า</p>
                          </Col>
                        </Row>
                        <Row className=" mt-3 mb-3">
                          <Col className="d-flex justify-content-start set-position-radio">
                            <Form.Group controlId="kindOfStand">
                              <Form.Check
                                value="customer"
                                type="radio"
                                aria-label="radio 1"
                                label="ค้นหาด้วยชื่อ"
                                onChange={handleChange}
                                checked={selectSearch.kindOfStand === "customer"}
                                defaultValue={true}
                              />
                              <Form.Check
                                value="car"
                                type="radio"
                                aria-label="radio 2"
                                label="ค้นหาด้วยป้ายทะเบียนและเลขตัวถัง"
                                onChange={handleChange}
                                checked={selectSearch.kindOfStand === "car"}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row id="search">
                          {
                            selectSearch.kindOfStand === 'customer' ? (
                              <Row className=" mt-3 mb-3"  >
                                <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='ค้นหาด้วยชื่อ'
                                            onInput={(e) => {SearchfilterCustomers(e.target.value)}}
                                            onChange={(e) => clearSearch()}
                                            value={SearchCustomerName}
                                            option
                                            required
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterCustomers?.map((item, key) => {
                                              return (
                                                <Dropdown.Item 
                                                  className="font-black-only"
                                                  onClick={() => {setCustomersList(item, key)}}
                                                >
                                                {item.type === 'บุคคลธรรมดา' ? `${item.full_name}` : 
                                                `${item.type === 'นิติบุคคล' ? item.organization : item.first_name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="font-black-only" onClick={() => { createCustomer() }}>
                                          <img src={AddLink} /> เพิ่มรายชื่อ
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className=" mt-3 mb-3"  >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown' >
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100' disabled={NotHaveCar}>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='ค้นหาเลขทะเบียนและเลขตัวถัง'
                                            onInput={(e) => {SearchfilterCarOwners(e.target.value)}}
                                            onChange={(e) => clearSearch()}
                                            value={SearchCarLicensePlateName}
                                            required
                                            option
                                            disabled={NotHaveCar}
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100' >
                                        <div className='limit'>
                                          {
                                            filterCarOwners?.map((item, key) => {
                                              return (
                                                <Dropdown.Item className="font-black-only" onClick={() => setCarOwnerList(item, key)}>{`${item.license_plate + ' (' + item.chassis_number + ')'}`}</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="font-black-only" onClick={() => { createCarOwner() }}>
                                          <img src={AddLink} /> เพิ่มรถ
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                
                              </Row>
                            ) : (
                            <Row className=" mt-3 mb-3">
                              <Col lg={12}>
                                 <Row >
                                    <Col lg={12}>
                                      <Dropdown className='w-100 custom-dropdown' >
                                        <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                          <div className='d-flex'>
                                            <Form.Control
                                              className="w-100"
                                              placeholder='ค้นหาเลขทะเบียนและเลขตัวถัง'
                                              onInput={(e) => {SearchfilterCarOwners(e.target.value)}}
                                              value={SearchCarLicensePlateName}
                                              required
                                            ></Form.Control>
                                            <Button className='arrow'></Button>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100' >
                                          <div className='limit'>
                                            {
                                              filterCarOwners?.map((item, key) => {
                                                return (
                                                  <Dropdown.Item className="font-black-only" onClick={() => setCarOwnerList(item, key)}>{`${item.license_plate + ' (' + item.chassis_number + ')'}`}</Dropdown.Item>
                                                )
                                              })
                                            }
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </Row>
                                  <Row className=" mt-3 mb-3">
                                    <Col lg={12}>
                                      <Dropdown className='w-100 custom-dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                          <div className='d-flex'>
                                            <Form.Control
                                              className="w-100"
                                              placeholder='ค้นหาด้วยชื่อ'
                                              onInput={(e) => SearchfilterCustomers(e.target.value)}
                                              value={Data.CustomerFullname ? Data.CustomerFullname : null}
                                              required
                                            ></Form.Control>
                                            <Button className='arrow'></Button>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100'>
                                          <div className='limit'>
                                            {
                                              filterCustomers?.map((item, key) => {
                                                return (
                                                  <Dropdown.Item className="font-black-only" onClick={() => setCustomersList(item, key)}>{item.type === 'บุคคลธรรมดา' ? `${item.first_name} ${item.last_name}` : 
                                                  `${item.type === 'นิติบุคคล' ? item.organization : item.first_name}`
                                                  }</Dropdown.Item>
                                                )
                                              })
                                            }
                                          </div>
                                          <Dropdown.Divider />
                                          <Dropdown.Item className="font-black-only" onClick={() => { createCustomer() }}>
                                            <img src={AddLink} /> เพิ่มรายชื่อ
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </Row>
                                </Col>
                            </Row>
                            )
                          }
                          <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown mt-4'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='เลือกพนักงาน'
                                            onInput={(e) => {SearchfilterEmployees(e.target.value)}}
                                            onChange={(e) => clearSearchE()}
                                            value={SearchEmployeesName}
                                            option
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterEmployees?.map((item, key) => {
                                              return (
                                                <Dropdown.Item 
                                                  className="font-black-only"
                                                  onClick={() => {setEmployeeList(item, key)}}
                                                >
                                                {
                                                  `${item.fullname + ' - ' + item.branch.name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>

                                <Row >
                                  <Col lg={12}>
                                    <Dropdown className='w-100 custom-dropdown mt-3'>
                                      <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                        <div className='d-flex'>
                                          <Form.Control
                                            className="w-100"
                                            placeholder='เลิอกสาขา'
                                            onInput={(e) => {SearchfilterBranch(e.target.value)}}
                                            onChange={(e) => clearSearchE()}
                                            value={SearchBranchName}
                                            option
                                          ></Form.Control>
                                          <Button className='arrow'></Button>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className='w-100'>
                                        <div className='limit'>
                                          {
                                            filterBranch?.map((item, key) => {
                                              return (
                                                <Dropdown.Item 
                                                  className={item.name == 'ระบบกลาง' ? 'd-none' : 'font-black-only'}
                                                  
                                                  onClick={() => {setBranchList(item, key)}}
                                                >
                                                {
                                                  `${item.name}`
                                                }</Dropdown.Item>
                                              )
                                            })
                                          }
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                        </Row>
                       
                      </Col>
                      <Col xs={12} lg={6}>
                        <Row className="justify-content-end">
                          <Col xs={12} lg={4}>
                            {/* <Button type="button" className="button" onClick={() => {
                              createCustomer()
                            }}>
                              สร้างรายชื่อใหม่
                            </Button> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* <Row >
                      <Col className="text-start flex">
                        <Switch checked={statusSwitch === 'new' ? false : true} onClick={(e) => {
                          UnlockAppointment(e)
                        }} />
                        <p className="pl-3">{statusSwitch === 'new' ? 'job ใหม่' : 'job นัดหมาย' }</p></Col>
                    </Row> */}
                    { 
                      statusSwitch === 'appointment'? (
                        <Row className="mt-4 mb-4 justify-content-center">
                          <Col xs={12} lg={6}>
                            <Row id="search-appointment">
                              <Row className=" mt-3 mb-3"  >
                                <Row >
                                  <Col lg={12}>
                                    <Select
                                      className={`select-list ` + (!Data.PackageTypeId ? 'validate-fail' : '') }
                                      required
                                      aria-label="Default select example"
                                      placeholder=""
                                      options={PackageTypes}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      onChange={
                                        (e) => {SetPackageType(e);GetPackage(e.id)}
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row className=" mt-3 mb-3"  >
                                  <Col lg={12}>
                                    <Select
                                      className={`select-list ` + (!Data.PackageId ? 'validate-fail' : '') }
                                      required
                                      aria-label="Default select example"
                                      placeholder=""
                                      options={Packages}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      value={Data.PackageId ? Packages?.find((data) => {return data.id === Data.PackageId}) : null}
                                      onChange={
                                        (e) => {SetDataPackage(e)}
                                      }
                                    />
                                  </Col>
                                </Row>

                                <Row className=" mt-3 mb-3"  >
                                  <Col lg={12}>
                                  <Select
                                      className={`select-list ` + (!Data.JobId ? 'validate-fail' : '') }
                                      required
                                      placeholder=""
                                      options={ServiceAppointment}
                                      getOptionLabel={(option) => `${option.job_detail.job.job_code} ${option.job_detail.package_type_id === 6 ? getServiceName(option.job_detail) : ''}`}
                                      getOptionValue={(option) => option.job_detail.job.id}
                                      value={Data.JobId ? Job?.find((data) => {return data.job_detail.job.id === Data.JobId}) : null}
                                      onChange={
                                        (e) => {SetDataJob(e); SetAppointmentSelect(e)}
                                      }
                                    />
                                  </Col>
                                </Row>
                                {
                                  Data.JobId !== '' ? 
                                  (
                                    <>
                                      <Row className=" mt-3 mb-3"  >
                                        <Col lg={12}>
                                            <div key={`inline-checkbox`}>
                                                {
                                                Data.PackageTypeId !== 7 && Data.PackageTypeId !== 8 ? (
                                                <>
                                                  <Row>
                                                      <Form.Check
                                                        inline
                                                        label={`ล้างรถ ${Data.PackageTypeId === 1 ? `${Data.clean_total - Data.clean_remaining}/${Data.clean_total}` : ''}`}
                                                        name={'ล้างรถ'}
                                                        type='checkbox'
                                                        defaultChecked={true}
                                                        id={`inline-checkbox-1`}
                                                        onChange={
                                                          (e) => {
                                                            setData({...Data, CleanCar: e.target.checked});
                                                          }
                                                        }
                                                        // disabled={Data.PackageTypeId === 1}
                                                        className="checkbox-input-job"
                                                      />
                                                  </Row>
                                                </> ) : null
                                                }
                                                {
                                                  Data.PackageTypeId === 7 ? (
                                                    <>
                                                    <Row className="g-0 mt-3">
                                                      <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-title">
                                                        <p>จำนวนครั้งรับบริการ</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>เคลือบ</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.enamel}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel))){
                                                              alert('เคลือบไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'enamel');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel)} / {AppointmentDisplay.e.job_detail.package.enamel})</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>ล้าง</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.wash}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash))){
                                                              alert('ล้างไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'wash');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                      <Col lg={6} xl={5}>
                                                        <div className="service-fast-position">
                                                          <Form.Check
                                                            type="checkbox"
                                                            label={AppointmentDisplay.e.attrition ? 'เคยรับบริการขัดสีแล้ว' :'ขัดสี'}
                                                            disabled={AppointmentDisplay.e.attrition}
                                                            onChange={handleAttrition}
                                                            checked={attrition}
                                                            className="checkbox-input mt-3"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0 mb-3">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                      <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash)} / {AppointmentDisplay.e.job_detail.package.wash})</p>
                                                      </Col>
                                                    </Row>
                                                  </> ) : null
                                                }
                                                {
                                                  Data.PackageTypeId === 8 ? (
                                                    <>
                                                    <Row className="g-0 mt-3">
                                                      <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-title">
                                                        <p>จำนวนครั้งรับบริการ</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>ซักเบาะ</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.wash_upholstery}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.wash_upholstery) - Number(AppointmentDisplay.e.total_wash_upholstery)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.wash_upholstery) - Number(AppointmentDisplay.e.total_wash_upholstery))){
                                                              alert('ซักเบาะไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'wash_upholstery');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.wash_upholstery) - Number(AppointmentDisplay.e.total_wash_upholstery)} / {AppointmentDisplay.e.job_detail.package.wash_upholstery})</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>ซักสายเข็มขัด</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.wash_belt}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.wash_belt) - Number(AppointmentDisplay.e.total_wash_belt)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.wash_belt) - Number(AppointmentDisplay.e.total_wash_belt))){
                                                              alert('ซักสายเข็มไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'wash_belt');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.wash_belt) - Number(AppointmentDisplay.e.total_wash_belt)} / {AppointmentDisplay.e.job_detail.package.wash_belt})</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>ซักแผงประตู</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.wash_door}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.wash_door) - Number(AppointmentDisplay.e.total_wash_door)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.wash_door) - Number(AppointmentDisplay.e.total_wash_door))){
                                                              alert('ซักแผงประตูไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'wash_door');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.wash_door) - Number(AppointmentDisplay.e.total_wash_door)} / {AppointmentDisplay.e.job_detail.package.wash_door})</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>เคลือบภายใน</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.enamel}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel))){
                                                              alert('เคลือบภายในไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'enamel');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.enamel) - Number(AppointmentDisplay.e.total_enamel)} / {AppointmentDisplay.e.job_detail.package.enamel})</p>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                        <p>ล้างรถ</p>
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder=""
                                                          value={Data.wash}
                                                          required
                                                          min={1}
                                                          max={Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash)}
                                                          onWheel={(e) => e.target.blur() }
                                                          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                          onChange={(e) => {
                                                            if(e.target.value > (Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash))){
                                                              alert('ล้างรถไม่พอ')
                                                            }else{
                                                              updateLocalStorage(e,null,'wash');
                                                            }
                                                          }}
                                                          className="input-middle-price my-1"
                                                        ></Form.Control>
                                                      </Col>
                                                      <Col lg={6} xl={5}>
                                                        <div className="service-fast-position">
                                                          <Form.Check
                                                            type="checkbox"
                                                            label={AppointmentDisplay.e.ozone_oven ? 'เคยรับบริการอบโอโซนแล้ว' :'อบโอโซน'}
                                                            disabled={AppointmentDisplay.e.ozone_oven}
                                                            onChange={handleOzoneOven}
                                                            checked={ozone_oven}
                                                            className="checkbox-input mt-3"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row className="g-0 mb-3">
                                                      <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-title">
                                                      </Col>
                                                      <Col xs={8} md={8} lg={6} xl={4}>
                                                      <p>เหลือ ({Number(AppointmentDisplay.e.job_detail.package.wash) - Number(AppointmentDisplay.e.total_wash)} / {AppointmentDisplay.e.job_detail.package.wash})</p>
                                                      </Col>
                                                    </Row>
                                                  </> ) : null
                                                }
                                                {
                                                  Data.PackageTypeId === 2 ? (
                                                    <>
                                                      <Row>
                                                          <Form.Check
                                                            inline
                                                            label={'เช็คการเซ็ตตัว'}
                                                            name={'เช็คการเซ็ตตัว'}
                                                            type='checkbox'
                                                            id={`inline-checkbox-1`}
                                                            className="checkbox-input-job"
                                                            defaultChecked={false}
                                                            disabled={Data.setup === true}
                                                            onChange={
                                                              (e) => {
                                                                setData({...Data, SetUp: e.target.checked});
                                                              }
                                                            }
                                                          />
                                                      </Row>
                                                    </>
                                                  ): null
                                                }
                                                {
                                                  Data.PackageTypeId === 3 ? (
                                                    <>
                                                      {
                                                        // Data.first_time === false ? ( 
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={'พ่นเคลือบหลังติดตั้ง'}
                                                              name={'พ่นเคลือบหลังติดตั้ง'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, SprayCoating: e.target.checked});
                                                                }
                                                              }
                                                              disabled={Data.spray_coating === true}
                                                            />
                                                        </Row>
                                                        // ) : ''
                                                      }
                                                    {
                                                      Data.spray_coating === true ? (
                                                        <Row>
                                                          <Form.Check
                                                            inline
                                                            label={'ทรีตเมนต์'}
                                                            name={'ทรีตเมนต์'}
                                                            type='checkbox'
                                                            id={`inline-checkbox-1`}
                                                            className="checkbox-input-job"
                                                            defaultChecked={false}
                                                            disabled={Data.treatment === true}
                                                            onChange={
                                                              (e) => {
                                                                setData({...Data, Treatment: e.target.checked});
                                                              }
                                                            }
                                                          />
                                                      </Row>
                                                      ) : ''
                                                    }
                                                    {
                                                        Data.claim_renewal_date === Data.day_now ? (
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={'เคลมต่ออายุประกัน'}
                                                              name={'เคลมต่ออายุประกัน'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              disabled={Data.claim_renewal_date === Data.day_now}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, RenewalClaim: e.target.checked});
                                                                }
                                                              }
                                                            />
                                                        </Row>
                                                        ) : ''
                                                      }
                                                    </>
                                                  ): null
                                                }
                                                {
                                                  Data.PackageTypeId === 4 ? (
                                                    <>
                                                      <Row>
                                                        <Form.Check
                                                          inline
                                                          label={'พ่นเคลือบหลังติดตั้ง'}
                                                          name={'พ่นเคลือบหลังติดตั้ง'}
                                                          type='checkbox'
                                                          id={`inline-checkbox-1`}
                                                          className="checkbox-input-job"
                                                          defaultChecked={false}
                                                          onChange={
                                                            (e) => {
                                                              setData({...Data, SprayCoating: e.target.checked});
                                                            }
                                                          }
                                                          disabled={Data.spray_coating === true}
                                                        />
                                                    </Row>
                                                    </>
                                                  ): null
                                                }
                                                {
                                                  Data.PackageTypeId === 5 ? (
                                                    <>
                                                      {
                                                        // Data.first_time === true ? (
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={'เช็คการเซ็ตตัว'}
                                                              name={'เช็คการเซ็ตตัว'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              disabled={Data.setup === true}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, SetUp: e.target.checked});
                                                                }
                                                              }
                                                            />
                                                        </Row>
                                                        // ) : Data.first_name === false ? '(ใช้ครั้งแรก)' : ''
                                                      }
                                                      {
                                                        Data.setup === true ? (
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={`ทรีตเมนต์ (${Data.total - Data.remaining}/${Data.total}) วันที่นัด ${Data.appointment_date}`}
                                                              name={'ทรีตเมนต์'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              disabled={Data.treatment === true}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, Treatment: e.target.checked});
                                                                }
                                                              }
                                                            />
                                                        </Row>
                                                        ) : ''
                                                      }
                                                      {
                                                        Data.claim_renewal_date === Data.day_now ? (
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={'เคลมต่ออายุประกัน'}
                                                              name={'เคลมต่ออายุประกัน'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              disabled={Data.claim_renewal_date === Data.day_now}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, RenewalClaim: e.target.checked});
                                                                }
                                                              }
                                                            />
                                                        </Row>
                                                        ) : ''
                                                      }
                                                      {
                                                        Data.treatment === true ? (
                                                          <Row>
                                                            <Form.Check
                                                              inline
                                                              label={'เก็บงาน'}
                                                              name={'เก็บงาน'}
                                                              type='checkbox'
                                                              id={`inline-checkbox-1`}
                                                              className="checkbox-input-job"
                                                              defaultChecked={false}
                                                              disabled={Data.keep_work === true}
                                                              onChange={
                                                                (e) => {
                                                                  setData({...Data, KeepWork: e.target.checked});
                                                                }
                                                              }
                                                            />
                                                        </Row>
                                                        ) : ''
                                                      }
                                                    </>
                                                  ): null
                                                }
                                            </div>
                                        </Col>
                                      </Row>
                                      <Row className=" mt-3 mb-3 right" >
                                        <Col lg={3}>
                                          <Button type="button" className="button" onClick={() => {
                                            increasePackage()
                                          }}>
                                            เพิ่ม
                                          </Button>
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                  : ''
                                }

                              </Row>
                            </Row>
                          
                          </Col>
                          <Col xs={12} lg={6}>
                            <Row className="justify-content-end">
                              <Col xs={12} lg={4}>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ): null
                    }
                      <Row>
                        <Col xs={12} lg={6} >
                          <Col xs={12} lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                lg={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>รหัสสมาชิก</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{displayDetail.customer_code}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>ชื่อ</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{Data.CustomerFullname}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label> ชื่อ AKA </Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{displayDetail.aka}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>เบอร์โทรศัพท์</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{ displayDetail.phone }</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>รถที่เข้ารับบริการ</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>
                                  {/* เลขทะเบียนรถ : กด-252 รายละเอียด : ยี่ห้อ : Honda,
                                  รุ่น Civic -1.8 el, สี : ขาว, ป้าย : ธรรมดา
                                  จังหวัด : เชียงใหม่ เลขตัวถัง : 484226 */}
                                  {
                                    `เลขทะเบียนรถ : ${displayDetail?.license_plate} รายละเอียด : ยี่ห้อ : ${displayDetail?.car_brand_name}
                                    รุ่น ${displayDetail?.car_model_name}, สี : ${displayDetail?.color?.name}, ป้าย : ${displayDetail?.plate_type}
                                    จังหวัด : ${displayDetail?.province_name} เลขตัวถัง : ${displayDetail.chassis_number === null ? '-' : displayDetail.chassis_number}
                                    `
                                  }
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>หมายเหตุ</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{displayDetail.note}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="mt-3 mb-3">
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={4}
                                xl={4}
                                className="text-left font-weight-bold"
                              >
                                <Form.Label>ความต้องการพิเศษ</Form.Label>
                              </Col>
                              <Col className="text-left">
                                <p>{displayDetail.special_need}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                        {
                          statusSwitch === 'appointment'  ? (
                            <Col xs={6} lg={6}>
                              <Col xs={12} lg={12} className="mt-3 mb-3">
                                <Row>
                                  <Col
                                    xs={12}
                                    className="text-center font-weight-bold"
                                  >
                                    <Form.Label>รายการนัดหมาย</Form.Label>
                                  </Col>
                                </Row>
                                <Col lg={12} className="mt-3 mb-3">
                                <Row>
                                  <Col className="text-left">
                                  <table className="table table-responsive table-wash-part ">
                                    <tbody className="t-body-package">
                                      {
                                        Data.PackageAppointment.length > 0 && Data.PackageAppointment?.map((item, key) => {
                                          return (
                                            <tr>
                                              <td>{item.PackageCode}</td>
                                              <td>{item.PackageName} {item.CleanCar === false ? '(ไม่ล้างรถ)' : ''}</td>
                                              <td>{getActiveText(item)}</td>
                                              <td>
                                                <Button type="button" className="button-table" 
                                                  onClick={() => removeProduct(item)}>
                                                  <img src={Delete} />
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                  </Col>
                                </Row>
                              </Col>
                              </Col>
                            </Col>
                          ): null
                        }
                         <Col sm={12} className="mt-3 mb-3">
                            <Row className="justify-content-center">
                              { Data.Type === 'show_room' ? (
                                <Col xs={4} lg={3}>
                                  <Button type="button" className="button" onClick={() => {
                                    LinkTo('/job/company-b2b-show-room')
                                  }}>
                                    ย้อนกลับ
                                  </Button>
                              </Col> 
                              ) : ''}
                              <Col xs={4} lg={3}>
                                {/* <Link to="/job/inside-create"> */}
                                  <Button type="button" className="button" onClick={() => {
                                    createForm()
                                  }}>
                                    ถัดไป
                                  </Button>
                                {/* </Link> */}
                              </Col>
                            </Row>
                          </Col>
                      </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
