import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Container, ToastContainer, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SignIn } from '../actions/auth/index';
import { useHistory } from 'react-router-dom';
import { updateUser } from 'slice/userSlice';

import Service from '../assets/icon/management.png';
import Mr_Kleane from '../assets/icon/Mr.Kleane.png'

export default function Login({ location}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const { employee } = useSelector( (state) => state.user)

  const redirect = location.search
  ? location.search.split('=')[1]
  : '/';

  useEffect(() => {
    if (employee) {
      history.push(redirect);
    }
  }, [employee]);
  
  const credential = {
    username,
    password
  }

  const HandleSubmit = e => {
    e.preventDefault();
    dispatch(SignIn(credential)).then(response => {
      if(response && response.data){
        dispatch(updateUser(response.data))
        history.push('/');
      }
      
    }).catch((error) =>  {
      if(error.response && error.response.data){
        if(error.response.data.messages){
          setErrors([...errors, ...error.response.data.messages])
        }
      }
    });
  }

  return (
    <>
      <Container fluid className="bg-mrk-login Login-Page p-0">
        <ToastContainer position='top-end'>
          {
            errors?.map((error, key) => {
              return (
                <Toast show={true} key={key} delay={2500} autohide onClose={() => setErrors([])}>
                  <Toast.Header>
                    <strong className="me-auto">แจ้งเตือน</strong>
                  </Toast.Header>
                  <Toast.Body>{ error }</Toast.Body>
                </Toast>
              )
            })
          }
        </ToastContainer>
        <div className="border-login">
          <div className="header-login d-none d-sm-block">
            <h5 className="modal-title" id="exampleModalLabel">
            <img className="logo pr-2" src={Service} />
              เข้าสู่ระบบ
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
            >
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div className="body-login">
            <Row className="align-items-center m-0">
              <Col>
                <Row className="align-items-center">
                  <Col xs={12} className="mt-3 mb-3">
                      <Row className="align-items-center">
                      <Col xs={12} className="d-flex justify-content-center">
                        <img src={Mr_Kleane} width="100%"/>
                      </Col>
                      </Row>
                  </Col>
                  <Col xs={12} className="mt-3 mb-3">
                    <Form onSubmit={HandleSubmit}>
                      <Form.Group controlId="email">
                        <Form.Control
                          type="username"
                          placeholder="ชื่อผู้ใช้"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          className="input-login"
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId="password">
                        <Form.Control
                          type="password"
                          placeholder="รหัสผ่าน"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="input-login"
                        ></Form.Control>
                      </Form.Group>

                      <center>
                        <Button type="submit" variant="primary" className="button-login">
                          เริ่มงาน
                        </Button>
                      </center>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Form, Button, Row, Col, Container } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { SignIn } from '../actions/auth/index';
// import { useHistory } from 'react-router-dom';

// import Service from '../assets/icon/management.png';
// import Mr_Kleane from '../assets/icon/Mr.Kleane.png'

// class Login extends React.Component {
//   // history = useHistory();
//   // dispatch = useDispatch();

//   handleChangeUsername(event) {
//     this.setState({username: event.target.value});
//   }

//   handleChangePassword(event) {
//     this.setState({password: event.target.value});
//   }

//   async handleSubmit(){
//     const credential = {
//       username: this.state.username,
//       password: this.state.password
//     }
//     const response = await this.props.dispatch(SignIn(credential));
//     if (response) {
//       console.log('redirect')
//       // history.push('/');
//       this.props.history.push({
//         pathname: `/`
//       });
//     }
//   }

//   close() {
//     this.props.history.push({
//       pathname: `/`
//     });
//   }

//   render() {
//     return (
//       <>
//         <Container fluid className="bg-mrk Login-Page p-0">
//         <div className="border-login">
//           <div className="header-login d-none d-sm-block">
//           <h5 className="modal-title" id="exampleModalLabel">
//                     <img className="logo pr-2" src={Service} />
//                     เข้าสู่ระบบ
//                   </h5>
//                   <button
//                     type="button"
//                     className="btn-close"
//                     aria-label="Close"
//                     onClick={this.close}
//                   >
//                     <span aria-hidden="true">X</span>
//                   </button>
//           </div>
//           <div className="body-login">
//           <Row className="align-items-center m-0">
//         <Col>
//           <Row className="align-items-center">
//           <Col xs={12} className="mt-3 mb-3">
//               <Row className="align-items-center">
//               <Col xs={12} className="d-flex justify-content-center">
//                 <img src={Mr_Kleane} width="100%"/>
//               </Col>
//               </Row>
//           </Col>
//           <Col xs={12} className="mt-3 mb-3">
//           <Form onSubmit={this.handleSubmit()}>
//           <Form.Group controlId="email">
//             <Form.Control
//               type="username"
//               placeholder="ชื่อผู้ใช้"
//               value={this.state.username}
//               onChange={this.handleChangeUsername}
//               className="input-login"
//             ></Form.Control>
//           </Form.Group>
  
//           <Form.Group controlId="password">
//             <Form.Control
//               type="password"
//               placeholder="รหัสผ่าน"
//               value={this.state.password}
//               onChange={this.handleChangePassword}
//               className="input-login"
//             ></Form.Control>
//           </Form.Group>
  
//           <center>
//           <Button type="submit" variant="primary" className="button-login">
//             เริ่มงาน
//           </Button>
//           </center>
//           </Form>
//           </Col>
//           </Row>
//         </Col>
//         </Row>
//           </div>
//         </div>
//         </Container>
//       </>
//     );
//   }
// }

// export default Login; 