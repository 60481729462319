import axios, { post, put } from 'axios';

export const TechnicianQueueList = (params) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/technician-queues`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateTechnicianQueue = (formData) => async () => {
  return await post(`${process.env.REACT_APP_BASE_URL}/api/technician-queues`, formData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdateTechnicianQueue = ({id, formData}) => async () => {
  return await post(`${process.env.REACT_APP_BASE_URL}/api/technician-queues/${id}`, formData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdateOnlyDateTechnicianQueue = ({id, data}) => async () => {
  return await put(`${process.env.REACT_APP_BASE_URL}/api/technician-queues/${id}/date`, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const DestroyTechnicianQueue = (id) => async () => {
  return await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/technician-queues/${id}`)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
