import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';

import { useHistory } from 'react-router-dom';
import Increase from 'assets/icon/add_menu.png';
import { useDispatch, useSelector } from 'react-redux';
import MyPagination from 'Components/MyPagination'

import Back from 'assets/icon/back.png';
import Delete from 'assets/icon/delete.png';
import Select, { components } from 'react-select';
import TabsIconMember from 'MiddleSystems/Components/layout/TabsIconMember';
import TabsIconMemberB from 'BranchSystems/Components/layout/TabsIconMember';
// import MenuMember from 'MiddleSystems/Components/layout/MenuMember';
import view from 'assets/icon/view-data.png';
import Returnvalue from 'assets/icon/return-value.png'
import MemberImage from 'assets/icon/member.png';

import { Customer, DestroyCustomer } from 'actions/customer/index';
import { SegmentList } from 'actions/settings/segment';

// API
import { CarBrandList } from 'actions/settings/car_brand';
import { CarModelList } from 'actions/settings/car_model';
import { CarOwner } from 'actions/settings/car';
import { loadAuth } from 'actions/auth';

var dayjs = require('dayjs')
var relativeTime = require('dayjs/plugin/relativeTime')
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: "ใน %s",
    past: "%s ที่แล้ว",
    s: 'ไม่กี่วินาที',
    m: "ไม่กี่่นาที",
    mm: "%d นาที",
    h: "ไม่กี่ชั่วโมง",
    hh: "%d ชั่วโมง",
    d: "1 วัน",
    dd: "%d วัน",
    M: "1 เดือน",
    MM: "%d เดือน",
    y: "1 ปี",
    yy: "%d ปี"
  }
})

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);

  const { employee } = useSelector( (state) => state.user)

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  function close() {
    history.push(`/`);
  }

  const [Segments, setSegments] = useState([]);
  const [CarBrands, setCarBrands] = useState([]);
  const [CarModels, setCarModels] = useState([]);
  

  const [Search, setSearch] = useState({
    Keyword: '',
    Name: '',
    CompanyName: '',
    SegmentId: null,
    CarBrandId: null,
    CarModelId: null,
    LicensePlate: null,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const [Customers, setCustomers] = useState([]);
  const GetCustomer = (search = false) => {
    setLoading(true)
    const [first, last] = Search.Name.split(' ');
    const params = {
      keyword: Search.Keyword,
      first_name: first,
      last_name: last,
      organization: Search.CompanyName,
      segment_id: Search.SegmentId,
      car_brand_id: Search.CarBrandId,
      car_model_id: Search.CarModelId,
      license_plate: Search.LicensePlate,
      limit: Search.PerPage,
      page: Search.Keyword === '' ? Search.CurrentPage : 1,
      type: ['บุคคลธรรมดา','นิติบุคคล'],
    }

    dispatch(Customer(params)).then(response => {
      if(response.data.data && response.data.data.data){
        setCustomers(response.data.data.data);
        setSearch({ ...Search, 
          Rows: response.data.data.last_page,
          CurrentPage: search ? 1 : response.data.data.current_page
        })
      }else{
        setCustomers(response.data.data);
      }
      setLoading(false)
    }).catch( e => {

    })
  }

  const GetSegment = async (data) => {
    try {
      const response = await dispatch(SegmentList());
      setSegments(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }
  
  const [ClearSearch, setClearSearch] = useState(false);
  function ResetSearch() {
    setSearch({ ...Search, 
      Keyword: '',
      Name:'',
      CompanyName: '',
      SegmentId: null,
      CarBrandId: null,
      CarModelId: null,
      LicensePlate: null,
      PerPage: 10 ,
      CurrentPage: 1,
    })

    setClearSearch(true)
  }

  const GetBrand = async (data) => {
    try {
      const response = await dispatch(CarBrandList());
      setCarBrands(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['customerB2C'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
	history.push('/');
      console.error(error);
    }
  };

  const GetCarModel = async (car_brand_id) => {
    const params = {
      car_brand_id
    }

    try {
      const response = await dispatch(CarModelList(params));
      setCarModels(response.data.data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const [CarLicensePlate, setLicensePlate] = useState([]);
  const GetLicensePlate = async (license_plate) => {
    const params = {
      license_plate
    }

    try {
      const response = await dispatch(CarOwner(params)); /// ไม่รู้ต้องไปเรียก api ป้ายทะเบียนตรงไหน
      console.log(response);
      if(response){
        setLicensePlate(response.data.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }
  
  function ChangeModel(data) {
    setSearch({...Search, CarBrandId: data.id })
    GetCarModel(data.id)
  }

  function ActiveTap(id,tab) {
    history.push({
      pathname: `/member/edit/${id}`,
      search: `?activeTab=${tab}`
    })
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);
  function deleteConfirmation(customer) {
    setDeleting(customer)
    setConfirmation(true)
  }

  const DeleteCustomer = () => {
    dispatch(DestroyCustomer(deleting.id)).then( response => {
      GetCustomer()
      setDeleting(null)
      setConfirmation(false)
    }).catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    GetBrand();
    GetSegment();
    GetLicensePlate();
    getAuth()
  }, []);

  useEffect(() => {
    GetCustomer();
  }, [Search.PerPage, Search.CurrentPage]);

  useEffect(() => {
    if (!ClearSearch) GetCustomer();
    setClearSearch(false)
  }, [Search.PerPage, ClearSearch]);

  return (
    <>
      
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={MemberImage} />
                  <b>Customer (B2C)</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={MemberImage} />
                  <b>รายชื่อลูกค้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIconMember />
                    :
                    <TabsIconMemberB />
                  }
                </Row>
                <Row className="g-0 justify-content-center mb-3">
                  <Row className="set-search-row">
                    <Col xs={12} lg={4} className=" mt-2 mb-2">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="ค้นหาด้วยรหัสลูกค้า"
                        value={Search.Keyword ? Search.Keyword : ''}
                        onChange={(e) => setSearch({ ...Search, Keyword: e.target.value })}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4} className=" mt-2 mb-2">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="ค้นหาด้วยชื่อลูกค้า,ชื่อเล่น,AKA,เบอร์โทรศัพท์"
                        value={Search.Name ? Search.Name : ''}
                        onChange={(e) => setSearch({ ...Search, Name: e.target.value , CompanyName: ''})}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4} className=" mt-2 mb-2">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="ค้นหาด้วยชื่อบริษัท"
                        value={Search.CompanyName ? Search.CompanyName : ''}
                        onChange={(e) => setSearch({ ...Search, CompanyName: e.target.value , Name: ''})}
                      ></Form.Control>
                    </Col>
                    
                    <Col xs={12} lg={2} className="mt-2 mb-2">
                      <Select
                        options={Segments}
                        placeholder="Segment"
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isClearable={false}
                        isSearchable={false}
                        value={Search.SegmentId ? Segments.find((data) => {return data.id === Search.SegmentId}) : null}
                        onChange={(data) => setSearch({ ...Search, SegmentId: data.id })}
                        className="select-list"
                      />
                    </Col>
                    <Col xs={12} lg={2} className="mt-2 mb-2">
                      <Select
                        options={CarBrands}
                        placeholder="ยี่ห้อรถ"
                        className="select-add-member"
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        value={Search.CarBrandId ? CarBrands.find((data) => {return data.id === Search.CarBrandId}) : null}
                        onChange={(data) => ChangeModel(data)}
                      />
                    </Col>
                    <Col xs={12} lg={2} className="mt-2 mb-2">
                      <Select
                        options={CarModels}
                        placeholder="รุ่นรถ"
                        className="select-add-member"
                        getOptionLabel={option => `${option.model}-${option.car_serie.code}`}
                        getOptionValue={option => option.id}
                        isDisabled={!Search.CarBrandId}
                        value={Search.CarModelId ? CarModels.find((data) => {return data.id === Search.CarModelId}) : null}
                        onChange={(data) => setSearch({...Search, CarModelId: data.id })}
                      />
                    </Col>
                    <Col xs={12} lg={2} className="mt-2 mb-2">
 
                      <Select
                        options={CarLicensePlate}
                        placeholder="ป้ายทะเบียน"
                        className="select-add-member"
                        getOptionLabel={(option) => option.license_plate}
                        getOptionValue={(option) => option.id}
                        isDisabled={!Search.CarModelId}
                        value={Search.LicensePlate ? CarLicensePlate.find((data) => {return data.id === Search.LicensePlate}) : null}
                        onChange={(data) => setSearch({...Search, LicensePlate: data.id })}
                      />
                    </Col>
                    <Col xs={6} lg={2} className="mt-2 mb-2">
                      <Button
                        className="button-menu"
                        style={{ padding: 0, margin: 0 }}
                        onClick={() => GetCustomer(true)}
                      >
                        ค้นหา &nbsp;
                        <img src="icon/magnifying_glass_4-1 2.png" alt="" />
                      </Button>
                    </Col>
                    <Col xs={6} lg={2} className="mt-2 mb-2">
                      <Button className="button-menu" onClick={ResetSearch}>
                        คืนค่า &nbsp; <img src={Returnvalue} />
                      </Button>
                    </Col>

                    <Col xs={12} className="mt-2 mb-2 d-sm-none">
                      <Button className="button-menu ">
                        <img src={Increase} alt="" /> &nbsp; เพิ่มรายชื่อ
                      </Button>
                    </Col>
                  </Row>
                </Row>

                <Row className="g-0 justify-content-center m-0">
                  <Col className="set-table-stock p-0">
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>รหัสสมาชิก</th>
                          <th>ชื่อสมาชิก</th>
                          <th>โทรศัพท์</th>
                          <th>ยี่ห้อ</th>
                          <th>รุ่น</th>
                          <th>ทะเบียน</th>
                          <th>สี</th>
                          <th>ใช้บริการล่าสุด</th>
                          <th>ยอดใช้บริการ</th>
                          <th>อายุสมาชิก</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        <div id="overlay" className={ isLoading ? 'd-block' : ''}>
                          <div className='spinner-field'>
                            <div className='spinner-border' role='status'></div>
                          </div>
                        </div>
                        { 
                          Customers.map(customer => {
                            return (
                              <tr key={ customer.id }>
                                <td>{ `${customer.customer_code}` }</td>
                                {
                                  customer.organization && <td>{ `${customer.organization}` }</td>
                                }
                                {
                                  (customer.first_name && customer.last_name) && <td>{ `${customer.first_name} ${customer.last_name}` }</td>
                                }
                                {
                                  (customer.contacts && customer.contacts.length > 0) ?
                                  <td>
                                    {
                                      customer.contacts?.map((contact, key) => {
                                        return (
                                          JSON.parse(contact.phone)?.map((phone, i) => {
                                            return (
                                              <span key={`phone-${key}-${phone}`}>{
                                                ((key+1) === customer.contacts.length) && (i+1 === JSON.parse(contact.phone).length) ? `${phone}` : `${phone}, `
                                              }</span>
                                            )
                                          })
                                        )
                                      })
                                    }
                                  </td>
                                  :
                                  <td>
                                    {
                                      customer.phone ? 
                                      <span>{ `${JSON.parse(customer.phone)}` }</span>
                                      : '-' 
                                    }
                                  </td>
                                }
                                <td>
                                  {
                                    customer.cars?.map((car, key) => {
                                      return (
                                        <p key={`brand-${key}`} className='m-0'>{car.car_serie && car.car_serie.car_model && car.car_serie.car_model.car_brand &&car.car_serie.car_model.car_brand.name}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td>
                                  {
                                    customer.cars?.map((car, key) => {
                                      return (
                                        <p key={`model-${key}`} className='m-0'>{car.car_serie && car.car_serie.car_model && car.car_serie.car_model.model}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td>
                                  {
                                    customer.cars?.map((car, key) => {
                                      return (
                                        <p key={`code-${key}`} className='m-0'>{car?.license_plate}</p>
                                      ) 
                                    })
                                  }
                                </td>
                                <td>
                                  {
                                    customer.cars?.map((car, key) => {
                                      return (
                                        <p key={`color-${key}`} className='m-0'>{car?.color?.name}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td>
                                  {
                                    '-'
                                  }
                                </td>
                                <td>
                                  {
                                    '-'
                                  }
                                </td>
                                <td>{ dayjs(customer.created_at).fromNow(true) }</td>
                                <td>
                                    <Button className="view-button" onClick={() => {
                                      ActiveTap(customer.id, '1')
                                    }}>
                                      <img src={view} />
                                    </Button>
                                </td>
                                <td>
                                  <Button
                                    className="button-package"
                                    onClick={(e) =>
                                      deleteConfirmation(customer)
                                    }
                                  >
                                    <img src={Delete} />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          options={Pages}
                          onChange={(data) => setSearch({ ...Search, PerPage: data.value })}
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {
                        Search.Rows > 0 && !isLoading &&
                        <MyPagination totPages={Search.Rows} currentPage={Search.CurrentPage} isLoading={isLoading} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบสมาชิก { `${ deleting && deleting.type === 'บุคคลธรรมดา' ? `${deleting?.first_name} ${deleting?.last_name}` : deleting?.organization }` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยินยันการลบสมาชิก { `${ deleting && deleting.type === 'บุคคลธรรมดา' ? `${deleting?.first_name} ${deleting?.last_name}` : deleting?.organization }` } </h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteCustomer() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
