export default {

  menuName: {
    mrk: 'Mr. Kleane Sky',
    member: 'Customer (B2C)',
    user: 'User',
    service: 'จัดการบริการ',
    notification: 'Notification',
    b2b: 'Company (B2B)',
    stock: 'Stock',
    package: 'แพ็คเกจ',
    appointment: 'การนัดหมาย',
    property: 'ทรัพย์สิน',
    product: 'วัสดุอุปกรณ์(สินค้า)',
    technicianQueue: 'คิวช่างกระจก',
    management: 'การจัดการสาขา',
    setting: 'การตั้งค่า',
    staff: 'ครอบครัว(MRKfam)',
    report: 'รายงาน',
    bill: 'Bill',
    job: 'JOB',
    glass: 'Glass',
  },

};