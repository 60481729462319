import React, { useState , useEffect} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Delete from '../../assets/icon/delete.png';

//API
import { LoadServices } from 'actions/service'
import { FindCarOwner } from 'actions/customer/car';

export default function Service() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDisabled, setDisabled] = useState(false);
  const [isClearable, setClearable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isRtl, setRtl] = useState(false);
  const [isSearchable, setSearchable] = useState(true);
  const [Services, setServices] = useState([]);
  const [validated, setValidated] = useState(false);

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

  const [Data,setData] = useState({
    StorageListService: parseDataJob && parseDataJob.PackageList.length > 0
    ? parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const StorageModel = {
    key: 0,
    PackageId: null,
    PackageCode: null,
    PackageName: null,
    Quantity: null,
    Price: '',
    TypeWork:'',
    FilmThickness: '',
    ServiceNow: true,
  }

  const [CarOwner,setCarOwner] = useState([]);

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function SetService(item) {
    const car_owner_size = CarOwner?.car_serie?.size
    let insert = StorageModel
      insert.key = (Data.StorageListService.slice(-1).length > 0 ? Data.StorageListService.slice(-1)[0].key : 0) + 1
      insert.PackageId = item.id
      insert.PackageCode = item.code
      insert.PackageName= item.name
      insert.form = 'service'
      insert.Status = 'new'
      let result = 0
      if(item.sale_type === 'size'){
        result = item.service_sale_type?.find((i) => {
          return i.size === car_owner_size
        })
      }
      insert.Price = item.sale_type === 'all' ? item.all_price : result.amount
      setData({...Data, StorageListService:[...Data.StorageListService, insert]})
      if(Data.StorageListService > 0){
        setValidated(false)
      }
      const data = Data.StorageListService
      data.push(insert)

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = data;

      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }

  function updateLocalStorage(data,item,form){
    
    if(form === 'quantity'){
      setData(prevState => ({...Data,
        StorageListService: prevState.StorageListService.map(
          el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
        )}))
  
      item.Quantity = Number(data.target.value)
    }

    if(form ==='note'){
      setData({...Data, note: data.target.value })
      Data.note = data.target.value
    }

    if(form ==='insite_note'){
      setData({...Data, insite_note: data.target.value })
      Data.insite_note = data.target.value
    }

    if(form ==='things_in_car'){
      setData({...Data, things_in_car: data})
      Data.things_in_car = data
    }

    if(form ==='defect_car'){
      setData({...Data, defect_car: data})
      Data.defect_car = data
    }

    if(form ==='area'){
      setData({...Data, area: data.target.value })
      Data.area = data.target.value
    }

    const result = Data.StorageListService

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = result;
      parseDataJob.insite_note = Data.insite_note
      parseDataJob.note = Data.note
      parseDataJob.area = Data.area
      parseDataJob.things_in_car = Data.things_in_car
      parseDataJob.defect_car = Data.defect_car

      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }
  async function GetServices(){
    setLoading(true)
    try {
      const response = await dispatch(LoadServices());
      setServices(response.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const removeService = (item) => {
    let find = null;
    find = Data.StorageListService.filter(i => i.key !== item.key);

    setData({ ...Data,
      StorageListService : find,
    })

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = find;

      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
    if(Data.StorageListService < 1){
      setValidated(true)
    }
  } 

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  useEffect(() => {
    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    GetServices();
    getCarSize(parseDataJob.car_id);
  },[]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="ค้นหาสินค้าด้วยรหัสบริการ, ชื่อบริการ"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              name="color"
              options={Services}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(data) => SetService(data)}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-3 m-0">
          <Col className="set-table-inside p-0">
            <table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th>ลำดับ</th>
                  <th>รหัสรายการ</th>
                  <th>ชื่อรายการ</th>
                  <th>จำนวน</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
              {
                 Data.StorageListService?.map((item, key) => {
                  return (
                    <tr>
                      <td>{ key + 1}</td>
                      <td>{item.PackageCode}</td>
                      <td>{item.PackageName} 
                        <p>
                          { getTextByPackage(item)}
                          { getDisplayTextPositionCoating(item) }
                          { getDisplayTextPositionUse(item)}
                          { item.TextDetails }
                        </p>
                          {item.ServiceNow === true ? 'บริการทันที' : ''
                          
                          }</td>
                      <td>
                        <Form.Control className="input-table"
                          disabled={item.Status === 'appointment'}
                          value={item.Quantity ? item.Quantity : ''}
                          type="number"
                          required
                          onChange={
                            (data) => {
                              updateLocalStorage(data,item,'quantity');
                            }
                          }
                        ></Form.Control>
                      </td>
                      <td>
                        <Button type="button"  disabled={item.Status === 'appointment'} className="button-table" onClick={() => removeService(item)}>
                          <img src={Delete} />
                        </Button>
                      </td>
                    </tr>
                  );
                 })
                }
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        <Row className="mt-3 mb-5">
          <Col lg={6}>
            <Row>
              <Col lg={3} className="text-left">
                <Form.Label>หมายเหตุ</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  className="input-area"
                  rows={5}
                  value={Data.note}
                  onChange={ (data) => {
                    setData({...Data, note: data.target.value});
                    updateLocalStorage(data,null,'note');
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={3} className="text-left">
                <Form.Label>โน้ตภายใน</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  className="input-area"
                  rows={5}
                  value={Data.insite_note}
                  onChange={ (data) => {
                    setData({...Data, insite_note: data.target.value});
                    updateLocalStorage(data,null,'insite_note');
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
