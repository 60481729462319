import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal
} from 'react-bootstrap';
import Setting from 'assets/icon/setting.png';
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import IconBar from './Components/IconBar';
import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import Edit from 'assets/icon/key-fix.png';
import Delete from 'assets/icon/delete.png';
import Add from 'assets/icon/add_menu.png';

import MyPagination from 'Components/MyPagination';

//API
import {LevelList, DestroyLevel} from '../../actions/settings/permission';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const [Rows, setRows] = useState(0);

  const [isLoading, setisLoading] = useState(false);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });

  const [Levels,setLevels] = useState([]);

  const GetLevels = async (data) => {
    setisLoading(true)
    const params = {
      limit: Search.PerPage,
      page: Search.CurrentPage
    }
    try {
      const response = await dispatch(LevelList(params));
      if(response.data.data && response.data.data.data){
        setLevels(response.data.data.data);
        setRows(response.data.data.last_page);
        setisLoading(false)
      }else{
        setLevels(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetLevels();
  }, [Search.PerPage, Search.CurrentPage]);


  function close() {
    let path = `/setting`;
    history.push(path);
  }

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(null);
  // const [errors, setErrors] = useState([]);

  function deleteConfirmation(level) {
    setDeleting(level)
    setConfirmation(true)
  }

  const DeleteLevel = () => {
    dispatch(DestroyLevel(deleting.id)).then( response => {
      GetLevels()
      setConfirmation(false)
      setDeleting(null)
    }).catch(e => {
      console.log(e)
    })
  }
  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <IconBar />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    {/* <Row>
                      <Col xs={12} md={9} lg={4}>
                        <Form.Control placeholder="ชื่อประเภท"></Form.Control>
                      </Col>
                      <Col md={3} lg={3} xl={2}>
                        <Button type="button" className="button">
                          <img src={Add} /> &nbsp; เพิ่มรายการ
                        </Button>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
                <Row className="justify-content-center set-hidden-add">
                  <Col>
                    <Button type="button" className="button">
                      <img src={Add} /> &nbsp; เพิ่มรายการ
                    </Button>
                  </Col>
                </Row>
                <div className="set-height">
                  <Row className="g-0   mt-3 justify-content-center">
                    <Row className=" mt-3">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="font-weight-bold">ชื่อ</th>
                            <th className="font-weight-bold">สถานะ</th>
                            <th className="font-weight-bold">การใช้งาน</th>
                            <th className="font-weight-bold">แอคชั่น</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            Levels?.map( (level, index) => {
                              return (
                                <tr className="t-tr-setting">
                                  <td>{level.name}</td>
                                  <td>
                                      {
                                        level.active === true ?  <p className="" style={{ color: 'green' }}>
                                        เปิด
                                      </p> : <p className="" style={{ color: 'red' }}>
                                        ปิด
                                      </p>
                                      }
                                     
                                  </td>
                                  <td> { level.middle === true ? <p>ระบบกลาง</p> : <p>สาขา</p>}</td>
                                  <td>
                                  <Link to={`/setting/permission/edit/${level.id}`}>
                                    <Button type="" className="button-package">
                                      <img src={Edit} />
                                    </Button>
                                </Link>
                                    
                                    &nbsp; &nbsp;
                                    <Button type="button" onClick={(e) => deleteConfirmation(level)} className="button-package">
                                      <img src={Delete} />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                       
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={5} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                      <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          isSearchable={false}
                          options={Pages}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) =>
                            setSearch({ ...Search, PerPage: data.value })
                          }
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {
                        !isLoading &&
                        <MyPagination
                          totPages={Rows}
                          currentPage={Search.CurrentPage}
                          pageClicked={(e) => {
                            setSearch({ ...Search, CurrentPage: e });
                          }}
                        ></MyPagination>
                        } */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบระดับ { `${deleting?.name}` }</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบระดับ { `${deleting?.name}` }</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => DeleteLevel() }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}