import React, { useState, useEffect } from 'react';
import {
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from 'reactstrap';
import { Container, Row, Col, Form, Dropdown , Pagination, } from 'react-bootstrap';


import Select from 'react-select';
import note_car from 'assets/icon/note-car.png';
import add_menu from 'assets/icon/add_menu.png';
import MyPagination from 'Components/MyPagination'

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HistoryCustomer } from 'actions/customer/index';
import dayjs from 'dayjs';

export default function CustomerHistory() {

  const dispatch = useDispatch();
  const { id } = useParams();

  const [search, setSearch] = useState({
    customer_id: id,
    PerPage: 10,
    CurrentPage: 1,
    Rows: 0,
  });

  const [history, setHistory] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [Pages, setPage] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  const GetHistoryCustomer = (params) => {
    setLoading(true);
    const newParams = {
      customer_id: id,
      limit: search.PerPage,
      page: search.CurrentPage
      
    }

    dispatch(HistoryCustomer(newParams)).then(response => {
        
      if(search.PerPage > 0){
        // console.log(response.data.data.data);
        setHistory(response.data.data.data);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
        setLoading(false);
      }else{
        // console.log(response.data.data);
        setHistory(response.data.data);
        setSearch({ ...search, 
          Rows: 0,
          CurrentPage: 1 
        })
        setLoading(false);
      }
      
    }).catch(e => {
      console.log(e);
    })
  }

  useEffect(() => {
    GetHistoryCustomer();
  }, [search.PerPage, search.CurrentPage]);


  const [statepage, setStatepage] = useState([{ value: '', label: '' }]);

  function SetNumberPerPage(data) {
    setStatepage({ value: data.value, label: data.value });
  }
  const optionPage = [
    { value: 'all', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ];
  return (
    <>
      <div className="customerhistory">
        <Table responsive className="custom-table-info text-nowrap">
          <thead className="thead-custom">
            <tr className="text-center">
              <th>เลขที่บิล</th>
              <th>เลขที่ JOB</th>
              <th>สาขารับบริการ</th>
              <th>วันรับบริการ</th>
              <th>รายการ</th>
              <th>สถานะ</th>
              <th>การชำระ</th>
            </tr>
          </thead>
          <tbody className="table-custom">
            {
              history.map(data => {
                return(
                  <tr key={ data.id }>
                    <td>{ data.job_detail.job.bill === null ? '-' : data.job_detail.job.bill.bill_code }</td>
                    <td>{ data.job_detail.job.job_code }</td>
                    <td>{ data.job_detail.job.branch.name }</td>
                    <td>{ dayjs(data.job_detail.job.created_at).format('DD/MM/YYYY') }</td>
                    <td>{ data.job_detail.package.name }</td>
                    <td>{ data.status }</td>
                    {
                      data.job_detail.job.bill === null ?
                        <td>{'ชำระแล้ว'}</td>
                      :
                        <td>{ data.job_detail.job.bill !== null && data.job_detail.job.bill.debtor_status === 1 ? 'ลูกหนี้' : data.job_detail.job.bill.status_pay === 'pending' ? 'ยังไม่ชำระ' : 'ชำระแล้ว' }</td>
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </div>
        <Row className="g-0 mt-5 ">
            <Col lg={4} className=" set-page">
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>แสดง</p>
                </Col>
                <Col lg={7}>
                  <Select
                    className="text-left select-style"
                    aria-label="Default select example"
                    options={Pages}
                    onChange={(data) => setSearch({ ...search, PerPage: data.value })}
                    defaultValue={{ value: 10, label: '10' }}
                  />
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <p style={{ margin: 0 }}>รายการ</p>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex justify-content-end set-paginate">
              <div className="box-paginate">
                {
                  !isLoading ? 
                    search.Rows > 1 && search.PerPage > 0 ?
                      <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                  : 
                    search.Rows > 1 && search.PerPage > 0 ?
                      <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                    : ''
                }
              </div>
            </Col>
          </Row>
    </>
  );
}
