import axios, { post } from 'axios';

export const ImportExcel = (data) => async (dispatch) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/import-excel`, data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};