import { post } from 'axios';


export const CreateAssignJob = (formData) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/assign-jobs`,
    formData
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateAssignJob = ({id, formData}) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/assign-jobs/${id}`,
    formData
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

