import React, { useState, useEffect, useRef  } from 'react';

import LogoImage from "assets/icon/Mr.Kleane.png";
import Phone from "assets/icon/phone.png";
import QRcode from "assets/images/qrcode.png";

import 'Setting/PreviewBill/Component/css/bill-1.css';

import dayjs from 'dayjs'

export class Bill1 extends React.PureComponent {
  
  render() {

    const { Bill } = this.props

    const renderProduct = (detail) => {
      if (detail.package) {
        return detail.package.name
      }
      if (detail.product) {
        return detail.product.name
      }
      if (detail.service) {
        return detail.service.name
      }
    }

    const displayPhone = (phone,form) => {
        if(phone && Array.isArray(phone)){
          if(phone && Array.isArray(phone) === true && phone.length > 1){
            return phone.join(', ')
          }else{
            return [phone]
          }
        }else if(phone && Array.isArray(Array.isArray(phone) ? JSON.parse(phone) : false)){
          if(phone && Array.isArray(JSON.parse(phone)  && phone.length > 1)){
            return JSON.parse(phone).join(', ')
          }else{
            return JSON.parse(phone)
          }
        }else{
          return [phone]
        }
    }

    return (
      <div id="bill-1" className='bill-1'>
        <div className='original'>
          <div className='header'>
            <img src={LogoImage} />
            
            <div className='address'>
              <div className='line'>
                <div className='qr'>
                  <div className='phone'>
                    <div className='inline'>
                      <img src={Phone} />
                      <p>{ `${displayPhone(Bill?.job?.branch?.phone,'branch')}` }</p>
                    </div>
                    <div className='type'>ต้นฉบับ</div>
                  </div>
                  <img src={QRcode} />
                </div>
              </div>
              <div className='address-2'>
                <p>Mr.Kleane สาขา { Bill?.job?.branch?.name }</p>
                <p>ที่อยู่ { Bill?.job?.branch?.address }</p>
              </div>
            </div>
          </div>

          <div className='inline' style={{marginTop: '10px'}}>
            <div className='customer-info'>
              <label>ข้อมูลลูกค้า</label>
              <p>ชื่อ { `${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` }</p>
              <p>ที่อยู่ { `${Bill?.job?.customer?.address ? Bill?.job?.customer?.address : ''}` }</p>
              <p>เบอร์โทร{
                Bill && Bill.job && Bill.job.customer && Bill.job.customer.phone ? 
                <span> { `${JSON.parse(Bill.job.customer.phone)}` }</span>
                : '-' 
              }
              </p>
            </div>
            
            <div className='invoice'>
              <div className='label-receipt'>
                RECEIPT
              </div>
              <p style={{marginTop: '10px'}}>INVOICE NO.: { Bill?.bill_code }</p>
              <p>วันที่ { dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
              <p>พนักงานขาย {`${Bill?.job?.create_by?.employee?.prefix} ${Bill?.job?.create_by?.employee?.fullname}`}</p>
            </div>
          </div>

          <div className='table-group'>
            <div className='table-header'>
              <div className='title'>รายการ</div>
              <div className='title'>ราคา/หน่วย</div>
              <div className='title'>จำนวน</div>
              <div className='title'>จำนวนเงิน</div>
            </div>

            <div className='table-body'>
              {
                Bill?.job?.job_details.map((data, index) => {
                  return(
                    <>
                      <div className='table-field'>
                        <div>{ renderProduct(data) }</div>
                        <div>{data.price}</div>
                        <div>{data.quantity}</div>
                        <div>{data.total}</div>
                      </div>
                    </>
                  )
                })
              }
              {
                Number(Bill?.job?.job_details.length) < 7 &&
                [...Array(7 - Number(Bill?.job?.job_details.length))].map((e, i) => {
                  return(
                    <div className='table-field'>
                      <div>{``}</div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          
          <div className='inline' style={{marginTop: '10px'}}>
            <div className='car-info'>
              <label>ข้อมูลรถ</label>
              <p>ยี่ห้อ : {Bill?.job?.car_owner?.car_serie?.car_model?.car_brand?.name}</p>
              <p>รุ่น : {Bill?.job?.car_owner?.car_serie?.car_model?.model}</p>
              <p>ทะเบียนรถ : {Bill?.job?.car_owner?.license_plate}</p>
              <p>เลขตัวถัง : {Bill?.job?.car_owner?.chassis_number}</p>
            </div>

            <div className='note'>
              <label>หมายเหตุ</label>
              <p>{Bill?.job?.note}</p>
            </div>

            <div className='price'>
              <div className='inline'>
                <p className='title'>จำนวนเงิน</p>
                <p>{ Bill?.job?.total }</p>
              </div>

              <div className='inline'>
                <p className='title'>ส่วนลด</p>
                <p>{ Bill?.job?.discount_type === 'baht' ? Bill.job.discount : Bill?.job?.discount_calculate }</p>
              </div>

              <div className='inline'>
                <p className='title'>จำนวนเงินหลังหักส่วนลด</p>
                <p>{ (Bill?.job?.total - (Bill?.job?.discount_type === 'baht' ? Bill.job.discount : Bill?.job?.discount_calculate)).toFixed(2) }</p>
              </div>

              <div className='inline'>
                <p className='title'>ภาษีมูลค่าเพิ่ม({Number(Bill?.job?.vat) + '%'})</p>
                <p>{Bill?.job?.vat_calculate}</p>
              </div>

              <div className='inline'>
                <p className='title'>ยอดสุทธิ</p>
                <p>{ Bill?.job?.total_price }</p>
              </div>

              <div className='signature'>
                <div className='employee'>ลงลายมือชื่อพนักงาน</div>
                <div className='car-owner'>ลงลายมือชื่อเจ้าของรถ</div>
              </div>
            </div>
          </div>
        </div>

        <div className='copy'>
          <div className='header'>
            <img src={LogoImage} />
            
            <div className='address'>
              <div className='line'>
                <div className='qr'>
                  <div className='phone'>
                    <div className='inline'>
                      <img src={Phone} />
                      <p>{ `${displayPhone(Bill?.job?.branch?.phone,'branch2')}` }</p>
                    </div>
                    <div className='type'>สำเนา</div>
                  </div>
                  <img src={QRcode} />
                </div>
              </div>
              
              <div className='address-2'>
                <p>Mr.Kleane สาขา { Bill?.job?.branch?.name }</p>
                <p>ที่อยู่ { Bill?.job?.branch?.address }</p>
              </div>
            </div>
          </div>

          <div className='inline' style={{marginTop: '10px'}}>
            <div className='customer-info'>
              <label>ข้อมูลลูกค้า</label>
              <p>ชื่อ { `${Bill?.job?.customer?.first_name} ${Bill?.job?.customer?.last_name}` }</p>
              <p>ที่อยู่ { `${Bill?.job?.customer?.address ? Bill?.job?.customer?.address : ''}` }</p>
              <p>เบอร์โทร{
                Bill && Bill.job && Bill.job.customer && Bill.job.customer.phone ? 
                <span> { `${JSON.parse(Bill.job.customer.phone)}` }</span>
                : '-' 
              }
              </p>
            </div>
            
            <div className='invoice'>
              <div className='label-receipt'>
                RECEIPT
              </div>
              <p style={{marginTop: '10px'}}>INVOICE NO.: { Bill?.bill_code }</p>
              <p>วันที่ { dayjs(Bill?.created_at).format('DD/MM/YYYY') }</p>
              <p>พนักงานขาย {`${Bill?.job?.create_by?.employee?.prefix} ${Bill?.job?.create_by?.employee?.fullname}`}</p>
            </div>
          </div>

          <div className='table-group'>
            <div className='table-header'>
              <div className='title'>รายการ</div>
              <div className='title'>ราคา/หน่วย</div>
              <div className='title'>จำนวน</div>
              <div className='title'>จำนวนเงิน</div>
            </div>

            <div className='table-body'>
              {
                Bill?.job?.job_details.map((data, index) => {
                  return(
                    <>
                      <div className='table-field'>
                        <div>{ renderProduct(data) }</div>
                        <div>{data.price}</div>
                        <div>{data.quantity}</div>
                        <div>{data.total}</div>
                      </div>
                    </>
                  )
                })
              }
              {
                Number(Bill?.job?.job_details.length) < 7 &&
                [...Array(7 - Number(Bill?.job?.job_details.length))].map((e, i) => {
                  return(
                    <div className='table-field'>
                      <div>{``}</div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          
          <div className='inline' style={{marginTop: '10px'}}>
            <div className='car-info'>
              <label>ข้อมูลรถ</label>
              <p>ยี่ห้อ : {Bill?.job?.car_owner?.car_serie?.car_model?.car_brand?.name}</p>
              <p>รุ่น : {Bill?.job?.car_owner?.car_serie?.car_model?.model}</p>
              <p>ทะเบียนรถ : {Bill?.job?.car_owner?.license_plate}</p>
              <p>เลขตัวถัง : {Bill?.job?.car_owner?.chassis_number}</p>
            </div>

            <div className='note'>
              <label>หมายเหตุ</label>
              <p>{Bill?.job?.note}</p>
            </div>

            <div className='price'>
              <div className='inline'>
                <p className='title'>จำนวนเงิน</p>
                <p>{ Bill?.job?.total }</p>
              </div>

              <div className='inline'>
                <p className='title'>ส่วนลด</p>
                <p>{ Bill?.job?.discount_type === 'baht' ? Bill.job.discount : Bill?.job?.discount_calculate }</p>
              </div>

              <div className='inline'>
                <p className='title'>จำนวนเงินหลังหักส่วนลด</p>
                <p>{ (Bill?.job?.total - (Bill?.job?.discount_type === 'baht' ? Bill.job.discount : Bill?.job?.discount_calculate)).toFixed(2) }</p>
              </div>

              <div className='inline'>
                <p className='title'>ภาษีมูลค่าเพิ่ม({Number(Bill?.job?.vat) + '%'})</p>
                <p>{Bill?.job?.vat_calculate}</p>
              </div>

              <div className='inline'>
                <p className='title'>ยอดสุทธิ</p>
                <p>{ Bill?.job?.total_price }</p>
              </div>

              <div className='signature'>
                <div className='employee'>ลงลายมือชื่อพนักงาน</div>
                <div className='car-owner'>ลงลายมือชื่อเจ้าของรถ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}