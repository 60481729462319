import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
  ToastContainer, Toast
} from 'react-bootstrap';
import BillImage from 'assets/icon/job.png'
import Footer from '../Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import GroupButtonStock from '../Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';

import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Write from '../assets/icon/flat-style-circle-write.png';
import Arrow from '../assets/icon/arrow.png';
import Document from '../assets/icon/document.png';
import Search from '../assets/icon/eyes-search.png';
import Save from '../assets/icon/flat-style-circle-save.png';
import Return from '../assets/icon/flat-style-circle-turn-on.png';
import Product_Component from '../Components/Job/Product';
import Service_Component from '../Components/Job/Service';
import Package_Component from '../Components/Job/Package';

//API
import { CreateJob } from 'actions/jobs/index'
import { useDispatch } from 'react-redux';
import moment from 'moment';

export default function Inside() {
  const dispatch = useDispatch()
  const history = useHistory();

  const [product, setProduct] = useState(true);
  const [creating, setCreating] = useState(false);
  const [service, setService] = useState(false);
  const [Package, setPackage] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Alert,setAlert] = useState([]);
  const optionHours = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
  ];

  const optionMinutes = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
    { value: '60', label: '60' },
  ];

  const [sPrice, setsPrice] = useState(null);
  const [sDiscount, setsDiscount] = useState(null);
  const [sCost, setsCost] = useState(null);
  const [sCommission, setsCommission] = useState(null);
  const [sTotal, setsTotal] = useState(null);
  const [sDifferent, setsDifferent] = useState(null);
  const [Trigger, setTrigger] = useState(true)

  const [data, setData] = useState({
    pickup_date: null,
    pickup_hours: null,
    pickup_minutes: null,
  });

  function modifileShowroom(name, val){
    if(name == "price"){setTrigger(false);setsPrice(val)}
    if(name == "discount"){setTrigger(false);setsDiscount(val)}
    if(name == "cost"){setTrigger(false);setsCost(val)}

    if(name == "comission"){
      setTrigger(true)
      setsCommission(val)
      // upComiss(true)
    }

    // upComiss()
    // console.log(sPrice)
  }

  function upComiss() {
    let result = Number(sPrice) - (Number(prePrice) - Number(sCost));
    setsCommission(result)

  }

  const [DefectCar,setDefectCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
  };

  const [Type, setType] = useState('');
  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  async function StorageJob(e){
    
    setCreating(true);
    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    // console.log('parseDataJob',parseDataJob);
    // return false
    const PackageListStatusNew = parseDataJob.PackageList.length > 0 ? parseDataJob.PackageList : [];

    const PackageList = PackageListStatusNew.filter((item) => {
      return item.Status === 'new'
    })
  
    const PackageListAppointment = parseDataJob.PackageListAppointment && parseDataJob.PackageListAppointment.length > 0 ? parseDataJob.PackageListAppointment : [];
   

    setValidated(true);
    let formData = new FormData();
    formData.append('customer_id', parseDataJob.customer_id);
    formData.append('car_owner_id', parseDataJob.car_id);
    formData.append('employee_id', parseDataJob.employee_id);
    formData.append('branch_id', parseDataJob.branch_id);
    // if(PackageList.length > 0 ){
      for (let i = 0; i < PackageList.length; i++) {
        //get total price in list
        const new_quantity = PackageList[i].Quantity ? PackageList[i].Quantity : 0
        const new_price = PackageList[i].Price ? PackageList[i].Price : 0
        PackageList[i].total =  Number(new_quantity) * Number(new_price)
        if(PackageList[i].Other && PackageList[i].Other.length > 0) PackageList[i].other =  PackageList[i].Other.map(item => item.value)
        //end total price in list
        formData.append(`package_list[${i}]`, JSON.stringify(PackageList[i]));
      }
      for (let i = 0; i < PackageListAppointment.length; i++) {
        formData.append(`package_list_appointment[${i}]`, JSON.stringify(PackageListAppointment[i]));
      }
      //get sum total
      const sum = PackageList.reduce((accumulator, object) => {
        return accumulator + object.total;
      }, 0);
      //end sum total
      formData.append('sum_total', sum)
      formData.append('type', parseDataJob.type)
      formData.append('show_room_employee_id', parseDataJob.show_room_employee_id)
      formData.append('company_id', parseDataJob.company_id)
     if(parseDataJob.note)formData.append('note', parseDataJob.note)
     if(parseDataJob.insite_note)formData.append('insite_note', parseDataJob.insite_note)
     if(parseDataJob.defect_car)formData.append('defect_car', parseDataJob.defect_car)
     if(parseDataJob.things_in_car)formData.append('things_in_car', parseDataJob.things_in_car)
     if(parseDataJob.area)formData.append('area', parseDataJob.area)
     if(parseDataJob.enamel)formData.append('enamel', parseDataJob.enamel)
     if(parseDataJob.wash)formData.append('wash', parseDataJob.wash)
     if(parseDataJob.attrition)formData.append('attrition', parseDataJob.attrition)
     if(parseDataJob.ozone_oven)formData.append('ozone_oven', parseDataJob.ozone_oven)
     if(parseDataJob.wash_upholstery)formData.append('wash_upholstery', parseDataJob.wash_upholstery)
     if(parseDataJob.wash_belt)formData.append('wash_belt', parseDataJob.wash_belt)
     if(parseDataJob.wash_door)formData.append('wash_door', parseDataJob.wash_door)
     if(data.pickup_date)formData.append('pickup_date', data.pickup_date)
     if(data.pickup_hours)formData.append('pickup_hours', data.pickup_hours)
     if(data.pickup_minutes)formData.append('pickup_minutes', data.pickup_minutes)

     if(Number(sPrice))formData.append('showroom_price', sPrice)
     if(Number(sDiscount))formData.append('showroom_discount', sDiscount)
     if(Number(sCost) > 0)formData.append('showroom_cost', sCost)
     if(Number(sCommission) > 0)formData.append('showroom_comission', sCommission)
     if(Number(sTotal) > 0)formData.append('showroom_total', sTotal)
     
     if(DefectCar)formData.append('wait_car', DefectCar)
    // }

    try {
      const response = await dispatch(CreateJob(formData));
     
      if(response){
        if(response.data.message === 'success'){
          localStorage.removeItem('job-inside-data');
          history.push(`/job`);
        }else if(response.data.message === 'request_import_stock'){
          const data = response.data.data
          const result = []
          data?.map((item) => {
            result.push({
              id: item.id,
              name: item.name,
              less : item.total_request_stock, //รายงานจำนวน สินค้าที่ขาดใน stock
              form : item.form,
            })
          })
          setAlert(result);
        }else{
          alert(response.data.data)
        }
      }else{
        console.log('failed')
        alert('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }

    setCreating(false);
    
  }

  const [prePrice, setPrePrice] = useState(0);
  const getPrePrice = () => {
    setPrePrice(0);
    if(parseDataJob.PackageList.length > 0){
      let total = 0;
      parseDataJob.PackageList.map(item => {
        let result = item.Quantity * item.Price;
        total = total + result;
      });
      setPrePrice(total);
    }else{
      setPrePrice(0);
    }

    if(!Trigger){
      let result = Number(sPrice) - (Number(prePrice) - Number(sCost));
      setsCommission(result)
      console.log('Commission', result)
    }

    let total = Number(sPrice) - Number(sDiscount);
    setsTotal(total)

    setsDifferent(Number(prePrice) - Number(sCost));

  };

  function LoadLoop() {
    getPrePrice()
    setTimeout(() => {
      LoadLoop()
    }, 3000);
  }
  
  function LinkTo(url){
    history.push(url)
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    let path = `/job`;
    history.push(path);
  }
  const handleProduct = () => {
    setProduct(true);
    setService(false);
    setPackage(false);
  };
  const handleService = () => {
    setProduct(false);
    setService(true);
    setPackage(false);
  };
  const handlePackage = () => {
    setProduct(false);
    setService(false);
    setPackage(true);
  };

  useEffect(() => {

    setType(parseDataJob.type)
    getPrePrice()
    // upComiss()

    let previousValue = localStorage.getItem('job-inside-data');

    const intervalId = setInterval(() => {
      const updatedValue = localStorage.getItem('job-inside-data');

      if (updatedValue !== previousValue) {
        // Value has been updated, call your function here

        let pre = JSON.parse(updatedValue);
        console.log(pre)

        let total = 0;
        pre.PackageList.map(item => {
          let result = item.Quantity * item.Price;
          total = total + result;
        });
        setPrePrice(total);

        // Update the previous value to the new value
        previousValue = updatedValue;
      }
    }, 1000); // Check every 1 second

    return () => clearInterval(intervalId); // Clean up the interval on component unmount

    // LoadLoop()
    //
  },[parseDataJob])
  return (
    <>
      <Container fluid className="package-container Service Job bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>
                    JOB &gt; ภายในร้าน &gt; &nbsp;
                    {product && !service && !Package ? 'สินค้า' : ''}
                    {!product && service && !Package ? 'บริการ' : ''}
                    {!product && !service && Package ? 'แพคเกจ' : ''}
                  </b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>
                    JOB &gt; ภายในร้าน &gt; &nbsp;
                    {product && !service && !Package ? 'สินค้า' : ''}
                    {!product && service && !Package ? 'บริการ' : ''}
                    {!product && !service && Package ? 'แพคเกจ' : ''}
                  </b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="justify-content-center">
                  <Col lg={11}>
                    <Row className="mt-4 mb-4 justify-content-center">
                      <Col className="d-flex justify-content-start set-position-radio">
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                              inline
                              label="วัสดุอุปกรณ์(สินค้า)"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              onChange={handleProduct}
                              checked={product}
                            />
                            <Form.Check
                              inline
                              label="บริการ"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              onChange={handleService}
                              checked={service}
                            />
                            <Form.Check
                              inline
                              label="แพ็คเกจ"
                              name="group1"
                              type={type}
                              id={`inline-${type}-3`}
                              onChange={handlePackage}
                              checked={Package}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      {product && !service && !Package ? (
                        <Product_Component />
                      ) : (
                        ''
                      )}
                      {!product && service && !Package ? (
                        <Service_Component />
                      ) : (
                        ''
                      )}
                      {!product && !service && Package ? (
                        <Package_Component />
                      ) : (
                        ''
                      )}
                      <Row className="align-items-center mt-3 mb-3">
                        <Col xs={4} lg={2} className="text-left mt-2  mb-2">
                          <Form.Label>เวลานัดรับรถ</Form.Label>
                        </Col>
                        <Col xs={8} lg={3} className="text-left  mt-2  mb-2">
                          <DatePicker
                            placeholder=""
                            suffixIcon={<img src={Calendar} />}
                            value={data.pickup_date ? moment(data.pickup_date) : null}
                            onChange={(date) => setData({...data, pickup_date: moment(date).format('YYYY-MM-DD')})}
                          />
                        </Col>
                        <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                          <Select
                            options={optionHours}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            defaultValue={optionHours[0]}
                            isClearable={false}
                            isSearchable={false}
                            placeholder="ชั่วโมง"
                            className="select-list"
                            value={data.pickup_hours ? optionMinutes.find(item => item.value === data.pickup_hours) : null }
                            onChange={(e) => setData({...data, pickup_hours: e.value})}
                          />
                        </Col>
                        :
                        <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                          <Select
                            options={optionMinutes}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            defaultValue={optionMinutes[0]}
                            isClearable={false}
                            isSearchable={false}
                            placeholder="นาที"
                            className="select-list"
                            value={data.pickup_minutes ? optionMinutes.find(item => item.value === data.pickup_minutes) : null }
                            onChange={(e) => setData({...data, pickup_minutes: e.value})}
                          />
                        </Col>
                        <Col xs={5} lg={2} className="text-left  mt-2  mb-2">
                          <Form.Check
                            type="checkbox"
                            label="รอรับรถ"
                            onChange={handleDefectCar}
                            checked={DefectCar}
                            className="checkbox-input"
                          />
                        </Col>
                      </Row>

                      { parseDataJob.type == 'show_room' && parseDataJob.status_job == 'new' ? (
                      <Row className="align-items-center mt-3 mb-3">
                        <Col xs={12} lg={6} className="text-left mt-2  mb-2">
                        </Col>
                        <Col xs={12} lg={6} className="text-left mt-2  mb-2">
                          <Row className="align-items-center mt-3 mb-3">

                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                              <Form.Label>ราคาส่ง</Form.Label>
                            </Col>
                            <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                              <input 
                                className="input-table2"
                                style={{ fontSize: '1.1rem', padding: '10px' }}
                                type="number"
                                value={prePrice}
                                readOnly
                                disabled
                              ></input>
                            </Col>

                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                              <Form.Label>Showroom ขายลูกค้า</Form.Label>
                            </Col>
                            <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                              <input 
                                value={sPrice}
                                className="input-table2"
                                style={{ fontSize: '1.1rem', padding: '10px' }}
                                type="number"
                                onChange={(data) => {modifileShowroom('price', data.target.value);}}
                              ></input>
                            </Col>

                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                              <Form.Label>Showroom แจ้งส่วนลด</Form.Label>
                            </Col>
                            <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                              <input 
                                value={sDiscount}
                                className="input-table2"
                                style={{ fontSize: '1.1rem', padding: '10px' }}
                                type="number"
                                onChange={(data) => {modifileShowroom('discount', data.target.value)}}
                              ></input>
                            </Col>

                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                              <Form.Label>ใบสั่ง</Form.Label>
                            </Col>
                            <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                              <input 
                                value={sCost}
                                className="input-table2"
                                style={{ fontSize: '1.1rem', padding: '10px' }}
                                type="number"
                                onChange={(data) => {modifileShowroom('cost', data.target.value)}}
                              ></input>
                            </Col>
                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                            <Form.Label>ส่วนต่าง</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                            <input 
                              value={sDifferent}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              readOnly
                              disabled
                            ></input>
                          </Col>
                            <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                              <Form.Label>Commission Sale</Form.Label>
                            </Col>
                            <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                              <input 
                                value={sCommission}
                                className="input-table2"
                                style={{ fontSize: '1.1rem', padding: '10px' }}
                                type="number"
                                onChange={(data) => {modifileShowroom('comission', data.target.value)}}
                              ></input>
                            </Col>

                          </Row>
                        </Col>
                      </Row>
                      ) : (
                        ''
                      )}

                      <Row className="set-row-button-create justify-content-center">
                        { 
                          Type === 'show_room' ? ( 
                            <Col sm={4} lg={2}>
                              <Button type="button" className="button" onClick={() => {
                                LinkTo('/job/company-b2b-member')
                              }}>
                                กลับ
                              </Button>
                            </Col>
                          ) : (
                            <Col sm={4} lg={2}>
                              <Button type="button" className="button" onClick={() => {
                                LinkTo('/job/inside-data')
                              }}>
                                กลับ
                              </Button>
                            </Col>
                          )                
                        }
                        
                        <Col sm={4} lg={2}>
                          <Button type="button" className="button" disabled={creating} onClick={() => {
                            StorageJob()
                          }}>
                            {creating ? 'รอสักครู่..' : 'สร้าง'}
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body className="body-set-height">
          <ToastContainer position='top-center'>
            {
              Alert?.map((error, key) => {
                return (
                  <Toast show={true} key={key} delay={5000} bg='danger' autohide onClose={() => setAlert([])}>
                    <Toast.Header>
                      <strong className="me-auto">แจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body>
                      ชื่อสินค้า:{ error.name } -
                      จำนวนที่ขาด:{ error.less} -
                      จาก:{ error.form}
                    </Toast.Body>
                  </Toast>
                )
              })
            }
          </ToastContainer>

        </Modal.Body>
      </Container>
      <Footer />
      
    </>
  );
}
