import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Modal,
  Dropdown
} from 'react-bootstrap';
import Service from '../../assets/icon/management.png';
import Footer from '../../../Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from '../../Components/layout/TabsIcon';
import Back from '../../assets/icon/back.png';
import Select, { components } from 'react-select';
import StockImage from '../../assets/icon/appwizard_list.png'

import Save from '../../assets/icon/new-save.png';
import Getout from '../../assets/icon/new-re-turn.png';
import Print from '../../assets/icon/print.png';
import CanCel from '../../assets/icon/new-cancel.png';
import Delete from '../../assets/icon/delete.png';
import Return from '../../assets/icon/new-re-turn.png';
import { DatePicker, Space } from 'antd';
import Calendar from '../../assets/icon/calendar-bg.png';
import AddLink from '../../assets/icon/add-link.png';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//API
import { ProductList } from 'actions/product'; 
import { PartnerList } from 'actions/partner';
import { CreateOrder } from 'actions/orders';
import { List } from 'actions/employee';

import dayjs from 'dayjs';
export default function PurchaseOrder() {
  const history = useHistory();
  const dispatch = useDispatch();

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const [specialName, setSpecialName] = useState('');

  const [statepage, setStatepage] = useState('');
  // const [searchProduct, setSearchProduct] = useState([
  //   { value: '', label: '' },
  //   { value: '', label: '' },
  //   {
  //     value: <img src={AddLink} />,
  //     label: (
  //       <a className="link" onClick={handleShow}>
  //         <img src={AddLink} /> รายการพิเศษ11
  //       </a>
  //     ),
  //   },
  // ]);

  const StorageModel = {
    key: 0,
    ProductId: null,
    ProductCode: null,
    ProductName: null,
    Quantity: '',
  }

  const [tax, setTax] = useState([
    { value: 'Exclude Vat', label: 'Exclude Vat' },
    { value: 'Include Vat', label: 'Include Vat' },
    { value: 'Non Vat', label: 'Non Vat' },
  ]);
  const [useStatus, setUseStatus] = useState([
    { value: 'order_stock', label: 'เข้าสต็อก' },
    { value: 'use_now', label: 'ใช้ทันที' },
  ]);

  const [Data,setData] = useState({
    PartnerId: null,
    EmployeeId: null,
    // OrderDate: dayjs().format('YYYY-MM-DD'),
    OrderDate: null,
    UserId: null,
    SumTotal: 0,
    VatType: 'non',
    StorageList:[],
    Note: '',
  });
  const [Products, setProducts] = useState([]);
  const [filterProducts, setfilterProducts] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [partners,setPartners] = useState([]);

  //end set state

  //function
  const GetProduct = async (data) => {
    setisLoading(true)
    try {
      const response = await dispatch(ProductList());
      if (response) {
        setProducts(response.data.data);
        setfilterProducts(response.data.data);
        setisLoading(false)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setisLoading(false)
    }
  }

  const GetPartner = async () => {
    setisLoading(true)
    try {
      const params = {
        keyword: '',
      }
      const response = await dispatch(PartnerList(params));
      setPartners(response.data.data);
      setisLoading(false)
    } catch (error) {
      console.error(error);
      
    } finally {
      console.log('finally')
      setisLoading(false)
    }
  }

  function closePage() {
    let path = `/stock/receipt-order`;
    history.push(path);
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    setShow(false);
  }

  function setProductList(item, key) {
    // console.log(item);
    let insert = StorageModel
        insert.key = Data.StorageList.length 
        insert.ProductId = item.id
        insert.ProductCode = item.code
        insert.ProductName= item.name
        insert.UnitId = item.unit && item.unit.id
        insert.UnitName = item.unit && item.unit.name_buy
        insert.SpecialType = item.special_type ? item.special_type : 'normal'
        setData({ ...Data, StorageList:[...Data.StorageList, insert]})
        if(Data.StorageList > 0){
          setValidated(false)
        }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    setValidated(true);
    setisLoading(true);

    setTimeout(async () => {
      const validator = document.querySelectorAll('.validate-fail');

      if(Data.StorageList.length < 1){
        setisLoading(false);
        return alert('โปรดเลือกรายการ')
      }
      if (form.checkValidity() && validator.length === 0) {
        let formData = new FormData();
        if(Data.PartnerId) formData.append('partner_id', Data.PartnerId);
        if(Data.EmployeeId) formData.append('ordered_id', Data.EmployeeId);
        if(Data.OrderDate) formData.append('order_date', Data.OrderDate);
        if(Data.useStatus) formData.append('use_status', Data.useStatus);
        if(Data.StorageList.length > 0){
          for (let i = 0; i < Data.StorageList.length; i++) {
            formData.append(`storage_list[${i}]`, JSON.stringify(Data.StorageList[i]));
          }
        }
        formData.append('note',Data.Note);
        
        try {
          const response = await dispatch(CreateOrder(formData));
          if(response.data.message === 'success'){
            history.push('/stock/receipt-order');
            setisLoading(false)
          }else if(response.data.message === "สินค้าระบบกลางไม่พอสั่ง"){
            const data = response.data.data
            console.log('error',data)
          }
        } catch (error) {
          console.error(error);
          setisLoading(false)
        
        } finally {
          console.log('finally')
          setisLoading(false)
        }
      }
    })
    
  };

  const removeProduct = (item) => {
    let find = null;
    if(item.specialName === 'normal'){
      const ProductId = item.ProductId
      find = Data.StorageList.filter(i => i.ProductId !== ProductId);
      
    }else{
      find = Data.StorageList.filter(i => i.key !== item.key);
    }

    setData({ ...Data,
      StorageList : find,
    })
    if(Data.StorageList < 1){
      setValidated(true)
    }
    // Data.StorageList.splice(find.key,1);
  } 

  const [employees,setEmployees] = useState([]);

const GetEmployee = async () => {
    setisLoading(true)
    try {
      const params = {
        keyword: '',
        branch_id : profile.branch_id
      }
      const response = await dispatch(List(params));
      setEmployees(response.data.data);
      setisLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setisLoading(false);
    }
  }

  const filterProduct = (keyword) => {
    setfilterProducts(Products.filter(item => item.name.includes(keyword)))
  }

  useEffect(() => {
    GetProduct();
    GetPartner();
    GetEmployee();
  }, []);

  return (
    <>
      {
 
          <Container fluid className="package-container Service Stock  bg-mrk">
            <div id="overlay" className={ isLoading ? 'd-block' : ''}>
                <div className='spinner-field'>
                  <div className='spinner-border' role='status'></div>
                </div>
              </div>
            <div className="package">
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      <img className="logo pr-2" src={StockImage} />
                      <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt;  <Link to={`/stock/receipt-order`} className='mr-2'>รายการรับเข้า/สั่งซื้อ</Link> &gt; ใบสั่งซื้อ</b>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closePage}
                    >
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                  <div className="modal-header-mobile">
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closePage}
                    >
                      <img src={Back} />
                    </button>
                    <h5 className="modal-title" id="exampleModalLabel">
                      <img className="logo pr-2" src={StockImage} />
                      <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; สั่งเข้าสต็อก2</b>
                    </h5>
                  </div>
                  <div className="modal-body">
                    <Row className="g-0 tabsicon justify-content-center ">
                      <TabsIcon />
                    </Row>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="Stock-Request-Order-Branch mt-2 mb-2">
                      <Row className="g-0 justify-content-start">
                        <Col md={12} lg={6} className="">
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              xl={2}
                              className="d-flex align-items-center  set-label"
                            >
                              <Form.Label className="font-weight-normal">
                                ผู้สั่ง
                              </Form.Label>
                            </Col>
                            <Col
                              xs={8}
                              sm={6}
                              md={6}
                              lg={5}
                              className="text-left mt-2 mb-2"
                            >
                              <Select
                                className={`select-list ` + (!Data.EmployeeId && validated ? 'validate-fail' : '') }
                                required
                                aria-label="Default select example"
                                placeholder=""
                                options={employees}
                                getOptionLabel={(option) => option.fullname}
                                getOptionValue={(option) => option.id}
                                onChange={
                                  (e) => setData({...Data, EmployeeId: e.id })
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              xl={2}
                              className="d-flex align-items-center  set-label"
                            >
                              <Form.Label className="font-weight-normal">
                                การใช้งาน
                              </Form.Label>
                            </Col>
                            <Col
                              xs={8}
                              sm={6}
                              md={6}
                              lg={5}
                              className="text-left mt-2 mb-2"
                            >
                              <Select
                                className="text-left select-style select-list"
                                aria-label="Default select example"
                                placeholder=""
                                options={useStatus}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={
                                  (e) => setData({...Data, useStatus: e.value })
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              xl={2}
                              className="d-flex align-items-center  set-label"
                            >
                              <Form.Label className="font-weight-normal">
                                วันที่สั่ง
                              </Form.Label>
                            </Col>
                            <Col
                              xs={8}
                              sm={6}
                              md={6}
                              lg={5}
                              className="text-left mt-2 mb-2"
                            >
                              <DatePicker
                                placeholder=""
                                required
                                suffixIcon={<img src={Calendar} />}
                                format={"YYYY-MM-DD"}
                                className={`select-list ` + (!Data.OrderDate && validated ? 'validate-fail' : '') }
                                onChange={
                                  (e) => setData({...Data, OrderDate: e })
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              xl={2}
                              className="text-left   mt-3 mb-3"
                            >
                              <Form.Label className="font-weight-normal">
                                ผู้บันทึก
                              </Form.Label>
                            </Col>
                            <Col
                              xs={8}
                              sm={6}
                              md={6}
                              lg={5}
                              className="text-left mt-3 mb-3"
                            >
                              <p>: {profile.fullname ? profile.fullname : ''}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                        <Dropdown className='w-100 custom-dropdown'>
                          <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                            <div className='d-flex'>
                              <Form.Control
                                className="w-100"
                                placeholder='ค้นหาและเลือกสินค้า'
                                onInput={(e) => filterProduct(e.target.value)}
                                // required
                              ></Form.Control>
                              <Button className='arrow'></Button>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className='w-100'>
                            <div className='limit'>
                              {
                                filterProducts?.map((item, key) => {
                                  return (
                                    <Dropdown.Item className="font-black-only" onClick={() => setProductList(item, key)}>{item.name}</Dropdown.Item>
                                  )
                                })
                              }
                            </div>
                            <Dropdown.Divider />
                            <Dropdown.Item className="font-black-only" onClick={() => { handleShow();setSpecialName('') }}>
                              <img src={AddLink} /> รายการพิเศษ
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        </Col>

                      
                      </Row>
                      <Row className="g-0 justify-content-center m-0">
                        <Col
                          xs={12}
                          className=" mt-3 p-0"
                          style={{ overflow: 'scroll', height: '200px' }}
                        >
                          <table className="table table-responsive table-wash-part ">
                            <thead>
                              <tr className="t-header-package">
                                <th>รหัสสินค้า</th>
                                <th>ชื่อสินค้า</th>
                                <th>หน่วย</th>
                                <th>จำนวน</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="t-body-package">
                            {Data.StorageList?.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td >{item.ProductCode}</td>
                                    <td >{item.ProductName}</td>
                                    <td>{item.UnitName}</td>
                                    <td>
                                      <Form.Control className="input-in-table" 
                                        // value={item.Quantity}
                                        type='Number'
                                        required
                                        onChange={
                                          (data) => {setData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
                                            )
                                          }));
                                          }
                                        }
                                      ></Form.Control>
                                    </td>
                                    <td>
                                    <Button type="button" className="button-table" onClick={() => removeProduct(item)}>
                                      <img src={Delete} />
                                    </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row className="g-0 mt-2 mb-2 justify-content-between align-items-center set-position">
                        <Col lg={5}>
                          <Row>
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              xl={2}
                              className="text-left"
                            >
                              <Form.Label className="font-weight-normal">
                                หมายเหตุ
                              </Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                onChange={
                                  (e) => {setData({...Data, Note: e.target.value});
                                  }
                                }
                                as="textarea"
                                rows={5}
                                className="text-area"
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className=" justify-content-center set-row-botton-request">
                        <Col lg={9}>
                          <Row className="d-flex justify-content-start">
                            <Col xs={12} lg={4} xl={3} className="mt-2">
                              {/* <Link to="/branch/stock/receive"> */}
                                <Button type='submit' className="button" disabled={isLoading}>
                                  <img src={Save} /> &nbsp; สั่งซื้อ
                                </Button>
                              {/* </Link> */}
                            </Col>
                            {/* <Col xs={6} lg={4} xl={3} className="mt-2">
                              <Button className="button">
                                <img src={Print} /> &nbsp; พิมพ์เอกสาร
                              </Button>
                            </Col> */}
                            {/* <Col xs={6} lg={4} xl={3} className="mt-2">
                              <Button className="button">
                                <img src={CanCel} /> &nbsp; ยกเลิก
                              </Button>
                            </Col> */}
                          </Row>
                        </Col>
                        <Col lg={3}>
                          <Row className="d-flex justify-content-end">
                            <Col className=" mt-2">
                              <Button className="button" onClick={() => closePage()}>
                                <img src={Getout} /> &nbsp; ออก
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
      }
      <Footer />
      <Modal
        className="modal-cutstock"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-cutstock">
          <p>
            <b>รายการพิเศษ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="justify-content-center align-items-center"
            style={{ height: '200px' }}
          >
            <Col xs={8} className="">
              <Form.Control
                placeholder="กรุณาระบุชื่อรายการที่สั่ง"
                className="form-input"
                value={specialName}
                onChange={(data) => {
                  setSpecialName(data.target.value)
                }}
              ></Form.Control>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5 mb-5">
            <Col xs={11} lg={8}>
              <Row className="justify-content-between">
                <Col xs={6}>
                  <Button type="button" className="button" onClick={
                     () => {
                      setProductList({
                        id: null, code: null, name: specialName, unit: {
                          id: null,
                          name_buy: null,
                        }, special_type: 'special',
                      });
                      handleClose()
                     }
                  }>
                    <img src={Save} /> &nbsp; บันทึก
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button type="button" className="button" onClick={handleClose}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
