import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import Select, { components } from 'react-select';
import { Switch } from 'antd';
import Save from 'assets/icon/flat-style-circle-save.png';
import Return from 'assets/icon/flat-style-circle-turn-on.png';
import OIP from 'assets/images/OIP.png';
import { useHistory, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs'
import { approvedCoupon, notificationJob } from 'actions/notification';

export default function Job() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const DateFormat = 'DD/MM/YYYY'

  const optionApproval = [
    { value: 'อนุมัติ', label: 'อนุมัติ' },
    { value: 'ไม่อนุมัติ', label: 'ไม่อนุมัติ' },
  ];

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [FormData, setFormData] = useState({
    job_id: null,
    status: null,
    value: null
  });

  const handleClose = () => setShow(false);
 
  const close = () => {
    setShow(false);
  };

  const [Job, setJob] = useState(null);
  const GetJob = () => {
    dispatch(notificationJob(id)).then(response => {
      setJob(response.data.data.job)
      setFormData({...FormData, job_id: response.data.data.job_id})
    }).catch(e => {

    })
  }

  useEffect(() => {
    GetJob()
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    setValidated(true);

    const validator = document.querySelectorAll('.validate-fail');

    if (validator.length > 0) {
      return false
    }
    
    const data = {
      id,
      data: FormData
    }

    dispatch(approvedCoupon(data)).then(response => {
      history.push('/notification')
    }).catch( e => {

    })
    
  };

  const renderProduct = (detail) => {
    if (detail.package) {
      return detail.package.name
    }
    if (detail.product) {
      return detail.product.name
    }
    if (detail.service) {
      return detail.service.name
    }
  }

  const renderProductCode = (detail) => {
    if (detail.package) {
      return detail.package.code
    }
    if (detail.product) {
      return detail.product.code
    }
    if (detail.service) {
      return detail.service.code
    }
  }
  
  
  const displayPhone = (phone) => {
    if(phone && Array.isArray(phone)) return phone.join(', ')
    if(phone && !Array.isArray(phone)) return JSON.parse(phone).join(', ')
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container fluid className="set-height-order">
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left  mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                เอกสารเปิดงานเสนอราคา
              </Form.Label>
            </Col>
            <Col lg={6}>
              <Row>
                <Col xs={12} className="text-left">
                  <Form.Label className=" font-weight-bold">
                    ข้อมูลเจ้าของรถ
                  </Form.Label>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="text-right mt-3 mb-2">
                      <Form.Label>รหัสสมาชิก : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>{ Job?.customer?.customer_code }</Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="text-right mt-3 mb-2">
                      <Form.Label>ชื่อ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>
                        {
                          Job?.customer?.type === 'นิติบุคคล' &&
                          `${Job?.customer?.organization}`
                        }
                        {
                          Job?.customer?.type === 'บุคคลธรรมดา' &&
                          ` คุณ ${Job?.customer?.first_name} ${Job?.customer?.last_name} (คุณ${Job?.customer?.nickname})`
                        }
                      </Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="text-right mt-3 mb-2">
                      <Form.Label>ที่อยู่ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>
                        { Job?.customer?.address }
                      </Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="text-right mt-3 mb-2">
                      <Form.Label>เบอร์โทรศัพท์ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>{ displayPhone(Job?.customer?.phone) }</Form.Label>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {
                Job?.payer_by_id !== Job?.customer_id &&
                <Row className="justify-content-center">
                  <Col xs={12} className="text-left mt-3 mb-3 ">
                    <Form.Label className="font-weight-bold">
                      ลูกค้าไม่ได้ชำระเอง
                    </Form.Label>
                    &nbsp;
                  </Col>

                  <Col xs={11} className="bg-data-order">
                    <Row className="p-2">
                      <Col xs={12}>
                        <Row>
                          <Col className="text-right mt-3 mb-2">
                            <Form.Label>รหัสสมาชิก : </Form.Label>
                          </Col>

                          <Col
                            className="text-left mt-3 mb-2"
                            style={{ padding: 0 }}
                          >
                            <Form.Label>{ Job?.payer_by?.customer_code }</Form.Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col className="text-right mt-3 mb-2">
                            <Form.Label>ชื่อ : </Form.Label>
                          </Col>

                          <Col
                            className="text-left mt-3 mb-2"
                            style={{ padding: 0 }}
                          >
                            <Form.Label>
                              {
                                Job?.payer_by?.type === 'นิติบุคคล' &&
                                `${Job?.payer_by?.organization}`
                              }
                              {
                                Job?.payer_by?.type === 'บุคคลธรรมดา' &&
                                ` คุณ ${Job?.payer_by?.first_name} ${Job?.payer_by?.last_name} (คุณ${Job?.payer_by?.nickname})`
                              }
                            </Form.Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col className="text-right mt-3 mb-2">
                            <Form.Label>ที่อยู่ : </Form.Label>
                          </Col>

                          <Col
                            className="text-left mt-3 mb-2"
                            style={{ padding: 0 }}
                          >
                            <Form.Label>
                              { Job?.payer_by?.address }
                            </Form.Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col className="text-right mt-3 mb-2">
                            <Form.Label>เบอร์โทรศัพท์ : </Form.Label>
                          </Col>

                          <Col
                            className="text-left mt-3 mb-2"
                            style={{ padding: 0 }}
                          >
                            <Form.Label>{ displayPhone(Job?.payer_by?.phone) }</Form.Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

            </Col>
            <Col lg={6} className="mt-4 mb-3">
              <Row>
                <Col xs={12} className="mt-2 mb-2">
                  <p>จำนวนทั้งสิ้น</p>
                </Col>
                <Col xs={12} className="mt-2 mb-2">
                  <p style={{ fontSize: '36px' }}>{ Job?.total_price } บาท</p>
                </Col>
                <Col xs={12} className="mt-2 mb-2">
                  <p>วันที่ { dayjs(Job?.created_at).format(DateFormat) }</p>
                </Col>
                <Col xs={12} className="mt-2 mb-2">
                  <p>ผู้บันทึก { Job?.create_by?.employee?.fullname }</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                ข้อมูลรถยนต์
              </Form.Label>
            </Col>
            <Col className="">
              <Row className="align-items-center ">
                <Col xs={12} lg={12} className="mt-4 mb-4">
                  <Form.Label>
                    เลขทะเบียนรถ : { Job?.car_owner?.license_plate } รายละเอียด : { Job?.car_owner?.car_serie?.car_model?.car_brand?.name } : Honda, รุ่น
                    { Job?.car_owner?.car_serie?.car_model?.model + ' ' +Job?.car_owner?.car_serie?.code }, สี : { Job?.car_owner?.color }, ป้าย : { Job?.car_owner?.plate_type }, จังหวัด : { Job?.car_owner?.province?.name_th }
                    เลขตัวถัง : { Job?.car_owner?.chassis_number }
                  </Form.Label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                รายละเอียดสินค้า
              </Form.Label>
            </Col>
            <Col xs={12}>
              <Row className="mt-4 mb-4 justify-content-center m-0">
                <Col className="p-4 set-order-table">
                  <table className="table table-responsive table-wash-part ">
                    <thead>
                      <tr className="t-header-package">
                        <th>ลำดับ</th>
                        <th>รหัสสินค้า</th>
                        <th className="text-left">ชื่อสินค้า</th>
                        <th>จำนวน</th>
                        <th>ราคาต่อหน่วย</th>
                        <th>ส่วนลด (บาท) </th>
                        <th>ราคารวม</th>
                      </tr>
                    </thead>
                    <tbody className="t-body-package ">
                      {
                        Job?.job_details.map((detail, key) => {
                          return(
                            <tr>
                              <td>{ key+1 }</td>
                              <td>{ renderProductCode(detail) }</td>
                              <td>{ renderProduct(detail) }</td>
                              <td>{detail.quantity}</td>
                              <td>{detail.price}</td>
                              <td>{detail.discount}</td>
                              <td>{detail.grand_total}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                รูปภาพประกอบการซ่อม
              </Form.Label>
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <Row>
                <Col xs="auto" className="size-image mt-3 mb-3">
                  {/* <img src={OIP} /> */}
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={6}>
                  <Col>
                    <Row className="mt-3 mb-3">
                      <Col xs={12} lg={3} className="text-left mt-3">
                        <Form.Label>หมายเหตุ</Form.Label>
                      </Col>
                      <Col className="mt-3 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="text-area"
                          value={Job?.note}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      <Col xs={12} lg={3} className="text-left mt-3">
                        <Form.Label>โน้ตภายใน</Form.Label>
                      </Col>
                      <Col className="mt-3 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="text-area"
                          value={Job?.insite_note}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                </Col>
                <Col lg={6} className="">
                  <Row className="bg-data-sum pt-2 pb-2 p-3">
                    <Col xs={12} className="">
                      <Row className="justify-content-between">
                        <Col className="text-left  mt-3 mb-3">
                          <p>รวมเป็นเงิน</p>
                        </Col>
                        <Col className="text-right  mt-3 mb-3 ">
                          <p>{ Job?.total } บาท</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="text-left  mt-3 mb-3">
                          <p>ส่วนลดรวม</p>
                        </Col>
                        <Col className="text-right  mt-3 mb-3 ">
                          <p>{ Job?.discount_type === 'baht' ? Job?.discount_calculate : Job?.discount_calculate+' '+(Job?.discount)+'%' }</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="text-left  mt-3 mb-3">
                          <p>ราคาหลังหักส่วนลด</p>
                        </Col>
                        <Col className="text-right  mt-3 mb-3">
                          <p>{ Job?.total - Job?.discount_calculate }</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between align-items-center">
                        <Col className="text-left  mt-3 mb-3">
                          <p>หักภาษี ณ ที่จ่าย { `${Job?.tax ? `(${Job?.tax}%)` : '' }` }</p>
                        </Col>
                        <Col xs={3} className="text-right  mt-3 mb-3">
                          <p>{ Job?.tax_calculate}</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-between">
                        <Col className="text-left  mt-3 mb-3">
                          <p>ราคาสุทธิ</p>
                        </Col>
                        <Col className="text-right mt-3 mb-3 ">
                          <p>{ Job?.total_price }</p>
                        </Col>
                      </Row>
                      <Row className="justify-content-center align-items-center">
                        <Col xs={4} lg={3} className="text-right">
                          <Form.Label className="font-weight-bold ">
                            การอนุมัติ
                          </Form.Label>
                        </Col>
                        <Col xs={8} md={4} lg={5} xl={5}>
                          <Select
                            options={optionApproval}
                            placeholder="กรุณาเลือก"
                            isClearable={false}
                            isSearchable={false}
                            onChange={(data) => setFormData({ ...FormData, status: data.value })}
                            className={'select-list '+(!FormData.status && validated ? 'validate-fail' : '') }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 pt-5 pb-5 bg-white justify-content-center">
            <Col xs={6}>
              <Row className="justify-content-end">
                <Col xs={12} md={6} xl={5} className="mt-2 mb-2">
                  <Button type="submit" className="button">
                    <img src={Save} /> &nbsp; บันทึก
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-start">
                <Col xs={12} md={6} lg={6} xl={5} className="mt-2 mb-2">
                  <Button type="button" className="button" onClick={() => history.push('/notification')}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal className="modal-style" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-style">
          <p>
            <b>รับรถ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row
              className="justify-content-center "
              style={{ height: '300px' }}
            >
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-5">
                  <Col xs={3} lg={3} className="text-left">
                    <Form.Label className="set-text-height">วันที่</Form.Label>
                  </Col>
                  <Col className="p-0 pr-3"></Col>
                </Row>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center ">
                  <Col xs={2} className="text-left">
                    <Form.Label className="set-text-height">เวลา</Form.Label>
                  </Col>
                  <Col></Col>:<Col></Col>
                </Row>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center">
                  <Col lg={5}>
                    <Button type="button" className="button">
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
