import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Pagination, Form } from 'react-bootstrap';
import StockImage from 'assets/icon/appwizard_list.png'
import Footer from 'Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import Select, { components } from 'react-select';
import Back from 'assets/icon/back.png';

import Delete from 'assets/icon/delete.png';
import Increase from 'assets/icon/add-staff.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/new-re-turn.png';
import Calendar from 'assets/icon/calendar-bg.png';
import { DatePicker } from 'antd';

import { Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

//API
import {ProductList} from 'actions/product/index';
import {UnitList,UnitSale} from 'actions/settings/unit'
// import { UserList } from 'actions/user/index'
import { List} from 'actions/employee'
import { saveStorage, LoadNewStockCode } from 'actions/stock'
import { loadAuth } from 'actions/auth';

export default function Adjust() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [statepage, setStatepage] = useState('');
  const { employee } = useSelector( (state) => state.user)
  const [Products, setProducts] = useState('');
  const handleSelectPage = (e) => setStatepage(e);
  const [take, setTake] = useState(true);
  const [Loading,setisLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Report, setReport] = useState(false);

  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const dateFormat = 'YYYY/MM/DD';
  const StorageModel = {
    key: 0,
    ProductId: null,
    TotalQuantity: 0,
    UnitName: null,
    Admit: true,
    TakeOut: false,
    Quantity: null,
    UnitType: null,
    UnitId: null,
    UnitSaleId: null,
    CountBy: null,
    Note: null,
    StockDate: null,
    reasonType: null,
    Type:'adjust',
    StockCode: null,
    report: false,
  }
  const [Data,SetData] = useState({
    StorageList:[
      StorageModel
    ],
  });
  const [employees,setEmployees] = useState([]);
  const [StockCode,setStockCode] = useState([]);

  const [causeAdjust, setCauseAdjust] = useState([
    { value: 'lost_product', label: 'สินค้าหาย' },
    { value: 'excess_product', label: 'สินค้าเกิน' },
    { value: 'other', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const InsertStorage = (key) => {
    // console.log(key);
    
    let insert = StorageModel
    // insert.key = Data.StorageList.length
    insert.key = Number(key) + 1  
    insert.Admit = true
    console.log('insert', insert)
    SetData({ ...Data, StorageList:[...Data.StorageList, insert]})
  }



  const RemoveStorage = (key) => {
    if(Data.StorageList.length > 1){
      const list = Data.StorageList
      const index = list.filter((item) => item.key !== key)
      const result = index
      SetData({ ...Data, StorageList: result })
    }else{
      alert('ไม่สามารถลบได้ ต้องมีมากกว่า 1 รายการ')
    }
    
  }

  const GetProduct = async () => {
    setisLoading(true)
    try {
      const params = {

      }
      const response = await dispatch(ProductList(params));
      setProducts(response.data.data);
      setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['ปรับ/ตัด(stock)'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/stock');
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getNewStockCode(){
    setisLoading(true)
      try {
        const params = {
          type: 'adjust',
        }
        const response = await dispatch(LoadNewStockCode(params));
        setStockCode(response.data.data);
        setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  async function saveStorageList(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    // console.log('Data.StorageList',Data.StorageList);
    let formData = new FormData();
    if(Data.StorageList.length > 0){
      for (let i = 0; i < Data.StorageList.length; i++) {
        formData.append(`stock_list[${i}]`, JSON.stringify(Data.StorageList[i]));
      }
    }

    try {
      const response = await dispatch(saveStorage(formData));
      
      if(response.data.message === 'success'){
        history.push(`/stock`);
      }else if(response.data.message === 'errors'){
        Data.StorageList.map((item) => {
          item.report = false
        })

        const report = response.data.data
        report.map((item) => {
          let find = Data.StorageList.find((f) => {
            return f.ProductId === item.id
          })
          find.report = true
          console.log('find',find);
          setReport(true)
        });
      }else if(response.data.message === 'errors_empty'){
        // console.log('errors_empty')
        Data.StorageList.map((item) => {
          item.report = false
        })
        setReport(false)
      }else{
        console.log('failed')
        setReport(false)
      }
    } catch (error) {
      console.error(error);
      setReport(false)
    } finally {
      console.log('finally')
    }


  }

  // const GetUnit = async () => {
  //   setisLoading(true)
  //   try {
  //     const params = {

  //     }
  //     const response = await dispatch(UnitList(params));
  //     setUnit(response.data.data);
  //     setisLoading(false)
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     console.log('finally')
  //   }
  // }
  const [unitSales, setUnitSales] = useState([]);
  async function getUnitSale(unit_id){
    setisLoading(true)
    try {
      const params = {
        unit_id: unit_id,
      }
        const response = await dispatch(UnitSale(params));
        setUnitSales(response.data.data)
        setisLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }

  }
  const GetEmployees = async () => {
    setisLoading(true);
    try {
      const params = {
        branch_id : profile.branch_id
      }
      const response = await dispatch(List(params));
      setEmployees(response.data.data);
      setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }


  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function closePage() {
    let path = `/stock`;
    history.push(path);
  }
  const initialValues = {
    mainField: '',
    operation: '',
    value: [],
  };

  const optionProducts = [
    { value: '', label: '' },
    { value: '', label: '' },
  ];
  const optionCountBy = [
    { value: '', label: '' },
    { value: '', label: '' },
  ];
  const optionUnit = [
    { value: 'buy', label: 'หน่วยซื้อ' },
    { value: 'sale', label: 'หน่วยขาย' },
  ];

  useEffect(() => {
    console.log('report');
  },[Report]);

  useEffect(() => {
    GetProduct();
    // GetUnit();
    GetEmployees();
    getAuth()
    getNewStockCode();
  }, []);

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }
  
  return (
    <>
      <Container
        fluid
        className="package-container Service Stock Staff CutStock bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ปรับสต็อก</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; ปรับสต็อก</b>
                </h5>
              </div>
              <div className="modal-body">
                <Form noValidate validated={validated} onSubmit={saveStorageList}>
                  <Row className="g-0 tabsicon justify-content-center ">
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                  </Row>
                  <Row className="g-0  justify-content-center  align-items-center   set-position">
                    <Col xs={12} lg={12}>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <Form.Label>เอกสารหมายเลข</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {StockCode}</p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <Form.Label>วันที่ปรับ</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {dayjs().format('DD-MM-YYYY')}</p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-2">
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <Form.Label>ผู้บันทึก</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                          <p>: {profile.fullname}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {
                    Data.StorageList?.map(  (item, key) => {
                      return (
                        <div>
                          <Row className="g-0  mt-2 mb-5">
                            <Col lg={11}>
                              <Row className="g-0">
                                <Col lg={9}>
                                  <Row>
                                    <Col
                                      xs={3}
                                      sm={3}
                                      lg={2}
                                      xl={2}
                                      className="d-flex align-items-center justify-content-start set-Label text-left"
                                    >
                                      <Form.Label>ชื่อสินค้า</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={5}>
                                      <Select
                                        options={Products}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isDisabled={Loading}
                                        placeholder={Loading ? 'กำลังโหลดข้อมูล' : 'ชื่อสินค้า'}
                                        isClearable={false}
                                        isSearchable={true}
                                        value={item.ProductId ? Products.find((data) => {return data.id === item.ProductId}) : null}
                                        onChange={
                                          (data) => {SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, ProductId: data.id,
                                                TotalQuantity: data.total_quantity, UnitName : data.unit && data.unit.name_buy ? data.unit.name_buy : null,
                                                UnitId: data.unit_id,
                                                UnitSaleId: null,
                                            }: el
                                            )
                                          })); getUnitSale(data.unit_id)}
                                        }
                                        className="select-style select-list"
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      lg={4}
                                      className="d-flex align-items-center font-weight-bold"
                                    >
                                      <p>ยอดปัจจุบัน : {item.TotalQuantity ? item.TotalQuantity.toFixed(2) : 0}  {item.UnitName} </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9} className="mt-3 mb-3">
                                   
                                  {['radio'].map((type) => (
                                    <Row
                                      key={`inline-${type}`}
                                      className="align-items-center"
                                    >
                                      <Col xs={3} lg={2} xl={2} className="d-flex">
                                        <Form.Check
                                          inline
                                          label="รับเข้า"
                                          name={`inline-${type}-${item.key}`}
                                          type={type}
                                          id={`inline-${type}-1-${item.key}`}
                                          checked={item.Admit}
                                          onChange={
                                            (e) => SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, Admit: e.target.checked,TakeOut: false, Type: 'adjust' }: el
                                              )
                                            }))
                                          }
                                        />
                                      </Col>
                                      <Col xs={3} lg={2} xl={2} className="d-flex">
                                        <Form.Check
                                          inline
                                          label="นำออก"
                                          name={`inline-${type}-${item.key}`}
                                          type={type}
                                          id={`inline-${type}-1-${item.key}`}
                                          checked={item.TakeOut}
                                          onChange={
                                            (e) => SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, TakeOut: e.target.checked, Admit: false, Type: 'adjust' }: el
                                              )
                                            }))
                                          }
                                        />
                                      </Col>

                                      <Col
                                        xs={2}
                                        lg={1}
                                        className="d-flex align-items-center justify-content-end set-Label"
                                      >
                                        <Form.Label>จำนวน</Form.Label>
                                      </Col>
                                      <Col xs={4} lg={3}>
                                        <Form.Control
                                          type="text"
                                          required
                                          placeholder="จำนวน"
                                          className="input-in-table"
                                          value={commaCase(item.Quantity)}
                                          onFocus={
                                            (e) =>
                                            SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : null }: el
                                              )
                                            }))
                                          }
                                          onBlur={
                                            (e) =>
                                            SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, Quantity: parseFloat(e.target.value.replaceAll(',', '')) > 0 ? e.target.value.replaceAll(',', '') : 0 }: el
                                              )
                                            }))
                                          }
                                          onChange={
                                            (e) => {
                                              SetData(prevState => ({...Data,
                                                StorageList: prevState.StorageList.map(
                                                  el => el.key === item.key ? { ...el, Quantity: e.target.value.replaceAll(',', '') }: el
                                                )
                                              })
                                            )}
                                          }
                                          onKeyPress={(event) => {
                                            if (!event.key.match(/^[0-9]*\.?[0-9]*$/)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        ></Form.Control>
                                        {
                                          item.report === true ? <p style={{color: 'red'}}>จำนวนคงเหลือไม่พอ</p> : ''
                                        }
                                      </Col>
                                      <Col xs={3} lg={1} className="set-Label">
                                        <Form.Label>หน่วย</Form.Label>
                                      </Col>
                                      <Col xs={9} lg={3}>
                                        <Select
                                          options={unitSales}
                                          getOptionLabel={(option) => option.name_sale}
                                          getOptionValue={(option) => option.id}
                                          placeholder="หน่วย"
                                          isClearable={false}
                                          isSearchable={false}
                                          value={item.UnitSaleId ? unitSales.find((data) => {return data.id === item.UnitSaleId}) : null}
                                          onChange={
                                            (e) => SetData(prevState => ({...Data,
                                              StorageList: prevState.StorageList.map(
                                                el => el.key === item.key ? { ...el, UnitSaleId: e.id }: el
                                              )
                                            }))
                                          }
                                          className="select-style select-list"
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9}>
                                  <Row className="align-items-center">
                                    <Col
                                      xs={4}
                                      lg={3}
                                      xl={2}
                                      className="d-flex justify-content-start set-Label text-left"
                                    >
                                      <Form.Label>วันที่นับสต็อก</Form.Label>
                                    </Col>
                                    <Col xs={8} lg={4}>
                                      <DatePicker
                                        // onChange={onChange}
                                        format={dateFormat}
                                        value={item.StockDate}
                                        onChange={
                                          (e) => SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, StockDate: e }: el
                                            )
                                          }))
                                        }
                                        placeholder="วันที่นับสต็อก"
                                        suffixIcon={<img src={Calendar} />}
                                      />
                                    </Col>
                                    <Col xs={3} lg={1} className="set-Label">
                                      <Form.Label>นับโดย</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={4}>
                                      <Select
                                        options={employees}
                                        getOptionLabel={(option) => option.fullname}
                                        getOptionValue={(option) => option.id}
                                        placeholder="นับโดย"
                                        isClearable={false}
                                        isSearchable={false}
                                        // onChange={(data) => setCountBy(data)}
                                        onChange={
                                          (e) => SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, CountBy: e.id }: el
                                            )
                                          }))
                                        }
                                        className="select-style select-list"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mt-2 mb-2">
                                <Col lg={9}>
                                  <Row className="align-items-center">
                                  <Col
                                    xs={6}
                                    lg={3}
                                    xl={2}
                                    className="d-flex align-items-center text-left"
                                  >
                                    <Form.Label>เหตุผล</Form.Label>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <Select
                                      className="text-left select-style select-list"
                                      aria-label="Default select example"
                                      placeholder="เหตุผล"
                                      options={causeAdjust}
                                      getOptionLabel={(option) => option.label}
                                      getOptionValue={(option) => option.value}
                                      onChange={
                                        (e) => SetData({...Data, reasonType: e.value })
                                      }
                                    />
                                  </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mt-3 mb-2">
                                <Col lg={9}>
                                  <Row>
                                    <Col
                                      xs={3}
                                      lg={3}
                                      xl={2}
                                      className="d-flex justify-content-start set-Label"
                                    >
                                      <Form.Label>หมายเหตุ</Form.Label>
                                    </Col>
                                    <Col xs={9} lg={5}>
                                      <Form.Control
                                        as="textarea"
                                        placeholder="หมายเหตุ"
                                        rows={3}
                                        className="text-area"
                                        value={item.Note}
                                        onChange={
                                          (e) => SetData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Note: e.target.value }: el
                                            )
                                          }))
                                        }
                                      ></Form.Control>
                                    </Col>
                                    <Col lg={4} xl={5} className="pt-3">
                                      <Row className="g-0 align-items-center justify-content-center">
                                        <Col className="col-12 col-md-6 pt-2 pb-2">
                                          <Button
                                            type="button"
                                            className="button-delete-menu-stock"
                                            onClick={() => RemoveStorage(item.key)}
                                          >
                                            <img src={Delete} /> &nbsp; ลบรายการ
                                          </Button>
                                        </Col>
                                        <Col className="col-12 col-md-6 pt-2 pb-2">
                                          {
                                            (key+1) === Data.StorageList.length &&
                                            <Row className="align-items-center">
                                            <Col>
                                              <Button
                                                type="button"
                                                className="button-delete-menu-stock"
                                                onClick={() => {InsertStorage(item.key)}}
                                              >
                                                <img src={Increase} />{' '}
                                                &nbsp; เพิ่มรายการ
                                              </Button>
                                            </Col>
                                          </Row>
                                          }
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="g-0 mb-5"
                            style={{ border: '1px solid #7A7A7A' }}
                          ></Row>
                        </div>
                      );
                    })
                  }

                  
                  <Row className="justify-content-between mt-3 mb-3">
                    <Col lg={2}>
                      <Button
                        type="submit"
                        className="button-delete-menu-stock"
                      >
                        <img src={Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>
                    <Col lg={2}>
                      <Button
                        type="button"
                        className="button-delete-menu-stock"
                        onClick={closePage}
                      >
                        <img src={Return} /> &nbsp; ออก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
