import axios from 'axios';

export const LoadProperties = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/properties`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadProperty = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/properties/${id}`,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateProperty = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/properties`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateProperty = ({id,formData}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/properties/${id}`,
    formData,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};


export const LoadPropertyStorageByPropertyId = (params) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/information-by-property`,
    {params},
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadPropertyStorageById = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/property-informations/${id}`,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const saveStorage = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/property-informations`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const updateStorage = ({information_id,formData}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/property-informations/${information_id}`,
    formData,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const destroyStorage = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/proprty-storages/${id}`,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const destroyProperty = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/properties/${id}`,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}



