import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Dropdown,
  Form,
  Table,
  Alert
} from 'react-bootstrap';
import Footer from 'Components/Footer';

import { useHistory,Link } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import Back from 'assets/icon/back.png';
import Service from 'assets/icon/management.png';

import Delete from 'assets/icon/deletes.png';
import Increase from 'assets/icon/add-staff.png';
import { Switch } from 'antd';
import 'antd/dist/antd.css';

import Save from 'assets/icon/new-save.png';
import Turnoff from 'assets/icon/new-re-turn.png';
import Select, { components } from 'react-select';

//api 
import {LoadProductCategories} from 'actions/product_category'
import { CreateService } from 'actions/service'
import { loadAuth } from 'actions/auth';
import { ProductList } from 'actions/product';

export default function AddService() {

  const history = useHistory();
  const dispatch = useDispatch();
  const productRef = useRef(null)
  const productRefScroll = () => productRef.current.scrollIntoView() 
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [statepage, setStatepage] = useState('');
  const handleSelectPage = (e) => setStatepage(e);

  const [showroom, setShowRoom] = useState('');
  const [pass_package, setPass_package] = useState('');
  const [time_service, setTime_service] = useState('');
  const [calendar, setCalendar] = useState('');
  const [number_Service, setNumber_Service] = useState('');
  const [upload_image, setUpload_image] = useState('');
  const [category, setCategory] = useState('');
  // ขนาดรถและราคา
  const [car_size_S, setCar_Size_S] = useState('');
  const [car_size_M, setCar_Size_M] = useState('');
  const [car_size_L, setCar_Size_L] = useState('');
  const [car_truck_cab, setCar_Truck_Cab] = useState('');
  const [car_suv, setCar_Suv] = useState('');
  const [car_truck4Door, setCar_Truck4Door] = useState('');
  const [car_size_XL,setCar_Size_XL] = useState('');
  const [car_van, setCar_Van] = useState('');

  const [time_car_size_S, setTime_car_size_S] = useState('');
  const [time_car_size_M, setTime_car_size_M] = useState('');
  const [time_car_size_L, setTime_car_size_L] = useState('');
  const [time_car_truck_cab, setTime_Car_Truck_Cab] = useState('');
  const [time_car_suv, setTime_car_suv] = useState('');
  const [time_car_truck4Door, setTime_car_truck4Door] = useState('');
  const [time_car_size_XL, setTime_car_size_XL] = useState('');
  const [time_car_van, setTime_car_van] = useState('');
  const [package_Name, setPackage_Name] = useState('');
  const [package_Note, setPackage_Note] = useState('');
  // วัสดุ/อุปกรณ์อ้างอิง
  const [size_S, setSize_S] = useState('');
  const [size_M, setSize_M] = useState('');
  const [size_L, setSize_L] = useState('');
  const [truck, setTruck] = useState('');
  const [suv, setSuv] = useState('');
  const [truck4Door, setTruck4Door] = useState('');
  const [size_XL, setSize_XL] = useState('');
  const [van, setVan] = useState('');
  const [statusService, setStatusService] = useState(false);
  const [check_product, setCheck_product] = useState(true);
  const [check_service, setCheck_Service] = useState(false);
  const [check_package, setCheck_Package] = useState(false);
  const [number_Coating, setNumber_Coating] = useState(0);
  const [number_Package_Wash, setNumber_Package_Wash] = useState('');
  const [ValidatedProduct, setValidatedProduct] = useState(false);
  const [price_all, setPrice_all] = useState('');
  const [runtime, set_Runtime] = useState('');
  const [statusclose_price_all, setStatusclose_price_all] = useState({
    disabled: true,
  });
  const [switchFrom, setSwitchFrom] = useState(false);
  const statusServiceToggler = () => {
    statusService ? setStatusService(false) : setStatusService(true);
  };
  const statusSwitchFrom = () => {
    switchFrom ? setSwitchFrom(false) : setSwitchFrom(true);
  };

  const [Products, setProducts] = useState([]);
  const [UnitSales, setUnitSales] = useState([])
  const [ProductEditMode, setProductEditMode] = useState(false);

  const ProductSizesModel = [
    {
      key: 0,
      size: 'S',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 1,
      size: 'M',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 2,
      size: 'L',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 3,
      size: 'TRUCK CAB',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 4,
      size: 'SUV',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 5,
      size: 'TRUCK 4 DOOR',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 6,
      size: 'XL',
      quantity: null,
      product_id: null,
      product: null
    },
    {
      key: 7,
      size: 'VAN',
      quantity: null,
      product_id: null,
      product: null
    }
  ]

  const [ProductSizes, setProductSizes] = useState(ProductSizesModel);
  

  const GetProduct = async (data) => {
    try {
      const response = await dispatch(ProductList({ has_sales: true }));
      if (response) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  useEffect(() => {
    GetProduct();
    GetServiceOptions();
    getAuth();
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [Rows, setRows] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);
  const [Options, setServicesOptions] = useState([]);

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  const handleSelectShowRoom = (e) => setShowRoom(e);
  const handleSelectCategory = (e) => setCategory(e);
  function closePackage() {
    let path = `/`;
    history.push(path);
  }
  function SetService(data) {
    setService({ value: data.value, label: data.value });
  }
  async function GetServiceOptions(){
    setLoading(true)
    try {
     
      const response = await dispatch(LoadProductCategories());
      const options = []
      // const new_arr = 
      response.data.data.map(item => {
        const data = {
          value: item.id,
          label: item.name
        }
        options.push(data);
      })
      setServicesOptions(options);
      setLoading(false);
    }  catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['จัดการบริการ'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      history.push('/');
    }
  };
  // const [ errors, setErrors ] = useState({
  //   package_Name: null,
  //   service: null,
  //   price_all: null,
  //   runtime:null,
  // })
  const [validated, setValidated] = useState(false);
  const [Errors, setErrors] = useState([])
//   const findFormErrors = () => {
//     const newErrors = {}
//     // name errors
//     if ( !package_Name || package_Name === '' ) newErrors.package_Name = 'ชื่อบริการ'
//     if ( !service.value || service.value === '' ) newErrors.service = 'ประเภทหมวดหมู่'
//     const type =  switchFrom === true ? 'size' : 'all';
//     if (type === 'all' && price_all === '') newErrors.price_all = 'จำนวน'
//     if (type === 'all' && runtime === '') newErrors.runtime = 'จำนวนเวลา'

//     if (type === 'size' && car_size_S === '' || car_size_M === '') newErrors.car_size = 'โปรดใส่ข่องได้ ช่องหนึ่ง'

//     return newErrors
// }

  function addService(e){
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setValidated(true);
    // const newErrors = findFormErrors()
    // if ( Object.keys(newErrors).length > 0 ) {
    //   // We got errors!
    //   setErrors(newErrors)
      
    //   console.log(errors);
    // }else{

      const data = {
        package_Name : package_Name,
        package_Note : package_Note,
        service: service.value,
        active_type: switchFrom === true ? 'size' : 'all',
        active: statusService,
        price_all: price_all,
        runtime: runtime,
        car_size_S: {
          size: 'S',
          amount: car_size_S,
          time: time_car_size_S,
        },
        car_size_M:{
          size: 'M',
          amount: car_size_M,
          time: time_car_size_M
        },
        car_size_L:{
          size: 'L',
          amount: car_size_L,
          time: time_car_size_L
        },
        car_size_XL:{
          size: 'XL',
          amount: car_size_XL,
          time: time_car_size_XL
        },
        car_van:{
          size: 'VAN',
          amount: car_van,
          time: time_car_van
        },
        car_truck_cab:{
          size: 'TRUCK CAB',
          amount: car_truck_cab,
          time: time_car_truck_cab
        },
        car_truck4Door:{
          size: 'TRUCK 4 DOOR',
          amount: car_truck4Door,
          time: time_car_truck4Door
        },
        car_suv:{
          size: 'SUV',
          amount: car_suv,
          time: time_car_suv
        }
      }
      //  console.log(data)
      
      let formData = new FormData();
      formData.append('name', data.package_Name);
      formData.append('note', data.package_Note);
      formData.append('product_category_id', data.service);
      if(data.active_type === 'all')formData.append('all_price', data.price_all);
      if(data.active_type === 'all')formData.append('all_processing_time', data.runtime);
      formData.append('active', data.active);
      formData.append('sale_type', data.active_type )
      formData.append('active_sale', switchFrom )
  
      const detail = [];
      if(data.active_type === 'size' && data.car_size_S.amount && data.car_size_S.time) detail.push(data.car_size_S)
      if(data.active_type === 'size' && data.car_size_M.amount && data.car_size_M.time) detail.push(data.car_size_M)
      if(data.active_type === 'size' && data.car_size_L.amount && data.car_size_L.time) detail.push(data.car_size_L)
      if(data.active_type === 'size' && data.car_size_XL.amount && data.car_size_XL.time) detail.push(data.car_size_XL)
      if(data.active_type === 'size' && data.car_van.amount && data.car_van.time) detail.push(data.car_van)
      if(data.active_type === 'size' && data.car_truck_cab.amount && data.car_truck_cab.time) detail.push(data.car_truck_cab)
      if(data.active_type === 'size' && data.car_truck4Door.amount && data.car_truck4Door.time) detail.push(data.car_truck4Door)
      if(data.active_type === 'size' && data.car_suv.amount && data.car_suv.time) detail.push(data.car_suv)


      if(data.active_type === 'size' && detail.length > 0){
        for (let i = 0; i < detail.length; i++) {
          formData.append(`sale_type_detail[${i}]`, JSON.stringify(detail[i]));
        }
      }

      if(ProductData && ProductData.length > 0){
        console.log('ProductData', ProductData)
        ProductData.forEach((product, key) => {
          formData.append(`products[${key}][size]`, product.size);
          if(product.quantity) formData.append(`products[${key}][quantity]`, product.quantity);
          formData.append(`products[${key}][product_id]`, product.product_id);
          formData.append(`products[${key}][unit_sale_id]`, product.unit_sale_id);
          key++;
        });
      }
  
      dispatch(CreateService(formData)).then(response => {
        setErrors([])
        history.push('/service');
      }).catch(e => {
        let errors = []
        if (e.response && e.response.data && e.response.data.messages) {
          e.response.data.messages.forEach(element => {
            errors.push(element)
          });
          setErrors(errors)
        }
      })
    // }
    
  }

  const [ProductId, setProductId] = useState(null);
  const [UnitSaleId, setUnitSaleId] = useState(null)
  const [ProductTable, setProductTable] = useState([])
  const [ProductData, setProductData] = useState([])
  
  const InsertProductSize = async (e) => {
    
    let valid = false
    if (!ProductId) valid = true
    if (!UnitSaleId) valid = true
    ProductSizes.forEach(product => {
      if (product.quantity === null) {
        valid = true
      }
    });
    if(valid) productRefScroll()
    setValidatedProduct(valid);
    if (!valid) {
      let obj = {}

      ProductSizes.forEach((product, key) => {
        obj[product.size] = Number(product.quantity) || null
        product.product_id = ProductId
        product.unit_sale_id = UnitSaleId
      });

      obj.product = Products.find((item) => item.id === ProductId)
      obj.product_id = ProductId
      obj.unit_sale_id = UnitSaleId

      setProductTable([...ProductTable, obj])
      setProductData([...ProductData, ...ProductSizes])
      setProductSizes(ProductSizesModel)
      setValidatedProduct(false);
      setProducts(Products.filter(item => item.id !== ProductId))
      setProductId(null)
      setUnitSaleId(null)
    }
  }

  const EditProduct = (product) => {
    const data = []

    let temp = {}
    ProductSizes.forEach(item => {
      let obj = {}
      if (product.ref) {
        let data = product.ref.find(value => value.size === item.size)
        obj.id = data.id
        obj.service_id = data.service_id
      }
      
      obj.size = item.size
      obj.quantity = product[item.size]
      obj.product_id = product.product_id
      obj.unit_sale_id = product.unit_sale_id
      temp = {
        product_id: product.product_id,
        unit_sale_id: product.unit_sale_id
      }
      obj.product = product.product
      obj.key = data.length
      obj.table_key = product.key
      data.push(obj)
    });

    const sales = product.product.unit.sales
    setUnitSales(sales)
    setUnitSaleId(temp.unit_sale_id)
    setValidatedProduct(false)
    setProductId(product.product_id)
    setProductSizes(data)
    setProductEditMode(true)
  }

  const UpdateProductState = () => {
    let valid = false
    ProductSizes.forEach(product => {
      if (product.quantity) {
        valid = true
      }
    });

    if (valid) {
      let obj = {}
      let edited = []

      ProductSizes.forEach(product => {
        obj[product.size] = Number(product.quantity) || null
        obj.product = product.product
        obj.product_id = product.product_id
        obj.sale_unit_id = product.sale_unit_id
        edited.push(product)
      });

      // delete all old data by product_id
      let filter = ProductData.filter((item) => item.product_id !== obj.product_id);
      let result = filter.concat(edited)

      console.log('result', filter, result)

      obj.product = obj.product
      obj.product_id = ProductId

      setProductTable([...ProductTable.map(
        el => el.product_id === obj.product_id ? obj : el
      )])

      setProductData(result)
      setProductSizes(ProductSizesModel)
      setProductId(null);
      setUnitSaleId(null);
      setUnitSales(null);
      setProductEditMode(false)
    }
    
  }

  const DeleteProduct = (product, key) => {
    setProductTable([...ProductTable.filter((item, index) => index !== key)])
    setProductData([...ProductData.filter((item, index) => item.product_id !== product.product_id)])
    setProducts([...Products, product.product])
  }

  function exit() {
    history.push(`/service`);
  }

  function addCommas(x) {
    var nStr = x + '';  
    nStr = nStr.replace(/\,/g, "");
    x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';  
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2
  }

  function commaCase(value){
    if (value) {
      return addCommas(value)
    }else{
      if (value === null) {
        return ''
      }else{
        if (value === '') return null
        return 0
      }
    }
  }
  return (
    <>
      
      <Container fluid className="package-container Stock Service bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>
                    <Link to={`/service`} className='mr-2'>จัดการบริการ</Link>
                    &gt; 
                    เพิ่มบริการ
                  </b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b>จัดการบริการ &gt; เพิ่มบริการ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                {
                  Errors.length > 0 &&
                  <Alert variant="danger">
                    <Alert.Heading>เกิดข้อผิดพลาด !</Alert.Heading>
                    <div className='text-left'>
                    {
                      Errors.map((error, key) => {
                        return(
                          <p>{error}</p>
                        )
                      })
                    }
                    </div>
                  </Alert>
                }
                <Form noValidate validated={validated} onSubmit={addService}>
                  <p className="form-title">รายละเอียดบริการ</p>
                  <Form.Group
                    className="package-data"
                    controlId="formBasicEmail"
                  >
                    <Row className="g-0">
                      <Col
                        xs={2}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        className="text-title"
                      >
                        <p>ชื่อบริการ</p>
                      </Col>
                      <Col xs={10} md={6} lg={5} xl={4}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="ชื่อบริการ"
                          value={package_Name}
                          onChange={(e) => setPackage_Name(e.target.value)}
                          className="input-middle-price"
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="g-0 mt-2 mb-2">
                      <Col
                        xs={2}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        className="text-title"
                      >
                        <p>หมวดหมู่</p>
                      </Col>
                      <Col xs={10} md={6} lg={5} xl={4}>
                        <Select
                            required
                            options={Options}
                            defaultValue={Options[0]}
                            placeholder="เลือกหมวดหหมู่"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data) => SetService(data)}
                            className="select-list"
                          />
                      </Col>
                    </Row>
                    <Row className="g-0 mt-3">
                      <Col
                        xs={2}
                        sm={3}
                        md={3}
                        lg={2}
                        xl={2}
                        className="text-title"
                      >
                        <p>หมายเหตุ</p>
                      </Col>
                      <Col xs={10} md={6} lg={5} xl={4}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="หมายเหตุ"
                          value={package_Note}
                          onChange={(e) => setPackage_Note(e.target.value)}
                          className="text-area"
                        ></Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                  

                  <Row>
                    <Col xs={12} lg={2} className="colum-open-service">
                      <Switch onClick={statusSwitchFrom} />
                    </Col>
                  </Row>
                  {/* ///// */}

                  <Row className={(switchFrom === true ? 'bg-opacity':'')}>
                    <Col lg={6}>
                      <Row>
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={4}
                          xl={4}
                          className="text-title"
                        >
                          <p>ราคาเหมา</p>
                        </Col>
                        <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                          <Form.Control
                            type="text"
                            required
                            placeholder="ระบุราคา"
                            className="input-search-add-wash-part"
                            value={commaCase(price_all)}
                            onChange={(e) => setPrice_all(e.target.value.replaceAll(',', ''))}

                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              switchFrom === true
                            }
                          ></Form.Control>
                        </Col>
                        <Col xs={1} sm={2} className="text-bath">
                          <p>บาท</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className="text-title"
                        >
                          <p>เวลาดำเนินการ</p>
                        </Col>
                        <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                          <Form.Control
                            type="text"
                            required
                            placeholder="ระบุเวลา"
                            className="input-search-add-wash-part"
                            value={commaCase(runtime) }
                            onWheel={(e) => e.target.blur() }
                            onChange={(e) => set_Runtime(e.target.value.replaceAll(',', ''))}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              switchFrom === true
                            }
                          ></Form.Control>
                        </Col>
                        <Col xs={1} sm={2} className="text-bath">
                          <p>นาที</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* //// */}
                  <Form.Group
                    controlId=""
                    className={"package-data " + (switchFrom === false ? 'bg-opacity' : '')}
                  >
                    <p className="form-title">ราคาตามขนาดรถ</p>
                    <Row mt-3>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>S</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_size_S)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Size_S(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_size_S)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_size_S(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>M</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_size_M)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Size_M(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_size_M)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_size_M(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>L</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_size_L)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Size_L(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_size_L)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_size_L(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>TRUCK CAB</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_truck_cab)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Truck_Cab(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_truck_cab)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_Car_Truck_Cab(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>SUV</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_suv)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Suv(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_suv)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_suv(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>TRUCK 4 DOOR </p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_truck4Door)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Truck4Door(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_truck4Door)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_truck4Door(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>XL</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_size_XL)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Size_XL(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_size_XL)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_size_XL(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={4}
                            xl={4}
                            className="text-title"
                          >
                            <p>VAN</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุราคา"
                              className="input-search-add-wash-part"
                              value={commaCase(car_van)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setCar_Van(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} sm={2} className="text-bath">
                            <p>บาท</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            className="text-title"
                          >
                            <p>เวลาดำเนินการ</p>
                          </Col>
                          <Col xs={7} sm={4} md={4} lg={6} xl={4}>
                            <Form.Control
                              type="text"
                              required
                              placeholder="ระบุเวลา"
                              className="input-search-add-wash-part"
                              value={commaCase(time_car_van)}
                              onWheel={(e) => e.target.blur() }
                              onChange={(e) => setTime_car_van(e.target.value.replaceAll(',', ''))}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={
                                switchFrom === false
                              }
                            ></Form.Control>
                          </Col>
                          <Col xs={1} className="text-bath">
                            <p>นาที</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>

                <p ref={productRef} className="form-title">วัสดุ/อุปกรณ์อ้างอิง</p>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col xs={8} className="mt-2 mb-2">
                        <Select
                          options={Products}
                          placeholder="ค้นหาวัสดุ/อุปกรณ์"
                          isClearable={true}
                          isSearchable={true}
                          // required={ValidatedProduct}
                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          value={ProductId ? Products.find((item) => item.id === ProductId) : null }
                          onChange={(data) => {setProductId(data.id); setUnitSales(data.unit_sales)}}
                        />
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col xs={8} className="mt-2 mb-2">
                        <Select
                          options={UnitSales}
                          placeholder="เลือกหน่วยขาย"
                          // required={ValidatedProduct}
                          className={`select-list ` + (ValidatedProduct ? 'validate-fail' : '') }
                          isClearable={true}
                          isSearchable={true}
                          getOptionLabel={option => option.name_sale}
                          getOptionValue={option => option.id}
                          value={UnitSaleId ? UnitSales.find((item) => item.id === UnitSaleId) : null }
                          isDisabled={!ProductId || UnitSales && UnitSales.length === 0 }
                          onChange={(data) => setUnitSaleId(data.id)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {
                  ProductSizes?.map((product, key) => {
                    return(
                      <Row className="g-0">
                        <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-title">
                          <p>{product.size}</p>
                        </Col>
                        <Col xs={9} sm={4} md={4} lg={3} xl={2} className="input-stock">
                          <Form.Control
                            type="text"
                            name={product.size}
                            placeholder="ระบุจำนวน"
                            required={ValidatedProduct}
                            className="input-search-add-wash-part"
                            value={commaCase(product.quantity)}
                            onWheel={(e) => e.target.blur() }
                            onChange={
                              (e) => setProductSizes(ProductSizes.map(
                                el => el.key === key ? { ...el, quantity: e.target.value.replaceAll(',', '') }: el
                              )) 
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          ></Form.Control>
                        </Col>
                      </Row>
                    )
                  })
                }

                <Row className="g-0">
                  <Col xs={0} sm={3} md={3} lg={2} xl={2} className="text-title">
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={2}
                    className="set-row-group-input"
                  >
                  {
                    ProductEditMode 
                    ?
                    <Button
                      type="button"
                      className="button-package-save mt-4 mb-4"
                      style={{ margin: 0 }}
                      onClick={ () => UpdateProductState()}
                    >
                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; แก้ไข
                    </Button>
                    :
                    <Button
                      type="button"
                      className="button-package-save mt-4 mb-4"
                      style={{ margin: 0 }}
                      onClick={ () => InsertProductSize()}
                    >
                      <img src={Increase} className="" /> &nbsp; &nbsp; &nbsp; เพิ่ม
                    </Button>
                  }
                  </Col>
                </Row>

                <Row className="g-0" style={{ overflow: 'scroll', height: '200px' }}>
                  <Col>
                    <Table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th style={{ width: '20%' }}>ชื่ออุปกรณ์/ขนาด</th>
                          <th>S</th>
                          <th>M</th>
                          <th>L</th>
                          <th>TRUCK CAB</th>
                          <th>SUV</th>
                          <th>TRUCK 4 DOOR </th>
                          <th>XL</th>
                          <th>VAN</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package">
                        {
                          ProductTable?.map((product, key) => {
                            return(
                              <tr key={`column-${key}`}>
                                <td>{product?.product?.name}</td>
                                <td>{product.S}</td>
                                <td>{product.M}</td>
                                <td>{product.L}</td>
                                <td>{product['TRUCK CAB']}</td>
                                <td>{product['SUV']}</td>
                                <td>{product['TRUCK 4 DOOR']}</td>
                                <td>{product['XL']}</td>
                                <td>{product['VAN']}</td>
                                <td>
                                  <img src={Increase} onClick={() => EditProduct(product)}/>
                                </td>
                                <td>
                                  <img src={Delete} onClick={() => DeleteProduct(product, key)}/>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <Row>
                  <Col className="colum-open-service">
                    <p>เปิด/ปิด ให้บริการ</p>{' '}
                    <Switch onClick={statusServiceToggler} />{' '}
                  </Col>
                </Row>

                <Row className="g-0 d-flex justify-content-center row-delete-mobile">
                  <Button type="submit" className="button-package-save">
                    <img src={Delete} className="" /> &nbsp; ลบแพคเกจ
                  </Button>
                </Row>
                <Row className="g-0 mt-5 mb-5">
                  <Col className="d-flex justify-content-center">
                    <Button type="submit" className="button-package-save">
                      <img src={Save} className="" /> &nbsp; &nbsp; บันทึก
                    </Button>
                    <Button type="button" className="button-package-save" onClick={exit}>
                      <img src={Turnoff} className="" /> &nbsp; &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
