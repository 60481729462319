import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  ToastContainer,
  Toast
} from 'react-bootstrap';

// import Service from 'assets/icon/management.png';
import Footer from 'Components/Footer';

import { useHistory } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import MyPagination from 'Components/MyPagination'
import dayjs from 'dayjs';
import moment from 'moment';
import * as XLSX from 'xlsx'

import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import Save from 'assets/icon/flat-style-circle-save.png';
import Status from 'assets/icon/ststus-appointment.png';
import StatusDisable from 'assets/icon/ststus-appointment-disable.png';
import AppointmentImage from 'assets/icon/purchase.png';
import SearchImage from 'assets/icon/search.png';
import CALENDAR from 'assets/icon/Calendar.png';

//API
import { loadAuth } from 'actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { LoadAppointment, UpdateAppointment, CreateAppointmentLog } from 'actions/appointment/index';
import { LoadJobServiceAppointments } from 'actions/service_appointment/index'

export default function Index() {
  const history = useHistory();
  const { employee } = useSelector( (state) => state.user)
  const dispatch = useDispatch();

  const { RangePicker } = DatePicker;

  const [search, setSearch] = useState({
    Date: [
      null,
      null
    ],
    date: null,
    status: null,
    type: null,
    PerPage: 5,
    Rows: null,
    CurrentPage: null,
    keyword: null
  })

  const [appointments, setAppointments] = useState([])
  const [appointment, setAppointment] = useState(null)
  //
  const [JobServiceAppointment, setJobServiceAppointment] = useState([])

  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState([]);

  function close() {
    history.push(`/`);
  }

  const optionNotification = [
    { value: null , label: 'ทั้งหมด' },
    { value: 'ครบกำหนด', label: 'วันนี้' },
    { value: 'เลยกำหนด', label: 'เลยกำหนด' },
    { value: 'ใกล้ถึงวันนัด', label: 'ใกล้ถึงวันนัด' },
  ];

  const optionNotificationType = [
    { value: null , label: 'ทั้งหมด' },
    { value: 'ทรีตเมนต์', label: 'ทรีตเมนต์' },
    { value: 'เช็คการเซ็ตตัว', label: 'เช็คการเซ็ตตัว' },
    { value: 'พ่นเคลือบ', label: 'พ่นเคลือบ' },
    { value: 'เก็บงาน', label: 'เก็บงาน' },
  ];

  const optionHours = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
  ];

  const optionMinutes = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
    { value: '60', label: '60' },
  ];

  const getAuth = async () => {
    try {
      const params = {
        name: ['การนัดหมาย'],
        permission : 'view',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/');
      // }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const [isLoading, setLoading] = useState(true);

  const ResetSelect = () => {
    setSearch({...search, status: null, type: null});
  }

  const GetAppointment = () => {
    setLoading(true)
    const params = {
      start_date: moment(search?.Date[0]).format('YYYY-MM-DD'),
      end_date: moment(search?.Date[1]).format('YYYY-MM-DD'),
      date: search.date,
      status: search.status,
      type: search.type,
      limit: search.PerPage,
      page: search.CurrentPage,
      keyword: search.keyword
    }
    
    dispatch(LoadAppointment(params)).then(response => {
      if(response.data.data && response.data.data.data){
        // console.log('test');
        setAppointments(response.data.data.data);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setAppointments(response.data.data);
      }
      setAppointment(response.data.appointment);
      setLoading(false)
    }).catch(e => {

    })
  }

  async function GetJobServiceAppointments() {
    setLoading(true)
    const params = {
      date: search.date,
      status: search.status,
      type: search.type,
      limit: search.PerPage,
      page: search.CurrentPage
    }

    dispatch(LoadJobServiceAppointments(params)).then(response => {
      if(response.data.data && response.data.data.data){
        setJobServiceAppointment(response.data.data.data);
        setSearch({ ...search, 
          Rows: response.data.data.last_page,
          CurrentPage: response.data.data.current_page 
        })
      }else{
        setJobServiceAppointment(response.data.data);
      }
      setAppointment(response.data.appointment);
      setLoading(false)
    }).catch(e => {

    })
  }

  useEffect(() => {
    getAuth();
    GetAppointment();
    // GetJobServiceAppointments();
  }, []);

  useEffect(() => {
    GetAppointment()
    // GetJobServiceAppointments();
  }, [search.status, search.type, search.CurrentPage]);
  
  const [showNote, setShowNote] = useState(false);
  const [showPostpone, setShowPostpone] = useState(false);
  const [currentAction, setCurrentAction] = useState({
    id: null,
    note: null
  });

  const [log, setLog] = useState({
    id: null,
    date: null,
    appointment_date: null,
    appointment_time: null,
    hours: null,
    minutes: null,
    note: null
  });

  const ToggleNote  = (appointment) => {
    setCurrentAction({ 
      id: appointment.id,
      status:'มาตามนัด',
      note: appointment.note
    })
    setShowNote(!showNote)
    
  };

  const editNote = (e) => {
    e.preventDefault();
    const form = {
      id: currentAction.id,
      data:{
        status: currentAction.status,
        note: currentAction.note
      }
    }

    dispatch(UpdateAppointment(form)).then( response =>{
      history.push(`/appointment/${currentAction.id}`)
      GetAppointment()
      setShowNote(false)
    }).catch(e => {
      console.log(e)
    })
  };

  const TogglePostpone  = (appointment) => {
    // console.log(' appointment.appointment[0].note', appointment.appointment_log);
    const note = appointment?.appointment_log.length > 0 ? appointment?.appointment_log[0].note : ''
    setLog({
      ...log,
      id: appointment.id,
      date: appointment.appointment_date ? moment(appointment.appointment_date).format('YYYY-MM-DD') : null,
      appointment_date: appointment.appointment_date ? moment(appointment.appointment_date).format('YYYY-MM-DD') : null,
      appointment_time: appointment.appointment_time ? moment(appointment.appointment_time).format('HH:mm:ss') : null,
      hours: appointment.appointment_time ? moment(appointment.appointment_time, 'HH:mm:ss').format('HH') : null,
      minutes: appointment.appointment_time ? moment(appointment.appointment_time, 'HH:mm:ss').format('mm') : null,
      // appointment_log_note: note, // get last array
    })
    setShowPostpone(!showPostpone)
  };

  function createLog(e) {
    e.preventDefault();

    const form = {
      id: log.id,
      data:{
        date: dayjs(log.date).format('YYYY-MM-DD'),
        status: 'เลื่อนนัด',
        appointment_date: log.appointment_date,
        appointment_time: log.hours && log.minutes ? moment(`${log.hours}:${log.minutes}`, 'HH:mm').format('HH:mm') : null,
        note: log.note
      }
    }

    dispatch(CreateAppointmentLog(form)).then( () => {
      GetAppointment();
      setShowPostpone(false)
    }).catch(e => {
      if (e.response.data && e.response.data.messages) {
        setErrors([...e.response.data.messages])
      }
    })
  }

  function notificationOff(id) {
    const form = {
      id: id,
      data:{
        date: null,
        status: 'เลิกแจ้งเตือน',
        note: null
      }
    }

    dispatch(CreateAppointmentLog(form)).then( response =>{
      GetAppointment()
      setDeleting([]);
      setConfirmation(false);
    }).catch(e => {
      console.log(e)
      setDeleting([]);
      setConfirmation(false);
    })
  }

  const renderTime = (appointment) => {
    const appointmentType = appointment.type
    let appointmentDate = null
    let appointmentTime = null
    switch (appointmentType) {
      case 'ทรีตเมนต์':
        appointmentDate = appointment.appointment_date
        appointmentTime = appointment.appointment_time
        break;
      case 'เช็คการเซ็ตตัว':
        appointmentDate = appointment.job_detail.service_appointment.setup_date
        appointmentTime = appointment.job_detail.service_appointment.setup_time
        break;
      case 'พ่นเคลือบ':
        appointmentDate = appointment.job_detail.service_appointment.coating_date
        appointmentTime = appointment.job_detail.service_appointment.coating_time
        break;
      case 'เก็บงาน':
        appointmentDate = appointment.job_detail.service_appointment.schedule_date
        appointmentTime = appointment.job_detail.service_appointment.schedule_time
        break;
    }

    return (
      <p className="m-0">
        {appointmentType} 
        {appointment.job_detail.service_appointment.total ? ` ${appointment.title ? (appointment.title) : ''}` : '' } 
        {appointmentDate ? ` (${dayjs(appointmentDate).format('DD/MM/YYYY')}) เวลา` : ''} 
        {appointmentTime ? ` (${moment(appointmentTime, 'HH:mm:ss').format('HH:mm')}) น.` : ''} 
      </p>
    )
  }

  const renderTimeToExcel = (appointment) => {
    const appointmentType = appointment.type
    let appointmentDate = null
    let appointmentTime = null
    switch (appointmentType) {
      case 'ทรีตเมนต์':
        appointmentDate = appointment.appointment_date
        appointmentTime = appointment.appointment_time
        break;
      case 'เช็คการเซ็ตตัว':
        appointmentDate = appointment.job_detail.service_appointment.setup_date
        appointmentTime = appointment.job_detail.service_appointment.setup_time
        break;
      case 'พ่นเคลือบ':
        appointmentDate = appointment.job_detail.service_appointment.coating_date
        appointmentTime = appointment.job_detail.service_appointment.coating_time
        break;
      case 'เก็บงาน':
        appointmentDate = appointment.job_detail.service_appointment.schedule_date
        appointmentTime = appointment.job_detail.service_appointment.schedule_time
        break;
    }

    return `${appointmentType} ${appointment.job_detail.service_appointment.total ? `${appointment.title ? (appointment.title) : ''}` : '' } ${appointmentDate ? ` (${dayjs(appointmentDate).format('DD/MM/YYYY')}) เวลา` : ''} ${appointmentTime ? `(${moment(appointmentTime, 'HH:mm:ss').format('HH:mm')}) น.` : ''}`
  }

  const [errors, setErrors] = useState([]);
  const ExportExcel = () => {
    const params = {
      date: search.date,
      status: search.status,
      type: search.type
    }

    dispatch(LoadAppointment(params)).then(response => {
      if (response.data.data && !response.data.data.length) {
        setErrors(['ไม่พบการนัดหมายที่เลือก'])
        return false
      }
      let data = [];
      let export_name = [];
      response.data.data.map( (item, key) => {
        const setTime = renderTimeToExcel(item)
        let set = {
          'ลำดับ': key + 1 ,
          // 'วันที่': dayjs(item.created_at).format('DD/MM/YYYY'),
          // 'เลขที่ Job': item.job_detail?.job?.job_code,
          'รายละเอียด': `Job ${item.job_detail?.job?.job_code} สาขา ${ item.job_detail?.job?.branch.name } ${item.job_detail?.job?.customer?.first_name} ${item.job_detail?.job?.customer?.last_name} (${item.job_detail?.job?.customer?.nickname}) ${ item.job_detail?.job.customer && JSON.parse(item.job_detail?.job.customer.phone)} ${item.job_detail.service_appointment && setTime }`
        }
        export_name = `การนัดหมาย - ${dayjs(item.created_at).format('DD-MM-YYYY')} `

        data.push(set);
      })
      const dataWS = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, export_name + '.xlsx')
    }).catch(e => {
      setErrors(['เกิดข้อผิดพลาด'])
    })
  }

  function deleteConfirmation(id) {
    const value = {
      id: id,
    }
    setDeleting(value)
    setConfirmation(true)
  }
  
  return (
    <>
      <ToastContainer position='top-end'>
        {
          errors?.map((error, key) => {
            return (
              <Toast show={true} key={key} delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                <Toast.Header>
                  <strong className="me-auto">แจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body>{ error }</Toast.Body>
              </Toast>
            )
          })
        }
      </ToastContainer>
      <Container
        fluid
        className="package-container Service Stock Job Appointment bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={AppointmentImage} />
                  <b>การนัดหมาย</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={AppointmentImage} />
                  <b>การนัดหมาย</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>
                <Row className="set-search-row">
                    <Col xs={12} lg={4} className=" mt-2 mb-2">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="ค้นหาด้วยชื่อลูกค้า ทะเบียนรถ"
                        value={search.keyword ? search.keyword : ''}
                        onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                      ></Form.Control>
                    </Col>
                    <Col
                      lg={5}
                      xl={4}
                      className="d-flex justify-content-center mt-2 mb-2"
                    >
                      <RangePicker
                          ranges={{
                            Today: [moment(), moment()],
                            'This Month': [
                              moment().startOf('month'),
                              moment().endOf('month'),
                            ],
                          }}
                          value={search.Date}
                          onChange={(date) => setSearch({...search, Date: date})}
                          suffixIcon={<img src={CALENDAR} />}
                          separator="-"
                          className="calendar-antd-dashboard"
                          style={{
                            boxShadow: 'none ',
                            height: '50px',
                          }}
                        />
                    </Col>
                    <Col xs={6} lg={2} className="mt-2 mb-2">
                      <Button
                        className="button-menu"
                        style={{ padding: 0, margin: 0 }}
                        onClick={() => GetAppointment()}
                      >
                        ค้นหา &nbsp;
                        <img src={SearchImage} alt="" />
                      </Button>
                    </Col>
                  </Row>
                <Row className="g-0 justify-content-between ">
                  <Col lg={8}>
                    <Row>
                      <Col lg={5} className="mb-2">
                        <Select
                          options={optionNotification}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          placeholder="การแจ้งเตือน"
                          isClearable={false}
                          isSearchable={false}
                          onChange={(data) => setSearch({ ...search, status: data.value, CurrentPage: 1})}
                          value={optionNotification.find((data) => {return data.value === search.status})}
                          className="select-list"
                        />
                      </Col>
                      <Col lg={5} className=" mb-2">
                        <Select
                          options={optionNotificationType}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          placeholder="ประเภทการแจ้งเตือน"
                          isClearable={false}
                          isSearchable={false}
                          onChange={(data) => setSearch({ ...search, type: data.value, CurrentPage: 1})}
                          value={optionNotification.find((data) => {return data.value === search.type})}
                          className="select-list"
                        />
                      </Col>
                      <Col lg={2} className=" mb-2">
                        <Button type="button" className="button" onClick={() => ResetSelect()}>
                          คืนค่า
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} lg={3} className="mt-4 mb-4 text-left">
                        <Form.Label className="font-weight-bold">
                          คิวนัดวันนี้ { appointment?.today } ราย
                        </Form.Label>
                      </Col>
                      <Col xs={6} lg={3} className="mt-4 mb-4 text-left">
                        <Form.Label className="font-weight-bold">
                          มาตามนัด { appointment?.come_by_appointment } ราย
                        </Form.Label>
                      </Col>
                      <Col className="mt-4 mb-4 text-left">
                        <Form.Label className="font-weight-bold">
                          นัดที่ต้องติดตาม { appointment?.follow } ราย
                        </Form.Label>
                      </Col>
                      <Col className="mt-4 mb-4 text-left">
                        <Form.Label className="font-weight-bold">
                          ติดตามแล้ว { appointment?.followed } ราย
                        </Form.Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-end set-row-export">
                      <Col xs={5} lg={9} xl={6}>
                        <Button type="button" className="button" onClick={() => ExportExcel()}>
                          Export
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="g-0 justify-content-center mt-3 set-height-aappointment">
                  {appointments.map((item, index) => {
                    return (
                      <Col xs={12} key={item.id} className="mb-3">
                        <div className="body-text-appointment">
                          <Row className="align-items-center">
                            <Col xs={12} lg={5} className="text-left pl-5">
                              <p className="m-0">
                                <b>Job {item.job_detail?.job?.job_code}</b> สาขา{ item.job_detail?.job?.branch_name }
                              </p>
                              <p className="m-0">
                                {`${item.job_detail?.job?.customer?.first_name} ${item.job_detail?.job?.customer?.last_name} (${item.job_detail?.job?.customer?.nickname}) ${ item.job_detail?.job.customer && JSON.parse(item.job_detail?.job.customer.phone)}`}
                              </p>
                              {
                                item.job_detail.service_appointment && renderTime(item)
                              }
                            </Col>
                            <Col xs={12} lg={4} className="mt-2 mb-2">
                              <Row>
                                <Col xs={5} lg={7} className="pl-5">
                                  <Form.Label className="font-weight-bold">
                                    {item.status} { item.status === 'ใกล้ถึงวันนัด' ? `(${item.close_to_appointment_count})` : ''} 
                                  </Form.Label>
                                </Col>
                                <Col xs={3} lg={5}>
                                  <a
                                    type='button'
                                    className="link link-note"
                                    onClick={() => history.push(`/appointment/${item.id}`)}
                                  >
                                    หมายเหตุ
                                  </a>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} lg={3}>
                              <Row className="justify-content-center">
                                <Col xs={6} className="text-center">
                                  <Button
                                    type="button"
                                    className="button"
                                    disabled={item.status === 'มาตามนัด'}
                                    onClick={() => TogglePostpone(item)}
                                  >
                                    เลื่อนนัด
                                  </Button>
                                </Col>
                                {/* <Col xs={4}>
                                  <Button type="button" className="button"  onClick={() => deleteConfirmation(item.id)}>
                                    เลิกแจ้งเตือน
                                  </Button>
                                </Col> */}
                                <Col xs={6} className="text-center">
                                  <Button
                                    type="button"
                                    className="button-image"
                                    disabled={item.status === 'มาตามนัด'}
                                    onClick={ () => ToggleNote(item) }
                                    
                                  >
                                    <img src={item.status === 'มาตามนัด' ? StatusDisable : Status} />
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <Row className="justify-content-center mt-4 mb-4">
                  <Col className="d-flex justify-content-center set-paginate">
                    <div className="box-paginate">
                      {
                      !isLoading ? 
                        search.Rows > 1 && search.PerPage > 0 ?
                          <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                      : 
                        search.Rows > 1 && search.PerPage > 0 ?
                          <MyPagination totPages={search.Rows} currentPage={search.CurrentPage} pageClicked={(e) => { setSearch({...search, CurrentPage: e }) }}></MyPagination> 
                        : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />

      <Modal
        className="modal-style"
        centered
        show={showNote}
        onHide={ () => setShowNote(false)}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>หมายเหตุ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowNote(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
        {/* noValidate validated={validated}  */}
          <Form onSubmit={editNote}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '300px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <Row className="align-items-center mt-2">
                  <Form.Control
                    as="textarea"
                    rows={8}
                    className="form-control-area"
                    value={currentAction.note ? currentAction.note : ''}
                    onChange={(e) =>
                      setCurrentAction({
                        ...currentAction,
                        note: e.target.value,
                      })
                    }
                  />
                </Row>
              </Col>

              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={showPostpone}
        onHide={() => setShowPostpone(false)}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>เลื่อนนัด</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setShowPostpone(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form onSubmit={createLog}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '400px' }}
            >
              <Col xs={12} lg={10} className="mt-2 ">
                <Row className="align-items-center mt-2">
                  <Col xs={12} className="text-left">
                    <Row>
                      <Col className='d-flex' style={{ flexWrap: 'warp' }}>
                        <Form.Label className="font-weight-bold" style={{width: '20%', alignSelf: 'center' }}>
                          วันนัดปัจจุบัน
                        </Form.Label>

                        <Form.Label>{ `${log.date}` }</Form.Label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={12} className="text-left mt-3 mb-3">
                    <Row className="align-items-center">
                      <Col className='d-flex' style={{ flexWrap: 'warp' }}>

                        <Form.Label className="font-weight-bold" style={{width: '20%', alignSelf: 'center' }}>
                          วันที่เลื่อนนัด
                        </Form.Label>

                        <DatePicker
                          placeholder=""
                          suffixIcon={<img src={Calendar} />}
                          style={{width: '40%'}}
                          value={moment(log.appointment_date)}
                          onChange={(e) => setLog({...log, appointment_date: moment(e).format('YYYY-MM-DD')})}
                        />

                        <Select
                          options={optionHours}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          defaultValue={optionHours[0]}
                          placeholder="ชั่วโมง"
                          className="select-list ml-2"
                          style={{ width: 'auto' }}
                          value={log.hours ? optionHours.find(item => item.value === log.hours) : null }
                          onChange={(e) => setLog({...log, hours: e.value})}
                        />

                        <p className='m-2' style={{ alignSelf: 'center' }}>:</p>

                        <Select
                          options={optionMinutes}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          defaultValue={optionMinutes[0]}
                          placeholder="นาที"
                          className="select-list"
                          style={{width: 'auto'}}
                          value={log.minutes ? optionMinutes.find(item => item.value === log.minutes) : null }
                          onChange={(e) => setLog({...log, minutes: e.value})}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={12} className="text-left">
                    <Form.Label className="font-weight-bold pl-3">
                      หมายเหตุ
                    </Form.Label>
                  </Col>
                  <Col xs={12}>
                    <Form.Control
                      as="textarea"
                      rows={8}
                      className="form-control-area"
                      // value={log.appointment_log_note}
                      onChange={(e) =>
                        setLog({
                          ...log,
                          note: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยัน</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ต้องการยกเลิกการแจ้งเตือนใช่หรือไม่</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => notificationOff(deleting.id) }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
