import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Delete from '../../../assets/icon/delete.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { FindPackageType } from 'actions/package';
import { FindCarOwner } from 'actions/customer/car';

export default function WashPart(props) {

  const dispatch = useDispatch()
  const history = useHistory();
  const PackageTypeId = props.data && props.data.id ? props.data.id : [];
  const PackageKey = props.data && props.data.key ? props.data.key : 0;

  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);
  
  const [serviceMore, setServiceMore] = useState(false);
  const [serviceNow, setServiceNow] = useState(false);
  const [attrition, setAttrition] = useState(false);
  const [ozone_oven, setOzoneOven] = useState(false);
  const [service, setService] = useState([{ value: '', label: '' }]);
  const [Data,setData] =useState({
    PackageStorageList: parseDataJob.PackageList.length > 0 
    ?  parseDataJob.PackageList  : [],
    note: parseDataJob.note ? parseDataJob.note : '',
    insite_note: parseDataJob.insite_note ? parseDataJob.insite_note : '',
    area: parseDataJob.area ? parseDataJob.area : '',
    defect_car: parseDataJob.defect_car? parseDataJob.defect_car: false,
    things_in_car: parseDataJob.things_in_car? parseDataJob.things_in_car: false,
  })
  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);

  const handleDefectCar = () => {
    setDefectCar(!DefectCar);
    updateLocalStorage(!DefectCar,null,'defect_car');
  };

  const handleThingsInCar = () => {
    setThingsInCar(!ThingsInCar);
    updateLocalStorage(!ThingsInCar,null,'things_in_car');
  }

  const [isLoading, setLoading] = useState(false);
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);
  const [CarOwner,setCarOwner] = useState([]);

  const handleServiceMore = () => {
    setServiceMore(!serviceMore);
    // updateLocalStorage(!serviceMore,null,'service_more');
  };
  const handleFastService = () => {
    setServiceNow(!serviceNow);
    // updateLocalStorage(!serviceNow,null,'service_now');
  };

  const handleAttrition = () => {
    setAttrition(!attrition);
    updateLocalStorage(!attrition,null,'attrition');
  };
  const handleOzoneOven = () => {
    setOzoneOven(!ozone_oven);
    updateLocalStorage(!ozone_oven,null,'ozone_oven');
  };

  // const optionService = packages;
  function SetService(data) {
    setService({ value: data.id, label: data.name, data });
    // setData({...Data, 
    //   PackageId : data.id,
    //   PackageName: data.name
    // })
    
  }

  //  const StorageModel = {
  //   key: 0,
  //   package_id: '',
  //   PackageCode: '',
  //   PackageName: '',
  //   Quantity: '',
  // }
  const StorageModel = {
    key: 0,
    PackageId: null,
    PackageCode: null,
    PackageName: null,
    PackageTypeId: null,
    PackageTypeName: null,
    Status: 'new',
    form: 'package',
    Quantity: null,
    Price: 0,
  }
  function removeProduct(data){
    const result = PackagesDefault.find((item) => {
      return item.id === data.package_id
    })
    Packages.push(result);
    // console.log('Data.PackageStorageList',Data.PackageStorageList);
    // console.log('data',data);
    const resultList = Data.PackageStorageList.filter( (storeList) => {
      return storeList.key !== data.key
    })
    // console.log('resultList',resultList);
    setData({...Data, PackageStorageList: resultList});

    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = resultList;
    parseDataJob.PackageListAppointment = [];
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));


  }

  function InsertPackage(){
    if(service.data && service.data.id){
      let insert = StorageModel
      insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
      insert.PackageId = service.data.id
      insert.PackageName = service.data.name
      insert.PackageCode = service.data.code
      insert.PackageTypeId = props.data.id 
      insert.PackageTypeName = props.data.name
      insert.ServiceNow = serviceNow
      const CarSize = CarOwner.car_serie.size
      const MiddleSize = service.data.middle_sizes
      const resultCarSize = MiddleSize.find((item) => {
        return item.size === CarSize
      })
      if(MiddleSize[0].active == 1 && MiddleSize[0].size == 'ราคาเหมา'){
        insert.Price = MiddleSize[0].price
      }else{
        insert.Price = resultCarSize.price ? resultCarSize.price : 0 
      }
      insert.FilmThickness = null
      insert.TypeWork = null
      insert.PackageType = service.data.category

      // for Appointment
      insert.CleanService = null
      insert.CleanServiceTime = service.data.service_time  
      insert.Treatment = null // TreatmentTime * Quantity
      insert.TreatmentTime = service.data.treatment_time
      insert.TreatmentRanges = service.data.treatment_ranges
      insert.Setup = service.data.setup
      insert.Schedule = service.data.schedule
      insert.PackageTypeId = service.data.package_type_id
      
      setData({...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

      //clear 
      setService({ value: '', label: '' });
      setServiceNow(false);
      setAttrition(false);
      setOzoneOven(false);

      // //for remove select form select list
      // const result = Packages.filter((item)=> {
      //   return item.id !== service.data.id
      // });
      // setPackages(result);

      const package_list = Data.PackageStorageList
      package_list.push(insert)

      parseDataJob.PackageList = package_list;
      parseDataJob.PackageListAppointment = [];
      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
      
    }else{
      alert('เลือกรายการ')
    }
  }

  function updateLocalStorage(data,item,form){

      switch (form) {
        case 'Quantity':
          setData(prevState => ({...Data,
            PackageStorageList: prevState.PackageStorageList.map(
              el => el.key === item.key ? { ...el, Quantity: Number(data.target.value) }: el
            )}))
      
          item.Quantity = Number(data.target.value)
          item.CleanService = Number(item.CleanServiceTime) * Number(item.Quantity)
          break;
        case 'note':
          setData({...Data, note: data.target.value })
          Data.note = data.target.value
          break;
        case 'insite_note':
          setData({...Data, insite_note: data.target.value })
          Data.insite_note = data.target.value
          break;
        // case 'service_now':
        //   setData({...Data, service_now: data })
        //   Data.service_now = data
        //   break;
        // case 'service_more':
        //   setData({...Data, service_more: data })
        //   Data.service_more = data
        //   break;
        case 'things_in_car':
          setData({...Data, things_in_car: data })
          Data.things_in_car = data
          break;
        case 'defect_car':
          setData({...Data, defect_car: data })
          Data.defect_car = data
          break;
        case 'area':
          setData({...Data, area: data.target.value })
          Data.area = data.target.value
          break;
        case 'wash':
          setData({...Data, wash: data.target.value })
          Data.wash = data.target.value
          break;
        case 'enamel':
          setData({...Data, enamel: data.target.value })
          Data.enamel = data.target.value
          break;
        case 'attrition':
          setData({...Data, attrition: !attrition })
          Data.attrition = !attrition
          break;
        case 'wash_upholstery':
          setData({...Data, wash_upholstery: data.target.value })
          Data.wash_upholstery = data.target.value
          break;
        case 'wash_belt':
          setData({...Data, wash_belt: data.target.value })
          Data.wash_belt = data.target.value
          break;
        case 'wash_door':
          setData({...Data, wash_door: data.target.value })
          Data.wash_door = data.target.value
          break;
        case 'ozone_oven':
          setData({...Data, ozone_oven: !ozone_oven })
          Data.ozone_oven = !ozone_oven
          break;
        default:
          break;
      }

      const result = Data.PackageStorageList

      let DataJob = localStorage.getItem('job-inside-data')
      var parseDataJob = JSON.parse(DataJob);
      parseDataJob.PackageList = result;
      parseDataJob.note = Data.note
      parseDataJob.insite_note = Data.insite_note
      parseDataJob.area = Data.area
      if(props.data.name === 'Box Set'){
        parseDataJob.wash = Data.wash
        parseDataJob.enamel = Data.enamel
        if(form === 'attrition') { parseDataJob.attrition = Data.attrition }
      }
      if(props.data.name === 'Interior'){
        parseDataJob.wash = Data.wash
        parseDataJob.enamel = Data.enamel
        parseDataJob.wash_upholstery = Data.wash_upholstery
        parseDataJob.wash_belt = Data.wash_belt
        parseDataJob.wash_door = Data.wash_door
        if(form === 'ozone_oven') { parseDataJob.ozone_oven = Data.ozone_oven }
      }
      parseDataJob.things_in_car = Data.things_in_car
      parseDataJob.defect_car = Data.defect_car
      parseDataJob.PackageListAppointment = [];

      localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));

        // let DataJob = localStorage.getItem('job-inside-data')
        // let parseDataJob = JSON.parse(DataJob);
        // let parsePackageType = parseDataJob.PackageType;

        // const index =  parsePackageType.findIndex((findPackageType) => 
        // {return findPackageType.key === PackageKey})

        // parsePackageType[index] = Data;
        // parseDataJob.PackageType= parsePackageType;
        // localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
  }
  async function findPackageType(PackageTypeId){
    setLoading(true)
    try {
      const response = await dispatch(FindPackageType(PackageTypeId));
      const packages = response.data.data && response.data.data.packages
      const PackagesDefault = response.data.data && response.data.data.packages
      setPackages(packages);
      setPackagesDefault(PackagesDefault);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  async function getCarSize(CarId){
    setLoading(true)
    try {
      const response = await dispatch(FindCarOwner({ id: CarId }));
      const car_onwer = response.data.data
      setCarOwner(car_onwer);
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  function LinkTo(url){
    let DataJob = localStorage.getItem('job-inside-data')
    var parseDataJob = JSON.parse(DataJob);
    parseDataJob.PackageList = Data.PackageStorageList;
    localStorage.setItem('job-inside-data',JSON.stringify(parseDataJob));
    history.push(url)

  }

  function getTextByPackage(item){
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
          return ''
        break;
      case 'บริการฟิล์มกรองแสง':
          return ''
        break;
      case 'บริการเคลือบเหมา':
        return ''
        break;
      case 'บริการเคลือบเฉพาะจุด':
        return ''
        break;
      case 'บริการฟิล์มกันรอย':
        let set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
        break;
      case 'แพ็คเกจพิเศษ':
        return ''
        break;
    
      default:
        break;
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('item',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.PointCoatings.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){ 
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.PointCoatings === null){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  useEffect(() => {
    findPackageType(PackageTypeId);
    getCarSize(parseDataJob.car_id)
  },[])
  return (
    <>
      <Form>
        <Container fluid className="pl-3 pr-3">
          <Row className="g-0 justify-content-start">
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <Select
                    options={Packages}
                    getOptionValue={(option)=> option.id}
                    getOptionLabel={(option) => option.name}
                    value={service.value ? Packages?.find((data) => {return data?.package_id === service?.value}) : '' }
                    placeholder=""
                    isClearable={false} 
                    isSearchable={false}
                    onChange={(data) => SetService(data)}
                    className="select-list"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="">
              <Row className="justify-content-end">
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="รับบริการทันที"
                      onChange={handleFastService}
                      checked={serviceNow}
                      className="checkbox-input mt-3"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {
            props.data.name === 'Interior'
            &&
            <>
              <Row className="g-0 mt-3">
                <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                  <p>จำนวนครั้งรับบริการ</p>
                </Col>
              </Row>
              <Row className="g-0">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>ซักเบาะ</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.wash_upholstery}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'wash_upholstery');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="g-0">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>ซักสายเข็มขัด</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.wash_belt}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'wash_belt');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="g-0">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>ซักแผงประตู</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.wash_door}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'wash_door');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="g-0">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>เคลือบภายใน</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.enamel}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'enamel');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="g-0 mb-3">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>ล้างรถ</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.wash}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'wash');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="อบโอโซน"
                      onChange={handleOzoneOven}
                      checked={ozone_oven}
                      className="checkbox-input mt-3"
                    />
                  </div>
                </Col>
              </Row>
            </>
          }
          {
            props.data.name === 'Box Set'
            &&
            <>
              <Row className="g-0 mt-3">
                <Col xs={4} sm={3} md={3} lg={2} xl={2} className="text-title">
                  <p>จำนวนครั้งรับบริการ</p>
                </Col>
              </Row>
              <Row className="g-0">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>เคลือบ</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.enamel}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'enamel');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="g-0 mb-3">
                <Col xs={4} sm={3} md={3} lg={1} xl={1} className="text-title">
                  <p>ล้าง</p>
                </Col>
                <Col xs={8} md={8} lg={6} xl={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={Data.wash}
                    required
                    min={0}
                    onWheel={(e) => e.target.blur() }
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    onChange={(e) => {
                      updateLocalStorage(e,null,'wash');
                    }}
                    className="input-middle-price my-1"
                  ></Form.Control>
                </Col>
                <Col lg={6} xl={5}>
                  <div className="service-fast-position">
                    <Form.Check
                      type="checkbox"
                      label="ขัดสี"
                      onChange={handleAttrition}
                      checked={attrition}
                      className="checkbox-input mt-3"
                    />
                  </div>
                </Col>
              </Row>
            </>
          }
          <Row className="g-0 justify-content-start">
            {/* <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-4 ">
              <Form.Check
                type="checkbox"
                label="บริการต่อเนื่อง"
                onChange={handleServiceMore}
                checked={serviceMore}
                className="checkbox-input"
              />
            </Col> */}
            <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-4 d-lg-none">
              {/* <Form.Check
                type="checkbox"
                label="รับบริการทันที"
                onChange={handleFastService}
                checked={serviceNow}
                className="checkbox-input"
              /> */}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col lg={3} xl={2} className="mt-3 mb-3">
                  <Button type="button" className="button" onClick={() => {InsertPackage()}}>
                    เพิ่ม
                  </Button>
                </Col>
                {
                  parseDataJob.status_job  === 'appointment' ? (
                    <Col lg={3} xl={2} className="mt-3 mb-3" >
                      <Button type="button" className="button" onClick={() =>{
                        LinkTo('/job/inside-data')
                      }} >
                        job นัดหมาย
                      </Button>
                    </Col>
                  ): null
                }
                
              </Row>
            </Col>
          </Row>
          <Row className="mt-5 mb-5 m-0">
            <Col className="p-0">
              <table className="table table-responsive table-wash-part ">
                <thead>
                  <tr className="t-header-package">
                    <th>ลำดับ</th>
                    <th>รหัสรายการ</th>
                    <th>ชื่อรายการ</th>
                    <th>จำนวน</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="t-body-package ">
                  {
                    Data.PackageStorageList?.map((item,key) => {
                      return(
                        <tr>
                          <td>{key +1 }</td>
                          <td>{item.PackageCode}</td>
                          <td>{item.PackageName} 
                          <p>
                            { getTextByPackage(item)}
                            { getDisplayTextPositionCoating(item) }
                            { getDisplayTextPositionUse(item)}
                            { item.TextDetails }
                          </p> {item.ServiceNow === true ? '(บริการทันที)' : ''}</td>
                          <td>
                          <Form.Control className="input-table"
                            value={item.Quantity ? item.Quantity : ''}
                            disabled={item.Status === 'appointment'}
                            required
                            type="number"
                            onChange={
                              (data) => {
                                updateLocalStorage(data,item,'Quantity');
                              }
                            }
                        ></Form.Control>
                          </td>
                          <td>
                            <Button type=""  disabled={item.Status === 'appointment'} className="button-package"
                              onClick={ () => {removeProduct(item)} }
                            >
                              <img src={Delete} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
              <Col lg={4} className="text-left mt-3 mb-3">
                <Form.Check
                      type="checkbox"
                      label="สิ่งของในรถ"
                      onChange={handleThingsInCar}
                      checked={ThingsInCar}
                      className="checkbox-input"
                    />
                </Col>
                <Col lg={4} className="text-left mt-3 mb-3">
                  <Form.Check
                      type="checkbox"
                      label="ตำหนิรถ"
                      onChange={handleDefectCar}
                      checked={DefectCar}
                      className="checkbox-input"
                    />
                </Col>
                
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>บริเวณ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.area}
                    onChange={ (data) => {
                      setData({...Data, area: data.target.value})
                      updateLocalStorage(data,null,'area');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="text-left mt-3 mb-3">
                  <Form.Label>โน้ตภายใน</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    className="input-area"
                    rows={5}
                    value={Data.insite_note}
                    onChange={
                      (data) => {
                        updateLocalStorage(data,null,'insite_note');
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}
