
import React, { Component, useState, useEffect } from 'react';
import { Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { Container, Row, Col, Form, Modal, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Search from 'assets/icon/search.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/getout.png';
import Delete from 'assets/icon/delete.png';
import add_contract from 'assets/icon/add-staff.png';
import Returnvalue from 'assets/icon/return-value.png'
import { Province, Amphure, District } from 'actions/data/index';
import { CreateCustomer } from 'actions/customer/index';
import { SegmentList } from 'actions/settings/segment';
import { Customer } from 'actions/customer/index';

export default function AddJuristicPart({ props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = () => setShow(false);

  const close = () => {
    setShow(false);
  };

  const GenerateNumber = async () => {
    if (!Data.SegmentId) return false
    GetNumber();
    // setShow(true)
  }

  const [FNumber, setFNumber] = useState(null);
  const [PreNumber, setPreNumber] = useState([]);

  const PrepareNumber = async () => {
    let numbers = []
    for (let index = 0; index < 1200000; index++) {
      // numbers.push(await Pad(index+1, 6))
      PreNumber.push(await Pad(index+1, 6))
    }
    // setPreNumber(numbers)
    console.log('Prepare Number', 'success')
    console.log(PreNumber)
  }

  const [SegmentNumber, setSegmentNumber] = useState([]);
  const [SearchSegment, setSearchSegment] = useState({
    Keyword: ''
  });

  const NumberList = async (e, action) => {
    e.preventDefault();
    if(action === 'reset') setSearchSegment({Keyword: '' })
    
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }

    const keyword = action !== 'reset' ? SearchSegment.Keyword : ''
    let limit = 9999999
    let count = 0
    let result =  GNumber.filter(item => {
      if(item.includes(keyword.toString()) && count <= limit){
        count++
        return true
      }else{
        return false
      }
    });

    result = result.filter(val => !SegmentsExist[Data.SegmentId].includes(val));
    
    setSegmentNumber(result)
  }

  // const Prefixes = [
  //   {
  //     value: 'นาย',
  //     label: 'นาย',
  //   },
  //   {
  //     value: 'นาง',
  //     label: 'นาง',
  //   },
  //   {
  //     value: 'นางสาว',
  //     label: 'นางสาว',
  //   },
  // ];

  // const Channels = [
  //   { value: 'Social Media', label: 'Social Media' },
  //   { value: 'Offline', label: 'Offline' },
  //   { value: 'Connection', label: 'Connection' }
  // ];

  const [validated, setValidated] = useState(false);
  const [Segments, setSegments] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [SegmentsExist,setSegmentsExist] = useState([]);
  const [DataNext, setDataNext] = useState([]);

  const [Data, setData] = useState({
    SegmentId: null,
    SegmentNumber: null,

    Organization: null,
    TaxId: null,
    InvoiceAddress: null,
    Address: null,
    ProvinceId: null,
    AmphureId: null,
    PostCode: null,
    Email: null,
    ContactName: null,

    Contacts: [
      {
        key: 0,
        fullname: null,
        phone: []
      }
    ],

    // Prefix: null,
    // FirstName: null,
    // LastName: null,
    // NickName: null,
    // AKA: null,
    // CitizenId: null,

    Channel: null,
    CustomerId: null,
    Note: null,
    SpecialNeed: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    setTimeout(async () => {
      console.log(1);
      const validator = document.querySelectorAll('.validate-fail');
      if (form.checkValidity() && validator.length === 0) {
        let formData = new FormData();
        formData.append('type', 'นิติบุคคล')

        if(Data.SegmentId) formData.append('segment_id', Data.SegmentId)
        if(Data.SegmentNumber) formData.append('segment_number', Data.SegmentNumber)
        if(Data.Organization) formData.append('organization', Data.Organization)
        if(Data.TaxId) formData.append('citizen_id', Data.TaxId)
        if(Data.AmphureId) formData.append('amphure_id', Data.AmphureId)
        if(Data.DistrictId) formData.append('district_id', Data.DistrictId)
        if(Data.PostCode) formData.append('postcode', Data.PostCode)
        if(Data.CustomerId) formData.append('recommender', Data.CustomerId)
        if(Data.InvoiceAddress) formData.append('tax_invoice_address', Data.InvoiceAddress)
        if(Data.Address) formData.append('address', Data.Address)
        if(Data.PostCode) formData.append('postcode', Data.PostCode)
        if(Data.Email) formData.append('email', Data.Email)
        if(Data.Contacts && Data.Contacts.length > 0){
          Data.Contacts.forEach( (contact, key) => {
            formData.append(`contacts[${key}][fullname]`, contact.fullname)
            formData.append(`contacts[${key}][phone]`, JSON.stringify(contact.phone))
          });
        }

        if(Data.Note) formData.append('note', Data.Note)
        
        try {
          const response = await dispatch(CreateCustomer(formData));
        
          if(response){
            if(response.data.message == 'บันทึกสำเร็จ'){
              GetProvince();
              GetSegment();
              alert('บันทึกเสร็จสิ้น')
              setDataNext(response.data.data)
              setData({
                SegmentId: null,
                SegmentNumber: null,
            
                Organization: null,
                TaxId: null,
                InvoiceAddress: null,
                Address: null,
                ProvinceId: null,
                AmphureId: null,
                PostCode: null,
                Email: null,
                ContactName: null,
            
                Contacts: [
                  {
                    key: 0,
                    fullname: null,
                    phone: []
                  }
                ],
            
                // Prefix: null,
                // FirstName: null,
                // LastName: null,
                // NickName: null,
                // AKA: null,
                // CitizenId: null,
            
                Channel: null,
                CustomerId: null,
                Note: null,
                SpecialNeed: null,
              })
            }else{
              console.log(response)
              let text = '';
              Object.values(response.data.message).map((item, index) => {
                if(item.length > 0){
                  item.map(i => {
                    text = text + i + '\n'
                  })
                }else{
                  text = text + item + '\n'
                }
              });
              alert(text);
            }
          }else{
            console.log('failed')
          }
        } catch (error) {
          console.error(error);
        } finally {
          console.log('finally')
        }
      }
    }, 200)
  };

  useEffect(() => {
    GetProvince();
    GetSegment();
    PrepareNumber();
  }, []);

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetAmphure = async (data, select = true) => {
    if (select) setData({ ...Data, ProvinceId: data.id, AmphureId: null, DistrictId: null })
    
    const params = {
      ProvinceId: data.id,
    };
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally");
    }
  };

  const GetSegment = async (data) => {
    try {
      const response = await dispatch(SegmentList());
      setSegments(response.data.data);
      GetCustomer(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const GetCustomer = async (segmentData) => {
    let pr = {
      seg: 'seg',
    }
    try {
      const response = await dispatch(Customer(pr));
      
      if(response.data.data){
        const data = response.data.data
        setCustomers(data);
        // let arr = data.map(i => {
        //   if(i.segment_number !== null){ 
        //     return i.segment_number
        //   }
        // });

        let arr = []
        segmentData.forEach(segment => {
          arr[segment.id] = data.filter(item => item.segment_id === segment.id).map(i => i.segment_number)
        });

        setSegmentsExist(arr)
      }
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const SetSegmentNumber = (gnumber) => {
    setData({ ...Data, SegmentNumber: gnumber });
    setShow(false);
  }

  
  const InsertContact = () => {
    const contacts = Data.Contacts
    const model = {
      key: Data.Contacts.length,
      fullname: null,
      phone: []
    }
    contacts.push(model)
    const result = contacts

    setData({ ...Data, Contacts: result })
  }

  const RemoveContact = (key) => {
    const contacts = Data.Contacts
    const index = contacts.findIndex((item) => item.key === key)
    contacts.splice(index, 1)
    const result = contacts
    setData({ ...Data, Contacts: result })
  }

  const [show, setShow] = useState(false);
  const [GNumber, setGNumber] = useState([]);

  const DisplayNumber = async () => {
    if (!Data.SegmentId) return false
    GetNumber();
    setShow(true)
  }

  const Pad = async (num = 1, size = 6) => {
    num = num.toString();
        num = "1" + num;
    while (num.length < size) num = "0" + num;
    return num
  }

  const GetNumber = async () => {

    if(PreNumber.length > 0){
      setGNumber(PreNumber)
      let limit = 4000
      let count = 0
      let result =  PreNumber.filter(item => {
        if(count <= limit){
          count++
          return true
        }else{
          return false
        }
      });
      result = result.filter(val => !SegmentsExist[Data.SegmentId].includes(val));
      console.log(result)
      // setData({...Data, SegmentNumber: result[0]})
      setSegmentNumber(result)
      // setData({...Data, SegmentNumber: result[0]})
      setFNumber(result[0])
      // console.log(FNumber)
      // setData({ ...Data, SegmentNumber: result[0] });
    }else{
      await PrepareNumber()
      GetNumber()
    }
  };
  
  function closePage() {
    history.push(`/member`);
  }

  const [Districts, setDistricts] = useState([]);

  const GetDistrict = async (data) => {
    setData({ ...Data, AmphureId: data.id, DistrictId: '', PostCode: '' })
    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  return (
    <>
      <Container fluid className="component-create-member">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="row-form-create-memeber ">
            <Col xs={12} lg={3} className=" ">
              <Row className="align-items-center">
                <Col xs={4} lg={4} className="text-left mt-3 mb-3 text-lg-right pr-lg-0">
                  <Form.Label>Segment</Form.Label>
                </Col>
                <Col className="mt-3 mb-3">
                  <Select
                    options={Segments}
                    placeholder="Segment"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={false}
                    value={Data.SegmentId ? Segments.find((data) => {return data.id === Data.SegmentId}) : null}
                    onChange={(data) => { 
                      GenerateNumber();
                      setData({ ...Data, SegmentId: data.id })
                      // GetNumber() 
                      // setData({...Data, SegmentNumber: FNumber})
                      // SetMySegmentNumber(FNumber, data.id)
                    }}
                    className={`select-list ` + (!Data.SegmentId && validated ? 'validate-fail' : '') }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className=" pass-segment">
              <Row className="align-items-center">
                <Col xs={4} xl={3} className="mt-3 mb-3 text-left">
                  <Button
                    type="button"
                    onClick={DisplayNumber}
                    className={`pick-segment-button ` + (!Data.SegmentNumber && validated ? 'validate-fail' : '') }
                  >
                    <p className='m-0'>เลือกรหัส</p>
                  </Button>
                </Col>
                <Col xs={12} sm={8} className="mt-3 mb-3 text-left p-0">
                  <p className="m-0 ml-4">Ex. Segment: {Data.SegmentId && Segments.length > 0 ? Segments.find((data) => {return data.id === Data.SegmentId}).name : null} {Data.SegmentNumber}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={6} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ชื่อองค์กร</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="ชื่อองค์กร"
                    required
                    value={Data.Organization}
                    className={(!Data.Organization && validated ? 'validate-fail' : '') }
                    onChange={(e) => setData({ ...Data, Organization: e.target.value })}
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} xl={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                </Col>
                <Col lg={5}>
                  <Form.Control
                    type="text"
                    placeholder="เลขประจำตัวผู้เสียภาษี"
                    // required
                    value={Data.TaxId}
                    // className={(!Data.TaxId && validated ? 'validate-fail' : '') }
                    onChange={(e) => setData({ ...Data, TaxId: e.target.value })}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={12} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left">
                  <Form.Label>ที่อยู่ออกใบกำกับภาษี</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="ที่อยู่ออกใบกำกับภาษี"
                    // required
                    value={Data.InvoiceAddress}
                    onChange={(e) => setData({ ...Data, InvoiceAddress: e.target.value })}
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>จังหวัด</Form.Label>
                </Col>
                <Col>
                  <Select
                    options={Provinces}
                    placeholder="จังหวัด"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={false}
                    defaultValue={Data.ProvinceId}
                    onChange={(data) => { GetAmphure(data) }}
                    // required
                    className={`w-100 select-list ` 
                    // + (!Data.ProvinceId && validated ? 'validate-fail' : '') 
                  }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col className="text-left text-lg-right pr-lg-0" lg={3}>
                  <Form.Label>อำเภอ</Form.Label>
                </Col>
                <Col>
                  <Select
                    options={Amphures}
                    placeholder="อำเภอ"
                    getOptionLabel={option => option.name_th}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    isSearchable={false}
                    value={Data.AmphureId ? Amphures.find((data) => {return data.id === Data.AmphureId}) : null}
                    onChange={(data) => { GetDistrict(data) }}
                    isDisabled={!Data.ProvinceId}
                    className="select-list w-100"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ตำบล</Form.Label>
                </Col>
                <Col lg={8}>
                  <Select
                    options={Districts}
                    placeholder="ตำบล"
                    getOptionLabel={(option) => option.name_th}
                    getOptionValue={(option) => option.id}
                    isClearable={false}
                    defaultValue={Data.DistrictId}
                    value={
                      Data.DistrictId
                        ? Districts.find((data) => {
                          return data.id === Data.DistrictId;
                        })
                        : null
                    }
                    onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : '' }) }}
                    isDisabled={!Data.AmphureId && Districts.length === 0}
                    // required
                    className={`w-100 select-list `}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>รหัสไปรษณีย์</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder="รหัสไปรษณีย์"
                    value={Data.PostCode}
                    onChange={(e) => setData({ ...Data, PostCode: e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>ที่อยู่</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    // required
                    placeholder="ที่อยู่"
                    value={Data.Address}
                    onChange={(e) => setData({ ...Data, Address : e.target.value }) }
                    className={`aka-form ` }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-form-create-memeber">
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>E-mail</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    // required
                    value={Data.Email}
                    // className={(!Data.Email && validated ? 'validate-fail' : '') }
                    onChange={(e) => setData({ ...Data, Email: e.target.value }) }
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          {
            Data.Contacts?.map((contact, key) => {
              return (
                <div key={key}>
                  <Row className="row-form-create-memeber mt-4 mb-4">
                    <Col lg={8}>
                      <Row>
                        <Col lg={5} className="mt-3 mb-3">
                          <Row className="align-items-center">
                            <Col lg={4} className=" text-left text-lg-right">
                              <Form.Label>ชื่อผู้ติดต่อ</Form.Label>
                            </Col>
                            <Col className="pl-lg-0">
                              <Form.Control
                                type="text"
                                placeholder="ชื่อผู้ติดต่อ"
                                // required
                                value={contact.fullname}
                                onChange={
                                  (e) => setData(prevState => ({...Data,
                                    Contacts: prevState.Contacts.map(
                                      el => el.key === key ? { ...el, fullname: e.target.value }: el
                                    )
                                  })) 
                                }
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} className="mt-3 mb-3">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left text-lg-right pr-lg-0">
                              <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            </Col>
                            <Col>
                              <div>
                                <ReactTagInput
                                  placeholder="เบอร์โทรศัพท์"
                                  editable={true}
                                  readOnly={false}
                                  removeOnBackspace={true}
                                  maxTags={2}
                                  tags={contact.phone}
                                  // required
                                  onChange={
                                    (newTags) => setData(prevState => ({
                                      ...Data,
                                      Contacts: prevState.Contacts.map(
                                        el => el.key === key ? { ...el, phone: newTags }: el
                                      )
                                    })) 
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={1} className="set-button-delete">
                          <Button
                            type="button"
                            className="delete-contract"
                            onClick={() => RemoveContact(key)}
                          >
                            <img src={Delete} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} className="mt-3 mb-3">
                      {
                        (key+1) === Data.Contacts.length &&
                        <Row className="align-items-center">
                        <Col>
                          <Button
                            type="button"
                            className="add-contract-button"
                            onClick={(e) => InsertContact()}
                          >
                            <img src={add_contract} /> &nbsp;
                            เพิ่มผู้ติดต่อ
                          </Button>
                        </Col>
                      </Row>
                      }
                    </Col>
                  </Row>
                </div>
              )
            })
          }
          
          <Row className="row-form-create-memeber comment-row mt-5">
            <Col lg={4}>
              <Row>
                <Col lg={3} className="text-left text-lg-right pr-lg-0">
                  <Form.Label>หมายเหตุ</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder="หมายเหตุ"
                    style={{ height: '121px' }}
                    value={Data.Note}
                    // required
                    onChange={(e) => setData({ ...Data, Note: e.target.value }) }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between mt-5 mb-5">
            <Col xs={6} md={4} lg={3}>
              <Button type="submit" className="button">
                <img src={Save} /> &nbsp; บันทึก
              </Button>
              { DataNext.id ? (<Link to={"edit/"+DataNext.id+"?activeTab=2"}>
                <Button type="button" className="button mt-3">
                  เพิ่มข้อมูลรถ
                </Button>
              </Link>) : null}
            </Col>
            <Col xs={6} md={4} lg={3}>
              <Button type="button" className="button" onClick={closePage}>
                <img src={Return} /> &nbsp; ออก
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal
        className="modal-segment"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-segment">
          <p>
            <b>เลือกรหัส</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={NumberList}>
            <Row className="set-row-search-box">
              <Col lg={3} className="d-flex justify-content-center">
                <Form.Control
                  type="number"
                  placeholder="ค้นหารหัสว่าง"
                  required
                  value={SearchSegment.Keyword ? SearchSegment.Keyword : ''}
                  onChange={(e) => setSearchSegment({Keyword: e.target.value})}
                  className="search-code"
                ></Form.Control>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='submit' className="button-modal-segment">
                  <img src={Search} /> &nbsp; ค้นหา
                </Button>
              </Col>
              <Col lg={2} className="d-flex justify-content-center">
                <Button type='button' className="button-modal-segment" onClick={(e) => NumberList(e, 'reset')}><img src={Returnvalue} /> &nbsp; คืนค่า</Button>
              </Col>
            </Row>
          </Form>
          <Row className="set-box-value">
            <Col className="colum-value">
            { 
              SegmentNumber?.map(gnumber => {
                return (
                  <Button type='button' className="put-value-segment" onClick={(e) => SetSegmentNumber(gnumber)} key={gnumber}>{gnumber}</Button>
                )
              })
            }
            </Col>
            ;
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
