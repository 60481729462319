import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';

import Delete from '../../assets/icon/delete.png';
import Increase from 'assets/icon/add-staff.png';
import MainComponent from './Package/Index';
import WashPart from './Package/WashPart';
import Lightfilter from './Package/Lightfilter';
import CoatingService from './Package/CoatingService';
import Protective from './Package/Protective';
import ServiceFilter from './Package/ServiceFilter';
import SpecialPackage from './Package/SpecialPackage';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//API
import { PackageTypeList } from 'actions/package';


export default function Package() {
  let DataJob = localStorage.getItem('job-inside-data')
  var parseDataJob = JSON.parse(DataJob);

 

  const dispatch = useDispatch()
  const history = useHistory();
  const [typepackage, setTypePackage] = useState([{ value: '', label: '' }]);
  const [serviceMore, setServiceMore] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [PackageType,setPackageTypes] = useState([]);

  const optionPackage = [
    { value: 'บริการล้างและเคลือบแว๊กซ์', label: 'บริการล้างและเคลือบแว๊กซ์' },
    { value: 'บริการฟิล์มกรองแสง', label: 'บริการฟิล์มกรองแสง' },
    { value: 'บริการเคลือบเหมา', label: 'บริการเคลือบเหมา' },
    { value: 'บริการเคลือบเฉพาะจุด', label: 'บริการเคลือบเฉพาะจุด' },
    { value: 'บริการฟิล์มกันรอย', label: 'บริการฟิล์มกันรอย' },
    { value: 'แพ็คเกจพิเศษ', label: 'แพ็คเกจพิเศษ' },
    { value: 'Box Set', label: 'Box Set' },
    { value: 'Interior', label: 'Interior' },
  ];
  // const StorageModel = {
  //   key: 0,
  //   package_id: '',
  //   package_code: '',
  //   package_name: '',
  //   quantity: '',
  // }
  const StorageListModel  = {
    key:0,
    PackageTypeId:'',
    PackageTypeName:'',
    List:[
      // StorageModel
    ],
    note: '',
    insite_note:'',
  }
  const [Data,setData] = useState({
    PackageStorageList:
      parseDataJob && parseDataJob.PackageList.length > 0 ?  parseDataJob.PackageList  : []
  });



  function SetPackage(data) {
    if(data && data.id){
      setTypePackage({ value: data.id ,label: data.name, data});
    }else{
      alert('เลือกรายการ')
    }
  }

  
  function InsertPackageType(){
      if(typepackage.data && typepackage.data.id){
        let insert = StorageListModel
        insert.key = (Data.PackageStorageList.slice(-1).length > 0 ? Data.PackageStorageList.slice(-1)[0].key : 0) + 1
        insert.PackageTypeId = typepackage.data.id
        insert.PackageTypeName = typepackage.data.name
        setData({...Data, PackageStorageList:[...Data.PackageStorageList, insert]})

        const data = Data.PackageStorageList
        data.push(insert)

      }else{
        alert('เลือกรายการ')
      }
  }

  async function getPackageType(){
    setLoading(true)
      try {
        const response = await dispatch(PackageTypeList());
        setPackageTypes(response.data.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  useEffect(() => {
    getPackageType();
  },[]);

  const handleServiceMore = () => {
    setServiceMore(!serviceMore);
  };

  return (
    <>
      <Container fluid className="set-height-package">
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={10}>
                <Select
                  options={PackageType}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  // value={typepackage.value ? PackageType.find((data) => data.)}
                  placeholder="เลือกแพคเกจ"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(data) => SetPackage(data)}
                  className="select-list"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Row className="mt-3 mb-3 g-0 justify-content-center">
          {
               typepackage.label === 'Interior' ? (
                <>
                  <WashPart name='Interior' data={typepackage.data}/>
                </>
               ) : typepackage.label === 'Box Set' ? (
                <>
                  <WashPart name='Box Set' data={typepackage.data}/>
                </>
               ) : typepackage.label === 'บริการล้างและเคลือบแว๊กซ์' ? (
                <>
                  <WashPart name='บริการล้างและเคลือบแว๊กซ์' data={typepackage.data}/>
                </>
              ) : typepackage.label === 'บริการฟิล์มกรองแสง' ? (
                  <>
                    <Lightfilter name='บริการฟิล์มกรองแสง' data={typepackage.data}/>
                  </>
              ) : typepackage.label === 'บริการเคลือบเหมา' ? (
                <>
                  <CoatingService name='บริการเคลือบเหมา' data={typepackage.data}/>
                  
                </>

              ) : typepackage.label === 'บริการเคลือบเฉพาะจุด' ? (
                <>
                  <Protective name='บริการเคลือบเฉพาะจุด' data={typepackage.data}/>
                  
                </>

              ) : typepackage.label === 'บริการฟิล์มกันรอย' ? (
                  <>
                    <ServiceFilter name='บริการฟิล์มกันรอย' data={typepackage.data}/>
                    
                  </>
              ) : typepackage.label === 'แพ็คเกจพิเศษ' ? (
                <SpecialPackage name='แพ็คเกจพิเศษ' data={typepackage.data}/>
              ) : (
                <MainComponent  />
              )
          }
        </Row>
      </Container>
      
    </>
  );
}
