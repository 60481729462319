import axios, { post, put } from 'axios';

export const UserList = (params) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/users`,
      { params }
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const FindUser = (id) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/users/${id}`
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CreateUser = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/users`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateUser = ({id, formData}) => async (dispatch) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });
};

// export const UpdateUser = ({id, data}) => async () => {
//   return await post(
//     `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,data
//   ).then(response => {
//     return Promise.resolve(response);
//   }).catch( e => {
//     return Promise.reject(e)
//   });
// };

export const UpdateUserProfile = ({id, data}) => async () => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/users-profile/${id}`,data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateUserActive = ({id, data}) => async (dispatch) => {
  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/users/${id}/active`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadProfile = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/user/profile`, { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}
