import axios, { post, put } from 'axios';

export const DepartmentList = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/departments`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const CreateDepartment = (data) => async (dispatch) => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/departments`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const UpdateDepartment = ({id, data}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await put(
    `${process.env.REACT_APP_BASE_URL}/api/departments/${id}`,
    data,
    config
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const DestroyDepartment = (id) => async (dispatch) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/departments/${id}`
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};