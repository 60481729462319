import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Service from '../assets/icon/management.png';
import Footer from '../../Components/Footer';
import UserImage from 'assets/icon/user.png'

import { useHistory, useParams } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import { Switch } from 'antd';
import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import New_Save from '../assets/icon/save.png';

import { useDispatch } from 'react-redux';

// api
import { List } from '../../actions/employee/index';
import { UpdateUser, FindUser } from '../../actions/user/index';

export default function EditUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [statusService, setStatusService] = useState(false);

  function closePage() {
    let path = `/user`;
    history.push(path);
  }

  const [validated, setValidated] = useState(false);

  const optionLevel = [
    { value: 'Level 1', label: 'Level 1' },
    { value: 'Level 2', label: 'Level 2' },
    { value: 'Level 3', label: 'Level 3' },
  ];

  const [Label, setLabel] = useState({
    Employee: null,
    Branch: null,
    Department: null,
  });

  const [Data, setData] = useState({
    Username: null,
    Password: null,
    PasswordConfirmation: null,
    Active: false,
  });

  useEffect(() => {
    GetUser();
    GetEmployee();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    let formData = new FormData();
    if (Data.Username) formData.append('username', Data.Username);
    if (Data.Password) formData.append('password', Data.Password);
    if (Data.PasswordConfirmation)
      formData.append('password_confirmation', Data.PasswordConfirmation);
    formData.append('active', Data.Active ? 1 : 0);
    formData.append('_method', 'put');

    try {
      const response = await dispatch(UpdateUser({ id, formData }));

      if (response) {
        console.log('Created', response);
        history.push('/user');
      } else {
        console.log('failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const [Employees, setEmployees] = useState([]);
  const GetEmployee = async () => {
    const params = {
      user: false,
    };
    try {
      const response = await dispatch(List(params));
      setEmployees(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  // const [User, setUser] = useState([]);
  const GetUser = async () => {
    const params = {
      user: false,
    };
    try {
      const response = await dispatch(FindUser(id));
      // setUser(response.data);
      // console.log('response.data.employee.branch_id', response.data.data);
      setEmployee(response.data.data.employee);
      setData({
        // EmployeeId: response.data.data.employee_id,
        // BranchId: response.data.data.employee.branch_id,
        // DepartmentId: response.data.data.employee.department_id,
        Username: response.data.data.username,
        Password: null,
        PasswordConfirmation: null,
        Active: response.data.data.active,
      });
      // console.log('Data', Data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const setEmployee = (data) => {
    setData({ ...Data, EmployeeId: data.id });
    setLabel({
      Employee: data.fullname,
      Branch: data.branch.name,
      Department: data.department.name,
    });
  };
  return (
    <>
      <Container fluid className="package-container Service Stock User bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User &gt; รายชื่อผู้ใช้ระบบ &gt; เพิ่มผู้ใช้งาน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={UserImage} />
                  <b>User &gt; รายชื่อผู้ใช้ระบบ &gt; เพิ่มผู้ใช้งาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="form-user-create"
                >
                  <Row className="g-0 justify-content-start m-0   set-line mb-4">
                    <Col xs={12} className="p-0 mt-2 mb-2">
                      <div className="box-user">
                        <Form.Label>ข้อมูลส่วนตัว</Form.Label>
                      </div>
                    </Col>
                    <Col xs={12} lg={7} className="mt-3 mb-3">
                      <Row>
                        <Col lg={2} xl={2}></Col>
                        <Col xs={12} lg={10}>
                          <Row className="justify-content-start align-items-center">
                            <Col xs={5} lg={3} className="mt-4 mb-4 text-left">
                              <Form.Label>ชื่อพนักงาน</Form.Label>
                            </Col>
                            <Col
                              xs={7}
                              lg={6}
                              xl={5}
                              className="mt-4 mb-4 text-left"
                            >
                              <Form.Label>{Label.Employee}</Form.Label>
                              {/* <Select
                                options={Employees}
                                placeholder="พนักงาน"
                                getOptionLabel={option => option.fullname}
                                getOptionValue={option => option.id}
                                isClearable={false}
                                isSearchable={false}
                                onChange={(data) => setEmployee(data) }
                                className="select-list"
                              /> */}
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            <Col xs={5} lg={3} className="mt-4 mb-4 text-left">
                              <Form.Label>สาขา</Form.Label>
                            </Col>
                            <Col className="mt-4 mb-4 text-left">
                              <Form.Label>{Label.Branch}</Form.Label>
                              {/* <Select
                                options={Branches}
                                placeholder="สาขา"
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isClearable={false}
                                isSearchable={false}
                                onChange={(data) => setData({ ...Data, BranchId: data.id })}
                                className="select-list"
                              /> */}
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            <Col xs={5} lg={3} className="mt-4 mb-4 text-left">
                              <Form.Label>แผนก</Form.Label>
                            </Col>
                            <Col className="mt-4 mb-4 text-left">
                              <Form.Label>{Label.Department}</Form.Label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-start m-0   set-line">
                    <Col xs={12} lg={7} className="mt-5 mb-3">
                      <Row>
                        <Col lg={2} xl={2}></Col>
                        <Col xs={12} lg={10}>
                          <Row className="align-items-center">
                            <Col xs={5} lg={3} className="mt-3 mb-3 d-flex">
                              <Form.Label className="font-weight-bold">
                                Account
                              </Form.Label>
                            </Col>
                            <Col className="mt-3 mb-3 d-flex">
                              <Switch
                                checked={Data.Active}
                                onChange={(e) =>
                                  setData({ ...Data, Active: e })
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="set-height-edit-user">
                        {statusService === false ? (
                          <Row>
                            <Col lg={2} xl={2}></Col>
                            <Col xs={12} lg={10}>
                              <Row className="justify-content-start align-items-center">
                                {/* <Col
                                xs={5}
                                lg={3}
                                className="mt-3 mb-3 text-left"
                              >
                                <Form.Label>ชื่อพนักงาน</Form.Label>
                              </Col>
                              <Col
                                xs={7}
                                lg={6}
                                xl={5}
                                className="mt-3 mb-3 text-left"
                              >
                                <Select
                                  options={optionLevel}
                                  placeholder="เลเวล"
                                  isClearable={false}
                                  isSearchable={false}
                                  onChange={(data) => SetLevel(data)}
                                  className="select-list"
                                />
                              </Col> */}
                              </Row>
                              <Row className="justify-content-start align-items-center">
                                <Col xs={12}></Col>
                                <Col
                                  xs={5}
                                  lg={3}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label>ชื่อผู้ใช้</Form.Label>
                                </Col>
                                <Col
                                  xs={7}
                                  lg={6}
                                  xl={5}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Control
                                    value={Data.Username}
                                    required
                                    onChange={(e) =>
                                      setData({
                                        ...Data,
                                        Username: e.target.value,
                                      })
                                    }
                                  ></Form.Control>
                                </Col>
                                {/* <Col
                                xs={12}
                                lg={3}
                                className="mt-3 mb-3 text-left d-flex justify-content-center"
                              >
                                <Form.Label><a className="link-edit-password" className={(statusEditpassword === true ? 'd-none':'link-edit-password ')} onClick={handlerEditpassword}>แก้ไขรหัสผ่าน</a></Form.Label>
                              </Col> */}
                              </Row>
                              <Row className="justify-content-start align-items-center">
                                <Col
                                  xs={5}
                                  lg={3}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label>รหัสผ่าน</Form.Label>
                                </Col>
                                <Col
                                  xs={7}
                                  lg={6}
                                  xl={5}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Control
                                    type="password"
                                    required
                                    onChange={(e) =>
                                      setData({
                                        ...Data,
                                        Password: e.target.value,
                                      })
                                    }
                                    // disabled={(statusEditpassword === false)}
                                  ></Form.Control>
                                </Col>
                              </Row>
                              <Row className="justify-content-start align-items-center">
                                <Col
                                  xs={5}
                                  lg={3}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                                </Col>
                                <Col
                                  xs={7}
                                  lg={6}
                                  xl={5}
                                  className="mt-3 mb-3 text-left"
                                >
                                  <Form.Control
                                    type="password"
                                    required
                                    onChange={(e) =>
                                      setData({
                                        ...Data,
                                        PasswordConfirmation: e.target.value,
                                      })
                                    }
                                    // disabled={(statusEditpassword === false)}
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center  mt-5 mb-5">
                    <Col xs={6} lg={3} xl={2}>
                      <Button type="submit" className="button">
                        <img src={New_Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
