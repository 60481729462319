import axios, { post, put } from 'axios';

export const LevelList = (params) => async (dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/levels`, { params })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};

export const UpdateLevelMiddle = ({id,data}) => async (dispatch) => {
  try {
    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/levels-middle-update/${id}`,
      data
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
} 

export const DestroyLevel = (id) => async (dispatch) => {
  try {
    return await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/api/levels/${id}`)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
  } catch (error) {}
};




export const CustomPomermissions = (params) => async (dispatch) => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/custom-permissions`, { params })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}

export const CreateLogPermission = (data) => async (dispatch) => {
  return await post(
    `${process.env.REACT_APP_BASE_URL}/api/levels`,
    data
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const FindLevelAndPermission = ({id,data}) => async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/levels/${id}`,
    { data } 
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
}

export const UpdateLogPermission = ({id,data}) => async (dispatch) => {
  try {
    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/levels/${id}`,
      data
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
}

