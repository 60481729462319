import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  // Pagination,
  Modal,
  Form,
  // Dropdown,
} from 'react-bootstrap';
import FilmImage from 'assets/icon/glass-staff.png'
import Footer from 'Components/Footer';
import { DatePicker, TimePicker } from 'antd';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
// import Calendar from 'assets/icon/calendar-bg.png';
import Back from 'assets/icon/back.png';
import 'assets/scss/style-component.scss';
// import Increase from 'assets/icon/increase.png';
//assets/stock/order-stock
// import Request from 'assets/stock/delivery-request.png';
import { useDispatch, useSelector } from 'react-redux';

// import MyPagination from 'Components/MyPagination';

import AddIcon from 'assets/icon/increase.png';
import DeleteIcon from 'assets/icon/delete.png';

//API
import { JobList } from 'actions/jobs'
import { Branch } from 'actions/branch'
import { Customer } from 'actions/customer';
import { TechnicianQueueList, CreateTechnicianQueue, UpdateTechnicianQueue, DestroyTechnicianQueue } from 'actions/technician_queue';
import { List } from 'actions/employee';

import moment from 'moment';
var dayjs = require('dayjs')

export default function Detail() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { date } = useParams();
  const { employee } = useSelector( (state) => state.user)

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, 5)
  });

  function closePage() {
    history.push(`/`);
  }

  const daysInMonth = dayjs().daysInMonth()
  const firstDayInMonth = dayjs().date(1).get('day')

  const days = [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์'
  ]
  const formatTime = 24
  const workingTime = []
  for (let index = 5; index <= formatTime; index++) {
    workingTime.push({
      time: dayjs().hour(index).minute(0).second(0).format('HH:mm'),
      dayjs: dayjs().hour(index).minute(0).second(0)
    })
  }

  const [queues, setQueues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getQueue(){
    setIsLoading(true)
    const params = {
      date
    }
    dispatch(TechnicianQueueList(params)).then(response => {
      setQueues(response.data.data)
      setIsLoading(false)
    }).catch( e => {

    })
  }

  const getTimeRange = (item, period) => {
    const start_time_arr = item.start_time.split(':')
    const end_time_arr = item.end_time.split(':')
    const period_arr = period.split(':')
    const start_time = parseFloat(`${start_time_arr[0]}.${start_time_arr[1]}`)
    const end_time = parseFloat(`${end_time_arr[0]}.${end_time_arr[1]}`)
    const period_time = parseFloat(`${period_arr[0]}.${period_arr[1]}`)

    let status_color = null
    switch (item.status) {
      case 'รอดำเนินการ':
        status_color = '#F29339'
        break;
      case 'เสร็จสิ้น':
        status_color = '#009944'
        break;
      case 'ยกเลิก':
        status_color = '#cf000f'
        break;
    }

    if (parseFloat(start_time_arr[0]) === parseFloat(period_arr[0])) {
      const st = parseFloat(start_time_arr[1])
      const percent = (st / 60) * 100
      return(
        <div className='duration' style={{width: '100%', backgroundColor: status_color, marginLeft: `${percent}%`}} onClick={() => openEditModal(item) }></div>
      )
    }

    if (period_time > start_time && period_time < end_time) {
      let percent = 100
      if (parseInt(end_time) === parseInt(period_time)) {
        percent = ((end_time - period_time) / 0.6) * 100
      }
      return(
        <div className='duration' style={{ width: `${percent}%`, backgroundColor: status_color }} onClick={() => openEditModal(item) }></div>
      )
    }

  }

  const [selectedYear, setSelectedYear] = useState(dayjs().get('year'));
  const [selectedMonth, setSelectedMonth] = useState(dayjs().get('month'));

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [form, setForm] = useState({
    job_id: null,
    date: null,
    start_time: null,
    end_time: null,
    time: null,
    type: 'สาขา',
    b2b_id: null,
    b2b_contact_id: null,
    branch_id: null,
    employee_id: null
  });

  const [formEdit, setFormEdit] = useState({
    id: null,
    job_id: null,
    date: null,
    start_time: null,
    end_time: null,
    type: 'สาขา',
    b2b_id: null,
    b2b_contact_id: null,
    branch_id: null,
    employee_id: null
  });

  function openAddModal(){

    setForm({...form, date: moment(dayjs(date).format('YYYY-MM-DD'))})
    setAddModal(true)
  }

  function openEditModal(item){
    setSelectedCustomer(customers.find(c => c.id === item.b2b_id))
    setFormEdit({
      id: item.id,
      job_id: item.job_id,
      date: moment(item.date),
      time: [moment(item.start_time, 'HH:mm:ss'), moment(item.end_time, 'HH:mm:ss')],
      type: item.type,
      b2b_id: item.b2b_id ? item.b2b_id : null,
      b2b_contact_id: item.b2b_contact_id ? item.b2b_contact_id : null,
      branch_id: item.branch_id ? item.branch_id : null,
      employee_id: item.employee_id ? item.employee_id : null,
    })
    
    setEditModal(true)
  }

  const [jobs, setJobs] = useState([]);

  async function getJob(){
    dispatch(JobList()).then(response => {
      setJobs(response.data.data);
    }).catch( e => {

    })
  }

  const [branches, setBranches] = useState([]);

  async function getBranch(){
    dispatch(Branch()).then(response => {
      setBranches(response.data.data);
    }).catch( e => {

    })
  }

  const [employees, setEmployees] = useState([]);
  async function getEmployee(){
    dispatch(List()).then(response => {
      setEmployees(response.data.data);
    }).catch( e => {

    })
  }

  const [customers, setCustomers] = useState([]);
  async function getCustomer(){
    const params = {
      type: ['โชว์รูม']
    }

    dispatch(Customer(params)).then(response => {
      setCustomers(response.data.data);
    }).catch( e => {

    })
  }

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [validated, setValidated] = useState(false);

  const AddQueue = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true)

    let formData = new FormData();
    if(form.job_id) formData.append('job_id', form.job_id)
    if(form.date) formData.append('date', form.date.format('YYYY-MM-DD'))
    if(form.time && form.time[0]) formData.append('start_time', moment(form.time[0], 'HH:mm').format('HH:mm'))
    if(form.time && form.time[1]) formData.append('end_time', moment(form.time[1], 'HH:mm').format('HH:mm'))
    if(form.type) formData.append('type', form.type)
    if(form.b2b_id) formData.append('b2b_id', form.b2b_id)
    if(form.b2b_contact_id) formData.append('b2b_contact_id', form.b2b_contact_id)
    if(form.branch_id) formData.append('branch_id', form.branch_id)
    if(form.employee_id) formData.append('employee_id', form.employee_id)
    
    dispatch(CreateTechnicianQueue(formData)).then(response => {
      getQueue()
      setForm({
        job_id: null,
        date: null,
        time: null,
        type: 'สาขา',
        b2b_id: null,
        b2b_contact_id: null
      })
      setSelectedCustomer(null)
      setSelectedContact(null)
      setAddModal(false)
    }).catch( e => {

    })
  }

  const EditQueue = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    if (target.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true)

    let formData = new FormData();
    if(formEdit.job_id) formData.append('job_id', formEdit.job_id)
    if(formEdit.date) formData.append('date', formEdit.date.format('YYYY-MM-DD'))
    if(formEdit.time && formEdit.time[0]) formData.append('start_time', moment(formEdit.time[0], 'HH:mm').format('HH:mm'))
    if(formEdit.time && formEdit.time[1]) formData.append('end_time', moment(formEdit.time[1], 'HH:mm').format('HH:mm'))
    if(formEdit.type) formData.append('type', formEdit.type)
    if(formEdit.b2b_id) formData.append('b2b_id', formEdit.b2b_id)
    if(formEdit.b2b_contact_id) formData.append('b2b_contact_id', formEdit.b2b_contact_id)
    if(formEdit.branch_id) formData.append('branch_id', formEdit.branch_id)
    if(formEdit.employee_id) formData.append('employee_id', formEdit.employee_id)
    formData.append("_method", "put");

    const form = {
      id: formEdit.id,
      formData
    }
    
    dispatch(UpdateTechnicianQueue(form)).then(response => {
      getQueue()
      setForm({
        job_id: null,
        date: null,
        time: null,
        type: 'สาขา',
        b2b_id: null,
        b2b_contact_id: null
      })
      setSelectedCustomer(null)
      setSelectedContact(null)
      setEditModal(false)
    }).catch( e => {

    })
  }

  const deleteQueue = () => {
    dispatch(DestroyTechnicianQueue(formEdit.id)).then(response => {
      setQueues(queues.filter(item => item.id !== formEdit.id))
      setEditModal(false)
    }).catch( e => {

    })
  }

  useEffect(() => {
    getEmployee()
    getCustomer()
    getBranch()
    getJob()
    getQueue()
  }, []);

  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={FilmImage} />
                  <b>คิวช่างกระจก</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={FilmImage} />
                  <b>Stock</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Row>

                <Row className="g-0 justify-content-end">
                  <Col>
                    <div className='d-flex justify-content-between w-100'>
                      <Button type="button" className="button my-2 w-25" onClick={() => history.push(`/technician-queue`) }>
                        กลับ
                      </Button>
                      <Button type="button" className="button my-2 w-25" onClick={() => openAddModal() }>
                        <img src={AddIcon} className="icon-button" /> &nbsp;
                        เพิ่มรายการ
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row className="g-0 justify-content-start">
                  <Col>
                    <div className='custom-time-responsive'>
                      <div className='current-day'>
                        <p>{ dayjs(date).format('DD/MM/YYYY') }</p>
                      </div>
                      <div className='custom-calendar-time'>
                        <div className='name'>ช่างกระจก</div>
                        {
                          workingTime.map((item, key) => {
                            return(
                              <div className='time'>{item.time}</div>
                            )
                          })
                        }
                      </div>
                      {
                        queues.map((queue, key) => {
                          return(
                            <div className='custom-calendar-queue'>
                              <div className='list-name'>{ queue.employee?.fullname}</div>
                              {
                                workingTime.map((item, key) => {
                                  return(
                                    <div className='list'>
                                      { getTimeRange(queue, item.time) }
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={addModal}
        onHide={ () => setAddModal(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>เพิ่มคิวช่างกระจก</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setAddModal(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={validated} onSubmit={AddQueue}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '400px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <Select
                  placeholder="เลขที่ Job"
                  className='my-1'
                  options={jobs}
                  getOptionLabel={(option) => option.job_code}
                  getOptionValue={(option) => option.id}
                  value={form.job_id ? jobs.find((item) => {return item.id === form.job_id}) : null}
                  onChange={(j) => setForm({ ...form, job_id: j.id }) }
                />

                <DatePicker value={form.date} className='my-1' onChange={(date) => setForm({...form, date: date } ) } />

                <TimePicker.RangePicker className='my-1' disabledTime={disabledDateTime} format={'HH:mm'} onChange={(time) => setForm({...form, time: time } ) } />

                <Select
                  placeholder="ช่าง"
                  className='my-1'
                  options={employees}
                  getOptionLabel={(option) => option.fullname}
                  getOptionValue={(option) => option.id}
                  value={selectedEmployee && selectedEmployee}
                  onChange={(employee) => { setForm({ ...form, employee_id: employee.id });setSelectedEmployee(employee); } }
                />

                <div className='d-flex justify-content-around'>
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-1'
                    id={`radio-type`}
                    label={`สาขา`}
                    checked={form.type === "สาขา"}
                    value='สาขา'
                    onChange={(e) => {setForm({...form, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                  
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-2'
                    id={`radio-type`}
                    label={`โชว์รูม`}
                    value='โชว์รูม'
                    checked={form.type === "โชว์รูม"}
                    onChange={(e) => {setForm({...form, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                </div>

                {
                  form.type === "สาขา" &&
                  <Select
                    placeholder="สาขา"
                    className='my-1'
                    options={branches}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={form.branch_id ? branches.find((item) => {return item.id === form.branch_id}) : null}
                    onChange={(b) => setForm({ ...form, branch_id: b.id }) }
                  />
                }
              
                {
                  form.type === "โชว์รูม" &&
                  <>
                    <Select
                      placeholder="B2B"
                      className='my-1'
                      options={customers}
                      getOptionLabel={(option) => option.organization}
                      getOptionValue={(option) => option.id}
                      value={selectedCustomer && selectedCustomer}
                      onChange={(b2b) => { setForm({ ...form, b2b_id: b2b.id, b2b_contact_id: null });setSelectedCustomer(b2b);setSelectedContact(null); } }
                    />
                    <Select
                      placeholder="พนักงานขาย"
                      className='my-1'
                      options={selectedCustomer?.showroom_contacts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isDisabled={!form.b2b_id}
                      value={selectedContact && selectedContact}
                      onChange={(contact) => {setForm({ ...form, b2b_contact_id: contact.id });setSelectedContact(contact);} }
                    />
                  </>
                }
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setAddModal(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={editModal}
        onHide={ () => setEditModal(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>แก้ไขคิวช่างกระจก</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setEditModal(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Row className="g-0 justify-content-start">
            <Col sm={12} md={4} lg={4}>
              <Button type="button" className="button my-2" onClick={() => deleteQueue() }>
                <img src={DeleteIcon} className="icon-button" /> &nbsp;
                ลบ
              </Button>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={EditQueue}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '400px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <Select
                  placeholder="เลขที่ Job"
                  className='my-1'
                  options={jobs}
                  getOptionLabel={(option) => option.job_code}
                  getOptionValue={(option) => option.id}
                  value={formEdit.job_id ? jobs.find((item) => {return item.id === formEdit.job_id}) : null}
                  onChange={(j) => setFormEdit({ ...formEdit, job_id: j.id }) }
                />

                <DatePicker value={formEdit.date} className='my-1' onChange={(date) => setFormEdit({...formEdit, date: date } ) } />

                <TimePicker.RangePicker className='my-1' disabledTime={disabledDateTime} defaultValue={formEdit.time} format={'HH:mm'} onChange={(time) => setFormEdit({...formEdit, time: [moment(time[0], 'HH:mm'), moment(time[1], 'HH:mm')] } ) } />
                
                <Select
                  placeholder="ช่าง"
                  className='my-1'
                  options={employees}
                  getOptionLabel={(option) => option.fullname}
                  getOptionValue={(option) => option.id}
                  value={formEdit.employee_id ? employees.find(item => item.id === formEdit.employee_id) : null}
                  onChange={(employee) => { setFormEdit({ ...formEdit, employee_id: employee.id });setSelectedEmployee(employee); } }
                />

                <div className='d-flex justify-content-around'>
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-1'
                    id={`radio-type`}
                    label={`สาขา`}
                    checked={formEdit.type === "สาขา"}
                    value='สาขา'
                    onChange={(e) => {setFormEdit({...formEdit, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                  
                  <Form.Check 
                    inline
                    type='radio'
                    name='radio-type-2'
                    id={`radio-type`}
                    label={`โชว์รูม`}
                    value='โชว์รูม'
                    checked={formEdit.type === "โชว์รูม"}
                    onChange={(e) => {setFormEdit({...formEdit, type: e.target.value, branch_id: null, b2b_id: null, b2b_contact_id: null});setSelectedCustomer(null);setSelectedContact(null);} }
                  />
                </div>

                {
                  formEdit.type === "สาขา" &&
                  <Select
                    placeholder="สาขา"
                    className='my-1'
                    options={branches}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={formEdit.branch_id ? branches.find((item) => {return item.id === formEdit.branch_id}) : null}
                    onChange={(b) => setFormEdit({ ...formEdit, branch_id: b.id }) }
                  />
                }
              
                {
                  formEdit.type === "โชว์รูม" &&
                  <>
                    <Select
                      placeholder="B2B"
                      className='my-1'
                      options={customers}
                      getOptionLabel={(option) => option.organization}
                      getOptionValue={(option) => option.id}
                      value={formEdit.b2b_id ? customers.find((item) => {return item.id === formEdit.b2b_id}) : null}
                      onChange={(b2b) => { setFormEdit({ ...formEdit, b2b_id: b2b.id, b2b_contact_id: null });setSelectedCustomer(b2b);setSelectedContact(null); } }
                    />
                    <Select
                      placeholder="พนักงานขาย"
                      className='my-1'
                      options={selectedCustomer?.showroom_contacts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isDisabled={!formEdit.b2b_id}
                      value={formEdit.b2b_contact_id ? selectedCustomer?.showroom_contacts.find((item) => {return item.id === formEdit.b2b_contact_id}) : null}
                      onChange={(contact) => {setFormEdit({ ...formEdit, b2b_contact_id: contact.id });setSelectedContact(contact);} }
                    />
                  </>
                }
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button">
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setEditModal(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
