import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Modal,
} from 'react-bootstrap';
import Service from '../../assets/icon/management.png';
import Footer from '../../../Components/Footer';

import { useHistory,useParams, Link } from 'react-router-dom';
import TabsIcon from '../../Components/layout/TabsIcon';
import Back from '../../assets/icon/back.png';

import Save from '../../assets/icon/new-save.png';
import Getout from '../../assets/icon/new-re-turn.png';
import Print from '../../assets/icon/print.png';

import Return from '../../assets/icon/new-re-turn.png';
import StockImage from '../../assets/icon/appwizard_list.png'

import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

//API
import {OrderFind, UpdateOrder, UpdateOrderAdmin} from 'actions/orders'
import dayjs from 'dayjs';

export default function Data() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const levelString = localStorage.getItem('level');
  const level = levelString && levelString !== "undefined" ? JSON.parse(levelString): '';

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function closePackage() {
    let path = `/stock/receipt-order`;
    history.push(path);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState([]);
  const [Data,setData] = useState([]); 
  const [validated, setValidated] = useState(false);
  const [request,setReqeust] = useState(false);

  const getOrderFind = async () => {
    setIsLoading(true)
    try {
      const order_id = id
      const res = await dispatch(OrderFind(order_id))
      setOrder(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setIsLoading(false)
    }

  }

  const OrderUseTypeTH = (data) => {
    switch (data) {
      case 'order_stock':
        return 'เข้าสต็อก'
      case 'use_now':
          return 'ใช้ทันที'
      default:
        break;
    }
  }

  const updateOrder = () => {

    try {
      const data = {
        id: id,
        data: {
          type_use : order.type_use,
          note: Data.Note,
          status: 'done',
        }
      }
      const res = dispatch(UpdateOrderAdmin(data));
      history.push('/stock/receipt-order')
    } catch (error) {
      
    }
  }


  const convertStatusText = (status) => {
    switch (status){
      case 'pending' : return 'รออนุมัติ'
      case 'approved' : return 'อนุมัติ'
      case 'rejected' : return 'ยกเลิก'
      case 'delivery' : return 'รอรับเข้า'
      case 'done' : return 'เสร็จสิ้น'

    }
  }


  useEffect( () => {
    getOrderFind()
  },[]);
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    setShow(false);
  }
  return (
    <>
      <Container fluid className="package-container Service Stock  bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content mb-5">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; สั่งเข้าสต็อก</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePackage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; สั่งเข้าสต็อก</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form className="Stock-Request-Order-Branch mt-2 mb-2">
                  <Row className="g-0 justify-content-start">
                    <Col md={12} lg={6} className="">
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right d-lg-none "
                        >
                          <Form.Label className="mr-3">สถานะเอกสาร:</Form.Label>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={5}
                          className="ml-3 text-left d-lg-none"
                        >
                          <p>{order.status}</p>
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right"
                        >
                          <Form.Label>เลขที่ใบสั่งซื้อ</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.stock_code}</p>
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right"
                        >
                          <Form.Label>วันที่สั่ง</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{dayjs(order.order_date).format('DD-MM-YYYY')}</p>
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.employee && order.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right"
                        >
                          <Form.Label>ผู้บันทึก</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.user && order.user.employee && order.user.employee.fullname}</p>
                        </Col>
                      </Row>
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="set-label text-right"
                        >
                          <Form.Label>การใช้งาน</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{OrderUseTypeTH(order.type_use)}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} lg={6} className="set-hidden-status">
                      <Row className="justify-content-end">
                        <Col className="text-right">
                          <Form.Label>สถานะเอกสาร: </Form.Label>
                        </Col>
                        <Col xs={6} lg={3} className="text-left">
                          <p>{convertStatusText(order.status)}</p>
                          {/* <p>{order.status}</p> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-center m-0">
                    <Col
                      xs={12}
                      className=" mt-3 p-0"
                      style={{ overflow: 'scroll', height: '200px' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th >
                              รหัสสินค้า
                            </th>
                            <th >
                              ชื่อสินค้า
                            </th>
                            <th >จำนวน</th>
                            <th >หน่วย</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                          {
                            order.details?.map( item => {
                              return (
                                <tr>
                                  <td >{ item.product && item.product.code}</td>
                                  <td >{item.name}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.product && item.product.unit && item.product.unit.name_buy}</td>
                                  {/* <th></th> */}
                                </tr>
                              )
                            })
                          }
                          {/* <tr>
                            <td className="text-left">0406010300002</td>
                            <td className="text-left">น้ำยาเคลือบรถ</td>
                            <td>1</td>
                            <td>ขวด</td>
                            <th></th>
                          </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row className="g-0 mt-2 mb-2 justify-content-between align-items-center set-position">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          xl={2}
                          className="text-left"
                        >
                          <Form.Label className="font-weight-normal">
                            หมายเหตุ
                          </Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={order.note}
                            onChange={(e) =>{
                              setData({
                                ...Data,
                                Note: e.target.value,
                              });
                              setOrder({...order, note: e.target.value})}
                            }
                            className="text-area"
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className=" justify-content-center set-row-botton-request">
                    <Col lg={9}>
                      <Row className="d-flex justify-content-start pb-2">
                        {
                          order.status === 'delivery' ? (
                            <Col xs={12} lg={4} xl={3} className="mt-0 mb-5">
                              <Button className="button" onClick={handleShow}>
                                <img src={Save} /> &nbsp; รับเข้า
                              </Button>
                            </Col>
                          ) : null
                        }
                        <Col xs={6} lg={4} xl={3} className="mt-2">
                          {/* <Button className="button">
                            <img src={Print} /> &nbsp; พิมพ์เอกสาร
                          </Button> */}
                        </Col>
                        <Col
                          xs={6}
                          lg={4}
                          xl={3}
                          className="set-hidden-return-mobile   mt-2"
                        >
                          <Button className="button" onClick={closePackage}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <Row className="d-flex justify-content-end">
                        <Col className="set-hidden-return mt-2">
                          <Button className="button" onClick={closePackage}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
      <Modal
        className="modal-Confirm-Admit"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-Confirm-Admit">
          <p>
            <b>รับเข้าสินค้า</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="justify-content-center mt-3"
            style={{ overflow: 'scroll', height: 'auto' }}
          >
            <table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th>รหัสสินค้า</th>
                  <th>ชื่อสินค้า</th>
                  <th>จำนวน</th>
                  <th>หน่วย</th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
              {
                order && order.details?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.product && item.product.code}</td>
                      <td>{item.product && item.product.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.product && item.product.unit && item.product.unit.name_buy}</td>
                    </tr> 
                  );
                })}
              </tbody>
            </table>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8} className="mt-2 mb-2">
              <Row className="justify-content-center">
                {/* <Col xs={7} lg={6} className="mt-2 mb-2">
                  <LinkContainer to="/stock/receive/admit">
                    <Button className="button">
                      <img src={Save} /> &nbsp; ยันยันการรับเข้า
                    </Button>
                  </LinkContainer>
                </Col>
                <Col xs={7} lg={6} className="mt-2 mb-2">
                  <Button className="button">
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col> */}
                <Col xs={7} lg={6} className="mt-2 mb-2">
                  <Button className="button" onClick={updateOrder}>
                      <img src={Save} /> &nbsp; ยืนยันการรับเข้า
                    </Button>
                </Col>
                <Col xs={7} lg={6} className="mt-2 mb-2">
                  <Button className="button" onClick={close}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
