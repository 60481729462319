import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Form,
  Modal,
} from 'react-bootstrap';
import Service from 'assets/icon/appwizard_list.png';
import Footer from 'Components/Footer';

import { useHistory, Link,useParams } from 'react-router-dom';
import TabsIcon from 'MiddleSystems/Components/layout/TabsIcon';
import GroupButtonStock from 'MiddleSystems/Components/layout/GroupButtonStock';
import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';

import Save from 'assets/icon/new-save.png';
import Getout from 'assets/icon/getout.png';
import Print from 'assets/icon/print.png';

import Return from 'assets/icon/new-re-turn.png';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import { useDispatch } from 'react-redux';

//API
import {OrderFind, UpdateOrder, UpdateOrderAdmin} from 'actions/orders'



import dayjs from 'dayjs';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const profileString = localStorage.getItem('profile');
  const profile =JSON.parse(profileString);

  const levelString = localStorage.getItem('level');
  const level = levelString && levelString !== "undefined" ? JSON.parse(levelString): '';
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [statepage, setStatepage] = useState('');
  const [tax, setTax] = useState([
    { value: 'Exclude Vat', label: 'Exclude Vat' },
    { value: 'Include Vat', label: 'Include Vat' },
    { value: 'Non Vat', label: 'Non Vat' },
  ]);
  const [status, setStatus] = useState([
    { value: 'order_stock', label: 'เข้าสต็อก' },
    { value: 'use_now', label: 'ใช้ทันที' },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState([]);
  const [Data,setData] = useState({
    StockDate : null,
    DocumentDate: null,
  }); 
  const [validated, setValidated] = useState(false);
  const [request,setReqeust] = useState(false);

  const CurrentDate = dayjs().format('DD-MM-YYYY')

  const getOrderFind = async () => {
    setIsLoading(true)
    try {
      const order_id = id
      const res = await dispatch(OrderFind(order_id))
      setOrder(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
      setIsLoading(false)
    }

  }

  const ConvertVatText = (data) => {
    switch (data) {
      case 'non':
        return 'Non Vat'
        break;
      case 'include':
        return 'Include Vat'
        break;
        case 'exclude':
          return 'Exclude Vat'
          break;
    
      default:
        break;
    }
  }

  const updateOrder = () => {

    try {
      const data = {
        id: id,
        data: {
          stock_date: Data.StockDate,
          document_date: Data.DocumentDate,
          type_use: Data.Status,
          note: Data.Note,
          status: 'done',
        }
      }
      const res = dispatch(UpdateOrderAdmin(data));
      history.push('/stock/receipt-order')
    } catch (error) {
      
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setReqeust({...request, Note: true});

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity()) {
      const formData = {
        id: id,
        data: Data,
      }
      try {
        const response = await dispatch(UpdateOrder(formData));
          // history.push('/stock/receipt-order');
          //122121
      } catch (error) {
        console.error(error);
       
      } finally {
        console.log('finally')
      }
    }
  };

  function FunctionCheckValidate(){
    
    if(Data.StockDate !== null && Data.DocumentDate !== null){
      handleShow()
    }else{
      setValidated(true);
    }
    // 
  }

  useEffect( () => {
    getOrderFind()
  },[]);

  function closePage() {
    let path = `/stock/receipt-order`;
    history.push(path);
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    setShow(false);
  }
  
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รับเข้า</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Service} />
                  <b><Link to={`/stock`} className='mr-2'>Stock</Link> &gt; รับเข้า</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form validated={validated} onSubmit={handleSubmit} className="Stock-Request-Order-Branch mt-2 mb-2">
                  <Row className="g-0 justify-content-center">
                    <Col md={12} lg={6} className="">
                      <Row className="set-row-admit">
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex  set-label"
                        >
                          <Form.Label>สั่งโดย</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                          <p>{order.employee && order.employee.fullname ? order.employee.fullname : ''}</p>
                        </Col>
                      </Row>
                      {
                        level.middle === true ? (
                          <div>
                            <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              className="d-flex  set-label"
                            >
                              <Form.Label>ผู้จัดจำหน่าย</Form.Label>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                              {/* <p>{order.partner && order.partner.company_name ? order.partner.company_name : ''}</p> */}
                              <p>{order.partner_name ? order.partner_name : ''}</p>
                            </Col>
                          </Row>
                           <Row className="set-row-admit">
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={3}
                              className="d-flex align-items-center  set-label"
                            >
                              <Form.Label>การใช้งาน</Form.Label>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={5} className="text-left">
                              <Select
                                className={(!Data.Status && validated ? 'validate-fail' : '') }
                                aria-label="Default select example"
                                placeholder=""
                                options={status}
                                required
                                onChange={
                                  (e) => setData({...Data, Status: e.value })
                                }  
                              />
                            </Col>
                          </Row>
                          </div>
                        ) : null
                      }
                      
                    </Col>
                    <Col md={12} lg={6} className="set-div-position ">
                      <Row className="justify-content-end set-row-status">
                        <Col xs={12} md={12} lg={8} xl={8} className="bg-admit">
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>เลขที่ใบสั่งซื้อ</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              {
                                level.middle === true ? (
                                  <p>{order.order_number}</p>
                                ): (
                                  <p>{order.stock_code}</p>
                                )
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>วันที่รับเข้าระบบ</Form.Label>
                            </Col>
                            <Col>
                              <DatePicker
                                className={(!Data.StockDate && validated ? 'validate-fail' : '') }
                                  value={Data.StockDate}
                                  onChange={(date) => setData({ ...Data, StockDate: date})}
                                  required
                                  placeholder=""
                                  suffixIcon={<img src={Calendar} />}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>เอกสารลงวันที่</Form.Label>
                            </Col>
                            <Col>
                              <DatePicker
                                className={(!Data.DocumentDate && validated ? 'validate-fail' : '') }
                                value={Data.DocumentDate}
                                onChange={(date) => setData({ ...Data, DocumentDate: date})}
                                required
                                placeholder=""
                                suffixIcon={<img src={Calendar} />}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>วันที่บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p>{CurrentDate}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={4}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Form.Label>ผู้บันทึก</Form.Label>
                            </Col>
                            <Col className="d-flex">
                              <p className="text-p">: {order.user && order.user.employee && order.user.employee.fullname}</p>
                            </Col>
                          </Row>
                          {
                            level.middle ? ( 
                              <Row>
                                <Col
                                  xs={4}
                                  lg={5}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label>ชนิดภาษี</Form.Label>
                                </Col>
                                <Col className="d-flex">
                                  <p>{ConvertVatText(order.vat_type)}</p>
                                </Col>
                              </Row>
                            ) : null
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="g-0 justify-content-center">
                    <Row
                      className=" mt-3"
                      style={{ overflow: 'scroll', height: 'auto' }}
                    >
                      <table className="table table-responsive table-wash-part ">
                        <thead>
                          <tr className="t-header-package">
                            <th>รหัสสินค้า</th>
                            <th>ชื่อสินค้า</th>
                            <th>จำนวน</th>
                            <th>หน่วย</th>
                            <th>ราคาต่อหน่วย</th>
                            <th>ราคารวม</th>
                          </tr>
                        </thead>
                        <tbody className="t-body-package ">
                          {order && order.details?.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>{item.product.code}</td>
                                <td>{item.product.name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.product && item.product.unit && item.product.unit.name_buy}</td>
                                <td>{item.price}</td>
                                <td>{Number(item.total) - Number(item.discount_calculate)}</td>
                              </tr> 
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col lg={5}>
                      <Row>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          className="d-flex align-items-center"
                        >
                          <Form.Label>หมายเหตุ</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className=""
                            style={{ minHeight: '100px' }}
                            value={order.note}
                            required={request.Note === true ? request.Note : false }
                            onChange={(e) =>{
                              setData({
                                ...Data,
                                Note: e.target.value,
                              });
                              setOrder({...order, note: e.target.value})}
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className=" justify-content-center set-row-botton-request">
                    <Col lg={9}>
                      <Row className="d-flex justify-content-start">
                        <Col xs={12} lg={4} xl={3} className="mt-2">
                          <Button className="button" onClick={() => {FunctionCheckValidate()}}>
                            <img src={Save} /> &nbsp; รับเข้า
                          </Button>
                        </Col>
                        {/* <Col xs={6} lg={4} xl={3} className="mt-2">
                          <Button className="button">
                            <img src={Print} /> &nbsp; พิมพ์เอกสาร
                          </Button>
                        </Col> */}
                        {/* <Col xs={6} lg={4} xl={3} className="mt-2"  >
                          <Button className="button" type='submit'>
                            <img src={Getout} /> &nbsp; ยกเลิก
                          </Button>
                        </Col> */}
                        <Col
                          xs={6}
                          lg={4}
                          xl={3}
                          className="set-hidden-return-mobile   mt-2"
                        >
                          <Button className="button" type='button' onClick={closePage}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <Row className="d-flex justify-content-end">
                        <Col className="set-hidden-return mt-2">
                          <Button className="button" type='button' onClick={closePage}>
                            <img src={Getout} /> &nbsp; ออก
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
      <Modal
        className="modal-Confirm-Admit"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-Confirm-Admit">
          <p>
            <b>รับเข้าสินค้า</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="justify-content-center mt-3"
            style={{ overflow: 'scroll', height: 'auto' }}
          >
            <table className="table table-responsive table-wash-part ">
              <thead>
                <tr className="t-header-package">
                  <th>รหัสสินค้า</th>
                  <th>ชื่อสินค้า</th>
                  <th>จำนวน</th>
                  <th>หน่วย</th>
                </tr>
              </thead>
              <tbody className="t-body-package ">
              {
                order && order.details?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.product.code}</td>
                      <td>{item.product.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.product && item.product.unit && item.product.unit.name_buy}</td>
                    </tr> 
                  );
                })}
              </tbody>
            </table>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8} className="mt-2 mb-2">
              <Row className="justify-content-center">
                <Col xs={7} lg={6} className="mt-2 mb-2">
                  {/* <Link className="link-page" to="/stock/receipt-order">
                    <Button className="button">
                      <img src={Save} /> &nbsp; ยืนยันการรับเข้า
                    </Button>
                  </Link> */}
                  <Button className="button" onClick={updateOrder}>
                      <img src={Save} /> &nbsp; ยืนยันการรับเข้า
                    </Button>
                </Col>
                <Col xs={7} lg={6} className="mt-2 mb-2">
                  <Button className="button" onClick={close}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
