import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Delete from "assets/icon/delete.png";
import Select, { components } from "react-select";
import ReactTagInput from '@pathofdev/react-tag-input';

import "assets/css/app.css";
import "assets/css2/css/app.css";

import "assets/css2/css/modal.css";
import "assets/css2/css/employee.css";
import "assets/css2/css/responsive.css";
import "assets/css2/css/input.css";
import "assets/css2/css/table.css";
import "assets/css2/css/mr.kleane.css";
import "assets/scss/style.scss";

import { DatePicker, Space } from "antd";
import Calendar from "assets/icon/calendar-bg.png";

import Save from 'assets/icon/new-save.png';
import Out from 'assets/icon/getout.png';
import Increase from "assets/icon/add_menu.png";

import { FindCustomer, UpdateCustomer } from "actions/customer/index";
import { loadAuth } from "actions/auth";

import { Province, Amphure, District } from "actions/data/index";

import moment from "moment";
var dayjs = require("dayjs");
export default function Data() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const dateFormat = "YYYY-MM-DD";
  const [validated, setValidated] = useState(false);
  const ContactModel = {
    key: 0,
    name: null,
    phone: null,
    bank_name: null,
    account_name: null,
    account_number: null,
  };

  const [Data, setData] = useState({
    Organization: null,
    BranchName: null,
    TaxId: null,
    InvoiceAddress: null,
    AmphureId: null,
    DistrictId: null,
    PostCode: null,
    Email: null,
    ContactDate: null,
    Contacts: [ContactModel],
  });

  const [Provinces, setProvinces] = useState([]);
  const [Amphures, setAmphures] = useState([]);
  const [Districts, setDistricts] = useState([]);

  const [ErrorEmail , SetErrorEmail] = useState('');
  const GetCustomerInfo = async () => {
    try {
      const response = await dispatch(FindCustomer(id));
      let contacts = [];
      if (
        response.data.data.showroom_contacts &&
        response.data.data.showroom_contacts.length > 0
      ) {
        response.data.data.showroom_contacts.forEach((contact, key) => {
          let obj = {};
          obj.key = key;
          obj.id = contact.id || null;
          if (contact.customer_id) obj.customer_id = contact.customer_id;
          obj.name = contact.name;
          obj.phone = contact.phone;
          obj.bank_name = contact.bank_name;
          obj.account_name = contact.account_name;
          obj.account_number = contact.account_number;
          contacts.push(obj);
        });
      } else {
        contacts.push(ContactModel);
      }

      let province_id = null
      if (response.data.data.district) {
        province_id = response.data.data.district.amphure.province.id
        GetAmphure(response.data.data.district.amphure.province, false)
        GetDistrict(response.data.data.district.amphure, false);
      }else if(response.data.data.amphure){
        province_id = response.data.data.amphure.province.id
        GetAmphure(response.data.data.amphure.province, false)
        GetDistrict(response.data.data.amphure, false);
      }

      setData({
        CustomerId: id,
        Type: response.data.data.type,
        Organization: response.data.data.organization,
        BranchName: response.data.data.branch_name,
        TaxId: response.data.data.citizen_id,
        Email: response.data.data.email,
        ProvinceId: province_id,
        AmphureId: response.data.data.amphure_id,
        DistrictId: response.data.data.district_id,
        Address: response.data.data.address,
        InvoiceAddress: response.data.data.tax_invoice_address,
        PostCode: response.data.data.postcode,
        Note: response.data.data.note,
        ContactDate: response.data.data.contact_date
          ? moment(response.data.data.contact_date, dateFormat)
          : null,
        Contacts: contacts,
      });

    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const checkTaxId = (e) => {
    let val = e.target.value
    let maxLength = 13 
    let newValue = val < maxLength ? val : parseInt(val.toString().substring(0, maxLength));
    setData({ ...Data , TaxId: newValue })
  }
  const checkPostCode = (e) => {
    let val = e.target.value
    let maxLength = 6
    let newValue = val < maxLength ? val : parseInt(val.toString().substring(0, maxLength));
    setData({ ...Data , PostCode: newValue })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity()) {
      let formData = new FormData();
      formData.append("type", "โชว์รูม");
      if (Data.CustomerId) formData.append("customer_id", Data.CustomerId);
      if (Data.Organization) formData.append("organization", Data.Organization);
      if (Data.BranchName) formData.append("branch_name", Data.BranchName);
      if (Data.Email) formData.append("email", Data.Email);
      if (Data.TaxId) formData.append("citizen_id", Data.TaxId);
      if (Data.AmphureId) formData.append("amphure_id", Data.AmphureId);
      if (Data.DistrictId) formData.append("district_id", Data.DistrictId);
      if (Data.PostCode) formData.append("postcode", Data.PostCode);
      if (Data.CustomerId) formData.append("recommender", Data.CustomerId);
      if (Data.InvoiceAddress)
        formData.append("tax_invoice_address", Data.InvoiceAddress);
      if (Data.Address) formData.append('address', Data.Address)
      if (Data.ContactDate)
        formData.append(
          "contact_date",
          dayjs(Data.ContactDate).format("YYYY-MM-DD")
        );
      if (Data.Contacts && Data.Contacts.length > 0) {
        Data.Contacts.forEach((contact, key) => {
          if (contact.id) formData.append(`contacts[${key}][id]`, contact.id);
          if (contact.customer_id)
            formData.append(
              `contacts[${key}][customer_id]`,
              contact.customer_id
            );
          formData.append(`contacts[${key}][name]`, contact.name);
          formData.append(`contacts[${key}][phone]`, contact.phone);
          formData.append(`contacts[${key}][bank_name]`, contact.bank_name);
          formData.append(
            `contacts[${key}][account_name]`,
            contact.account_name
          );
          formData.append(
            `contacts[${key}][account_number]`,
            contact.account_number
          );
        });
      }

      if (Data.Note) formData.append("note", Data.Note);
      formData.append("_method", "put");
      const data = {
        id: id,
        formData,
      };

      try {
        const response = await dispatch(UpdateCustomer(data));

        if (response) {
          if(response.data.message == 'บันทึกสำเร็จ'){
            alert('บันทึกสำเร็จ');
            GetCustomerInfo();
            GetProvince();
            getAuth();
          }else{
            let text = '';
            Object.values(response.data.message).map((item, index) => {
              if(item.length > 0){
                item.map(i => {
                  text = text + i + '\n'
                })
              }else{
                text = text + item + '\n'
              }
            });
            alert(text);
          }
        } else {
          console.log("failed");
          if(Data.Email){
            SetErrorEmail('อีเมลให้ถูกต้อง')
          }else {
            SetErrorEmail('')
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log("finally");
      }
    }
  };

  const GetProvince = async () => {
    try {
      const response = await dispatch(Province());
      setProvinces(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally");
    }
  };

  const getAuth = async () => {
    try {
      const params = {
        name: ['customerB2B'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      // if(response.data.data === 'Unauthorized'){
      //   alert(response.data.message)
      //   history.push('/b2b');
      // }
    } catch (error) {
	history.push('/');
      console.error(error);
    }
  };

  const GetAmphure = async (data, select = true) => {
    if (select) setData({ ...Data, ProvinceId: data.id, AmphureId: null, DistrictId: null })
    
    const params = {
      ProvinceId: data.id,
    };
    try {
      const response = await dispatch(Amphure(params));
      setAmphures(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally");
    }
  };

  const GetDistrict = async (data, select = true) => {
    if (select) setData({ ...Data, AmphureId: data.id, DistrictId: '', PostCode: '' })
    
    const params = {
      AmphureId: data.id
    }
    try {
      const response = await dispatch(District(params));
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const RemoveContact = (key) => {
    const contacts = Data.Contacts;
    const index = contacts.findIndex((item) => item.key === key);
    contacts.splice(index, 1);
    const result = contacts;
    setData({ ...Data, Contacts: result });
  };

  const InsertContact = () => {
    let insert = ContactModel;
    insert.key = Data.Contacts.length;
    setData({ ...Data, Contacts: [...Data.Contacts, insert] });
  };

  useEffect(() => {
    GetCustomerInfo();
    GetProvince();
    getAuth()
  }, []);

  function exit() {
    history.push(`/b2b`);
  }

  return (
    <>
      <Container
        fluid
        style={{ paddingTop: 0, margin: 0 }}
        className="data-b2b"
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={3} className="text-left ">
                  <Form.Label>ชื่อองค์กร</Form.Label>
                </Col>
                <Col className="">
                  <Form.Control
                    type="text"
                    name="company"
                    value={Data.Organization}
                    onChange={(e) =>
                      setData({ ...Data, Organization: e.target.value })
                    }
                    placeholder=""
                    required
                    className="text-left search-btn-top"
                  ></Form.Control>
                </Col>
              </Row>
              
            </Col>
            <Col lg={3} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={3} className="text-left ">
                  <Form.Label>สาขา</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="branch"
                    value={Data.BranchName}
                    onChange={(e) =>
                      setData({ ...Data, BranchName: e.target.value })
                    }
                    placeholder=""
                    // required
                    className="text-left search-btn-top"
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={5} className="text-left ">
                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                </Col>
                <Col>
                 <Form.Control
                    type="number"
                    name="TaxpayerIdentificationNo"
                    value={Data.TaxId}
                    onChange={(e) => checkTaxId(e)}
                    placeholder=""
                    // required
                    className="text-left search-btn-top"
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="">
            <Col className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col xs={12} lg={2} className="text-left ">
                  <Form.Label>ที่อยู่ออกใบกำกับภาษี</Form.Label>
                </Col>
                <Col lg={5}>
                  <Form.Control
                    type="text"
                    name="taxinvoice"
                    value={Data.InvoiceAddress}
                    onChange={(e) =>
                      setData({ ...Data, InvoiceAddress: e.target.value })
                    }
                    placeholder=""
                    className="text-left search-btn-top"
                    // required
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left">
                  <Form.Label>จังหวัด</Form.Label>
                </Col>
                <Col lg={8}>
                  <Select
                    options={Provinces}
                    getOptionLabel={(option) => option.name_th}
                    getOptionValue={(option) => option.id}
                    isClearable={false}
                    value={
                      Data.ProvinceId
                        ? Provinces.find((data) => {
                            return data.id === Data.ProvinceId;
                          })
                        : null
                    }
                    onChange={(data) => {
                      GetAmphure(data);
                    }}
                    className="select-list w-100"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left">
                  <Form.Label>อำเภอ</Form.Label>
                </Col>
                <Col lg={8}>
                  <Select
                    options={Amphures}
                    placeholder="อำเภอ"
                    getOptionLabel={(option) => option.name_th}
                    getOptionValue={(option) => option.id}
                    isClearable={false}
                    defaultValue={Data.AmphureId}
                    value={
                      Data.AmphureId
                        ? Amphures.find((data) => {
                          return data.id === Data.AmphureId;
                        })
                        : null
                    }
                    onChange={(data) => { GetDistrict(data) }}
                    isDisabled={!Data.ProvinceId}
                    // required
                    className={`w-100 select-list ` }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left">
                  <Form.Label>ตำบล</Form.Label>
                </Col>
                <Col lg={8}>
                  <Select
                    options={Districts}
                    placeholder="ตำบล"
                    getOptionLabel={(option) => option.name_th}
                    getOptionValue={(option) => option.id}
                    isClearable={false}
                    defaultValue={Data.DistrictId}
                    value={
                      Data.DistrictId
                        ? Districts.find((data) => {
                          return data.id === Data.DistrictId;
                        })
                        : null
                    }
                    onChange={(data) => { setData({ ...Data, DistrictId: data.id, PostCode: data.zip_code ? data.zip_code : '' }) }}
                    isDisabled={!Data.AmphureId && Districts.length === 0}
                    // required
                    className={`w-100 select-list ` }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left">
                  <Form.Label>รหัสไปรษณีย์</Form.Label>
                </Col>
                <Col>
                <Form.Control
                  type="number"
                  name="postcode"
                  value={Data.PostCode}
                  onChange={ (e) => checkPostCode(e)}
                  placeholder=""
                  className="text-left search-btn-top"
                ></Form.Control>
                </Col>
              </Row>
            </Col>

            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={3} className="text-left">
                  <Form.Label>ที่อยู่</Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="address"
                    value={Data.Address}
                    onChange={ (e) => setData({ ...Data, Address: e.target.value }) }
                    placeholder=""
                    className="text-left search-btn-top"
                  ></Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="">
            <Col lg={6} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={2} className="text-left">
                  <Form.Label>E-mail</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={Data.Email}
                    onChange={(e) =>
                      setData({ ...Data, Email: e.target.value })
                    }
                    placeholder=""
                    className="text-left search-btn-top"
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={2} className="text-left">
                              
                </Col>
                <Col className='text-left'>
                  <label className='text-danger mt-2'>{ErrorEmail}</label>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={4} className="text-left">
                  <Form.Label> วันเริ่มติดต่อ </Form.Label>
                </Col>
                <Col>
                  <DatePicker
                    placeholder=""
                    suffixIcon={<img src={Calendar} />}
                    value={Data.ContactDate}
                    // required
                    onChange={(data) => setData({ ...Data, ContactDate: data })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" increase-contract">
            {Data.Contacts?.map((contact, key) => {
              return (
                <Col xs={12}>
                  <Row className="align-items-end">
                    <Col lg={10} className="set-increase-contract">
                      <Row className="align-items-center">
                      <Col lg={4} className="mt-2 mb-2">
                        <Row className="align-items-center">
                          <Col lg={4} className="text-left">
                              <Form.Label>ชื่อผู้ติดต่อ</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                name="nameContract"
                                value={contact.name}
                                onChange={(e) =>
                                  setData((prevState) => ({
                                    ...Data,
                                    Contacts: prevState.Contacts.map((el) =>
                                      el.key === key
                                        ? { ...el, name: e.target.value }
                                        : el
                                    ),
                                  }))
                                }
                                placeholder=""
                                className="text-left search-btn-top"
                                // required
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} className="mt-2 mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} className="text-left">
                              <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                name="phone"
                                value={contact.phone}
                                onChange={(e) =>
                                  setData((prevState) => ({
                                    ...Data,
                                    Contacts: prevState.Contacts.map((el) =>
                                      el.key === key
                                        ? { ...el, phone: e.target.value }
                                        : el
                                    ),
                                  }))
                                }
                                placeholder=""
                                className="text-left search-btn-top"
                                // required
                              ></Form.Control>
                              {/* <ReactTagInput
                                placeholder="เบอร์โทรศัพท์"
                                editable={true}
                                readOnly={false}
                                removeOnBackspace={true}
                                maxTags={2}
                                tags={contact.phone && contact.phone.length > 0 ? contact.phone : []}
                                onChange={
                                  (newTags) => setData(prevState => ({
                                    ...Data,
                                    Contacts: prevState.Contacts.map(
                                      el => el.key === key ? { ...el, phone: newTags }: el
                                    )
                                  })) 
                                }
                                className="input-search-add-staff"
                              /> */}
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          lg={2}
                          xl={2}
                          className="d-flex align-items-center justify-content-center mt-3 mb-3"
                        >
                          {key >= 0 && (
                            <Button
                              className="button"
                              onClick={() => RemoveContact(key)}
                            >
                              <img src={Delete} />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2} xl={2} className="setting-add mb-3">
                      <Row className="justify-content-center align-items-end">
                        {Data.Contacts.length - 1 === key && (
                          <Col sm={12}>
                            <Button
                              className="button"
                              onClick={() => InsertContact()}
                            >
                              <img src={Increase} /> &nbsp; เพิ่ม
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className="mt-3 mb-3">
              <Row>
                <Col lg={1} className="text-left">
                  <Form.Label style={{ fontSize: "16px" }}>หมายเหตุ</Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className="text-area"
                    value={Data.Note}
                    // required
                    onChange={(e) => setData({ ...Data, Note: e.target.value })}
                  />
                </Col>

              </Row>
            </Col>
          </Row>

          <Row className="mt-3 mb-2">
            <Col className="d-flex justify-content-start">
              <Button type="submit" className="increase">
                <img src={Save} />
                <span>บันทึก</span>
              </Button>
              <Link to="/job/company-b2b-show-room">
                <Button type="button" className="button ml-4">
                  Job Showroom (B2B)
                </Button>
              </Link>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button type="button" className="increase" onClick={exit}>
                <img src={Out} />
                <span>ออก</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}
