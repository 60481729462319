
import axios from 'axios';
import { updateUser } from 'slice/userSlice';

export default {
  setupInterceptors: (store, history) => {

      axios.interceptors.response.use(response => {
        return response;
      }, error => {

      if (error.response.status === 401) {
        console.log('inthis', history)
        store.dispatch(updateUser(null));
        history.replace('/login');
        window.location = '/login'
      }

      // if (error.response.status === 404) {
      //    history.push('/not-found');
      // }

      return Promise.reject(error);
    });
  },
};