import axios from 'axios';
// import {
//   USER_LOGIN_FAIL,
//   USER_LOGIN_REQUEST,
//   USER_LOGIN_SUCCESS,
//   USER_LOGOUT,
// } from '../Constants/userConstants';

export const SignIn = (credential) => async (dispatch) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, credential, config)
    .then(response => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
      localStorage.setItem('access_token', response.data.access_token)
      localStorage.setItem('profile', JSON.stringify(response.data.profile))
      localStorage.setItem('userInfo', JSON.stringify(response));
      localStorage.setItem('level', JSON.stringify(response.data.level))

      return Promise.resolve(response);
    }).catch(errors => {
      return Promise.reject(errors)
    })
};

export const SignOut = () => async () => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/logout`)
      .then(response => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('level');
        return Promise.resolve(response);
      })
      .catch(errors => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('level');
        return Promise.resolve(errors)
      })
};

export const loadAuth = (params) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user-auth`,{ params },
  config)
  .then(response => {
    return Promise.resolve(response);
  })
  .catch(errors => {
    return Promise.reject(errors)
  })
};



