import axios from 'axios';

export const LoadDashboard = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/dashboard`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadDashboardBranch = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/dashboard/branch`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};

export const LoadDashboardAppointment = (params) => async (dispatch) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/dashboard/appointment`,
    { params }
  ).then(response => {
    return Promise.resolve(response);
  }).catch( e => {
    return Promise.reject(e)
  });
};