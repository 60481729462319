import React, { useState } from 'react';

import Footer from 'Components/Footer';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  MenuItem,
  Pagination,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Table, Label, Input, FormGroup } from 'reactstrap';
import Select, { components } from 'react-select';

import 'assets/css/app.css';
import 'assets/css2/css/app.css';

import 'assets/css2/css/modal.css';
import 'assets/css2/css/employee.css';
import 'assets/css2/css/responsive.css';
import 'assets/css2/css/input.css';
import 'assets/css2/css/table.css';
import 'assets/css2/css/mr.kleane.css';
import 'assets/scss/style.scss';

import DataB2B from 'MiddleSystems/B2B/Components/Data';
import History from 'MiddleSystems/B2B/Components/History';
import DamageReport from 'MiddleSystems/B2B/Components/DamageReport';
import OverView from 'MiddleSystems/B2B/Components/OverView';
import TabsIcon from '../Components/layout/TabsIcon';
import TabsIconB from 'BranchSystems/Components/layout/TabsIcon';
import B2B from 'assets/icon/users_key-4.png';
import Back from 'assets/icon/back.png';
import B2BImage from 'assets/icon/address_book_home.png'
import { useDispatch, useSelector} from 'react-redux';

export default function Add() {
  const history = useHistory();
  const { employee } = useSelector( (state) => state.user)
  const [key, setKey] = useState('data');
  function close() {
    let path = `/b2b`;
    history.push(path);
  }
  return (
    <>
      
      <Container
        fluid
        className="package-container Service Stock User B2B bg-mrk"
      >
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  <b><Link to={`/b2b`} className='mr-2'>Company (B2B)</Link></b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={B2BImage} />
                  Company (B2B)
                </h5>
              </div>
              <div className="modal-body">
                <Col className="justify-content-start" style={{ padding: 0 }}>
                  { 
                    employee.is_middle ?
                    <TabsIcon />
                    :
                    <TabsIconB />
                  }
                </Col>
                <Col className="justify-content-start" style={{ padding: 0 }}>
                  <div className="box-typecar "></div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-4"
                  >
                    <Tab eventKey="data" title="ข้อมูล">
                      <DataB2B />
                    </Tab>
                    <Tab eventKey="history-service" title="ประวัติรับบริการ">
                      <History />
                    </Tab>
                    <Tab eventKey="view-all" title="ภาพรวม">
                      <OverView />
                    </Tab>
                    <Tab eventKey="damagereport" title="บันทึกความเสียหาย">
                      <DamageReport />
                    </Tab>
                  </Tabs>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
