import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap';
import StockImage from 'assets/icon/appwizard_list.png'
import Footer from '../../Components/Footer';

import { useHistory, Link } from 'react-router-dom';
import TabsIcon from '../Components/layout/TabsIcon';
import { DatePicker, Space } from 'antd';

import Calendar from 'assets/icon/calendar-bg.png';
import Back from 'assets/icon/back.png';
import Write from 'assets/icon/view-data.png';
import Save from 'assets/icon/new-save.png';
import Search from 'assets/icon/search.png';
import Return from 'assets/icon/return-value.png';
import Getout from 'assets/icon/getout.png';
import Increase from 'assets/icon/add-staff.png';
import Select, { components } from 'react-select';
import Arrow from 'assets/stock/adjust-stock.png';
import Document from 'assets/stock/cut-stock.png';
import PurchaseOrder from 'assets/stock/purchase-order.png';
import MenuSend from 'assets/stock/accept-orders.png';
import Transfer from 'assets/stock/storefront-transfer.png';
import Order_Stock from 'assets/stock/order-stock.png';
export default function Index() {
  const history = useHistory();
  const [showCutStock, setShowCutStock] = useState(false);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: '', label: '' }]);
  const [stock, setStock] = useState([
    {
      id: '0406010300002',
      product: 'น้ำยาเคลือบรถ',
      pointBuy: 15,
      lowpoint: 5,
      balance: 10,
      unitbuy: 'ขวด',
      updateday: '11/06/2564',
      user: 'นภาวรรณ ยั่งยืน ',
    },
    {
      id: '0406010300002',
      product: 'น้ำยาเคลือบรถ',
      pointBuy: 15,
      lowpoint: 5,
      balance: 10,
      unitbuy: 'ขวด',
      updateday: '11/06/2564',
      user: 'นภาวรรณ ยั่งยืน ',
    },
  ]);
  const handleCutStockClose = () => setShowCutStock(false);
  const handleCutStockShow = () => setShowCutStock(true);

  const [showAdjustStock, setShowAdjustStock] = useState(false);

  const handleAdjustStockClose = () => setShowAdjustStock(false);
  const handleAdjustStockShow = () => setShowAdjustStock(true);

  const [showTransfer, setShowTransfer] = useState(false);
  const handleTransferClose = () => setShowTransfer(false);
  const handleTransferShow = () => setShowTransfer(true);

  const [statepage, setStatepage] = useState('');
  const [page, setPage] = useState([
    { value: 'ทั้งหมด', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [cause, setCause] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const [transferBy, setTransferBy] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);

  const [causeAdjust, setCauseAdjust] = useState([
    { value: 'สินค้าหาย', label: 'สินค้าหาย' },
    { value: 'สินค้าเกิน', label: 'สินค้าเกิน' },
    { value: 'อื่น ๆ โปรดระบุ', label: 'อื่น ๆ โปรดระบุ' },
  ]);
  const handleSelectPage = (e) => setStatepage(e);
  function closeCutStock() {
    setShowCutStock(false);
  }
  function closeAdjustStock() {
    setShowAdjustStock(false);
  }
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function closePage() {
    let path = `/`;
    history.push(path);
  }
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [button_Search, setButton_Search] = useState('');
  const handleSelectType = (e) => setType(e);
  const handleSelectCategory = (e) => setCategory(e);

  const reset_value = () => {
    setSearch('');
    setType('');
    setCategory('');
  };
  return (
    <>
      <Container fluid className="package-container Service Stock bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  Stock
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePage}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={StockImage} />
                  Stock
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0">
                  <Col
                    lg={5}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="ค้นหาด้วยชื่อสินค้า,รหัสสินค้า"
                      // defaultValue={statepage[0]}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="input"
                    ></Form.Control>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu">
                      <img src={Search} /> &nbsp; ค้นหา
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Button className="button-menu" onClick={reset_value}>
                      <img src={Return} /> &nbsp; คืนค่า
                    </Button>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Link className="link-page" to="/branch/stock/adjust">
                      <Button className="button-menu">
                        <img src={Arrow} /> &nbsp; ปรับสต๊อก
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Link className="link-page" to="/branch/stock/cutstock">
                      <Button className="button-menu">
                        <img src={Document} /> &nbsp; ตัดสต็อก
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Link
                      className="link-page"
                      to="/stock/transfer-store"
                    >
                      <Button className="button-menu">
                        <img src={Transfer} /> &nbsp; โอนไปหน้าร้าน
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Link
                      className="link-page"
                      to="/stock/receive/order-to-stock"
                    >
                      <Button className="button-menu">
                        <img src={Order_Stock} /> &nbsp; สั่งเข้าสต็อก
                      </Button>
                    </Link>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    className="d-flex justify-content-center mt-2 mb-2"
                  >
                    <Link className="link-page" to="/branch/stock/receive">
                      <Button className="button-menu">
                        <img src={MenuSend} />
                        รายการรับเข้า/สั่งซื้อ
                      </Button>
                    </Link>
                  </Col>
                </Row>
                {/* <Row className="g-0 justify-content-center">
                  <GroupButtonStock />
                </Row> */}
                <Row className="g-0 justify-content-center m-0">
                  <Col
                    className=" mt-3 p-0"
                    style={{ overflow: 'scroll', height: '500px' }}
                  >
                    <table className="table table-responsive table-wash-part ">
                      <thead>
                        <tr className="t-header-package">
                          <th>รหัสสินค้า</th>
                          <th>ชื่อสินค้า</th>
                          <th>คลังสินค้า</th>
                          <th>หน้าร้าน</th>
                          <th>คงเหลือ</th>
                          <th>หน่วยซื้อ</th>
                          <th style={{ textAlign: 'left' }}>อัพเดทล่าสุด</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="t-body-package ">
                        {stock.map((item, i) => {
                          return (
                            <tr key={item}>
                              <td>{item.id}</td>
                              <td>{item.product}</td>
                              <td>{item.pointBuy}</td>
                              <td>{item.lowpoint}</td>
                              <td>{item.balance}</td>
                              <td>{item.unitbuy}</td>
                              <td>{item.updateday}</td>
                              <td>{item.user}</td>
                              <td>
                                <Button
                                  type=""
                                  className="button-package"
                                  onClick={handleAdjustStockShow}
                                >
                                  <img src={Arrow} />
                                </Button>
                              </td>
                              <td>
                                <Button
                                  type=""
                                  className="button-package"
                                  onClick={handleCutStockShow}
                                >
                                  <img src={Document} />
                                </Button>
                              </td>
                              <td>
                                <Button
                                  type=""
                                  className="button-package"
                                  onClick={handleTransferShow}
                                >
                                  <img src={Transfer} />
                                </Button>
                              </td>
                              <td>
                                <Button type="" className="button-package">
                                  <img src={Search} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row className="g-0 mt-3 mb-3">
                  <Col lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col lg={7}>
                        <Select
                          className="text-left select-style select-list"
                          aria-label="Default select example"
                          placeholder=""
                          options={page}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>

                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>

                        <Pagination.Ellipsis />
                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-cutstock"
          centered
          show={showCutStock}
          onHide={handleCutStockClose}
        >
          <Modal.Header className="modal-header-cutstock">
            <p>
              <b>ตัดสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeCutStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>0410300002</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>น้ำยาเคลือบรถ</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>10 ขวด</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวนนำออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control className="form-input"></Form.Control>
                  </Col>
                </Row>

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นำสินค้าออก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      onChange={onChange}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={cause}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: DC983435</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ตัด</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: 11/06/2564</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: นภาวรรณ ยั่งยืน</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button" className="button">
                      <img src={Getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-adjuststock"
          centered
          show={showAdjustStock}
          onHide={handleAdjustStockClose}
        >
          <Modal.Header className="modal-header-adjuststock">
            <p>
              <b>ปรับสต็อก</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeAdjustStock}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>0410300002</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>น้ำยาเคลือบรถ</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>10 ขวด</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 justify-content-start">
                  <Col xs={6} lg={8} className="">
                    {['radio'].map((type) => (
                      <Row
                        key={`inline-${type}`}
                        className="justify-content-start"
                      >
                        <Col xs={6} lg={4} className="d-flex">
                          <Form.Check
                            inline
                            label="รับเข้า"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                            onChange={(e) => setAdmit(e.target.value)}
                            checked={admit}
                          />
                        </Col>
                        <Col xs={6} lg={3} className="d-flex">
                          <Form.Check
                            inline
                            label="นำออก"
                            name="group1"
                            type={type}
                            id={`inline-${type}-2`}
                            onChange={(e) => setTakeOut(e.target.value)}
                            checked={takeOut}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control className="form-input"></Form.Control>
                  </Col>
                  <Col
                    xs={6}
                    lg={2}
                    className="d-flex align-items-center text-left  "
                  >
                    <Form.Label>นับโดย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list set-respon"
                      aria-label="Default select example"
                      placeholder=""
                      options={count}
                    />
                  </Col>
                </Row>

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่นับสต็อก</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      onChange={onChange}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>เหตุผล</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={causeAdjust}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: DC983435</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่ปรับ</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: 11/06/2564</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: นภาวรรณ ยั่งยืน</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button" className="button">
                      <img src={Getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-cutstock"
          centered
          show={showTransfer}
          onHide={handleTransferClose}
        >
          <Modal.Header className="modal-header-cutstock">
            <p>
              <b>โอนไปหน้าร้าน</b>
            </p>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleTransferClose}
            >
              <span aria-hidden="true">X</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-3">
              <Col xs={12}>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>0410300002</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>น้ำยาเคลือบรถ</p>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>ยอดปัจุบัน :</Form.Label>
                  </Col>
                  <Col xs={6} lg={3} className="text-left">
                    <p>10 ขวด</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>จำนวน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Form.Control className="form-input"></Form.Control>
                  </Col>
                </Row>

                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>วันที่โอน</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <DatePicker
                      onChange={onChange}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col
                    xs={6}
                    lg={3}
                    className="d-flex align-items-center text-left"
                  >
                    <Form.Label>โอนโดย</Form.Label>
                  </Col>
                  <Col xs={6} lg={3}>
                    <Select
                      className="text-left select-style select-list"
                      aria-label="Default select example"
                      placeholder=""
                      options={transferBy}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} lg={3} className="d-flex">
                    <Form.Label>หมายเหตุ</Form.Label>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-area"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center set-row">
              <Col xs={12} lg={12}>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex  text-left">
                    <Form.Label>เอกสารหมายเลข</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: DC983435</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>วันที่โอน</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: 11/06/2564</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <Form.Label>ผู้บันทึก</Form.Label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} className="d-flex text-left">
                    <p>: นภาวรรณ ยั่งยืน</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={11} lg={8}>
                <Row className="justify-content-between">
                  <Col xs={6}>
                    <Button type="button" className="button">
                      <img src={Save} /> &nbsp; บันทึก
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button" className="button" onClick={handleTransferClose}>
                      <img src={Getout} /> &nbsp; ออก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </>
  );
}
