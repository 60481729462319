import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import Pie from 'react-apexcharts';
import 'antd/dist/antd.css';
import ReactApexChart from 'react-apexcharts';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import CALENDAR from 'assets/icon/Calendar.png';
import Down from 'assets/icon/down.png';
// API
import { FindCustomer, OverView as CustomerOverView } from "actions/customer/index";

export default function OverView() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoadingBar, setLoadingBar] = useState(false)
  const [isLoadingPie, setLoadingPie] = useState(false)
  const [isLoadingSale, setLoadingSale] = useState(false)
  const [isLoadingContact, setLoadingContact] = useState(false)

  const { RangePicker } = DatePicker;
  const [Search, setSearch] = useState({
    year: moment(),
    year_income: moment().year(),
    date: [
      moment().startOf('month'),
      moment().endOf('month')
    ],
    showroom_contact_id: null,
  })
  const [Buddhist, setBuddhist] = useState([])


  const [BarOptions, setBarOptions] = useState({
    colors: ['#4E79A7', '#5D00A4', '#FF0000'],
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    stroke: {
      width: [20, 5],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    fill: {
      opacity: [0.85, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [],
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'month',
    },
    yaxis: {
      title: {
        text: 'บาท',
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(0) + ' บาท';
          }
          return y;
        },
      },
    },
  });
  const [BarSeries, setBarSeries] = useState([]);

  const [PieOptions, setPieOptions] = useState({
    colors: ['#4E79A7', '#F28E2B', '#E15759'],
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });
  const [PieSeries, setPieSeries] = useState([]);
  const [PieData, setPieData] = useState(null);

  const [SaleOptions, setSaleOptions] = useState({
    colors: ['#4E79A7', '#F28E2B', '#E15759'],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    labels:[],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    // xaxis: {
    //   type: 'user',
    //   categories: [
    //     'นายวันดี มีชัย',
    //     'นายขยัน อดทน',
    //     'นายแดง วันดี',
    //     'นางสาวมะลิ พาพี',
    //   ],
    // },
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  });
  const [SaleSeries, setSaleSeries] = useState([]);

  const [SaleEmployeeOptions, setSaleEmployeeOptions] = useState({
    colors: ['#FF0000'],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: '',
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    labels:[]
    // xaxis: {
    //   categories: [
    //     'มกราคม',
    //     'กุมภาพันธ์',
    //     'มีนาคม',
    //     'เมษายน',
    //     'พฤษภาคม',
    //     'มิถุนายน',
    //     'กรกฎาคม',
    //     'สิงหาคม',
    //     'กันยายน',
    //     'ตุลาคม',
    //     'พฤศจิกายน',
    //     'ธันวาคม',
    //   ],
    // },
  });

  const [SaleEmployeeSeries, setSaleEmployeeSeries] = useState([]);

  const { Option } = Select;

  const [ShowroomContacts , setShowroomContacts] = useState([])
  const [Data, setData] = useState(null)
  const GetCustomerInfo = () => {
    dispatch(FindCustomer(id)).then(response => {
      setData(response.data.data)
      setShowroomContacts(response.data.data.showroom_contacts)
    }).catch(e => {

    })
  };

  const [DataOverView, setDataOverView] = useState(null)
  const GetOverView = (y = null, s_date = null, e_date = null, y_n = null, c_t = null) => {

    const year = y ? y : moment(Search?.year).format('YYYY')
    const year_income = y_n ? y_n : Search?.year_income

    const start_date = s_date ? s_date : moment(Search?.date[0]).format('YYYY-MM-DD')
    const end_date = e_date ? e_date : moment(Search?.date[1]).format('YYYY-MM-DD')
    const showroom_contact_id = c_t ? c_t : Search?.showroom_contact_id

    const params = {
      year,
      year_income,
      customer_id: id,
      type: 'B2B',
      start_date,
      end_date,
      showroom_contact_id
    }

    dispatch(CustomerOverView(params)).then( response => {
      setDataOverView(response.data.data)

      setLoadingBar(true)

      const THMonths = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];

      const range = getMonths(response.data.data.total_sales, year)

      setBarOptions({...BarOptions, labels: THMonths })
      setBarSeries([
        {
          name: 'ยอดขายรายเดือน',
          type: 'column',
          data: range.total,
        },
        {
          name: 'ยอดขายสะสม',
          type: 'line',
          data: range.total.map((sum => value => sum += value)(0)),
        },
      ])

      setTimeout(() => {
        setLoadingBar(false)
      }, 500);

      setLoadingPie(true)

      setPieOptions({...PieOptions, labels: Object.keys(response.data.data.packages) })
      const PieSeries = Object.keys(response.data.data.packages).map((key) => response.data.data.packages[key].map((item) => item.grand_total).reduce((prev, next) => Number(prev) + Number(next), 0))
      const PieSerieCars = [].concat.apply([], Object.keys(response.data.data.packages).map((key) => response.data.data.packages[key].map((item) => item.job.car_owner_id)))
      setPieSeries(PieSeries)
      setPieData({
        total: PieSeries.reduce((prev, next) => Number(prev) + Number(next), 0),
        cars: PieSerieCars?.length
      })

      setTimeout(() => {
        setLoadingPie(false)
      }, 500);

      setLoadingSale(true)

      setSaleOptions({...SaleOptions, labels: Object.keys(response.data.data.sales) })
      const data = Object.keys(response.data.data.sales).map(key => response.data.data.sales[key])
      let temp = []

      data.forEach(item => {
        Object.keys(item).forEach(packageName => {
          let obj = {
            name: packageName,
            data: []
          }
          const exist = temp.find(t => t.name === obj.name)
          if (!exist) temp.push(obj)
        });
      })

      Object.keys(response.data.data.sales).forEach(key => {
        temp.forEach(item => {
          let mock = true
          Object.keys(response.data.data.sales[key]).forEach(j => {
            if (item.name === j) {
              item.data.push(response.data.data.sales[key][j].map(i => i.grand_total).reduce((prev, next) => Number(prev) + Number(next), 0))
              mock = false
            }
          });

          if (mock) {
            item.data.push(0)
          }
        });
      });

      setSaleSeries(temp)

      setTimeout(() => {
        setLoadingSale(false)
      }, 500);


      setLoadingContact(true)

      const rangeC = getContactMonths(response.data.data.contact_sales, year_income)

      setSaleEmployeeOptions({...SaleEmployeeOptions, labels: THMonths })
      setSaleEmployeeSeries([
        {
          name: 'ยอดขาย',
          data: rangeC.total,
        }
      ])

      setTimeout(() => {
        setLoadingContact(false)
      }, 500);
      

    }).catch(e => {
      console.log(e);
    })
  }

  function getMonths(total_sales, year) {
    
    let total = [];
    let currentYear = year;

    for (let index = 0; index < 12; index++) {
      const month = moment().month(index).year(currentYear).format('MM-YYYY')
      const temp = total_sales.filter(item => moment(item.created_at).format('MM-YYYY') === month)
      if (temp && temp.length > 0) {
        const sumTotal = temp.map(item => item.total_price).reduce((prev, next) => Number(prev) + Number(next), 0)
        total.push(sumTotal)
      }else{
        total.push(0)
      }
    }

    return { total };
  }

  function getContactMonths(total_sales, year) {
    
    let total = [];
    let currentYear = year;

    for (let index = 0; index < 12; index++) {
      const month = moment().month(index).year(currentYear).format('MM-YYYY')
     
      const temp = total_sales.filter(item => moment(item.created_at).format('MM-YYYY') === month)

      if (temp && temp.length > 0) {
        const sumTotal = temp.map(item => item.total_price).reduce((prev, next) => Number(prev) + Number(next), 0)
        total.push(sumTotal)
      }else{
        total.push(0)
      }
    }

    return { total };
  }

  const ChangeYear = (year) => {
    setSearch({...Search, year: moment(year).format('YYYY')})
    GetOverView(moment(year).format('YYYY'))
  }

  const ChangeYearIncome = (year) => {
    setSearch({...Search, year_income: (year - 543)})
    GetOverView(null, null, null, (year - 543))
  }

  const ChangeContact = (value) => {
    setSearch({...Search, showroom_contact_id: value})
    GetOverView(null, null, null, null, value)
  }

  const ChangeDate = (date) => {
    setSearch({...Search, date: date})
    if(date[0] && date[1]) GetOverView(null, date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
  }

  useEffect(() => {
    GetCustomerInfo();
    GetOverView()
    let Buddhist = []
    for (let index = (moment().year() - 10); index <= moment().year(); index++) {
      Buddhist.push(index + 543)
    }
    setBuddhist(Buddhist)
  }, []);

  return (
    <>
      <Container fluid className="view-all-b2b">
        <div className="set-dashboard">
          <div className="set-margin-wrong">
            <Row className="set-row-b2b">
              <Col lg={6} className="set-col-b2b title-view-all mt-3 mb-3">
                <div className="set-div div-name">
                  <p>ภาพรวม</p>
                  <p>{ DataOverView?.organization }</p>
                </div>
              </Col>
              <Col xs={6} lg={3} className="set-col-b2b income mt-3 mb-3">
                <div className="set-div">
                  <p>รายรับ</p>
                  <p>{ DataOverView?.income } บาท</p>
                </div>
              </Col>
              <Col xs={6} lg={3} className="set-col-b2b number-car mt-3 mb-3">
                <div className="set-div">
                  <p>จำนวนรถ</p>
                  <p>{ DataOverView?.cars } คัน</p>
                </div>
                
              </Col>
            </Row>
          </div>
          <Row className="set-row-chart">
            <div className="set-div-chart  ">
              <Row>
                <Col xs={12} lg={6} className="text-left">
                  <p className="sales-title">
                    ยอดขาย { Data?.organization }
                  </p>
                </Col>
                <Col xs={12} lg={6} className="">
                  <Row>
                    <Col className="d-flex justify-content-end align-items-center">
                      <p className="annual-text">รายรับประจำปี</p>
                    </Col>
                    <Col>
                      <DatePicker
                        picker="year"
                        suffixIcon={<img src={Down} />}
                        style={{
                          borderRadius: '20px',
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                        value={Search.year ? moment(Search.year) : null }
                        onChange={(year) => ChangeYear(year) }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <center>
                    {
                      !isLoadingBar && Data &&
                      <ReactApexChart
                        options={BarOptions}
                        series={BarSeries}
                        type="line"
                      />
                    }
                  </center>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="set-row-chart">
            <div className="set-div-chart">
              <Row>
                <Col sm={6} lg={6} className="text-left">
                  <p className="sales-title">เปรียบเทียบยอดขายแต่ละบริการ</p>
                </Col>
                <Col sm={6} lg={6} className="">
                  <Row>
                    <Col></Col>
                    <Col>
                      <RangePicker
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                        }}
                        value={Search.date}
                        onChange={(date) => ChangeDate(date)}
                        suffixIcon={<img src={CALENDAR} />}
                        separator="-"
                        className="calendar-antd-dashboard"
                        style={{
                          borderRadius: '20px',
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <Row className="justify-content-center">
                    <Col lg={10}>
                      <center>
                        {
                          !isLoadingPie && Data &&
                          <Pie
                            options={PieOptions}
                            series={PieSeries}
                            type="pie"
                            width="60%"
                          />
                        }
                      </center>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <div className="sum-all text-left">
                        <p className="sum-sales">รวมยอดขาย { PieData?.total } บาท</p>
                        <p className="sum-sales">จำนวน { PieData?.cars } คัน</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="set-row-chart ">
            <div className="set-div-chart bar-chart">
              <Row>
                <Col sm={6} lg={6} className="d-flex justify-content-start">
                  <p className="sales-title">ภาพรวมยอดขายพนักงาน</p>
                </Col>
                <Col sm={6} lg={6} className="">
                  <Row>
                    <Col></Col>
                    <Col>
                      <RangePicker
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                        }}
                        value={Search.date}
                        onChange={(date) => ChangeDate(date)}
                        suffixIcon={<img src={CALENDAR} />}
                        separator="-"
                        className="calendar-antd-dashboard"
                        style={{
                          borderRadius: '20px',
                          boxShadow: 'none ',
                          height: '50px',
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="">
                <Col className="">
                  <center>
                    {
                      !isLoadingSale && Data &&
                      <ReactApexChart
                        options={SaleOptions}
                        series={SaleSeries}
                        type="bar"
                        width="80%"
                      />
                    }
                  </center>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="set-row-chart ">
            <div className="set-div-chart ">
              <Row>
                <Col lg={3} className="d-flex justify-content-start">
                  <p className="sales-title">ยอดขายพนักงาน</p>
                </Col>
                <Col lg={9} className="d-flex justify-content-end mt-5">
                  <Row>
                    <Col
                      lg={6}
                      className="d-flex justify-content-end set-text-chart-line mt-3 mb-3"
                    >
                      <Col lg={5}>
                        <p className="text-chart-line">พนักงานขาย</p>
                      </Col>
                      <Input.Group compact>
                        <Select value={Search.showroom_contact_id} onChange={(value) => ChangeContact(value)} className="input-year">
                          {
                            ShowroomContacts?.map((item, key) => {
                              return(
                                <Option value={item.id}>{item.name}</Option>
                              )
                            })
                          }
                        </Select>
        
                      </Input.Group>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-end set-text-chart-line mt-3 mb-3"
                    >
                      <Col lg={5}>
                        <p className="text-chart-line">รายรับประจำปี</p>
                      </Col>
                      <Input.Group compact>
                        <Select value={Search.year_income + 543} onChange={(value) => ChangeYearIncome(value)} className="input-year">
                          {
                            Buddhist?.map(item => {
                              return(
                                <Option value={item}>{item}</Option>
                              )
                            })
                          }
                        </Select>
                      </Input.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    !isLoadingContact && Data &&
                    <ReactApexChart
                      options={SaleEmployeeOptions}
                      series={SaleEmployeeSeries}
                      type="line"
                      height="500px"
                    />
                  }
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </Container>
    </>
  );
}
