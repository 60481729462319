import axios, { post } from 'axios';

export const LoadStockList = (params) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/stocks`,
      { params }
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

// export const ProductStocks = (params) => async (dispatch) => {
//   try {
//     return await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/api/stocks`,
//       { params }
//     ).then(response => {
//       return Promise.resolve(response);
//     }).catch( e => {
//       return Promise.reject(e)
//     });

//   } catch (error) {

//   }
// };


export const saveStorage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/stocks`,
      formData,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const LoadNewStockCode = (params) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/new-stock-code`,
      { params }
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};