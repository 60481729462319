import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap';
import Setting from 'assets/icon/setting.png';
import Footer from 'Components/Footer';

import { useHistory,useParams,Link } from 'react-router-dom';
import TabsIcon from 'Components/layout/TabsIcon';
import GroupButtonStock from 'Components/layout/GroupButtonStock';
import { DatePicker, Space } from 'antd';
import Calendar from 'assets/icon/calendar-bg.png';
import { Switch } from 'antd';
import Back from 'assets/icon/back.png';
import Select, { components } from 'react-select';
import MenuStock from 'Components/layout/MenuStock';
import Write from 'assets/icon/flat-style-circle-write.png';
import Arrow from 'assets/icon/arrow.png';
import Document from 'assets/icon/document.png';
import Search from 'assets/icon/eyes-search.png';
import Save from 'assets/icon/save.png';
import Return from 'assets/icon/flat-style-circle-turn-on.png';
import Note from 'assets/icon/address_book.png';
import AddIcon from 'assets/icon/add_menu.png';

import { CustomPomermissions,FindLevelAndPermission,UpdateLogPermission,UpdateLevelMiddle } from '../../actions/settings/permission';


export default function Edit() {
  const {id} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCutStock, setShowCutStock] = useState(false);
  const [admit, setAdmit] = useState(true);
  const [takeOut, setTakeOut] = useState(true);
  const [count, setCount] = useState([{ value: '', label: '' }]);

  const [statusService, setStatusService] = useState(false);
  const statusServiceToggler = () => {
    statusService ? setStatusService(false) : setStatusService(true);
  };

  const [Data,setData] = useState('');
  const [statepage, setStatepage] = useState('');
  const [page, setPage] = useState([
    { value: 'ทั้งหมด', label: 'ทั้งหมด' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ]);

  const [HideAdd, setHideAdd] = useState([
    'Stock'
  ]);

  const [HideDelete, setHideDelete] = useState([
    'Stock'
  ]);

  const [validated, setValidated] = useState(false);
  const [Rows, setRows] = useState(0);

  const [isLoading, setisLoading] = useState(false);
  const [Rerender,setRerender] = useState(false);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1
  });
  const [Middle,setMiddle] = useState(false);
  const [Permissons,setPermissons] = useState([]);

  const handleOnChangeMiddle = () => {
    setMiddle(!Middle);
    updateMiddle({id,Middle})
    setRerender(true)
    // GetLevel();
  };

    useEffect(() => {
    if (!Rerender) GetLevel();
    setRerender(false)
   }, [Rerender]);

  const handleOnChangePermissons = (status, checked, index) =>{
    if(status === 'All'){
      let obj = {}
      for (var key in Permissons[index]) {
        if (!['PermissionId', 'Name'].includes(key)) {
          obj[key] = checked
        }
      }
      setPermissons(Permissons.map(
        (el, key) => key === index ? {...el, ...obj} : el
      ))
    }else{
      setPermissons(Permissons.map(
        (el, key) => key === index ? {...el, [status]: checked} : el
      ))
    }

    // if(status === 'All'){
    //   let data = {...Permissons[index]};
    //     data.All = true
    //     data.View = true
    //     data.Add = true
    //     data.Edit = true
    //     data.Delete = true
    //   Permissons[index] = data;
    //   setPermissons(Permissons);
    // }else{
    //   let data = {...Permissons[index]};
    //   data.All = false
    //   if(status === 'View'){
    //     data.View = !data.View
    //   }
    //   if(status === 'Add'){
    //     data.Add = !data.Add
    //   }
    //   if(status === 'Edit'){
    //     data.Edit = !data.Edit 
    //   }
    //   if(status === 'Delete'){
    //     data.Delete = !data.Delete 
    //   }
    //   if(data.View === true && data.Add === true && data.Edit === true && data.Delete === true){
    //     data.All = true
    //   }

    //   Permissons[index] = data;

    //   setPermissons(Permissons);
    // }
  }

  const GetLevel = async () => {
    const form = {
      id : id,
      data : {
        middle: Middle,
      }
    }
    try {
        setisLoading(true)
        const response = await dispatch(FindLevelAndPermission(form));
        const data = response.data.data
        const newFormData = {
          LevelId: data.id,
          Name: data.name
        }
        setData(newFormData)
        setStatusService(data.active);
        setMiddle(data.middle);
        // console.log('test',data.middle === true);
        if(data.middle === true ||  data.middle === 'true'){
          // console.log('middle');
          const newForm = data.log_permissions_middle.map( i => {
            return {
              PermissionId: i.custom_permission_id,
              Name: i.permission.name,
              All: i.all,
              View: i.view,
              Add: i.add,
              Edit: i.edit,
              Delete: i.delete,
            }
          });
          // console.log('newForm',newForm);
          setPermissons(newForm);

        }else{
          // console.log('normal');
          const newForm = data.log_permissions_branche.map( i => {
            return {
              PermissionId: i.custom_permission_id,
              Name: i.permission.name,
              All: i.all,
              View: i.view,
              Add: i.add,
              Edit: i.edit,
              Delete: i.delete,
            }
          });
          // console.log('newForm',newForm);
          setPermissons(newForm);
        }
        setisLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }



  const GetPermissons = async (data) => {
    setisLoading(true)
    try {
      const response = await dispatch(CustomPomermissions());
      if(response.data.data && response.data.data.data){
        setPermissons(response.data.data.data);
        setRows(response.data.data.data.last_page);
        setisLoading(false)
      }else{
        const data = response.data.data
        const newForm = data.map( i => {
          return {
            PermissionId: i.id,
            Name: i.name,
            All: false,
            View: false,
            Add: false,
            Edit: false,
            Delete: false,
          }
        });
        // console.log('newForm',newForm);
        setPermissons(newForm);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const updateMiddle = async (data) => {
    console.log(data);

    const formData = {
      id : data.id,
      data: {
        middle: data.Middle,
      }
    }
    try {
      const response = await dispatch(UpdateLevelMiddle(formData));

    } catch (error) {
      console.error(error);
     
    } finally {
      console.log('finally')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    
    if (form.checkValidity()) {
      const formData = {
        id : Data.LevelId,
        data: {
          name: Data.Name,
          active: statusService,
          middle: Middle,
          setting_permission: Permissons
        }
      }

      
      try {
        const response = await dispatch(UpdateLogPermission(formData));
       
        if(response){
          history.push('/setting/permission');
        }else{
          console.log('failed')
        }
      } catch (error) {
        console.error(error);
       
      } finally {
        console.log('finally')
      }
    }
  };

  const handleSelectPage = (e) => setStatepage(e);

  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  function close() {
    let path = `/setting/permission`;
    history.push(path);
  }

  useEffect(() => {
    GetLevel();
    // GetPermissons();
  }, []);


  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; ตั้งค่าการใช้งาน</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Row className="g-0 justify-content-center mb-3">
                  <Col xs={12}>
                    <Row>
                      <Col xs={8} md={9} lg={5}>
                        {/* <Form.Control placeholder="ชื่อประเภท"  ></Form.Control> */}
                        <Form.Control
                          type="text"
                          name="name"
                          required
                          value={Data.Name}
                          onChange={(e) => setData({ ...Data, Name: e.target.value })}
                          placeholder="ชื่อระดับ" 
                        ></Form.Control>
                      </Col>
                      <Col
                        xs={4}
                        md={3}
                        lg={3}
                        xl={1}
                        className="d-flex align-items-center"
                      >
                        <Switch checked={statusService} onClick={statusServiceToggler} />
                        {/* {statusService ? <span>well</span> : <span>Bad</span>} */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                  <div className="set-height">
                    <Row className="g-0   mt-3 justify-content-center">
                      <Col lg={12} className="d-flex mt-2 mb-2">
                        <Form.Check inline label="" name="check" 
                        checked={Middle}
                        onChange={handleOnChangeMiddle}

                        />
                        <p>เฉพาะระบบกลาง
                          {/* { Middle === true ? 'true' : 'false' }  */}
                          </p>
                      </Col>
                      <Col lg={12} className="d-flex mt-2 mb-2">
                        <p className="font-weight-bold">ตั้งค่าการใช้งาน</p>
                      </Col>
                      <Row className="">
                        <table className="table table-responsive ">
                          <thead>
                            <tr className="t-header-setting">
                              <th className="text-left" width="50%">
                                ชื่อเมนู
                              </th>
                              <th>ทั้งหมด</th>
                              <th>ดู</th>
                              <th>สร้าง</th>
                              <th>แก้ไข</th>
                              <th>ลบ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            { 
                              Permissons?.map( (item, index) => {
                                return (
                                  <tr
                                    key={`inline-checkbox${index}`}
                                    className="t-tr-setting"
                                  >
                                   
                                    <td className="text-left">{item.Name}</td>

                                    <td>
                                      {
                                        !HideAdd.includes(item.Name) &&
                                        <Form.Check
                                          inline
                                          name="all"
                                          type='checkbox'
                                          checked={item.All}
                                          onChange={(e) => {handleOnChangePermissons('All', e.target.checked, index)}}
                                          id={`inline-checkbox-box-1`}
                                        />
                                      }
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name="view"
                                        type='checkbox'
                                        checked={item.View}
                                        onChange={(e) => {handleOnChangePermissons('View', e.target.checked, index)}}
                                        id={`inline-checkbox-box-2`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name="add"
                                        type='checkbox'
                                        onChange={(e) => {handleOnChangePermissons('Add', e.target.checked, index)}}
                                        checked={item.Add}
                                        id={`inline-checkbox-box-3`}
                                      />
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name="edit"
                                        type='checkbox'
                                        checked={item.Edit}
                                        onChange={(e) => {handleOnChangePermissons('Edit', e.target.checked, index)}}
                                        id={`inline-checkbox-box-4`}
                                      />
                                    </td>
                                    <td>
                                      {
                                        !HideDelete.includes(item.Name) &&
                                        <Form.Check
                                          inline
                                          name="delete"
                                          type='checkbox'
                                          checked={Permissons[index].Delete}
                                          onChange={(e) => {handleOnChangePermissons('Delete', e.target.checked, index)}}
                                          id={`inline-checkbox-box-5`}
                                        />
                                      }
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </Row>
                    </Row>
                  </div>

                  <Row className="justify-content-center mt-3 mb-2">
                    <Col md={3} lg={2}>
                      <Button type="submit" className="button">
                        <img src={Save} /> &nbsp; บันทึก
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
