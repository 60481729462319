import axios, { post, put } from 'axios';

export const CarModelList = (params) => async (dispatch) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/car-models`,
      { params }
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const CreateCarModel = (data) => async (dispatch) => {
  try {
    return await post(
      `${process.env.REACT_APP_BASE_URL}/api/car-models`,
      data
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};

export const UpdateCarModel = ({id, data}) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await put(
      `${process.env.REACT_APP_BASE_URL}/api/car-models/${id}`,
      data,
      config
    ).then(response => {
      return Promise.resolve(response);
    }).catch( e => {
      return Promise.reject(e)
    });

  } catch (error) {

  }
};